import { useRef, useState } from 'react'

import Icon, { IconProps } from 'components/front-end/Icons/Icon'

type MainTabProps = {
  title: string[]
  description?: string
  link?: string
  type?: string
  isdisabled?: boolean
}

const Questionnaire = ({ title, description, link, type }: MainTabProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  })
  const [isChecked, setIsChecked] = useState(false)
  const [checkedItems, setCheckedItems] = useState<boolean[]>(title.map(() => false))
  const tabElement = useRef<HTMLButtonElement>(null)
  const icon: Pick<IconProps, 'name' | 'type'> = checkedItems
    ? { name: 'radio-unselected', type: 'stroke' }
    : { name: 'check-circle', type: 'fill' }

  function handleMouseMove(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const offsetLeft = tabElement?.current?.getBoundingClientRect().left || 0
    const width = tabElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < 80) finalX = 0
    if (finalX > width - 80) finalX = width

    setMousePosition({ left: finalX, top: ev.pageY })
  }

  function handleClick() {
    setIsChecked(!isChecked)
    // Perform other click-related actions if needed
  }

  function handleListItemClick(index: number) {
    const updatedCheckedItems = [...checkedItems]
    updatedCheckedItems[index] = !updatedCheckedItems[index]
    setCheckedItems(updatedCheckedItems)
  }

  const buttonClasses = `
        group z-0 relative flex
        border-t xm:border-t-1 last:border-b xm:last:border-b-1 border-r xm:border-r-0 border-l xm:last:border-r
        border-solid border-grey-light [.dark_&]:border-grey-medium
        min-w-full xm:min-w-[30%]
        p-[18px] xm:px-8 xm:py-6
        text-left overflow-hidden transition duration-400
        ${isChecked ? 'icon-hover-true is-active' : ''}
        ${type === 'Questionnaire Desktop Standard' ? 'xm:py-10 xm:px-10' : ''}
        ${type === 'List Questionnaire Desktop Standard' ? 'xm:py-4 xm:p-4' : ''}
        ${type === 'Questionnaire Desktop Detailed' ? 'xm:py-6 xm:px-6 xm:min-w-[40%]' : ''}
    `
  const titleClasses = `
        z-[1] relative block ml-4
        lbody text-gray-medium [.dark_&]:text-white
        [.dark_&]:group-[.is-active]:text-black
        ${
          type === 'Questionnaire Desktop Detailed'
            ? ' group-hover:text-black group-[.is-active]:text-black [.dark_&]:group-hover:text-black  '
            : 'group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black'
        }

    `

  const descriptionClasses = `
        xsbody
        z-[1] relative block ml-4
        text-gray-medium [.dark_&]:text-white
        [.dark_&]:group-[.is-active]:text-black
        ${
          type === 'Questionnaire Desktop Detailed'
            ? ' group-hover:text-black group-[.is-active]:text-black [.dark_&]:group-hover:text-black  '
            : 'group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black'
        }

    `
  const linkClasses = `
        button mt-4 mb-0
        z-[1] relative block ml-4
        text-gray-medium [.dark_&]:text-white
        [.dark_&]:group-[.is-active]:text-black
        ${
          type === 'Questionnaire Desktop Detailed'
            ? ' group-hover:text-black group-[.is-active]:text-black [.dark_&]:group-hover:text-black  '
            : 'group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black'
        }

    `
  const iconClasses = `
        ${type === 'Bullet Points' ? '' : ''}
        z-[1] relative
        [.dark_&]:text-white
        pt-1
        ${
          type === 'Questionnaire Desktop Detailed'
            ? ' group-hover:text-black group-[.is-active]:text-black [.dark_&]:group-hover:text-black  '
            : 'group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black'
        }

        `
  const fillClasses = `
        tab-fill z-0 absolute top-0 left-0 h-full w-full
        bg-black-off [.dark_&]:bg-white
        scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
        transition duration-400 ease-linear
    `
  const listItemClasses = `
        border-b border-solid border-grey-light [.dark_&]:border-grey-medium py-5
        min-w-[80%]
        w-80
        ${isChecked ? 'bg-gray-100' : ''}
    `

  return (
    <>
      <section className="tab-list">
        <div data-cols="12">
          {type != 'Bullet Points' && ( //for boxes
            <button
              tabIndex={0}
              ref={tabElement}
              onMouseMove={(ev) => handleMouseMove(ev)}
              onMouseUp={handleClick}
              className={buttonClasses}
            >
              <div className={iconClasses}>
                <Icon name={icon.name} type={icon.type} clicked={!isChecked} />
              </div>
              <div className="flex flex-col flex-nowrap">
                <span className={titleClasses}>{title}</span>
                {type == 'Questionnaire Desktop Detailed' && (
                  <div className="pt-1">
                    <span className={descriptionClasses}>{description}</span>
                    <span className={linkClasses}>
                      {link}
                      <Icon name="arrow-east" type="stroke" className="ml-1 inline h-4" />
                    </span>
                  </div>
                )}
              </div>
              {type != 'Questionnaire Desktop Detailed' && (
                <div className={fillClasses} style={{ transformOrigin: mousePosition.left }}></div>
              )}
            </button>
          )}

          {type === 'Bullet Points' && ( //for bullet points
            <div>
              <ul>
                {title.map((item, index) => (
                  <li key={index} className={listItemClasses} onClick={() => handleListItemClick(index)}>
                    <label className="inline-flex items-center">
                      {/* <div className={iconClasses}>
                                                <Icon name={icon} type="stroke" clicked={!checkedItems[index]} />
                                            </div> */}
                      <span className={iconClasses}>
                        <Icon name="check-circle" className="text-success" />
                      </span>
                      <span className="ml-2">{item}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default Questionnaire
