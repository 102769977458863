import React, { useCallback, useEffect, useRef, useState } from 'react'
import Head from 'next/head'
import { twJoin } from 'tailwind-merge'

import { JobData } from 'components/front-end/JobDetails/_JobInterface'
import JobCTATiles from 'components/front-end/JobDetails/JobCTATiles'
import JobDetails from 'components/front-end/JobDetails/JobDetails'
import JobNextSteps from 'components/front-end/JobDetails/JobNextSteps'
import JobNotification from 'components/front-end/JobDetails/JobNotification'
import JobRole from 'components/front-end/JobDetails/JobTheRole'
import JobWaysToJoin from 'components/front-end/JobDetails/JobWaysToJoin'
import RelatedJobs from 'components/front-end/JobDetails/RelatedJobs'
import slugify from 'utils/slugifyStrings'
import { trackJobDetailsPageLoad } from 'utils/tracking'
import JobPageHeader from './front-end/JobDetails/JobPageHeader'
import { useIntersectionObserver } from 'utils/useIntersectionObserver'


export type ADFJobDetailsProps = {
  jobData: JobData
}

const jobPageTabs = {
  'The role': JobRole,
  'Role details': JobDetails,
  'Ways to join': JobWaysToJoin,
  'Next steps': JobNextSteps,
} as const

type JobPageTabLabel = keyof typeof jobPageTabs
const jobPageTabLabels = Object.keys(jobPageTabs) as JobPageTabLabel[]
const defaultTabLabel = jobPageTabLabels[0]

const ADFJobDetails = ({ jobData }: ADFJobDetailsProps) => {
  // sticky state
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: [0, 1] })
  useEffect(() => {
    document.documentElement.setAttribute('data-sticky-nav', isIntersecting ? 'false' : 'true')

    return () => {
      document.documentElement.setAttribute('data-sticky-nav', 'false')
    }
  }, [isIntersecting])
  // sticky state

  const [animateTab, setAnimateTab] = useState(false)
  const animationTimeout = useRef<NodeJS.Timeout>()

  const [currentTabKey, setCurrentTabKey] = useState<string | undefined>()
  const [nextTabKey, setNextTabKey] = useState<string | undefined>()

  useEffect(
    () => () => {
      animationTimeout.current && clearTimeout(animationTimeout.current)
    },
    []
  )

  // Tracking page load event
  useEffect(() => {
    const { title, id, isPriority } = jobData
    if (title && id) {
      trackJobDetailsPageLoad(title, id, isPriority ? 'priority to fill' : '')
    }
  }, [jobData])

  const animateClasses = twJoin('transition duration-500', animateTab ? 'delay-0 opacity-0' : 'delay-100 opacity-100')
  const animateRightClasses = twJoin(animateClasses, animateTab && 'translate-x-10')
  const animateDownClasses = twJoin(animateClasses, animateTab && 'translate-y-10')

  const currentTabLabel = jobPageTabLabels.find((label) => slugify(label) === currentTabKey) ?? defaultTabLabel

  const JobPageView = jobPageTabs[currentTabLabel]
  const showRelatedJobs = ['The role', 'Next steps'].includes(currentTabLabel)

  const handleTransitionEnd = useCallback(() => {
    setCurrentTabKey(nextTabKey)
    if (animationTimeout.current) {
      clearTimeout(animationTimeout.current)
    }
    animationTimeout.current = setTimeout(() => {
      setAnimateTab(false)
    })
  }, [nextTabKey])

  const handleChangeTabs = useCallback((tab: string) => {
    setNextTabKey((t) => (t !== tab ? tab : t))
    setAnimateTab(true)
  }, [])

  if (!jobData.title) {
    return null
  }

  return (
    <>
      <Head>
        <title>{jobData.title + ' - ADF Careers' || 'Jobs - ADF Careers'}</title>
      </Head>

      <JobPageHeader jobData={jobData} labels={jobPageTabLabels} onChange={handleChangeTabs} />

      <div className="pointer-events-none absolute inset-x-0 top-0 -z-10 h-[--navbar-height]" ref={ref} />

      <section className="pb-10 pt-6">
        {currentTabKey !== 'next-steps' && jobData.showNotificationMessage ? (
          <JobNotification
            message={jobData.notificationMessage}
            icon={jobData.notificationIcon}
            service={jobData.service}
          />
        ) : null}
      </section>

      <div className={animateClasses} onTransitionEnd={animateTab ? handleTransitionEnd : undefined}>
        <JobPageView
          id={`tabpanel-${currentTabKey ?? slugify(defaultTabLabel)}`}
          responseData={jobData}
          animateClasses={[animateRightClasses, animateDownClasses]}
        />
      </div>

      <JobCTATiles
        id={jobData.id}
        className={twJoin('mb-20', animateDownClasses)}
        service={jobData.service}
        cta={jobData.cta}
      />

      {showRelatedJobs ? (
        <RelatedJobs
          className={twJoin('mb-20', animateDownClasses)}
          header={jobData.relatedJobsHeader}
          jobs={jobData.relatedJobs}
        />
      ) : null}
    </>
  )
}

export default ADFJobDetails
