import { escapeRegExp } from 'src/utils/escapeRegexp'
import { stripHtml } from 'src/utils/stripHTML'

import type { FAQ, FAQCategory, FAQData, FAQSubcategory, FAQTag, GroupedFAQs, QuickLinkFields } from './FAQTypes'

export type FAQLookupData = {
  categories: Record<string, FAQCategory>
  subcategories: Record<string, FAQSubcategory>
  tags: Record<string, FAQTag>
  faqs: Record<string, FAQ>
  faqsInSubcategory(subcategoryId: string, faqIds?: string[]): string[]
  faqsInSubcategories(subcategoryIds: string[], faqIds?: string[]): string[]
  faqsInCategory(categoryId: string, faqIds?: string[]): string[]
  faqsInCategoryAndSubcategory(categoryId: string, subcategoryId: string, faqIds?: string[]): string[]
  subcategoriesInCategory(categoryId: string): string[]
  subcategoriesForFaqs(faqIds: string[]): string[]
  tagsForFAQs(faqIds: string[]): string[]
  faqsWithTag(tagId: string, faqIds?: string[]): string[]
  faqsWithHeading(heading: string, faqIds?: string[]): string[]
  headingsForFAQs(faqIds: string[]): string[]
  faqsGroupedByHeading(faqIds: string[]): GroupedFAQs
  faqsGroupedBySubcategory(faqIds?: string[], subcategoryIds?: string[]): GroupedFAQs
  faqsMatchingQuery(text: string): FAQ[]
}

export function buildLookupData(data: FAQData): FAQLookupData {
  // Create a record of faqs indexed by their id
  const faqs = data.faqs.reduce(
    (acc, faq) => {
      const text = stripHtml(`${faq.question.toLocaleLowerCase()} ${faq.answer.toLocaleLowerCase()}`)
      const tags = faq.tags.map((tag) => tag.slice(1, -1).toLowerCase())
      const quickLink = parseQuickLink(faq.fields)
      acc[faq.id] = { ...faq, text, tags, quickLink }
      return acc
    },
    {} as Record<string, FAQ>
  )

  // Create a record of subcategories indexed by their id, with categories and faqs initialized as empty sets
  const subcategories = data.subcategories.reduce(
    (acc, subcategory) => {
      const faqs = faqsInSubcategory(subcategory.id)
      acc[subcategory.id] = {
        ...subcategory,
        faqs,
        categories: [], // Populate the subcategory.categories array after building the categories object (below)
      }
      return acc
    },
    {} as Record<string, FAQSubcategory>
  )

  // Create a record of categories indexed by their id, with subcategories initialized as empty sets
  const categories = data.categories.reduce(
    (acc, category) => {
      const faqs = faqsInCategory(category.id)
      acc[category.id] = { ...category, subcategories: subcategoriesForFaqs(faqs) }
      return acc
    },
    {} as Record<string, FAQCategory>
  )

  // Populate the subcategory.categories array now that the categories object has been built
  Object.values(subcategories).forEach((subcategory) => {
    subcategory.categories = categoriesInFaqs(subcategory.faqs)
  })

  // Create a record of tags indexed by their id
  const tags = data.tags.reduce(
    (acc, tag) => {
      acc[tag.id] = tag
      return acc
    },
    {} as Record<string, FAQTag>
  )

  // Convenience functions for finding and referencing FAQ data
  function getFAQs(faqIds?: string[]): FAQ[] {
    return faqIds?.map((id) => faqs[id]) ?? Object.values(faqs)
  }

  function faqsInSubcategory(subcategoryId: string, faqIds?: string[]): string[] {
    return getFAQs(faqIds)
      .filter((faq) => faq.subcategories.includes(subcategoryId))
      .map(({ id }) => id)
  }

  function faqsInSubcategories(subcategoryIds: string[], faqIds?: string[]): string[] {
    return getFAQs(faqIds)
      .filter((faq) => faq.subcategories.some((subcategoryId) => subcategoryIds.includes(subcategoryId)))
      .map(({ id }) => id)
  }

  function faqsInCategory(categoryId: string, faqIds?: string[]): string[] {
    return getFAQs(faqIds)
      .filter((faq) => faq.categories.includes(categoryId))
      .map(({ id }) => id)
  }

  function faqsInCategoryAndSubcategory(categoryId: string, subcategoryId: string, faqIds?: string[]): string[] {
    return getFAQs(faqIds)
      .filter(
        (faq) => (!categoryId || faq.categories.includes(categoryId)) && faq.subcategories.includes(subcategoryId)
      )
      .map(({ id }) => id)
  }

  function faqsWithTag(tagId: string, faqIds?: string[]): string[] {
    return getFAQs(faqIds)
      .filter((faq) => faq.tags.includes(tagId))
      .map(({ id }) => id)
  }

  function faqsWithHeading(heading: string, faqIds?: string[]): string[] {
    return getFAQs(faqIds)
      .filter((faq) => faq.heading === heading)
      .map(({ id }) => id)
  }

  function categoriesInFaqs(faqIds: string[]): string[] {
    const categoryIds = new Set<string>()
    faqIds.forEach((faqId) => {
      faqs[faqId].categories.forEach((categoryId) => categoryIds.add(categoryId))
    })
    return Array.from(categoryIds)
  }

  function subcategoriesForFaqs(faqIds: string[]): string[] {
    const subcategoryIds = new Set<string>()
    faqIds.forEach((faqId) => {
      faqs[faqId].subcategories.forEach((subcategoryId) => {
        if (subcategories[subcategoryId]?.faqs.includes(faqId)) {
          subcategoryIds.add(subcategoryId)
        }
      })
    })

    return Array.from(subcategoryIds)
  }

  function subcategoriesInCategory(categoryId: string): string[] {
    const subcategoryIds = new Set<string>()
    faqsInCategory(categoryId).forEach((faqId) => {
      faqs[faqId].subcategories.forEach((subcategoryId) => subcategoryIds.add(subcategoryId))
    })
    return Array.from(subcategoryIds)
  }

  function tagsForFAQs(faqIds: string[]): string[] {
    const tagsIds = new Set<string>()
    faqIds.forEach((faqId) => {
      faqs[faqId].tags.forEach((tagId) => tagsIds.add(tagId))
    })
    return Array.from(tagsIds)
  }

  function headingsForFAQs(faqIds: string[]): string[] {
    const headings = new Set<string>()
    faqIds.forEach((faqId) => {
      headings.add(faqs[faqId].heading)
    })
    return Array.from(headings)
  }

  function faqsGroupedByHeading(faqIds: string[]): GroupedFAQs {
    const headings = headingsForFAQs(faqIds)

    return Array.from(headings).map((heading) => ({
      heading: heading,
      faqs: faqIds.map((faqId) => faqs[faqId]).filter((faq) => faq.heading === heading),
    }))
  }

  function faqsGroupedBySubcategory(faqIds?: string[], subcategoryIds?: string[]): GroupedFAQs {
    return Object.values(subcategories)
      .filter(({ id }) => !subcategoryIds || subcategoryIds.includes(id))
      .map((subcategory) => ({
        heading: subcategory.faqSubcategoryTitle,
        faqs: Object.values(faqs)
          .filter(({ id }) => !faqIds || faqIds.includes(id))
          .filter((faq) => faq.subcategories.includes(subcategory.id)),
      }))
  }

  // Returns an array of FAQs sorted by the number of times the query appears in the FAQ text
  // Records with whole-word matches always come first, followed by partial matches
  function faqsMatchingQuery(text: string): FAQ[] {
    const escapedText = escapeRegExp(text)
    const wholeWordMatches = Object.values(faqs)
      .map((faq) => ({
        faq,
        wholeWordMatchCount: (faq.text.match(new RegExp(`\\b${escapedText}\\b`, 'gi')) || []).length,
      }))
      .filter(({ wholeWordMatchCount }) => wholeWordMatchCount > 0)
      .sort((a, b) => b.wholeWordMatchCount - a.wholeWordMatchCount)
      .map(({ faq }) => faq)
    const partialMatches = Object.values(faqs)
      .map((faq) => ({
        faq,
        partialMatchCount: (faq.text.match(new RegExp(`${escapedText}`, 'gi')) || []).length,
      }))
      .filter(({ partialMatchCount, faq }) => partialMatchCount > 0 && !wholeWordMatches.includes(faq))
      .sort((a, b) => b.partialMatchCount - a.partialMatchCount)
      .map(({ faq }) => faq)
    return [...wholeWordMatches, ...partialMatches]
  }

  return {
    categories,
    subcategories,
    tags,
    faqs,
    faqsInSubcategory,
    faqsInSubcategories,
    faqsInCategory,
    faqsInCategoryAndSubcategory,
    subcategoriesInCategory,
    subcategoriesForFaqs,
    tagsForFAQs,
    faqsWithTag,
    faqsWithHeading,
    headingsForFAQs,
    faqsGroupedByHeading,
    faqsGroupedBySubcategory,
    faqsMatchingQuery,
  }
}

function parseQuickLink(fields: QuickLinkFields): FAQ['quickLink'] | undefined {
  if (!fields?.QuickLinkURL.value.href) {
    return undefined
  }

  return {
    link: {
      value: {
        ...fields.QuickLinkURL.value,
        title: fields.QuickLinkHeader.value,
        text: fields.QuickLinkDescription.value,
      },
    },
    service: fields.QuickLinkService.value || '',
    isServiceTag: !!fields.QuickLinkServiceTagIsVisible.value,
  }
}
