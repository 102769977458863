import { IconProps } from 'components/front-end/Icons/Icon'

const fillIcons = [
  'add-circle',
  'apple-store',
  'arrow-circle-down',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-circle-up',
  'author',
  'bookmark',
  'calendar',
  'chat',
  'check-circle',
  'checkbox',
  'clock',
  'close-circle',
  'comparison',
  'computer',
  'copy',
  'delete',
  'directions',
  'document',
  'download',
  'edit',
  'email',
  'empty',
  'error',
  'error-orange',
  'expand',
  'facebook',
  'flag',
  'google-play',
  'help',
  'image',
  'info-circle',
  'instagram',
  'join',
  'linkedin',
  'location',
  'map',
  'messenger',
  'my-location',
  'notifications',
  'number-1',
  'number-2',
  'number-3',
  'number-4',
  'number-5',
  'number-6',
  'number-7',
  'number-8',
  'number-9',
  'pause',
  'phone',
  'play',
  'print',
  'quote',
  'send',
  'settings',
  'share',
  'small-box',
  'star',
  'thumbs-down',
  'thumbs-up',
  'twitter',
  'upload',
  'user',
  'view',
  'warning',
  'youtube',
] as const

const strokeIcons = [
  'add-circle',
  'add',
  'arrow-circle-down',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-circle-up',
  'arrow-east',
  'arrow-north-east',
  'arrow-north',
  'arrow-north-west',
  'arrow-south-east',
  'arrow-south',
  'arrow-south-west',
  'arrow-west',
  'author',
  'back-to-top',
  'bookmark',
  'calendar',
  'cc',
  'chat',
  'check-circle',
  'check',
  'checkbox',
  'chevron-down',
  'chevron-left-double',
  'chevron-left',
  'chevron-right-double',
  'chevron-right',
  'chevron-right-triple',
  'chevron-up',
  'clock',
  'close-circle',
  'close',
  'collections',
  'comparison',
  'compass',
  'computer',
  'copy',
  'delete',
  'directions',
  'document',
  'download',
  'edit',
  'email',
  'empty-box',
  'error',
  'expand',
  'facebook',
  'filter',
  'flag',
  'headphones',
  'help-circle',
  'image',
  'info-circle',
  'instagram',
  'join',
  'lightning',
  'linkedin',
  'location',
  'map',
  'menu',
  'messenger',
  'minus',
  'my-location',
  'notifications',
  'number-1',
  'number-2',
  'number-3',
  'number-4',
  'number-5',
  'number-6',
  'number-7',
  'number-8',
  'number-9',
  'open-drawer',
  'open-in-full',
  'open-new',
  'pause',
  'phone',
  'play-next',
  'play-previous',
  'play',
  'print',
  'quote',
  'radio-selected',
  'radio-unselected',
  'refresh',
  'satisfied',
  'search',
  'send',
  'settings',
  'share',
  'shortlist',
  'small-box',
  'sound',
  'star',
  'support',
  'thumbs-down',
  'thumbs-up',
  'twitter',
  'unsatisfied',
  'upload',
  'user',
  'view',
  'warning',
  'youtube',
  'zoom-in',
  'zoom-out',
] as const

export const contentIcons = [
  'age',
  'apply',
  'benefits',
  'books',
  'bus',
  'calendar',
  'campus',
  'chat',
  'checkmark',
  'children',
  'community',
  'console',
  'desktop',
  'donation',
  'dumbell',
  'education',
  'envelope',
  'family',
  'faq',
  'flag',
  'globe',
  'goals',
  'graph',
  'handshake',
  'health-travel',
  'home',
  'honour',
  'humanity',
  'identification',
  'information',
  'laundry',
  'lesson',
  'location',
  'luggage',
  'medical',
  'money',
  'ocean',
  'options',
  'photo',
  'progress',
  'pushup',
  'qualification',
  'research',
  'run',
  'scales',
  'scholarship',
  'search',
  'services',
  'situp',
  'skills',
  'superannuation',
  'support',
  'team',
  'technology',
  'timer',
  'trophy',
] as const

export type FillIcons = (typeof fillIcons)[number]
export type StrokeIcons = (typeof strokeIcons)[number]
export type ContentIcons = (typeof contentIcons)[number]
export type IconNames = FillIcons | StrokeIcons | ContentIcons

export type IconType<T extends FillIcons | StrokeIcons | ContentIcons> =
  | (T extends FillIcons ? { [K in FillIcons]: 'fill' }[T] : never)
  | (T extends StrokeIcons ? { [K in StrokeIcons]: 'stroke' }[T] : never)
  | (T extends ContentIcons ? { [K in ContentIcons]: 'content' }[T] : never)

const filled: string[] = [...fillIcons].sort()
const stroked: string[] = [...strokeIcons].sort()
const content: string[] = [...contentIcons].sort()

export const data: Record<string, IconProps[]> = {
  'Actions fill 24px': filled.filter((i) => stroked.includes(i)).map((name: FillIcons) => ({ name, type: 'fill' })),
  'Actions stroke 24px': stroked
    .filter((i) => filled.includes(i))
    .map((name: StrokeIcons) => ({ name, type: 'stroke' }) as IconProps),
  'Actions coloured - (Example option)': [
    {
      name: 'arrow-circle-up',
      className: '!text-tri-service',
    },
    {
      name: 'arrow-circle-down',
      className: '!text-army',
    },
    {
      name: 'arrow-circle-left',
      className: '!text-navy',
    },
    {
      name: 'arrow-circle-right',
      className: '!text-air-force',
    },
    {
      name: 'arrow-circle-up',
      type: 'stroke',
      className: '!text-tri-service',
    },
    {
      name: 'arrow-circle-down',
      type: 'stroke',
      className: '!text-army',
    },
    {
      name: 'arrow-circle-left',
      type: 'stroke',
      className: '!text-navy',
    },
    {
      name: 'arrow-circle-right',
      type: 'stroke',
      className: '!text-air-force',
    },
  ],
  'Actions - fill-only [computed] + height/width (48px)': fillIcons
    .filter((i) => !stroked.includes(i))
    .map((name: FillIcons) => ({ name, type: 'fill', height: 48, width: 48 })),
  'Actions - fill-only + height/width (48px)': [
    {
      name: 'youtube',
      height: 48,
      width: 48,
    },
    {
      name: 'facebook',
      height: 48,
      width: 48,
    },
    {
      name: 'instagram',
      height: 48,
      width: 48,
    },
    {
      name: 'twitter',
      height: 48,
      width: 48,
    },
    {
      name: 'messenger',
      height: 48,
      width: 48,
    },
    {
      name: 'linkedin',
      height: 48,
      width: 48,
    },
  ],
  'Actions - Stroke-only [computed] (24px) ': strokeIcons
    .filter((i) => !filled.includes(i))
    .map((name: StrokeIcons) => ({ name, type: 'stroke', height: 24, width: 24 })),
  'Actions - Stroke-only [remove after verifying] (24px)': [
    {
      name: 'arrow-north',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'arrow-south',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'arrow-east',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'arrow-west',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'arrow-south-east',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'arrow-south-west',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'arrow-north-east',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'arrow-north-west',
      type: 'stroke',
      className: '!text-adf-primary',
    },
    {
      name: 'add',
      type: 'stroke',
    },
    {
      name: 'check',
      type: 'stroke',
    },
    {
      name: 'chevron-down',
      type: 'stroke',
    },
    {
      name: 'chevron-up',
      type: 'stroke',
    },
    {
      name: 'chevron-left',
      type: 'stroke',
    },
    {
      name: 'chevron-right',
      type: 'stroke',
    },
    {
      name: 'chevron-left-double',
      type: 'stroke',
    },
    {
      name: 'chevron-right-double',
      type: 'stroke',
    },
    {
      name: 'chevron-right-triple',
      type: 'stroke',
    },
    {
      name: 'close',
      type: 'stroke',
    },
    {
      name: 'compass',
      type: 'stroke',
    },
    {
      name: 'expand',
      type: 'stroke',
    },
    {
      name: 'comparison',
      type: 'stroke',
    },
    {
      name: 'support',
      type: 'stroke',
    },
    {
      name: 'sound',
      type: 'stroke',
    },
    {
      name: 'lightning',
      type: 'stroke',
    },
    {
      name: 'filter',
      type: 'stroke',
    },
    {
      name: 'menu',
      type: 'stroke',
    },
    {
      name: 'minus',
      type: 'stroke',
    },
    {
      name: 'open-drawer',
      type: 'stroke',
    },
    {
      name: 'open-new',
      type: 'stroke',
    },
    {
      name: 'radio-selected',
      type: 'stroke',
    },
    {
      name: 'radio-unselected',
      type: 'stroke',
    },
    {
      name: 'refresh',
      type: 'stroke',
    },
    {
      name: 'search',
      type: 'stroke',
    },
    {
      name: 'shortlist',
      type: 'stroke',
    },
    {
      name: 'satisfied',
      type: 'stroke',
    },
    {
      name: 'unsatisfied',
      type: 'stroke',
    },
  ],
  'Transitions on click': [
    {
      name: 'search',
      type: 'stroke',
      clicked: true,
    },
    {
      name: 'play',
      type: 'stroke',
      clicked: true,
    },
    {
      name: 'menu',
      type: 'stroke',
      clicked: true,
    },
    {
      name: 'add',
      type: 'stroke',
      clicked: true,
    },
    {
      name: 'chevron-down',
      type: 'stroke',
      clicked: true,
    },
    {
      name: 'radio-selected',
      type: 'stroke',
      clicked: true,
    },
    {
      name: 'checkbox',
      type: 'stroke',
      clicked: true,
    },
  ],
  'Transitions on hover': [
    {
      name: 'chevron-right-double',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'filter',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'delete',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'upload',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'download',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'arrow-east',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'directions',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'refresh',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'open-new',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'location',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'chat',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'shortlist',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'my-location',
      type: 'stroke',
      hover: true,
    },
    {
      name: 'sound',
      type: 'stroke',
      hover: true,
    },
  ],
  'Icon sizes by Tailwind': [
    {
      name: 'my-location',
      type: 'stroke',
      className: 'size-3.5',
    },
    {
      name: 'notifications',
      type: 'stroke',
      className: 'size-6',
    },
    {
      name: 'pause',
      type: 'stroke',
      className: 'size-[34px]',
    },
    {
      name: 'phone',
      type: 'stroke',
      className: 'size-11',
    },
    {
      name: 'play',
      type: 'stroke',
      className: 'size-[54px]',
      clicked: true,
    },
    {
      name: 'print',
      type: 'stroke',
      className: 'size-3.5',
    },
    {
      name: 'quote',
      type: 'stroke',
      className: 'size-6',
    },
    {
      name: 'send',
      type: 'stroke',
      className: 'h-6 w-[34px]',
    },
    {
      name: 'settings',
      type: 'stroke',
      className: 'size-11',
    },
    {
      name: 'share',
      type: 'stroke',
      className: 'size-[54px]',
    },
  ],
  'Icon load test (Do repeated dynamic imports fail)': Array(64).fill({
    name: 'chevron-right-double',
    type: 'stroke',
    hover: true,
  }),
  'Content animated + on scroll': content.map((name: ContentIcons) => ({ name, type: 'content' })),
}

export default data
