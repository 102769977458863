import { IconNames, IconType } from 'components/front-end/Icons/icon-data'
import Icon from '../Icons/Icon'

type ListIconProps<T extends IconNames = IconNames> = {
  iconName: T
  iconType: IconType<T>
  titleText?: string
  text: string
  isHorizontal?: boolean
  service?: string
}

const ListIcon = ({
  text,
  titleText = '',
  iconName,
  iconType,
  isHorizontal = true,
  service,
}: ListIconProps): JSX.Element => {
  return (
    <div role="listitem" className={`flex gap-x-6 gap-y-4 ${isHorizontal ? 'flex-row' : 'flex-col'}`}>
      <Icon name={iconName} type={iconType} service={service} />
      <div className="flex flex-col justify-center">
        {titleText && <h4 className="h6">{titleText}</h4>}
        {text && <p className={`lbody text-grey-medium [.dark_&]:text-white`}>{text}</p>}
      </div>
    </div>
  )
}

export default ListIcon
