import { useEffect, useRef } from 'react'

interface UseIntentionalHoverConfig {
  onHover: () => void
  onLeave?: () => void
  velocityThreshold?: number
  durationThreshold?: number
}

function useIntentionalHover<T extends HTMLElement = HTMLElement>({
  onHover,
  onLeave,
  velocityThreshold = 0.1,
  durationThreshold = 10,
}: UseIntentionalHoverConfig): React.RefObject<T> {
  const elementRef = useRef<T>(null)
  const lastPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
  const lastTime = useRef<number>(0)
  const timeoutId = useRef<number | null>(null)
  const controllerRef = useRef<AbortController | null>(null)

  useEffect(() => {
    const element = elementRef.current
    if (!element) return

    const handleMouseMove = (event: MouseEvent) => {
      const currentTime = Date.now()
      const { clientX, clientY } = event

      if (lastTime.current) {
        const deltaTime = currentTime - lastTime.current
        const deltaX = clientX - lastPosition.current.x
        const deltaY = clientY - lastPosition.current.y

        const velocity = Math.sqrt(deltaX * deltaX + deltaY * deltaY) / deltaTime

        if (velocity <= velocityThreshold) {
          if (timeoutId.current) clearTimeout(timeoutId.current)
          timeoutId.current = window.setTimeout(() => {
            onHover()
            controllerRef.current?.abort()
            controllerRef.current = new AbortController()
            element.addEventListener('mouseleave', handleMouseLeave, { signal: controllerRef.current.signal })
          }, durationThreshold)
        } else {
          if (timeoutId.current) clearTimeout(timeoutId.current)
        }
      }

      lastPosition.current = { x: clientX, y: clientY }
      lastTime.current = currentTime
    }

    const handleMouseLeave = () => {
      onLeave?.()
      controllerRef.current?.abort()
      controllerRef.current = new AbortController()
      element.addEventListener('mousemove', handleMouseMove, { signal: controllerRef.current.signal })
    }

    controllerRef.current = new AbortController()
    element.addEventListener('mousemove', handleMouseMove, { signal: controllerRef.current.signal })

    return () => {
      controllerRef.current?.abort()
      if (timeoutId.current) clearTimeout(timeoutId.current)
    }
  }, [onHover, onLeave, velocityThreshold, durationThreshold])

  return elementRef
}

export default useIntentionalHover
