import { useRef, useState } from 'react'
import { Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs'

import slugify from 'utils/slugifyStrings'
import { truncate } from 'utils/truncate'

type MainTabProps = {
  title: TextField
  onClick?: () => void
  isExperience?: boolean
  isSelected?: boolean
}

const PageTab = ({ title, onClick, isExperience, isSelected }: MainTabProps): JSX.Element | null => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  })

  const tabElement = useRef<HTMLButtonElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const offsetLeft = tabElement?.current?.getBoundingClientRect().left || 0
    const finalX = ev.pageX - offsetLeft

    setMousePosition({ left: finalX, top: ev.pageY })
  }

  const buttonClasses = `
        page-tab-button
        ${isSelected ? 'is-active' : ''}
        group z-0 relative
        flex flex-col items-start shrink-0
        ml-5 mr-5 first:ml-0 last:mr-0
        pb-[15px] md:pb-5 pt-[15px] md:pt-5
        text-left overflow-hidden transition duration-400
    `
  const titleClasses = `
        sbody md:mbody
        z-[1] relative block
        text-grey-medium group-[.is-active]:text-black [.dark_&]:text-white [.dark_&]:group-[.is-active]:text-white
        overflow-hidden text-ellipsis whitespace-nowrap max-w-[250px]
    `
  const fillClasses = `
        tab-fill z-0 absolute bottom-0 left-0 h-1 w-full
        bg-black-off [.dark_&]:bg-white
        scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
        transition duration-400 ease-linear
    `

  return typeof title?.value === 'string' ? (
    <button
      tabIndex={0}
      ref={tabElement}
      onMouseMove={(ev) => handleMouseMove(ev)}
      // onMouseUp is not passing through to ADFSideTab, switching back to onClick, this also fixes accessibly issues for people using screen readers on mobile
      onClick={onClick}
      onMouseUp={isExperience ? () => onClick?.() : undefined}
      className={buttonClasses}
      role="tab"
      id={`tab-${slugify(title.value)}`}
      aria-controls={`tabpanel-${slugify(title.value)}`}
      aria-selected={isSelected}
    >
      {isExperience ? (
        <Text className={titleClasses} field={title} />
      ) : (
        <span className={titleClasses}>{truncate(title.value, 26)}</span>
      )}
      <div className={fillClasses} style={{ transformOrigin: mousePosition.left }}></div>
    </button>
  ) : null
}

export default PageTab
