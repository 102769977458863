import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs'

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'action'
  | 'tertiary'
  | 'tertiary-action'
  | 'primary-icon'
  | 'secondary-icon'

export type ButtonProps = {
  link: LinkField
  onClick?: () => void
  iframeLink?: LinkField
  videoLink?: string
  theme?: 'dark' | 'light'
  type?: ButtonTypes
  icon?: string
  service?: string
  target: string
  displayName: string
  imageUrl: string
  imageAlt: string
}

// const hoverColor = {
//   'tri-service': 'group-hover:border-tri-service',
//   'army': 'group-hover:border-army',
//   'air-force': 'group-hover:border-air-force',
//   'navy': 'group-hover:border-navy',
// }

const ProfileButton = (props: ButtonProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props = props
  //const hoverColorClass = hoverColor[service as keyof typeof hoverColor];
  return (
    <></>
    // <a href={fields.url} target={target} className={`adf-button-profile flex flex-col items-center no-underline group`}>
    //   <Image className={`profile-image ${hoverColorClass} border-solid border-[3px] object-cover`} src={`/${imageUrl}`} width="48" height="48" alt={imageAlt} />
    //   <span className='profile-name button pt-3 text-black'>{displayName}</span>
    // </a>
  )
}

//export default withDatasourceCheck()<ProfileButtonProps>(ProfileButton);
export default ProfileButton
