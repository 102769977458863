import { Field, LinkField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs'

import { ButtonProps, ButtonTypes } from '../Buttons/Button'

export type StudyKeys = 'ADFA' | 'DUS'

type WeightedMultiChoiceAnswer = {
  id: string
  name: string
  answerText: { jsonValue: Field<string> }
  answerScoreKeyList: {
    count: number
    targetItems: {
      weightedScoreKey: {
        value: string
      }
    }[]
  }
}

type WeightedMultiChoiceQuestion = {
  id: string
  name: string
  questionText: {
    jsonValue: Field<string>
  }
  options: {
    results: WeightedMultiChoiceAnswer[]
  }
}

type WeightedResultSections<TKey = string> = {
  total: number
  results: {
    id: string
    name: TKey
    weightedScoreKey: Field<TKey>
    title: { jsonValue: Field<string> }
    description: { jsonValue: Field<string> }
    weightedScoreKeyPoints: { jsonValue: RichTextField }
    ctaButton: { jsonValue: LinkField }
    ctaButtonIcon: Field<ButtonProps['icon']>
    ctaButtonType: Field<ButtonTypes>
  }[]
}

type WeightedResult = {
  id: string
  name: string
  title: {
    jsonValue: Field<string>
  }
  description: {
    jsonValue: Field<string>
  }
  sections: WeightedResultSections<StudyKeys>
}

export type WeightedQuestionnaireFields = {
  data: {
    datasource: {
      id: string
      questionnaireTitle: { jsonValue: Field<string> }
      questionnaireDescription: { jsonValue: Field<string> }
      previousButtonText: Field<string>
      nextButtonText: Field<string>
      submitButtonText: Field<string>
      resetButtonText: Field<string>
      questions: {
        total: number
        results: WeightedMultiChoiceQuestion[]
      }
      resultSection: {
        results: WeightedResult[]
      }
    }
  }
}

export type WeightedQuestionnaireFormState = {
  index: number
  answers: { id: string; value: string }[]
  scores?: Record<string, number>
  error?: string | false
}

export type WeightedQuestionnaireButtonLabels = Pick<
  WeightedQuestionnaireFields['data']['datasource'],
  'nextButtonText' | 'previousButtonText' | 'submitButtonText' | 'resetButtonText'
>

type WeightedQuestionnaireFormAction =
  | { type: 'ANSWER'; payload: { id: string; value: string } }
  | { type: 'NEXT' }
  | { type: 'PREVIOUS' }
  | { type: 'RESET'; payload: WeightedQuestionnaireFormState }

export function weightedQuestionnaireReducer(
  state: WeightedQuestionnaireFormState,
  action: WeightedQuestionnaireFormAction
): WeightedQuestionnaireFormState {
  const allAnswered = state.answers.every((answer) => answer.value)

  switch (action.type) {
    case 'ANSWER':
      const { id, value } = action.payload
      const answers = [...state.answers]
      answers[state.index] = { id, value }
      return { ...state, error: false, answers }
    case 'PREVIOUS':
      return {
        ...state,
        error: state.index === 0 && state.error,
        index: Math.max(state.index - 1, 0),
      }
    case 'NEXT':
      if (state.answers[state.index].value) {
        const scores = allAnswered ? calculateScore(state.answers) : undefined
        return { ...state, error: false, index: Math.min(state.index + 1, state.answers.length), scores }
      }
      return { ...state, error: 'Please select an answer' }
    case 'RESET':
      return action.payload
    default:
      return state
  }
}

export type ParsedQuestions = ReturnType<typeof parseQuestions>
export function parseQuestions(questions: WeightedMultiChoiceQuestion[]) {
  return questions.map(({ questionText, options, ...question }) => ({
    ...question,
    questionText: questionText.jsonValue.value,
    options: options.results.map(({ answerText, answerScoreKeyList, ...rest }) => ({
      ...rest,
      option: {
        label: answerText.jsonValue.value,
        value: answerScoreKeyList.targetItems.map(({ weightedScoreKey }) => weightedScoreKey.value).join(','),
      },
    })),
  }))
}

export function parseResults(results: WeightedResult[]) {
  return results
    .flatMap(({ sections }) =>
      sections.results.map(
        ({
          title,
          description,
          weightedScoreKey,
          weightedScoreKeyPoints,
          ctaButton,
          ctaButtonIcon,
          ctaButtonType,
          ...rest
        }) => ({
          ...rest,
          title: title.jsonValue.value,
          description: description.jsonValue.value,
          weightedScoreKey: weightedScoreKey.value,
          weightedScoreKeyPoints: weightedScoreKeyPoints.jsonValue,
          cta: {
            'data-trackingid': '',
            link: ctaButton.jsonValue,
            icon: ctaButtonIcon.value,
            type: ctaButtonType.value,
          },
        })
      )
    )
    .sort((a, b) => a.name.localeCompare(b.name))
}

export type ParsedQuestionnaireResults = ReturnType<typeof parseResults>

export const calculateScore = (answers: WeightedQuestionnaireFormState['answers']) => {
  const talliedScores = answers
    .flatMap(({ value }) => value.split('|')[0].split(','))
    .reduce(
      (option, tally) => {
        if (!option[tally]) option[tally] = 0
        option[tally]++
        return option
      },
      {} as Record<string, number>
    )
  const total = Object.values(talliedScores).reduce((total, score) => total + score, 0)
  const matches = Object.entries(talliedScores).map(([key, value]) => [key, (value / total) * 100])
  return Object.fromEntries(matches) as Record<string, number>
}
