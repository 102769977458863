import React, { useEffect, useState } from 'react'
import { ComponentParams, Field } from '@sitecore-jss/sitecore-jss-nextjs'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { EventRegoForm } from 'components/ADFEventHome'
import SectionTabs from 'src/components/front-end/Tabs/SectionTabs'
import NotFound from 'src/NotFound'
import ErrorPage from 'src/pages/_error'
import { EventSearchResult } from './ADFEventSearchResults'
import usePageContext from './front-end/_layoutContext'
import AboutSection from './front-end/Events/event-details/AboutSection'
import DetailsTab from './front-end/Events/event-details/DetailsTab'
import LocationTab from './front-end/Events/event-details/LocationTab'
import RegisterTab from './front-end/Events/event-details/RegisterTab'
import SimilarEvents from './front-end/Events/event-details/SimilarEvents'
import EventDrawer from './front-end/Events/EventDrawer'
import { FAQData } from './front-end/FAQ/FAQTypes'

interface Fields {
  EventRegistrationForm: EventRegoForm
  EventFaqTitle: Field<string>
  EventFaqText: Field<string>
  FaqTopicListTitle: Field<string>
  items: FAQData
}

export interface EventDetailResultsResponse {
  AttendedCount: number
  AvailableInPersonRegistrant: boolean
  AvailableOnlineRegistrant: boolean
  EOIOnlyEvent: boolean
  EnableDietaryRequirements: boolean
  EnableInSearchResults: boolean
  EventAdminStatus: string
  EventBannerImage: EventImage
  EventCapacity: number
  EventCanRegister: boolean
  EventStartDateFormattedText: string
  EventEndDateFormattedText: string
  EventClosingDateFormattedText: string
  EventCategories: string | null
  EventCategoryIds: string[]
  EventClosingDate: string
  EventClosingFormattedDate: string
  EventCode: string
  EventContactEmail: string
  EventContactName: string
  EventContactPhone: string
  EventContent: string
  EventDFRCLocation: string | null
  EventDFRCLocationId: string
  EventDisableEmailComms: boolean
  EventEnableCapacity: boolean
  EventEnableClosingDate: boolean
  EventEnableEOIForms: boolean
  EventEnableEventContact: boolean
  EventEnableExternalRegistration: boolean
  EventEndDate: string
  EventEndTime: string
  EventEndFormattedDate: string
  EventEoiDisplayName: string
  EventEoiId: string | null
  EventExternalRegistrationUrl: string
  EventFormId: string
  EventInternalName: string
  EventIsHybrid: boolean
  EventIsReserve: boolean
  EventIsVirtual: boolean
  EventLocation: EventLocation
  EventLocationType: string
  EventManagerEmail: string
  EventName: string
  EventPIN: string
  EventPhone: string
  EventRegion: string
  EventServiceId: string
  EventSiteDetailDisplayName: string
  EventSiteDetailSystemName: string
  EventSiteId: string
  EventStartDate: string
  EventStartTime: string
  EventStartFormattedDate: string
  EventState: string
  EventStateId: string
  EventStatus: string
  EventStatusId: string
  EventSubHeading: string
  EventThumbnailImage: EventImage
  EventTimeZoneId: string
  EventTimeZoneName: string
  EventTotalAttendees: string | null
  EventTotalInPersonAttendees: string | null
  EventTotalOnlineAttendees: string | null
  EventTrackingId: string | null
  EventType: EventType
  EventTypeId: string
  EventVenue: string
  EventVirtualCapacity: number
  EventVirtualMeetingLink: string
  EventVirtualMeetingScheme: string | null
  EventVirtualTimes: string[]
  HideTimeDetailsForMultiDaysEvent: boolean
  Id: string
  IsFull: boolean
  LastModified: string
  LastModifiedBy: string
  LastPublishedDate: string
  RegistrantCount: number
  RegistrantInPersonCount: number
  RegistrantOnlineCount: number
  RemainingCapacity: number
  RemainingInPersonCapacity: number
  RemainingOnlineCapacity: number
  SimilarEvents: EventSearchResult[]
  SocialShareMetadata: string | null
}

export interface EventImage {
  Domain: string
  Id: string
  ImageName: string
  ImagePath: string
  UpdateDate: string
}

export interface EventLocation {
  City: string
  Country: string
  Id: string
  Latitude: string
  Line1: string
  Line2: string | null
  Line3: string | null
  Longitude: string
  Name: string
  PostCode: string
  State: string
  StateId: string
}

export interface EventType {
  FormUrl: string
  Id: string
  TypeName: string
}

export interface EventDetailSearchRequest {
  eventCode: string
}

export type EventProps = {
  params: ComponentParams
  fields: Fields
}

export type DetailProps = {
  event: EventDetailResultsResponse
  deliveryAllocated?: string | undefined
  openEventDrawer: () => void
  onlineEvent?: boolean
}

const Default = (props: EventProps) => {
  const router = useRouter()
  const containerStyles = `
    relative  mb-10 pt-20
    ${props.params?.GridParameters ?? ''}
    ${props.params?.styles ?? ''}
  `
  const { pageContext } = usePageContext()
  const [eventcode, setEventcode] = useState('')
  const [openEventDrawer, setEventOpenDrawer] = useState(false)
  const [response, setResponse] = useState<EventDetailResultsResponse>()
  const [eventLoading, setEventLoading] = useState(true)

  const [mainEvent, setMainEvent] = useState<EventSearchResult>()
  const [activeEvent, setActiveEvent] = useState<EventSearchResult>()
  const [error, setError] = useState('')
  const [showNotFound, setShowNotFound] = useState(false)
  const [showServerError, setShowServerError] = useState(false)

  useEffect(() => {
    setEventLoading(true)
    const eventDJDetails = router?.asPath?.match(/\/events\/events-detail\/([^\/]*)\/*/)
    const eventExpDetails = router?.asPath?.match(/\/events-and-experiences\/events-detail\/([^\/]*)\/*/)
    const eventDetails = eventDJDetails ?? eventExpDetails
    if (eventDetails?.length ?? 0 > 0) {
      let eventCodeValue = eventDetails?.[1] ?? ''
      if (eventCodeValue.indexOf('?') >= 0) {
        eventCodeValue = eventCodeValue.split('?')[0]
      }
      setEventcode(eventCodeValue)
    }
  }, [router?.asPath])

  useEffect(() => {
    const newSearchController = new AbortController()
    let ignore = false

    if (eventcode) {
      const eventDetailSearchRequest: EventDetailSearchRequest = {
        eventCode: eventcode,
      }

      fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/eventfinder/events/searchEvent`, {
        signal: newSearchController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventDetailSearchRequest),
      })
        .then((response) => {
          if (ignore) {
            return
          }
          if (response.status === 200) {
            return response.json()
          } else if (response.status === 404) {
            setEventLoading(false)
            setShowNotFound(true)
            return Promise.reject(new Error('Not Found'))
          } else {
            setEventLoading(false)
            setShowServerError(true)
            return Promise.reject(new Error('Server Error'))
          }
        })
        .then((r: EventDetailResultsResponse) => {
          if (ignore) {
            return
          }
          if (!r) {
            return
          }
          setResponse(r)
          const eventData = {
            EventEnableClosingDate: r.EventEnableClosingDate,
            EventIsHybrid: r.EventIsHybrid,
            EventIsReserve: r.EventIsReserve,
            EventIsVirtual: r.EventIsVirtual,
            EventLocationType: r.EventLocationType,
            EventState: r.EventLocation.State,
            EventTimeZone: '',
            EventTimeZoneName: r.EventTimeZoneName,
            EventVenue: r.EventVenue,
            EventVirtualMeetingLink: r.EventVirtualMeetingLink,
            EnableDietaryRequirements: r.EnableDietaryRequirements,
            eventCapacity: r.EventCapacity,
            eventCapacityIsFull: r.IsFull,
            EventCanRegister: r.EventCanRegister,
            EventStartDateFormattedText: r.EventStartDateFormattedText,
            EventEndDateFormattedText: r.EventEndDateFormattedText,
            EventClosingDateFormattedText: r.EventClosingDateFormattedText,
            eventCode: r.EventCode,
            eventEndDate: r.EventEndDate,
            EventEndTime: r.EventEndTime,
            eventStartDate: r.EventStartDate,
            EventStartTime: r.EventStartTime,
            eventStatus: r.EventStatus,
            eventType: r.EventType.TypeName,
            eventVirtualCapacity: r.EventVirtualCapacity,
            hideTimeDetailsForMultiDaysEvent: r.HideTimeDetailsForMultiDaysEvent,
            id: r.Id,
            image: r.EventBannerImage.ImagePath,
            inPersonRegistrants: r.RegistrantInPersonCount,
            locationCity: r.EventLocation.City,
            locationName: r.EventLocation.Name,
            locationState: r.EventLocation.State,
            onlineRegistrants: r.RegistrantOnlineCount,
            registrants: r.RegistrantCount,
            service: r.EventSiteDetailDisplayName,
            title: r.EventName,
            url: '',
          }
          setMainEvent(eventData)
          setActiveEvent(eventData)
          setEventLoading(false)
        })
        .catch((error) => {
          if (ignore) {
            return
          }
          setEventLoading(false)
          setError(error.message)
        })
    }
    return () => {
      ignore = true
    }
  }, [eventcode])

  if (showNotFound) {
    pageContext.errorState.setIsNotFound(true)
    return <NotFound heading="404: Event Not Found" />
  }

  if (showServerError) {
    return <ErrorPage />
  }

  if (!response) {
    return null
  }

  if (error) {
    return <p>{error}</p>
  }

  const closeEventDrawer = () => {
    setEventOpenDrawer(false)
    // set focus back to the event card
    if (!!response) {
      document.getElementById(response.Id)?.getElementsByTagName('a')[0]?.focus()
    }
  }

  const handleOpenEvent = () => {
    setActiveEvent(mainEvent)
    setEventOpenDrawer(true)
  }

  const handleOpenSimilarEvent = (eventData: EventSearchResult) => {
    setActiveEvent(eventData)
    setEventOpenDrawer(true)
  }

  const { AvailableInPersonRegistrant, AvailableOnlineRegistrant, EventIsHybrid } = response
  const virtualCapacityFilled = EventIsHybrid && !AvailableOnlineRegistrant
  const inPersonCapacityFilled = EventIsHybrid && !AvailableInPersonRegistrant
  const deliveryAllocated = inPersonCapacityFilled ? 'inPerson' : virtualCapacityFilled ? 'virtual' : undefined

  const eventSectionTabs = [
    {
      title: 'Event details',
      content: (
        <DetailsTab
          event={response}
          deliveryAllocated={deliveryAllocated}
          openEventDrawer={handleOpenEvent}
          faqs={props.fields}
        />
      ),
    },
    {
      title: 'How to attend',
      content: <RegisterTab event={response} deliveryAllocated={deliveryAllocated} openEventDrawer={handleOpenEvent} />,
    },
    {
      title: 'Location',
      content: (
        <LocationTab
          event={response}
          deliveryAllocated={deliveryAllocated}
          openEventDrawer={handleOpenEvent}
          onlineEvent={Boolean(mainEvent?.EventIsVirtual || mainEvent?.EventIsHybrid)}
        />
      ),
    },
  ]

  return (
    <>
      <Head>
        <title>ADF Careers - {response.EventName}</title>
        <meta name="description" content={response.EventSubHeading} />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}${response?.EventBannerImage?.ImagePath}`} />
        <meta property="og:title" content={response.EventName} />
        <meta property="og:description" content={response.EventSubHeading} />
      </Head>

      {!eventLoading && !!activeEvent ? (
        <div className={containerStyles}>
          <AboutSection event={response} openEventDrawer={handleOpenEvent} />
          <SectionTabs tabs={eventSectionTabs} />

          {response.SimilarEvents.length > 0 && (
            <SimilarEvents events={response.SimilarEvents} openEventDrawer={handleOpenSimilarEvent} />
          )}
          <EventDrawer
            activeEvent={activeEvent}
            eventFormFields={props.fields.EventRegistrationForm.fields}
            isOpen={openEventDrawer}
            closeDrawer={() => closeEventDrawer()}
          />
        </div>
      ) : null}
    </>
  )
}

export default Default
