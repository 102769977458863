import { Field, getPublicUrl, ImageField, LayoutServiceData } from '@sitecore-jss/sitecore-jss-nextjs'
import Head from 'next/head'

interface RouteFields {
  [key: string]: unknown
}

interface LayoutProps {
  layoutData: LayoutServiceData
}

function GetTitle(seoTitle: string, pageTitle: string, displayName: string) {
  const potentialTitles = [seoTitle, pageTitle, displayName]

  return potentialTitles.find((t) => t !== undefined && t.trim() !== '')
}

const publicUrl = getPublicUrl()

const Metadata = ({ layoutData }: LayoutProps) => {
  const { route } = layoutData.sitecore
  const fields = route?.fields as RouteFields
  const currentUrl = `${process.env.PUBLIC_URL}${layoutData.sitecore.context.itemPath}`
  const seoTitle = (fields?.['SeoTitle'] as Field<string>)?.value
  const keywords = (fields?.['SeoKeywords'] as Field<string>)?.value || ''
  const description = (fields?.['SeoDescription'] as Field<string>)?.value || ''
  const pageTitle = (fields?.['PageTitle'] as Field<string>)?.value
  const openGraphType = (fields?.['OpenGraphType'] as Field<string>)?.value || 'website'
  const twitterCardType = (fields?.['TwitterCardType'] as Field<string>)?.value || 'summary_large_image'
  const twitterSite = (fields?.['TwitterSite'] as Field<string>)?.value || ''
  const image = (fields?.['CtaImage'] as ImageField)?.value?.src || ''

  const title = GetTitle(seoTitle, pageTitle, route?.displayName || '')

  return (
    <Head>
      <title>{pageTitle ? `${pageTitle} - ADF Careers` : 'Page'}</title>
      <link rel="icon" href={`${publicUrl}/favicon.ico`} />
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="og:title" content={title} />
      <meta name="og:url" content={currentUrl} />
      {description && <meta name="og:description" content={description} />}
      <meta name="og:type" content={openGraphType} />
      {image && <meta name="og:image" content={`${process.env.PUBLIC_URL}${image}`} />}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content={twitterCardType} />
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={`${process.env.PUBLIC_URL}${image}`} />}
    </Head>
  )
}

export default Metadata
