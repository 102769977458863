import { ImageField } from "@sitecore-jss/sitecore-jss-nextjs"

export type ProfileTabPageProps = {
  fields: {
    data: {
      datasource: {
        children: {
          results: {
            profileID: string
            profileImage: {
              jsonValue: ImageField
            }
            profileTitle: {
              value: string
            }
            url: {
              value: string
            }
          }[]
        }
      }
    }
  }
}
