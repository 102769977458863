import React from 'react'
import { DefaultValues, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import SubmittingOverlay from './SubmittingOverlay'
import { InputOption, KeyValueDefinition } from './types'

export const parseOptionFields = (options: Array<KeyValueDefinition> = []): InputOption[] =>
  options.map((option) => ({
    id: option.id,
    key: option.fields.KeyValueDefinitionKey.value,
    value: option.fields.KeyValueDefinitionValue.value,
    label: option.fields.KeyValueDefinitionLabel.value,
  }))

interface FormProps<T extends FieldValues> {
  onSubmit?: SubmitHandler<T>
  children: React.ReactNode
  defaultValues?: DefaultValues<T>
  successMessage?: React.ReactNode
  className?: string
}
export default function Form<T extends FieldValues>(props: FormProps<T>) {
  const methods = useForm<T>({ defaultValues: props.defaultValues })
  const { handleSubmit, formState } = methods
  const hideFormContent = formState.isSubmitSuccessful && props.successMessage

  return (
    <FormProvider {...methods}>
      <SubmittingOverlay />
      <form
        onSubmit={props.onSubmit ? handleSubmit(props.onSubmit) : undefined}
        className={twMerge('flex flex-col gap-y-6', props.className)}
      >
        {!hideFormContent ? props.children : null}
      </form>
      {props.successMessage ? props.successMessage : null}
    </FormProvider>
  )
}
