import { NextPage } from 'next'
import Head from 'next/head'

interface ErrorPageProps {
  statusCode?: number | null | undefined
}

/**
 * Rendered for 500 errors on both server and client. Used only in Production mode.
 * @link https://nextjs.org/docs/advanced-features/custom-error-page#more-advanced-error-page-customizing
 */
const ErrorPage: NextPage<ErrorPageProps> = ({ statusCode }) => (
  <>
    <Head>
      <title>Error</title>
    </Head>
    <div style={{ padding: 10 }}>
      <h1>There was a problem serving the requested page.</h1>
      <p>
        {statusCode
          ? `A server-side ${statusCode} error occurred.`
          : 'Sorry, there was a problem accessing the requested page.'}
      </p>
      Please try again later, or go back to the <a href="/">homepage</a>
    </div>
  </>
)

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404

  return { statusCode }
}

export default ErrorPage
