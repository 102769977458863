import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../tailwind.config.js'

// Use type assertion to tell TypeScript that tailwindConfig is of type Config
const config = tailwindConfig as unknown as import('tailwindcss').Config

export const fullConfig = resolveConfig(config)

// Export the screens object specifically for our hooks
export const screens = tailwindConfig.theme.screens

export type ScreenKey = keyof typeof screens
