import React from 'react'
import { ComponentParams, ComponentRendering, Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

interface ContainerProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
}

// {xs: 375}
// {sm: 428}
// {md: 768}
// {xm: 1024}
// {lg: 1280}
// {xl: 1440}
// {xxl: 1920}

export const Default = ({ params, rendering }: ContainerProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const containerStyles = params?.styles ?? ''
  const styles = `${params.GridParameters} ${containerStyles}`.trimEnd()
  const gridcols = Number(params?.gridcols ?? '12')
  const distribution = params?.Distribution ?? ''

  // Experience editor doesn't position placeholder if state or useEffect is used
  const { columnSizes, columns } = (() => {
    const columnSizes = [...Array(Number(14))]
    const columns = distribution == '5-7' || distribution == '7-5' ? 2 : Number(params?.Columns ?? '2')
    if (distribution == '5-7') {
      columnSizes[0] = 5
      columnSizes[1] = 7
    } else if (params.Distribution == '7-5') {
      columnSizes[0] = 7
      columnSizes[1] = 5
    } else {
      const columnSize = gridcols / columns
      for (let i = 0; i < columns; i++) {
        columnSizes[i] = columnSize
      }
    }
    return {
      columnSizes,
      columns,
    }
  })()

  return (
    <div data-cols={12}>
      <div className={`row relative ${styles}`}>
        <>
          {[...Array(Number(14))].map((_item, i) => (
            <div
              key={i}
              className={`${columnSizes[i] ? `span-12 lg-span-${columnSizes[i]}` : ''} ${
                i >= columns ? 'hidden' : '[&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden'
              }`}
            >
              <Placeholder name={`placeholder0-${i}`} rendering={rendering} />
            </div>
          ))}
          {sitecoreContext.pageEditing && (
            <div id="grid">
              {[...Array(Number(gridcols))].map((_x, i) => (
                <div key={i}>{i + 1}</div>
              ))}
            </div>
          )}
        </>
      </div>
    </div>
  )
}
