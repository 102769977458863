import { DetailProps } from 'components/ADFEventDetails'
import Button from 'components/front-end/Buttons/Button'
import ClosedNotification from './ClosedNotification'
import DeliveryAllocated from './DeliveryAllocated'

const RegisterTab: React.FC<DetailProps> = ({ event, deliveryAllocated, openEventDrawer }) => {
  return (
    <div className="component rich-text container-grid ">
      <div className="row component-content">
        <div className="span-12 md-span-4 ">
          <h2 className="h4 normal-case">Reserve your place</h2>
          <p>Availability is limited. It’s highly recommended you reserve your spot today to avoid missing out.</p>
          {event.EventCanRegister ? (
            event.EventEnableExternalRegistration && event.EventExternalRegistrationUrl ? (
              <>
                <span className="mt-4">
                  <Button
                    data-trackingid={event.Id}
                    type="primary"
                    service={event.EventSiteDetailDisplayName}
                    link={{ value: { href: event.EventExternalRegistrationUrl, text: 'Register Now' } }}
                    icon="chevron-right-double"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </span>
                {deliveryAllocated && (
                  <DeliveryAllocated allocated={deliveryAllocated} service={event.EventSiteDetailDisplayName} />
                )}
              </>
            ) : (
              <>
                <span className="mt-4">
                  <Button
                    data-trackingid={event.Id}
                    type="primary"
                    service={event.EventSiteDetailDisplayName}
                    link={{ value: { href: '#', text: 'Register Now' } }}
                    icon="chevron-right-double"
                    button={true}
                    onClick={() => openEventDrawer()}
                  />
                </span>
                {deliveryAllocated && (
                  <DeliveryAllocated allocated={deliveryAllocated} service={event.EventSiteDetailDisplayName} />
                )}
              </>
            )
          ) : (
            <ClosedNotification
              className="mt-10"
              isClosed={event.EventStatus === 'Closed'}
              service={event.EventSiteDetailDisplayName}
            />
          )}
        </div>
        <div className="span-12 md-span-7 md-offset-1">
          <h3 className="h6 mt-6 normal-case">How to reserve your place</h3>

          <ol className="mr-auto">
            <li>Make sure you (and any guests) are available on the event date.</li>
            <li>If you have a choice of in person or virtual, decide which works best.</li>
            <li>
              Complete the{' '}
              {event.EventStatus === 'Published' && !event.IsFull ? (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault, openEventDrawer()
                  }}
                >
                  online registration form
                </a>
              ) : (
                <span className="text-grey-medium">online registration form</span>
              )}{' '}
              to reserve your place.
            </li>
            <li>You’ll receive a reservation confirmation by email.</li>
            <li>Set yourself a reminder a day or two before the event.</li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default RegisterTab
