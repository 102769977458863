import React, { useEffect, useRef } from 'react'
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import usePageContext from 'components/front-end/_layoutContext'
import Button from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'
import { IconNames } from 'components/front-end/Icons/icon-data'
import { ComponentProps } from 'lib/component-props'
import { useIntersect } from 'utils/intersection'
import { trackNavigation } from 'utils/tracking'

interface FooterLink {
  fields: {
    Link: LinkField
  }
}

interface Fields {
  Links: Array<FooterLink>
  HelpfulPagesTitle: Field<string>
  HelpfulPages: Array<FooterLink>
  ResourcesTitle: Field<string>
  Resources: Array<FooterLink>
  ConnectsTitle: Field<string>
  Connects: Array<FooterLink>
  FitnessAppTitle: Field<string>
  FitnessApp: Array<FooterLink>
  AcknowledgementTitle: Field<string>
  AcknowledgementText: Field<string>
  ContactUs: LinkField
  Disclaimer: Field<string>
}

export type FooterProps = ComponentProps & {
  fields: Fields
}

const ADFFooter = (props: FooterProps) => {
  const pageState = usePageContext().pageContext

  const containerStyles = `
    ${props.params?.GridParameters ?? ''}
    ${props.params?.styles ?? ''}
    overflow-hidden
  `
  const gridcols = Number(props.params?.gridcols ?? '12')
  const footerNodeRef = useRef<HTMLDivElement>(null)
  const footerEntry = useIntersect(footerNodeRef, '-125px 0px 0px')

  useEffect(() => {
    const entry = footerEntry?.intersectionRatio ? footerEntry?.intersectionRatio * 100 > 1 : false
    pageState.footer.setIsVisibleFooter(entry)
  }, [footerEntry, pageState.footer])
  if (pageState.errorState.isNotFound) {
    return null
  }
  return (
    <div className={`${containerStyles}`} ref={footerNodeRef}>
      <div className="h-px bg-grey-light [.dark_&]:bg-grey-medium"></div>
      <div className="col-12 relative mb-10" data-cols={gridcols}>
        <div className="row pt-10 md:pt-12 xxl:pt-[120px]">
          <div className="span-12 xm-span-4 lg-span-3">
            {/* row */}
            <div className="md:flex md:justify-between xm:flex-wrap">
              <div className="span h4 w-full md:w-auto xm:w-full [.dark_&]:text-white">
                {props?.fields?.ConnectsTitle?.value}
              </div>
              <div className="span flex w-full pt-3 md:w-auto md:self-center md:justify-self-center md:pt-0 xm:w-full xm:pt-4 lg:pt-4 xxl:pt-8">
                {props?.fields?.Connects?.map((x, i) => (
                  <Link
                    key={i}
                    className="mr-4 block last:mr-0 lg:mr-5"
                    href={x?.fields?.Link?.value?.href || ''}
                    target={x?.fields?.Link?.value?.target}
                    aria-label={x?.fields?.Link?.value?.text || ''}
                    onClick={() =>
                      trackNavigation({
                        location: 'footer',
                        path: x?.fields?.Link?.value?.text ?? '',
                      })
                    }
                  >
                    <Icon
                      name={x?.fields?.Link?.value?.text as IconNames}
                      className="size-6 text-black md:h-8 md:w-8 [.dark_&]:text-white"
                    />
                  </Link>
                ))}
              </div>
              <div className="span w-full pt-5 md:w-auto md:pt-0 md:text-right xm:w-full xm:pt-8 xm:text-left xxl:pt-10">
                <Button
                  data-trackingid={props.rendering.uid as string}
                  icon="chevron-right-double"
                  isSmall={true}
                  type="secondary"
                  onClick={() =>
                    trackNavigation({
                      location: 'footer',
                      path: 'help-contact-us',
                    })
                  }
                  link={props?.fields?.ContactUs}
                />
              </div>
            </div>
          </div>
          <div className="span-12 relative pb-10 pt-10 xm:hidden">
            <div className="absolute ml-[-200px] h-[0.5px] w-[calc(100%+400px)] bg-grey-light [.dark_&]:bg-grey-medium"></div>
          </div>
          <div className="span-12 md-span-6 xm-span-4 lg-span-3 mb-10 text-black dark:text-white md:mb-[62px] lg:mb-14">
            <div className="xm:ml-6 xm:pt-2 lg:ml-16 xl:ml-20">
              <div className="mtag">{props?.fields?.HelpfulPagesTitle?.value}</div>
              {props?.fields?.HelpfulPages?.map((x, i) => (
                <Link
                  key={i}
                  className="mt-3 block md:mt-5 xm:mt-4 lg:mt-5"
                  href={x?.fields?.Link?.value?.href || ''}
                  target={x?.fields?.Link?.value?.target}
                  onClick={() =>
                    trackNavigation({
                      location: 'footer',
                      path: x?.fields?.Link?.value?.text ?? '',
                    })
                  }
                >
                  <div className="lbody">{x?.fields?.Link?.value?.text}</div>
                </Link>
              ))}
            </div>
          </div>
          <div className="span-12 md-span-6 xm-span-4 lg-span-3 mb-10 text-black dark:text-white md:mb-[62px] lg:mb-14">
            <div className="md:ml-[31px] xm:ml-8 xm:pt-2 lg:ml-16 xl:ml-20">
              <div className="mtag">{props?.fields?.ResourcesTitle?.value}</div>
              {props?.fields?.Resources?.map((x, i) => (
                <Link
                  key={i}
                  className="mt-3 block md:mt-5 xm:mt-4 lg:mt-5"
                  href={x?.fields?.Link?.value?.href || ''}
                  target={x?.fields?.Link?.value?.target}
                  onClick={() =>
                    trackNavigation({
                      location: 'footer',
                      path: x?.fields?.Link?.value?.text ?? '',
                    })
                  }
                >
                  <div className="lbody">{x?.fields?.Link?.value?.text}</div>
                </Link>
              ))}
            </div>
          </div>
          <div className="span-12 lg-span-3">
            <div className="lg:ml-16 xl:ml-20 xl:pt-1">
              <div className="mtag text-black dark:text-white">{props?.fields?.AcknowledgementTitle?.value}</div>
              <div className="sbody pt-5 [.dark_&]:text-white">{props?.fields?.AcknowledgementText?.value}</div>
            </div>
          </div>
          <div className="span-12 relative pt-10 md:pb-10 lg:hidden">
            <div className="absolute ml-[-200px] hidden h-[0.5px] w-[calc(100%+400px)] bg-grey-light md:block [.dark_&]:bg-grey-medium"></div>
          </div>
          <div className="span-12">
            <div className="h6 mb-5 [.dark_&]:text-white">{props?.fields?.FitnessAppTitle?.value}</div>
            <div className="flex">
              {props?.fields?.FitnessApp?.map((x, i) => (
                <Link
                  key={i}
                  className="mr-3 block"
                  href={x?.fields?.Link?.value?.href || ''}
                  target={x?.fields?.Link?.value?.target}
                  aria-label={x?.fields?.Link?.value?.text || ''}
                  onClick={() =>
                    trackNavigation({
                      location: 'footer',
                      path: x?.fields?.Link?.value?.text ?? '',
                    })
                  }
                >
                  <Icon name={x?.fields?.Link?.value?.text as IconNames} className="w-auto" height={44} width={0} />
                </Link>
              ))}
              <div className="flex-grow"></div>
              <div className="hidden md:block">
                <Button
                  data-trackingid={props.rendering.uid as string}
                  link={{
                    value: {
                      text: 'back to top',
                    },
                  }}
                  button
                  icon="arrow-north"
                  type="action"
                  isSmall={true}
                  onClick={() => {
                    window?.scrollTo({ top: 0, behavior: 'smooth' })
                    trackNavigation({
                      location: 'footer',
                      path: 'back to top',
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="span-12 relative pb-10 pt-10 md:pt-12 lg:pt-16">
            <div className="absolute ml-[-200px] h-[0.5px] w-[calc(100%+400px)] bg-grey-light [.dark_&]:bg-grey-medium"></div>
          </div>
          <div className="span-12 xm-span-6">
            <div className="mbody-bold mb-4 text-black dark:text-white xm:mb-0">{props?.fields?.Disclaimer?.value}</div>
          </div>
          <div className="span-12 xm-span-6 mb-6 text-black dark:text-white md:mb-0 xm:self-center">
            <div className="flex justify-between md:justify-start xm:justify-end">
              {props?.fields?.Links?.map((x, i) => (
                <Link
                  key={i}
                  className="block md:mr-6 md:last:mr-0 xm:text-right"
                  href={x?.fields?.Link?.value?.href || ''}
                  target={x?.fields?.Link?.value?.target}
                  onClick={() =>
                    trackNavigation({
                      location: 'footer',
                      path: x?.fields?.Link?.value?.text || '',
                    })
                  }
                >
                  <div className="xsbody">{x?.fields?.Link?.value?.text}</div>
                </Link>
              ))}
            </div>
          </div>
          <div className="span-12 md:hidden">
            <Button
              data-trackingid={props.rendering.uid as string}
              link={{
                value: {
                  text: 'back to top',
                },
              }}
              button
              icon="arrow-north"
              type="action"
              isSmall={true}
              onClick={() => {
                window?.scrollTo({ top: 0, behavior: 'smooth' })
                trackNavigation({
                  location: 'footer',
                  path: 'back to top',
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ADFFooter
