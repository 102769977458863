import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { assertIsString } from './assertIsString'

export function usePathSegments() {
  const itemPath = useRouter().asPath

  const pathSegments = useMemo(() => {
    assertIsString(itemPath)
    return itemPath
      .replace(/^\/+|\/+$/g, '')
      .split('/')
      .filter(Boolean)
      .slice(0, 3)
      .reduce((acc, _, index, array) => {
        acc.push(['', ...array.slice(0, index + 1)].join('/'))
        return acc
      }, [] as string[])
  }, [itemPath])
  return pathSegments
}
