import React from 'react'
import { ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs'

interface StoryItemProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
}

export const Default = (_props: StoryItemProps): JSX.Element => {
  return <div>default</div>
}

export const TitlePanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Title Panel</div>
}

export const StatementPanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Statement panel</div>
}

export const QuotePanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Quote panel</div>
}

export const StatsPanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Stats Panel</div>
}

export const ListPanel = (_props: StoryItemProps): JSX.Element => {
  return <div>List Panel</div>
}

export const CopyPanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Copy Panel</div>
}

export const CareerCardPanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Career Card Panel</div>
}

export const MediaVideoPanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Media Video Panel</div>
}

export const MediaImagePanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Media Image Panel</div>
}

export const RankPanel = (_props: StoryItemProps): JSX.Element => {
  return <div>Rank Panel</div>
}
