import React from 'react'
import { ComponentParams } from '@sitecore-jss/sitecore-jss-nextjs'

import { SearchResult } from './ADFSearchResults'
import JobCard from './front-end/JobCard/JobCard'

interface Fields {
  Jobs: SearchResult[]
  DisplayPriorityTag: {
    value: boolean
  }
}

export type JobCardProps = {
  params: ComponentParams
  fields: Fields
}

const ADFJobCard = ({ params = {}, fields = {} as Fields }: JobCardProps): JSX.Element => {
  const containerStyles = ['relative', params.GridParameters, params.styles].filter(Boolean).join(' ')
  const { Jobs: jobs = [], DisplayPriorityTag: displayPriorityTag = { value: false } } = fields
  return (
    <div className={containerStyles}>
      {jobs.map((job) => (
        <JobCard key={job.id} jobDetails={job} displayPriorityTag={displayPriorityTag.value} />
      ))}
    </div>
  )
}

export default ADFJobCard
