import { Loader, LoaderOptions } from '@googlemaps/js-api-loader'

// Interface to override privacy settings
interface PublicLoader extends Omit<Loader, 'reset' | 'deleteScript'> {
  instance: PublicLoader | null
  reset(): void
  deleteScript(): void
}

// Extend the Loader class
export class DynamicLoader extends Loader {
  constructor(options: LoaderOptions) {
    const loaderClass = Loader as unknown as PublicLoader

    // Check if Loader.instance exists and if the apiKey is different
    if (loaderClass.instance && loaderClass.instance.options.apiKey !== options.apiKey) {
      // Call reset() and deleteScript() on the existing instance
      loaderClass.instance.reset()
      loaderClass.instance.deleteScript()

      // Set Loader.instance to null
      loaderClass.instance = null

      // Delete the google property from the global window object
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any).google
    }

    // Call the parent constructor
    super(options)
  }
}
