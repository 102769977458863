import React from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

type InfoPanelProps = {
  heading: string
  message: string
}

/**
 * A Info Panel component, with a heading and message.
 * This is to be used to provide the content editor with information about the component.
 * Such when a datasource is missing content.
 */
const InfoPanel = ({ heading, message }: InfoPanelProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  if (sitecoreContext?.pageEditing) {
    return (
      <div className="bg-blue-100 border-blue-500 text-blue-900 rounded-b border-t-4 px-4 py-3 shadow-md" role="alert">
        <div className="flex">
          <div className="py-1">
            <svg
              className="text-blue-500 mr-4 size-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 110-12 6 6 0 010 12z" />
              <path
                fillRule="evenodd"
                d="M10 4a1 1 0 011 1v4a1 1 0 01-2 0V5a1 1 0 011-1zm0 6a1 1 0 100 2 1 1 0 000-2z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div>
            <p className="font-bold">{heading || 'Sitecore'}</p>
            <p className="text-sm">{message}</p>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default InfoPanel
