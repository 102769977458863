import React from 'react'
import { ComponentParams, ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-nextjs'

interface Fields {
  Size: Field<'XL - 64px' | 'Space 64' | 'L - 48px' | 'Space 56' | 'M - 32px' | 'S - 24px'>
}

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
}

const ADFSpacer = ({ params, fields }: ComponentProps): JSX.Element => {
  const size = fields?.Size?.value ?? params?.Size
  const id = params.RenderingIdentifier ?? params.DynamicPlaceholderId
  let className = ''

  switch (size) {
    case 'XL - 64px':
    case 'Space 64':
      className = 'h-8 md:h-14 xm:h-16'
      break

    case 'L - 48px':
    case 'Space 56':
      className = 'h-6 md:h-10 xm:h-12'
      break

    case 'M - 32px':
      className = 'h-4 md:h-6 xm:h-8'
      break

    default:
      className = 'h-4 xm:h-6'
  }

  return <div className={`w-full ${className}`} id={id}></div>
}

export default ADFSpacer
