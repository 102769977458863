import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha'
import { useController } from 'react-hook-form'

import { InputProps } from './types'
import { ValidationWrapper } from './Validation'

type ReCaptchaProps = InputProps<'captcha'> &
  Pick<ReCAPTCHAProps, 'size' | 'onErrored' | 'onExpired'> & {
    sitekey?: ReCAPTCHAProps['sitekey']
    captchaType?: ReCAPTCHAProps['type']
  }

// TODO: Move this to process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
const RECAPTCHA_SITE_KEY = '6LcY_roUAAAAAGGPSYdqVht5zsBUqOX4_57i4NZ3'

const Captcha = ({ captchaType, sitekey = RECAPTCHA_SITE_KEY, ...props }: ReCaptchaProps) => {
  const { field } = useController(props)
  delete props.rules

  const handleChange = (token: string | null): void => {
    const encodedToken = Buffer.from(token ?? '').toString('base64')
    const escapedToken = encodedToken
      .replaceAll('--', 'dashdash')
      .replace(/(on)|(0x)/gi, (str) => (isNaN(+str[0]) ? str.split('').join('_') : str[1] === 'x' ? 'h_b_s' : 'H_B_S'))

    field.onChange(escapedToken)
  }

  return (
    <ValidationWrapper name={props.name} border={false}>
      <ReCAPTCHA {...props} {...field} onChange={handleChange} type={captchaType} sitekey={sitekey} />
    </ValidationWrapper>
  )
}

export default Captcha
