import React from 'react';
import { ComponentParams, ComponentRendering, Field, Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';



import { useSharedContext } from './front-end/_sharedContext';
import Drawer from './front-end/Drawers/Drawer';


interface Fields {
  PanelName: Field<string>
  Title: Field<string>
  HideFooter: Field<boolean>
}
interface DrawerProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
}

export const Default = (props: DrawerProps): JSX.Element => {
  const phKey = `container-${props.params.DynamicPlaceholderId}`
  const id = props?.params?.RenderingIdentifier

  const { sitecoreContext } = useSitecoreContext()
  const { drawers, updateDrawers } = useSharedContext()

  if (props.fields.PanelName?.value) {
    if (!drawers.has(props.fields.PanelName?.value)) {
      drawers.set(props.fields.PanelName?.value, false)
    }
  }

  return (
    <>
      {sitecoreContext.pageEditing && (
        <div id={id}>
          <h4>{props.fields.PanelName?.value ?? Drawer}</h4>
          <Placeholder name={phKey} rendering={props.rendering} />
        </div>
      )}
      {!sitecoreContext.pageEditing && (
        <Drawer
          id={props.fields.PanelName?.value}
          title={props.fields.Title?.value}
          show={drawers.get(props.fields.PanelName?.value) ?? false}
          hideFooter={props.fields.HideFooter?.value}
          closeDrawer={() => {
            drawers.set(props.fields.PanelName?.value, false)
            updateDrawers()
          }}
        >
          <Placeholder name={phKey} rendering={props.rendering} />
        </Drawer>
      )}
    </>
  )
}