import {
  ComponentParams,
  ComponentRendering,
  Field,
  LinkField,
  PlaceholdersData,
} from '@sitecore-jss/sitecore-jss-nextjs'

import { useService } from 'src/utils/useService'
import { useSharedContext } from './front-end/_sharedContext'
import QuickLink from './front-end/QuickLinks/QuickLink'

interface Fields {
  Header: Field<string>
  Description: Field<string>
  URL: LinkField
  IsDownload: Field<boolean>
  IsServiceTag: Field<boolean>
  Service: Field<string>
  PanelName: Field<string>
}

interface QuickLinksProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
  placeholders?: PlaceholdersData
}

export const Default = (props: QuickLinksProps) => {
  const { drawers, updateDrawers } = useSharedContext()
  const fields = props?.fields
  const service = useService(fields?.Service?.value)

  if (!fields) {
    return null
  }

  const isServiceTag = Boolean(fields.IsServiceTag?.value)
  const panelName = fields.PanelName?.value || ''
  const title = fields.Header?.value || ''
  const text = fields.Description?.value || ''
  const link = { value: { ...fields.URL?.value, title, text } }
  const isDownload = Boolean(fields.IsDownload)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (drawers.has(panelName)) {
      e.preventDefault()
      drawers.set(panelName, true)
      updateDrawers()
    }
  }

  return (
    <div className="mx-auto">
      <div className="my-6 bg-white-off sm:my-6 md:my-12 [.dark_&]:bg-grey-dark [.light_&]:bg-white-off">
        <QuickLink
          service={service}
          link={link}
          isServiceTag={isServiceTag}
          isDownload={isDownload}
          onClick={handleClick}
        />
      </div>
    </div>
  )
}

export default Default
