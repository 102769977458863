import { useEffect, useState } from 'react'
import { FieldArrayWithId, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { compareArrays } from 'src/utils/compareArrays'
import { stringArrayParser, useLocalStorage } from 'src/utils/useLocalStorage'
import z from 'zod'

import { FormValues } from 'components/ADFJobComparison'
import { useShortlist } from '../Shortlist/ShortlistProvider'

export type CareerOptions = z.infer<typeof careerOptionsSchema>
export type CareerOptionsWithId = FieldArrayWithId<FormValues, 'careerOptions', 'id'>[]

const careerOptionsSchema = z.array(
  z.object({
    entryMethodId: z.string().uuid(),
    selected: z.boolean(),
  })
)

export function useCareerOptions() {
  const { jobs } = useShortlist()

  const { control, formState, getValues } = useFormContext<FormValues>()
  const { replace, fields, update } = useFieldArray<FormValues>({ control, name: 'careerOptions' })

  const careerOptions = useWatch({ control, name: 'careerOptions' })

  const storedSelectedOptions = useLocalStorage('ADF-compare', stringArrayParser)
  const [selectedOptions, setSelectedOptions] = useState<string[] | null | undefined>(storedSelectedOptions)

  const careerOptionsWithId = careerOptions.map((careerOption) => ({
    ...careerOption,
    id: fields.find(({ entryMethodId }) => entryMethodId === careerOption.entryMethodId)?.id,
  })) as CareerOptionsWithId

  // JOBS LOADED OR CHANGED
  useEffect(() => {
    if (!jobs) {
      return
    }

    const currentEntryMethods = careerOptions.map(({ entryMethodId }) => entryMethodId)
    const newEntryMethods = jobs.flatMap((job) => job.entryMethods.map(({ id }) => id))

    const entryMethodsChanged = !compareArrays(newEntryMethods, currentEntryMethods, { ignoreOrder: true })

    if (entryMethodsChanged) {
      const selectedCareerOptions = readSelectedCareerOptions() ?? []

      replace(
        newEntryMethods.map((id) => ({
          entryMethodId: id,
          selected: selectedCareerOptions.includes(id),
        }))
      )
    }
  }, [careerOptions, jobs, replace])

  // CAREER OPTIONS CHANGED - UPDATE SELECTED OPTIONS
  useEffect(() => {
    if (!jobs) {
      return
    }

    setSelectedOptions((currentSelectedOptions) => {
      const newSelectedOptions = careerOptions
        .filter(({ selected }) => selected)
        .map(({ entryMethodId }) => entryMethodId)

      const selectedOptionsChanged = Boolean(
        currentSelectedOptions && careerOptions.length && currentSelectedOptions.length !== newSelectedOptions.length
      )

      return selectedOptionsChanged ? writeSelectedCareerOptions(newSelectedOptions) : currentSelectedOptions
    })
  }, [careerOptions, formState.isDirty, jobs])

  useEffect(() => {
    if (!jobs) {
      return
    }

    setSelectedOptions(fields.filter(({ selected }) => selected).map(({ entryMethodId }) => entryMethodId))
  }, [fields, jobs])

  // LOCALSTORAGE CHANGED
  useEffect(() => {
    if (!storedSelectedOptions) {
      return
    }

    if (
      !compareArrays(
        storedSelectedOptions,
        fields.filter(({ selected }) => selected).map(({ entryMethodId }) => entryMethodId),
        { ignoreOrder: true }
      )
    ) {
      const current = getValues('careerOptions')
        .filter(({ selected }) => selected)
        .map(({ entryMethodId }) => entryMethodId)
      const addedIds = storedSelectedOptions?.filter((id) => !current?.includes(id)) ?? []
      const removedIds = current?.filter((id) => !storedSelectedOptions?.includes(id)) ?? []
      const changedIds = [addedIds, removedIds].flat()

      if (changedIds.length) {
        if (changedIds.length === 1) {
          const index = fields.findIndex(({ entryMethodId }) => changedIds.includes(entryMethodId))
          const field = fields[index]
          update(index, { ...field, selected: storedSelectedOptions?.includes(field.entryMethodId) ?? false })
        } else {
          replace(
            fields.map((field) => ({
              ...field,
              selected: storedSelectedOptions?.includes(field.entryMethodId) ?? false,
            }))
          )
        }
      }
    }
  }, [storedSelectedOptions, replace, fields, update, getValues])

  return [careerOptionsWithId, selectedOptions] as const
}

function writeSelectedCareerOptions(selectedIds: string[]) {
  if (!localStorage) return []

  const storedIds = readSelectedCareerOptions() ?? []

  const keep = storedIds.filter((id) => selectedIds.includes(id))
  const add = selectedIds.filter((id) => !storedIds.includes(id))

  const selectedOptionIds = [...keep, ...add]

  localStorage.setItem('ADF-compare', JSON.stringify(selectedOptionIds))

  return selectedOptionIds
}

function readSelectedCareerOptions() {
  if (typeof localStorage === 'undefined') return

  try {
    return z.array(z.string().uuid()).parse(JSON.parse(localStorage.getItem('ADF-compare') ?? ''))
  } catch (error) {
    return undefined
  }
}
