import { ReactNode } from 'react'

import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'

interface CardCopyProps {
  service?: string
  title?: string | null
  subtitle?: string | null
  copy?: string | null
  tags?: JSX.Element
  cardType?: string | null
  children?: ReactNode | null
}

const CardCopy = ({ title, subtitle, copy, children, tags, cardType }: CardCopyProps): JSX.Element => {
  const textSubtitleClasses = `font-uniform-condensed font-medium uppercase text-grey-medium dark:text-white mb-2`

  const titleClasses = `${useGreaterThan('lg') && cardType === 'feature' ? 'h5 mb-3' : 'h6 mb-2'}`

  const copyClasses = `
    ${useGreaterThan('lg') && cardType === 'feature' ? 'mb-6 lbody' : 'mb-6 mbody'}
    ${cardType === 'compact' || cardType === 'pathway' ? 'text-grey-medium dark:text-white' : ''}
  `

  return (
    <>
      {subtitle && <p className={textSubtitleClasses}>{subtitle}</p>}

      <h6 className={titleClasses}>{title}</h6>
      {/* TODO:FIX THIS BREAKPOINT */}
      {useLessThan('xm') && tags}

      <div className={copyClasses}>
        {copy && <p className="mb-4 lg:mb-6">{copy}</p>}
        {children}
      </div>
    </>
  )
}

export default CardCopy
