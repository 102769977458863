import { createContext, useContext, useState } from 'react'

type Props = {
  children: React.ReactNode
}

export type SharedContextProps = {
  drawers: Map<string, boolean>
  updateDrawers: () => void
}

export const SharedContext = createContext<SharedContextProps>({
  drawers: new Map<string, boolean>(),
  updateDrawers: () => {
    /**/
  },
})

export default function SharedContextProvider({ children }: Props) {
  const [refresh, setRefresh] = useState(false)
  const [drawers, _setDrawers] = useState(new Map<string, boolean>())

  const ctx = {
    drawers,
    updateDrawers: () => {
      setRefresh(!refresh)
    },
  }

  return <SharedContext.Provider value={ctx}>{children}</SharedContext.Provider>
}

export function useSharedContext() {
  return useContext(SharedContext)
}
