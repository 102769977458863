import React, { useEffect, useRef, useState } from 'react'
import {
  Field,
  ImageField,
  NextImage,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs'
import Image from 'next/image'
import YouTube, { YouTubePlayer, YouTubeProps } from 'react-youtube'

import { ComponentProps } from 'lib/component-props'
import { useIntersect } from 'src/utils/intersection'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import { useService } from 'src/utils/useService'
import Button from './front-end/Buttons/Button'
import { getImagePath } from './front-end/Images/getImagePath'

interface Fields {
  HeroBannerTitle: Field<string>
  HeroBannerYouTubeID: Field<string>
  HeroBannerPosterImage: ImageField
  HeroBannerImageDesktop: ImageField
  HeroBannerImageTablet: ImageField
  HeroBannerImageMobile: ImageField
}

export type HeroBannerProps = ComponentProps & {
  fields: Fields
}

export const ADFHeroBanner = ({ rendering, params, fields }: HeroBannerProps): JSX.Element => {
  const id = params.RenderingIdentifier ?? params.DynamicPlaceholderId
  const [playing, setPlaying] = useState(true)
  // This is what the user controlled
  const [userPlaying, setUserPlaying] = useState(playing)
  const service = useService()
  const { sitecoreContext } = useSitecoreContext()

  const playerElement = useRef<YouTubePlayer | null>(null)
  const playerTimeout = useRef<NodeJS.Timeout | null>(null)

  const onBackgroundReady: YouTubeProps['onReady'] = (event) => {
    // Access to player in all event handlers via event.target
    playerElement.current = event.target
    // console.log('playerElement ready')
  }

  const onPlayerStateChange: YouTubeProps['onStateChange'] = () => {
    // console.log(event, id)
  }

  const onPlaybackEnd = () => {
    playerElement.current?.seekTo(0)
    playerElement.current.playVideo()
  }
  const inViewPlayer = useRef<HTMLDivElement | null>(null)
  const entryIntersect = useIntersect(inViewPlayer, '-10px -10px')

  useEffect(
    () => () => {
      // console.log('playerElement UNMOUNT')
      if (typeof playerElement.current?.destroy() === 'function') {
        playerElement.current.destroy()
      }
      // window.YT = undefined
      playerElement.current = null
    },
    []
  )

  const greaterThanMd = useRef(useGreaterThan('md'))
  // Ensure the render forces the current size
  greaterThanMd.current = useGreaterThan('md')

  useEffect(() => {
    if (!greaterThanMd.current) {
      return
    }

    // Play / Pause the video when in or out of view
    const entry = entryIntersect?.intersectionRatio ? entryIntersect?.intersectionRatio * 100 > 10 : false

    if (playerElement.current && entry) {
      if (typeof playerElement.current?.playVideo === 'function' && playerElement.current?.getPlayerState() !== 1) {
        if (userPlaying) {
          if (playerTimeout.current) {
            clearTimeout(playerTimeout.current)
          }
          playerTimeout.current = setTimeout(() => {
            // console.log('playerElement PLAY NOW')
            if (playerElement.current && greaterThanMd.current) {
              playerElement.current.playVideo()
            }
            playerTimeout.current = null
          }, 500)
        }
      }
    } else if (playerElement.current !== null && playerElement.current.getPlayerState() === 1) {
      playerElement.current.pauseVideo()
    }
    return () => {
      if (playerTimeout.current) {
        clearTimeout(playerTimeout.current)
      }
    }
  }, [entryIntersect, playerElement, userPlaying])

  const bgOpts: YouTubeProps['opts'] = {
    height: '1280',
    width: '720',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: playing ? 1 : 0,
      rel: 0,
      modestbranding: 1,
      loop: 1,
      playsinline: 1,
      mute: 1,
      controls: 0,
    },
    playlist: id,
  }

  const backgroundClasses = `
      absolute top-0 left-0 right-0 bottom-0 pointer-events-none
      [&_iframe]:absolute [&_iframe]:top-0 [&_iframe]:left-0 [&_iframe]:w-full [&_iframe]:h-full
      sm:[&_iframe]:scale-[1.45] md:[&_iframe]:scale-125 lg:[&_iframe]:scale-100
    `

  return (
    <div
      id={id ? id : undefined}
      className="dark relative flex w-full overflow-hidden bg-black sm:aspect-[2] md:aspect-[2.6]"
      ref={inViewPlayer}
    >
      {sitecoreContext.pageEditing ? (
        <NextImage
          className="absolute h-full w-full object-cover object-center"
          field={fields.HeroBannerPosterImage}
          width={1280}
          height={720}
        />
      ) : (
        <Image
          className="h-full min-h-[500px] w-full object-cover object-center sm:absolute sm:min-h-full"
          src={getImagePath(fields.HeroBannerPosterImage?.value?.src ?? '')}
          alt={''}
          title={fields.HeroBannerTitle.value}
          width={1280}
          height={720}
        />
      )}

      {/* This is just reusing the variable set above, it's always accurate to the current render */}
      {greaterThanMd.current && (
        <>
          <div
            className="pointer-events-none absolute top-1/2 aspect-video w-full -translate-y-1/2"
            aria-hidden={true}
            // https://github.com/facebook/react/issues/17157#issuecomment-1572230721 This prevents youtube from being focusable
            {...{ inert: '' }}
          >
            <YouTube
              id={fields.HeroBannerYouTubeID.value}
              className={backgroundClasses}
              videoId={fields.HeroBannerYouTubeID.value}
              opts={bgOpts}
              onReady={onBackgroundReady}
              onPause={() => setPlaying(false)}
              onPlay={() => setPlaying(true)}
              onEnd={onPlaybackEnd}
              onStateChange={onPlayerStateChange}
            />
          </div>
          <div className="absolute bottom-8 right-8 z-10 [&_.secondary-icon]:h-12 [&_.secondary-icon]:w-12 [&_.secondary-icon_svg]:h-6 [&_.secondary-icon_svg]:w-6">
            <Button
              data-trackingid={rendering.uid as string}
              aria-controls={fields.HeroBannerYouTubeID.value}
              aria-label={userPlaying ? 'Pause' : 'Play'}
              link={{ value: { text: userPlaying ? 'Pause' : 'Play' } }}
              icon={userPlaying ? 'pause' : 'play'}
              type="secondary-icon"
              solid={true}
              button
              onClick={() => {
                playing ? playerElement?.current?.pauseVideo() : playerElement?.current?.playVideo()
                setUserPlaying(!playing)
              }}
              service={service}
            />
          </div>
        </>
      )}

      <h1 className="w-full">
        <span className="sr-only">{fields.HeroBannerTitle.value}</span>
        {/* Note - this dictates the aspect ratio on mobile */}
        {sitecoreContext.pageEditing ? (
          <NextImage
            className="relative z-10 w-full sm:hidden"
            field={fields.HeroBannerImageMobile}
            title={fields.HeroBannerTitle.value}
            width={1280}
            height={720}
          />
        ) : (
          fields?.HeroBannerImageMobile?.value && (
            <>
              {!fields.HeroBannerImageMobile.value.src?.includes('svg') ? (
                <Image
                  className="img1 relative z-10 w-full sm:hidden"
                  src={getImagePath(fields.HeroBannerImageMobile?.value?.src ?? '')}
                  alt=""
                  title={fields.HeroBannerTitle.value}
                  width={1280}
                  height={720}
                />
              ) : (
                <img
                  className="SVG-mob absolute inset-0 top-1/2 h-auto w-full -translate-y-1/2 object-cover object-center sm:hidden"
                  src={fields.HeroBannerImageMobile?.value?.src!}
                  title={fields.HeroBannerTitle.value}
                  width={1280}
                  height={720}
                ></img>
              )}
            </>
          )
        )}

        <div className="absolute inset-0 overflow-hidden">
          {/* Aspect ratio is dictated by the video container above (responsive and wider than 16:9) */}
          {sitecoreContext.pageEditing ? (
            <NextImage
              className="absolute inset-0 hidden h-full w-full object-cover object-center sm:block lg:hidden"
              field={fields.HeroBannerImageTablet}
              title={fields.HeroBannerTitle.value}
              width={1280}
              height={720}
            />
          ) : (
            fields?.HeroBannerImageTablet?.value && (
              <>
                {!fields.HeroBannerImageTablet.value.src?.includes('svg') ? (
                  <Image
                    className="img2 absolute inset-0 hidden h-full w-full object-cover object-center sm:block lg:hidden"
                    src={getImagePath(fields.HeroBannerImageTablet?.value?.src ?? '')}
                    alt=""
                    title={fields.HeroBannerTitle.value}
                    width={1280}
                    height={720}
                  />
                ) : (
                  <img
                    className="SVG-tab absolute inset-0 left-1/2 hidden h-full w-auto -translate-x-1/2 object-cover object-center sm:block lg:hidden"
                    src={getImagePath(fields.HeroBannerImageDesktop?.value?.src ?? '')}
                    title={fields.HeroBannerTitle.value}
                    width={1280}
                    height={720}
                  ></img>
                )}
              </>
            )
          )}
          {sitecoreContext.pageEditing ? (
            <NextImage
              className="absolute inset-0 hidden h-full w-full object-cover object-center lg:block"
              field={fields.HeroBannerImageDesktop}
              title={fields.HeroBannerTitle.value}
              width={1280}
              height={720}
            />
          ) : (
            fields?.HeroBannerImageDesktop?.value && (
              <>
                {!fields.HeroBannerImageDesktop.value.src?.includes('svg') ? (
                  <Image
                    className="img3 absolute inset-0 hidden h-full w-full object-cover object-center lg:block"
                    src={getImagePath(fields.HeroBannerImageDesktop?.value?.src ?? '')}
                    alt=""
                    title={fields.HeroBannerTitle.value}
                    width={1280}
                    height={720}
                  />
                ) : (
                  <img
                    className="SVG-des absolute inset-0 left-1/2 hidden h-full w-auto -translate-x-1/2 object-cover object-center lg:block"
                    src={getImagePath(fields.HeroBannerImageDesktop?.value?.src ?? '')}
                    title={fields.HeroBannerTitle.value}
                    width={1280}
                    height={720}
                  ></img>
                )}
              </>
            )
          )}
        </div>
      </h1>
    </div>
  )
}

export default withDatasourceCheck()<HeroBannerProps>(ADFHeroBanner)
