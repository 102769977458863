import React from 'react'
import { Field, FileField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs'

import { ComponentProps } from 'lib/component-props'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import AudioPlayer from '../Audio/AudioPlayer'
import ContentComponent, { ContentComponentType } from '../ContentBlock/ContentComponent'
import { HeaderImage } from './HeaderImage'

type CommonHeaderFields = {
  HeaderImage: ImageField
  TileImage1?: ImageField
  TileImage2?: ImageField
  TileImage3?: ImageField
}

type CategoryHeaderProps = {
  fields: CommonHeaderFields & {
    Title: Field<string>
    Description: Field<string>
  }
  showAudioPlayer: never
}

type RealStoriesHeaderProps = {
  fields: CommonHeaderFields & {
    Quote: Field<string>
    QuoteSource: Field<string>
    AudioName: Field<string>
    AudioFile: FileField
    AudioImage: ImageField
    AudioJobTitle: Field<string>
    AudioTranscript: Field<string>
  }
  showAudioPlayer?: boolean
  uid?: string
}

type GenericHeaderProps = {
  fields: CommonHeaderFields
  showAudioPlayer: never
}

export type PageHeaderProps<T extends ContentComponentType> = ComponentProps &
  (T extends infer TContentType
    ? (TContentType extends 'Header'
        ? CategoryHeaderProps
        : TContentType extends 'Quote'
          ? RealStoriesHeaderProps
          : GenericHeaderProps) & {
        contentComponentType: TContentType
      }
    : { contentComponentType: T })

export const PageHeaderComponent = ({
  contentComponentType,
  fields,
  showAudioPlayer,
  params: { GridParameters, styles },
  ...props
}: PageHeaderProps<ContentComponentType>) => {
  const containerStyles = [GridParameters, styles].filter(Boolean).join(' ')
  const uid = ('uid' in props ? props.uid : props.rendering.uid) as string
  const headerContent =
    contentComponentType === 'Header'
      ? { title: fields.Title, description: fields.Description }
      : contentComponentType === 'Quote'
        ? { title: fields.QuoteSource, description: fields.Quote }
        : null

  return (
    <section className={`${containerStyles} dark w-full bg-black pb-20`}>
      <HeaderImage
        image={fields.HeaderImage.value}
        className="absolute inset-x-0 top-0 max-h-screen overflow-hidden bg-black after:absolute after:inset-x-0 after:bottom-0 after:z-[1] after:h-20 after:bg-gradient-to-t after:from-[black] after:to-transparent md:after:h-40 [&_.image-loader]:relative [&_.image-loader]:opacity-40"
        width={300}
        height={200}
      />

      <div className="relative z-10">
        <div
          className="z-10 grid w-full pb-4 pt-32 md:min-h-[80vh] md:grid-cols-3 md:gap-x-10 md:pb-12 lg:gap-x-28"
          data-cols="12"
        >
          <div className="col-span-2 flex max-w-[42rem] items-end md:pr-16 lg:pr-0">
            {headerContent && (
              <ContentComponent
                id={uid}
                Type={contentComponentType}
                HeadingStyle="h1"
                ShowServiceTag={false}
                Title={headerContent.title.value}
                Description={headerContent.description.value}
              />
            )}
          </div>
        </div>
        <div className="z-10 grid grid-cols-1 gap-y-6 md:grid-cols-3 md:gap-x-10 lg:gap-x-28" data-cols="12">
          <div
            className={`relative grid grid-cols-1 gap-y-6 md:-mt-72 ${
              fields.TileImage2?.value?.src || fields.TileImage3?.value?.src ? 'md:order-last' : 'md:col-start-3'
            }`}
          >
            {fields.TileImage1 ? (
              <HeaderImage image={fields.TileImage1.value} width={500} height={useGreaterThan('md') ? 580 : 300} />
            ) : null}

            {contentComponentType === 'Quote' && showAudioPlayer && fields.AudioFile?.value?.src ? (
              <div className="md:absolute md:-left-20 md:top-20 md:z-10">
                <div className="w-full text-black">
                  {fields.AudioFile?.value?.src && fields.AudioImage?.value?.src ? (
                    <AudioPlayer
                      url={fields.AudioFile.value.src}
                      image={fields.AudioImage.value.src}
                      name={fields.AudioName.value || ''}
                      role={fields.AudioJobTitle.value || ''}
                      audioTranscript={fields.AudioTranscript.value || ''}
                      id={uid}
                      group
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          {(fields.TileImage2?.value?.src || fields.TileImage3?.value?.src) && (
            <div className="relative col-span-2 grid grid-cols-1 gap-y-6 md:gap-y-4">
              <HeaderImage image={fields.TileImage2?.value} width={500} height={useGreaterThan('md') ? 430 : 300} />

              {fields.TileImage3 ? (
                <div className="md:relative md:-right-1/3 md:-top-1/2 md:ml-auto md:w-1/2">
                  <HeaderImage image={fields.TileImage3.value} width={500} height={useGreaterThan('md') ? 380 : 300} />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default PageHeaderComponent
