import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { trackFilterInteraction } from 'src/utils/tracking'
import {
  ADFEventSearchResultsProps,
  EventSearchResultsRequest,
} from 'components/ADFEventSearchResults'
import Filter, { type FilterValue } from 'components/front-end/Drawers/drawer-components/Filter'
import Drawer from '../Drawers/Drawer'

export interface EventSearchDrawerProps {
  props: ADFEventSearchResultsProps
  openDrawer: boolean
  filters: Map<string, Map<string, string>>
  onApply: () => void
  onReset: () => void
  onClose: () => void
}

const EventSearchDrawer = (props: EventSearchDrawerProps): JSX.Element => {
  const router = useRouter()

  //short cuts
  const dsProps = props?.props?.fields
  // const isReserve = props?.props?.fields?.data?.datasource?.isReserve?.value?.value == true
  //const orderingProps = dsProps?.orderingOptions
  const eventHistoricalProps = dsProps?.EventHistorical

  const eventDateProps = dsProps?.EventDate
  const categoriesProps = dsProps?.Categories
  const servicesProps = dsProps?.Services
  const eventStatesProps = dsProps?.EventStates
  const eventTypesProps = dsProps?.EventTypes
  const locationTypesProps = dsProps?.LocationTypes
  const searchSource = dsProps?.SearchSource?.id ?? '{6A9BC189-E8B2-4DAD-93AE-E9CDD7CD54ED}'

  const [isUpcomingEvents, setisUpcomingEvents] = useState(true)
  const [defaultEventHistorical, setEventHistorical] = useState(
    eventHistoricalProps?.children?.results?.length > 0 ? eventHistoricalProps?.children?.results[0].id : ''
  )
  const setNewPayload = (filters: Map<string, Map<string, string>>) => {
    const matches = router?.asPath?.match(/query=([^&]*)/)
    const res = {
      filterValues: Array.from(filters.entries())
        .filter((x) => x[1].size > 0)
        .map((x) => ({
          FacetId: x[0],
          FacetValues: Array.from(x[1].keys()),
        })),
      searchId: searchSource,
      page: 1,
      perPage: 10,
      query: matches?.[1],
    }
    return res
  }
  const [payload, setPayload] = useState<EventSearchResultsRequest>(setNewPayload(props?.filters))
  //const [results, setResults] = useState(props?.results)
  //const [active, setActive] = useState<string | undefined>(dsProps?.generalEntryMethodHeading?.value)
  //const [filters, setFilters] = useState<boolean>(false)
  const eventHistoricalPropsFilterCount = props?.filters.get(eventHistoricalProps?.id)?.size ?? 0

  useEffect(() => {
    if (eventHistoricalPropsFilterCount === 0) {
      setEventHistorical(
        eventHistoricalProps?.children?.results?.length > 0 ? eventHistoricalProps?.children?.results[0].id : ''
      )
    }
    setisUpcomingEvents(defaultEventHistorical == props?.props?.fields?.EventHistorical?.children?.results[0].id)
  }, [props.openDrawer])

  useEffect(() => {
    if (eventHistoricalPropsFilterCount === 0) {
      setEventHistorical(
        eventHistoricalProps?.children?.results?.length > 0 ? eventHistoricalProps?.children?.results[0].id : ''
      )
    } else {
      for (const entry of props?.filters.get(eventHistoricalProps?.id)?.entries() ?? []) {
        if (entry[0]) {
          setEventHistorical(entry[0])
        }
      }
    }
    setisUpcomingEvents(defaultEventHistorical == props?.props?.fields?.EventHistorical?.children?.results[0].id)
  }, [eventHistoricalPropsFilterCount])


  const trackEventFilterData = (value: FilterValue) => {
    const filterAction = value?.checked === false ? 'select' : 'deselect'
    const filterComponent = 'event filter'
    const filterSelection = `${value?.title}-${value?.name} - ${props.props.rendering.uid}` as const
    trackFilterInteraction(filterAction, filterComponent, filterSelection)
  }

  const tickFilter = (keyId: string, value: FilterValue) => {
    const map = props?.filters.get(keyId)
    if (!map) return

    if (value.value == 'any' || value.value == 'all') {
      map?.clear()
    } else {
      const isFilter = map.has(value.value)
      if (isFilter) {
        map?.delete(value.value)
      } else {
        map?.set(value.value, value.queryName!)
      }
    }
    setPayload(setNewPayload(props?.filters))
    trackEventFilterData(value)
  }

  const selectFilter = (keyId: string, value: FilterValue) => {
    setisUpcomingEvents(value.value == props?.props?.fields?.EventHistorical?.children?.results[0].id)
    setEventHistorical(value.value)
    const map = props?.filters.get(keyId)
    if (!map) return

    console.log('selectFilter before reset', map, props?.filters)
    //reset the radio filter
    map?.clear()
    map?.set(value.value, value.queryName!)

    //reset upcoming date filter
    const upcomingFilterMap = props?.filters.get(eventDateProps?.id)
    if (upcomingFilterMap) {
      upcomingFilterMap?.clear()
    }

    setPayload(setNewPayload(props?.filters))
    trackEventFilterData(value)
  }

  // useEffect(() => {
  //   if (props?.openDrawer) {
  //     fetch(`${process.env.HUB_API_HOST}/api/v2/search/JobSearch`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(payload),
  //     })
  //       .then((r) => r.json())
  //       .then((r) => {
  //         setResults(r)
  //       })
  //   }
  // }, [payload, props?.openDrawer])

  return (
    <Drawer
      title="Filters"
      id="event-search-drawer"
      show={props?.openDrawer}
      closeDrawer={() => props.onClose()}
      primaryText={`RESET FILTERS`}
      primaryIcon={'refresh'}
      primaryClick={() => {
        setEventHistorical(
          eventHistoricalProps?.children?.results?.length > 0 ? eventHistoricalProps?.children?.results[0].id : ''
        )
        setisUpcomingEvents(true)
        props.onReset()
      }}
      secondaryText={`APPLY FILTERS`}
      secondaryClick={() => props.onApply()}
    >
      <>
        {/* <Header text='Tell us what you think' /> */}
        {/* Disable tab focus for filters */}
        {props?.openDrawer && (
          <>
            <Filter
              title={dsProps?.EventHistoricalHeading?.value}
              isRadio={true}
              defaultOption={defaultEventHistorical}
              filterValues={[
                ...eventHistoricalProps?.children?.results?.map((x) => {
                  return {
                    name: x?.displayName?.value ?? '',
                    value: x?.id ?? '',
                    queryName: x?.name?.value,
                    checked: props?.filters.get(eventHistoricalProps?.id)?.has(x?.id),
                    title: dsProps?.EventHistoricalHeading?.value,
                  }
                }),
              ]}
              onClick={(value) => selectFilter(eventHistoricalProps?.id, value)}
            />

            {isUpcomingEvents && (
              <Filter
                title={dsProps?.EventDateHeading?.value}
                filterValues={[
                  {
                    name: 'All',
                    value: 'all',
                    checked: (props?.filters.get(eventDateProps?.id)?.size ?? 0) <= 0,
                    title: dsProps?.EventDateHeading?.value,
                  },
                  ...eventDateProps?.children?.results?.map((x) => {
                    return {
                      name: x?.displayName?.value ?? '',
                      value: x?.id ?? '',
                      queryName: x?.name?.value,
                      checked: props?.filters.get(eventDateProps?.id)?.has(x?.id),
                      title: dsProps?.EventDateHeading?.value,
                    }
                  }),
                ]}
                onClick={(value) => tickFilter(eventDateProps?.id, value)}
              />
            )}
            <Filter
              title={dsProps?.LocationTypeHeading?.value}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (props?.filters.get(locationTypesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.LocationTypeHeading?.value,
                },
                ...locationTypesProps?.children?.results?.map((x) => ({
                  name: x?.displayName?.value ?? '',
                  value: x?.id ?? '',
                  queryName: x?.name?.value,
                  checked: props?.filters.get(locationTypesProps?.id)?.has(x?.id),
                  title: dsProps?.LocationTypeHeading?.value,
                })),
              ]}
              onClick={(value) => tickFilter(locationTypesProps?.id, value)}
            />
            <Filter
              title={dsProps?.ServiceHeading?.value}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (props?.filters.get(servicesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.ServiceHeading?.value,
                },
                ...servicesProps?.children?.results?.map((x) => ({
                  name: x?.displayName?.value ?? '',
                  value: x?.id ?? '',
                  queryName: x?.name?.value,
                  checked: props?.filters.get(servicesProps?.id)?.has(x?.id),
                  title: dsProps?.ServiceHeading?.value,
                })),
              ]}
              onClick={(value) => tickFilter(servicesProps?.id, value)}
            />
            <Filter
              title={dsProps?.CategoryHeading?.value}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (props?.filters.get(categoriesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.CategoryHeading?.value,
                },
                ...categoriesProps?.children?.results?.map((x) => {
                  return {
                    name: x?.displayName?.value ?? '',
                    value: x?.id ?? '',
                    queryName: x?.name?.value,
                    checked: props?.filters.get(categoriesProps?.id)?.has(x?.id),
                    title: dsProps?.CategoryHeading?.value,
                  }
                }),
              ]}
              onClick={(value) => tickFilter(categoriesProps?.id, value)}
            />
            <Filter
              title={dsProps?.EventStateHeading?.value ?? 'STATE / TERRITORY'}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (props?.filters.get(eventStatesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.EventStateHeading?.value ?? 'STATE / TERRITORY',
                },
                ...eventStatesProps?.children?.results?.map((x) => ({
                  name: x?.displayName?.value ?? '',
                  value: x?.id ?? '',
                  queryName: x?.name?.value,
                  checked: props?.filters.get(eventStatesProps?.id)?.has(x?.id),
                  title: dsProps?.EventStateHeading?.value ?? 'STATE / TERRITORY',
                })),
              ]}
              onClick={(value) => tickFilter(eventStatesProps?.id, value)}
            />
            <Filter
              title={dsProps?.EventTypeHeading?.value}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (props?.filters.get(eventTypesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.EventTypeHeading?.value,
                },
                ...eventTypesProps?.children?.results?.map((x) => ({
                  name: x?.displayName?.value ?? '',
                  value: x?.id ?? '',
                  queryName: x?.name?.value,
                  checked: props?.filters.get(eventTypesProps?.id)?.has(x?.id),
                  title: dsProps?.EventTypeHeading?.value,
                })),
              ]}
              onClick={(value) => tickFilter(eventTypesProps?.id, value)}
            />
          </>
        )}
      </>
    </Drawer>
  )
}

export default EventSearchDrawer
