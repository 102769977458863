import React from 'react'
import { useFieldArray, useFormState } from 'react-hook-form'

import Button from '../Buttons/Button'
import Icon from '../Icons/Icon'
import Attendee, { AttendeeProps } from './Attendee'
import { defaultRegistrant } from './RegistrationForm'

type GuestProps = Omit<AttendeeProps, 'prefix'> & {
  limit: number
  service: string
  name?: string
  labels: Record<'addGuest' | 'removeGuest', string>
}

const fieldName = 'Guests'

export default function Guests({ limit, service, name = 'Guests', labels, ...props }: GuestProps) {
  const { isSubmitting } = useFormState()
  const { fields, append, remove } = useFieldArray({ name })
  const handleAddGuest = () => {
    append(defaultRegistrant)
  }

  const handleRemoveGuest = (index: number) => {
    if (index + 1 > fields.length) return
    remove(index)
  }

  const addGuestButton = (
    <Button
      data-trackingid={props.id}
      button
      buttonType="button"
      icon="add"
      service={service}
      type="small-action"
      link={{
        value: {
          text: labels.addGuest,
        },
      }}
      onClick={() => {
        handleAddGuest()
      }}
      disabled={isSubmitting}
    />
  )

  const removeGuestButton = (index: number) => (
    <Button
      data-trackingid={props.id}
      button
      buttonType="button"
      icon="minus"
      service={service}
      type="small-action"
      link={{
        value: {
          text: labels.removeGuest,
        },
      }}
      onClick={() => {
        handleRemoveGuest(index)
      }}
      disabled={isSubmitting}
    />
  )

  if (fields.length === 0) {
    return <div className="my-4 flex gap-x-6">{addGuestButton}</div>
  }

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Attendee {...props} guest heading={`Guest ${index + 1} Details`} path={`${fieldName}.${index}`} />
          <div className="my-4 flex gap-x-6">
            {index === fields.length - 1 ? addGuestButton : null}
            {fields.length >= 1 ? removeGuestButton(index) : null}
          </div>
        </React.Fragment>
      ))}
      {fields.length >= limit ? (
        <div className="my-6 flex gap-2 bg-grey-pale p-4">
          <Icon name="error" type="stroke" height={24} width={24} />
          <p className="sbody">
            <strong>Only maximum 10 guests can be added.</strong>
          </p>
        </div>
      ) : null}
    </>
  )
}
