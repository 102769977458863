import React from 'react'
import { ComponentParams, ComponentRendering, Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'

import ContactCTA from 'components/front-end/ContactCTA/ContactCTA'
import { ButtonProps } from './front-end/Buttons/Button'

interface ContactCTATileField {
  Headline: Field<string>
  SubHeadline: Field<string>
  CTAButton1: LinkField
  CTAButton2: LinkField
  ContactIcon1: Field<ButtonProps['icon']>
  ContactTitle1: Field<string>
  ContactDetail1: Field<string>
  ContactIcon2: Field<ButtonProps['icon']>
  ContactTitle2: Field<string>
  ContactDetail2: Field<string>
  ContactIcon3: Field<ButtonProps['icon']>
  ContactTitle3: Field<string>
  ContactDetail3: Field<string>
  ContactIcon4: Field<ButtonProps['icon']>
  Contactlink4: LinkField
}

interface ContactCTATilePageProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: ContactCTATileField
}

export const Default = (props: ContactCTATilePageProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier ?? props.params?.DynamicPlaceholderId
  const containerStyles = props.params?.styles ?? ''
  const styles = `${props.params?.GridParameters} ${containerStyles}`.trimEnd()

  return (
    <div className={`relative ${styles}`} id={id ? id : undefined}>
      <ContactCTA
        id={props.rendering.uid as string}
        Headline={props.fields?.Headline?.value}
        SubHeadline={props.fields?.SubHeadline?.value}
        CTAButton1={props.fields?.CTAButton1}
        CTAButton2={props.fields?.CTAButton2}
        ContactIcon1={props.fields?.ContactIcon1?.value}
        ContactTitle1={props.fields?.ContactTitle1?.value}
        ContactDetail1={props.fields?.ContactDetail1?.value}
        ContactIcon2={props.fields?.ContactIcon2?.value}
        ContactTitle2={props.fields?.ContactTitle2?.value}
        ContactDetail2={props.fields?.ContactDetail2?.value}
        ContactIcon3={props.fields?.ContactIcon3?.value}
        ContactTitle3={props.fields?.ContactTitle3?.value}
        ContactDetail3={props.fields?.ContactDetail3?.value}
        ContactIcon4={props.fields?.ContactIcon4?.value}
        Contactlink4={props.fields?.Contactlink4}
      />
    </div>
  )
}

export default Default
