import React from 'react'
import { useController } from 'react-hook-form'
import { twJoin, twMerge } from 'tailwind-merge'

import Icon from 'components/front-end/Icons/Icon'
import { InputProps } from './types'
import { ValidationWrapper } from './Validation'

export default function Checkbox(props: InputProps<'checkbox'>) {
  const { field, fieldState } = useController(props)
  // console.log({ ...props, ...field })

  return (
    <ValidationWrapper name={props.name} border={false} focusBorder={true}>
      <label
        className={twMerge(
          'svg-clicked relative flex cursor-pointer select-none items-center gap-3 px-1 py-3 transition-opacity aria-readonly:pointer-events-none aria-readonly:cursor-not-allowed aria-readonly:opacity-50',
          field.value && 'clicked-active',
          props.className
        )}
        aria-readonly={props.readOnly}
        inert={props.readOnly ? '' : undefined}
      >
        <input className="peer sr-only" {...field} checked={field.value} type="checkbox" />
        <Icon name="checkbox" type="stroke" className={twJoin('shrink-0', props.readOnly && 'opacity-40')} />
        <span
          className={twJoin(
            'sbody text-black',
            fieldState.invalid ? 'text-error' : 'text-black',
            props.rules?.required && typeof props.label === 'string'
              ? "after:content-['*']"
              : "[&>*:last-child]:after:content-['*']"
          )}
        >
          {props.label}
        </span>
      </label>
    </ValidationWrapper>
  )
}
