import React, { useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import axios from 'axios' // Import Axios for making API requests

import { SubmitHandler } from 'react-hook-form'

import { EventProps, EventRegoField } from 'components/ADFEventHome'
import Button from 'components/front-end/Buttons/Button'
import Header from 'components/front-end/Drawers/drawer-components/Header'
import { Checkbox, Form, Input } from 'components/front-end/Forms'
import Icon from 'components/front-end/Icons/Icon'
import { AttendeeType, EventAddGuest, trackEventRegistration } from 'src/utils/tracking'
import Attendee, { AttendeeProps } from './Attendee'
import Guests from './Guests'

type AttendeeObjType = {
  id: string
  key: string
  label: string
  value: AttendeeType
}

export interface Registrant {
  FirstName: string
  Surname: string
  Email: string
  Postcode: string
  Mobile: string
  CandidateNumber?: string
  Attend: string
  AttendeeType: AttendeeObjType | string
}

type FormFields = {
  Registrant: Registrant
  Guests: Registrant[]
  SessionValues: string[]
  ReceiveUpdates: boolean
  AcceptPrivacy: boolean
  AcceptEventGuidelines: boolean
} & Pick<EventFields, 'EventId' | 'EventCode' | 'EventName'>

export type EventFields = {
  EventId: EventProps['id']
  EventCode: EventProps['eventCode']
  EventName: EventProps['title']
  EventUrl: EventProps['url']
  EnableDietaryRequirements: EventProps['EnableDietaryRequirements']
} & Pick<
  EventProps,
  | 'EventVirtualMeetingLink'
  | 'EventIsVirtual'
  | 'EventIsHybrid'
  | 'onlineRegistrants'
  | 'eventCapacity'
  | 'eventCapacityIsFull'
  | 'eventVirtualCapacity'
  | 'inPersonRegistrants'
>

interface EventRegistrationFormProps {
  data: EventRegoField
  fields?: FormFields
  event: EventFields
  service: string
  onFormClose: () => void
}

export const defaultRegistrant: Registrant = {
  FirstName: '',
  Surname: '',
  Email: '',
  Postcode: '',
  Mobile: '',
  Attend: '',
  AttendeeType: '',
}

type ApiError = {
  systemMessage: 'NO_SPOTS' | 'EXIST' | 'CANDIDATEID' | Omit<string, 'NO_SPOTS' | 'EXIST' | 'CANDIDATEID'>
  userMessage: string
  errors: unknown
}
type EventApiResult = {
  result: {
    id: string | null
    statusCode: number
    warning: unknown
  } & (
    | {
        success: true
        data: { limit: number; offset: number; totalCount: number; totalPage: number; objectSet: unknown }
      }
    | {
        success: false
        data: { limit: 0; offset: 0; totalCount: 0; totalPage: 0; objectSet: null }
        error: {
          systemMessage: 'NO_SPOTS' | 'EXIST' | 'CANDIDATEID' | Omit<string, 'NO_SPOTS' | 'EXIST' | 'CANDIDATEID'>
          userMessage: string
          errors: unknown
        }
      }
  )
}

const trackEventRegistrationFormData = (formData: FormFields) => {
  const eventName = formData?.EventName
  const attendee = formData?.Registrant?.AttendeeType as AttendeeObjType
  const attendeeType = attendee?.value
  const guestLength = formData?.Guests?.length
  const addGuest = guestLength > 0 ? `yes - ${guestLength}` : `no`
  if (!eventName || !attendeeType || !addGuest) {
    return
  }
  trackEventRegistration(eventName, attendeeType, addGuest as EventAddGuest)
}

const EventRegistrationForm = (props: EventRegistrationFormProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const [registered, setRegistered] = useState(false)
  const [apiError, setApiError] = useState<ApiError>()

  const defaultValues = {
    Registrant: {
      ...defaultRegistrant,
      CandidateNumber: '',
    },
    Guests: [],
    SessionValues: ['facebook'],
    ReceiveUpdates: false,
    AcceptPrivacy: false,
    AcceptEventGuidelines: false,

    // Hidden fields
    EventId: props.event.EventId,
    EventCode: props.event.EventCode,
    EventName: props.event.EventName,
  }

  const { eventVirtualCapacity, onlineRegistrants, eventCapacity, inPersonRegistrants } = props.event
  const virtualCapacityFilled = +eventVirtualCapacity > 0 && onlineRegistrants >= +eventVirtualCapacity
  const inPersonCapacityFilled = eventCapacity > 0 && inPersonRegistrants >= eventCapacity
  const deliveryAllocated = inPersonCapacityFilled ? 'inPerson' : virtualCapacityFilled ? 'virtual' : undefined

  const deliveryType =
    !props.event.EventIsVirtual || (props.event.EventIsHybrid && deliveryAllocated)
      ? 'In Person'
      : props.event.EventIsVirtual && !props.event.EventIsHybrid
        ? 'Online'
        : undefined

  const attendeeProps: Omit<AttendeeProps, 'prefix'> = {
    id: props.event.EventId,
    data: props.data,
    hybridEvent: props.event.EventIsHybrid,
    deliveryAllocated,
    deliveryType,
  }

  const handleSubmit: SubmitHandler<FormFields> = async (formData) => {
    try {
      const { data } = await axios.post<EventApiResult>(
        `${process.env.DIRECT_APP_OMTAPI_HOST}/api/eventfinder/application/registerevent/`,
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )

      if (data.result.success) {
        trackEventRegistrationFormData(formData)
        setRegistered(true)
      } else {
        console.error('Error Response:', data.result)
        setApiError(data.result.error)
      }
    } catch (error) {
      console.error('Error Response:', error)
    }
  }

  const successMessage = (
    <div className="mt-10 text-grey-dark">
      {sitecoreContext.pageEditing && (
        <div className="my-10">
          <h4 className="mb-2"> Feedback form - Success Message Section: </h4>
          <hr />
        </div>
      )}
      <div>
        <Icon name="calendar" height={72} width={72} type="content" />
        <Header text={props.data.SuccessMessageHeading?.value ?? ''} />
        <p className="sbody my-6">{props.data.SuccessMessage.value}</p>
        <p className="sbody my-6 font-semibold">{props.event.EventName}</p>
        <p className="sbody my-6">{props.data.SuccessMessage2.value}</p>

        <Button
          data-trackingid={props.event.EventId}
          button
          link={{ value: { text: 'Close' } }}
          type="primary"
          icon="close"
          service={props.service}
          onClick={() => props.onFormClose()}
        />
      </div>
    </div>
  )

  const eventFullMessage = (
    <div className="flex gap-2 bg-grey-pale p-4">
      <Icon name="error" type="stroke" height={24} width={24} />
      <p className="sbody">
        {deliveryAllocated === 'inPerson'
          ? props.data.InpersonExhaustedMessage.value
          : props.data.OnlineExhaustedMessage.value}
      </p>
    </div>
  )

  const apiErrorMessage = apiError ? (
    <div className="mt-6 flex flex-col gap-3 border border-error bg-error-tint p-6">
      <h4 className="mb-2">{props.data.FailMessageHeading.value}</h4>
      {apiError.systemMessage === 'EXIST' ? (
        <p className="font-semibold">Your email is already registered for {props.event.EventName || 'this event'}.</p>
      ) : apiError.systemMessage === 'NO_SPOTS' ? (
        <p className="font-semibold">There are no more spots available for {props.event.EventName || 'this event'}.</p>
      ) : apiError.systemMessage === 'CANDIDATEID' ? (
        <p className="font-semibold">Your Candidate ID can only contain numbers.</p>
      ) : (
        <>
          <p className="text-grey-dark">There appears to be an error in the form.</p>
          <p className="text-grey-dark">Please check the values and try again.</p>
        </>
      )}
    </div>
  ) : null

  return (
    <>
      {!registered ? (
        <Form<FormFields> onSubmit={handleSubmit} defaultValues={defaultValues}>
          <div>
            <Header text={props.event.EventName} />
            {deliveryAllocated && eventFullMessage}
          </div>

          <Attendee {...attendeeProps} heading={props.data.RegistrantHeading.value} path="Registrant" />
          <Guests
            limit={10}
            service={props.service}
            labels={{ addGuest: props.data.AddGuestText.value, removeGuest: 'Remove guest' }}
            {...attendeeProps}
          />

          {props.event.EnableDietaryRequirements ? (
            <Input
              label="Dietary Requirements (if applicable)"
              name="DietaryRequirements"
              placeholder="Enter dietary requirements"
            />
          ) : null}

          <Checkbox label={props.data.WithReceiveLabel.value} name="ReceiveUpdates" />
          <Checkbox
            label={
              <span
                className="[&_a]:underline"
                dangerouslySetInnerHTML={{
                  __html: props.data.EventGuidelinesLabel.value.replace(
                    'ADF Careers Event Guidelines',
                    `<a href="${props.data.EventGuidelinesLink.value}" target="_blank" rel="nofollow noopener">ADF Careers Event Guidelines</a>`
                  ),
                }}
              />
            }
            name="AcceptEventGuidelines"
            rules={{ required: props.data.EventGuidelinesMessage.value }}
          />
          <Checkbox
            label={
              <span
                className="[&_a]:underline"
                dangerouslySetInnerHTML={{
                  __html: props.data.AgreePrivacyLabel.value.replace(
                    'Privacy Statement',
                    `<a href="${props.data.PrivacyLink.value}" target="_blank" rel="nofollow noopener">Privacy Statement</a>`
                  ),
                }}
              />
            }
            name="AcceptPrivacy"
            rules={{ required: props.data.AgreePrivacyMessage.value }}
          />

          <Input type="hidden" name="EventId" />
          <Input type="hidden" name="EventCode" />
          <Input type="hidden" name="EventName" />

          {apiError && apiErrorMessage}

          <p>
            <Button
              data-trackingid={props.event.EventId}
              button
              buttonType="submit"
              link={{ value: { text: props.data.SubmitButtonText.value } }}
              type="primary"
              icon="chevron-right-double"
              service={props.service}
            />
          </p>
        </Form>
      ) : null}

      {registered || sitecoreContext.pageEditing ? successMessage : null}
    </>
  )
}

export default EventRegistrationForm
