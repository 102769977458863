import React, { useRef } from 'react'
import { Field, ImageField, Image as JssImage, LinkField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { useRouter } from 'next/router'
import { twJoin } from 'tailwind-merge'

import CarouselSwiper from 'components/front-end/Carousel/CarouselSwiper'
import Icon from 'components/front-end/Icons/Icon'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import VideoModal, { CustomModalRef } from 'components/front-end/Video/VideoModal'
import ImageCard from './front-end/Cards/ImageCard'
import ImageOverlayCard from './front-end/Cards/ImageOverlayCard'
import InfoPanel from './front-end/SitecoreHelpers/InfoPanel'
import StandardTagList from './front-end/Tags/StandardTagList'

export interface CardItemFields {
  CardTitle: Field<string>
  CardService: Field<string>
  CardImage: ImageField
  CardButton: LinkField
  CardDescription?: Field<string>
}

interface VideoCardItemFields extends CardItemFields {
  VideoCardDuration: Field<string>
  VideoCardButtonText: Field<string>
  YouTubeVideoLink: Field<string>
}

interface ServiceCardItemFields extends CardItemFields {
  ServiceCardColumnLayout: Field<string>
  IsReserve: Field<boolean>
}

interface GeneralCardItemFields extends CardItemFields {
  GeneralCardColumnLayout?: Field<string>
}

export type GeneralCardItem = {
  id: string
  url?: string
  fields: GeneralCardItemFields
}

export type PanoramaCardItem = {
  id: string
  fields: GeneralCardItemFields
}

export type ServiceCardItem = {
  id: string
  name: string
  url?: string
  displayName: string
  fields: ServiceCardItemFields
}

export type VideoCardItem = {
  id: string
  url?: string
  fields: VideoCardItemFields
}

export interface CardCarouselRootFields<T = GeneralCardItem | PanoramaCardItem | ServiceCardItem | VideoCardItem> {
  LayoutType: Field<string>
  items: T[]
}

type CardCarouselProps<T> = {
  params: { [key: string]: string }
  fields: CardCarouselRootFields<T>
  rendering: {
    uid?: string
    dataSource?: string
  }
}

const CarouselCardsDefaultComponent = (): JSX.Element => (
  <div>
    <span className="is-empty-hint">Carousel Card</span>
  </div>
)

/**
 * (Default) General Card Carousel Variant.
 */
export const Default = (props: CardCarouselProps<GeneralCardItem>): JSX.Element => {
  /**
   * TODO: Check grid spacing throughout across breakpoints
   * Check overlay gradient
   */
  const { sitecoreContext } = useSitecoreContext()

  // Carousel, Stacked
  const layoutType = props.fields.LayoutType
  const itemCount = props.fields.items?.length || 0

  if (itemCount === 0) {
    return (
      <div>
        <InfoPanel heading="Sitecore | General Card Wrapper" message="Please add Carousel Cards below" />
      </div>
    )
  }
  if (Object.values(props.fields).length) {
    const list = props.fields.items?.map((element: GeneralCardItem, key: number) => {
      return (
        <ImageOverlayCard
          key={key}
          title={element.fields.CardTitle}
          lazy={true}
          service={element.fields.CardService.value}
          description={element.fields.CardDescription}
          cardLink={{
            'data-trackingid': element.id,
            link: element.fields.CardButton,
            icon: 'chevron-right-double',
            type: 'small-secondary',
          }}
          field={element.fields.CardImage}
          width={500}
          height={760}
          sizes="(max-width: 767px) 100vw, (max-width: 1279px) 50vw, 33vw"
        />
      )
    })

    return sitecoreContext.pageEditing || layoutType.value != 'Carousel' ? (
      <div data-cols="12" className={twJoin('mb-20 grid gap-6 md:grid-cols-2', itemCount > 2 && 'lg:grid-cols-3')}>
        {list}
      </div>
    ) : (
      <div
        className="container-break-right mb-20"
        data-tracking-component={props.rendering.dataSource?.split('/').pop()?.replace(' ', '-') || 'Card-Carousel'}
      >
        {/* currently slider column count is set globally in _component-carousel.scss
              - If required we can make this a per slider variable */}
        <CarouselSwiper id={props.rendering.uid as string} alignControls="right">
          {list}
        </CarouselSwiper>
      </div>
    )
  }

  return <CarouselCardsDefaultComponent />
}

export const PanoramaCards = (props: CardCarouselProps<PanoramaCardItem>): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const layoutType = props.fields.LayoutType
  const itemCount = props.fields.items?.length || 0

  if (itemCount === 0) {
    return (
      <div>
        <InfoPanel heading="Sitecore | Panorama Card Wrapper" message="Please add Carousel Cards below" />
      </div>
    )
  }
  if (Object.values(props.fields).length) {
    const list = props.fields.items?.map((element: PanoramaCardItem, key: number) => (
      <ImageCard
        key={key}
        field={element.fields.CardImage}
        title={element.fields.CardTitle}
        service={element.fields.CardService.value as string}
        url={element.fields.CardButton.value.href as string} // MAKE THIS LINKY
        lazy={true}
        description={element.fields.CardDescription}
        tags={['Panorama']}
        button={{
          'data-trackingid': element.id,
          link: element.fields.CardButton,
          icon: 'chevron-right-double',
          type: 'small-secondary',
        }}
      />
    ))
    return sitecoreContext.pageEditing || layoutType.value != 'Carousel' ? (
      <div data-cols="12" className={twJoin('mb-20 grid gap-6 md:grid-cols-2', itemCount > 2 && 'lg:grid-cols-3')}>
        {list}
      </div>
    ) : (
      <div className="container-break-right mb-20">
        <CarouselSwiper id={props.rendering.uid as string} className="four-up">
          {list}
        </CarouselSwiper>
      </div>
    )
  }

  return <CarouselCardsDefaultComponent />
}

export const ServiceCards = (props: CardCarouselProps<ServiceCardItem>): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const layoutType = props.fields.LayoutType
  const itemCount = props.fields.items?.length || 0
  if (itemCount === 0) {
    return (
      <div>
        <InfoPanel heading="Sitecore | Service Card Wrapper" message="Please add Carousel Cards below" />
      </div>
    )
  }
  if (Object.values(props.fields).length) {
    const list = props.fields.items?.map((element: ServiceCardItem, key: number) => (
      <ImageOverlayCard
        key={key}
        title={element.fields.CardTitle}
        url={element.url as string}
        lazy={true}
        cardType="service"
        description={element.fields.CardDescription}
        cardLink={{
          'data-trackingid': element.id,
          link: element.fields.CardButton,
          icon: 'chevron-right-double',
          type: 'small-secondary',
        }}
        width={500}
        height={760}
        field={element.fields.CardImage}
        service={element.fields.CardService.value as string}
        isReserve={element.fields.IsReserve.value}
        sizes="(max-width: 767px) 100vw, (max-width: 1279px) 50vw, 33vw"
      />
    ))

    return sitecoreContext.pageEditing || layoutType.value != 'Carousel' ? (
      <div data-cols="12" className={twJoin('mb-20 grid gap-6 md:grid-cols-2', itemCount > 2 && 'lg:grid-cols-3')}>
        {list}
      </div>
    ) : (
      <div className="container-break-right mb-20">
        {/* currently slider column count is set globally in _component-carousel.scss
          - If required we can make this a per slider variable */}
        <CarouselSwiper id={props.rendering.uid as string}>{list}</CarouselSwiper>
      </div>
    )
  }

  return <CarouselCardsDefaultComponent />
}

// TODO: Check if this component is used
export const VideoCards = (props: CardCarouselProps<VideoCardItem>): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const layoutType = props.fields.LayoutType // .value = Carousel, Stacked
  const itemCount = props.fields.items?.length || 0
  if (itemCount === 0) {
    return (
      <div>
        <InfoPanel heading="Sitecore | Video Card Wrapper" message="Please add Carousel Cards below" />
      </div>
    )
  }
  if (Object.values(props.fields).length) {
    const list = props.fields.items?.map((element: VideoCardItem, key: number) => (
      <VideoCardSlideItem key={key} card={element} />
    ))

    return sitecoreContext.pageEditing || layoutType.value != 'Carousel' ? (
      <div
        data-cols="12"
        className={twJoin(
          'mb-20 grid gap-x-4 gap-y-6 md:grid-cols-2',
          itemCount > 2 && 'lg:grid-cols-3',
          itemCount > 3 && 'xl:grid-cols-4'
        )}
      >
        {list}
      </div>
    ) : (
      <div className="container-break-right mb-20">
        <CarouselSwiper id={props.rendering.uid as string} className="four-up">
          {list}
        </CarouselSwiper>
      </div>
    )
  }

  return <CarouselCardsDefaultComponent />
}

const VideoCardSlideItem = (props: { card: VideoCardItem }): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const router = useRouter()
  const modalRef = useRef<CustomModalRef>()

  const Image = () => <JssImage className="w-full" field={props.card.fields.CardImage} />
  const tags = props.card.fields.VideoCardDuration?.value
    ? ['video', props.card.fields.VideoCardDuration.value as string]
    : ['video']
  const openModal = () => {
    if (modalRef.current) modalRef.current.openModal()
  }
  const onVideoClick = () => {
    if (sitecoreContext.pageEditing) return
    if (props.card.fields.YouTubeVideoLink) {
      openModal()
      return
    } else {
      if (props.card.fields.CardButton.value.target === '_blank') {
        return window.open(props.card.fields.CardButton.value.href, '_blank')
      } else {
        return router.push(props.card.fields.CardButton.value.href as string)
      }
    }
  }

  return (
    <div className="relative">
      <div
        onClick={() => onVideoClick()}
        className="field-link mb-4 w-full cursor-pointer [&_img]:aspect-[16/9] [&_img]:object-cover [&_img]:object-top"
      >
        <div className="opacity-overlay dark absolute left-0 top-0 flex w-full items-center p-4 text-white shadow-[0_0_20px_20px_rgba(0,0,0,.6)]">
          <ServiceTag service={props.card.fields.CardService.value as string} isCard={true} />
          <StandardTagList className="ml-auto dark:text-white" tags={tags} />
        </div>
        <Image />
      </div>
      {props.card.fields.CardTitle && <h5 className="lbody mb-3 font-normal">{props.card.fields.CardTitle.value}</h5>}
      {props.card.fields.VideoCardButtonText &&
        (props.card.fields.YouTubeVideoLink ? (
          <>
            <button className="sbody flex items-center gap-2" onClick={() => openModal()}>
              <Icon name="play" width={14} height={14} /> <span>{props.card.fields.VideoCardButtonText.value}</span>
            </button>
            <VideoModal
              id={props.card.id}
              ref={modalRef}
              videoId={props.card.fields.YouTubeVideoLink.value as string}
              service={props.card.fields.CardService.value as string}
            />
          </>
        ) : (
          <a href={props.card.url} className="sbody flex items-center gap-2">
            <Icon name="play" width={14} height={14} /> <span>{props.card.fields.VideoCardButtonText.value}</span>
          </a>
        ))}
    </div>
  )
}
