import React, { ReactNode, useState } from 'react'
import { ComponentParams, ComponentRendering, Field, RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs'
import axios from 'axios'
import { DefaultValues, SubmitHandler, UseControllerProps } from 'react-hook-form'
import { ContactusFormResponse } from 'src/pages/api/contactus/submitEnquiryForm'
import { ContactMethod, track } from 'src/utils/tracking'

import { StrokeIcons } from 'components/front-end/Icons/icon-data'
import ContactUsPreferences, { ContactUsPreferencesProps } from './front-end/ContactUs/Preferences'
import Header from './front-end/Drawers/drawer-components/Header'
import {
  Captcha,
  Checkbox,
  ErrorMessage,
  Fieldset,
  Form,
  Input,
  parseOptionFields,
  Radio,
  Select,
  Submit,
  SuccessMessage,
  TextArea,
} from './front-end/Forms'
import { emailRegex, mobileRegex, postcodeRegex } from './front-end/Forms/constants'
import { InputOption, KeyValueDefinition } from './front-end/Forms/types'

export interface Fields {
  ErrorMessage: Field<string>
  ErrorMsgAgreePrivacyStatement: Field<string>
  ErrorMsgCaptcha: Field<string>
  ErrorMsgContactMeby: Field<string>
  ErrorMsgEmail: Field<string>
  ErrorMsgFirstName: Field<string>
  ErrorMsgForm: Field<string>
  ErrorMsgPhoneNumber: Field<string>
  ErrorMsgPostcode: Field<string>
  ErrorMsgService: Field<string>
  ErrorMsgSurname: Field<string>
  ErrorMsgWorkType: Field<string>
  FormHeading: Field<string>
  Icon: Field<StrokeIcons>
  LblAgreePrivacyStatement: RichTextField
  LblCaptcha: Field<string>
  LblContactMeBy: Field<string>
  LblEmail: Field<string>
  LblFirstname: Field<string>
  LblIdentity: Field<string>
  LblMessage: Field<string>
  LblMethodOfEntry: Field<string>
  LblCitizenship: Field<string>
  LblOrigin: Field<string>
  LblPhoneNumber: Field<string>
  LblPostcode: Field<string>
  LblRecieveUpdates: Field<string>
  LblSectionAboutYou: Field<string>
  LblSectionContactDetails: Field<string>
  LblSectionHelpUs: Field<string>
  LblService: Field<string>
  LblSubmitButton: Field<string>
  LblSurname: Field<string>
  LblWorkType: Field<string>
  OptionsContactMeBy: Array<KeyValueDefinition>
  OptionsIdentity: Array<KeyValueDefinition>
  OptionsMethodOfEntryFullTime: Array<KeyValueDefinition>
  OptionsMethodOfEntryReserveAirforce: Array<KeyValueDefinition>
  OptionsMethodOfEntryReserveArmy: Array<KeyValueDefinition>
  OptionsMethodOfEntryReserveNavy: Array<KeyValueDefinition>
  OptionsMethodOfEntryReserveUnsure: Array<KeyValueDefinition>
  OptionsCitizenship: Array<KeyValueDefinition>
  OptionsOrigin: Array<KeyValueDefinition>
  OptionsService: Array<KeyValueDefinition>
  OptionsWorkType: Array<KeyValueDefinition>
  PhTextEmail: Field<string>
  PhTextFirstName: Field<string>
  PhTextMessage: Field<string>
  PhTextMethodOfEntry: Field<string>
  PhTextCitizenship: Field<string>
  PhTextOrigin: Field<string>
  PhTextPhoneNumber: Field<string>
  PhTextPostcode: Field<string>
  PhTextService: Field<string>
  PhTextSurname: Field<string>
  PhTextWorkType: Field<string>
  SuccessHeading: Field<string>
  SuccessMsg: RichTextField
}

export type FormValues = {
  agreeToTerms: boolean
  email: string
  firstName: string
  gender: string
  citizenship: InputOption<string>
  indigenousAustralian: InputOption<string>
  methodOfEntry: InputOption<string>
  phoneNumber: string
  postcode: string
  preferedContactMethod: string
  question: string
  receiveUpdates: boolean
  serviceName: InputOption<'unsure' | 'navy' | 'army' | 'airforce'>
  serviceId: number
  lastName: string
  workType: 'fulltime' | 'reserve'
  sCode: string
  captcha: string
}

export type FormFieldDefinition = {
  label: string
  name: string
  placeholder: string
  rules?: UseControllerProps['rules']
}

export type FormFieldOptions = {
  options: InputOption[]
}

export type FormFieldGroupDefinition = FormFieldDefinition & FormFieldOptions

export type MethodOfEntryOptionKey = 'fulltime' | `reserve.${FormValues['serviceName']['value']}`

export type ContactUsFormProps = {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
}

const ADFContactUsForm = (props: ContactUsFormProps): JSX.Element => {
  const formId = props.rendering.uid as string
  const defaultValues: DefaultValues<FormValues> = {
    agreeToTerms: false,
    email: '',
    firstName: '',
    gender: '',
    citizenship: undefined,
    indigenousAustralian: undefined,
    methodOfEntry: undefined,
    phoneNumber: '',
    postcode: '',
    preferedContactMethod: '',
    question: '',
    receiveUpdates: false,
    serviceName: undefined,
    serviceId: undefined,
    lastName: '',
    workType: 'fulltime',
    sCode: '',
    captcha: '',
  }
  const [error, setError] = useState<{ reason: string; details: ReactNode } | undefined>()

  const handleSubmit: SubmitHandler<FormValues> = async ({
    citizenship,
    indigenousAustralian,
    postcode,
    methodOfEntry,
    question,
    serviceName,
    ...form
  }: FormValues) => {
    const services = ['unsure', 'navy', 'army', 'airforce']
    const formData = {
      ...form,
      postcode: postcode || '0000',
      citizenship: citizenship?.label ?? '',
      indigenousAustralian: indigenousAustralian?.value ?? '0',
      methodOfEntryId: methodOfEntry.value,
      methodOfEntry: methodOfEntry.label ?? methodOfEntry.key,
      question: Buffer.from(question).toString('base64'),
      serviceId: services.indexOf(serviceName.value).toString(),
    }

    setError(undefined)

    const api = `${process.env.DIRECT_APP_API_HOST}/api/contactus/submitEnquiryForm`

    try {
      const { data } = await axios.post<ContactusFormResponse>(api, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!data.success) {
        throw new Error(data.error)
      }

      track({
        event: 'form_submission',
        form_name: 'contact us',
        contact_us_medium: form.preferedContactMethod as ContactMethod,
      })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError({
          reason: err.message || 'Unknown error',
          details: props.fields.ErrorMsgForm.value || err.code || '',
        })
      }
      console.error(err)
    }
  }

  const methodOfEntryOptions = {
    fulltime: parseOptionFields(props.fields.OptionsMethodOfEntryFullTime),
    'reserve.unsure': parseOptionFields(props.fields.OptionsMethodOfEntryReserveUnsure),
    'reserve.navy': parseOptionFields(props.fields.OptionsMethodOfEntryReserveNavy),
    'reserve.army': parseOptionFields(props.fields.OptionsMethodOfEntryReserveArmy),
    'reserve.airforce': parseOptionFields(props.fields.OptionsMethodOfEntryReserveAirforce),
  } as ContactUsPreferencesProps['methodOfEntry']['options']

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      successMessage={
        !error ? (
          <SuccessMessage
            id={formId}
            icon={props.fields.Icon.value}
            heading={props.fields.SuccessHeading.value}
            message={props.fields.SuccessMsg}
            rendering={props.rendering}
          />
        ) : null
      }
    >
      <Header text={props.fields.FormHeading.value} />

      <Fieldset>
        <TextArea
          label={props.fields.LblMessage.value}
          name="question"
          placeholder={props.fields.PhTextMessage.value}
          rules={{ required: true }}
        />
      </Fieldset>

      <ContactUsPreferences
        heading={props.fields.LblSectionHelpUs.value}
        workType={{
          label: props.fields.LblWorkType.value,
          name: 'workType',
          placeholder: props.fields.PhTextWorkType.value,
          options: parseOptionFields(props.fields.OptionsWorkType),
          rules: { required: props.fields.ErrorMsgWorkType.value },
        }}
        postcode={{
          label: props.fields.LblPostcode.value,
          name: 'postcode',
          placeholder: props.fields.PhTextPostcode.value,
          rules: {
            pattern: {
              value: postcodeRegex,
              message: props.fields.ErrorMsgPostcode.value,
            },
          },
        }}
        service={{
          label: props.fields.LblService.value,
          name: 'serviceName',
          placeholder: props.fields.PhTextService.value,
          options: parseOptionFields(props.fields.OptionsService),
          rules: { required: props.fields.ErrorMsgService.value },
        }}
        methodOfEntry={{
          label: props.fields.LblMethodOfEntry.value,
          name: 'methodOfEntry',
          placeholder: props.fields.PhTextMethodOfEntry.value,
          options: methodOfEntryOptions,
          rules: { required: 'Please select a method of entry' },
        }}
      />

      <Fieldset legend={props.fields.LblSectionContactDetails.value}>
        <Input
          label={props.fields.LblFirstname.value}
          name="firstName"
          placeholder={props.fields.PhTextFirstName.value}
          rules={{ required: true }}
        />
        <Input
          label={props.fields.LblSurname.value}
          name="lastName"
          placeholder={props.fields.PhTextSurname.value}
          rules={{ required: true }}
        />
        <Input
          label={props.fields.LblPhoneNumber.value}
          name="phoneNumber"
          placeholder={props.fields.PhTextPhoneNumber.value}
          rules={{ required: true, pattern: { value: mobileRegex, message: props.fields.ErrorMsgPhoneNumber.value } }}
        />
        <Input
          label={props.fields.LblEmail.value}
          name="email"
          placeholder={props.fields.PhTextEmail.value}
          rules={{ required: true, pattern: { value: emailRegex, message: props.fields.ErrorMsgEmail.value } }}
        />
        <Radio
          label={props.fields.LblContactMeBy.value}
          name="preferedContactMethod"
          options={parseOptionFields(props.fields.OptionsContactMeBy)}
          rules={{ required: props.fields.ErrorMsgContactMeby.value }}
        />
      </Fieldset>

      <Fieldset legend={props.fields.LblSectionAboutYou.value}>
        <Radio
          label={props.fields.LblIdentity.value}
          name="gender"
          options={parseOptionFields(props.fields.OptionsIdentity)}
        />

        <Select
          label={props.fields.LblOrigin.value}
          name="indigenousAustralian"
          options={parseOptionFields(props.fields.OptionsOrigin)}
          placeholder={props.fields.PhTextOrigin.value}
        />

        <Select
          label={props.fields.LblCitizenship.value}
          name="citizenship"
          options={parseOptionFields(props.fields.OptionsCitizenship)}
          placeholder={props.fields.PhTextCitizenship.value}
        />
      </Fieldset>

      <div className="flex flex-col gap-y-6 [&_a]:underline">
        <Checkbox label={props.fields.LblRecieveUpdates.value} name="receiveUpdates" />
        {props.fields.LblAgreePrivacyStatement.value ? (
          <Checkbox
            label={<RichText tag="span" field={props.fields.LblAgreePrivacyStatement} />}
            name="agreeToTerms"
            rules={{ required: props.fields.ErrorMsgAgreePrivacyStatement.value }}
          />
        ) : null}
      </div>

      <Captcha name="captcha" rules={{ required: props.fields.ErrorMsgCaptcha.value }} />

      {/* Possible redundant field - appears to always be an empty string on the existing contact form */}
      <Input name="sCode" type="hidden" />

      {error ? <ErrorMessage heading={error.reason}>{error.details}</ErrorMessage> : null}

      <Submit id={formId} label={props.fields.LblSubmitButton.value} />
    </Form>
  )
}
export default ADFContactUsForm
