import { useState } from 'react'

import Divider from '../Drawers/drawer-components/Divider'
import Icon from '../Icons/Icon'

type ListLinkProps = {
  text: string
  subTitleText?: string
}

const ListLink = ({ text, subTitleText = '' }: ListLinkProps): JSX.Element => {
  const [isHover, setIsHover] = useState(false)

  return (
    <>
      <div
        className={`flex w-full cursor-pointer flex-col py-5 icon-hover-${isHover}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {subTitleText && <div className="stag pb-0.5 text-grey-dark">{subTitleText}</div>}
        <div className={`adf-list flex w-full items-center`}>
          <div className={`mbody w-full pr-6`}>{text}</div>
          <Icon name="chevron-right-double" type="stroke" width={18} height={18} hover />
        </div>
      </div>
      <Divider />
    </>
  )
}

export default ListLink
