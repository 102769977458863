import { useEffect, useRef, useState } from 'react'
import { trackDropdown } from 'src/utils/tracking'

import ToggleButton from 'components/front-end/Buttons/ToggleButton'
import Icon from 'components/front-end/Icons/Icon'

export type FormFieldProps = {
  name?: string
  type?: string
  label?: string
  placeholder?: string
  value?: string
  errorState?: boolean
  successState?: boolean
  errorMessage?: string
  successMessage?: string
  activeClass?: string
  clicked?: boolean
  enable?: boolean
  isdisabled?: boolean
  checked?: boolean
  options?: { value: string; label: string; active?: boolean }[] // Added options prop
  mainText?: JSX.Element | string
  secondaryText?: string
  onClick?: (value: string | boolean | undefined) => void
  onBlur?: (value: string | boolean | undefined) => void
  onBlurCapture?: () => void
  onChange?: (value: string | boolean | undefined) => void // Add onChange prop
  defaultSelectedValue?: string // Add this line
}

const FormField = ({
  defaultSelectedValue,
  type,
  name,
  label,
  placeholder,
  value,
  options = [], // Added options prop with a default value of an empty array
  onChange,
  errorState = false,
  successState = false,
  errorMessage = '',
  successMessage = '',
  clicked,
  isdisabled,
  enable = false,
  mainText = '',
  secondaryText = '',
  onClick = () => undefined,
  onBlurCapture = () => undefined,
}: FormFieldProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const [isFocused, setIsFocused] = useState(false)
  const [isOptionOpen, setIsOptionOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value || defaultSelectedValue || '')
  const [selectedLabel, setSelectedLabel] = useState(value || '')
  const [isRadioActive, setIsRadioActive] = useState(() => {
    const activeOption = options.find((option) => option.active)
    return activeOption ? activeOption.value : ''
  })
  const [isClicked, setIsClicked] = useState(clicked || false) // State to track if the text is clicked
  const [selectIcon, setSelectIcon] = useState<'chevron-up' | 'chevron-down'>('chevron-up') // Initial icon for closed state

  //function to check the borders of an input
  const mainDiv = 'my-4'
  const borderColorClass = () => {
    let classes = `border py-4 pr-4 pl-4 relative
            ${isdisabled ? 'bg-[#f8f8f8]' : 'bg-white'}
        `

    // if the class have error state
    if (errorState) {
      classes += ' border-error'
    } else {
      // if the class doesn't have error state
      if (isFocused) {
        classes += ' border-grey'
      } else {
        classes += ' border-grey-light'
      }
    }
    return classes
  }
  const inputLabel = 'stag block text-sm font-bold mb-2 text-black'
  const input = `mbody  ${
    isdisabled ? 'bg-[#f8f8f8]' : 'bg-transparent'
  } outline-none border-none w-full text-black leading-tight`
  const errorIcon = 'ml-2 w-4 h-4 text-red-50 top-1/2 transform -translate-y-3 right-4 absolute'
  const chevronIcon = 'ml-2 w-4 h-4 text-red-50 top-1/2 transform -translate-y-1/2 right-5 absolute'
  const successIcon = 'ml-2 w-4 h-4 text-red-50 top-1/2 transform -translate-y-3 right-4 absolute'
  const clickedIcon = `text-black inline-flex shrink-0 radio-selected svg-clicked ${isClicked ? 'clicked-active' : ''}`
  const errorMessageClass = `xsbody-bold pl-4 mt-2 text-black`
  const checkboxErorrClass = `${errorState ? 'text-error' : ''}`
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1)
  const [isInputReadOnly, setIsInputReadOnly] = useState(false)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && isOptionOpen) {
      // If the options are open and TAB is pressed, prevent the default behavior (tabbing to the next input)
      e.preventDefault()

      // Calculate the next highlighted option index based on the current index
      let nextIndex = highlightedOptionIndex + 1
      console.log(nextIndex, 'next index')
      if (nextIndex >= options.length) {
        nextIndex = 0 // Wrap around to the first option if reaching the end
      }
      setHighlightedOptionIndex(nextIndex)
      console.log(highlightedOptionIndex, 'highlighted index')
    } else if (e.key === 'Enter' && highlightedOptionIndex !== -1) {
      // If ENTER is pressed and an option is highlighted, select it
      handleOptionSelect(options[highlightedOptionIndex])
      console.log(options[highlightedOptionIndex].value, 'value')
    }
  }

  const handleFocus = () => {
    setIsFocused(true)
    // console.log("i am here");
  }

  const handleOption = () => {
    setIsOptionOpen(!isOptionOpen)
    setIsInputReadOnly(!isOptionOpen)
    trackDropdown(name || label || placeholder || 'dropdown')
  }

  useEffect(() => {
    // Update the selectIcon based on the new value of isOptionOpen
    setSelectIcon(isOptionOpen ? 'chevron-up' : 'chevron-down')
    // Reset the highlighted option index when the options are closed
    if (!isOptionOpen) {
      setHighlightedOptionIndex(-1)
    }
  }, [isOptionOpen])

  const handleBlur = () => {
    setIsFocused(false)
    //setIsOptionOpen(false);
  }

  const handleOptionSelect = (selectedOption: { value: string; label: string }) => {
    setSelectedValue(selectedOption.value) // Update the selectedValue
    setSelectedLabel(selectedOption.label)
    setIsOptionOpen(false)

    const inputElement = document.activeElement as HTMLElement
    if (inputElement) {
      inputElement.blur()
    }

    if (onChange) {
      onChange(selectedOption.value) // Pass the selectedOption to the onChange callback
    }
  }

  const handleRadioChange = (value: string) => {
    setIsRadioActive(value)
    setIsClicked(value === isRadioActive)
    if (onChange) {
      onChange(value)
    }
  }

  useEffect(() => {
    console.log('FormField Value/Clicked update', value, isClicked)
    setSelectedValue(value || '') // Update the selectedValue whenever the value prop changes
  }, [value, isClicked])

  useEffect(() => {
    const handleResetForm = () => {
      setSelectedValue('')
      setIsRadioActive('')
    }
    const form = ref.current?.closest('form')
    form?.addEventListener('reset', handleResetForm)
    return () => {
      form?.removeEventListener('reset', handleResetForm)
    }
  }, [defaultSelectedValue, options])

  // Define a separate handleChange function for textarea
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }
  return (
    <div ref={ref}>
      {type === 'select' && (
        <>
          <div className={mainDiv}>
            <div className={borderColorClass()}>
              <label className={inputLabel} htmlFor="label">
                {label ?? 'LABEL'}
              </label>
              <div>
                <div className="">
                  <input
                    className={`${input}  hover:cursor-pointer`}
                    disabled={isdisabled ? true : false}
                    onFocus={handleOption}
                    onBlur={handleBlur}
                    type="text"
                    value={selectedLabel}
                    placeholder={placeholder}
                    readOnly={isInputReadOnly} // Set the input field to read-only when options are open
                    tabIndex={enable ? 0 : -1} // Set tabIndex based on the 'enable' prop
                    onKeyDown={handleKeyDown}
                    onChange={() => undefined}
                  />

                  <input
                    hidden
                    disabled={isdisabled ? true : false}
                    className={`${input} hover: cursor-pointer`}
                    type="text"
                    placeholder={placeholder}
                    value={selectedValue}
                    onChange={handleChange}
                  />
                  <div className={chevronIcon}>
                    <Icon
                      name={selectIcon}
                      type="stroke"
                      className={`clicked-active ${isOptionOpen ? 'clicked' : ''}`}
                      clicked={isOptionOpen}
                      onClick={handleOption} // Add this line to trigger the dropdown when the icon is clicked
                    />
                  </div>
                </div>
                <ul
                  className={`
                                    ${isOptionOpen ? 'border' : ''}
                                    mbody absolute left-0 right-0 z-10 -mx-px mt-1 border-grey-light bg-white
                                    ${
                                      isOptionOpen
                                        ? 'transition-max-height max-h-[1000px] duration-500 ease-in-out'
                                        : 'max-h-0'
                                    }
                                    overflow-hidden
                                `}
                >
                  {isOptionOpen &&
                    options.map((option, index) => (
                      <li
                        key={option.value}
                        className={`cursor-pointer px-4 py-3 hover:bg-[#f8f8f8] ${
                          index === highlightedOptionIndex ? 'bg-[#f8f8f8]' : ''
                        }`}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.label}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            {errorState && <p className={errorMessageClass}>{errorMessage}</p>}
            {successState && <p className="xsbody-bold text-green-500 mt-2 pl-4">{successMessage}</p>}
          </div>
        </>
      )}
      {(type === 'text' || type === 'number' || type === 'tel') && (
        <>
          <div className={mainDiv}>
            <div className={borderColorClass()} onBlur={() => onBlurCapture()}>
              <label className={inputLabel} htmlFor="label">
                {label ?? 'LABEL'}
              </label>
              <input
                disabled={isdisabled ? true : false}
                className={input}
                type={type}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />

              {errorState && (
                <span className={errorIcon}>
                  <Icon name="error" className="text-error" />
                  {/* <Icon name="radio-selected" type="stroke" clicked className='text-army'/> */}
                </span>
              )}
              {successState && (
                <span className={successIcon}>
                  <Icon name="check-circle" className="text-success" />
                </span>
              )}
            </div>
            {errorState && <p className={errorMessageClass}>{errorMessage}</p>}
            {successState && <p className="xsbody-bold text-green-500 mt-2 pl-4">{successMessage}</p>}
          </div>
        </>
      )}
      {type === 'textarea' && (
        <>
          <div className={mainDiv}>
            <div className={borderColorClass()}>
              <label className={inputLabel} htmlFor="label">
                {label ?? 'LABEL'}
              </label>
              <textarea
                disabled={isdisabled ? true : false}
                className={`${input} h-24 pt-3`}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={(e) => handleTextareaChange(e)}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />

              {errorState && (
                <span className={errorIcon}>
                  <Icon name="error" className="text-error" />
                  {/* <Icon name="radio-selected" type="stroke" clicked className='text-army'/> */}
                </span>
              )}
              {successState && (
                <span className={successIcon}>
                  <Icon name="check-circle" className="text-success" />
                </span>
              )}
            </div>
            {errorState && <p className={errorMessageClass}>{errorMessage}</p>}
            {successState && <p className="xsbody-bold text-green-500 mt-2 pl-4">{successMessage}</p>}
          </div>
        </>
      )}
      {type == 'radio' && (
        <div className={mainDiv}>
          <div>
            <fieldset>
              {label && <legend className="mb-4 font-semibold">{label}</legend>}
              <div className="flex gap-1">
                {options.map((option, i) => {
                  return (
                    <label
                      className={`radio-selected svg-clicked flex shrink-0 gap-1 pr-2 text-black ${
                        isRadioActive === option.value ? 'clicked-active' : ''
                      }`}
                      key={i}
                    >
                      <input
                        className="peer sr-only"
                        type="radio"
                        name={name}
                        id={option.label}
                        value={option.value}
                        checked={isRadioActive === option.value}
                        onChange={() => handleRadioChange(option.value)}
                      />
                      <Icon
                        className="inline-block rounded-full peer-focus-visible:outline peer-focus-visible:outline-grey"
                        name="radio-selected"
                        type="stroke"
                        clicked={false}
                      />
                      <span>{option.label}</span>
                    </label>
                  )
                })}
              </div>
            </fieldset>
            {/* Do we need error/success icons on radio and check? */}
            {/* {errorState && (
              <span className={errorIcon}>
                <Icon name="error" className="text-error" />
              </span>
            )}
            {successState && (
              <span className={successIcon}>
                <Icon name="check-circle" className="text-success" />
              </span>
            )} */}
          </div>
          {errorState && <p className={errorMessageClass}>{errorMessage}</p>}
          {successState && <p className="xsbody-bold text-green-500 mt-2 pl-4">{successMessage}</p>}
        </div>
      )}

      {type === 'checkbox' && (
        <>
          <div className="item-center flex">
            <label className="item-center flex">
              {/* button is not valid child of label */}
              <button
                className={clickedIcon}
                onClick={(e) => {
                  e.preventDefault() // Prevent default behavior
                  setIsClicked(!isClicked)
                }}
              >
                <Icon name="checkbox" type="stroke" clicked={isClicked} className={checkboxErorrClass} />
              </button>
              {typeof mainText === 'string' ? (
                <span
                  className="text-gray-700 mbody inline-block cursor-pointer pl-2"
                  dangerouslySetInnerHTML={{ __html: mainText.replace(/<a/g, '<a class="underline"') }}
                />
              ) : (
                mainText
              )}
            </label>
          </div>
          {successState && <p className="xsbody-bold text-green-500 mt-2 pl-4">{successMessage}</p>}
          {errorState && <p className={errorMessageClass}>{errorMessage}</p>}
        </>
      )}

      {type == 'toggle' && (
        <div className="flex py-5">
          <div
            className={`${clickedIcon}`}
            onClick={() => setIsClicked(!isClicked)} // Toggle the isClicked state on text click
          >
            <div className="text-gray-700 mbody mr-2 inline-block cursor-pointer">
              <span className="text-gray-700 mbody inline-block cursor-pointer"> {mainText} </span>
              <br></br>
              {secondaryText && (
                <span className="text-gray-700 sbody inline-block cursor-pointer">{secondaryText} </span>
              )}
            </div>
            <ToggleButton enabled={enable} />
          </div>
        </div>
      )}
    </div>
  )
}

export default FormField
