import { useFAQContext } from './FAQContext'
import { FAQListGroup } from './FAQList'

type FAQTopicListProps = {
  title: string
}

const FAQTopicList = ({ title }: FAQTopicListProps) => {
  const { lookup } = useFAQContext()

  return (
    <>
      {title && <h4 className="mb-4">{title}</h4>}
      {lookup
        .faqsGroupedBySubcategory()
        .filter(({ faqs }) => faqs.length)
        .map(({ heading, faqs }, _, groups) => (
          <FAQListGroup key={heading} heading={groups.length > 1 ? heading : ''} faqs={faqs} />
        ))}
    </>
  )
}

export default FAQTopicList
