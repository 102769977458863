import { createContext, Dispatch, ReactNode, useCallback, useContext, useReducer } from 'react'
import { Service } from 'src/utils/useService'

import FAQDrawer from './FAQDrawer'
import { buildLookupData, FAQLookupData } from './FAQHelpers'
import { FAQActions, faqReducer, FAQState } from './FAQReducer'
import { FAQData } from './FAQTypes'

type FAQContextProps = { state: FAQState; send: Dispatch<FAQActions>; lookup: FAQLookupData }

type FAQProviderProps = {
  children: ReactNode
  defaultState?: FAQState
  data: FAQData
  service?: Service
}

const faqContext = createContext<FAQContextProps | null>(null)

export const useFAQContext = () => {
  const ctx = useContext(faqContext)
  if (!ctx) {
    throw new Error('useFAQContext must be used within a FAQProvider')
  }
  return ctx
}

export default function FAQProvider({ defaultState, data, service = 'tri-service', children }: FAQProviderProps) {
  const initialState: FAQState = {
    query: '',
    results: [],
    selectedFaqId: '',
    selectedCategoryId: '',
    selectedSubcategoryId: '',
    selectedHeading: '',
    previousSearch: { query: '', results: [] },
    ...defaultState,
  }
  const lookup = buildLookupData(data)
  const [state, dispatch] = useReducer(faqReducer(lookup), initialState)
  const send = useCallback((action: FAQActions) => dispatch(action), [])

  return (
    <faqContext.Provider value={{ state, send, lookup }}>
      {children}
      <FAQDrawer service={service} />
    </faqContext.Provider>
  )
}
