import React, { useMemo, useState } from 'react'
import { Field, RichText, RichTextField, Text } from '@sitecore-jss/sitecore-jss-nextjs'

import { JobComparisonProps } from 'components/ADFJobComparison'
import { stripHtml } from 'utils/stripHTML'
import Button from '../Buttons/Button'
import Drawer from '../Drawers/Drawer'
import { Job, useShortlist } from '../Shortlist/ShortlistProvider'
import ServiceTag from '../Tags/ServiceTag'
import StandardTag from '../Tags/StandardTag'
import ComparisonTable, { ComparedItems, ComparisonTableProps } from './ComparisonTable'
import { useCareerOptions } from './useCareerOptions'

interface JobComparisonTableProps {
  maxSelected: number
  fields: JobComparisonProps['fields']
  jobsOverride?: JobComparison[]
}

interface HelpfulInfo {
  title?: string
  content?: string
  show?: boolean
}

export interface JobComparison extends Job {
  method: {
    id: string
    title: string
    description: string
    longDescription: string
    isClosed: boolean
    salary: {
      startingPay: {
        name: string
        title: string
        perks: string
        amount: string
        isDaily: boolean
      }
      trainedPay: {
        name: string
        title: string
        perks: string
        amount: string
        isDaily: boolean
      }
    }
    compareRequirements: {
      ageRange: string
      allowances: string
      educationExperience: string
      employmentTraining: string
      fitnessRequirements: string
      furtherTraining: string
      initialMilitaryTraining: string
      jobDescription: string
      minimumServicePeriod: string
      jobComparisonStartingSalaryHeader: string
    }
  }
}

export default function JobComparisonTable({ fields, maxSelected, jobsOverride }: JobComparisonTableProps) {
  const [_, selectedOptions] = useCareerOptions() ?? [[], []]
  const [helpfulInfo, setHelpfulInfo] = useState<HelpfulInfo | undefined>()
  const { jobs } = useShortlist()

  const hideHelpfulInfo = (): void => {
    setHelpfulInfo((info): HelpfulInfo => ({ ...info, show: false }))
  }

  const richTextReset = `[&_ul]:list-disc [&_ul]:pl-4 [&_ol]:list-decimal [&_ol]:pl-4
    [&_p:not(:last-child)]:mb-5 [&_ul:not(:last-child)]:mb-5 [&_ol:not(:last-child)]:mb-5 
  `
  const comparedItems: ComparisonTableProps['data'] = useMemo((): ComparedItems => {
    const selectedEntryMethods = jobsOverride?.map((job) => job.method.id) ?? selectedOptions

    const roles = jobsOverride ?? jobs?.flatMap((job) => job.entryMethods.map((method) => ({ ...job, method })))

    const showHelpfulInfo = (title: string, content: string): void => {
      setHelpfulInfo({ title, content, show: true })
    }

    const RowTitle = ({ label, helpfulInfo }: { label: Field<string>; helpfulInfo?: RichTextField }) => {
      const info = helpfulInfo?.value
      const helpfulInfoLinkLabel = fields.HelpfulInfoLinkText.value
      return (
        <div className="flex flex-col items-start gap-y-0.5">
          <RichText tag="div" field={label}  class={richTextReset} />
          {info && helpfulInfoLinkLabel ? (
            <button
              data-info={stripHtml(info)}
              className="font-normal underline hover:no-underline"
              onClick={() => showHelpfulInfo(label.value, info)}
            >
              {helpfulInfoLinkLabel}
            </button>
          ) : null}
        </div>
      )
    }

    const selectedRoles =
      selectedEntryMethods
        ?.slice()
        .map((id) => roles?.find((role) => role.method.id === id) as JobComparison)
        .filter(Boolean)
        .slice(0, maxSelected) ?? []

    return [
      [
        <Text tag="h2" className="role-section-title h6 text-nowrap normal-case" field={fields.RoleSectionTitle} key="RoleSectionTitle" />,
        [
          [
            <RowTitle key="ServiceLabel" label={fields.ServiceLabel} />,
            ...selectedRoles.map(({ service }, i) => (
              <ServiceTag key={service + i} className="font-semibold md:py-3" isCard service={service} />
            )),
          ],
          [
            <RowTitle key="RoleNameLabel" label={fields.RoleNameLabel} />,
            ...selectedRoles.map(({ title, isPriority }, i) => (
              <div key={i} className="flex flex-col items-start gap-y-0.5 md:py-3">
                <div key={'title' + i} className="mbody-bold text-black">
                  {title}
                </div>
                {isPriority ? <StandardTag text="Priority role" /> : null}
              </div>
            )),
          ],
          [
            <RowTitle key="JobDescriptionLabel" label={fields.JobDescriptionLabel} />,

            ...selectedRoles.map(({ method }, i) => <div key={i}>{method.compareRequirements.jobDescription}</div>),
          ],
          [
            <RowTitle key="CommitmentLevelLabel" label={fields.CommitmentLevelLabel} />,
            ...selectedRoles.map(({ isReserve }, i) => (
              <div key={i} className="flex items-center">
                <StandardTag text={isReserve ? 'Reserve' : 'Full Time'} />
              </div>
            )),
          ],
          [
            <RowTitle key="EntryMethodLabel" label={fields.EntryMethodLabel} />,
            ...selectedRoles.map(({ method }, i) => <div key={i}>{method.title}</div>),
          ],
        ],
      ],
      [
        <Text
          tag="h2"
          className="h6 text-nowrap normal-case"
          field={fields.SalarySectionTitle}
          key="SalarySectionTitle"
        />,
        [
          [
            <RowTitle
              key="StartingPayLabel"
              label={fields.StartingPayLabel}
              helpfulInfo={fields.StartingPayHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => {

              const startingPayAmount = Number(method.salary.startingPay?.amount ?? '0');
              return (
                <div key={i}>
                  {method.salary.startingPay && startingPayAmount > 0 ? (
                    <>
                      <div>
                        {Number(method.salary.startingPay.amount).toLocaleString('en-AU', {
                          style: 'currency',
                          currency: 'AUD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                        {`${method.salary.startingPay.isDaily ? ' per day' : ' per year'}`}
                      </div>
                      <div>{method.salary.startingPay.perks}</div>
                    </>
                  ) : (<>
                    <div>{method.compareRequirements.jobComparisonStartingSalaryHeader}</div>
                  </>)
                  }
                </div>
              )
            }),
          ],
          [
            <RowTitle
              key="TrainedPayLabel"
              label={fields.TrainedPayLabel}
              helpfulInfo={fields.TrainedPayHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                {method.salary.trainedPay ? (
                  <>
                    <div>
                      {Number(method.salary.trainedPay.amount).toLocaleString('en-AU', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      {`${method.salary.trainedPay.isDaily ? ' per day' : ' per year'}`}
                    </div>
                    <div>{method.salary.trainedPay.perks}</div>
                  </>
                ) : null}
              </div>
            )),
          ],
          [
            <RowTitle
              key="AllowancesLabel"
              label={fields.AllowancesLabel}
              helpfulInfo={fields.AllowancesHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.allowances }} class={richTextReset} />
              </div>
            )),
          ],
          [
            <RowTitle
              key="InitialMilitaryTrainingLabel"
              label={fields.InitialMilitaryTrainingLabel}
              helpfulInfo={fields.InitialMilitaryTrainingHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.initialMilitaryTraining }} class={richTextReset} />
              </div>
            )),
          ],
          [
            <RowTitle
              key="EmploymentTraining"
              label={fields.EmploymentTrainingLabel}
              helpfulInfo={fields.EmploymentTrainingHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.employmentTraining }} class={richTextReset} />
              </div>
            )),
          ],
          [
            <RowTitle
              key="FurtherTraining"
              label={fields.FurtherTrainingLabel}
              helpfulInfo={fields.FurtherTrainingHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.furtherTraining }} class={richTextReset} />
              </div>
            )),
          ],
        ],
      ],
      [
        <Text
          tag="h2"
          className="h6 text-nowrap normal-case"
          field={fields.EligibilitySectionTitle}
          key="SalarySectionTitle"
        />,
        [
          [
            <RowTitle key="AgeRangeLabel" label={fields.AgeRangeLabel} helpfulInfo={fields.AgeRangeHelpfulInfo} />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.ageRange }} class={richTextReset} />
              </div>
            )),
          ],
          [
            <RowTitle
              key="EducationExperienceLabel"
              label={fields.EducationExperienceLabel}
              helpfulInfo={fields.EducationExperienceHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.educationExperience }} class={richTextReset} />
              </div>
            )),
          ],
          [
            <RowTitle
              key="FitnessRequirementsLabel"
              label={fields.FitnessRequirementsLabel}
              helpfulInfo={fields.FitnessRequirementsHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.fitnessRequirements }} class={richTextReset} />
              </div>
            )),
          ],
          [
            <RowTitle
              key="MinimumServicePeriodLabel"
              label={fields.MinimumServicePeriodLabel}
              helpfulInfo={fields.MinimumServicePeriodHelpfulInfo}
            />,
            ...selectedRoles.map(({ method }, i) => (
              <div key={i}>
                <RichText field={{ value: method.compareRequirements.minimumServicePeriod }} class={richTextReset} />
              </div>
            )),
          ],
          [
            <RowTitle key="MoreInformationLabel" label={fields.MoreInformationLabel} />,
            ...selectedRoles.map(({ id, url, title }) => (
              <Button
                data-trackingid="job-comparison-read-more"
                data-trackinglabel={title}
                key={id}
                link={{ value: { text: fields.RoleDetailsButtonText.value, href: url } }}
                type="tertiary"
                icon={fields.RoleDetailsButtonIcon.value}
              />
            )),
          ],
        ],
      ],
    ]
  }, [fields, jobs, jobsOverride, maxSelected, selectedOptions])

  return (
    <>
      <ComparisonTable data={comparedItems} />
      <Drawer
        show={helpfulInfo?.show ?? false}
        title={helpfulInfo?.title ?? ''}
        closeDrawer={() => hideHelpfulInfo()}
        hideFooter
      >
        {helpfulInfo?.content ? (
          <RichText className="flex w-full flex-col gap-y-4 pt-10" field={{ value: helpfulInfo.content }} class={richTextReset} />
        ) : null}
      </Drawer>
    </>
  )
}
