import React, { useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import MainNavBack from 'components/front-end/Navigation/MainNavBack'
import Tab from 'components/front-end/Tabs/Tab'
import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'

export interface Item {
  title: string
  tag?: string
  content: JSX.Element
}

export interface SectionTabProps {
  tabs: Item[]
  extended?: boolean
}

const SectionTabs = (props: SectionTabProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const [tab, setTab] = useState<number>(0)
  const [mobOpen, setMobOpen] = useState<boolean>(false)

  const isEdit = sitecoreContext.pageState === 'edit'

  const type = props.extended ? 'extended' : 'standard'

  // const containerStyles = `${props.params?.GridParameters ?? ''} ${props.params?.styles ?? ''}`
  // const styles = `${theme === themes[0]? 'bg-[#f8f8f8]': 'bg-[#2c2c2c]'} ${theme}`
  const gridcols = 12

  return (
    <>
      <div className="relative mt-16">
        <div className={`pb-12 pt-12`}>
          <section className="tab-list border-grey-light xm:border-y [.dark_&]:border-grey-medium">
            <div data-cols={gridcols}>
              <nav className="tab-nav flex flex-wrap xm:flex-nowrap" role="tablist">
                {props?.tabs?.map((x, i) => (
                  <Tab
                    key={i}
                    title={{ value: x.title }}
                    tracking={{
                      component: 'Custom SectionTab',
                      name: `${x?.title}`,
                      // TODO: Parking to move to bugs, get real GUID
                      guid: '1234',
                    }}
                    tag={type == 'extended' ? { value: x.tag as string } : undefined}
                    onClick={() => {
                      setTab(i)
                      setMobOpen(true)
                    }}
                    isSelected={tab == i}
                    isExperience={isEdit}
                  />
                ))}
              </nav>
            </div>
          </section>
        </div>

        {!isEdit && useGreaterThan('xm') && (
          <div className="tab-content relative">
            {props?.tabs?.map((_item, i) => (
              <div key={i} className={`${tab !== i ? 'hidden' : ''}`}>
                {_item.content}
              </div>
            ))}
          </div>
        )}

        {useLessThan('xm') && !isEdit && mobOpen && (
          <div className="tab-content-takeover fixed top-0 z-50 h-full w-full bg-white">
            <div className="tab-content-back mb-5 touch-none border-b border-grey-light">
              <div data-cols={gridcols} className="flex h-16 items-center">
                <div className="flex-1">
                  <MainNavBack title="Back" onClick={() => setMobOpen(false)} />
                </div>
              </div>
            </div>
            <div className={`h-[calc(100%_-_85px)] overflow-auto`}>
              <div>
                {props?.tabs?.map((_item, i) => (
                  <div key={i} className={`${tab !== i ? 'hidden' : ''}`}>
                    {_item.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SectionTabs
