import { twMerge } from 'tailwind-merge'

import Icon from '../Icons/Icon'

interface BackButtonProps extends React.HTMLAttributes<HTMLButtonElement> {}

export default function BackButton({ className, ...props }: BackButtonProps) {
  return (
    <button
      className={twMerge(
        'snav group relative flex h-10 items-center text-black transition duration-300 visited:outline-none focus:outline-none',
        className
      )}
      type="button"
      {...props}
    >
      <Icon name="arrow-west" type="stroke" width={20} height={20} className="shrink-0" />
      <span className="z-[1] ml-2 overflow-hidden text-ellipsis whitespace-nowrap transition duration-300">Back</span>
    </button>
  )
}
