import StatText from '../TextBlocks/StatText'
import { StorySlideProps } from './StorySlide'

const StatsPanel = ({ content }: StorySlideProps) => {
  if (!content) {
    return null
  }

  const statPanelClasses = `
    stat-panel bg-black-off dark
    flex flex-col justify-center
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[560px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[690px]
    xxl:py-[175px] xxl:px-[120px] max-w-[848px]
    ${content.Stat3.value ? '[&_.stat1_.stat-title]:hidden [&_.stat2_.stat-title]:hidden' : ''}
    [&_>_div]:mb-7 [&_>_div]:lg:mb-8 [&_>_div]:xl:mb-11 [&_>_div]:xxl:mb-14
  `
  return (
    <div className={statPanelClasses}>
      {content.Stat1.value && (
        <StatText
          title={content.StatsHeading1.value}
          stat={content.Stat1.value}
          text={content.StatDescription1.value}
          className="stat1"
        />
      )}

      {content.Stat2.value && (
        <StatText
          title={content.StatHeading2.value}
          stat={content.Stat2.value}
          text={content.StatDescription2.value}
          className="stat2"
        />
      )}

      {content.Stat3.value && (
        <StatText stat={content.Stat3.value} text={content.StatDescription3.value} className="stat3 mb-0" />
      )}
    </div>
  )
}

export default StatsPanel
