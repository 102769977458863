import List from '../Lists/List'
import { KeywordSuggestionsResponse } from './SearchTypes'

interface SearchNoResultsProps {
  keywords: KeywordSuggestionsResponse | undefined
  query: string
  type: 'global' | 'degrees' | 'jobs' | 'events'
  showTips?: boolean
  onClick: (word: string) => void
}

const SearchNoResults = ({ type, keywords, query, onClick, showTips }: SearchNoResultsProps): JSX.Element => {
  const popularTerms = keywords?.popularTerms

  const columnLGLessGutters = `
    lg:!pr-[calc(var(--colwidth)-var(--gutter)/2)] lg:!pl-[calc(var(--colwidth)-var(--gutter)/2)]
    xxl:!pr-[calc(var(--colwidth)*2-var(--gutter)/2)] xxl:!pl-[calc(var(--colwidth)*2-var(--gutter)/2)]
  `

  const noResultMessage =
    type === 'degrees' ? (
      `Sorry, there are no results that match your degree selection. Please try again using a different choice of degree.`
    ) : type === 'events' ? (
      'Sorry, there ware no results that match your search or filter choices. Please try again using a different choice of filters.'
    ) : (
      <>
        Your search <strong>{query ?? ''}</strong> did not match any results. Please try adjusting your search.
      </>
    )

  const hasSuggestions = keywords?.items?.some(({ isSuggestion }) => isSuggestion)

  return (
    <>
      {keywords && (
        <div className={`span-12 mb-30 ${columnLGLessGutters}`}>
          {hasSuggestions ? (
            <>
              <span className="lbody-bold mr-2 text-error">Did you mean:</span>
              {keywords?.items?.map(({ word, isSuggestion }) => (
                <>
                  <button key={word} onClick={() => onClick(word)}>
                    <span className={`mr-2 ${isSuggestion ? 'lbody-bold underline' : 'lbody'}`}>{word}</span>
                  </button>{' '}
                </>
              ))}
            </>
          ) : null}
          <div className="row pt-10 md:pt-12">
            <div className="span-12 xm-span-6">
              <div className="h6 mt-5">No results found</div>
              <div className="mbody mt-2 max-w-[402px]">{noResultMessage}</div>
            </div>
            {showTips && (
              <div className="span-12 xm-span-6">
                <div className="h6 mt-10">Helpful search tips</div>
                <div className="mt-5">
                  <List text="Make sure all words are spelt correctly" size="standard" hasTopDivider={true} />
                  <List text="Try different keywords" size="standard" />
                  <List text="Try fewer keywords" size="standard" />
                  {type === 'jobs' && <List text="Use filters to refine your search" size="standard" />}
                </div>
                {popularTerms && (
                  <>
                    <div className="h6 mt-10">Popular search terms</div>
                    <div className="mt-5">
                      {popularTerms
                        ?.split(',')
                        .filter(Boolean)
                        .map((term) => (
                          <>
                            <button key={term.replace(' ', '-')} onClick={() => onClick(term)}>
                              <span className="sbody-bold underline">{term}</span>
                            </button>
                            <span className="last:hidden">{', '}</span>
                          </>
                        ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default SearchNoResults
