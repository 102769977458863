import { useState } from 'react'

import Icon from 'components/front-end/Icons/Icon'
import Divider from './Divider'

export type FilterValue = {
  name: string
  value: string
  queryName?: string
  checked?: boolean
  title?: string
  // allow to implement multi groups select/deselect logic
  groupName?: string
}

type FilterProps = {
  title?: string
  filterValues: FilterValue[]
  onClick?: (value: FilterValue) => void
  isCollapsable?: boolean
  isCollapsed?: boolean
  collapsableTitle?: string
  collapsableSubtitle?: string
  onCollapse?: (value?: boolean) => void
  isRadio?: boolean
  isRadioInline?: boolean
  defaultOption?: string
}

const Filter = ({
  title,
  filterValues,
  onClick = () => undefined,
  isCollapsable = false,
  isCollapsed = false,
  collapsableTitle = '',
  collapsableSubtitle = '',
  onCollapse,
  isRadio = false,
  defaultOption = '',
  isRadioInline,
}: FilterProps): JSX.Element => {
  const clickedClass = !isCollapsed ? 'svg-clicked clicked-active' : ''
  const heightClass = !isCollapsed ? 'max-h-full' : 'max-h-[130px] overflow-hidden'
  const [isRadioActive, setIsRadioActive] = useState(defaultOption)

  const handleRadioChange = (value: FilterValue) => {
    setIsRadioActive(value.value)
    //setIsClicked(value.value === isRadioActive)
    onClick(value)
  }

  const checkboxFilters = !isRadio && !isRadioInline && (
    <div className="flex flex-col">
      {filterValues.map((f) => (
        <div className="pb-4" key={f.value}>
          <div>
            <div className="flex">
              <button
                className={`radio-selected svg-clicked inline-flex shrink-0 ${f.checked ? 'clicked-active' : ''}`}
                onClick={() => onClick?.(f || 'err')}
              >
                <svg
                  className="svg-icon checkbox"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.7 4C3.7 3.83431 3.83432 3.7 4 3.7H20C20.1657 3.7 20.3 3.83431 20.3 4V20C20.3 20.1657 20.1657 20.3 20 20.3H4C3.83431 20.3 3.7 20.1657 3.7 20V4Z"
                    stroke="currentColor"
                    strokeWidth="1.4"
                  />
                  <path d="M7 11.5L10.5 15L17 8.5" stroke="currentColor" strokeWidth="1.4" />
                </svg>
                <span className="text-gray-700 mbody ml-2 inline-block cursor-pointer">{f.name}</span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  const radioFilters = isRadio && (
    <div className="flex flex-col">
      {filterValues.map((option, i) => (
        <div className="pb-4" key={i}>
          <div>
            <div className="flex">
              <label
                className={`radio-selected svg-clicked flex shrink-0 gap-1 pr-2 text-black ${isRadioActive === option.value ? 'clicked-active' : ''
                  }`}
              >
                <input
                  className="peer sr-only"
                  type="radio"
                  name={title}
                  id={option.name}
                  value={option.value}
                  onChange={() => handleRadioChange(option)}
                />
                <Icon
                  className="inline-block rounded-full peer-focus-visible:outline peer-focus-visible:outline-grey"
                  name="radio-selected"
                  type="stroke"
                  clicked={false}
                />
                <span>{option.name}</span>
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  const radioInlineFilters = isRadioInline && (
    <div className="flex flex-col sm:flex-row gap-3">
      {filterValues.map((f) => (
        <div className="sm:pb-4" key={f.value}>
          <div>
            <div className="flex">
              <button
                className={`radio-selected svg-clicked inline-flex shrink-0 ${f.checked ? 'clicked-active' : ''}`}
                onClick={() => onClick?.(f || 'err')}
              >
                <svg
                  className="svg-icon radio-selected"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0001 16.65C13.3001 16.65 14.4001 16.2 15.3001 15.3C16.2001 14.4 16.6501 13.3 16.6501 12C16.6501 10.7 16.2001 9.60001 15.3001 8.70001C14.4001 7.80001 13.3001 7.35001 12.0001 7.35001C10.7001 7.35001 9.6001 7.80001 8.7001 8.70001C7.8001 9.60001 7.3501 10.7 7.3501 12C7.3501 13.3 7.8001 14.4 8.7001 15.3C9.6001 16.2 10.7001 16.65 12.0001 16.65Z"
                    fill="#000000"
                  />
                  <circle cx="12" cy="12" r="8.7" stroke="currentColor" strokeWidth="1.4" />
                </svg>
                <span className="text-gray-700 mbody ml-2 inline-block cursor-pointer">{f.name}</span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div className="pt-6">
      {title && <div className={`stag pb-4 pt-3`}>{title}</div>}
      {radioFilters}
      {radioInlineFilters}

      {isCollapsable && !isRadio && !isRadioInline && (
        <>
          <Divider></Divider>
          <button
            className={`${heightClass} flex w-full cursor-pointer items-center justify-between
             py-5 transition-[max-h] duration-1000`}
            onClick={() => onCollapse?.(!isCollapsed)}
          >
            <div className="flex">
              <div className="sbody-bold flex-none pr-1">{collapsableTitle}</div>
              <div className="sbody text-left text-grey-medium">{collapsableSubtitle}</div>
            </div>
            <Icon className={clickedClass} name="chevron-down" type="stroke" clicked width={18} height={18} />
          </button>
        </>
      )}
      {isCollapsable ? !isCollapsed && checkboxFilters : checkboxFilters}
    </div>
  )
}

export default Filter
