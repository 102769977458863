import { RichText as JssRichText, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'

import Button, { ButtonTypes } from 'components/front-end/Buttons/Button'
import { cleanHeadingStyle, HeadingElementStyle } from 'src/utils/cleanHeadingStyle'
import { useService } from 'src/utils/useService'
import HeaderText, { HeadingLevel } from './HeaderText'

/** TODO:
 * match props to LedeText
 * multiple button support
 * check how copySize is being returned from SC
 * check rowcols in content component
 */

interface TitleProps {
  id: string
  title: string
  text?: string
  CTAButton1?: LinkField
  CTAButton1Theme?: string
  CTAButton2?: LinkField
  CTAButton2Theme?: string
  service?: string
  showButton?: boolean | true
  HeadingLevel?: HeadingLevel
  HeadingStyle?: HeadingElementStyle
  LedeCopySize?: string
}

const TitleText = ({
  id,
  title,
  text,
  CTAButton1,
  CTAButton1Theme,
  CTAButton2,
  CTAButton2Theme,
  service,
  showButton = true,
  // Note - this works the opposite of HeaderText, you pass HeaderStyle and then override it with HeaderLevel.
  // HeaderText is the opposite of TitleText @todo - refactor to be consistent
  HeadingLevel: headingLevelOverride,
  HeadingStyle = 'h2',
  LedeCopySize = 'lbody',
}: TitleProps) => {
  service = useService(service)
  const HeadingLevelStyle = (
    cleanHeadingStyle(HeadingStyle) === 'p' ? 'h2' : cleanHeadingStyle(HeadingStyle)
  ) as HeadingLevel
  const headingLevel = headingLevelOverride ?? HeadingLevelStyle
  const titleTextClasses = `
    title-panel [.dark_&]:!text-grey-light 
    ${headingLevel === 'h1' ? '[&_h1]:mb-8 [&_h1]:break-words' : ''}
    ${headingLevel === 'h2' ? '[&_h2]:mb-8 [&_h2]:break-words' : ''}
    ${headingLevel === 'h3' ? '[&_h3]:mb-8 [&_h3]:break-words' : ''}
    ${headingLevel === 'h4' ? '[&_h4]:mb-8 [&_h4]:break-words' : ''}
    ${headingLevel === 'h5' ? '[&_h5]:mb-8 [&_h5]:break-words' : ''}
    ${headingLevel === 'h6' ? '[&_h6]:mb-8 [&_h6]:break-words' : ''}
  `

  return (
    <div className={titleTextClasses}>
      {title && (
        <HeaderText title={title} showServiceTag={false} headingLevel={headingLevel} headingStyle={HeadingLevelStyle} />
      )}

      {text && (
        <div className={`${LedeCopySize as string} component rich-text`}>
          <JssRichText field={{ value: text }} />
        </div>
      )}
      {showButton && (CTAButton1?.value?.href || CTAButton2?.value?.href) && (
        <div className="mt-6 flex flex-wrap gap-6">
          {CTAButton1?.value?.href && (
            <Button
              data-trackingid={id}
              icon="chevron-right-double"
              link={CTAButton1}
              type={(CTAButton1Theme as ButtonTypes) || 'tertiary'}
              service={service || ''}
            />
          )}
          {CTAButton2?.value?.href && (
            <Button
              data-trackingid={id}
              icon="chevron-right-double"
              link={CTAButton2}
              type={(CTAButton2Theme as ButtonTypes) || 'tertiary'}
              service={service || ''}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TitleText
