import { RefObject, useEffect, useState } from 'react'

type Axis = 'horizontal' | 'vertical'
type ScrollInfo = [boolean, number]

function useScrollInfo(ref: RefObject<HTMLElement>, axis: Axis): ScrollInfo {
  const [scrollInfo, setScrollInfo] = useState<ScrollInfo>([false, 0])

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const checkScrollInfo = () => {
      let hasScroll: boolean
      let scrollPosition: number

      const style = window.getComputedStyle(element)
      const borderTop = parseFloat(style.borderTopWidth) || 0
      const borderBottom = parseFloat(style.borderBottomWidth) || 0
      const borderLeft = parseFloat(style.borderLeftWidth) || 0
      const borderRight = parseFloat(style.borderRightWidth) || 0

      if (axis === 'horizontal') {
        const scrollWidth = element.scrollWidth - borderLeft - borderRight
        const clientWidth = element.clientWidth - borderLeft - borderRight
        hasScroll = scrollWidth > clientWidth
        scrollPosition = hasScroll ? element.scrollLeft / (scrollWidth - clientWidth) : 0
      } else {
        const scrollHeight = element.scrollHeight - borderTop - borderBottom
        const clientHeight = element.clientHeight - borderTop - borderBottom
        hasScroll = scrollHeight > clientHeight
        scrollPosition = hasScroll ? element.scrollTop / (scrollHeight - clientHeight) : 0
      }

      // Ensure scrollPosition is always between 0 and 1, and round to 2 decimal places
      scrollPosition = Math.round(Math.max(0, Math.min(1, scrollPosition)) * 100) / 100

      setScrollInfo([hasScroll, scrollPosition])
    }

    // Check initially
    checkScrollInfo()

    // Set up a resize observer to check when the element's size changes
    const resizeObserver = new ResizeObserver(checkScrollInfo)
    resizeObserver.observe(element)

    // Add scroll event listener
    element.addEventListener('scroll', checkScrollInfo)

    // Clean up the observer and event listener when the component unmounts
    return () => {
      resizeObserver.disconnect()
      element.removeEventListener('scroll', checkScrollInfo)
    }
  }, [ref, axis])

  return scrollInfo
}

export default useScrollInfo
