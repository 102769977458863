import React, { useEffect } from 'react'
import { ComponentRendering, PlaceholdersData } from '@sitecore-jss/sitecore-jss-nextjs'
import { twMerge } from 'tailwind-merge'

import { Default as ADFCardCarousel } from 'components/ADFCardCarousel'
import ADFSectionTab, { SectionTabFields, TabType as SectionTabType } from 'components/ADFSectionTab'
import { SideTabData, TabType } from 'components/ADFSideTab'
import JobDetailsTypes, { EntryMethod, SalaryInformation } from 'components/front-end/JobDetails/_JobInterface'
import JobTabHeader from 'components/front-end/JobDetails/JobTabHeader'
import { MAX_SIDENAV_SUB_TABS, MAX_SIDENAV_TABS } from 'components/front-end/Navigation/SideNav'
import slugify from 'utils/slugifyStrings'
import { Service } from 'utils/useService'
import { buildTitle, create } from './contentBuilder'
import JobTabPanel from './JobTabPanel'

type PlaceholderIndex = `placeholder${number}-${number}`
type ComponentRenderingWithFields<T = Record<string, unknown>, P extends string = PlaceholderIndex> = Omit<
  Required<ComponentRendering>,
  'fields' | 'placeholdera'
> & {
  fields: T
  placeholders: PlaceholdersData<P>
}

const JobWaysToJoin = ({
  responseData,
  animateClasses: [animateRightClasses, animateDownClasses] = [],
  id,
}: JobDetailsTypes): JSX.Element | null => {
  useEffect(() => {
    const sideTabsAll = document.querySelectorAll('button.nav-item, button.nav-title')
    const scrollToContent = () => {
      const anchor = document.querySelector('.section-tab-anchor')
      if (anchor) {
        const rect = anchor.getBoundingClientRect();
        // 89 sticky navigation + 130 global navigation - 48 table padding 
        const scrollTo2 = window.scrollY + (rect.top - 158)
        window.scrollTo({top: scrollTo2, behavior: 'smooth'});
      }
    }
    sideTabsAll.forEach((item) => {
      item.addEventListener('click', scrollToContent)
    })

    return () => {
      sideTabsAll.forEach((item) => {
        item.removeEventListener('click', scrollToContent)
      })
    }
  })

  if (!responseData) {
    return null
  }

  const { entryMethods = [], service, isReserve } = responseData

  // Create content for each Entry Method tab
  const sectionTabContentPlaceholders = entryMethods.reduce(
    (acc, entryMethod: EntryMethod, tabIndex: number) => {
      const sideTabData = defineSideTabs(entryMethod)
      const [tabContent, placeholders] = createSideTabContent(sideTabData)

      const sideTabRenderingData = createSideTabRenderingData({
        heading: entryMethod.longDescription,
        tabContent,
        placeholders: {
          'sidetab-narrow': create()
            .addNotification({
              service,
              message: entryMethod.isClosed
                ? entryMethod.isClosedText || 'This entry method is currently not recruiting.'
                : undefined,
            })
            .get(),
          ...placeholders,
        },
      })

      const salaryRenderingData = createSalaryDetailsRenderingData(entryMethod.salaryInformation, service, isReserve)

      acc[`placeholder0-${tabIndex}`] = [sideTabRenderingData, salaryRenderingData]

      return acc
    },
    {} as Record<
      PlaceholderIndex,
      ReturnType<typeof createSideTabRenderingData | typeof createSalaryDetailsRenderingData>[]
    >
  )

  // Pad section tabs with empty placeholders to ensure MAX_SECTION_TABS of placeholders are rendered
  for (let i = entryMethods.length; i < MAX_SIDENAV_TABS; i++) {
    sectionTabContentPlaceholders[`placeholder0-${i}`] = []
  }

  const sectionTabRenderingData = createSectionTabRenderingData({
    tabs: entryMethods.map(
      ({ title = 'section-tab', description = '' }: EntryMethod): SectionTabType => ({
        ...buildTitle(description),
        tag: { field: { value: title } },
        name: slugify(title),
      })
    ),
    placeholders: sectionTabContentPlaceholders as unknown as PlaceholdersData<PlaceholderIndex>,
  })

  const { fields, ...rendering } = sectionTabRenderingData

  const richTextAreaClasses = `
    [&_.title-panel_h1]:mb-5 [&_.title-panel_h2]:mb-5 [&_.title-panel_h3]:mb-5
    [&_.title-panel_p:not(:last-child)]:mb-5 [&_.title-panel_.p:not(:last-child)]:mb-5
    [&_.title-panel_a]:underline
    [&_.title-panel_ul:not(:last-child)]:mb-9 [&_.title-panel_ul]:list-disc
    [&_.title-panel_li_p]:mb-0 [&_.title-panel_li]:mb-3
    [&_.title-panel_[class^='h']]:!text-h6
    [&_.title-panel_[class^='h']]:!mb-3
    [&_.title-panel_[class^='h']]:!text-grey-medium
    [&_.content-component]:p-0 [&_.content-component]:mt-0 [&_.content-component]:mb-10
  `

  return (
    <JobTabPanel id={id}>
      <JobTabHeader
        className={animateRightClasses}
        title={responseData?.waysToJoinHeader ?? ''}
        apply={responseData?.applyUrl}
        jobId={responseData?.id}
        service={service}
        closed={Boolean(responseData?.isClosed)}
        isPriority={Boolean(responseData?.isPriority)}
      />

      <section className="ABOUT-JOB">
        <div className="relative" data-cols="12">
          <div className="row">
            <div className="span-12">
              <div className={animateRightClasses}>
                <h3 className="h4 mb-0 font-normal normal-case">{responseData.waysToJoinEntryMethodHeader}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className={twMerge("section-tab-anchor", animateDownClasses, richTextAreaClasses)}>
          <ADFSectionTab
            fields={fields}
            placeholders={rendering.placeholders}
            rendering={rendering}
            params={rendering.params}
          />
        </div>
      </section>

      {responseData.cardCarouselHeader && responseData.cardCarousel?.fields?.items?.length && (
        <section className="SUPPORT">
          <div className="relative" data-cols="12">
            <div className="row">
              <div className="span-12">
                <div className={animateRightClasses}>
                  <h3 className="h4 mb-0 font-normal normal-case">{responseData.cardCarouselHeader}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className={twMerge('mt-12', animateDownClasses)}>
            <ADFCardCarousel params={{}} fields={responseData.cardCarousel.fields} rendering={{}} />
          </div>
        </section>
      )}
    </JobTabPanel>
  )
}

export default JobWaysToJoin

export function createSectionTabRenderingData({
  tabs,
  placeholders,
}: {
  tabs: SectionTabType[]
  placeholders: PlaceholdersData<PlaceholderIndex>
}): ComponentRenderingWithFields<SectionTabFields> {
  return {
    componentName: 'ADFSectionTab',
    dataSource: 'ADFSectionTab',
    uid: '',
    params: {
      DynamicPlaceholderId: '1',
      GridParameters: 'col-12',
      gridcols: '12',
    },
    fields: {
      data: {
        datasource: {
          children: { results: tabs },
          field: { type: { value: 'extended' } },
          name: '',
          __typename: 'SectionTab',
        },
      },
    },
    placeholders,
  }
}

function createSalaryDetailsRenderingData(
  salaryInformation?: SalaryInformation,
  service?: Service,
  isReserve?: boolean
) {
  return {
    componentName: 'ADFJobSalaryDetails',
    fields: {
      Salary: { value: salaryInformation },
    },
    service,
    isReserve,
  }
}

export function createSideTabRenderingData({
  heading = '',
  tabContent,
  placeholders,
}: {
  heading?: string
  tabContent: TabType[]
  placeholders: PlaceholdersData<PlaceholderIndex | 'sidetab-narrow'>
}): ComponentRenderingWithFields<{ data: { datasource: SideTabData } }, PlaceholderIndex | 'sidetab-narrow'> {
  placeholders['sidetab-narrow'] ??= []
  return {
    componentName: 'ADFSideTab',
    dataSource: 'ADFSideTab',
    uid: '',
    params: {
      GridParameters: 'col-12',
      gridcols: '12',
      DynamicPlaceholderId: '2',
    },
    fields: {
      data: {
        datasource: {
          heading: { value: heading },
          headingStyle: { value: 'h6' },
          children: { results: tabContent },
          field: { type: { value: 'extended' } },
          name: '',
          __typename: 'SideTabItem',
        },
      },
    },
    placeholders,
  }
}

function defineSideTabs({
  aboutHeader,
  aboutDescription,
  training,
  requirements,
  applicationProcess,
  periodOfService,
}: EntryMethod) {
  const sideTabData: Record<string, Record<string, ComponentRendering[]>> = {
    'About this entry method': {
      'About this entry method': create().addContent({ copy: aboutDescription, title: aboutHeader }).get(),
    },
    Requirements: requirements
      ? {
          Citizenship: create()
            .addContent({
              copy: requirements.citizenshipRequirementCriteria,
              title: 'Citizenship',
            })
            .get(),
          Age: create()
            .addContent({
              copy: requirements.ageRequirementCriteria,
              title: 'Age',
            })
            .get(),
          'Education and experience': create()
            .addContent({
              copy: requirements.educationRequirementCriteria,
              title: 'Education and experience',
            })
            .get(),
          Licences: create()
            .addContent({
              copy: requirements.licenseRequirementCriteria,
              title: 'Licences',
            })
            .get(),
          'Health and fitness': create()
            .addContent({
              copy: requirements.medicalRequirementCriteria,
              title: 'Health and fitness',
            })
            .get(),
          'Period of service': create()
            .addContent({
              copy: requirements.periodOfServiceCriteria,
              title: 'Period of service',
            })
            .get(),
          'Additional requirements': create()
            .addContent({
              copy: requirements.specialRequirementCriteria,
              title: 'Additional requirements',
            })
            .addContent({
              copy: requirements.entryRequirementCriteria,
              title: 'Entry requirements',
            })
            .get(),
          Aptitude: create()
            .addContent({
              copy: requirements.aptitudeRequirementCriteria,
              title: 'Aptitude',
            })
            .get(),
          'Security requirements': create()
            .addContent({
              copy: requirements.securityRequirementCriteria,
              title: 'Security requirements',
            })
            .get(),
        }
      : {},
    'Application process': {
      'Application process': create()
        .addContent({
          copy: applicationProcess,
          title: 'Application process',
        })
        .get(),
    },
    Training: training
      ? {
          'Military training': create()
            .addContent({
              copy: training.militaryTrainingCriteria,
              title: 'Military training',
            })
            .get(),
          'Employment training': create()
            .addContent({
              copy: training.employmentTrainingCriteria,
              title: 'Employment training',
            })
            .get(),
          'Further training': create()
            .addContent({
              copy: training.furtherTrainingText,
              title: 'Further training',
            })
            .get(),
          'Sub specialist option': create()
            .addContent({
              copy: training.subSpecialistOptionCriteria,
              title: 'Sub specialist option',
            })
            .get(),
        }
      : {},
    'Period of service': {
      'Period of service': create()
        .addContent({
          copy: periodOfService,
          title: 'Period of service',
        })
        .get(),
    },
  } as const
  return sideTabData
}

export function createSideTabContent(
  sideTabData: Record<string, Record<string, ComponentRendering[]>>
): [TabType[], PlaceholdersData<PlaceholderIndex>] {
  if (!sideTabData) {
    return [[], {}]
  }

  const [tabs, placeholders] = Object.entries(sideTabData)
    .filter(([, content]) =>
      Object.values(content).some((subContent) =>
        subContent.some((componentRendering) => typeof componentRendering !== 'undefined')
      )
    )
    .reduce(
      ([tabsAcc, placeholdersAcc], [title, tabContent], i) => {
        const [subTabs, subTabRenderings] = Object.entries(tabContent)
          .filter(([, subTabContent]) => subTabContent.length)
          .reduce(
            ([subTabsAcc, renderingAcc], [subTabTitle, subTabContent], j) => {
              if (subTabContent.length) {
                renderingAcc[`placeholder${i}-${j}`] = subTabContent
                subTabsAcc.push({ ...buildTitle(subTabTitle), name: subTabTitle, children: { results: [] } })
              }
              return [subTabsAcc, renderingAcc] as const
            },
            [[], {}] as [TabType[], PlaceholdersData<PlaceholderIndex>]
          )
        tabsAcc.push({ ...buildTitle(title), name: title, children: { results: subTabs } })
        return [tabsAcc, { ...placeholdersAcc, ...subTabRenderings }] as const
      },
      [[], {}] as [TabType[], PlaceholdersData<PlaceholderIndex>]
    )

  for (let i = 0; i < MAX_SIDENAV_TABS; i++) {
    for (let j = 0; j < MAX_SIDENAV_SUB_TABS; j++) {
      const placeholderKey = `placeholder${i}-${j}` as const
      if (!placeholders.hasOwnProperty(placeholderKey)) {
        placeholders[placeholderKey] = []
      }
    }
  }

  return [tabs, placeholders]
}
