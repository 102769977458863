import React from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type TabButtonProps = {
  title?: Field<string>;
  tag?: Field<string>;
  type?: string;
  onClick?: () => void;
  isExperience?: boolean;
  isSelected?: boolean;
};

const TabButton = ({
  title,
  tag,
  type,
  onClick,
  isExperience,
  isSelected,
}: TabButtonProps): JSX.Element => {
  return (
    <div tabIndex={0} onMouseUp={() => onClick?.()}
      className={`block p-2 ${isSelected? 'border-0 bg-black-off': 'border border-grey-light bg-white'}`}
    >
      {isExperience ? (
        <Text className={`lbody block ${isSelected? 'text-white': 'text-grey-medium'}`} field={title}/>
      ):(
        <div className={`lbody ${isSelected? 'text-white': 'text-grey-medium'}`}>{title?.value}</div>
      )}
      {type == 'extended' && (
        isExperience ? (
          <Text className={`sbody block ${isSelected? 'text-white': 'text-grey-medium'}`} field={tag}/>
        ):(
          <div className={`sbody ${isSelected? 'text-white': 'text-grey-medium'}`}>{tag?.value}</div>
        )
      )}
    </div>
  );
};

export default TabButton;
