import { useState } from 'react'

import Icon from '../Icons/Icon'

type StandardTagProps = {
  tagText: string
  showButton?: boolean | true
}

const FilterTag = ({ tagText, showButton = true }: StandardTagProps): JSX.Element => {
  const backgroundColor = '[.dark_&]:bg-grey-dark bg-grey-pale'
  const textThemeClass = '[.dark_&]:text-white text-black'
  const hoverClasses = showButton ? '[.dark_&]:hover:bg-white hover:bg-black-off cursor-pointer' : 'cursor-default'
  const hoverColorClass = showButton ? '[.dark_&]:group-hover:text-black group-hover:text-white' : ''

  const [fadeClass, setFadeClass] = useState('')

  const onClick = () => {
    setFadeClass('fade')
  }

  return (
    <div
      className={`filter-tag ${backgroundColor} ${hoverClasses} group flex items-center gap-x-2.5 px-2 py-1.5 md:px-4 ${fadeClass}`}
    >
      <div className={`${hoverColorClass} ${textThemeClass} xstag whitespace-nowrap text-black`}>{tagText}</div>
      {showButton && (
        <Icon
          name="close"
          type="stroke"
          hover
          width={14}
          height={14}
          className={`${textThemeClass} ${hoverColorClass}`}
          onClick={onClick}
        />
      )}
    </div>
  )
}

export default FilterTag
