import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
  RichText,
  RichTextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'
import { useFormState } from 'react-hook-form'

import { StrokeIcons } from 'components/front-end/Icons/icon-data'
import Button from '../Buttons/Button'
import Header from '../Drawers/drawer-components/Header'
import Icon from '../Icons/Icon'

type SuccessMessageProps = {
  id: string
  icon?: StrokeIcons
  heading: string
  message: RichTextField
  rendering?: ComponentRendering & { params: ComponentParams }
  service?: string
  onClickClose?: () => void
}

export default function SuccessMessage(props: SuccessMessageProps) {
  const { sitecoreContext } = useSitecoreContext()
  const { isSubmitSuccessful } = useFormState()
  const isPageEditing = !!sitecoreContext?.pageEditing
  const dynamicPlaceholderId = props.rendering?.params.DynamicPlaceholderId

  return isSubmitSuccessful || isPageEditing ? (
    <div className={`mt-10 ${isPageEditing ? 'text-grey' : 'text-grey-dark'}`} aria-live="polite">
      {isPageEditing && (
        <div className="space-y-6 py-6">
          <hr />
          <h6>{props.rendering?.componentName} - Success Message Section:</h6>
          <hr />
        </div>
      )}

      {props.icon ? <Icon name={props.icon} height={72} width={72} type="stroke" hover={!isPageEditing} /> : null}
      <Header text={props.heading} />
      <div className="sbody my-6">
        <RichText field={props.message} />
      </div>

      {props.rendering && dynamicPlaceholderId ? (
        <Placeholder name={`container-${dynamicPlaceholderId}`} rendering={props.rendering} />
      ) : null}

      {props.onClickClose ? (
        <Button
          data-trackingid={props.id}
          button
          link={{ value: { text: 'Close' } }}
          type="primary"
          icon="close"
          service={props.service ?? 'tri-service'}
          onClick={props.onClickClose}
        />
      ) : null}
    </div>
  ) : null
}
