import React from 'react'
import { ComponentParams, ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs'

interface ContainerProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
}

export const Default = (props: ContainerProps): JSX.Element => {
  const rows = Number(props.params?.Rows ?? '2')

  return (
    <>
      {[...Array(Number(14))].map((_item, i) => (
        <div key={i} className={`${i >= rows ? 'hidden' : '[&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden'}`}>
          <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
        </div>
      ))}
    </>
  )
}
