import React, { ReactNode } from 'react'
// import ListItem from './ListItem'
type ListProps = {
  className?: string
  size?: 'large' | 'standard' | 'small'
  children?: ReactNode
}

const ListBase = ({ className, size, children }: ListProps) => {
  return (
    <ul className={`grid grid-cols-1 md:grid-cols-2 ${className} `}>
      {children}
      {/* TODO: add optional data prop to build items from API  */}
      {/* {listData.map((item, i) => (
        <ListItem {...item} size={size} key={i} />
      ))}
      */}
    </ul>
  )
}

export default ListBase
