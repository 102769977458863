import { MouseEventHandler } from 'react'

import Button from 'components/front-end/Buttons/Button'

interface AudioBarButtonProps {
  id: string
  service: string
  onClickClose: () => void
  onClickCaptions: () => void
}

function AudioBarButtons({ id, onClickClose, onClickCaptions, service }: AudioBarButtonProps) {
  const handleClickClose: MouseEventHandler<HTMLButtonElement> | undefined = () => {
    onClickClose()
  }
  const handleClickCaptions = () => {
    onClickCaptions()
  }
  return (
    <div className="captions ml-auto flex items-center gap-x-6">
      <Button
        data-trackingid={id}
        link={{
          value: {
            text: 'CLOSE',
          },
        }}
        button
        icon="close"
        type="tertiary-action"
        onClick={handleClickClose}
      />
      <Button
        data-trackingid={id}
        link={{
          value: {
            text: '',
          },
        }}
        button
        type="primary-icon"
        service={service}
        icon="cc"
        className="!size-12 flex-grow-0 [&_svg]:size-5"
        onClick={handleClickCaptions}
        aria-label="Close captions"
      />
    </div>
  )
}

export default AudioBarButtons
