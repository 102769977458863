import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'

import slugify from 'utils/slugifyStrings'
import { NavigationLevel } from './NavigationProvider'

export interface NavigationField {
  url: { path: string }
  hideInNavigation: Field<Field<boolean>>
  hideInBreadcrumb: Field<Field<boolean>>
  hideBackLink: Field<Field<boolean>>
  navigationTitle: Field<string>
  pageTitle: Field<string>
  showAsQuickLink: Field<Field<boolean>>
  ctaDescription: Field<string>
  children: { results: NavigationField[] }
}

export type ActionFields = Record<string, { field: LinkField }>

export interface NavigationItem {
  url: string
  hideInNavigation: boolean
  hideInBreadcrumb: boolean
  hideBackLink: boolean
  navigationTitle: string
  pageTitle: string
  showAsQuickLink: boolean
  ctaDescription: string
  parent: NavigationItem | undefined
}

export interface NavigationItemWithChildren extends NavigationItem {
  children: NavigationItemWithChildren[]
}

const transformNavigationFieldWithParent =
  (parent?: NavigationItem['parent']) =>
  (field: NavigationField): NavigationItemWithChildren => {
    const item = {
      url: field.url.path,
      hideInNavigation: field.hideInNavigation.value.value,
      hideInBreadcrumb: field.hideInBreadcrumb.value.value,
      hideBackLink: field.hideBackLink.value.value,
      navigationTitle: field.navigationTitle.value,
      pageTitle: field.pageTitle.value,
      showAsQuickLink: field.showAsQuickLink.value.value,
      ctaDescription: field.ctaDescription.value,
      parent,
    } as NavigationItem

    return {
      ...item,
      children: field.children.results.map(transformNavigationFieldWithParent(item)),
    }
  }

export const parseNavigationFields = (fields: NavigationField[]) =>
  fields.map(transformNavigationFieldWithParent()) ?? []

export const parseActionFields = (fields: ActionFields) =>
  Object.fromEntries(Object.entries(fields).map(([key, { field }]) => [key, field]))

export function navElementId(
  item: NavigationItem | NavigationItemWithChildren | undefined,
  level: NavigationLevel,
  type: string
) {
  return slugify(`${item?.navigationTitle ?? ''} ${level} ${type}`)
}
