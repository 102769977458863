import Icon from '../Icons/Icon'

interface CardContentEventsProps {
  event: {
    title: string
    description?: string
    service: string
    link: string
    startTime: string
    endTime: string
    location: LocationProps
  }
}

// Using separate interface for location in case we need to abstract it out
interface LocationProps {
  title: string
  address: {
    level?: string
    street: string
    city: string
    state: string
  }
}

const CardContentEvents = ({ event }: CardContentEventsProps) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-x-6 gap-y-2 mb-6 sbody text-grey-medium dark:text-white">
        <p className="flex gap-2">
          <Icon name="clock" height={16} width={16} className="mt-[2px]" />{' '}
          <span>
            {event.startTime} - {event.endTime} (AEDT)
          </span>
        </p>
        <p className="flex gap-2">
          <Icon name="location" height={16} width={16} className="mt-[2px]" />
          <span>
            {event.location?.title}, {event.location?.address.level && `Level ${event.location?.address.level},`} {event.location.address.street},
            <span className="whitespace-nowrap">
              {event.location.address.city}, {event.location.address.state}
            </span>
          </span>
        </p>
      </div>
    </>
  )
}

export default CardContentEvents
