import { useFormState } from 'react-hook-form'

import Icon from '../Icons/Icon'

export default function SubmittingOverlay() {
  const { isSubmitting } = useFormState()

  if (!isSubmitting) return null

  return (
    <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/60">
      <div className="svg-loading">
        <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
      </div>
    </div>
  )
}
