import React from 'react'

type spaceProps = {
  size?: string
  border?: boolean
}

const Space = ({ size = '', border = true }: spaceProps): JSX.Element => {
  return (
    <div
      className={`w-full ${border ? 'border border-black' : ''} ${
        size === 'Space 56' ? 'sm:h-6 md:h-10 lg:h-14' : 'sm:h-8 md:h-12 lg:h-16'
      }`}
    />
  )
}

export default Space
