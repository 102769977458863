export const attendanceMethodCopy = {
  Hybrid: {
    short: 'Hybrid (in person/online)',
    long: 'Choose how you’ll attend when you register.',
  },
  Virtual: {
    short: 'Online',
    long: 'Your online access details will be sent to you upon registration.',
  },
  'Face to Face': {
    short: 'In person',
    long: 'Event details will be sent to you upon registration.',
  },
}
