import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useLessThan } from 'src/utils/useBreakpoints'
import Icon from '../Icons/Icon'

interface SearchMatchDataProps {
  TotalCount: number
  Results?: string[]
}
interface SearchMatchProps {
  query?: string
  results?: SearchMatchDataProps | null
  onClick?: (result: string) => void
  showTotal?: boolean
}

const SearchMatch = ({ query = '', results, onClick, showTotal }: SearchMatchProps): JSX.Element => {
  const [isHover, setIsHover] = useState(false)
  const router = useRouter()
  const path = router?.query?.path
    ? (router?.query?.path as string[]).map((x) => {
        return x.toLowerCase()
      })
    : []

  const searchMatchList = `
        search-list z-10 absolute top-[calc(100%_+_14px)] md:top-[calc(100%_+_12px)] py-3
        w-full bg-white md:border border-grey-light
        adf-scroll-bar-container
    `
  const searchMatchItem = `
        search-item py-3 md:pl-4 md:pr-10 hover:bg-white-off
        block w-full
        transition duration-200
    `
  const searchMatchAll = `
        search-all
        bg-white
        py-2 md:px-4 mt-8
        flex items-center
        icon-hover-${isHover}
    `
  const searchAllMobile = `
        fixed left-0 bottom-0 w-full justify-center border-t border-grey-light h-16
    `
  const searchMatchArea = `
        search-match max-h-[500px] md:max-h-[280px] overflow-y-scroll
        adf-scroll-bar
    `

  const boldQueryMatch = (match: string) => {
    const strIdx = match.toLocaleLowerCase().indexOf(query.toLocaleLowerCase())
    const strMatch = match.slice(strIdx, strIdx + query.length)
    return match.replaceAll(strMatch, `<strong>${strMatch}</strong>`)
  }

  return (
    <div className={searchMatchList}>
      <div className={searchMatchArea}>
        {results?.Results?.map((x, i) => (
          <Link
            key={`${x}-${i}`}
            href={`${path}?query=${encodeURIComponent(x)}`}
            onClick={(event) => {
              event?.preventDefault()
              onClick?.(x)
            }}
            className={searchMatchItem}
            dangerouslySetInnerHTML={{ __html: boldQueryMatch(x) }}
          ></Link>
        ))}
      </div>
      {showTotal && (
        <Link
          href={`${path}?query=${query}`}
          className={`${searchMatchAll} ${useLessThan('md') ? searchAllMobile : ''}`}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <span className="xstag mr-2">See all ({results?.TotalCount}) results</span>
          <Icon name="chevron-right-double" type="stroke" hover height={12} width={12} />
        </Link>
      )}
    </div>
  )
}

export default SearchMatch
