export type MapType = 'navy' | 'army' | 'air-force' | 'army-reserve' | 'event' | 'adfc-contact-us' | undefined | null

export const mapId = process.env.NEXT_PUBLIC_GOOGLE_MAP_ID ?? ''

export const mapOptions: google.maps.MapOptions = {
  // mapId, // TODO: Uncomment this to apply the cloud-managed map styles
  center: {
    lat: -25.2744,
    lng: 133.7751,
  },
  zoom: 4.6,
  streetViewControl: false,
  mapTypeControl: false,
  styles: [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          //color: '#A5A8AB',
          color: '#000000',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#F5E6DF',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#C8DAE9',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ],
}

export function getKey(type: MapType): NodeJS.ProcessEnv[keyof NodeJS.ProcessEnv] {
  // if (process.env.NODE_ENV !== 'production') {
  //   return process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
  // }

  switch (type) {
    case 'navy':
      return `${process.env.NAVY_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'army':
      return `${process.env.ARMY_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'air-force':
      return `${process.env.AIR_FORCE_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'army-reserve':
      return `${process.env.ARMY_RESERVE_LOCATOR_GOOGLE_MAPS_API_KEY}`

    case 'event':
      return `${process.env.EVENT_LOCATIONS_GOOGLE_MAPS_API_KEY}`

    case 'adfc-contact-us':
      return `${process.env.ADFC_LOCATIONS_CONTACT_US_GOOGLE_MAPS_API_KEY}`

    default:
      return process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
  }
}
