import React from 'react'

type ErrorMessageProps = {
  heading: string
  children?: React.ReactNode
}

export default function ErrorMessage(props: ErrorMessageProps) {
  return (
    <div className="mt-6 flex flex-col gap-3 border border-error bg-error-tint p-6" aria-live="polite">
      <h4 className="mb-2 whitespace-pre-line">{props.heading}</h4>
      {typeof props.children === 'string' ? <p className="text-dark-grey">{props.children}</p> : props.children}
    </div>
  )
}
