import React from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import { EntryMethod, PriorityRole } from 'src/components/front-end/JobDetails/_JobInterface'
import QuickLink from 'src/components/front-end/QuickLinks/QuickLink'
import { useLessThan } from 'src/utils/useBreakpoints'
import { Service } from 'src/utils/useService'
import Button from '../Buttons/Button'
import ShortlistButton from '../Shortlist/ShortlistButton'

interface JobCardStatsProps {
  id?: string
  title?: string
  service?: string
  entryMethods: EntryMethod[]
  isFulltime?: boolean
  isReserve?: boolean
  salaryInfo?: string
  salaryFootnote?: string
  salary?: string
  isClosed?: boolean
  isClosedText?: string
  isPriority?: boolean
  isPriorityText?: string
  applyUrl?: string
  salaryDescription?: string
  priorityToFill?: PriorityRole
  salarySuper?: string
  oppositeUrl?: string
  setOpenDrawer: (open: boolean) => void
}

const JobCardStats = ({
  id,
  title,
  service,
  entryMethods = [],
  isFulltime,
  isReserve,
  salaryInfo,
  salaryFootnote,
  salary,
  isClosed,
  isClosedText,
  isPriority,
  isPriorityText,
  applyUrl,
  salaryDescription,
  setOpenDrawer,
  salarySuper,
  oppositeUrl,
}: JobCardStatsProps) => {
  const colorBorder = {
    'tri-service': 'border-tri-service',
    army: 'border-army',
    'air-force': 'border-air-force',
    navy: 'border-navy',
  }

  const closedMessageClasses = `
    border-l-4 px-6 py-5 md:py-6 text-black mbody-bold mb-4 [.dark_&]:text-white [.light_&]:bg-white
    ${!isClosed && service ? colorBorder[service as keyof typeof colorBorder] : 'border-l-4 border-tri-service'}
    ${isClosed ? '!border-error' : ''}
    `

  const lessThanMd = useLessThan('md')

  return (
    <div className="-mx-[var(--outermargin)] bg-white-off px-4 pb-10 pt-4 md:mx-auto [&_.quicklink]:mt-0">
      {isClosed && (
        <div className={closedMessageClasses}>
          <p>{isClosedText}</p>
        </div>
      )}

      {!isClosed && isPriority && (
        <QuickLink
          link={{
            value: {
              href: '',
              title: isPriorityText || '',
              text: '',
              target: '',
            },
          }}
          service={service as Service}
          className="quicklink !mb-3 !bg-white"
          onClick={(e) => {
            e.preventDefault()
            setOpenDrawer(true)
          }}
        />
      )}

      <div className="stat-list px-6">
        <p className="xstag-bold mb-3 [.light_&]:text-grey-dark">Service Type</p>
        <p className="meta mb-4 text-black">
          {isFulltime ? 'Full Time' : ''}
          {isReserve ? 'Reserve' : ''}
        </p>
        {oppositeUrl && (
          <Link
            className="sbody -mt-3 mb-4 block leading-6 underline"
            href={oppositeUrl}
            onClick={(e) => {
              e.preventDefault()
              window.open(oppositeUrl, '_self')
            }}
          >
            {isFulltime ? 'View Reserve (Part-time) role' : 'View Full-time role'}
          </Link>
        )}

        <span className="mb-6 block h-[0.5px] w-full bg-grey"></span>

        <p className="xstag-bold mb-3 [.light_&]:text-grey-dark">Entry Methods</p>
        <ul>
          {entryMethods.map((entryMethod, index) => (
            <li key={`${entryMethod}-${index}`} className="meta mb-4 text-black">
              {entryMethod.title}
            </li>
          ))}
        </ul>
        <span className="mb-6 block h-[0.5px] w-full bg-grey"></span>
        <p className="xstag-bold mb-3 [.light_&]:text-grey-dark">Salary</p>
        {salaryDescription && <p className="sbody mb-4 text-black">{salaryDescription}</p>}
        <p className="sbody mb-4 text-black">{salaryInfo}</p>
        <p className="meta mb-4 text-black">{salary}</p>
        {salarySuper && <p className="xsbody mb-4 text-black">{salarySuper}</p>}

        <RichText className="xsbody mb-4 [.light_&]:text-grey-dark" field={{ value: salaryFootnote }} />
        <span className="mb-6 block h-[0.5px] w-full bg-grey"></span>
        <div className="button-bar flex flex-wrap">
          {!isClosed && (
            <Button
              data-trackingid={id as string}
              data-jobname={title as string}
              data-jobtype={isPriority ? 'priority to fill' : undefined}
              link={{
                value: {
                  href: applyUrl,
                  text: 'Apply now',
                },
              }}
              type="primary"
              icon="chevron-right-double"
              service={service}
              className="mb-4 mr-4"
              isSmall={lessThanMd}
            />
          )}
          {id ? (
            <ShortlistButton
              id={id}
              service={service}
              type={lessThanMd ? 'small-action' : 'action'}
              data-jobname={title as string}
              data-jobtype={isPriority ? 'priority to fill' : undefined}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default JobCardStats
