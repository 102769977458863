import { TabType } from 'components/ADFSideTab'
import SideNavTab from 'components/front-end/Navigation/SideNavTab'

type MainTabBarProps = {
  tabList: Array<TabType>
}

const TabBar = (props: MainTabBarProps): JSX.Element => {
  return (
    <div className="side-nav-list w-full">
      <nav className="side-nav w-full">
        {props.tabList.map((x, i) => (
          <SideNavTab
            key={i}
            id={`tab-${i}`}
            list={x}
            isSelected={
              false
              // Not sure, this no longer exists in pageContext
              //state?.pageContext?.side.sideNav == i
            }
          />
        ))}
      </nav>
    </div>
  )
}

export default TabBar
