import { RefCallback, useCallback, useRef } from 'react'

// watchSelector: The element that will trigger the hover effect
// rectSelector: The element that will be used to calculate the mouse position
// targetSelector: The element that where the property will be applied

interface UseHoverOriginConfig {
  watchSelector?: (el: HTMLElement) => HTMLElement
  rectSelector?: (el: HTMLElement) => HTMLElement
  targetSelector?: (el: HTMLElement) => HTMLElement
}

const selfSelector = (el: HTMLElement) => el
const defaultConfig: UseHoverOriginConfig = {
  watchSelector: selfSelector,
  rectSelector: selfSelector,
  targetSelector: selfSelector,
}
export function useHoverOrigin({
  watchSelector = selfSelector,
  rectSelector = selfSelector,
  targetSelector = selfSelector,
} = defaultConfig): RefCallback<HTMLElement> {
  const elementRef = useRef<HTMLElement | null>(null)
  const controllerRef = useRef<AbortController | null>(null)

  const handleMouseEvent: EventListener = useCallback(
    (event: MouseEvent) => {
      if (!elementRef.current) {
        return
      }
      const rect = rectSelector(elementRef.current).getBoundingClientRect()
      const transformOriginX = `${Math.max(Math.min(1, (event.clientX - rect.left) / rect.width)) * 100}%`
      targetSelector(elementRef.current).style.setProperty('--transform-origin-x', transformOriginX)
    },
    [rectSelector, targetSelector]
  )

  const setRef = useCallback(
    (el: HTMLElement | null) => {
      // Remove event listeners from previous element
      controllerRef.current?.abort()

      // Get the element to watch
      const element = el && watchSelector(el)

      // Add event listeners to the new element
      if (element) {
        controllerRef.current = new AbortController()
        const signal = { signal: controllerRef.current.signal }
        element.addEventListener('mouseenter', handleMouseEvent, signal)
        element.addEventListener('mouseleave', handleMouseEvent, signal)
      }

      // Hold on to the element
      elementRef.current = element
    },
    [handleMouseEvent, watchSelector]
  )

  return setRef
}
