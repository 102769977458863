import { EventProps, EventRegoField } from 'components/ADFEventHome'
import Drawer from 'components/front-end/Drawers/Drawer'
import RegistrationForm from 'components/front-end/Events/RegistrationForm'

interface EventDrawerProps {
  activeEvent: EventProps | undefined
  eventFormFields: EventRegoField
  closeDrawer: () => void
  isOpen: boolean
}

const EventDrawer = (props: EventDrawerProps) => {
  if (!props.eventFormFields) {
    return null
  }
  return (
    <Drawer title="Reserve a seat" show={props.isOpen} closeDrawer={() => props.closeDrawer?.()} hideFooter={true}>
      {props.activeEvent?.id ? (
        <div className="light">
          <RegistrationForm
            key={props.activeEvent.eventCode}
            data={props.eventFormFields}
            service={props.activeEvent.service}
            event={{
              EventId: props.activeEvent.id,
              EventCode: props.activeEvent.eventCode,
              EventName: props.activeEvent.title,
              EventUrl: props.activeEvent.url,
              EventVirtualMeetingLink: props.activeEvent.EventVirtualMeetingLink,
              EventIsVirtual: props?.activeEvent?.EventIsVirtual,
              EventIsHybrid: props.activeEvent.EventIsHybrid,
              onlineRegistrants: props.activeEvent.onlineRegistrants,
              inPersonRegistrants: props.activeEvent.inPersonRegistrants,
              eventCapacity: props.activeEvent.eventCapacity,
              EnableDietaryRequirements: props.activeEvent.EnableDietaryRequirements,
              eventVirtualCapacity: props.activeEvent.eventVirtualCapacity,
            }}
            onFormClose={() => props.closeDrawer?.()}
          />
        </div>
      ) : null}
    </Drawer>
  )
}

export default EventDrawer
