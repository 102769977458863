import type { TabType } from 'components/ADFSectionTab'
import Tab from 'components/front-end/Tabs/Tab'
import usePageContext from '../_layoutContext'

type MainTabBarProps = {
  tabList: Array<TabType>
}

const TabBar = (props: MainTabBarProps): JSX.Element => {
  const state = usePageContext()

  // const [tab, setTab] = useState<number>(0)
  // useEffect(() => {
  //     state.pageContext.side.setSideNav(0)
  //     state.pageContext.nav.setSelection(tab)
  // },[tab])

  function setTab(tab: number) {
    // state.pageContext.side.setSideNav(0)
    // state.pageContext.nav.setSelection(tab)
  }

  return (
    <section className="tab-list tab-list-bar border-grey-light xm:border-y [.dark_&]:border-grey-medium">
      <div data-cols="12">
        <nav className="tab-nav flex flex-wrap xm:flex-nowrap" role="tablist">
          {props.tabList.map((x, i) => (
            <Tab
              key={i}
              title={x.title.field}
              tag={x?.tag?.field}
              onClick={() => setTab(i)}
              isSelected={
                false
                // Not sure, this no longer exists in pageContext
                // state.pageContext.nav.selection == i
              }
            />
          ))}
        </nav>
      </div>
    </section>
  )
}

export default TabBar
