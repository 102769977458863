import FormField from '../FormFields/FormField'

export type WeightedQuestion = {
  id: string
  name: string
  questionText: string
  options: {
    id: string
    name: string
    option: {
      label: string
      value: string
      active?: boolean
    }
  }[]
}

type WeightedQuestionnaireQuestionProps = {
  question: WeightedQuestion
  value: string
  onChange: (change: { id: string; value: string }) => void
  error?: string | false
}

const WeightedQuestionnaireQuestion = ({ question, value, onChange, error }: WeightedQuestionnaireQuestionProps) => {
  const { id, questionText, name, options } = question
  const handleChange = (value: string) => {
    onChange({ id, value })
  }

  return (
    <FormField
      type="radio"
      label={questionText}
      name={name}
      value={value}
      options={options.map(({ option }) => ({
        label: option.label,
        value: `${option.value}|${option.label}`,
        active: `${option.value}|${option.label}` === value,
      }))}
      onChange={handleChange}
      errorState={!!error}
      errorMessage={error ? error : undefined}
    />
  )
}

export default WeightedQuestionnaireQuestion
