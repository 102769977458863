import { useEffect, useRef, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { createPortal } from 'react-dom'

import { ProfileTabPageProps } from 'components/front-end/ProfileTab/_ProfileTabInterface'
import ProfileTabWrapper from 'components/front-end/ProfileTab/ProfileTabWrapper'
import { useIntersect } from 'src/utils/intersection'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import { useIsMounted } from 'src/utils/useIsMounted'

const ADFProfileTab: React.FC = ({ fields }: ProfileTabPageProps) => {
  const isMounted = useIsMounted()
  const { sitecoreContext } = useSitecoreContext()
  const isEdit = sitecoreContext.pageState === 'edit'
  const greaterThanMd = useGreaterThan('md')
  const profilePositionRef = useRef<HTMLDivElement>(null)
  const profileIntersect = useIntersect(profilePositionRef, '0px 0px 100000px')
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    setHidden(profileIntersect?.intersectionRatio ? profileIntersect.intersectionRatio * 100 < 1 : true)
  }, [profileIntersect])

  return (
    <>
      <div ref={profilePositionRef}></div>
      {isEdit ? (
        <div>Profile tab component displayed in experience editor mode</div>
      ) : (
        isMounted() &&
        createPortal(
          <ProfileTabWrapper fields={fields} hidden={hidden} />,
          (greaterThanMd
            ? document.querySelector('.profile-tab-desktop')
            : document.querySelector('.profile-tab-mobile')) || document.body
        )
      )}
    </>
  )
}

export default ADFProfileTab
