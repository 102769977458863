import React, { useEffect, useRef, useState } from 'react'
import { sendGTMEvent } from '@next/third-parties/google'
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Placeholder,
  PlaceholdersData,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'
import slugify from 'src/utils/slugifyStrings'
import useSwipe from 'src/utils/useSwipe'

import usePageContext from './front-end/_layoutContext'
import PageTab from './front-end/Tabs/PageTab'

export type TabType = {
  title: {
    field: TextField
  }
  name: string
}

interface Fields {
  data: {
    datasource: {
      children: {
        results: TabType[]
      }
      field: {
        type: Field<string>
      }
      name: string
      __typename: string
    }
  }
}

interface TabListProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
  placeholders?: PlaceholdersData
}

const navClasses = `
relative
overflow-hidden
`

const navInnerClasses = `
flex flex-nowrap
transition duration-400 ease-linear
border-b border-grey-light
`

const ADFPageTab = (props: TabListProps): JSX.Element => {
  const navigation = useRef<HTMLDivElement>(null)
  const [offset, setOffset] = useState<number>(0)
  const [navOffset, setNavOffset] = useState<number | undefined>()
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      if (navOffset) setOffset(-navOffset)
      //console.log(-navOffset);
    },
    onSwipedRight: () => {
      setOffset(0)
      console.log(0)
    },
  })
  useEffect(() => {
    function handleResize() {
      if (navigation.current === null || navigation.current.parentElement === null) return
      //window['nav'] = navigation?.current;
      //console.log('parentWidth:' + navigation.current.parentElement.clientWidth);
      //console.log('navWidth:' + navigation.current.clientWidth);
      if (navigation.current.parentElement.clientWidth < navigation.current.clientWidth) {
        setNavOffset(navigation.current.clientWidth - navigation.current.parentElement.clientWidth)
      } else {
        setNavOffset(0)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    sendGTMEvent({
      ga4Page: `${window.location.pathname}/${props?.fields?.data?.datasource?.children?.results[0].title.field.value}`,
    })
  }, [props?.fields?.data?.datasource?.children?.results])

  const { sitecoreContext } = useSitecoreContext()
  const isEdit = sitecoreContext.pageState === 'edit'

  const pageState = usePageContext().pageContext
  const tabGroup = slugify(props.fields?.data?.datasource.name || 'page-tab')
  const tabList = props?.fields?.data?.datasource?.children?.results?.map((x: TabType) => slugify(x?.name ?? '')) || []

  const tab = pageState.deepLink.tabSet[tabGroup] ? tabList?.indexOf(pageState.deepLink.tabSet[tabGroup]) : 0

  const containerStyles = `${props.params?.GridParameters ?? ''} ${props.params?.styles ?? ''}`
  const gridcols = Number(props.params?.gridcols ?? '12')

  const gradClasses = `
    z-0 absolute bottom-0 right-0 h-full w-14
    bg-gradient-to-r from-gradient to-white [.dark_&]:to-black
    pointer-events-none
    `

  return (
    <>
      <div className={`${containerStyles}`} data-cols={gridcols}>
        <div className={`col-12`}>
          <div className="w-full">
            <div className={navClasses} {...swipeHandlers}>
              <div
                className={navInnerClasses}
                ref={navigation}
                style={{ transform: `translateX(${offset}px)`, width: 'max-content' }}
              >
                {props?.fields?.data?.datasource?.children?.results?.map((x, i) => (
                  <PageTab
                    key={i}
                    title={x.title.field}
                    onClick={() => {
                      pageState.deepLink.setTabSet({ type: 'SET', payload: { [tabGroup]: tabList[i] } })
                      sendGTMEvent({
                        ga4Page: `${window.location.pathname}/${x.title.field.value}`,
                        event: 'tab_button',
                        ga4category: 'tab_button',
                        tab_component: props.fields?.data?.datasource.name || 'page-tab',
                        ga4name: `Tab - ${x?.title.field.value}-${props.rendering.uid as string}`,
                      })
                    }}
                    isSelected={tab == i}
                    isExperience={isEdit}
                  />
                ))}
              </div>
              <div
                className={gradClasses}
                style={{ opacity: `${navOffset && navOffset != 0 && offset == 0 ? 1 : 0}` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-tab relative">
        {[...Array(Number(9))].map((_item, i) => {
          return (
            <div
              key={i}
              className={`${tab !== i ? 'hidden' : '[&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden'}`}
            >
              <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
              {isEdit && (
                <div id="grid">
                  {[...Array(gridcols)].map((_x, i) => (
                    <div key={`grid_${i}`}>{i + 1}</div>
                  ))}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ADFPageTab
