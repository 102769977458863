import { useCallback, useEffect, useMemo } from 'react'
import { createActorContext, shallowEqual } from '@xstate/react'
import { Props } from 'focus-trap-react'

import { usePathSegments } from 'utils/usePathSegments'
import useScrollLock from 'utils/useScrollLock'
import { navigationMachine } from 'src/machines/siteNavigation.machine'
import { NavigationItemWithChildren } from './navigationHelpers'

export const NavigationContext = createActorContext(navigationMachine)

interface NavigationProviderProps {
  children: React.ReactNode
}

export const navigationLevels = ['primary', 'secondary', 'tertiary'] as const
export type NavigationLevel = (typeof navigationLevels)[number]

export function NavigationProvider({ children }: NavigationProviderProps) {
  return (
    <NavigationContext.Provider>
      <div className="inset-0 flex flex-col">{children}</div>
    </NavigationContext.Provider>
  )
}

export function useSearchTakeover() {
  const [, setLocked] = useScrollLock()
  const searchActive = NavigationContext.useSelector(
    (state) => state.matches('nav.search'),
    (a, b) => shallowEqual(a, b)
  )
  const { send } = NavigationContext.useActorRef()

  const setSearchActive = useCallback(
    (activate: boolean) => send(activate ? 'ACTIVATE_SEARCH' : 'DEACTIVATE_SEARCH'),
    [send]
  )

  const toggleSearchActive = useCallback(() => send('TOGGLE_SEARCH'), [send])

  useEffect(() => {
    setLocked(searchActive)
  }, [searchActive, setLocked])

  return [searchActive, setSearchActive, toggleSearchActive] as const
}

export function useNavigation() {
  const pathSegments = usePathSegments()

  const activePath = NavigationContext.useSelector(
    ({ context }) => {
      return context.activePath
    },
    (a, b) => shallowEqual(a, b)
  )
  const navItems = NavigationContext.useSelector(
    ({ context }) => {
      return context.navItems
    },
    (a, b) => shallowEqual(a, b)
  )
  const navigationStates = NavigationContext.useSelector(
    (state) => {
      return {
        // navIsHidden: state.matches('nav.idle.navigationBarHidden'),
        navIsIdle: state.matches('nav.idle'),
        navIsActivated: state.matches('nav.activated'),
        secondaryNavIsActivated: state.matches('nav.activated.secondaryNavActivated'),
        tertiaryNavIsActivated: state.matches('nav.activated.secondaryNavActivated.tertiaryNavActivated'),
        isTrapLocked: state.matches('focusTrap.locked'),
      }
    },
    (a, b) => shallowEqual(a, b)
  )

  const activeItem = activePath.length ? activePath[activePath.length - 1] : null

  const visibleNavItems = useMemo(
    () => [
      navItems.filter((item) => !item.hideInNavigation),
      ...activePath.map((item) => item.children).filter((items) => items.length > 0),
    ],
    [activePath, navItems]
  )

  const renderedNavItems = useMemo(
    () => [
      // Level 0 (primary) is the top level of navItems
      [navItems],
      // Level 1 (secondary) is an array of the children of the top level of navItems
      navItems.map(({ children }) => children),
      // Level 2 (tertiary) is an array of the children of the active secondary nav item
      activePath[0]?.children
        .filter(
          ({ children }) =>
            children.filter(({ hideInNavigation, showAsQuickLink }) => !hideInNavigation || showAsQuickLink).length
        )
        .map(({ children }) => children) ?? [],
    ],
    [activePath, navItems]
  )

  const breadcrumbNavItems = useMemo(
    () =>
      pathSegments.reduce((acc, segment, index) => {
        const source = index === 0 ? navItems : acc[index - 1]?.children

        if (!source) {
          return acc
        }

        const item = source.find((item) => !item.hideInNavigation && !item.hideInBreadcrumb && item.url === segment)

        if (item) {
          acc[index] = item
        }

        return acc
      }, [] as NavigationItemWithChildren[]),
    [navItems, pathSegments]
  )

  return {
    activePath,
    activeItem,
    navItems,
    visibleNavItems,
    renderedNavItems,
    breadcrumbNavItems,
    ...navigationStates,
  } as const
}

type TrapStack = NonNullable<Props['focusTrapOptions']>['trapStack']
export const navigationTrapStack: TrapStack = []
