import { trackButton } from 'src/utils/tracking'

import { useFAQContext } from './FAQContext'
import FAQSubcategoryItem, { type FAQSubcategoryItemProps } from './FAQSubcategoryItem'

export type DefaultSubcategory = Omit<FAQSubcategoryItemProps, 'onClick'>

type FAQSubcategoryListProps = {
  faqs: string[]
  defaultSubcategory?: DefaultSubcategory
  activeCategoryId?: string
  activeSubcategoryId?: string
  onChange?: (id: string) => void
}

const FAQSubcategoryList = ({
  faqs,
  defaultSubcategory,
  activeCategoryId,
  activeSubcategoryId,
  onChange,
}: FAQSubcategoryListProps) => {
  const { send, lookup } = useFAQContext()
  const subcategories = lookup.subcategoriesForFaqs(faqs)

  const handleSelectSubcategory = (id: string): void => {
    if (activeSubcategoryId !== id) {
      send({ type: 'SELECT_SUBCATEGORY', payload: id })
      trackButton({
        title: lookup.subcategories[id].faqSubcategoryTitle,
        guid: activeCategoryId as string,
        location: 'FAQ',
      })
      onChange?.(id)
    }
  }

  return (
    <ul>
      {defaultSubcategory ? (
        <FAQSubcategoryItem {...defaultSubcategory} onClick={() => handleSelectSubcategory(defaultSubcategory.id)} />
      ) : null}
      {subcategories
        .map((id) => ({ ...lookup.subcategories[id] }))
        .sort((x, y) => x.faqSubcategoryTitle.localeCompare(y.faqSubcategoryTitle))
        .map(({ id, faqSubcategoryTitle }) => (
          <FAQSubcategoryItem
            key={id}
            id={id}
            title={faqSubcategoryTitle}
            count={
              activeCategoryId
                ? lookup.faqsInCategoryAndSubcategory(activeCategoryId, id, faqs).length
                : lookup.faqsInSubcategory(id).length
            }
            onClick={() => handleSelectSubcategory(id)}
            isSelected={activeSubcategoryId ? activeSubcategoryId === id : id === defaultSubcategory?.id}
          />
        ))
      }
    </ul>
  )
}

export default FAQSubcategoryList
