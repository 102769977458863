import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'next-share'
import Head from 'next/head'

import Icon from '../Icons/Icon'

type SocialNetworks = 'Facebook' | 'Twitter' | 'LinkedIn'

type OgData = {
  image: string
} & Pick<ShareButtonsProps, 'title' | 'description'>

type ShareButtonsProps = {
  title: string
  description?: string
  ogData?: OgData
  shareTo?: SocialNetworks[]
  url: string
}

const ShareButtons = (props: ShareButtonsProps) => {
  const socials = [props.shareTo || 'Facebook', 'Twitter', 'LinkedIn']

  return (
    <>
      {props.ogData && (
        <Head>
          <meta property="og:image" content={`${process.env.PUBLIC_URL}${props.ogData.image}`} />
          <meta property="og:title" content={props.ogData.title} />
          {props.ogData.description && <meta property="og:description" content={props.ogData.description} />}
        </Head>
      )}
      <ul className="mt-1 flex gap-2">
        {socials.includes('Facebook') && (
          <li>
            <FacebookShareButton url={props.url} quote={props.title}>
              <span className="flex h-8 w-8 items-center justify-center border transition hover:bg-black hover:text-white">
                <Icon name="facebook" type="stroke" width={16} height={16} />
              </span>
            </FacebookShareButton>
          </li>
        )}
        {socials.includes('Twitter') && (
          <li>
            <TwitterShareButton
              url={props.url}
              title={`${props.title}  ${props.description ? ' - ' + props.description : ''}`}
            >
              <span className="flex h-8 w-8 items-center justify-center border transition hover:bg-black hover:text-white">
                <Icon name="twitter" type="stroke" width={16} height={16} />
              </span>
            </TwitterShareButton>
          </li>
        )}
        {socials.includes('LinkedIn') && (
          <li>
            <LinkedinShareButton url={props.url}>
              <span className="flex h-8 w-8 items-center justify-center border transition hover:bg-black hover:text-white">
                <Icon name="linkedin" type="stroke" width={16} height={16} />
              </span>
            </LinkedinShareButton>
          </li>
        )}
      </ul>
    </>
  )
}

export default ShareButtons
