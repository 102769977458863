/**
 * Truncates a string if it exceeds the specified maximum length.
 * @param str - The string to truncate.
 * @param maxLength - The maximum length of the truncated string.
 * @returns The truncated string.
 */
export function truncate(str = '', maxLength: number): string {
  str = str.trim()

  if (str.length <= maxLength) {
    return str
  }

  return str.slice(0, maxLength).trim() + '…'
}

// Examples
// console.log(`Original string: ${truncate('short and pithy', 17)}`) // Outputs 'short and pithy'
// console.log(`Truncated string: ${truncate('way too much copy to fit into the approved design', 17)}`) // Outputs 'way too much copy...'
