import type { JSX } from 'react'
import { Field, LinkField, RichText, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'

import Button from './front-end/Buttons/Button'

interface Fields {
  Title: Field<string>
  Text1: Field<string>
  CtaButton1: LinkField
  Text2: Field<string>
  CtaButton2: LinkField
  TitleHeadingLevel: Field<string> // h2, h3
  CopySizeType: Field<string> // large, regular
  ColumnLayoutType: Field<string> // 1-column, 2-column
  ComponentAligmentLayoutType: Field<string> // left, right
}

export type RichTextProps = ComponentProps & {
  params: { [key: string]: string }
  fields: Fields
}
export const ContentBlock = (props: RichTextProps): JSX.Element => {
  const gridcols = props.params.gridcols as string
  const id = props.params.RenderingIdentifier
  const alignRight = props.fields.ComponentAligmentLayoutType.value === 'right'

  const copySizeClass = `flex flex-col gap-4 ${props.fields.CopySizeType.value === 'large' ? 'lbody' : 'mbody'}`
  const headingLevel = (props.fields.TitleHeadingLevel.value || 'h2') as keyof JSX.IntrinsicElements

  return (
    <>
      <div data-cols={gridcols}>
        <div className={`component rich-text lg:w-2/3 ${alignRight ? 'ml-auto' : ''}`} id={id ? id : undefined}>
          {!!props.fields.Title.value.length && (
            <div className={`col-span-2 pb-8`}>
              {headingLevel === 'h2' ? (
                <h2 className="mb-10 mt-2">
                  <Text field={props.fields.Title} />
                </h2>
              ) : (
                <h3 className="mb-2 mt-2">
                  <Text field={props.fields.Title} />
                </h3>
              )}
            </div>
          )}
          <div
            className={`grid grid-cols-1 gap-20 ${
              props.fields.Text2.value || props.fields.CtaButton2.value.href ? 'md:grid-cols-2' : ''
            }`}
          >
            <div className="left">
              <RichText className={copySizeClass} field={{ value: props.fields.Text1.value }} />
              {props.fields.CtaButton1.value.href && (
                <div className="pt-8">
                  <Button
                    data-trackingid={props.rendering.uid as string}
                    link={{
                      value: {
                        href: props.fields.CtaButton1.value.href,
                        target: props.fields.CtaButton1.value.target,
                        text: props.fields.CtaButton1.value.text,
                      },
                    }}
                    icon="chevron-right-double"
                    type="secondary"
                  />
                </div>
              )}
            </div>
            {(props.fields.Text2.value || props.fields.CtaButton2.value.href) && (
              <div className="right">
                <RichText className={copySizeClass} field={{ value: props.fields.Text2.value }} />
                {props.fields.CtaButton2.value.href && (
                  <div className="pt-8">
                    <Button
                      data-trackingid={props.rendering.uid as string}
                      link={{
                        value: {
                          href: props.fields.CtaButton2.value.href,
                          target: props.fields.CtaButton2.value.target,
                          text: props.fields.CtaButton2.value.text,
                        },
                      }}
                      icon="chevron-right-double"
                      type="secondary"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default withDatasourceCheck()<RichTextProps>(ContentBlock)
