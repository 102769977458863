import { CSSProperties } from 'react'
import {
  Field,
  RichText as JssRichText,
  LinkField,
  RichTextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin, twMerge } from 'tailwind-merge'

import { ButtonProps } from 'components/front-end/Buttons/Button'
import SimpleCard from 'components/front-end/Cards/SimpleCard'
import { ContentIcons } from 'components/front-end/Icons/icon-data'
import InfoPanel from 'components/front-end/SitecoreHelpers/InfoPanel'
import { ComponentProps } from 'lib/component-props'
import { cleanHeadingStyle } from 'utils/cleanHeadingStyle'
import { fixAssetHref } from 'utils/fixAssetHref'

interface SimpleCardFields {
  Service: Field<string>
  Tag: Field<string>
  Icon: Field<ContentIcons>
  Heading: Field<string>
  HeadingLevel: Field<string> // h5, h6
  Content: RichTextField
  CtaButton1: LinkField
  CtaButton2: LinkField
  IsDownload1: boolean
  IsDownload2: boolean
}
type SimpleCard = {
  id: string
  fields: SimpleCardFields
}

type SimpleCardsProps = ComponentProps & {
  fields: {
    items: SimpleCard[]
  }
}

type SimpleCardItemProps = {
  id: string
  index: number
  total: number
  field: SimpleCardFields
}

const SimpleCardItem = ({ id, field, total }: SimpleCardItemProps): JSX.Element => {
  const buttons = (
    [
      [field.CtaButton1.value, field.IsDownload1],
      [field.CtaButton2.value, field.IsDownload2],
    ] as const
  )
    .filter(([{ href }]) => href)
    .map(
      ([linkValue, download]): ButtonProps => ({
        'data-trackingid': id,
        icon: 'chevron-right-double',
        type: 'small-secondary',
        link: { value: { ...linkValue, href: fixAssetHref(linkValue, download) } },
      })
    )

  return (
    <SimpleCard
      className={twMerge(
        'flex flex-col content-end border-grey-light [&:not(:first-of-type)]:border-t xm:[&:not(:first-of-type)]:border-l xm:[&:not(:first-of-type)]:border-t-0',
        total === 4 &&
          'xl:!border-b-0 xl:[&:not(:first-of-type)]:!border-l xm:[&:nth-of-type(1)]:border-b xm:[&:nth-of-type(2)]:border-b xm:[&:nth-of-type(3)]:border-l-0'
      )}
      service={field.Service.value}
      title={field.Heading.value}
      headingLevel={cleanHeadingStyle(field.HeadingLevel.value)}
      icon={field.Icon.value}
      buttons={buttons}
      rightButtonText={null}
      unsavedButtonText={null}
      savedButtonText={null}
      tags={field.Tag.value ? [field.Tag.value] : undefined}
    >
      <div className="component rich-text">
        <JssRichText field={field.Content} />
      </div>
    </SimpleCard>
  )
}

const ADFSimpleCards = ({ fields, params, rendering }: SimpleCardsProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const styles = twJoin(params.GridParameters, params?.styles)
  const gridcols = params.gridcols ?? 12
  const id = params.RenderingIdentifier

  const cards = fields?.items

  if (!cards) {
    return (
      <div className={styles} id={id}>
        <div className="component-content">
          {sitecoreContext.pageEditing && (
            <InfoPanel
              heading="Sitecore | Simple Cards"
              message="Please edit the datasource and select Simple Cards to display"
            />
          )}
        </div>
      </div>
    )
  }

  const maxRowValue = cards.length || 4
  return (
    <div className="mb-10" id={id} data-cols={gridcols}>
      <div
        className={twJoin(
          styles,
          'relative mb-10 grid w-full grid-cols-1 border border-grey-light',
          maxRowValue === 4 ? `xm:grid-cols-2 xl:grid-cols-4` : `xm:grid-cols-[repeat(var(--cards),1fr)]`
        )}
        style={{ '--cards': maxRowValue } as CSSProperties}
      >
        {cards.map((card, index) => (
          <SimpleCardItem key={index} id={id ?? rendering.uid} index={index} total={cards.length} field={card.fields} />
        ))}
      </div>
    </div>
  )
}

export default ADFSimpleCards
