import { RefObject } from 'react'

export type AudioGroupProps = {
  audioRef: RefObject<HTMLAudioElement>
  startPlayer: () => void
  stopPlayer: () => void
  id?: string
  name?: string
  role?: string
  image?: string
  audioTranscript?: string
}

export type AudioProps = {
  name: string
  role: string
  image: string
  alt?: string
  url: string
  group?: boolean
  id: string
  story?: boolean
  audioTranscript?: string
  service?: string
  playerRef?: RefObject<HTMLAudioElement>
}

export type TranscriptProps = {
  id: string
  group?: boolean
  transcript?: string
  open?: boolean
  onClick?: () => void
  story?: boolean
  service?: string
}
