import { useState } from 'react'

import Icon from 'components/front-end/Icons/Icon'
import Divider from './Divider'
import { FilterValue } from './Filter'

type EntryMethodFilterProps = {
  title?: string
  filterValues: FilterValue[]
  generalTitle?: string
  generalSubtitle?: string
  generalValues: FilterValue[]
  officerTitle?: string
  officerSubtitle?: string
  officerValues: FilterValue[]
  gapYearTitle?: string
  gapYearSubtitle?: string
  gapYearValues: FilterValue[]
  onClick?: (value: FilterValue) => void
  isCollapsed?: boolean
  onCollapse?: (value?: boolean) => void
  defaultOption?: string
}

const EntryMethodFilter = ({
  title,
  filterValues,
  generalTitle = '',
  generalSubtitle = '',
  generalValues,
  officerTitle = '',
  officerSubtitle = '',
  officerValues,
  gapYearTitle = '',
  gapYearSubtitle = '',
  gapYearValues,
  onClick = () => undefined,
  isCollapsed = false,
  onCollapse,
  defaultOption = '',
}: EntryMethodFilterProps): JSX.Element => {
  const clickedClass = !isCollapsed ? 'svg-clicked clicked-active' : ''
  const heightClass = !isCollapsed ? 'max-h-full' : 'max-h-[130px] overflow-hidden'

  const checkboxFilters = (title: string | undefined, subTitle: string, values: FilterValue[]) => (
    <>
      {title &&
        <div className="flex pb-4">
          <div className="sbody-bold flex-none pr-1">{title}</div>
          <div className="sbody text-left text-grey-medium">{subTitle}</div>
        </div>
      }
      <div className="flex flex-col pb-8">
        {values.map((f) => (
          <div className="pb-4" key={f.value}>
            <div>
              <div className="flex">
                <button
                  className={`radio-selected svg-clicked inline-flex shrink-0 ${f.checked ? 'clicked-active' : ''}`}
                  onClick={() => onClick?.(f || 'err')}
                >
                  <svg
                    className="svg-icon checkbox"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.7 4C3.7 3.83431 3.83432 3.7 4 3.7H20C20.1657 3.7 20.3 3.83431 20.3 4V20C20.3 20.1657 20.1657 20.3 20 20.3H4C3.83431 20.3 3.7 20.1657 3.7 20V4Z"
                      stroke="currentColor"
                      strokeWidth="1.4"
                    />
                    <path d="M7 11.5L10.5 15L17 8.5" stroke="currentColor" strokeWidth="1.4" />
                  </svg>
                  <span className="text-gray-700 mbody ml-2 inline-block cursor-pointer">{f.name}</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )

  return (
    <div className="pt-6">
      <>
        <button
          className={`${heightClass} flex w-full cursor-pointer items-center justify-between
            py-4 transition-[max-h] duration-1000`}
          onClick={() => onCollapse?.(!isCollapsed)}
        >
          <div className={`stag pb-4 pt-3`}>{title}</div>
          <Icon className={clickedClass} name="chevron-down" type="stroke" clicked width={18} height={18} />
        </button>
        <div className={`pb-4`}>
          <Divider></Divider>
        </div>
        
      </>
      {!isCollapsed && 
        <>
          {checkboxFilters(undefined, '', filterValues)}
          {checkboxFilters(generalTitle, generalSubtitle, generalValues)}
          {checkboxFilters(officerTitle, officerSubtitle, officerValues)}
          {gapYearValues && gapYearValues.length > 0 && checkboxFilters(gapYearTitle, gapYearSubtitle, gapYearValues)}
        </>
      }
    </div>
  )
}

export default EntryMethodFilter
