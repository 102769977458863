import React from 'react'
import { ComponentParams, ComponentRendering, Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'

import CTATile from 'components/front-end/CTATile/CTATile'
import { ButtonProps, ButtonTypes } from './front-end/Buttons/Button'

interface CTATileField {
  ImageDesktop: ImageField
  ImageMobile: ImageField
  Headline1: Field<string>
  SubHeadline1: Field<string>
  CTAButton1?: LinkField
  CTAButton1Theme?: Field<ButtonTypes>
  CTAButton1Icon?: Field<ButtonProps['icon']>
  Headline2?: Field<string>
  SubHeadline2?: Field<string>
  CTAButton2?: LinkField
  CTAButton2Theme?: Field<ButtonTypes>
  CTAButton2Icon?: Field<ButtonProps['icon']>
  Headline3?: Field<string>
  SubHeadline3?: Field<string>
  CTAButton3?: LinkField
  CTAButton3Theme?: Field<ButtonTypes>
  CTAButton3Icon?: Field<ButtonProps['icon']>
}

interface CTATilePageProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: CTATileField
}

export const ADFCTATile = (props: CTATilePageProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier ?? props.params?.DynamicPlaceholderId
  const containerStyles = props.params?.styles ?? ''
  const styles = `${props.params?.GridParameters} ${containerStyles}`.trimEnd()
  return (
    <div className={`relative ${styles} dark bg-transparent`} id={id ? id : undefined}>
      <CTATile
        id={props.rendering.uid as string}
        ImageDesktop={props.fields?.ImageDesktop}
        ImageMobile={props.fields?.ImageMobile}
        Headline1={props.fields?.Headline1?.value}
        SubHeadline1={props.fields?.SubHeadline1?.value}
        CTAButton1={props.fields?.CTAButton1}
        CTAButton1Theme={props.fields?.CTAButton1Theme?.value}
        CTAButton1Icon={props.fields?.CTAButton1Icon?.value}
        Headline2={props.fields?.Headline2?.value}
        SubHeadline2={props.fields?.SubHeadline2?.value}
        CTAButton2={props.fields?.CTAButton2}
        CTAButton2Theme={props.fields?.CTAButton2Theme?.value}
        CTAButton2Icon={props.fields?.CTAButton2Icon?.value}
        Headline3={props.fields?.Headline3?.value}
        SubHeadline3={props.fields?.SubHeadline3?.value}
        CTAButton3={props.fields?.CTAButton3}
        CTAButton3Theme={props.fields?.CTAButton3Theme?.value}
        CTAButton3Icon={props.fields?.CTAButton3Icon?.value}
      />
    </div>
  )
}

export default ADFCTATile
