import StandardText from 'components/front-end/TextBlocks/StandardText'

const StandardPanel = () => {
  const standardPanelClasses = `
    standard-panel bg-black-off
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[560px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[690px]
    xxl:py-[175px] xxl:px-[120px] max-w-[848px]
    flex items-center
  `
  return (
    <div className={standardPanelClasses}>
      <StandardText text="You'll learn how to work together effectively in unfamiliar situations and environments." />
    </div>
  )
}

export default StandardPanel
