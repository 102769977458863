import Link from "next/link";
import { AnchorHTMLAttributes, ComponentProps } from "react";

/**
 * usage: replaceTextWithLink(sourceText, linkText, linkUrl[, options])
 *
 * @param sourceText the text to be rendered
 * @param linkText the text to be replaced with a link
 * @param linkUrl the url to link to
 * @param options optional options object
 * @param options.replacementText the text to be rendered in place of the linkText
 * @param options.linkProps props to be passed to the <a> or <Link> component
 *
 * example: replaceTextWithLink('some text with a link', 'link', 'https://example.com')
 * example: replaceTextWithLink('Please refine your search, explore the FAQs below, or Contact Us', /contact us/i, '/contact-us', { replacementText: 'Contact Us' })
 *
 * IMPORTANT: if the targetText is a RegExp, the `replacementText` option is required
 * NOTE: if linkUrl is a remote url, the link will be rendered as an <a> tag, otherwise it will be rendered as a Next.js <Link> component
 *
 */

type RemoteUrl = `http${string}` | `mailto${string}` | `tel${string}`;

type ReplacementText<U> = U extends RegExp ? {
  replacementText: string;
} :{
  replacementText?: string;
};

type ReplaceTextWithLinkOptions<T, U> = {
  linkProps?: T extends RemoteUrl ? AnchorHTMLAttributes<HTMLAnchorElement> : Omit< ComponentProps<typeof Link>, 'href'>;
} & ReplacementText<U>

export function replaceTextWithLink<T extends RemoteUrl | string, U extends RegExp>(sourceText: string, linkText: U, linkUrl: T, options: ReplaceTextWithLinkOptions<T, U>): JSX.Element
export function replaceTextWithLink<T extends RemoteUrl | string, U extends string>(sourceText: string, linkText: U, linkUrl: T, options?: ReplaceTextWithLinkOptions<T, U>): JSX.Element
export function replaceTextWithLink<T extends RemoteUrl | string, U extends RegExp | string>(sourceText: string, linkText: U, linkUrl: T, options: ReplaceTextWithLinkOptions<T, U>): JSX.Element {
  const [start, end] = sourceText.split(linkText);

  const LinkElement = (/^(http|mailto|tel)/).test(linkUrl) ? 'a' : Link;
  const link = <LinkElement href={linkUrl} {...options?.linkProps}>{typeof linkText === 'string' ? options?.replacementText || linkText : options?.replacementText}</LinkElement>

  return (<>{start}{link}{end}</>)
}
