import SwiperCore, { A11y, FreeMode, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/scss/pagination'
import 'swiper/scss/free-mode'

import { useRef } from 'react'

import { SwipeDirection, trackSwiper } from 'src/utils/tracking'
import { useGreaterThan } from 'src/utils/useBreakpoints'

SwiperCore.use([Keyboard, Mousewheel])

interface StorySwiperProps {
  name: string
  children: JSX.Element[] | undefined
}

const StorySwiper = ({ name, children }: StorySwiperProps) => {
  const swiperClasses = `
    story-swiper h-full
    [&_.swiper-pagination-bullet]:bg-transparent
    [&_.swiper-pagination-bullet]:opacity-100
    [&_.swiper-pagination-bullet]:border
    [&_.swiper-pagination-bullet]:w-4
    [&_.swiper-pagination-bullet]:h-4
    [&_.swiper-pagination-bullet]:!mx-1.5
    [&_.swiper-pagination-bullet]:border-white
    [&_.swiper-pagination-bullet-active]:!bg-white
  `

  const previousSlide = useRef<number>(1)
  const orientation = useGreaterThan('lg') ? 'horizontal' : 'vertical'
  const direction: [SwipeDirection, SwipeDirection] = orientation === 'horizontal' ? ['left', 'right'] : ['up', 'down']

  return (
    <Swiper
      className={swiperClasses}
      modules={[Pagination, A11y, FreeMode, Navigation]}
      pagination={{ clickable: true }}
      slidesPerView="auto"
      spaceBetween={0}
      watchOverflow
      direction={orientation}
      freeMode={{
        enabled: true,
      }}
      mousewheel
      keyboard
      onAfterInit={(swiper) => {
        previousSlide.current = swiper.activeIndex + 1
      }}
      onSlideChange={(swiper) => {
        const currentSlide = swiper.activeIndex + 1

        if (currentSlide === previousSlide.current) {
          return
        }

        trackSwiper({
          swiperName: name,
          direction: direction[Number(currentSlide > previousSlide.current)],
          currentSlide,
          totalSlides: swiper.slides.length,
        })

        previousSlide.current = currentSlide
      }}
    >
      {children?.map((slide, i) => (
        <SwiperSlide key={i} className="story-slide !h-fit !w-full lg:!h-full lg:!w-fit">
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default StorySwiper
