import React, { useState } from 'react'
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'

import CarouselSwiper from 'components/front-end/Carousel/CarouselSwiper'
import EventDrawer from 'components/front-end/Events/EventDrawer'
import LocationDrawer from 'components/front-end/Events/LocationDrawer'
import LedeText from 'components/front-end/TextBlocks/LedeText'
import { ComponentProps } from 'lib/component-props'
import { EventSearchResult } from './ADFEventSearchResults'
import { ButtonProps } from './front-end/Buttons/Button'
import EventCard from './front-end/Events/EventCard'
import FeaturedEventWrapper from './front-end/Events/FeaturedEventWrapper'

export interface EventRegoField {
  AgreePrivacyLabel: Field<string>
  AttendeeTypes: Field<string>
  AttendeeTypesLabel: Field<string>
  AttendingLabel: Field<string>
  AttendingOptions: Field<string>
  CandidateNumberLabel: Field<string>
  EmailLabel: Field<string>
  FirstNameLabel: Field<string>
  GuestHeading: Field<string>
  MobileLabel: Field<string>
  PostCodeLabel: Field<string>
  PrivacyLink: Field<string>
  WithReceiveLabel: Field<string>
  RegistrantHeading: Field<string>
  SurnameLabel: Field<string>
  AddGuestText: Field<string>
  SubmitButtonText: Field<string>
  IndicatesMessage: Field<string>
  FailMessageHeading: Field<string>
  FormHeading: Field<string>
  InvalidMessage: Field<string>
  RequiredMessage: Field<string>
  SuccessMessage: Field<string>
  SuccessMessage2: Field<string>
  SuccessMessageHeading?: Field<string>
  FirstNameHelpText: Field<string>
  MobileHelpText: Field<string>
  PostCodeHelpText: Field<string>
  SurnameHelpText: Field<string>
  CandidateNumberHelpText: Field<string>
  EmailHelpText: Field<string>
  AgreePrivacyMessage: Field<string>
  AttendeeTypesMessage: Field<string>
  AttendingMessage: Field<string>
  CandidateNumberMessage: Field<string>
  EmailMessage: Field<string>
  FirstNameMessage: Field<string>
  InpersonExhaustedMessage: Field<string>
  MobileMessage: Field<string>
  OnlineExhaustedMessage: Field<string>
  PostCodeMessage: Field<string>
  SurnameMessage: Field<string>
  EventGuidelinesMessage: Field<string>
  EventGuidelinesLabel: Field<string>
  EventGuidelinesLink: Field<string>
}

export interface EventRegoForm {
  id: string
  url: string
  name: string
  displayName: string
  fields: EventRegoField
}

type EventHomePageProps = ComponentProps & {
  fields: EventHomeField
}

export interface EventProps extends EventSearchResult {
  featured?: boolean
  doubleFeatured?: boolean
}

interface EventHomeField {
  FeaturedEventsTitle: Field<string>
  RowLayer: Field<string>
  RowLayer2: Field<string>
  SeeAllEventsLink: LinkField
  Row1FeatureEvents: EventProps[]
  Row2FeatureEvents: EventProps[]
  UpcomingEvents: EventProps[]
  EventRegistrationForm: EventRegoForm
  UpcomingEventsTitle: Field<string>
}

export const ADFEventHome = (props: EventHomePageProps): JSX.Element => {
  const [activeEvent, setActiveEvent] = useState<EventProps | undefined>()
  const [openEventDrawer, setEventOpenDrawer] = useState(false)
  const [openLocationDrawer, setOpenLocationDrawer] = useState(false)

  const registerButtonAction = (event: EventProps) => {
    setActiveEvent(event)
    setEventOpenDrawer(true)
  }

  const closeEventDrawer = () => {
    setEventOpenDrawer(false)
  }

  const setFeatureFlags = ([card1, card2, ...rest]: EventProps[], layer: string) => {
    if (card1) {
      card1.featured = layer === 'Feature Left'
      card1.doubleFeatured = layer === '2 Cards'
    }
    if (card2) {
      card2.featured = layer === 'Feature Right'
      card2.doubleFeatured = layer === '2 Cards'
    }
    return [card1, card2, ...rest].filter((card) => card)
  }
  const row1 = setFeatureFlags(props.fields.Row1FeatureEvents.slice(), props.fields.RowLayer.value)
  const row2 = setFeatureFlags(props.fields.Row2FeatureEvents.slice(), props.fields.RowLayer2.value)

  const featuredRows = [...row1, ...row2]

  const eventButtons = (event: EventProps): ButtonProps[] =>
    [
      {
        'data-trackingid': event.id,
        link: { value: { href: event.url ?? '#', text: 'Explore Event' } },
        icon: 'chevron-right-double',
        type: 'small-secondary',
      },
      event.EventCanRegister
        ? event.EventEnableExternalForm && event.eventFormExternalUrl
          ? {
              'data-trackingid': event.id,
              link: { value: { href: event.eventFormExternalUrl, text: 'Register Now' } },
              icon: 'edit',
              type: 'small-action',
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : {
              'data-trackingid': event.id,
              link: { value: { text: 'Register Now' } },
              icon: 'edit',
              type: 'small-action',
              button: true,
              onClick: () => registerButtonAction(event),
            }
        : {
            'data-trackingid': event.id,
            link: {
              value: { text: 'Event Full' },
            },
            icon: 'error',
            type: 'small-action',
            button: true,
            disabled: true,
            className: 'border-grey-pale text-grey-pale',
          },
    ] as ButtonProps[]

  return (
    <>
      <div data-cols="12" className="relative mt-6">
        {featuredRows.length > 0 && (
          <>
            <h2 className="mb-6 mt-20">{props.fields.FeaturedEventsTitle.value}</h2>
            <div className="grid gap-4 xm:grid-cols-12">
              {featuredRows.map(({ featured, doubleFeatured, ...event }) => {
                return (
                  <FeaturedEventWrapper
                    key={event.id}
                    id={event.id}
                    featured={featured}
                    doubleFeatured={doubleFeatured}
                  >
                    <EventCard event={event} buttons={eventButtons(event)} />
                  </FeaturedEventWrapper>
                )
              })}
            </div>
          </>
        )}
      </div>
      <div data-cols="12" className="mb-10 mt-20">
        <LedeText
          id={props.rendering.uid as string}
          title={props.fields.UpcomingEventsTitle.value}
          CTAButton1={props.fields.SeeAllEventsLink}
          CTAButton1Theme="secondary"
          service="tri-service"
        />
      </div>
      {props.fields.UpcomingEvents && (
        <div className="container-break-right mb-20">
          <CarouselSwiper alignControls="right" id={props.rendering.uid as string}>
            {props.fields.UpcomingEvents.map(({ featured, doubleFeatured, ...event }) => (
              <FeaturedEventWrapper key={event.id} id={event.id} featured={featured} doubleFeatured={doubleFeatured}>
                <EventCard event={event} buttons={eventButtons(event)} />
              </FeaturedEventWrapper>
            ))}
          </CarouselSwiper>
        </div>
      )}
      <EventDrawer
        activeEvent={activeEvent}
        eventFormFields={props.fields.EventRegistrationForm.fields}
        isOpen={openEventDrawer}
        closeDrawer={() => closeEventDrawer()}
      />
      <LocationDrawer
        id={props.rendering.uid as string}
        isOpen={openLocationDrawer}
        service="army"
        closeDrawer={() => setOpenLocationDrawer(false)}
      />
    </>
  )
}

export default ADFEventHome
