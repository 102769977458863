import { RichText } from '@sitecore-jss/sitecore-jss-nextjs'

import ServiceTag from 'components/front-end/Tags/ServiceTag'
import { useService } from 'utils/useService'

interface StatementProps {
  text: string
  service?: string
  showServiceTag?: boolean
}

const StatementText = ({ text, service: defaultService, showServiceTag = true }: StatementProps) => {
  const service = useService(defaultService)

  return (
    <div className="statement-panel [.dark_&]:!text-white">
      {showServiceTag && (
        <div className="mb-18">
          <ServiceTag service={service} />
        </div>
      )}

      <RichText className="pull-quote mb-12" field={{ value: text }} />
    </div>
  )
}

export default StatementText
