import { twMerge } from 'tailwind-merge'

import AudioPlayer from '../Audio/AudioPlayer'
import ImageLoader from '../Images/ImageLoader'
import { StorySlideProps } from './StorySlide'

const MediaImagePanel = (props: StorySlideProps) => {
  const content = props?.content

  const desktopImage = content?.DesktopImage?.value ?? content?.MobileImage?.value
  const mobileImage = content?.MobileImage?.value ?? content?.DesktopImage?.value

  if (!desktopImage?.src || !mobileImage?.src) {
    return null
  }

  const getDisplay = () => {
    if ((content?.Layout?.value as string) === 'Portrait') {
      return 'hidden'
    } else {
      return mobileImage.src && (content?.Layout?.value as string) === 'Landscape' ? 'hidden lg:block' : 'block'
    }
  }

  const imagePanelClasses = `
    relative py-10 md:px-8
    w-screen lg:w-auto h-auto
    lg:h-full lg:py-[92px] lg:px-16
    xl:py-[108px] xl:px-18
    xxl:py-[175px] xxl:px-[120px]
    flex flex-wrap lg:flex-nowrap
  `
  const audioBlockClasses = `
    audio-wrapper w-full lg:w-22 h-10 lg:h-full relative
    [&_.audio-player]:absolute [&_.audio-player]:bottom-[30%] sm:[&_.audio-player]:left-12 lg:[&_.audio-player]:left-auto lg:[&_.audio-player]:right-0 lg:[&_.audio-player]:bottom-[70%]
    [&_.audio-player]:left-4 [&_.audio-player]:w-[calc(100%_-_32px)] sm:[&_.audio-player]:w-[initial]
  `
  const landscapeClasses = `
    landscape relative
    ${getDisplay()}
  `
  const portraitClasses = `
    portrait ${(content?.Layout?.value as string) === 'Portrait' ? 'block' : 'block lg:hidden'}
  `
  const loaderClasses = `
    relative [&_.image-loader]:!pb-0 [&_.image-loader]:absolute [&_.image-loader]:top-0 [&_.image-loader]:left-0 [&_.image-loader]:w-full [&_.image-loader]:h-full
  `
  return (
    <div className={imagePanelClasses}>
      {desktopImage.src && (
        <div className={twMerge(landscapeClasses, loaderClasses, mobileImage.src && 'hidden lg:flex')}>
          <svg
            className="h-auto w-full lg:h-full lg:w-auto"
            width={desktopImage.width as string}
            height={desktopImage.height as string}
            viewBox={`0 0 ${desktopImage.width} ${desktopImage.height as string}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width={desktopImage.width as string} height={desktopImage.height as string} />
          </svg>
          <ImageLoader
            height={desktopImage.height as number}
            width={desktopImage.width as number}
            src={desktopImage.src as string}
            alt={desktopImage.alt as string}
            full
            lazy
          />
        </div>
      )}
      {mobileImage.src && (
        <div className={twMerge(portraitClasses, loaderClasses, desktopImage.src && 'lg:hidden')}>
          <svg
            className="h-full max-w-full"
            width={mobileImage.width as string}
            height={mobileImage.height as string}
            viewBox={`0 0 ${mobileImage.width} ${mobileImage.height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width={mobileImage.width as string} height={mobileImage.height as string} />
          </svg>
          <ImageLoader
            height={mobileImage.height as number}
            width={mobileImage.width as number}
            src={mobileImage.src as string}
            alt={mobileImage.alt as string}
            full
            lazy
            sizes="100vw"
          />
        </div>
      )}
      {content?.AudioImage?.value && content?.File?.value?.href && (
        <div className={audioBlockClasses}>
          <AudioPlayer
            id={props.id}
            name={content?.Name?.value as string}
            role={content?.JobTitle?.value as string}
            url={content?.File?.value?.href as string}
            image={content?.AudioImage?.value?.src as string}
            audioTranscript={content?.AudioTranscript?.value as string}
            story
            service={props?.service}
          />
        </div>
      )}
    </div>
  )
}

export default MediaImagePanel
