import React, { useEffect, useRef, useState } from 'react'
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Placeholder,
  PlaceholdersData,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'
import { useRouter } from 'next/router'

import { EventRegoField } from 'components/ADFEventHome'
import usePageContext from 'components/front-end/_layoutContext'
import Button from 'components/front-end/Buttons/Button'
import Pagination from 'components/front-end/Buttons/Pagination'
import { FilterValue } from 'components/front-end/Drawers/drawer-components/Filter'
import EventSummaryCard from 'components/front-end/Events/event-details/EventSummaryCard'
import EventDrawer from 'components/front-end/Events/EventDrawer'
import EventSearchDrawer from 'components/front-end/Events/EventSearchDrawer'
import LocationDrawer from 'components/front-end/Events/LocationDrawer'
import Icon from 'components/front-end/Icons/Icon'
import SearchField from 'components/front-end/Search/SearchField'
import SearchNoResults from 'components/front-end/Search/SearchNoResults'
import FilterTag from 'components/front-end/Tags/FilterTag'
import { useIntersect } from 'utils/intersection'
import { extractUrlValue } from 'utils/regexp'
import { track, trackFilterInteraction } from 'utils/tracking'
import { useService } from 'utils/useService'

export interface EventSearchResult {
  Attendies?: number
  EventCanRegister: boolean
  EventEnableCapacity?: boolean
  EventEnableClosingDate: boolean
  EventEnableExternalForm?: boolean
  eventFormExternalUrl?: string
  EventIsHybrid?: boolean
  EventIsReserve: boolean
  EventIsVirtual?: boolean
  EventLocationType: string
  EventPIN?: string | null
  EventPhone?: string | null
  EventState: string
  EventStateId?: string
  EventTimeZone: string
  EventTimeZoneId?: string
  EventTimeZoneName: string
  EventVenue: string
  EventVirtualMeetingLink: string | null
  EventVirtualTime?: string
  banner?: string
  categories?: string
  EnableDietaryRequirements?: boolean
  eventCapacity: number
  eventCapacityIsFull?: boolean
  eventCode: string
  eventEndDate: string
  EventEndTime: string
  eventFormUrl?: string // not sent by server
  eventStartDate: string
  EventStartTime: string
  eventStatus: string
  eventStatusId?: string
  eventType: string
  eventVirtualCapacity: `${number}` | '' | number
  hideTimeDetailsForMultiDaysEvent: boolean
  id: string
  image: string
  inPersonRegistrants: number
  isClosed?: boolean | null // not sent by server
  isEventPage?: boolean
  isGapYear?: boolean | null
  isPriorityText?: string | null
  isPriority?: boolean | null
  isReserve?: boolean | null
  locationCity: string
  locationName: string
  locationState: string
  onlineRegistrants: number
  PriorityOrder?: number
  priorityRole?: string | null
  registrants: number
  service: string
  title: string
  url: string
}

export interface EventRegoForm {
  id: string
  url: string
  name: string
  displayName: string
  fields: EventRegoField
}

export interface EventSearchResultsResponse {
  TotalCount: number
  Results: EventSearchResult[]
  Message: string | null
}

export interface EventSearchResultsRequest {
  filterValues: {
    FacetId: string
    FacetValues: string[]
  }[]
  searchId: string
  page: number
  perPage: number
  query?: string
}

export interface userLocationProps {
  lat: number | undefined
  lng: number | undefined
  suburb: string
  state: string
  postcode: string
}

export type KeywordSuggestionsItem = {
  word: string
  isSuggestion: boolean
}

export type KeywordSuggestionsResponse = {
  suggestion?: string
  items?: [KeywordSuggestionsItem]
  error?: string
}

export interface KeywordSuggestionsRequest {
  index: string
  query: string
}

export type OrderingType = {
  id: string
  name: Field<string>
  fieldName: Field<string>
  isDefault: Field<string>
  description: Field<string>
}

export type EventSearchResultsDataType = {
  OrderingHeading: Field<string>
  OrderingOptions: {
    id: string
    name: string
    targetItems: Array<OrderingType>
  }
  EventHistoricalHeading: Field<string>
  EventDateHeading: Field<string>
  CategoryHeading: Field<string>
  ServiceHeading: Field<string>
  EventTypeHeading: Field<string>
  LocationTypeHeading: Field<string>
  SearchSource: {
    id: string
  }
}

export type EventSearchResultsField = {
  OrderingHeading: Field<string>
  OrderingOptions: {
    id: string
    name: string
    targetItems: Array<OrderingType>
  }
  EventHistoricalHeading: Field<string>
  EventDateHeading: Field<string>
  CategoryHeading: Field<string>
  ServiceHeading: Field<string>
  EventTypeHeading: Field<string>
  LocationTypeHeading: Field<string>
  EventStateHeading: Field<string>
  SearchSource: {
    id: string
  }
  EventRegistrationForm: EventRegoForm
  EventHistorical: DataField
  EventDate: DataField
  Categories: DataField
  Services: DataField
  EventStates: DataField
  EventTypes: DataField
  LocationTypes: DataField
  EventLocationCoordinate: DataField
}

export type FacetType = {
  name: Field<string>
  displayName: Field<string>
  id: string
  groupingName: {
    targetItem: {
      name: string
    }
  }
}

export interface DataField {
  id: string
  name: Field<string>
  children: {
    results: FacetType[]
  }
}

export interface ADFEventSearchResultsProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: EventSearchResultsField
  placeholders?: PlaceholdersData
  results: EventSearchResultsResponse
}

const HeadingKeysMap = {
  Categories: 'CategoryHeading',
  eventlocationtype: 'LocationTypeHeading',
  _EventIsInThePast: 'EventHistoricalHeading',
  _EventPeriod: 'EventDateHeading',
  _eventlocationstate: 'EventStateHeading',
  _eventsitename: 'ServiceHeading',
  _eventtypeid: 'EventTypeHeading',
} as const

type HeadingKeyType = keyof typeof HeadingKeysMap
type HeadingKeys = (typeof HeadingKeysMap)[HeadingKeyType]

const EventSearchResults = (props: ADFEventSearchResultsProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const pageState = usePageContext().pageContext
  const router = useRouter()

  //short cuts
  const searchSource = props?.fields?.SearchSource?.id ?? '{6A9BC189-E8B2-4DAD-93AE-E9CDD7CD54ED}'
  const eventHistoricalProps = props?.fields?.EventHistorical
  const eventDateProps = props?.fields?.EventDate
  const categoriesProps = props?.fields?.Categories
  const servicesProps = props?.fields?.Services
  const eventStatesProps = props?.fields?.EventStates
  const eventTypesProps = props?.fields?.EventTypes
  const locationTypesProps = props?.fields?.LocationTypes
  const eventLocationCoordinateProps = props?.fields?.EventLocationCoordinate

  const [currentPage, setCurrentPage] = useState<number>(() => {
    const matches = router?.asPath?.match(/page=([^&]*)/)
    const page = matches?.[1]
    return page && page != '' ? parseInt(page) : 1
  })
  const [resultsPerPage] = useState<number>(() => {
    const matches = router?.asPath?.match(/perPage=([^&]*)/)
    const perPage = matches?.[1]
    return perPage && perPage != '' ? parseInt(perPage) : 10
  })
  const phKey = `container-${props.params.DynamicPlaceholderId}`
  const gridcols = props.params.gridcols as string
  const [scrollOffset, setScrollOffset] = useState(0) // scroll offset for pagination click
  // lockScroll()
  const showSuggestion = false

  const setNewPayload = (query: string | undefined, page: number, filters: Map<string, Map<string, string>>) => {
    const res = {
      filterValues: Array.from(filters.entries())
        .filter((x) => x[1].size > 0)
        .map((x) => ({
          FacetId: x[0],
          FacetValues: Array.from(x[1].keys()),
        })),
      searchId: searchSource,
      page: page,
      perPage: 10,
      query: query,
    }
    setPayload(res)
    return res
  }

  useEffect(() => {
    setNewPayload(routeQuery, currentPage || 1, filters)
  }, [currentPage]) // can't access filters on initial render

  const createFilters = (path: string, location: userLocationProps | undefined) => {
    const eventHistoricals = new Map<string, string>(
      eventHistoricalProps?.children?.results?.map((x) => [x.name?.value, x.id])
    )
    const eventDateTypes = new Map<string, string>(eventDateProps?.children?.results?.map((x) => [x.name?.value, x.id]))
    const eventCategories = new Map<string, string>(
      categoriesProps?.children?.results?.map((x) => [x.name?.value, x.id])
    )
    const eventServices = new Map<string, string>(servicesProps?.children?.results?.map((x) => [x.name?.value, x.id]))
    const eventStates = new Map<string, string>(eventStatesProps?.children?.results?.map((x) => [x.name?.value, x.id]))
    const eventTypes = new Map<string, string>(eventTypesProps?.children?.results?.map((x) => [x.name?.value, x.id]))
    const eventLocationTypes = new Map<string, string>(
      locationTypesProps?.children?.results?.map((x) => [x.name?.value, x.id])
    )

    const coordinateMap = new Map<string, string>()
    const currentLocation = location

    if (currentLocation && currentLocation.postcode) {
      const coordinateValue = `${currentLocation?.lat},${currentLocation?.lng}`
      coordinateMap.set(coordinateValue, currentLocation.postcode)
    }

    const locationCoordinateFilter = coordinateMap

    const filters = new Map<string, Map<string, string>>([
      [
        eventHistoricalProps?.id,
        new Map<string, string>(
          extractUrlValue(path, eventHistoricalProps?.name?.value)
            ?.split(',')
            ?.map((x) => [eventHistoricals.get(x)!, x])
        ),
      ],
      [
        eventDateProps?.id,
        new Map<string, string>(
          extractUrlValue(path, eventDateProps?.name?.value)
            ?.split(',')
            ?.map((x) => [eventDateTypes.get(x)!, x])
        ),
      ],
      [
        categoriesProps?.id,
        new Map<string, string>(
          extractUrlValue(path, categoriesProps?.name?.value)
            ?.split(',')
            ?.map((x) => [eventCategories.get(x)!, x])
        ),
      ],
      [
        servicesProps?.id,
        new Map<string, string>(
          extractUrlValue(path, servicesProps?.name?.value)
            ?.split(',')
            ?.map((x) => [eventServices.get(x)!, x])
        ),
      ],
      [
        eventStatesProps?.id,
        new Map<string, string>(
          extractUrlValue(path, eventStatesProps?.name?.value)
            ?.split(',')
            ?.map((x) => [eventStates.get(x)!, x])
        ),
      ],
      [
        eventTypesProps?.id,
        new Map<string, string>(
          extractUrlValue(path, eventTypesProps?.name?.value)
            ?.split(',')
            ?.map((x) => [eventTypes.get(x)!, x])
        ),
      ],
      [
        locationTypesProps?.id,
        new Map<string, string>(
          extractUrlValue(path, locationTypesProps?.name?.value)
            ?.split(',')
            ?.map((x) => [eventLocationTypes.get(x)!, x])
        ),
      ],
      [eventLocationCoordinateProps?.id, locationCoordinateFilter],
    ])
    return filters
  }

  const hasFilters = () => {
    return (
      (filters.get(eventDateProps?.id)?.size ?? 0) +
        (filters.get(eventDateProps?.id)?.size ?? 0) +
        (filters.get(categoriesProps?.id)?.size ?? 0) +
        (filters.get(servicesProps?.id)?.size ?? 0) +
        (filters.get(eventStatesProps?.id)?.size ?? 0) +
        (filters.get(eventTypesProps?.id)?.size ?? 0) +
        (filters.get(locationTypesProps?.id)?.size ?? 0) +
        (filters.get(eventLocationCoordinateProps?.id)?.size ?? 0) >
      0
    )
  }

  const stickyListener = useRef<HTMLDivElement | null>(null)
  const searchField = useRef<HTMLDivElement | null>(null)
  const searchElement = useRef<HTMLDivElement | null>(null)
  const searchResults = useRef<HTMLDivElement | null>(null)
  const searchTags = useRef<HTMLDivElement | null>(null)

  const [searchPos, setSearchPos] = useState<true | false | undefined>(undefined)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openLocationDrawer, setOpenLocationDrawer] = useState(false)
  const [results, setResults] = useState(props?.results)
  const [routeQuery, setRouteQuery] = useState<string>(extractUrlValue(router.asPath, 'query') ?? '')

  const [locationFilter, setLocationFilter] = useState<userLocationProps | undefined>(undefined)
  const [tempFilters, setTempFilters] = useState(() => createFilters(router.asPath, undefined))
  const [filters, setFilters] = useState(() => createFilters(router.asPath, undefined))
  const [payload, setPayload] = useState<EventSearchResultsRequest | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [noResults, setNoResults] = useState<KeywordSuggestionsResponse | undefined>(undefined)

  const [searchController, setSearchController] = useState(new AbortController())
  const [suggestionController, setSuggestionController] = useState(new AbortController())

  const entryIntersect = useIntersect(stickyListener, '0px 0px')
  const state = usePageContext()
  const service = useService(state?.pageContext?.globalService, false) as string

  const tickFilter = (keyId: string, keyName: string, value: FilterValue) => {
    const map = filters.get(keyId)
    if (!map) return
    const isFilter = map.has(value.value)
    if (isFilter) {
      map?.delete(value.value)
    } else {
      map?.set(value.value, value.queryName!)
    }
    router.push(
      {
        query: {
          ...router.query,
          ...{
            [keyName]: Array.from(map.values())
              .map((x) => x)
              .join(','),
            page: 1,
          },
        },
      },
      undefined,
      {
        shallow: true,
      }
    )

    setFilters(filters)
    const query = extractUrlValue(router.asPath, 'query')
    setNewPayload(query, 1, filters)
  }

  const tickLocationFilter = () => {
    const filters = createFilters(router.asPath, undefined)
    setFilters(filters)
    setCurrentPage(1)
    const query = extractUrlValue(router.asPath, 'query')
    setNewPayload(query, 1, filters)
  }

  const trackAppliedEventFilterData = (query: Record<string, string>) => {
    if (query) {
      const filterSection: string[] = []
      for (const key of Object.keys(query)) {
        if (key && query[key]) {
          const sectionKey: HeadingKeys = HeadingKeysMap[key as HeadingKeyType]
          filterSection.push(`${props?.fields[sectionKey].value}-${query[key]}`)
        }
      }
      const selectedFilterOptions = `${filterSection.join(';')} - ${props.rendering.uid}` as const
      trackFilterInteraction('applied', 'event filter', selectedFilterOptions)
    }
  }

  const applyQuery = (filters: Map<string, Map<string, string>>) => {
    const eventHistoricalMap = filters.get(eventHistoricalProps?.id)
    const eventDateMap = filters.get(eventDateProps?.id)
    const categoriesMap = filters.get(categoriesProps?.id)
    const servicesMap = filters.get(servicesProps?.id)
    const eventTypesMap = filters.get(eventTypesProps?.id)
    const eventStatesMap = filters.get(eventStatesProps?.id)
    const locationTypesMap = filters.get(locationTypesProps?.id)
    const eventLocationCoordinateMap = filters.get(eventLocationCoordinateProps?.id)
    const query = {
      [eventHistoricalProps?.name?.value]: Array.from(eventHistoricalMap?.values()!).join(','),
      [eventDateProps?.name?.value]: Array.from(eventDateMap?.values()!).join(','),
      [categoriesProps?.name?.value]: Array.from(categoriesMap?.values()!).join(','),
      [servicesProps?.name?.value]: Array.from(servicesMap?.values()!).join(','),
      [eventStatesProps?.name?.value]: Array.from(eventStatesMap?.values()!).join(','),
      [eventTypesProps?.name?.value]: Array.from(eventTypesMap?.values()!).join(','),
      [locationTypesProps?.name?.value]: Array.from(locationTypesMap?.values()!).join(','),
      [eventLocationCoordinateProps?.name?.value]: Array.from(eventLocationCoordinateMap?.values()!).join(','),
    }
    router.push(
      {
        query: {
          ...router.query,
          ...query,
          page: 1,
        },
      },
      undefined,
      {
        shallow: true,
      }
    )
    trackAppliedEventFilterData(query)
  }

  useEffect(() => {
    // console.log('Payload has been changed')
    if (!payload || sitecoreContext?.pageEditing == true) return

    try {
      searchController.abort()
      suggestionController.abort()
    } catch (_ex) {}
    const newSearchController = new AbortController()
    const newSuggestionController = new AbortController()
    setSearchController(newSearchController)
    setSuggestionController(newSuggestionController)

    setIsLoading(true)
    setNoResults(undefined)
    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/eventSearch`, {
      signal: newSearchController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((r) => {
        if (!props?.results) {
          setResults(r)
          if ((r?.TotalCount ?? 0) == 0) {
            const keywordRequest: KeywordSuggestionsRequest = {
              index: '3E5609FF-1517-4EBE-9037-3EC539AF3D24',
              query: payload?.query ?? '',
            }
            fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/keywordSuggestion`, {
              signal: newSuggestionController.signal,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(keywordRequest),
            })
              .then((r) => r.json())
              .then((r) => {
                console.log('Suggestion has been received', r)
                setNoResults(r)
              })
              .finally(() => setIsLoading(false))
          } else {
            setNoResults(undefined)
            setIsLoading(false)
          }
        }
      })
      .catch(() => setIsLoading(false))
  }, [payload])

  useEffect(() => {
    // console.log(entryIntersect.intersectionRatio * 100)
    const entryRatio = entryIntersect?.intersectionRatio ? entryIntersect?.intersectionRatio * 100 : 0
    if (entryRatio) entryRatio < 85 ? setSearchPos(true) : setSearchPos(false)
  }, [entryIntersect])

  useEffect(() => {
    // console.log('searchPos', searchPos)
    const searchFieldVar = searchField.current as HTMLDivElement
    const searchElementVar = searchElement.current as HTMLDivElement
    const searchResultsVar = searchResults.current as HTMLDivElement
    const searchTagsVar = searchTags.current as HTMLDivElement
    const searchRect = searchElement.current?.getBoundingClientRect()

    if (searchPos) {
      if (searchElementVar) searchElementVar.style.height = searchRect!.height + 'px'
      if (searchFieldVar) {
        searchFieldVar.style.top = `64px` //`${topFixed}px`
        searchFieldVar.style.position = 'fixed'
        searchFieldVar.style.transform = `translateY(-${64 - 45}px)` //`translateY(-${(topFixed || 0) - 45}px)`
      }

      if (searchResultsVar && searchTagsVar) {
        searchResultsVar.style.transform = `translateY(-${searchResultsVar.offsetTop - searchTagsVar.offsetTop}px)`
      }
    } else if (searchPos === false) {
      if (searchElementVar) searchElementVar.style.height = 'auto'
      if (searchResultsVar) searchResultsVar.style.transform = `translateY(0px)`
      if (searchFieldVar) {
        searchFieldVar.style.transform = `translateY(0px)`
        searchFieldVar.style.position = 'initial'
      }
    }
  }, [searchPos])

  useEffect(() => {
    const filters = createFilters(router.asPath, locationFilter)
    setFilters(filters)
  }, [locationFilter])

  const sectionClasses = `
        relative mb-8
    `
  const columnLGLessGutters = `
        lg:!pr-[calc(var(--colwidth)_-_var(--gutter)_/_2)] lg:!pl-[calc(var(--colwidth)_-_var(--gutter)_/_2)]
        xxl:!pr-[calc(var(--colwidth)_*_2_-_var(--gutter)_/_2)] xxl:!pl-[calc(var(--colwidth)_*_2_-_var(--gutter)_/_2)]
    `
  const topSearchClasses = `
        [&_.adjust]:!p-0 [&_.adjust]:!m-0 [&_.adjust]:!w-full [&_.adjust]:!max-w-full
        [&_.search-inner]:!mx-6 lg:[&_.search-inner]:!mx-16 xl:[&_.search-inner]:!mx-18 xxl:[&_.search-inner]:!mx-[120px] [&_.search-inner]:!mb-1
        [&_input]:!lbody
        [&_.search-title]:opacity-0
        [&_+_.search-tags]:opacity-0
        [&_.icon-search]:!h-[22px] [&_.icon-search]:!w-[22px]
        [&_.sub-filter]:!h-0 [&_.sub-filter]:!p-0 [&_.sub-filter]:!opacity-0
    `
  const transitionClasses = `
        transition-all duration-[500ms] ease-out
    `
  const breadcrumbFollow = `
    breadcrumbFollow ${
      pageState.pageScroll?.scrollDirection && searchPos ? 'translate-y-16 xl:translate-y-22' : 'translate-y-0'
    }
    transition-all duration-[500ms] ease-out
    delay-[73ms] ease-linear
  `
  const registerButtonAction = (event: EventSearchResult) => {
    setActiveEvent(event)
    setEventOpenDrawer(true)
  }

  const [openEventDrawer, setEventOpenDrawer] = useState(false)
  const [activeEvent, setActiveEvent] = useState<EventSearchResult>()

  const filteredMap = filters.get(eventHistoricalProps?.id)

  const handleClickSuggestion = (word: string) => {
    router
      .push(
        {
          query: {
            ...router.query,
            query: word,
            page: 1,
          },
        },
        undefined,
        {
          shallow: true,
        }
      )
      .then(() => {
        setCurrentPage(1)
        setNewPayload(word, 1, filters)
        setRouteQuery(word)
      })
  }

  return (
    <div className="content-inner pb-20 pt-14 lg:pt-20 xl:pt-22 xxl:pt-[120px]">
      <div className="intersection-observer absolute left-0 top-[-1px] h-screen w-2" ref={stickyListener} />

      <section
        className={`SEARCH-HEAD relative z-[49] h-[100px] bg-white xm:mb-8 ${searchPos ? topSearchClasses : ''}`}
        ref={searchElement}
      >
        <div className={`w-full bg-white ${transitionClasses} adjust`} ref={searchField}>
          <div data-cols="12" className={`SEARCH-STICKY adjust relative bg-white ${breadcrumbFollow}`}>
            <div className={` ${transitionClasses} adjust`}>
              <div className={`span-12 ${columnLGLessGutters} ${transitionClasses} adjust`}>
                <SearchField
                  routeQuery={router.asPath}
                  placeholderText="What type of event are you looking for?"
                  searchTitle="FIND UPCOMING EVENTS"
                  focusField={true}
                  inPage={true}
                  searchPos={searchPos}
                  filter={
                    (filters.get(eventDateProps?.id)?.size ?? 0) +
                    (filters.get(categoriesProps?.id)?.size ?? 0) +
                    (filters.get(servicesProps?.id)?.size ?? 0) +
                    (filters.get(eventStatesProps?.id)?.size ?? 0) +
                    (filters.get(eventTypesProps?.id)?.size ?? 0) +
                    (filters.get(locationTypesProps?.id)?.size ?? 0)
                  }
                  location={true}
                  isJobSearch={false}
                  isEventSearch={true}
                  onclickFilter={() => {
                    setTempFilters(createFilters(router.asPath, undefined))
                    setTimeout(() => setOpenDrawer(!openDrawer), 10)
                  }}
                  onLocationClick={() => {
                    setLocationFilter(undefined)
                    setTempFilters(createFilters(router.asPath, undefined))
                    setTimeout(() => setOpenLocationDrawer(!openLocationDrawer), 10)
                  }}
                  updateResults={(query) => {
                    // don't reload for special sequence
                    if (query == '-----') return

                    // don't reload if payload already set
                    if (query == payload?.query) return

                    setNewPayload(query, 1, filters)
                    setCurrentPage(1)
                    router.push(
                      {
                        query: {
                          ...router.query,
                          query: query,
                          page: 1,
                        },
                      },
                      undefined,
                      {
                        shallow: true,
                      }
                    )
                    track({
                      event: 'search_completed',
                      ga4category: `In-page - Search`,
                      searchinitiated_page: router.asPath,
                      ga4name: query,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`SEARCH-TAGS search-tags ${sectionClasses} ${transitionClasses}`} ref={searchTags}>
        <div data-cols="12" className="relative">
          <div className="row">
            <div className={`span-12 ${columnLGLessGutters}`}>
              <div className="mb-2 flex flex-col justify-between md:flex-row md:items-start md:gap-x-3">
                <div className="filter-tags flex flex-wrap gap-x-2 gap-y-3 py-2">
                  {!filteredMap?.size ? (
                    <FilterTag tagText="VIEWING UPCOMING EVENTS" showButton={false} />
                  ) : (
                    <>
                      {eventHistoricalProps?.children?.results
                        ?.filter(({ id }) => filteredMap.has(id))
                        .map(({ id, displayName }) => (
                          <FilterTag key={id} tagText={`VIEWING ${displayName?.value}`} showButton={false} />
                        ))}
                    </>
                  )}

                  {eventDateProps?.children?.results?.map((x) => {
                    if (filters.get(eventDateProps?.id)?.has(x.id)) {
                      return (
                        <button
                          key={x.id}
                          onClick={() =>
                            tickFilter(eventDateProps?.id, eventDateProps?.name?.value, {
                              name: x?.displayName?.value ?? '',
                              value: x?.id ?? '',
                              queryName: x?.name?.value,
                            })
                          }
                        >
                          <FilterTag tagText={x.displayName?.value} />
                        </button>
                      )
                    } else {
                      return undefined
                    }
                  })}
                  {categoriesProps?.children?.results?.map((x) => {
                    if (filters.get(categoriesProps?.id)?.has(x.id)) {
                      return (
                        <button
                          key={x.id}
                          onClick={() =>
                            tickFilter(categoriesProps?.id, categoriesProps?.name?.value, {
                              name: x?.displayName?.value ?? '',
                              value: x?.id ?? '',
                              queryName: x?.name?.value,
                            })
                          }
                        >
                          <FilterTag tagText={x.displayName?.value} />
                        </button>
                      )
                    } else {
                      return undefined
                    }
                  })}
                  {servicesProps?.children?.results?.map((x) => {
                    if (filters.get(servicesProps?.id)?.has(x.id)) {
                      return (
                        <button
                          key={x.id}
                          onClick={() =>
                            tickFilter(servicesProps?.id, servicesProps?.name?.value, {
                              name: x?.displayName?.value ?? '',
                              value: x?.id ?? '',
                              queryName: x?.name?.value,
                            })
                          }
                        >
                          <FilterTag tagText={x.displayName?.value} />
                        </button>
                      )
                    } else {
                      return undefined
                    }
                  })}
                  {eventStatesProps?.children?.results?.map((x) => {
                    if (filters.get(eventStatesProps?.id)?.has(x.id)) {
                      return (
                        <button
                          key={x.id}
                          onClick={() =>
                            tickFilter(eventStatesProps?.id, eventStatesProps?.name?.value, {
                              name: x?.displayName?.value ?? '',
                              value: x?.id ?? '',
                              queryName: x?.name?.value,
                            })
                          }
                        >
                          <FilterTag tagText={x.displayName?.value} />
                        </button>
                      )
                    } else {
                      return undefined
                    }
                  })}
                  {eventTypesProps?.children?.results?.map((x) => {
                    if (filters.get(eventTypesProps?.id)?.has(x.id)) {
                      return (
                        <button
                          key={x.id}
                          onClick={() =>
                            tickFilter(eventTypesProps?.id, eventTypesProps?.name?.value, {
                              name: x?.displayName?.value ?? '',
                              value: x?.id ?? '',
                              queryName: x?.name?.value,
                            })
                          }
                        >
                          <FilterTag tagText={x.displayName?.value} />
                        </button>
                      )
                    } else {
                      return undefined
                    }
                  })}
                  {locationTypesProps?.children?.results?.map((x) => {
                    if (filters.get(locationTypesProps?.id)?.has(x.id)) {
                      return (
                        <button
                          key={x.id}
                          onClick={() =>
                            tickFilter(locationTypesProps?.id, locationTypesProps?.name?.value, {
                              name: x?.displayName?.value ?? '',
                              value: x?.id ?? '',
                              queryName: x?.name?.value,
                            })
                          }
                        >
                          <FilterTag tagText={x.displayName?.value} />
                        </button>
                      )
                    } else {
                      return undefined
                    }
                  })}
                  {locationFilter && (
                    <button
                      key={locationTypesProps.id}
                      onClick={() => {
                        localStorage.removeItem('userLocation')
                        setLocationFilter(undefined)
                        tickLocationFilter()
                      }}
                    >
                      <FilterTag tagText={locationFilter?.postcode} />
                    </button>
                  )}
                </div>
                <div className="clear-filter mb-3 mt-2 self-start md:mt-5 md:h-6">
                  {hasFilters() && (
                    <Button
                      data-trackingid={props.rendering.uid as string}
                      button
                      onClick={() => {
                        router
                          .push(
                            {
                              query: {
                                ...router.query,
                                ...{
                                  query: undefined,
                                  page: 1,
                                  [eventHistoricalProps?.name?.value]: undefined,
                                  [eventDateProps?.name?.value]: undefined,
                                  [categoriesProps?.name?.value]: undefined,
                                  [servicesProps?.name?.value]: undefined,
                                  [eventStatesProps?.name?.value]: undefined,
                                  [eventTypesProps?.name?.value]: undefined,
                                  [locationTypesProps?.name?.value]: undefined,
                                },
                              },
                            },
                            undefined,
                            {
                              shallow: true,
                            }
                          )
                          .then(() => {
                            setLocationFilter(undefined)
                            localStorage.removeItem('userLocation')
                            const filters = createFilters('', undefined)
                            setFilters(filters)
                            setTempFilters(filters)
                            setCurrentPage(1)
                            setNewPayload('', 1, filters)
                            // trigger update if it set to empty before
                            setRouteQuery('-----')
                            setTimeout(() => setRouteQuery(''), 1)
                          })
                      }}
                      type="tertiary-action"
                      icon="refresh"
                      link={{ value: { text: 'Clear All' } }}
                      service="tri-service"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {!noResults && (
        <section className={`SEARCH-BANNER ${sectionClasses}`}>
          <div className="relative" data-cols={gridcols}>
            <div className="row">
              <div className={`span-12 ${columnLGLessGutters} [&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden`}>
                <Placeholder name={phKey} rendering={props.rendering} />
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className={`SEARCH-RESULTS ${sectionClasses} ${
          isLoading ? 'h-[200px] bg-white' : 'bg-white'
        } ${transitionClasses}`}
        ref={searchResults}
      >
        {isLoading ? (
          <span className="absolute bottom-0 top-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        ) : (
          <div data-cols="12" className="relative">
            <div className="row">
              {results && results?.TotalCount > 0 && (
                <div className={`span-12 ${columnLGLessGutters}`}>
                  <div className="mx-[calc(var(--gutter)_/_2)] divide-y divide-grey-light">
                    <div className="pb-6">
                      <span className="sbody mr-2">
                        Showing: {(currentPage - 1) * resultsPerPage + 1}-
                        {Math.min(currentPage * resultsPerPage, results.TotalCount)} of {results.TotalCount} results
                      </span>
                    </div>
                    <div className="results [&>div]:px-0">
                      {results.Results.slice(0, 20).map((result) => (
                        <EventSummaryCard
                          key={result.eventCode}
                          data={result}
                          onClickRegister={() => {
                            registerButtonAction(result)
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <SearchNoResults keywords={noResults} query="" type="events" onClick={handleClickSuggestion} />
            </div>
            <div className="row mt-6">
              <div className={`span-12 ${columnLGLessGutters}`}>
                {results && results?.TotalCount > 0 && (
                  <Pagination
                    itemCount={results?.TotalCount}
                    currentPage={currentPage}
                    setCurrentPage={(page) => {
                      setCurrentPage(page)
                      router.push(
                        {
                          query: {
                            ...router.query,
                            page: page.toString(),
                          },
                        },
                        undefined,
                        {
                          shallow: true,
                        }
                      )
                      const query = extractUrlValue(router.asPath, 'query')
                      setNewPayload(query, page, filters)
                      setTimeout(() => {
                        // console.log(window)
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }, 1000)
                    }}
                    resultsPerPage={resultsPerPage}
                    scrollOffset={scrollOffset}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </section>
      <EventSearchDrawer
        props={props}
        openDrawer={openDrawer}
        filters={tempFilters}
        onClose={() => {
          setOpenDrawer(false)
        }}
        onReset={() => {
          setOpenDrawer(false)
          router
            .push(
              {
                query: {
                  ...router.query,
                  ...{
                    [eventHistoricalProps?.name?.value]: undefined,
                    [eventDateProps?.name?.value]: undefined,
                    [categoriesProps?.name?.value]: undefined,
                    [servicesProps?.name?.value]: undefined,
                    [eventStatesProps?.name?.value]: undefined,
                    [eventTypesProps?.name?.value]: undefined,
                    [locationTypesProps?.name?.value]: undefined,
                    page: 1,
                  },
                },
              },
              undefined,
              {
                shallow: true,
              }
            )
            .then(() => {
              const filters = createFilters('', undefined)
              setFilters(filters)
              setCurrentPage(1)
              setNewPayload('', 1, filters)
            })
        }}
        onApply={() => {
          setOpenDrawer(false)
          setCurrentPage(1)
          setFilters(tempFilters)
          setTempFilters(filters)
          applyQuery(tempFilters)
          const query = extractUrlValue(router.asPath, 'query')
          setNewPayload(query, 1, tempFilters)
        }}
      />
      <EventDrawer
        activeEvent={activeEvent}
        eventFormFields={props.fields.EventRegistrationForm.fields}
        isOpen={openEventDrawer}
        closeDrawer={() => setEventOpenDrawer(false)}
      />
      <LocationDrawer
        id={props.rendering.uid as string}
        isOpen={openLocationDrawer}
        service={service}
        closeDrawer={() => setOpenLocationDrawer(false)}
        onLocationSet={(x) => {
          console.log('onLocationSet: ', x)
          setLocationFilter(x)
          const filters = createFilters(router.asPath, x)
          setTempFilters(filters)
          setFilters(filters)
          const query = extractUrlValue(router.asPath, 'query')
          setNewPayload(query, 1, filters)
        }}
      />
    </div>
  )
}

export default EventSearchResults
