import { ReactNode, useEffect, useRef, useState } from 'react'
import { ComponentParams, ComponentRendering, Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin, twMerge } from 'tailwind-merge'

import { TabType } from 'components/ADFSideTab'
import useScrollLock from 'utils/scrollLock'
import slugify from 'utils/slugifyStrings'
import { useLessThan } from 'utils/useBreakpoints'
import usePageContext from '../_layoutContext'
import TabSwiper from '../Tabs/TabSwiper'
import MainNavBack from './MainNavBack'
import SideNavTab from './SideNavTab'

type TabName = 'tab' | 'section-tab' | 'title-tab' | 'nav-tab'

export type SideNavProps<T extends { name: string } = TabType> = {
  id: string
  name: TabName | Omit<string, TabName>
  heading?: ReactNode
  items: T[]
  className?: string
} & (
  | { rendering: ComponentRendering & { params: ComponentParams }; content?: never }
  | { content: Record<T['name'], ReactNode>; rendering?: never }
)

export const MAX_SIDENAV_TABS = 10
export const MAX_SIDENAV_SUB_TABS = 10

const SideNav = ({ id, name, className, heading, items, rendering, content }: SideNavProps) => {
  const { sitecoreContext } = useSitecoreContext()

  const [swipeMove, setSwipeMove] = useState(false)
  const { lockScroll, unlockScroll, lockedState } = useScrollLock()

  const [mobOpen, setMobOpen] = useState(false)

  // Handles tab 'actually' being set
  const previousDeepLinkTabIndex = useRef<number | undefined>(undefined)
  const lastKnownDeepLinkTabIndex = useRef<number | undefined>(undefined)

  const isEdit = sitecoreContext.pageState === 'edit'

  const { deepLink } = usePageContext().pageContext

  const tabList = items.map(({ name, title }) => slugify(name ?? title.field.value))
  const tabName = slugify(name as string)
  const deepLinkTabIndex = deepLink.tabSet[tabName] ? tabList?.indexOf(deepLink.tabSet[tabName]) : undefined
  const tabIndex = deepLinkTabIndex ?? 0

  const selectedTab = items[tabIndex]
  const subList = selectedTab?.children?.results || []

  const subTabName = slugify(selectedTab?.name || 'nav-tab')

  // title.field.value is only used for roles
  const subTabList = subList.map(({ name, title }) => slugify(name ?? title.field.value))
  const deepLinkSubTabIndex = deepLink.tabSet[subTabName] ? subTabList?.indexOf(deepLink.tabSet[subTabName]) : undefined
  const subTabIndex = deepLinkSubTabIndex ?? 0

  const lessMd = useLessThan('md')
  if (!lessMd && lockedState.current) {
    unlockScroll()
  }

  useEffect(() => {
    if (!isEdit && lessMd && previousDeepLinkTabIndex.current !== deepLinkTabIndex) {
      setTimeout(() => {
        const shouldMobOpen = typeof deepLinkTabIndex !== 'undefined'
        setMobOpen(shouldMobOpen)
        if (shouldMobOpen) {
          lockScroll()
        } else {
          unlockScroll()
        }
      }, 0)
      previousDeepLinkTabIndex.current = deepLinkTabIndex
      if (typeof deepLinkTabIndex !== 'undefined') {
        lastKnownDeepLinkTabIndex.current = deepLinkTabIndex
      }
    }
  }, [deepLinkTabIndex, isEdit, lessMd, lockScroll, unlockScroll])

  const placeholderIndex = `${tabIndex}-${subTabIndex}`

  if (!rendering) {
    return null
  }

  return (
    <>
      <div className={twMerge('relative', className)} data-cols={12} id={slugify(`${tabName}=${tabIndex}`)}>
        <div className="row">
          <div className="col span-12 md-span-4 lg-span-3 md:mr-[calc(var(--colwidth))]">
            <div className="side-nav-list w-full ">
              {heading}

              <nav className="side-nav w-full">
                {items.map((item, i) => {
                  return (
                    <SideNavTab
                      id={id}
                      key={i}
                      list={item}
                      onClick={(idx) => {
                        // This event does 2 things, verifies that the current tab is where this event is coming from
                        // and also update the subTab
                        const tabAction: Parameters<typeof deepLink.setTabSet>[0] = {
                          type: 'SET',
                          payload: {},
                        }

                        // Force the first level of the tab (as the first index may not be set in the url)
                        tabAction.payload[tabName] = tabList[i]
                        if (tabIndex === i && typeof idx !== 'undefined' && typeof subTabList[idx] !== 'undefined') {
                          tabAction.payload[subTabName] = subTabList[idx]
                        }
                        deepLink.setTabSet(tabAction)
                      }}
                      isSelected={
                        lessMd ? deepLinkTabIndex === i || lastKnownDeepLinkTabIndex.current === i : tabIndex === i
                      } // open first by default
                      isExperience={isEdit}
                      selectedChild={tabIndex === i ? subTabIndex : undefined}
                    />
                  )
                })}
              </nav>

              {rendering ? (
                <div className="sidetab-narrow mt-10 [&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden [&_>_div]:mx-0 [&_>_div]:w-full [&_>_div]:px-0">
                  <Placeholder name="sidetab-narrow" rendering={rendering} />
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={twMerge(
              'span-12 md-span-7 lg-span-8 xxl-span-7 relative hidden md:block [&_.contentBlock>div]:lg:max-w-none',
              isEdit && 'block'
            )}
            id={slugify(`${subTabName}=${subTabIndex}`)}
          >
            {[...Array(MAX_SIDENAV_TABS)].map((_item, i) =>
              [...Array(MAX_SIDENAV_TABS)]
                .filter((_subItem, j) => isEdit || (rendering?.placeholders?.[`placeholder${i}-${j}`]?.length ?? 0) > 0)
                .map((_subItem, j) => {
                  const key = `${i}-${j}`
                  return (
                    <div
                      key={key}
                      className={`${placeholderIndex !== key ? 'hidden' : isEdit ? '[&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden' : ''}`}
                      id={key}
                    >
                      {rendering ? <Placeholder name={`placeholder${key}`} rendering={rendering} /> : content?.[key]}
                    </div>
                  )
                })
            )}
          </div>
        </div>
      </div>

      <div
        className={twJoin(
          'tab-content-takeover fixed left-0 right-0 top-0 z-50 h-full w-full bg-white md:hidden',
          !isEdit && mobOpen ? 'block' : 'hidden'
        )}
      >
        <div className="tab-content-back mb-5 border-b border-grey-light">
          <div
            data-cols={12}
            className="is-fixed flex h-16 w-full !max-w-[unset] items-center !pr-0 md:pr-[calc(var(--gutter)/2)]"
          >
            <div className="shrink-0 border-r border-black pr-6">
              <MainNavBack
                title={selectedTab?.name ?? 'Back'}
                onClick={() => {
                  setMobOpen(false)
                  unlockScroll()
                  const sectionTabName = deepLink.tabSet['section-tab']
                  if (sectionTabName) {
                    deepLink.setTabSet({
                      type: 'SET',
                      payload: { 'section-tab': sectionTabName },
                    })
                  }
                }}
              />
            </div>

            <div className="relative overflow-hidden">
              {subList.length > 1 && (
                <TabSwiper
                  tabList={subList}
                  onClick={(idx) => {
                    deepLink.setTabSet({
                      type: 'SET',
                      payload: { [tabName]: subTabName, [subTabName]: subTabList[idx] },
                    })
                  }}
                  isSelected={subTabIndex}
                  setSwipeMove={setSwipeMove}
                />
              )}
              <div
                className={twJoin(
                  'absolute right-0 top-0 z-30 h-[65px] w-12 bg-gradient-to-r from-gradient to-white transition duration-200',
                  swipeMove ? 'opacity-0' : 'opacity-1'
                )}
              />
            </div>
          </div>
        </div>
        <div
          data-cols={12}
          className="is-fixed !-mr-0 max-h-[calc(100%_-_85px)] !max-w-[initial] overflow-y-auto py-12 !pr-12"
        >
          {[...Array(MAX_SIDENAV_TABS)].map((_item, i) =>
            [...Array(MAX_SIDENAV_SUB_TABS)]
              .filter((_subItem, j) => (rendering.placeholders?.[`placeholder${i}-${j}`]?.length ?? 0) > 0)
              .map((_subItem, j) => {
                const key = `${i}-${j}`
                return (
                  <div key={key} className={`${placeholderIndex !== key ? 'hidden' : ''}`} id={id}>
                    <Placeholder name={`placeholder${key}`} rendering={rendering} />
                  </div>
                )
              })
          )}
        </div>
      </div>
    </>
  )
}

export default SideNav
