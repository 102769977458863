import React from 'react'
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import { cleanHeadingStyle, HeadingElementStyle } from 'src/utils/cleanHeadingStyle'
import { useService } from 'src/utils/useService'

import usePageContext from 'components/front-end/_layoutContext'
import HeaderText, { HeadingLevel } from 'components/front-end/TextBlocks/HeaderText'
import LedeText from 'components/front-end/TextBlocks/LedeText'
import QuoteText from 'components/front-end/TextBlocks/QuoteText'
import TitleText from 'components/front-end/TextBlocks/TitleText'

export type ContentComponentType = 'Title' | 'Quote' | 'Header' | 'Lede'

interface ContentBlockField {
  id: string
  Type: ContentComponentType | Omit<string, ContentComponentType>
  Title: string
  Description: string
  ShowServiceTag: boolean
  HeadingStyle?: HeadingElementStyle
  LedeCopySize?: string
  CTAButton1?: LinkField
  CTAButton1Theme?: string
  CTAButton2?: LinkField
  CTAButton2Theme?: string
}

const ContentComponent = ({
  id,
  Type = '',
  Title = '',
  Description = '',
  ShowServiceTag = false,
  HeadingStyle,
  LedeCopySize,
  CTAButton1,
  CTAButton1Theme,
  CTAButton2,
  CTAButton2Theme,
}: ContentBlockField): JSX.Element => {
  const state = usePageContext()
  const service = useService(state?.pageContext?.globalService, false)
  const headingStyle = cleanHeadingStyle(HeadingStyle) ?? 'h1'

  const maxColWidth = 'lg:max-w-[calc(100vw_/12_*_7_-_calc(var(--gutter)_*_6)_+_calc(var(--outermargin)_/_2))]'
  return (
    <div className={`contentBlock mx-0`}>
      {Type === 'Text' && (
        <div className={maxColWidth}>
          <TitleText
            id={id}
            title={Title}
            text={Description}
            HeadingStyle={HeadingStyle || 'h1'}
            LedeCopySize={LedeCopySize}
            CTAButton1={CTAButton1}
            CTAButton1Theme={CTAButton1Theme}
            CTAButton2={CTAButton2}
            CTAButton2Theme={CTAButton2Theme}
            service={service}
            showButton
          />
        </div>
      )}
      {Type === 'Lede' && (
        <LedeText
          id={id}
          title={Title}
          text={Description}
          service={service}
          HeadingStyle={HeadingStyle ?? 'h1'}
          CTAButton1={CTAButton1}
          LedeCopySize={LedeCopySize}
          CTAButton1Theme={CTAButton1Theme}
          CTAButton2={CTAButton2}
          CTAButton2Theme={CTAButton2Theme}
        />
      )}
      {Type === 'Quote' && (
        <div className="max-w-5xl ">
          <QuoteText
            text={Description}
            author={Title}
            service={service}
            showServiceTag={false}
            HeadingStyle={HeadingStyle}
          />
        </div>
      )}
      {Type === 'Header' && (
        <div className={maxColWidth}>
          <HeaderText
            text={Description}
            title={Title}
            service={service}
            showServiceTag={ShowServiceTag}
            headingLevel={headingStyle as HeadingLevel}
            headingStyle="h3"
          />
        </div>
      )}
    </div>
  )
}

export default ContentComponent
