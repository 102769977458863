import { useEffect, useRef, useState } from 'react'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

import { useService } from 'src/utils/useService'
import { DynamicLoader } from './DynamicLoader'
import { getKey, mapOptions } from './map.config'

export type MapType = 'navy' | 'army' | 'air-force' | 'army-reserve' | 'event' | 'adfc-contact-us' | undefined | null

export interface MarkerItem {
  id: string
  name: string
  latitude: number
  longitude: number
  focusToLocation?: boolean
}

type GoogleMapProps = {
  mapType?: MapType
  markerItems?: MarkerItem[]
  userMarker?: MarkerItem
  focusMarker?: MarkerItem
  onMarkerClicked?: (x: MarkerItem) => void
}

const markerColours = {
  'tri-service': '#FF8F1C',
  army: '#F9BF2A',
  'air-force': '#549FD6',
  navy: '#1877CF',
} as any

const GoogleMap = ({ mapType, markerItems, userMarker, focusMarker, onMarkerClicked }: GoogleMapProps): JSX.Element => {
  // google maps related
  const [map, setMap] = useState<any>(null)
  const [markers, setMarkers] = useState<any>(null)
  const [centreMarker, setCentreMarker] = useState<any>(null)
  const [markerCluster, setMarkerCluster] = useState<any>(null)

  const mapRef = useRef(null)

  const getSvg = (colour: string) =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.1968 26.0612C12.4051 29.3251 15.7454 32.8598 20.2176 36.6654C24.6898 32.8598 28.0301 29.3251 30.2384 26.0612C32.4468 22.7973 33.5509 19.7765 33.5509 16.9987C33.5509 12.832 32.2107 9.51259 29.5301 7.04036C26.8496 4.56814 23.7454 3.33203 20.2176 3.33203C16.6898 3.33203 13.5857 4.56814 10.9051 7.04036C8.22455 9.51259 6.88428 12.832 6.88428 16.9987C6.88428 19.7765 7.98844 22.7973 10.1968 26.0612ZM20.2176 21.6654C21.5986 21.6654 22.7771 21.1773 23.7533 20.2011C24.7295 19.2249 25.2176 18.0463 25.2176 16.6654C25.2176 15.2844 24.7295 14.1058 23.7533 13.1297C22.7771 12.1535 21.5986 11.6654 20.2176 11.6654C18.8367 11.6654 17.6581 12.1535 16.6819 13.1297C15.7057 14.1058 15.2176 15.2844 15.2176 16.6654C15.2176 18.0463 15.7057 19.2249 16.6819 20.2011C17.6581 21.1773 18.8367 21.6654 20.2176 21.6654Z" fill="${colour}"/></svg>`

  useEffect(() => {
    const apiKey = getKey(mapType)

    if (!apiKey) {
      return
    }

    const loader = new DynamicLoader({
      apiKey,
      version: 'weekly',
      libraries: ['places'],
    })

    const libs = [loader.importLibrary('maps'), loader.importLibrary('marker')] as const
    Promise.all(libs)
      .then(([maps]) => {
        if (mapRef.current) {
          setMap(new maps.Map(mapRef.current, mapOptions))
        }
      })
      .catch((e) => {
        console.error('Error loading Google Maps:', e)
      })
  }, [mapType])

  const service = useService() as string

  useEffect(() => {
    if (!markerItems || !map || !google) return

    const markerColour = markerColours[service]

    const icon = {
      path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
      fillColor: markerColour,
      fillOpacity: 1,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 0.75,
    }

    const newMarkers = markerItems?.map((x) => {
      const marker = {
        map,
        title: x.name,
        position: {
          lat: x.latitude,
          lng: x.longitude,
        },
        draggable: false,
        icon: {
          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg(markerColour)),
          scaledSize: new google.maps.Size(41, 40),
        },
      }

      const gMarker = new google.maps.Marker(marker)
      gMarker.addListener('click', () => {
        onMarkerClicked?.(x)
        // infoWindow.setContent(`<h3>${p.name?.value}</h3><p>Test Paragraph</p>`)
        // infoWindow.open(map, gMarker)
      })
      return gMarker
    })

    const renderer = {
      render: (x: any) => {
        return new google.maps.Marker({
          label: { text: String(x?.count ?? 0), color: 'white', fontSize: '16px' },
          position: x?.position,
          draggable: false,
          icon: icon,
        })
      },
    }
    const newMarkerCluster = new MarkerClusterer({ markers: newMarkers, map, renderer })

    //remove old markers
    markers?.forEach((x: any) => {
      x.setMap(null)
    })
    setMarkers(newMarkers)
    markerCluster?.clearMarkers()
    setMarkerCluster(newMarkerCluster)

    const boundingRect = markerItems
      .map((x) => {
        return {
          left: x.latitude,
          right: x.latitude,
          top: x.longitude,
          bottom: x.longitude,
        }
      })
      .reduce(
        (x, y) => {
          return {
            left: x.left < y.left ? x.left : y.left,
            right: x.right > y.right ? x.right : y.right,
            top: x.top > y.top ? x.top : y.top,
            bottom: x.bottom < y.bottom ? x.bottom : y.bottom,
          }
        },
        {
          left: markerItems.length > 0 ? markerItems[0].latitude : -25.2744,
          right: markerItems.length > 0 ? markerItems[0].latitude : -25.2744,
          top: markerItems.length > 0 ? markerItems[0].longitude : 133.7751,
          bottom: markerItems.length > 0 ? markerItems[0].longitude : 133.7751,
        }
      )

    const southWest = new google.maps.LatLng(boundingRect.left, boundingRect.bottom)
    const northEast = new google.maps.LatLng(boundingRect.right, boundingRect.top)
    const bounds = new google.maps.LatLngBounds(southWest, northEast)
    map.fitBounds(bounds)
  }, [markerItems, map, service])

  useEffect(() => {
    if (!map || !google) return

    //console.log('User Marker: ', userMarker)

    //remove old markers
    centreMarker?.setMap(null)

    if (userMarker) {
      const newCentreMarker = ((x) => {
        const marker = {
          map,
          title: x.name,
          position: {
            lat: x.latitude,
            lng: x.longitude,
          },
          draggable: false,
          icon: {
            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg('#EF4F32')),
            scaledSize: new google.maps.Size(41, 40),
          },
        }

        //console.log('New marker: ', marker, google)
        const gMarker = new google.maps.Marker(marker)
        gMarker.addListener('click', () => {
          console.log('user marker clicked')
        })
        return gMarker
      })(userMarker)
      setCentreMarker(newCentreMarker)
      if (userMarker.focusToLocation) {
        map.setZoom(12)
        map.setCenter({
          lat: userMarker?.latitude,
          lng: userMarker?.longitude,
        })
      }
    }
  }, [userMarker, map])

  useEffect(() => {
    if (!focusMarker || !map || !google) return

    const { latitude, longitude } = focusMarker

    markers?.forEach((x: any) => {
      const lat = x.position.lat()
      const lng = x.position.lng()
      if (latitude == lat && longitude == lng) {
        x.setIcon({
          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg('black')),
          scaledSize: new google.maps.Size(41, 40),
        })
      }
    })

    map.setZoom(17)
    map.setCenter({
      lat: latitude,
      lng: longitude,
    })

    onMarkerClicked?.(focusMarker)

    return () => {
      if (!focusMarker || !map || !google) return

      const markerColour = markerColours[service]
      markers?.forEach((x: any) => {
        const lat = x.position.lat()
        const lng = x.position.lng()
        if (latitude == lat && longitude == lng) {
          x.setIcon({
            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg(markerColour)),
            scaledSize: new google.maps.Size(41, 40),
          })
        }
      })
    }
  }, [focusMarker, map, service])

  return <div className="h-full" ref={mapRef}></div>
}

export default GoogleMap
