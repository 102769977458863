import { ReserveUnit } from 'components/ADFReserveUnitLocator'
import Drawer from '../Drawers/Drawer'
import Header from '../Drawers/drawer-components/Header'
import JobCard from '../JobCard/JobCard'

export interface MapJobDrawerProps {
  id: string
  props: ReserveUnit | null
  isOpen: boolean
  onClose: () => void
}

const MapJobDrawer = ({ id, props, isOpen, onClose }: MapJobDrawerProps) => {
  if (!props) {
    return null
  }

  return (
    <Drawer
      title={props.name}
      titleIcon="location"
      id="map-drawer"
      show={isOpen}
      closeDrawer={onClose}
      hideFooter={true}
    >
      <div className="[&>div]:ml-0">
        <Header text={`${props.jobs.length} Available Roles`} />
        {isOpen &&
          props.jobs.map((job) => (
            <JobCard
              key={job.id}
              jobDetails={job}
              compact
              buttons={[
                {
                  'data-trackingid': id,
                  'data-jobname': job.title as string,
                  'data-jobtype': job.isPriority ? 'priority to fill' : '',
                  link: { value: { href: `${job.url}`, text: 'View Role' } },
                  icon: 'chevron-right-double',
                  type: 'small-secondary',
                },
              ]}
            />
          ))}
      </div>
    </Drawer>
  )
}

export default MapJobDrawer
