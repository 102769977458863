import { Service } from 'src/utils/useService'

import QuickLink from '../QuickLinks/QuickLink'
import FAQFeedback from './FAQFeedback'
import { FAQ } from './FAQTypes'

export type FAQContentProps = Omit<FAQ, 'text'> & {
  service?: Service
}

const FAQContent = ({ question, answer, heading, quickLink, id, service }: FAQContentProps) => {
  if (!id) {
    return null
  }

  const richTextAreaClasses = `
    space-y-4
    [&_ul]:space-y-2 [&_ul]:pl-6 [&_ul]:list-disc
    [&_a]:underline
  `
  return (
    <>
      {heading && (
        <span className="xstag-bold mt-10 inline-block bg-grey-pale px-2 py-1.5 text-black-off xm:hidden">
          {heading}
        </span>
      )}
      <h4 className="mb-4 mt-6 text-black xm:mt-16">{question}</h4>
      <div className={`pb-6 pt-4 ${richTextAreaClasses}`} dangerouslySetInnerHTML={{ __html: answer }} />
      <div className="border-t border-grey-light pt-6">
        <FAQFeedback id={id} question={question} service={service} />
      </div>
      {quickLink ? <QuickLink {...quickLink} /> : null}
    </>
  )
}

export default FAQContent
