import {
  Field,
  ImageField,
  Image as JssImage,
  Text,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs'
import Image, { ImageProps } from 'next/image'

import { ComponentProps } from 'lib/component-props'
import { getImagePath } from './front-end/Images/getImagePath'

interface RankCardItem {
  fields: {
    RankTitle: Field<string>
    RankImage: ImageField
  }
}

interface Fields {
  items: RankCardItem[]
}

export type RankCardListProps = ComponentProps & {
  fields: Fields
}

export const RankCardList = ({ params, fields }: RankCardListProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const id = params.RenderingIdentifier ?? params.DynamicPlaceholderId
  return (
    <div id={id ? id : undefined} className="@container">
      <div className="grid grid-cols-1 @md:grid-cols-2 @md:gap-4 @3xl:grid-cols-3 @3xl:gap-x-10">
        {fields.items
          .filter((item) => item.fields.RankImage.value?.src)
          .map((item, index) => (
            <figure key={index}>
              <div key={index} className="flex justify-center bg-grey-light">
                {sitecoreContext.pageEditing ? (
                  <JssImage field={item.fields.RankImage} />
                ) : (
                  <Image
                    {...(item.fields.RankImage.value as ImageProps)}
                    aria-labelledby={`${id}-${index}`}
                    src={getImagePath(item.fields.RankImage.value?.src ?? '')}
                  />
                )}
              </div>
              <figcaption className="my-6 text-black @md:mb-4 @md:mt-4 [.dark_&]:text-white-off" id={`${id}-${index}`}>
                <Text field={item.fields.RankTitle} />
              </figcaption>
            </figure>
          ))}
      </div>
    </div>
  )
}

export default withDatasourceCheck()<RankCardListProps>(RankCardList)
