import { trackFAQ } from 'src/utils/tracking'

import Icon from '../Icons/Icon'
import { boldQueryMatch } from '../Search/SearchInputResults'
import { useFAQContext } from './FAQContext'
import { FAQ, GroupedFAQs } from './FAQTypes'

type FAQListProps = {
  faqs: FAQ[]
  groupByHeading?: boolean
}

const FAQList = ({ faqs, groupByHeading }: FAQListProps) => {
  const {
    state: { selectedSubcategoryId, selectedHeading },
    lookup,
  } = useFAQContext()

  if (selectedSubcategoryId) {
    faqs = faqs.filter((faq) => faq.subcategories.includes(selectedSubcategoryId))
  }

  if (selectedHeading) {
    faqs = faqs.filter((faq) => faq.heading === selectedHeading)
  }

  let groupedFaqs: GroupedFAQs = []
  if (groupByHeading) {
    groupedFaqs = lookup.faqsGroupedByHeading(faqs.map(({ id }) => id))
  } else {
    groupedFaqs = [{ heading: selectedHeading, faqs }]
  }

  return (
    <div className="flex w-full flex-col">
      {groupedFaqs
        .sort((x, y) => x.heading.localeCompare(y.heading))
        .map(({ heading, faqs }) => (
          <FAQListGroup key={heading} heading={heading} faqs={faqs} />
        ))
      }
    </div>
  )
}

export const FAQListGroup = ({ heading, faqs }: GroupedFAQs[number]) => {
  return (
    <div className="flex w-full flex-col gap-y-2 xm:py-6">
      {heading && (
        <h6 className="font-bold leading-8 text-black" aria-level={3}>
          {heading}
        </h6>
      )}
      <ul className="flex flex-col pb-6 pt-4">
        {faqs.sort((x, y) => x.question.localeCompare(y.question)).map((faq) => (
          <FAQListItem key={faq.id} {...faq} />
        ))}
      </ul>
    </div>
  )
}

export const FAQListItem = (props: FAQ) => {
  const {
    state: { query, selectedSubcategoryId },
    send,
  } = useFAQContext()
  const highlightMatches = !selectedSubcategoryId

  const handleClick = () => {
    trackFAQ(props)
    send({ type: 'SELECT_FAQ', payload: props.id })
  }

  return (
    <li className="block border-b border-solid border-grey-light first:border-t">
      <button
        className="flex w-full items-center justify-between gap-x-6 py-5 text-left [&_path]:stroke-2"
        onClick={handleClick}
      >
        <span
          className="flex-1 text-black"
          dangerouslySetInnerHTML={{
            __html: highlightMatches ? boldQueryMatch(props.question, query) : props.question,
          }}
        ></span>
        <Icon name="arrow-east" type="stroke" width={16} height={16} />
      </button>
    </li>
  )
}

export default FAQList
