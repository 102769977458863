import { Field, useSitecoreContext, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'

import { IconNames } from 'components/front-end/Icons/icon-data'
import ListIcon from './front-end/Lists/ListIcon'

interface IconTextItem {
  fields: {
    Icon: Field<IconNames>
    Description: Field<string>
    Heading: Field<string>
  }
}

type IconTextListProps = ComponentProps & {
  fields: {
    Layout: Field<string> // 1 Column, 2 Column
    items: IconTextItem[]
  }
}

const IconTextList = (props: IconTextListProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const gridCols = props.fields.Layout.value === '1 Column' ? '1' : '2'
  const service = (sitecoreContext.route?.fields?.Service as Field<string>) || 'tri-service'
  return (
    <div className={`grid md:grid-cols-${gridCols} gap-10`}>
      {props.fields.items.map((item, index) => (
        <ListIcon
          key={index}
          iconName={item.fields.Icon?.value}
          iconType="content"
          text={item.fields.Description?.value}
          titleText={item.fields.Heading?.value}
          isHorizontal={props.fields.Layout.value === '1 Column'}
          service={service.value}
        />
      ))}
    </div>
  )
}

export default withDatasourceCheck()<IconTextListProps>(IconTextList)
