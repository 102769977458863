import Icon from 'components/front-end/Icons/Icon'
import { ContentIcons } from 'components/front-end/Icons/icon-data'

type HeaderProps = {
  text: string
  icon?: ContentIcons
}

const Header = ({ text, icon }: HeaderProps): JSX.Element => {
  const containerClasses = icon ? 'pt-16' : 'pt-8'

  return (
    <div className={`${containerClasses} pb-4`}>
      {icon ? (
        <div className="pb-4 xs:pb-5">
          <Icon name={icon} className="h-14 w-14 xs:h-16 xs:w-16" type="content" />
        </div>
      ) : null}
      <div className={'h4'}>{text}</div>
    </div>
  )
}

export default Header
