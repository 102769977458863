import { useRef, useState } from 'react'
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs'

import Icon from 'components/front-end/Icons/Icon'
import slugify from 'utils/slugifyStrings'
import { track } from 'utils/tracking'
import { truncate } from 'utils/truncate'

type MainTabProps = {
  title?: Field<string>
  tag?: Field<string>
  onClick?: () => void
  isExperience?: boolean
  isSelected?: boolean
  tracking?: {
    component: string
    name: string
    guid: string
  }
}

const Tab = ({ title, tag, onClick, isExperience, isSelected, tracking }: MainTabProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  })

  const tabElement = useRef<HTMLButtonElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const offsetLeft = tabElement?.current?.getBoundingClientRect().left || 0
    const width = tabElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < 80) finalX = 0
    if (finalX > width - 80) finalX = width

    setMousePosition({ left: finalX, top: ev.pageY })
  }

  const ellipsisStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical' as const,
  }

  const buttonClasses = `
        ${isSelected ? 'icon-hover-true is-active' : ''}
        group z-0 relative flex flex-col items-start
        border-t xm:border-t-0 last:border-b xm:last:border-b-0 border-r xm:border-r-0 border-l xm:last:border-r
        border-solid border-grey-light [.dark_&]:border-grey-medium
        min-w-full xm:min-w-0
        p-[18px] xm:px-8 xm:py-6
        text-left overflow-hidden transition duration-400
    `
  const titleClasses = `
        ${tag ? 'lbody' : 'mbody'}
        z-[1] relative block
        text-gray-medium [.dark_&]:text-white
        group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black
        [.dark_&]:group-[.is-active]:text-black

    `
  const tagClasses = `
        mbody z-[1] relative block mt-2
        text-gray-medium [.dark_&]:text-white
        group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black
        [.dark_&]:group-[.is-active]:text-black
        transition duration-400

    `
  const iconClasses = `
        z-[1] xm:hidden absolute right-[18px] top-1/2
        group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black
        [.dark_&]:text-white
        -translate-y-1/2
    `
  const fillClasses = `
        tab-fill z-0 absolute top-0 left-0 h-full w-full
        bg-black-off [.dark_&]:bg-white
        scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
        transition duration-400 ease-linear
    `

  return (
    <button
      tabIndex={0}
      ref={tabElement}
      onMouseMove={(ev) => handleMouseMove(ev)}
      onMouseUp={() => {
        onClick?.()
        track({
          event: 'tab_button',
          ga4category: 'tab_button',
          tab_component: tracking?.component || 'Base tab Component',
          ga4name: `${tracking?.name || 'tab'} - ${tracking?.guid || ''}`,
        })
      }}
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          onClick?.()
        }
      }}
      className={buttonClasses}
      role="tab"
      aria-selected={isSelected}
      id={`tab-${slugify(title?.value ?? '')}`}
      aria-controls={`tabpanel-${slugify(title?.value ?? '')}`}
    >
      {isExperience ? (
        <Text className={titleClasses} field={title} />
      ) : (
        <span className={titleClasses} style={ellipsisStyles}>
          {truncate(title?.value, 26)}
        </span>
      )}

      {tag &&
        (isExperience ? (
          <Text className={tagClasses} field={tag} />
        ) : (
          <span className={tagClasses} style={ellipsisStyles}>
            {truncate(tag.value, 39)}
          </span>
        ))}
      <Icon name="arrow-east" type="stroke" className={iconClasses} />
      <div className={fillClasses} style={{ transformOrigin: mousePosition.left }}></div>
    </button>
  )
}

export default Tab
