import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import { ContentIcons } from 'components/front-end/Icons/icon-data'
import { Service } from 'src/utils/useService'

import { QuickLinkProps } from '../QuickLinks/QuickLink'

type Category = {
  id: string
  faqCategoryTitle: string
  faqCategorySubtitle: string
}

type Subcategory = {
  id: string
  faqSubcategoryTitle: string
  faqSubcategoryDescription: string
  faqCount: number
}

type Tag = {
  id: string
  tag: string
}

type FAQItem = {
  id: string
  categories: string[]
  subcategories: string[]
  heading: string
  answer: string
  question: string
  tags: string[]
  icon: ContentIcons
  fields: FAQItemFields
}

export type FAQItemFields = {
  FaqIcon: Field<string>
  FaqItemAnswer: Field<string>
  FaqItemQuestion: Field<string>
  FaqItemHeading: {
    displayName: string
  }
} & QuickLinkFields

export type QuickLinkFields = {
  QuickLinkHeader: Field<string>
  QuickLinkDescription: Field<string>
  QuickLinkURL: LinkField
  QuickLinkServiceTagIsVisible: Field<boolean>
  QuickLinkService: Field<Service>
}

export type FAQData = {
  faqs: FAQItem[]
  categories: Category[]
  subcategories: Subcategory[]
  tags: Tag[]
  faqCount: number
  query: string
}

export type FAQCategory = Category & {
  subcategories: string[]
}
export type FAQSubcategory = Subcategory & {
  categories: string[]
  faqs: string[]
}
export type FAQTag = Tag
export type FAQ = Omit<FAQItem, 'fields'> & {
  text: string
  quickLink?: QuickLinkProps
}

export type GroupedFAQs = {
  heading: string
  faqs: FAQ[]
}[]
