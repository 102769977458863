import React, { useState } from 'react'
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Placeholder,
  PlaceholdersData,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'

import { track } from 'src/utils/tracking'
import { useGreaterThan } from 'src/utils/useBreakpoints'

export type TabType = {
  title: {
    field: Field<string>
  }
  tag: {
    field: Field<string>
  }
}

interface Fields {
  data: {
    datasource: {
      children: {
        results: TabType[]
      }
      field: {
        type: Field<string>
      }
    }
  }
}

interface TabListProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
  placeholders?: PlaceholdersData
}

const Default = (props: TabListProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const [tab, setTab] = useState<number>(0)

  const isEdit = sitecoreContext.pageState === 'edit'

  const containerStyles = `${props.params?.GridParameters ?? ''} ${props.params?.styles ?? ''}`
  const gridcols = Number(props.params?.gridcols ?? '12')

  return (
    <>
      <div className={`relative ${containerStyles}`}>
        <section data-cols={gridcols} className="pb-10 pt-10">
          <nav className="tab-nav flex flex-wrap gap-2 xm:flex-nowrap" aria-label="Story Banner Tab">
            {props?.fields?.data?.datasource?.children?.results?.map((result, i) => (
              <button
                key={i}
                onClick={() => {
                  setTab(i)
                  track({
                    event: 'tab_button',
                    ga4category: 'tab_button',
                    tab_component: 'ADFStoryBannerTab',
                    ga4name: `${result.title.field.value} - ${props?.rendering?.uid as string}`,
                  })
                }}
                onFocus={() => {
                  if (isEdit) {
                    setTab(i)
                  }
                }}
                className={`px-4 py-3 ${
                  tab === i
                    ? 'bg-black text-white [.dark_&]:bg-white [.dark_&]:text-black'
                    : 'cursor-pointer bg-grey-light text-black [.dark_&]:bg-grey-dark [.dark_&]:text-white'
                }`}
              >
                <Text field={result?.title?.field} />
              </button>
            ))}
          </nav>
        </section>

        <div className="tab-content relative" data-cols={useGreaterThan('lg') || isEdit ? gridcols : undefined}>
          {[...Array(9)].map((_, i) => (
            <div
              key={i}
              className={tab !== i ? 'hidden' : isEdit ? '[&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden' : ''}
            >
              <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
              {isEdit && (
                <div id="grid">
                  {[...Array(gridcols)].map((_, i) => (
                    <div key={`grid_${i}`}>{i + 1}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Default
