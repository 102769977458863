import React, { useRef, useState } from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: string
  type?: string
    onComplete?: () => void
}

const SvgIcon: React.FC<IconProps> = ({
    name,
    type = 'fill',
    onComplete,
    ...rest
}): JSX.Element | null => {

    const [isLoaded, setIsLoaded] = useState(false);

    const useDynamicSVGImport = () => {
        const iconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>()
        import(`../../../../public/icons/svgs/${`${name}-${type}`}.svg?icon`)
            .then(o => {
                iconRef.current = o.ReactComponent;
                setIsLoaded(true);
                onComplete?.();
                //console.log('svg is loaded: ', o.ReactComponent)
            })
            .catch(console.log);
    
        return { SvgIcon: iconRef.current }
    };

    rest.className = `svg-icon ${name} ${isLoaded} ${rest.className ?? ''}`

    const { SvgIcon } = useDynamicSVGImport();
    if (SvgIcon) {
        return <SvgIcon {...rest}></SvgIcon>;
    }
    else
        return null;
};

export default SvgIcon
