import { ReactNode } from 'react'

type FieldsetProps = {
  legend?: string
  children: ReactNode
}

export default function Fieldset({ legend, children }: FieldsetProps) {
  return (
    <fieldset className="mt-6 space-y-4 first-of-type:mt-0">
      {legend ? <legend className="h6">{legend}</legend> : null}
      {children}
    </fieldset>
  )
}
