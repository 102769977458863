import { EventRegoField } from 'components/ADFEventHome'
import { Input, Radio, Select } from 'components/front-end/Forms'
import { emailRegex, mobileRegex, postcodeRegex } from '../Forms/constants'

export type AttendeeProps = {
  id: string
  heading?: string
  path?: string
  guest?: boolean
  data: EventRegoField
  hybridEvent: boolean | undefined
  deliveryAllocated: 'inPerson' | 'virtual' | undefined
  deliveryType: 'In Person' | 'Online' | undefined
}

export default function Attendee(props: AttendeeProps) {
  const fieldName = (name: string) => [props.path, name].filter((arg) => arg).join('.')
  const required = props.data.RequiredMessage.value
  return (
    <fieldset className="mt-6 space-y-4 first-of-type:mt-0">
      <legend className="h6 normal-case">{props.heading}</legend>

      <Input
        label={props.data.FirstNameLabel.value}
        name={fieldName('FirstName')}
        placeholder={props.data.FirstNameHelpText.value}
        rules={{ required }}
      />
      <Input
        label={props.data.SurnameLabel.value}
        name={fieldName('Surname')}
        placeholder={props.data.SurnameHelpText.value}
        rules={{ required }}
      />
      <Input
        label={props.data.EmailLabel.value}
        name={fieldName('Email')}
        placeholder={props.data.EmailHelpText.value}
        type="email"
        rules={{
          required,
          pattern: {
            value: emailRegex,
            message: props.data.EmailMessage.value,
          },
        }}
      />
      <Input
        label={props.data.PostCodeLabel.value}
        name={fieldName('Postcode')}
        placeholder={props.data.PostCodeHelpText.value}
        rules={{
          required,
          pattern: {
            value: postcodeRegex,
            message: props.data.PostCodeMessage.value,
          },
        }}
      />
      <Input
        label={props.data.MobileLabel.value}
        name={fieldName('Mobile')}
        placeholder={props.data.MobileHelpText.value}
        rules={{
          pattern: {
            value: mobileRegex,
            message: props.data.MobileMessage.value,
          },
        }}
      />
      {!props.guest ? (
        <Input
          label={props.data.CandidateNumberLabel.value}
          name={fieldName('CandidateNumber')}
          placeholder={props.data.CandidateNumberHelpText.value}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: props.data.CandidateNumberMessage.value,
            },
          }}
        />
      ) : null}
      {props.hybridEvent && !props.deliveryAllocated ? (
        <Radio
          label={props.data.AttendingLabel.value}
          name={fieldName('Attend')}
          options={props.data.AttendingOptions.value
            .split(',')
            .map((str) => str.trim())
            .map((label: string) => {
              return { label, value: label, id: label, key: label }
            })}
          rules={{ required: props.data.AttendingMessage?.value || undefined }}
        />
      ) : (
        <>
          {/* AttendeeType not being sent on some forms */}
          {/* <p>Delivery Type = {props.deliveryType}</p>
          <Input type="hidden" name={fieldName('Attend')} defaultValue={props.deliveryType} /> */}
        </>
      )}
      <Select
        label={props.data.AttendeeTypesLabel.value}
        name={fieldName('AttendeeType')}
        placeholder="Select attendee type"
        options={props.data.AttendeeTypes.value.split(',').map((option: string) => {
          const [label, value] = option.split('::').map((str) => str.trim())
          return { label, value, id: value, key: value }
        })}
        rules={{ required: props.data.AttendeeTypesMessage.value }}
      />
    </fieldset>
  )
}
