import AudioPlayer from 'components/front-end/Audio/AudioPlayer'
import VideoBanner from 'components/front-end/Video/VideoBanner'
import type { StorySlideProps } from './StorySlide'

const MediaVideoPanel = ({ service, content, id }: StorySlideProps) => {
  if (!content?.YoutubeID?.value) {
    return null
  }

  // YoutubeLink
  // ('https://www.youtube.com/watch?v=uRQxMNQiOXo&t=4s')

  const videoPanelClasses = `
    video-panel dark relative
    flex flex-wrap xm:flex-nowrap xm:items-stretch
  `
  const videoPaddingClasses = `
    py-10 md:px-8 w-screen xm:w-auto h-auto
    xm:py-14 xm:px-12 xm:h-full
    lg:py-[92px] lg:px-16
    xl:py-[108px] xl:px-18
    xxl:py-[175px] xxl:px-[120px]
  `
  const svgClasses = `
    svg-box
    hidden xm:block
    h-auto lg:h-full
    w-full lg:w-auto
  `
  const audioBlockClasses = `
    [&_.audio-player]:w-[calc(100%_-_32px)] sm:[&_.audio-player]:w-[initial]
    w-full xm:w-22 h-10 xm:h-[initial] relative
    [&_.audio-player]:absolute [&_.audio-player]:bottom-[30%] [&_.audio-player]:left-12
    xm:[&_.audio-player]:bottom-[50%] xm:[&_.audio-player]:left-auto xm:[&_.audio-player]:right-0
    lg:[&_.audio-player]:bottom-[30%]
  `
  return (
    <div className={`${videoPanelClasses} ${videoPaddingClasses}`}>
      <div className="relative aspect-video h-full w-full xm:w-auto">
        <svg
          className={svgClasses}
          width="1600"
          height="900"
          viewBox="0 0 1600 900"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1600" height="900" fill="green" />
        </svg>
        <div className="inset-0 flex h-auto w-full xm:absolute">
          <VideoBanner
            id={content.YoutubeID?.value}
            image={content.VideoImage?.value?.src}
            title={content.VideoTitle?.value}
            type="story"
            service={service}
          />
        </div>
      </div>
      {content.AudioImage?.value.src && content.File?.value?.href && (
        <div className={audioBlockClasses}>
          <AudioPlayer
            id={id}
            name={content.Name?.value}
            role={content.JobTitle?.value}
            image={content.AudioImage.value.src}
            alt={(content.AudioImage.value.alt ?? '') as string}
            url={content.File?.value?.href}
            audioTranscript={content.AudioTranscript?.value}
            story
            service={service}
          />
        </div>
      )}
    </div>
  )
}

export default MediaVideoPanel
