import { useEffect, useRef, useState } from 'react'

export function useAudioPlayerProgress(player: HTMLAudioElement | null, frequency = 1) {
  const [currentTime, setCurrentTime] = useState<number>(0)
  const timeRef = useRef<NodeJS.Timeout | null>(null)
  const [isPlaying, setIsPlaying] = useState(Boolean(player && !player.paused))

  useEffect(() => {
    if (!player) return

    const handleTimeUpdate = () => {
      if (!player.paused && !timeRef.current) {
        const updateTime = () => {
          setCurrentTime((time) => {
            if (
              frequency > 1 ||
              (player.currentTime < 1 && player.duration > 0) ||
              Math.floor(player.currentTime) !== Math.floor(time || 0)
            ) {
              return player.currentTime
            }
            return time
          })
        }

        updateTime()
        timeRef.current = setInterval(updateTime, 1000 / frequency)
      } else if (player.paused && timeRef.current) {
        clearInterval(timeRef.current)
        timeRef.current = null
      }
      setIsPlaying(!player.paused)
    }

    const handlePlayerPause = () => {
      if (!player.paused && timeRef.current) {
        clearInterval(timeRef.current)
        timeRef.current = null
      }
      setIsPlaying(false)
    }

    const handlePlayerEnd = (): void => {
      handlePlayerPause()
      player.currentTime = 0
    }

    player.addEventListener('timeupdate', handleTimeUpdate)
    player.addEventListener('ended', handlePlayerEnd)

    return () => {
      player.removeEventListener('timeupdate', handleTimeUpdate)
      player.removeEventListener('ended', handlePlayerEnd)
    }
  }, [currentTime, player, frequency])

  return [currentTime || player?.duration || 0, isPlaying ? 'playing' : player?.ended ? 'ended' : 'paused'] as const
}
