import React from 'react'
import {
  Field,
  ImageField,
  Image as JssImage,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs'

import { ComponentProps } from 'lib/component-props'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import ImageLoader from './front-end/Images/ImageLoader'

interface Fields {
  Layout: Field<string> // Feature Right, Feature Left
  FeatureImageType: Field<string>
  FeatureImage: ImageField
  SecondaryImage: ImageField
}

export type TextImageProps = ComponentProps & {
  fields: Fields
}

export const ImageBlock = (props: TextImageProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const id = props.params?.RenderingIdentifier ?? props.params?.DynamicPlaceholderId
  const featureStyles = `col-span-1 md:col-span-2 order-first h-full ${
    props.fields.Layout.value === 'Feature Left' ? '' : ' md:order-none'
  }`
  const portraitStyles = `col-span-1 mt-auto md:-mb-20 xl:-mb-32  ${
    props.fields.Layout.value === 'Feature Left' ? '' : 'md:-ml-[var(--outermargin)]'
  }`
  return (
    <>
      <div
        id={id ? id : undefined}
        data-cols="12"
        className="mb-20 grid grid-cols-1 gap-x-20  gap-y-6 md:grid-cols-3 lg:gap-x-40 xl:mb-32"
      >
        <div className={portraitStyles}>
          {sitecoreContext.pageEditing ? (
            <JssImage field={props.fields.SecondaryImage} className="w-full" />
          ) : props?.fields?.SecondaryImage?.value?.src ? (
            <ImageLoader
              src={props.fields.SecondaryImage?.value?.src as string}
              alt={props.fields.SecondaryImage?.value?.alt as string}
              width={400}
              height={464}
              sizes="(max-width: 767px) 100vw, 40vw"
            />
          ) : null}
        </div>
        <div className={featureStyles}>
          {sitecoreContext.pageEditing ? (
            <JssImage field={props.fields.FeatureImage} className="w-full" />
          ) : props?.fields?.FeatureImage?.value?.src ? (
            <ImageLoader
              src={props.fields.FeatureImage?.value?.src as string}
              alt={props.fields.FeatureImage?.value?.alt as string}
              width={1200}
              height={800}
              sizes="(max-width: 767px) 100vw, 60vw"
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default withDatasourceCheck()<TextImageProps>(ImageBlock)
