import React from 'react'
import { Field, ImageField, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'

import { ComponentProps } from 'lib/component-props'
import { useService } from 'src/utils/useService'
import Button from './front-end/Buttons/Button'
import FormField from './front-end/FormFields/FormField'
import ImageLoader from './front-end/Images/ImageLoader'
import TitleText from './front-end/TextBlocks/TitleText'

interface Fields {
  CareerMatcherFormCopy: Field<string>
  CareerMatcherAppUrl: Field<string>
  CareerMatcherQueryParamKey: Field<string>
  SkipNameInputFieldText: Field<string>
  NameInputFieldLabel: Field<string>
  NameInputFieldPlaceholder: Field<string>
  NameSubmitButtonText: Field<string>
  Heading: Field<string>
  BackgroundImage: ImageField
  Description: Field<string>
}

type CareerMatchHeroProps = ComponentProps & {
  fields: Fields
}

const ADFCareerMatchHero = (props: CareerMatchHeroProps) => {
  const service = useService()

  const fields = props.fields
  const [name, setName] = React.useState('')

  return (
    <div className="dark relative flex w-full bg-black">
      <div className="xm:-12 absolute inset-0 -top-16 brightness-50 md:-top-12 lg:-top-22 xl:top-[-108px] [&_.image-loader]:min-h-screen [&_.image-loader]:!pb-0">
        {fields.BackgroundImage.value?.src && (
          <ImageLoader
            src={fields.BackgroundImage.value?.src}
            alt={fields.BackgroundImage.value?.alt as string}
            width={1600}
            height={900}
            priority
            fixed
            sizes="100vw"
          />
        )}
      </div>
      <div className="relative grid h-[calc(100vh-64px)] min-h-[500px] w-full grid-cols-1 content-center items-center px-[--outermargin] md:grid-cols-5 xm:h-[calc(100vh-70px)] xm:grid-cols-3 lg:h-[calc(100vh-108px)]">
        <div className="py-8 md:col-span-2 xm:col-span-1 [&_h1]:max-w-sm">
          <TitleText
            id={props.rendering.uid as string}
            text={fields.Description.value}
            title={fields.Heading.value}
            HeadingLevel="h1"
          />
        </div>

        <div className="col-span-2 flex flex-col gap-y-6 py-4 text-white md:col-start-4 xm:col-span-1 xm:col-start-3">
          <div className="text-lbody">
            <Text field={fields.CareerMatcherFormCopy} />
          </div>

          <form
            className="flex justify-stretch [&_.border]:border-none [&_div]:my-0"
            method="GET"
            action={fields.CareerMatcherAppUrl.value}
            target="_blank"
          >
            <div className="w-full flex-1">
              <FormField
                type="text"
                name={fields.CareerMatcherQueryParamKey.value}
                label={fields.NameInputFieldLabel.value}
                placeholder={fields.NameInputFieldPlaceholder.value}
                value={name}
                onChange={(value: string) => setName(value)}
              />
            </div>
            <div className="light flex items-center bg-white pr-4">
              <Button
                data-trackingid={props.rendering.uid as string}
                type="primary"
                isSmall
                icon="chevron-right-double"
                link={{
                  value: {
                    text: fields.NameSubmitButtonText.value,
                    href: `${fields.CareerMatcherAppUrl.value}?${fields.CareerMatcherQueryParamKey.value}=${name}`,
                    target: '_blank',
                  },
                }}
                service={service}
              />
            </div>
          </form>
          <div>
            <Button
              data-trackingid={props.rendering.uid as string}
              type="tertiary"
              link={{
                value: {
                  text: fields.SkipNameInputFieldText.value,
                  href: `${fields.CareerMatcherAppUrl.value}?${fields.CareerMatcherQueryParamKey.value}=`,
                  target: '_blank',
                },
              }}
              icon="chevron-right-double"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withDatasourceCheck()<CareerMatchHeroProps>(ADFCareerMatchHero)
