import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper-bundle.min.css'

import usePageContext from 'components/front-end/_layoutContext'
import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'

type ProfileTabsProps = {
  children: JSX.Element[] | JSX.Element | undefined
}

const ProfileTab = ({ children }: ProfileTabsProps): JSX.Element => {
  const pageState = usePageContext().pageContext
  const childrenArray = React.Children.toArray(children)

  const showLoop = () => window.innerHeight < 600

  const widthAndHeight = `max-w-content !w-auto md:max-h-content md:!h-auto h-[200px]`
  const tabClass = `
        z-40 mt-0 xm:pt-4
        w-full md:w-[108px]
        xm:h-[calc(100vh_-_64px)] lg:h-[calc(100vh_-_86px)] xl:h-[calc(100vh_-_98px)]
        ${pageState.header.isStickyHeader ? 'md:!h-[calc(100vh_-_64px)]' : '[&_.swiper-slide_>_a_>_div]:md:-mr-[26px]'}
        fixed bottom-0 top-[auto]
        md:absolute md:top-0 md:right-0 md:bottom-[auto]
        bg-black-off md:[.dark_&]:bg-transparent md:[.light_&]:bg-transparent md:[.grey_&]:bg-transparent
        transition-height duration-500
        md:bg-gradient-to-l md:from-black-off/60 md:to-black-off/0
        after:content-[''] after:absolute after:top-full after:w-full after:h-[100px] after:bg-gradient-to-l after:from-black-off/60 after:to-black-off/0
    `
  const tabContent = 'flex justify-center items-center h-full'
  const swiperClass = `${useLessThan('md') ? 'transparent-sides-fade' : 'transparent-fade'} w-full h-full`

  return (
    <>
      <div className={`${tabClass}`}>
        <div className={tabContent}>
          <Swiper
            className={swiperClass}
            slidesPerView={useLessThan('md') ? 5 : 'auto'}
            spaceBetween={1}
            direction={useLessThan('md') ? 'horizontal' : 'vertical'}
            loop={showLoop()} // Enable infinite scrolling
            // loopedSlides={18}
            // maxBackfaceHiddenSlides={18}
            watchSlidesProgress={true}
            // watchOverflow={true}
          >
            {childrenArray.map((child, index) => (
              <SwiperSlide key={`original-${index}`} className={widthAndHeight}>
                {child}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default ProfileTab
