import { memo, useEffect, useRef, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { createPortal } from 'react-dom'

import Button from 'components/front-end/Buttons/Button'
import { useDebounce } from 'src/utils/debounce'
import useScrollLock from 'src/utils/scrollLock'
import { StoryCarouselItem } from './StoryLaunch'
import StorySlide from './StorySlide'
import StorySwiper from './StorySwiper'

interface StoriesTakeoverProps {
  id: string
  name: string
  launch: boolean
  onClick?: () => void
  dataList: StoryCarouselItem[]
  service?: string
}

const StoriesTakeover = ({ id, name, launch, onClick, dataList, service }: StoriesTakeoverProps) => {
  const debouncedFunction = useDebounce(function handleResize() {
    closeTakeover()
  }, 100)

  useEffect(() => {
    window.addEventListener('resize', debouncedFunction)
    return () => {
      window.removeEventListener('resize', debouncedFunction)
    }
  })

  const [takeoverSize, setTakeoverSize] = useState<Partial<DOMRect>>({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 218,
  })

  const takeoverRef = useRef<HTMLDivElement | null>(null)
  const storyModalRef = useRef<HTMLDivElement | null>(null)

  const { lockScroll, unlockScroll } = useScrollLock()

  useEffect(() => {
    if (launch) {
      const takeoverRefBox = takeoverRef.current?.getBoundingClientRect()

      if (!takeoverRefBox) {
        return
      }

      lockScroll()

      const { top, left, width, height } = takeoverRefBox
      setTakeoverSize((size) => ({ ...size, top, left, width, height }))

      setTimeout(() => {
        storyModalRef.current?.classList?.add('!opacity-100')
      }, 100)

      setTimeout(() => {
        storyModalRef.current?.classList?.add(
          '!top-0',
          '!left-0',
          '!w-full',
          '!h-full',
          '[&_.takeover-loader]:opacity-0',
          '[&_.takeover-loader]:delay-500'
        )
      }, 600)

      setTimeout(() => {
        storyModalRef?.current?.classList?.remove('[&_.takeover-loader]:delay-500')
      }, 1100)
    }
  }, [launch, lockScroll])

  function closeTakeover() {
    if (!storyModalRef.current) {
      return
    }

    storyModalRef.current.classList?.remove('[&_.takeover-loader]:opacity-0')

    setTimeout(() => {
      storyModalRef?.current?.classList?.remove('!top-0', '!left-0', '!w-full', '!h-full', '!opacity-100')
    }, 200)
    setTimeout(() => {
      onClick?.()
      unlockScroll()
    }, 610)
  }

  const takeoverRefClasses = `
    absolute top-0 left-0 w-full h-full dark
    ${launch ? '' : 'pointer-events-none'}
  `
  const takeoverClasses = `
    story-modal inline-block dark
    transition-all duration-500 delay-200
    overflow-hidden z-50
    bg-black-off opacity-0
  `
  const closeClasses = `
    absolute top-10 right-10 z-40 dark
    transition duration-300
    bg-black-off
  `

  const PortalContent = () => {
    const { top, left, width, height } = takeoverSize

    const slides = dataList
      .filter((content) => content?.Type?.value)
      .map((content, i) => <StorySlide key={i} id={id} name={content.Type.value} content={content} service={service} />)
      .filter((slide) => slide)

    return createPortal(
      <FocusTrap>
        <section
          ref={storyModalRef}
          className={takeoverClasses}
          style={{ position: 'fixed', top, left, width, height }}
        >
          <div className="takeover-loader pointer-events-none absolute left-0 top-0 z-20 h-full w-full bg-black-off transition duration-300"></div>
          <div className={closeClasses}>
            <Button
              data-trackingid={id}
              link={{ value: { text: 'close' } }}
              icon="close"
              type="action"
              button
              onClick={() => closeTakeover()}
              isSmall
              service={service}
            />
          </div>
          <StorySwiper name={name}>
            {slides.map((slide, i) => (
              <span key={i} className="flex h-auto lg:h-screen">
                {slide}
              </span>
            ))}
          </StorySwiper>
        </section>
      </FocusTrap>,
      document.body
    )
  }

  return (
    <div ref={takeoverRef} className={takeoverRefClasses}>
      {launch && <PortalContent />}
    </div>
  )
}

const MemoisedStoriesTakeover = memo(StoriesTakeover)

export default MemoisedStoriesTakeover
