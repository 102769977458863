import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'
import { StorySlideProps } from './StorySlide'

const CopyPanel = ({ content }: StorySlideProps) => {
  if (!content?.CopyText?.value) {
    return null
  }

  const copyPanelClasses = `
    copy-panel bg-black-off
    flex flex-col justify-center
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[560px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[690px]
    xxl:py-[175px] xxl:px-[120px] max-w-[848px]
  `
  return (
    <div className={copyPanelClasses}>
      {useGreaterThan('xxl') && <p className="large text-white">{content.CopyText.value}</p>}
      {useLessThan('xxl') && <p className="medium text-white">{content.CopyText.value}</p>}
    </div>
  )
}

export default CopyPanel
