import { SearchResult } from 'components/ADFSearchResults'
import JobCard from 'components/front-end/JobCard/JobCard'

type RelatedJobsProps = {
  className?: string
  header: string | undefined
  jobs: SearchResult[] | undefined
}

export default function RelatedJobs({ className, header, jobs = [] }: RelatedJobsProps) {
  if (!jobs.length) {
    return null
  }
  return (
    <section className={className}>
      <div className="relative" data-cols="12">
        <div className="row">
          {header ? (
            <div className="span-12 lg-span-4">
              <h4 className="mb-10">{header}</h4>
            </div>
          ) : null}
          <div className="span-12 lg-span-8 lg:first:[&_>_div]:pt-0">
            {jobs.map((job) => (
              <JobCard key={job.id} jobDetails={job} compact displayPriorityTag={false} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
