import React, { CSSProperties, useEffect, useReducer, useRef } from 'react'
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'
import { AnalyticsQuiz, track } from 'src/utils/tracking'

import Button from './front-end/Buttons/Button'
import TitleText from './front-end/TextBlocks/TitleText'
import WeightedQuestionnaire from './front-end/WeightedQuestionnaire/WeightedQuestionnaire'
import {
  parseQuestions,
  parseResults,
  WeightedQuestionnaireFields,
  WeightedQuestionnaireFormState,
  weightedQuestionnaireReducer,
} from './front-end/WeightedQuestionnaire/weightedQuestionnaireHelpers'
import WeightedQuestionnaireResult from './front-end/WeightedQuestionnaire/WeightedQuestionnaireResult'
import WeightedQuestionnaireResultsCarousel from './front-end/WeightedQuestionnaire/WeightedQuestionnaireResultsCarousel'

type WeightedQuestionnaireProps = ComponentProps & {
  fields: WeightedQuestionnaireFields
}

export const ADFWeightedQuestionnaire = ({ fields, rendering }: WeightedQuestionnaireProps): JSX.Element => {
  const quizRef = useRef<HTMLDivElement>(null)
  const [timeoutValue, setTimeoutValue] = React.useState<NodeJS.Timeout | null>(null)

  const { questionnaireTitle, questionnaireDescription, resultSection, ...labels } = fields.data.datasource
  const questions = parseQuestions(fields.data.datasource.questions.results)
  const results = parseResults(resultSection.results)
  const transitionDuration = 300

  const initialState: WeightedQuestionnaireFormState = {
    index: 0,
    answers: questions.map(({ id }) => ({ id, value: '' })),
  }
  const [formState, send] = useReducer(weightedQuestionnaireReducer, initialState)
  const scores = formState.scores

  const trackQuizEvent = (action: AnalyticsQuiz['quiz_button']) => {
    track({
      event: 'quiz',
      quiz_name: questionnaireTitle.jsonValue.value,
      quiz_question: questions[formState.index]?.questionText || '',
      quiz_answer: (formState.answers[formState.index]?.value.split('|').pop() as string) || '',
      quiz_button: action,
    })
  }

  const handleReset = () => {
    trackQuizEvent('Restart')
    navigateWithTransition(() => {
      send({ type: 'RESET', payload: initialState })
      quizRef.current?.querySelector('form')?.reset()
    })
  }

  const handlePrevious = () => {
    trackQuizEvent('Back')
    navigateWithTransition(() => send({ type: 'PREVIOUS' }))
  }

  const handleNext = () => {
    trackQuizEvent('Next')
    navigateWithTransition(() => send({ type: 'NEXT' }))
  }

  const navigateWithTransition = (callback: () => void) => {
    if (!timeoutValue) {
      setTimeoutValue(
        setTimeout(() => {
          callback()

          quizRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          setTimeoutValue(null)
        }, transitionDuration)
      )
    }
  }

  const handleAnswer = (payload: { id: string; value: string }) => {
    send({ type: 'ANSWER', payload })
  }

  useEffect(() => {
    if (formState.index >= 0 && formState.index < formState.answers.length) {
      const [, id] = formState.answers[formState.index].value.split('|')
      if (id) {
        document.getElementById(id)?.focus()
      }
    }
  }, [formState.answers, formState.index])

  useEffect(
    () => () => {
      if (timeoutValue) {
        clearTimeout(timeoutValue)
      }
    },
    [timeoutValue]
  )

  return (
    <div className="grid grid-cols-1 gap-y-10 lg:mx-[--outermargin] lg:grid-cols-3 lg:gap-x-16">
      <div className="col-span-1 mx-[--outermargin] lg:mx-0 [&_.mbody]:md:text-lbody [&_h4]:mb-4 [&_h4]:lg:mb-6 [&_h4]:xl:mb-8">
        <TitleText
          id={rendering.uid as string}
          HeadingStyle="h4"
          title={questionnaireTitle.jsonValue.value}
          text={questionnaireDescription.jsonValue.value}
          LedeCopySize="mbody"
        />
      </div>
      <div
        ref={quizRef}
        className={`mx-[--outermargin] scroll-m-36 transition-opacity duration-[--transitionDuration] lg:col-span-2 lg:mx-0 ${
          timeoutValue && 'opacity-0'
        }`}
        style={{ '--transitionDuration': `${transitionDuration}ms` } as CSSProperties}
      >
        {!scores ? (
          <WeightedQuestionnaire
            id={rendering.uid as string}
            questions={questions}
            formState={formState}
            labels={labels}
            onNext={handleNext}
            onPrevious={handlePrevious}
            onReset={handleReset}
            onAnswer={handleAnswer}
          />
        ) : (
          <div className="lg:col-span-2">
            <div className="xm:hidden">
              <WeightedQuestionnaireResultsCarousel results={results} scores={scores} />
            </div>
            <div className="mx-[--outermargin] hidden flex-col gap-y-4 xm:flex lg:mx-0">
              <p
                slot="container-start"
                className="stag mx-[--outermargin] mb-4 uppercase text-black dark:text-white lg:mx-0 lg:mb-0"
              >
                Your Results
              </p>
              <div className="flex flex-col gap-y-6">
                {results.map((result) => {
                  const topScore = Math.max(...Object.values(scores ?? {}))
                  const score = scores?.[result.name] ?? 0
                  return (
                    <WeightedQuestionnaireResult
                      key={result.id}
                      bestMatch={score === topScore}
                      score={score}
                      onClick={() => {
                        track({
                          event: 'quiz',
                          quiz_name: questionnaireTitle.jsonValue.value,
                          quiz_question: result.title,
                          quiz_answer: `${score.toFixed(0)}%`,
                          quiz_button: 'Learn more',
                        })
                      }}
                      {...result}
                    />
                  )
                })}
              </div>
            </div>

            <Button
              data-trackingid={rendering.uid as string}
              type="action"
              button
              link={{ value: { text: labels.resetButtonText?.value || 'RESTART' } }}
              onClick={handleReset}
              icon="refresh"
              disabled={formState.answers.every(({ value }) => value === '')}
              className="ml-[--outermargin] mt-8 xm:mt-10 lg:ml-0"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default withDatasourceCheck()<WeightedQuestionnaireProps>(ADFWeightedQuestionnaire)
