import { useController } from 'react-hook-form'

import { InputProps } from './types'
import { ValidationWrapper } from './Validation'

export default function TextArea(props: InputProps<'textarea'>) {
  const { field } = useController(props)

  return (
    <ValidationWrapper name={props.name}>
      {'label' in props && (
        <label className="relative block px-4 pt-4">
          <span className="stag text-sm mb-2 block font-bold text-black">
            {props.label}
            {props.rules?.required && '*'}
          </span>
          <textarea {...field} placeholder={props.placeholder} className="block h-24 w-full ring-0 ring-offset-0" />
        </label>
      )}
    </ValidationWrapper>
  )
}
