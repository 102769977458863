import { useRef, useState } from 'react'
import { Field, ImageFieldValue, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin } from 'tailwind-merge'

import { SearchResult } from 'components/ADFSearchResults'
import Button, { ButtonProps } from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'
import StoriesTakeover from 'components/front-end/StoryPanel/StoryTakeover'
import VideoModal, { CustomModalRef } from 'components/front-end/Video/VideoModal'
import { Service, useService } from 'src/utils/useService'
import ImageLoader from '../Images/ImageLoader'
import { getTextColor } from './StoryBanner'
import { StorySlideProps } from './StorySlide'

export type StoryCarouselItem = {
  StatementText: Field<string>
  StatementServiceTag: Field<boolean>
  StatDescription1: Field<string>
  Stat1: Field<string>
  Stat3: Field<string>
  StatDescription2: Field<string>
  StatDescription3: Field<string>
  StatHeading2: Field<string>
  StatsHeading1: Field<string>
  StatsHeading3: Field<string>
  Stat2: Field<string>
  Heading: Field<string>
  Type: Field<StorySlideProps['name']>
  TitleDescription: Field<string>
  TitleButtonLink: LinkField
  Job: SearchResult[]
  CopyText: Field<string>
  ListItem6: Field<string>
  ListItem4: Field<string>
  ListItem5: Field<string>
  ListButtonLink: LinkField
  ListItem1: Field<string>
  ListItem2: Field<string>
  ListItem3: Field<string>
  ListItem10: Field<string>
  ListItem7: Field<string>
  ListItem8: Field<string>
  ListItem9: Field<string>
  AudioImage: {
    value: ImageFieldValue
  }
  File: LinkField
  JobTitle: Field<string>
  Name: Field<string>
  AudioTranscript: Field<string>
  DesktopImage: {
    value: ImageFieldValue
  }
  MobileImage: {
    value: ImageFieldValue
  }
  Layout: Field<string>
  VideoImage: {
    value: ImageFieldValue
  }
  VideoTitle: Field<string>
  YoutubeID: Field<string>
  Author: Field<string>
  QuoteText: Field<string>
  ServiceTag: {
    value: true
  }
  RankDescription1: Field<string>
  RankDescription2: Field<string>
  RankImage1: {
    value: ImageFieldValue
  }
  RankImage2: {
    value: ImageFieldValue
  }
  ImageDispaly: Field<string>
  RankTitle1: Field<string>
  RankTitle2: Field<string>
  CTAButton1: LinkField
  CTAButtonIcon1: Field<ButtonProps['icon']>
  CTAButtonTheme1: Field<ButtonProps['type']>
  CTAButton2: LinkField
  CTAButtonIcon2: Field<ButtonProps['icon']>
  CTAButtonTheme2: Field<ButtonProps['type']>
  Jobs: SearchResult[]
}

export type StoryBannerFields = {
  StoryCarousel: Field<string>
  CTAButtonText: Field<string>
  CTAThumbnailText: Field<string>
  BackgroundImage: {
    value: ImageFieldValue
  }
  BannerType: Field<'Story' | 'Video' | 'Image'>
  QuoteAuthor: Field<string>
  Service: Field<string>
  TextType: Field<'Quote' | 'Statement'>
  Title: Field<string>
  VideoTitle: Field<string>
  VideoYoutubeId: Field<string>
  VideoDuration: Field<string>
  VideoThumbnailImage: {
    value: ImageFieldValue
  }
  CarouselItems: StoryCarouselItem[]
}

export type StoriesLaunchProps = {
  id: string
  type: 'Story' | 'Video'
  service: Service
  data: StoryBannerFields
}

const StoriesLaunch = ({ id, type = 'Story', service, data }: StoriesLaunchProps) => {
  service = useService(service)

  const modalRef = useRef<CustomModalRef>()

  const [isOpen, setIsOpen] = useState(false)

  if (!data) {
    return null
  }

  const isVideo = type === 'Video'
  const isStory = type === 'Story'

  const launchButtonWrapper = twJoin(
    'dark bg-transparent',
    !isVideo && '[&_.button-adf]:border-black [&_.button-fill]:hover:bg-black [&_svg]:hover:text-white'
  )

  const videoThumbnailImage = data.VideoThumbnailImage?.value

  return (
    <div
      className={twJoin(
        'story-launch-button relative flex h-[250px] max-w-[400px] shrink-0 items-end justify-start lg:w-[323px] xl:w-[400px] [&_.background_img]:hover:scale-[110%]',
        isVideo ? 'dark border border-l-[5px]' : 'border-0',
        getTextColor(service)
      )}
    >
      {isVideo && videoThumbnailImage && videoThumbnailImage.src && (
        <div className="background absolute left-2.5 top-0 z-0 h-full w-[calc(100%_-_10px)] overflow-hidden transition duration-300">
          <ImageLoader
            src={videoThumbnailImage.src}
            alt={(videoThumbnailImage.alt as string) || ''}
            full
            width={384}
            sizes="400px"
          />
        </div>
      )}
      <div
        className={twJoin(
          'foreground absolute top-0 z-[1] flex h-full w-[calc(100%-10px)] shrink-0 items-end justify-start px-6 py-8 md:p-8',
          isVideo ? 'left-2.5 bg-black/60' : 'left-0'
        )}
      >
        <div>
          {isVideo ? (
            <p className="mb-2 flex items-center text-white">
              <Icon name="play" width={12} height={12} className="mr-1" />
              <span className="text-sm">{data.VideoDuration?.value}</span>
            </p>
          ) : null}
          <p className={twJoin('h6 mb-6', isVideo ? '!text-white' : '!text-black')}>
            {isVideo ? data.VideoTitle?.value : data.CTAThumbnailText?.value}
          </p>
          <div className={launchButtonWrapper}>
            <Button
              data-trackingid={id}
              data-testid={!isVideo ? 'launch-story-takeover' : undefined}
              icon={isVideo ? 'play' : 'arrow-east'}
              solid={isVideo}
              link={{
                value: {
                  text: data.CTAButtonText?.value,
                },
              }}
              button
              type="primary"
              service={service}
              onClick={() => {
                const storyModal = modalRef.current
                if (isStory) {
                  setIsOpen(true)
                } else if (isVideo && storyModal) {
                  storyModal.openModal()
                }
              }}
            />
          </div>
        </div>
      </div>
      {isStory && isOpen ? (
        <StoriesTakeover
          id={id}
          name={data.CTAThumbnailText?.value}
          launch={isOpen}
          onClick={() => setIsOpen(false)}
          dataList={data.CarouselItems}
          service={service}
        />
      ) : isVideo && data.VideoYoutubeId?.value ? (
        <VideoModal id={id} ref={modalRef} videoId={data.VideoYoutubeId.value} service={service} />
      ) : null}
    </div>
  )
}

export default StoriesLaunch
