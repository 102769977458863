import { Image as JssImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin } from 'tailwind-merge'

import ImageLoader from 'components/front-end/Images/ImageLoader'
import StoryLaunch, { StoryBannerFields } from 'components/front-end/StoryPanel/StoryLaunch'
import QuoteText from 'components/front-end/TextBlocks/QuoteText'
import StatementText from 'components/front-end/TextBlocks/StatementText'
import { Service, useService } from 'utils/useService'

type StoryBannerProps = {
  id: string
  fields: StoryBannerFields
}

export const getTextColor = (service: Service) => {
  return {
    'tri-service': 'bg-tri-service border-tri-service',
    army: 'bg-army border-army',
    'air-force': 'bg-air-force border-air-force',
    navy: 'bg-navy border-navy',
  }[service]
}

function StoryBanner({ id, fields }: StoryBannerProps) {
  const { sitecoreContext } = useSitecoreContext()

  const service = useService(fields.Service?.value)
  const type = fields.BannerType.value || 'Video'

  return (
    <div className="banner-wrapper dark">
      <div
        className={twJoin(
          'story-banner border-l-5 relative z-[1] flex w-full items-end justify-start',
          service && getTextColor(service)
        )}
      >
        {fields.BackgroundImage?.value?.src ? (
          <div className="background-banner absolute left-0 top-0 h-full w-full [&_.image-loader]:!pb-0">
            {sitecoreContext.pageEditing ? (
              <div className="z-[60] h-full w-full overflow-hidden">
                <JssImage className="h-full w-full object-cover" field={fields.BackgroundImage} />
              </div>
            ) : (
              <ImageLoader
                src={fields.BackgroundImage.value.src}
                alt={(fields.BackgroundImage.value.alt as string) ?? ''}
                full
                height={384}
                width={248}
                priority
                sizes="100vw"
              />
            )}
          </div>
        ) : null}
        <div className="foreground-banner z-[2] w-full items-end justify-between bg-gradient-to-t from-black/100 from-30% to-black/0 p-6 pt-[50%] md:p-12 md:pt-[50%] xm:flex xm:max-h-[75vh] xm:p-16 xm:pt-[50%] lg:from-black/60 lg:from-30% lg:via-black/60 lg:to-black/60 lg:p-18 lg:pt-[30%]">
          <div className="dark mr-18">
            {fields.TextType.value === 'Quote' && (
              <QuoteText
                text={fields.Title.value}
                author={fields.QuoteAuthor?.value ?? ''}
                service={service}
                showServiceTag={false}
              />
            )}
            {fields.TextType.value === 'Statement' && (
              <StatementText text={fields.Title.value} service={service} showServiceTag={false} />
            )}
          </div>

          <div className="hidden min-w-96 justify-end lg:flex">
            {type !== 'Image' ? <StoryLaunch id={id} service={service} type={type} data={fields} /> : null}
          </div>
        </div>
      </div>

      {type !== 'Image' ? (
        <div className="block bg-black p-6 !pt-0 pb-10 md:p-12 xm:p-16 lg:hidden">
          <StoryLaunch id={id} service={service} type={type} data={fields} />
        </div>
      ) : null}
    </div>
  )
}

export default StoryBanner
