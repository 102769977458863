import { useEffect, useRef } from 'react'

import type { QuoteCarouselItem } from 'components/ADFQuoteCarousel'
import AudioPlayer from '../Audio/AudioPlayer'

const QuoteSliderAudio = ({
  id,
  fields,
  active,
}: {
  id: string
  fields: Pick<QuoteCarouselItem['fields'], 'File' | 'Name' | 'JobTitle' | 'AudioImage' | 'AudioTranscript'>
  active?: boolean
}) => {
  const audioSource = fields.File?.value?.href
  const ref = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (!ref.current?.paused && !active) {
      ref.current?.pause()
    }
  }, [active])

  return audioSource ? (
    <div className="flex-1 xm:absolute xm:top-0 xm:-order-1 xm:-translate-x-[calc(100%-1.5rem)] xm:translate-y-1/2">
      <AudioPlayer
        name={fields.Name.value || ''}
        role={fields.JobTitle.value || ''}
        image={fields.AudioImage?.value?.src || ''}
        url={audioSource}
        audioTranscript={fields.AudioTranscript.value || ''}
        id={id}
        playerRef={ref}
      />
    </div>
  ) : null
}

export default QuoteSliderAudio
