import { Field } from '@sitecore-jss/sitecore-jss-nextjs'
import { UseControllerProps } from 'react-hook-form'

interface KeyValueDefinitionFields {
  KeyValueDefinitionKey: Field<string>
  KeyValueDefinitionLabel: Field<string>
  KeyValueDefinitionValue: Field<string>
}

export interface KeyValueDefinition {
  id: string
  url: string
  name: string
  displayName: string
  fields: KeyValueDefinitionFields
}

export type InputOption<TValue = string> = { id: string; key: string; value: TValue; label: string }

export type AllInputProps = UseControllerProps &
  (
    | {
        type: 'text' | 'email' | 'number' | 'password' | 'tel' | 'url' | 'search' | 'date' | 'time' | 'datetime'
        label: React.ReactNode
        placeholder?: string
        onBlur?: React.FocusEventHandler<HTMLInputElement>
      }
    | {
        type: 'textarea'
        label: React.ReactNode
        placeholder?: string
        onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
      }
    | {
        type: 'checkbox'
        label: React.ReactNode
        readOnly?: boolean
      }
    | {
        type: 'hidden' | 'captcha'
      }
    | {
        type: 'radio' | 'select'
        label: React.ReactNode
        placeholder?: string
        onBlur?: React.FocusEventHandler<HTMLInputElement>
        options: InputOption[]
      }
  )

type AllInputTypes = AllInputProps['type']

type ExtractInputProps<T extends { type: AllInputTypes }, U extends T['type']> = {
  [K in T as K['type']]: Extract<T, { type: K['type'] }>
}[U]

export type InputProps<T extends AllInputTypes = 'text'> = Omit<ExtractInputProps<AllInputProps, T>, 'type'> & {
  type?: AllInputTypes
  id?: string
  className?: string
}
