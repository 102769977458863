import React, { ReactNode } from 'react'
import { Link } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin, twMerge } from 'tailwind-merge'

import { useHoverOrigin } from 'utils/useHoverOrigin'
import useIntentionalHover from 'utils/useIntentionalHover'
import { NavigationItemWithChildren } from './navigationHelpers'
import { NavigationContext } from './NavigationProvider'

interface NavigationItemProps extends Omit<React.HTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  tag?: React.ElementType
  children?: ReactNode
  data: NavigationItemWithChildren
  transformTitle?: (item: NavigationItemWithChildren) => string
  onInteraction?: (item: NavigationItemWithChildren) => void
  onClick?: (item: NavigationItemWithChildren, evt: React.MouseEvent) => void
  icon?: React.ReactNode
  linkClassName?: string
  decorationClassName?: string
  // Focus trap
  onTrapDeactivate?: () => void
  isFirst: boolean
  isLast: boolean
}

export default function NavigationItem({
  tag: Tag = 'li',
  className,
  data,
  transformTitle = (item) => item.navigationTitle,
  onInteraction,
  onClick,
  icon,
  linkClassName,
  decorationClassName,
  onTrapDeactivate,
  isFirst,
  isLast,
  ...linkProps
}: NavigationItemProps): JSX.Element {
  const hasChildren = data.children.filter(({ hideInNavigation }) => !hideInNavigation).length > 0
  const sendToNavigation = NavigationContext.useActorRef().send

  const handleHover = () => {
    onInteraction?.(data)
  }
  const handleClick: React.MouseEventHandler = (e) => {
    onClick?.(data, e)
  }

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (hasChildren && (e.key === 'ArrowDown' || e.code === 'ArrowDown')) {
      e.preventDefault()
      handleHover()
    } else if (e.key === 'Escape' || e.code === 'Escape') {
      sendToNavigation({ type: 'POP_NAVIGATION' })
    } else if ((e.key === 'Tab' || e.code === 'Tab') && isLast) {
      onTrapDeactivate?.()
    }
  }

  const intentionalHoverRef = useIntentionalHover({
    onHover: handleHover,
    velocityThreshold: 0.2,
    durationThreshold: 20,
  })
  const setHoverOriginRef = useHoverOrigin({
    watchSelector: (el) => el.closest('li') ?? el,
    rectSelector: (el) => el.querySelector('a') ?? el,
  })

  return (
    <Tag className={twJoin('group/link', className)} ref={intentionalHoverRef}>
      <span className="relative flex items-stretch justify-start" ref={setHoverOriginRef}>
        <Link
          tabIndex={0}
          role="menuitem"
          field={{ href: data.url, text: transformTitle(data), title: data.pageTitle }}
          onClick={handleClick}
          className={twMerge(
            'relative flex cursor-pointer items-center text-inherit transition-colors duration-300',
            linkClassName
          )}
          onKeyDown={handleKeyDown}
          {...linkProps}
        />
        <AnimatedUnderline className={decorationClassName} />
      </span>

      {icon}
    </Tag>
  )
}

export function AnimatedUnderline({ className }: { className?: string }) {
  return (
    <span
      role="presentation"
      data-animated-underline
      className={twMerge(
        'absolute bottom-0 h-1 w-full origin-[--transform-origin-x] scale-x-0 bg-current transition group-hover/link:scale-x-100 [a:not([aria-current="false"])+&]:scale-x-100',
        className
      )}
    />
  )
}
