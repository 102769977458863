import { useFormState } from 'react-hook-form'

import Button, { ButtonProps } from '../Buttons/Button'

type SubmitProps = Pick<ButtonProps, 'icon' | 'isSmall'> & {
  id: string
  label: string
}

export default function Submit(props: SubmitProps) {
  const { isSubmitting } = useFormState()
  return (
    <div>
      <Button
        data-trackingid={props.id}
        button
        buttonType="submit"
        icon={props.icon || 'chevron-right-double'}
        isSmall={props.isSmall}
        link={{ value: { text: props.label } }}
        disabled={isSubmitting}
      />
    </div>
  )
}
