import { useState } from 'react'
import Image from 'next/image'

import { truncate } from 'utils/truncate'
import chevronDown from '../../../../public/chevron-down.svg'
import chevronUp from '../../../../public/chevron-up.svg'

type ExpandBarProps = {
  content: string
  maxCharLimit: number
}

const ExpandBar = ({ content, maxCharLimit }: ExpandBarProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false)
  const icon = isExpanded ? chevronUp : chevronDown
  const isBiggerThanMax = content && content.length > maxCharLimit

  return (
    <div className={(isExpanded ? 'expanded ' : ' ') + 'expand-bar expand-bar-container mbody w-[375px]'}>
      <div className="expand-text">{truncate(content, isExpanded ? Infinity : maxCharLimit)}</div>
      {isBiggerThanMax && (
        <div
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
          className="expand-bar-button button flex cursor-pointer bg-white"
        >
          {!isExpanded ? 'Expand to read more' : 'Minimise to read less'}
          <span className="expand-bar-icon pl-[8px]">
            <Image
              src={icon}
              width={15}
              height={15}
              alt={isExpanded ? 'Chevron pointing up' : 'Chevron pointing down'}
            />
          </span>
        </div>
      )}
    </div>
  )
}

export default ExpandBar
