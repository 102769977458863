import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { trackFilterInteraction } from 'src/utils/tracking'
import { ADFSearchResultsProps, SearchResultsRequest } from 'components/ADFSearchResults'
import Filter, { type FilterValue } from 'components/front-end/Drawers/drawer-components/Filter'
import Drawer from '../Drawers/Drawer'
import EntryMethodFilter from '../Drawers/drawer-components/EntryMethodFilter'

export interface SearchDrawerProps {
  props: ADFSearchResultsProps
  openDrawer: boolean
  isReserve: boolean
  isFulltime: boolean
  filters: Map<string, Map<string, string>>
  onApply: (isReserve: boolean, isFullTime: boolean) => void
  onReset: () => void
  onClose: () => void
}

const fullTimeValue = '{6F35FBB9-422B-4623-A66C-EC79C24BCBFE}'

const SearchDrawer = ({
  props,
  openDrawer,
  isReserve,
  isFulltime,
  filters,
  onApply,
  onReset,
  onClose,
}: SearchDrawerProps): JSX.Element => {
  const router = useRouter()
  const setNewPayload = (filters: Map<string, Map<string, string>>) => {

    const isFilterNotEmpty = (filter: [string, Map<string, string>]) => filter[1].size > 0;
    const matches = router?.asPath?.match(/query=([^&]*)/)
    const res = {
      filterValues: Array.from(filters.entries())
        .filter(filter => isFilterNotEmpty(filter)
          // && isValidEntryMethod(filter[0])
        )
        .map((x) => ({
          FacetId: x[0],
          FacetValues: Array.from(x[1].keys()),
        })),
      searchId: 'efc96bb1-53f2-42aa-b8eb-ca9ad7a1596a',
      page: 1,
      perPage: 10,
      query: matches?.[1],
    }
    return res
  }

  const typesProps = props?.fields?.data?.jobTypes
  const priorityOrderProps = props?.fields?.data?.priorityOrders
  // allows to have temporary version but it will be restored to parent value is drawer is closed
  const [isNewReserve, setIsNewReserve] = useState<boolean>(isReserve)
  useEffect(() => {
    setIsNewReserve(isReserve)
  }, [isReserve, openDrawer])

  const [isNewFulltime, setIsNewFulltime] = useState<boolean>(isFulltime)
  useEffect(() => {
    setIsNewFulltime(isFulltime)
  }, [isFulltime, openDrawer])

  //short cuts
  const dsProps = props?.fields?.data?.datasource
  //const orderingProps = dsProps?.orderingOptions
  const servicesProps = props?.fields?.data?.services
  const reserveEntryMethodsProps = props?.fields?.data?.reserveEntryMethods
  const generalEntryMethodsProps = props?.fields?.data?.entryMethods
  const allEntryMethodsProps = props?.fields?.data?.allEntryMethods
  const entryMethodsProps = isNewReserve ? reserveEntryMethodsProps : isNewFulltime ? generalEntryMethodsProps : allEntryMethodsProps
  const categoriesProps = props?.fields?.data?.categories

  const [, setPayload] = useState<SearchResultsRequest>(setNewPayload(filters))
  const [isCollapsed, setIsCollapsed] = useState(false)

  const trackCareerFilterData = (value: FilterValue) => {
    const filterAction = value?.checked === false ? 'select' : 'deselect'
    const filterComponent = 'job filter'
    const filterSelection = `${value.title}-${value?.name} - ${props?.rendering?.uid}` as const
    trackFilterInteraction(filterAction, filterComponent, filterSelection)
  }

  const tickFilter = (keyId: string, value: FilterValue) => {
    const map = filters.get(keyId)
    if (!map) return

    if (value.value == 'any' || value.value == 'all') {
      map?.clear()
    } else {
      const isFilter = map.has(value.value)
      if (isFilter) {
        map?.delete(value.value)
      } else {
        map?.set(value.value, value.queryName ?? '')
      }
    }
    setPayload(setNewPayload(filters))
    trackCareerFilterData(value)
  }

  const tickTypeFilter = (keyId: string, value: FilterValue) => {
    const map = filters.get(keyId)
    if (!map) return
    if (value.value == 'any' || value.value == 'all') {
      map?.clear()
      setIsNewReserve(false)
      setIsNewFulltime(false)
    } else {
      const isFilter = map.has(value.value)
      if (isFilter) {
        //map?.delete(value.value)
      } else {
        map?.clear()
        map?.set(value.value, value.queryName ?? '')

        //full-time
        if (value.value == fullTimeValue) {
          setIsNewReserve(false)
          setIsNewFulltime(true)
        } else {
          setIsNewReserve(true)
          setIsNewFulltime(false)
        }
      }

      // reset entry methods
      //let entryMethods = props?.filters.get(entryMethodsProps?.id)
      //entryMethods?.clear()
    }
    setPayload(setNewPayload(filters))
    trackCareerFilterData(value)
  }

  return (
    <Drawer
      title="Filters"
      id="search-drawer"
      show={openDrawer}
      closeDrawer={() => onClose()}
      primaryText={`RESET FILTERS`}
      primaryIcon={'refresh'}
      primaryClick={() => onReset()}
      secondaryText={`APPLY FILTERS`}
      secondaryClick={() => onApply(isNewReserve, isNewFulltime)}
    >
      <>
        {/* <Header text='Tell us what you think' /> */}
        {openDrawer && (
          <>
            <Filter
              title={dsProps?.orderingHeading?.value}
              isRadioInline={true}
              filterValues={[
                {
                  name: 'All Roles',
                  value: 'all',
                  checked: (filters.get(priorityOrderProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.orderingHeading?.value,
                },
                ...priorityOrderProps?.children?.results.map((x) => ({
                  name: x?.displayName?.value ?? '',
                  value: x?.id ?? '',
                  queryName: x?.name?.value ?? '',
                  checked: filters.get(priorityOrderProps?.id)?.has(x?.id),
                  title: dsProps?.orderingHeading?.value,
                }))]
              }
              onClick={(value) => {
                tickFilter(priorityOrderProps?.id, value)
              }}
            />
            <Filter
              title={dsProps?.typeHeading?.value}
              isRadioInline={true}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (filters.get(typesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.serviceHeading?.value,
                },
                ...typesProps?.children?.results?.map((x) => ({
                  name: x?.displayName?.value ?? '',
                  value: x?.id ?? '',
                  queryName: x?.name?.value,
                  checked: filters.get(typesProps?.id)?.has(x?.id),
                  title: dsProps?.typeHeading?.value,
                })),
              ]}

              onClick={(value) => tickTypeFilter(typesProps?.id, value)}
            />
            <Filter
              title={dsProps?.serviceHeading?.value}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (filters.get(servicesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.serviceHeading?.value,
                },
                ...servicesProps?.children?.results?.map((x) => ({
                  name: x?.displayName?.value ?? '',
                  value: x?.id ?? '',
                  queryName: x?.name?.value,
                  checked: filters.get(servicesProps?.id)?.has(x?.id),
                  title: dsProps?.serviceHeading?.value,
                })),
              ]}
              onClick={(value) => tickFilter(servicesProps?.id, value)}
            />
            <EntryMethodFilter
              isCollapsed={isCollapsed}
              title={dsProps?.entryMethodHeading?.value}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (() => {
                    const values = entryMethodsProps?.children?.results
                    const map = filters.get(entryMethodsProps?.id)
                    const checked = values.map((x) => map?.has(x.id)).reduce((x, y) => x || y, false) ?? false
                    return !checked
                  })(),
                  title: dsProps?.entryMethodHeading?.value,
                  groupName: 'General',
                }
              ]}
              generalTitle={dsProps?.generalEntryMethodHeading?.value}
              generalSubtitle={dsProps?.generalEntryMethodDescription?.value}
              generalValues={
                entryMethodsProps?.children?.results
                  ?.filter((x) => x?.groupingName?.targetItem?.name === 'General')
                  .map((x) => ({
                    name: x?.displayName?.value ?? '',
                    value: x?.id ?? '',
                    queryName: x?.name?.value,
                    checked: filters.get(entryMethodsProps?.id)?.has(x?.id),
                    title: dsProps?.entryMethodHeading?.value,
                  }))}
              officerTitle={dsProps?.officerEntryMethodHeading?.value}
              officerSubtitle={dsProps?.officerEntryMethodDescription?.value}
              officerValues={
                entryMethodsProps?.children?.results
                  ?.filter((x) => x?.groupingName?.targetItem?.name === 'Officer')
                  .map((x) => ({
                    name: x?.displayName?.value ?? '',
                    value: x?.id ?? '',
                    queryName: x?.name?.value,
                    checked: filters.get(entryMethodsProps?.id)?.has(x?.id),
                    title: dsProps?.entryMethodHeading?.value,
                  }))}
              gapYearTitle={dsProps?.gapYearEntryMethodHeading?.value}
              gapYearSubtitle={dsProps?.gapYearEntryMethodDescription?.value}
              gapYearValues={
                entryMethodsProps?.children?.results
                  ?.filter((x) => x?.groupingName?.targetItem?.name === 'Gap Year')
                  .map((x) => ({
                    name: x?.displayName?.value ?? '',
                    value: x?.id ?? '',
                    queryName: x?.name?.value,
                    checked: filters.get(entryMethodsProps?.id)?.has(x?.id),
                    title: dsProps?.entryMethodHeading?.value,
                  }))}
              onClick={(value) => tickFilter(entryMethodsProps?.id, value)}
              onCollapse={(value) => setIsCollapsed(value ?? false)}
            />
            <Filter
              title={dsProps?.categoryHeading?.value}
              filterValues={[
                {
                  name: 'All',
                  value: 'all',
                  checked: (filters.get(categoriesProps?.id)?.size ?? 0) <= 0,
                  title: dsProps?.categoryHeading?.value,
                },
                ...categoriesProps?.children?.results?.map((x) => {
                  return {
                    name: x?.displayName?.value ?? '',
                    value: x?.id ?? '',
                    queryName: x?.name?.value,
                    checked: filters.get(categoriesProps?.id)?.has(x?.id),
                    title: dsProps?.categoryHeading?.value,
                  }
                }),
              ]}
              onClick={(value) => tickFilter(categoriesProps?.id, value)}
            />
          </>
        )}
      </>
    </Drawer>
  )
}

export default SearchDrawer
