import Icon from 'components/front-end/Icons/Icon'

type DeliveryAllocatedProps = {
  allocated: string | undefined
  text?: string
  service?: string
}

const DeliveryAllocated: React.FC<DeliveryAllocatedProps> = ({ allocated, text, service }) => {
  const bgColor = {
    'Tri Service': 'bg-tri-service-tint',
    Army: 'bg-army-tint',
    'Air Force': 'bg-air-force-tint',
    Navy: 'bg-navy-tint',
  }

  const bgStyle = bgColor[(service as keyof typeof bgColor) || 'tri-service']
  return (
    <div className={`mt-10 flex gap-2 p-6 ${bgStyle}`}>
      <Icon name="error" className="shrink-0" type="stroke" height={24} width={24} />
      {text ? (
        <p>{text}</p>
      ) : (
        <p>
          This event is now full for <strong>{allocated === 'inPerson' ? 'in-person' : 'online'} attendance</strong>.
          You can still register to attend <strong>{allocated === 'inPerson' ? 'online' : 'in-person'}</strong>.
        </p>
      )}
    </div>
  )
}

export default DeliveryAllocated
