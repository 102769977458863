import Button from '../Buttons/Button'
import ImageLoader from '../Images/ImageLoader'
import { StorySlideProps } from './StorySlide'

const ThumbnailPanel = ({ id, service, content }: StorySlideProps) => {
  if (!content?.RankImage1?.value?.src) {
    return null
  }

  const thumbnailPanelClasses = `
    title-panel bg-black-off text-white
    flex flex-col justify-center
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[750px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[900px]
    xxl:py-[175px] xxl:px-[120px] max-w-[1200px]
    `
  const rowClasses = `
    flex flex-wrap md:flex-nowrap
    py-10 border-b border-grey-medium
    `
  const imageColClasses = `
    w-full md:w-2/5 shrink-0 flex items-center mb-4 md:mb-0
  `
  const copyColClasses = `
    w-full md:w-3/5 flex items-center
  `

  return (
    <div className={thumbnailPanelClasses}>
      <div className={`${rowClasses} border-t border-grey-medium `}>
        <div className={imageColClasses}>
          <ImageLoader
            height={800}
            width={1000}
            src={content.RankImage1?.value?.src}
            alt={(content.RankImage1?.value?.alt as string) || ''}
            lazy
            fill={content.ImageDispaly?.value === 'Fill area' || undefined}
            sizes="(max-width: 767px): 100vw, 40vw"
          />
        </div>
        <div className={copyColClasses}>
          <div className="px-10 py-4">
            <h6 className="mb-4">{content.RankTitle1?.value}</h6>
            <p className="mbody">{content.RankDescription1?.value}</p>
            {content.CTAButton1?.value && content.CTAButton1?.value?.href && (
              <div className="mt-10">
                <Button
                  data-trackingid={id}
                  icon={content.CTAButtonIcon1?.value}
                  link={content.CTAButton1}
                  type={content.CTAButtonTheme1?.value}
                  service={service || ''}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {content.RankImage2?.value?.src && (
        <div className={rowClasses}>
          <div className={imageColClasses}>
            <ImageLoader
              height={800}
              width={1000}
              src={content.RankImage2?.value?.src}
              alt={(content.RankImage2?.value?.alt as string) || ''}
              lazy
              fill={content.ImageDispaly?.value === 'Fill area' || undefined}
              sizes="(max-width: 767px): 100vw, 40vw"
            />
          </div>
          <div className={copyColClasses}>
            <div className="px-10 py-4">
              <h6 className="mb-4">{content.RankTitle2?.value}</h6>
              <p className="mbody">{content.RankDescription2?.value}</p>
              {content.CTAButton2?.value && content.CTAButton2?.value?.href && (
                <div className="mt-10">
                  <Button
                    data-trackingid={id}
                    icon={content.CTAButtonIcon2?.value}
                    link={content.CTAButton2}
                    type={content.CTAButtonTheme2?.value}
                    service={service}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ThumbnailPanel
