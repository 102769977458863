import Icon from "components/front-end/Icons/Icon";

type HeaderProps = {
    text: string;
  };
  
  const Header = ({ text = '' }: HeaderProps): JSX.Element => {

    return (
        <div>
            <div className={'h6'}>{text}</div>
        </div>
    );
  };
  
  export default Header;
  