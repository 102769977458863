import Button from 'components/front-end/Buttons/Button'
import ShortlistButton from 'components/front-end/Shortlist/ShortlistButton'
import { useLessThan } from 'utils/useBreakpoints'

interface JobTabHeaderProps {
  className?: string
  title: string
  apply?: string
  jobId?: string
  service?: string
  closed?: boolean
  isPriority?: boolean
}

const JobTabHeader = ({ className, title, apply, jobId, service, closed, isPriority }: JobTabHeaderProps) => {
  const animateRightClasses = `transition duration-500 [.animate-true_&]:translate-x-10 [.animate-true_&]:opacity-0`
  const lessThanMd = useLessThan('md')

  return (
    <section className={className}>
      <div className="relative" data-cols="12">
        <div className="row">
          <div className="span-12 lg-span-7">
            <div className={`${animateRightClasses}`}>
              <h2 className="pull-quote-light mb-10 font-normal normal-case lg:mb-0">{title}</h2>
            </div>
          </div>
          <div className="span-12 lg-span-5">
            <div className={`${animateRightClasses} h-full items-start justify-end lg:flex `}>
              {!closed && apply && jobId ? (
                <Button
                  data-trackingid={jobId}
                  data-jobname={title}
                  data-jobtype={isPriority ? 'priority to fill' : undefined}
                  link={{
                    value: {
                      href: apply,
                      text: 'Apply now',
                    },
                  }}
                  service={service}
                  icon="chevron-right-double"
                  type="primary"
                  className="mb-2 mr-5 align-bottom"
                  isSmall={lessThanMd}
                />
              ) : null}
              {jobId ? (
                <ShortlistButton
                  id={jobId}
                  data-jobname={title}
                  data-jobtype={isPriority ? 'priority to fill' : undefined}
                  type={lessThanMd ? 'small-action' : 'action'}
                  className="mb-2 ml-0 align-bottom"
                  service={service}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobTabHeader
