import { useState } from 'react'
import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs'
import { usePathname } from 'next/navigation'
import { track } from 'src/utils/tracking'
import { useService } from 'src/utils/useService'
import { A11y, Autoplay, Controller, EffectCreative, Navigation } from 'swiper'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { CreativeEffectOptions } from 'swiper/types'

import QuoteSlideImage from './QuoteSlideImage'

type QuoteImageCarouselProps = {
  items: {
    fields: {
      Image: ImageField
      YoutubeId: Field<string>
    }
    id: string
  }[]
  controlledCarousels: SwiperClass[]
  id?: string
  service?: string
  tracking_component?: string
}

const imageEffects: CreativeEffectOptions = {
  perspective: true,
  next: {
    shadow: false,
    translate: ['calc(100% + (var(--outermargin)))', 0, 0],
    opacity: 1,
  },
  prev: {
    shadow: false,
    translate: ['-35%', 0, -200],
    opacity: 0,
  },
}

const QuoteImageCarousel = ({ items, controlledCarousels, id, service = '' }: QuoteImageCarouselProps) => {
  const getService = useService(service)
  const path = usePathname()
  const [isSwiperTouch, setIsSwiperTouch] = useState(false)

  return (
    <Swiper
      modules={[A11y, Autoplay, Controller, EffectCreative, Navigation]}
      controller={{ control: controlledCarousels }}
      effect="creative"
      creativeEffect={imageEffects}
      grabCursor
      watchSlidesProgress
      centeredSlides={false}
      centerInsufficientSlides={false}
      centeredSlidesBounds={false}
      navigation={{
        nextEl: `.swiper-next-${id}`,
        prevEl: `.swiper-prev-${id}`,
      }}
      onTouchMove={() => {
        !isSwiperTouch && setIsSwiperTouch(true)
      }}
      onTouchEnd={() => {
        setTimeout(() => {
          setIsSwiperTouch(false)
        }, 300)
      }}
      onSlideChange={(swiper) => {
        isSwiperTouch
          ? track({
              event: 'carousel_swipe',
              ga4category: 'Carousel',
              ga4action: `Swipe - ${swiper.activeIndex < swiper.previousIndex ? 'Backward' : 'Forward'}`,
              ga4name: `QuoteImageCarousel - ${path}`,
            })
          : track({
              event: 'carousel_click',
              ga4category: 'Carousel',
              ga4action: `Click - ${swiper.activeIndex < swiper.previousIndex ? 'Previous' : 'Next'}`,
              ga4name: `QuoteImageCarousel - ${path}`,
            })
      }}
      className="[&_.swiper-slide-visible+.swiper-slide-visible]:!z-0"
    >
      {items.map(({ fields, id }) => (
        <SwiperSlide key={`image-${id}`}>
          <div className="mx-[--outermargin] xm:mr-0">
            <QuoteSlideImage image={fields.Image} videoId={fields.YoutubeId.value} service={getService} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default QuoteImageCarousel
