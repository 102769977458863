import { twMerge } from 'tailwind-merge'

import { EventProps } from 'components/ADFEventHome'
import { ButtonProps } from '../Buttons/Button'
import ImageOverlayCard from '../Cards/ImageOverlayCard'
import Icon from '../Icons/Icon'
import { dateRange } from '../Shared/Utils'

export interface EventCardProps {
  className?: string
  event: EventProps
  buttons?: ButtonProps[]
}

export const eventLocationTag = (locationType: string) => {
  const tagText: Record<string, string> = {
    'Virtual online only': 'Online',
    'Face to face only': 'In Person',
    Combined: 'Combined',
  }
  return locationType === 'Combined' ? ['Online', 'In Person'] : [tagText[locationType]]
}

export const EventCardDetails = ({ event, className }: Pick<EventCardProps, 'event' | 'className'>) => (
  <div className={twMerge('flex gap-x-6 gap-y-2', className)}>
    <p className="flex items-center gap-2">
      <Icon name="calendar" type="fill" width={14} height={14} />
      <span>{dateRange(event.eventStartDate, event.eventEndDate, event.EventTimeZone)}</span>
    </p>
    <p className="flex items-center gap-2">
      <Icon name="clock" type="fill" width={14} height={14} />
      <span>
        {event.EventStartTime} - {event.EventEndTime} {event.EventTimeZone ? `(${event.EventTimeZone})` : ''}
      </span>
    </p>
    <p className="flex gap-2 ">
      <Icon name="location" type="fill" width={14} height={14} className="mt-1 shrink-0" />
      {event.locationName ? (
        <span>
          {event.locationName}, {event.locationCity} {event.locationState}
        </span>
      ) : (
        <span>{event.EventVenue}</span>
      )}
    </p>
  </div>
)

function EventCard({ className, event, buttons = [] }: EventCardProps) {
  return (
    <ImageOverlayCard
      className={className}
      title={{ value: event.title }}
      subtitle={{ value: event.eventType }}
      lazy
      tags={eventLocationTag(event.EventLocationType)}
      dateTag={{ dateString: event.eventStartDate, service: event.service }}
      buttons={buttons}
      field={{ value: { src: event.banner, alt: event.title } }}
      service={event.service}
    >
      <EventCardDetails event={event} className="sbody flex-wrap" />
    </ImageOverlayCard>
  )
}

export default EventCard
