import { useEffect, useState } from 'react'
import { FeedbackAction, track } from 'src/utils/tracking'
import { Service, useService } from 'src/utils/useService'

import Button from '../Buttons/Button'
import { retrieveFeedback, storeFeedback } from '../Drawers/drawer-components/Feedback'

type FeedbackOption = {
  icon: 'satisfied' | 'unsatisfied'
  label: string
}
type FAQFeedbackProps = {
  id: string
  question: string
  options?: FeedbackOption[]
  service?: Service
}

const defaultOptions: FeedbackOption[] = [
  { icon: 'satisfied', label: 'Yes, very' },
  { icon: 'unsatisfied', label: 'Could be better' },
]

const FAQFeedback = ({ id, question, options = defaultOptions, service: defaultService }: FAQFeedbackProps) => {
  const service = useService(defaultService)
  const [feedback, setFeedback] = useState<string>()

  useEffect(() => {
    setFeedback(retrieveFeedback(id))
  }, [id])

  const handleClickFAQFeedback = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const { textContent } = e.currentTarget

    if (textContent) {
      const feedbackAction: FeedbackAction = textContent !== feedback ? `select` : 'deselect'
      setFeedback(feedbackAction === 'select' ? textContent : undefined)
      track({ event: 'feedback', feedback_name: `${feedbackAction} - ${textContent}`, feedback_component: question })
      storeFeedback(id, textContent)
    }
  }

  return (
    <>
      <h5 className="h6">Was this helpful?</h5>
      <div className="mt-6 flex w-full gap-x-4">
        {options.map(({ icon, label }) => (
          <Button
            data-trackingid={id}
            key={`${id}-${label}`}
            button
            link={{ value: { text: label } }}
            type="small-action"
            isSmall
            icon={icon}
            onClick={handleClickFAQFeedback}
            service={service}
            className={feedback === label ? '[&_.button-fill]:scale-x-100' : undefined}
          />
        ))}
      </div>
    </>
  )
}

export default FAQFeedback
