import { twMerge } from 'tailwind-merge'

import { Checkbox } from '../Forms'
import { useShortlist } from '../Shortlist/ShortlistProvider'
import ServiceTag from '../Tags/ServiceTag'
import { CareerOptionsWithId } from './useCareerOptions'

interface JobOptionsProps {
  maxSelected: number
  options: CareerOptionsWithId
  selectedOptions?: string[]
  className?: string
}

export default function CareerSelectorOptions({
  options = [],
  selectedOptions = [],
  maxSelected,
  className,
}: JobOptionsProps) {
  const { jobs } = useShortlist()

  let entry = 0

  return (
    <ul className={twMerge('grid grid-cols-1 gap-8 pt-8 transition duration-500 ease-out', className)}>
      {jobs?.map(({ title, service, id, entryMethods }) => (
        <li key={id}>
          <ServiceTag className="font-semibold" isCard service={service} />
          <div className="mbody-bold pb-2 pt-1 text-black">{title}</div>
          <ul>
            {entryMethods.map((entryMethod) => {
              const option = options.find(({ entryMethodId }) => entryMethodId === entryMethod.id)

              return option ? (
                <li key={option.id} className="-translate-x-2">
                  <Checkbox
                    className="gap-2 py-1.5 [&_span]:text-grey-medium"
                    label={entryMethod.title}
                    name={`careerOptions.${entry++}.selected` as const}
                    readOnly={selectedOptions.length >= maxSelected && !option?.selected}
                  />
                </li>
              ) : null
            })}
          </ul>
        </li>
      ))}
    </ul>
  )
}
