import Icon from 'components/front-end/Icons/Icon'

type ClosedNotificationProps = {
  isClosed: boolean
  service?: string
  className?: string
}

const ClosedNotification: React.FC<ClosedNotificationProps> = ({ isClosed, service, className }) => {
  const bgColor = {
    'tri-service': 'bg-tri-service-tint',
    army: 'bg-army-tint',
    'air-force': 'bg-air-force-tint',
    navy: 'bg-navy-tint',
  }
  const bgStyle = bgColor[(service?.replace(' ', '-').toLowerCase() as keyof typeof bgColor) || 'tri-service']
  return (
    <div className={`flex gap-2 p-6 ${bgStyle} ${className}`}>
      <Icon name="error" className="shrink-0" type="stroke" height={24} width={24} />
      <p>This event is now {isClosed ? 'closed' : 'full'}.</p>
    </div>
  )
}

export default ClosedNotification

