'use client'

import React from 'react'

import Icon from '../Icons/Icon'

type PaginationProps = {
  currentPage: number
  resultsPerPage: number
  itemCount: number
  setCurrentPage: (page: number) => void
  scrollOffset?: number
  hideShowing?: boolean
}

const Pagination = (props: PaginationProps): JSX.Element => {
  props = props

  const nPages = Math.ceil(props.itemCount / props.resultsPerPage)

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  const maxAdjacentPages = 2
  const displayPages = []

  if (nPages <= maxAdjacentPages + 1) {
    displayPages.push(...pageNumbers)
    // if current is less than end
  } else if (props.currentPage < nPages - maxAdjacentPages) {
    if (props.currentPage + 1 > maxAdjacentPages) displayPages.push('ellipsis')
    displayPages.push(...pageNumbers.slice(props.currentPage - 1, props.currentPage + maxAdjacentPages))
    displayPages.push('ellipsis')
  } else {
    displayPages.push('ellipsis')
    displayPages.push(...pageNumbers.slice(nPages - maxAdjacentPages - 1))
  }

  const prevPage = () => {
    if (props.currentPage !== 1) {
      props.setCurrentPage(props.currentPage - 1)
      scrollToOffset(props.scrollOffset ?? 0)
    }
  }
  const nextPage = () => {
    if (props.currentPage !== nPages) {
      props.setCurrentPage(props.currentPage + 1)
      scrollToOffset(props.scrollOffset ?? 0)
    }
  }
  const scrollToOffset = (offset: number) => {
    window?.scrollTo({ top: offset, behavior: 'smooth' })
  }

  // console.log({ currentPage: props.currentPage, nPages: nPages, displayPages: displayPages, maxAdjacentPages: maxAdjacentPages })

  return (
    <div className="">
      <div className="sbody flex items-center justify-between">
        {!props.hideShowing && (
          <p>
            Showing: {(props.currentPage - 1) * props.resultsPerPage + 1}-
            {Math.min(props.currentPage * props.resultsPerPage, props.itemCount)} of {props.itemCount} results
          </p>
        )}
        <div className="ml-auto flex" aria-label="pagination">
          {props.currentPage > 1 && (
            <button className="border px-3 py-2" onClick={prevPage} aria-label="Previous Page">
              <Icon name="chevron-left-double" type="stroke" width={14} height={14} />
            </button>
          )}

          {displayPages.map((page, i) => (
            <React.Fragment key={i}>
              {page === 'ellipsis' ? (
                <span className="block border border-l-0 px-3 py-2">...</span>
              ) : (
                <button
                  className={`border border-l-0 px-3 py-2 ${
                    page === props.currentPage
                      ? 'border-black bg-black text-white dark:border-white dark:bg-white dark:text-black'
                      : ''
                  }`}
                  onClick={() => {
                    props.setCurrentPage(page as number)
                    scrollToOffset(props.scrollOffset ?? 0)
                  }}
                  aria-label={page === props.currentPage ? `Page ${page} (current)` : `Page ${page}`}
                >
                  {page.toString()}
                </button>
              )}
            </React.Fragment>
          ))}

          {props.currentPage < nPages - 2 && (
            <button className="border border-l-0 px-3 py-2" onClick={nextPage} aria-label="Next Page">
              <Icon name="chevron-right-double" type="stroke" width={14} height={14} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pagination
