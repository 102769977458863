import React from 'react'
import { Field, LinkField, RichTextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'

import FAQBrowser from './front-end/FAQ/FAQBrowser'
import FAQProvider from './front-end/FAQ/FAQContext'
import FAQSearch from './front-end/FAQ/FAQSearch'
import { FAQData } from './front-end/FAQ/FAQTypes'
import HeaderText from './front-end/TextBlocks/HeaderText'

export type FAQCollectionProps = ComponentProps & {
  fields: {
    SearchInputFieldLabel: Field<string>
    SearchInputFieldPlaceholder: Field<string>
    SearchResultsSubcategoryTitle: Field<string>
    FAQSearchURL: Field<string>
    FAQSearchResultsPage: LinkField
    NoSearchResultsTitle: Field<string>
    NoSearchResultsCopy: RichTextField
    items: FAQData
  }
}

export const FAQCollection = ({ fields, params: { gridcols = '12' } }: FAQCollectionProps): JSX.Element => {
  const missingContent =
    !fields.items.faqs.length || !fields.items.categories.length || !fields.items.subcategories.length

  return (
    <>
      <div data-cols={gridcols} className="mt-18 flex flex-col sm:mt-24 md:mt-28 xm:mt-32 lg:mt-36">
        <div className="flex w-full justify-stretch whitespace-pre-wrap xs:max-w-sm md:max-w-md xm:max-w-lg xl:max-w-2xl [&_.h3]:normal-case">
          <HeaderText
            title={`Have questions?\nFind what you need`}
            headingLevel="h1"
            headingStyle="h3"
            variant="pull-quote-light"
            service=""
            showServiceTag={false}
          />
        </div>

        <FAQProvider data={fields.items}>
          <FAQSearch
            label={fields.SearchInputFieldLabel.value}
            placeholder={fields.SearchInputFieldPlaceholder.value}
            subcategoriesTitle={fields.SearchResultsSubcategoryTitle.value}
            noResults={
              <div className="mb-4 mt-18 max-w-lg space-y-2 xm:mt-20">
                <h6 aria-level={2}>{fields.NoSearchResultsTitle.value}</h6>
                <div
                  className="mbody [&_a]:whitespace-nowrap [&_a]:underline"
                  dangerouslySetInnerHTML={{ __html: fields.NoSearchResultsCopy.value ?? '' }}
                />
              </div>
            }
          />
        </FAQProvider>
      </div>

      {!missingContent ? (
        <>
          <div data-cols="12" className="mb-6">
            <h4 className="flex flex-col gap-y-1.5" aria-level={2}>
              <span className="mbody block text-grey-medium">OR BROWSE FAQs</span>
              <span className="">If you are...</span>
            </h4>
          </div>
          <div className="mb-10 md:mt-16">
            <FAQProvider data={fields.items}>
              <FAQBrowser />
            </FAQProvider>
          </div>
        </>
      ) : null}
    </>
  )
}

export default withDatasourceCheck()<FAQCollectionProps>(FAQCollection)
