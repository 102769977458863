import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import { ComponentParams, ComponentRendering, Field, RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import Icon from './front-end/Icons/Icon'
import { useSitecoreClick } from 'src/utils/useSitecoreClick'

interface Fields {
  Message: Field<string>
}

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
}

const TRUE_VALUE = 'true'

export const ADFGlobalNotificationBanner = ({fields, params, rendering}: ComponentProps): JSX.Element => {
  const id = `adf-global-notification`
  const message = fields?.Message?.value ?? params?.Message
  
  const notificationRef = useRef<HTMLInputElement | null>(null)

  const [fadeClass, setFadeClass] = useState('')
  const [showNotification, setShowNotification] = useState(false)

  useEffect(() => {
    setShowNotification(window.localStorage.getItem(id) != TRUE_VALUE)
  }, [])

  const onClickToast = (e: MouseEvent) => {
    e.preventDefault()
    setFadeClass('opacity-0')
    window.localStorage.setItem(id, TRUE_VALUE)

    setTimeout(() => {
      if (notificationRef.current) notificationRef.current.classList.add('no-display')
    }, 500)
  }

  const toastCloseProps = useSitecoreClick(onClickToast)

  const containerClasses = `adf-global-notification-container
    bg-tri-service text-black
    fixed right-0 bottom-0 z-[40] 
    w-full py-4 transition-opacity duration-500 ${fadeClass}`

  const textClasses = `adf-global-notification-text
    sbody xs:mbody [&_.button-adf]:text-black [&_.button-adf]:underline [&_a]:underline`

  return (
    <div className='adf-global-notification'>
      {message && message != '' && showNotification &&
        <div id={id} className={containerClasses} ref={notificationRef}>
          <div className="flex px-4 xs:px-8">
            <div className={textClasses}>
              <RichText field={{ value: message }} />
            </div>
            <div className="ml-auto flex pl-3 xs:items-center">
              <button type="button" {...toastCloseProps} className="h-5 shrink-0">
                <Icon name="close" type="stroke" className="size-5 shrink-0" />
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ADFGlobalNotificationBanner
