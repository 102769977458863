import React from 'react'
import { useController } from 'react-hook-form'

import Icon from 'components/front-end/Icons/Icon'
import { InputProps } from './types'
import { ValidationWrapper } from './Validation'

export default function Radio(props: InputProps<'radio'>) {
  const { field } = useController(props)

  return (
    <ValidationWrapper name={props.name} border={false}>
      <p className="stag text-sm mb-2 font-bold text-black">
        <span>{props.label}</span>
        {props.rules?.required && '*'}
      </p>

      <div className="flex gap-3 p-1" role="radiogroup">
        {props.options.map((option, i) => (
          <label key={i} className={`svg-clicked flex gap-2 ${option.value === field.value ? 'clicked-active' : ''}`}>
            <input className="peer sr-only" {...field} type="radio" value={option.value} />{' '}
            <Icon
              className="inline-block rounded-full peer-focus-visible:outline peer-focus-visible:outline-grey"
              name="radio-selected"
              type="stroke"
              clicked={false}
            />
            <span className="">{option.label}</span>
          </label>
        ))}
      </div>
    </ValidationWrapper>
  )
}
