import { ErrorMessage } from '@hookform/error-message'
import { useController } from 'react-hook-form'

import Icon from '../Icons/Icon'

type ValidationProps = {
  name: string
  className?: string
  border?: boolean
  focusBorder?: boolean
}

export function ValidationMessage(props: ValidationProps) {
  const { formState } = useController(props)

  return (
    <ErrorMessage
      name={props.name}
      errors={formState.errors}
      render={({ message }) => (
        <small
          id={`${props.name}-error`}
          className={`sbody -mt-4 inline-flex px-4 font-bold ${props.className ?? ''}`.trim()}
        >
          {message || 'This information is required'}
        </small>
      )}
    />
  )
}

export function ValidationIcon(props: ValidationProps) {
  const { fieldState } = useController(props)

  if (!fieldState.invalid) return null

  return (
    <div className="absolute inset-y-0 right-4 flex items-center group-[.border]:-mr-px [.svg-clicked+&]:relative [.svg-clicked+&]:ml-4 [.svg-clicked+&]:shrink-0">
      <div className={props.className}>
        <Icon name="error" className="text-error" />
      </div>
    </div>
  )
}

export function ValidationWrapper({
  border = true,
  focusBorder = false,
  ...props
}: ValidationProps & { children: React.ReactNode }) {
  const { fieldState } = useController(props)

  // Determine the thickness, as if there is no border, we need to ensure our focus is slightly thicker for consistency
  const focusOutline = border && !focusBorder ? 'focus-within:outline-2' : 'focus-within:outline-3'

  return (
    <div className="group relative">
      <div
        className={` relative box-content w-full transition-all focus-within:z-20  has-[[type="checkbox"]]:flex has-[[type="checkbox"]]:justify-between ${
          border ? 'border ' : ''
        } ${
          border || focusBorder
            ? `focus-within:outline ${focusOutline}
              ${
                fieldState.invalid
                  ? `border-error focus-within:outline-error`
                  : 'border-grey-light focus-within:border-black focus-within:outline-black'
              }`
            : ''
          // Ensure if we have an anchor link, we don't do any focus highlight to the container
        }
          group-has-[a:focus-visible]:outline-0
          `}
      >
        {props.children}
        <ValidationIcon name={props.name} />
      </div>
      <ValidationMessage name={props.name} />
    </div>
  )
}
