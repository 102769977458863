import React, { useEffect, useRef, useState } from 'react'
import { Field, RichText, RichTextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'

import type { ComponentProps } from 'lib/component-props'
import { ButtonTypes } from './front-end/Buttons/Button'
import { StrokeIcons } from './front-end/Icons/icon-data'
import CareerSelector from './front-end/JobComparison/CareerSelector'
import JobComparisonActions from './front-end/JobComparison/JobComparisonActions'
import JobComparisonTable, { JobComparison } from './front-end/JobComparison/JobComparisonTable'
import { CareerOptions } from './front-end/JobComparison/useCareerOptions'
import { fetchShortlistedJobs, useShortlist } from './front-end/Shortlist/ShortlistProvider'

export type JobComparisonFields = {
  SelectTitle: Field<string>
  ShortlistButtonText: Field<string>
  ShortlistButtonIcon: Field<StrokeIcons>
  RoleSectionTitle: Field<string>
  ServiceLabel: Field<string>
  RoleNameLabel: Field<string>
  CommitmentLevelLabel: Field<string>
  JobDescriptionLabel: Field<string>
  EntryMethodLabel: Field<string>
  SalarySectionTitle: Field<string>
  StartingPayLabel: Field<string>
  StartingPayHelpfulInfo: Field<string>
  TrainedPayLabel: Field<string>
  TrainedPayHelpfulInfo: Field<string>
  AllowancesLabel: Field<string>
  AllowancesHelpfulInfo: Field<string>
  InitialMilitaryTrainingLabel: Field<string>
  InitialMilitaryTrainingHelpfulInfo: Field<string>
  EmploymentTrainingLabel: Field<string>
  ProgressionLabel: Field<string>
  ProgressionHelpfulInfo: Field<string>
  FurtherTrainingLabel: Field<string>
  FurtherTrainingHelpfulInfo: Field<string>
  EligibilitySectionTitle: Field<string>
  AgeRangeLabel: Field<string>
  AgeRangeHelpfulInfo: Field<string>
  EducationExperienceLabel: Field<string>
  EducationExperienceHelpfulInfo: Field<string>
  EmploymentTrainingHelpfulInfo: Field<string>
  FitnessRequirementsLabel: Field<string>
  FitnessRequirementsHelpfulInfo: Field<string>
  MinimumServicePeriodLabel: Field<string>
  MinimumServicePeriodHelpfulInfo: Field<string>
  MoreInformationLabel: Field<string>
  RoleDetailsButtonText: Field<string>
  RoleDetailsButtonIcon: Field<StrokeIcons>
  CopyLinkButtonText: Field<string>
  CopyLinkButtonIcon: Field<StrokeIcons>
  CopyLinkButtonTheme: Field<ButtonTypes>
  PrintButtonText: Field<string>
  PrintButtonIcon: Field<StrokeIcons>
  PrintButtonTheme: Field<ButtonTypes>
  HelpfulInfoLinkText: Field<string>
  FooterNotes: RichTextField
}

export type JobComparisonProps = ComponentProps & { fields: JobComparisonFields; params: { ViewOnlyMode?: '1' } }

export type FormValues = {
  careerOptions: CareerOptions
}

export const MAX_SELECTED_JOBS = 4

const parseCareerOptions = (options: string[]) => {
  const [jobIds, entryMethodIds] = options.reduce<[string[], string[]]>(
    ([jobIds, entryMethodIds], career) => {
      const [jobId, entryMethodId] = career.split('--')
      return [
        [...jobIds, jobId],
        [...entryMethodIds, entryMethodId],
      ]
    },
    [[], []]
  )
  return [jobIds, entryMethodIds] as const
}

const ADFJobComparison = ({ fields, params: { ViewOnlyMode }, rendering }: JobComparisonProps): JSX.Element | null => {
  const { config } = useShortlist()
  const [jobsOverride, setJobsOverride] = useState<JobComparison[] | undefined>(undefined)
  const tableRef = useRef<HTMLDivElement>(null)
  const queryKey = 'c'

  const readOnly = ViewOnlyMode === '1'

  // Fetch jobs if in read-only mode
  useEffect(() => {
    if (!readOnly || config.hideCompareTool) {
      return
    }

    const params = new URLSearchParams(decodeURIComponent(window.location.search.slice(1)))
    const careers = params.getAll(`${queryKey}[]`)

    if (!careers?.length) {
      return
    }
    const [jobIds, entryMethodIds] = parseCareerOptions(careers)

    if (!jobIds.length || !entryMethodIds.length) {
      return
    }

    let abort = false
    fetchShortlistedJobs(jobIds).then((jobData) => {
      if (!jobData || abort) {
        return
      }
      const validJobs = jobData.jobs
        .flatMap((job) => job.entryMethods.map((method) => ({ ...job, method }) as JobComparison))
        .filter((job) => entryMethodIds.includes(job.method.id))

      setJobsOverride(validJobs)
    })

    return () => {
      abort = true
    }
  }, [config.hideCompareTool, readOnly])

  const defaultValues: DefaultValues<FormValues> = {
    careerOptions: [],
  }

  const methods = useForm<FormValues>({ defaultValues })

  if (config.hideCompareTool) {
    return null
  }

  return (
    <div className="adf-scroll-bar-container adf-jobs-comparison">
      <FormProvider {...methods}>
        {!readOnly && (
          <CareerSelector trackingId={rendering.uid as string} maxSelected={MAX_SELECTED_JOBS} fields={fields} />
        )}
        <div ref={tableRef} className="-mx-6 sm:-mx-8 md:mx-0">
          <JobComparisonTable
            maxSelected={MAX_SELECTED_JOBS}
            fields={fields}
            jobsOverride={readOnly ? jobsOverride : undefined}
          />
        </div>

        <div className="my-28 flex flex-col gap-y-10 md:flex-row md:justify-between">
          {readOnly ? null : <JobComparisonActions fields={fields} queryKey={queryKey} printArea={tableRef} />}
          <div className="component rich-text md:w-1/2">
            <RichText field={fields.FooterNotes} />
          </div>
        </div>
      </FormProvider>
    </div>
  )
}

export default withDatasourceCheck()<JobComparisonProps>(ADFJobComparison)
