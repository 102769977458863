import React, { useEffect } from 'react'
import { DetailProps } from 'src/components/ADFEventDetails'

import Button from '../../Buttons/Button'
import GoogleMap from '../../Map/GoogleMap'
import ClosedNotification from './ClosedNotification'
import DeliveryAllocated from './DeliveryAllocated'

type Marker = {
  id: string
  name: string
  suburb: string
  state: string
  postcode: string
  latitude: number
  longitude: number
}

const LocationTab: React.FC<DetailProps> = ({ event, deliveryAllocated, openEventDrawer, onlineEvent }) => {
  const [selectedTab, setSelectedTab] = React.useState('')
  const [marker, setMarker] = React.useState<Marker>()

  useEffect(() => {
    setSelectedTab(event.EventLocationType === 'Virtual' ? 'online' : 'map')
  }, [event, setSelectedTab])

  useEffect(() => {
    const m = {
      id: event.Id,
      name: event.EventLocation?.Name,
      suburb: event.EventLocation?.City,
      state: event.EventLocation?.State,
      postcode: event.EventLocation?.PostCode,
      latitude: Number(event.EventLocation?.Latitude),
      longitude: Number(event.EventLocation?.Longitude),
    }
    setMarker(m)
  }, [event, setMarker])

  return (
    <div className="component container-grid ">
      <div className="row">
        <div className="span-12 lg-span-4">
          <h2 className="h4 mb-6 normal-case">Event location</h2>
          <div className="mb-10 border-b border-grey-light pb-10 dark:border-grey-medium lg:hidden">
            {event.EventCanRegister ? (
              event.EventEnableExternalRegistration && event.EventExternalRegistrationUrl ? (
                <Button
                  data-trackingid={event.Id}
                  type="primary"
                  service={event.EventSiteDetailDisplayName}
                  link={{ value: { href: event.EventExternalRegistrationUrl, text: 'Register Now' } }}
                  icon="chevron-right-double"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ) : (
                <Button
                  data-trackingid={event.Id}
                  type="primary"
                  service={event.EventSiteDetailDisplayName}
                  link={{ value: { href: '#', text: 'Register Now' } }}
                  icon="chevron-right-double"
                  button={true}
                  onClick={() => openEventDrawer()}
                />
              )
            ) : (
              <ClosedNotification
                isClosed={event.EventStatus === 'Closed'}
                service={event.EventSiteDetailDisplayName}
              />
            )}
          </div>
          <nav className="hidden max-w-xs flex-col lg:flex">
            {event.EventLocationType !== 'Virtual' && (
              <button
                type="button"
                className={`w-full  px-4 py-3 text-left  ${
                  selectedTab === 'map' ? 'bg-black text-white' : 'border-t border-grey-light'
                }`}
                onClick={() => setSelectedTab('map')}
              >
                In person
              </button>
            )}
            {event.EventLocationType !== 'Face to Face' && (
              <button
                type="button"
                className={`w-full  px-4 py-3 text-left  ${
                  selectedTab === 'online' ? 'bg-black text-white' : 'border-b border-grey-light'
                }`}
                onClick={() => setSelectedTab('online')}
              >
                Online
              </button>
            )}
          </nav>
          {event.EventCanRegister && deliveryAllocated && (
            <DeliveryAllocated allocated={deliveryAllocated} service={event.EventSiteDetailDisplayName} />
          )}
          {!event.EventCanRegister && (
            <ClosedNotification
              className="mt-20 hidden lg:flex"
              isClosed={event.EventStatus === 'Closed'}
              service={event.EventSiteDetailDisplayName}
            />
          )}
        </div>
        <div className="span-12 lg-span-7 lg-offset-1">
          {marker && event.EventLocationType !== 'Virtual' && (
            <div
              className={`${
                selectedTab !== 'map' ? 'lg:hidden' : ''
              } mb-10 aspect-[3/2] border-b border-grey-light pb-10 lg:border-none`}
            >
              <h3 className="h6 mb-6 normal-case lg:hidden">In person</h3>
              <p className="lg:h6 mb-2 font-bold normal-case text-black">{marker?.name}</p>
              <p className="mb-10">
                {event.EventLocation?.Line1} {event.EventLocation?.Line2 || null} {marker.suburb}, {marker.state}{' '}
                {event.EventLocation?.PostCode}
              </p>
              <GoogleMap mapType='event' userMarker={marker} focusMarker={marker} />
              <div className="mt-10 flex flex-wrap items-center gap-6">
                {event.EventStatus === 'Published' && !event.IsFull ? (
                  event.EventEnableExternalRegistration && event.EventExternalRegistrationUrl ? (
                    <div className="hidden lg:block">
                      <Button
                        data-trackingid={event.Id}
                        type="primary"
                        service={event.EventSiteDetailDisplayName}
                        link={{ value: { href: event.EventExternalRegistrationUrl, text: 'Register Now' } }}
                        icon="chevron-right-double"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </div>
                  ) : (
                    <div className="hidden lg:block">
                      <Button
                        data-trackingid={event.Id}
                        type="primary"
                        service={event.EventSiteDetailDisplayName}
                        link={{ value: { href: '#', text: 'Register Now' } }}
                        icon="chevron-right-double"
                        button={true}
                        onClick={() => openEventDrawer()}
                      />
                    </div>
                  )
                ) : null}
                <Button
                  data-trackingid={event.Id}
                  type="action"
                  service={event.EventSiteDetailDisplayName}
                  link={{
                    value: {
                      href: `https://www.google.com/maps?q=${event.EventLocation.Line1}, ${
                        event.EventLocation.Line2 ? event.EventLocation.Line2 + ', ' : ''
                      } ${event.EventLocation.City}, ${event.EventLocation.State} ${event.EventLocation.PostCode}`,
                      text: 'Get Directions',
                      target: '_blank',
                    },
                  }}
                  icon="my-location"
                />
              </div>
            </div>
          )}
          {onlineEvent ? (
            <div className={`${selectedTab == 'map' ? 'lg:hidden' : ''} `}>
              <h3 className="h6 my-6 normal-case">Online</h3>
              <p className="mb-4">Your online access details will be sent to you upon registration.</p>

              <div className="mt-10 hidden items-center gap-6 lg:inline-flex">
                {event.EventStatus === 'Published' && !event.IsFull && event.AvailableOnlineRegistrant ? (
                  event.EventEnableExternalRegistration && event.EventExternalRegistrationUrl ? (
                    <Button
                      data-trackingid={event.Id}
                      type="primary"
                      service={event.EventSiteDetailDisplayName}
                      link={{ value: { href: event.EventExternalRegistrationUrl, text: 'Register Now' } }}
                      icon="chevron-right-double"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ) : (
                    <Button
                      data-trackingid={event.Id}
                      type="primary"
                      service={event.EventSiteDetailDisplayName}
                      link={{ value: { href: '#', text: 'Register Now' } }}
                      icon="chevron-right-double"
                      button={true}
                      onClick={() => openEventDrawer()}
                    />
                  )
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default LocationTab
