import Icon from '../Icons/Icon'

export type FAQSubcategoryItemProps = {
  id: string
  title: string
  count?: number
  onClick: (id: string) => void
  isSelected: boolean
}

const FAQSubcategoryItem = ({ id, title, isSelected, onClick, count }: FAQSubcategoryItemProps) => {
  return (
    <li className="flex w-full flex-col border-b border-solid border-grey-light first:border-t">
      <button
        className={`nav-item sbody relative -mx-[--outermargin] flex items-center px-[--outermargin] py-4 text-left text-black hover:bg-white-off focus-visible:bg-black-off focus-visible:text-white-off xm:mx-0 xm:px-3.5 [.dark_&]:text-white ${
          isSelected
            ? 'cursor-default xm:bg-black-off xm:text-white-off xm:hover:bg-black-off xm:hover:text-white-off'
            : 'active:bg-black-off active:text-white-off xm:hover:bg-black-off xm:hover:text-white-off'
        }`}
        onClick={() => onClick(id)}
      >
        <span className="flex-1">
          {title}
          {typeof count !== 'undefined' ? ` (${count})` : ''}
        </span>
        <Icon name="arrow-east" type="stroke" width={16} height={16} />
      </button>
    </li>
  )
}

export default FAQSubcategoryItem
