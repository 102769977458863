import React from 'react'
import { useController } from 'react-hook-form'
import ReactSelect, { components, DropdownIndicatorProps, InputProps as InputComponentProps } from 'react-select'
import { trackDropdown } from 'src/utils/tracking'

import Icon from '../Icons/Icon'
import { InputProps } from './types'
import { ValidationWrapper } from './Validation'

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name={props.selectProps.menuIsOpen ? 'chevron-up' : 'chevron-down'}
        type="stroke"
        className="!text-dark-grey"
      />
    </components.DropdownIndicator>
  )
}

const Input = (props: InputComponentProps) => <components.Input {...props} aria-activedescendant={undefined} />

export default function Select(props: InputProps<'select'>) {
  const { field, fieldState } = useController(props)

  const handleMenuOpen = () => {
    const name: string =
      typeof props.name === 'string'
        ? props.name
        : React.Children.toArray(props.name)
            .filter((child) => React.isValidElement(child) && typeof child === 'string')
            .join('')
    trackDropdown(name)
  }

  return (
    <ValidationWrapper name={props.name}>
      <label className="relative block pt-4" htmlFor={props.name}>
        <span className="stag text-sm mb-2 block px-4 font-bold text-black">
          {props.label}
          {props.rules?.required && '*'}
        </span>
        <ReactSelect
          {...field}
          aria-activedescendant=""
          name={field.name}
          inputId={field.name}
          instanceId={field.name}
          classNames={{
            container: () => 'z-10 -mx-px pb-2',
            control: () => 'px-4',
            indicatorsContainer: () => '[.border-error_&]:hidden',
            menuList: () => 'py-1',
            noOptionsMessage: () => '!text-left px-4 py-3',
            input: () => '[&>input]:ring-0 [&>input]:ring-offset-0',
            menu: () =>
              `bg-white border border-[3px] -left-0.5 -right-0.5 !w-auto border-t border-t-grey ${
                fieldState.invalid ? '!border-error' : 'border-black'
              }`,
            option: ({ isFocused, isSelected }) =>
              `px-4 py-3 ${isFocused ? 'bg-white-off' : ''} ${isSelected ? 'font-bold' : ''}`,
          }}
          placeholder={props.placeholder}
          options={props.options}
          components={{ DropdownIndicator, Input }}
          onMenuOpen={handleMenuOpen}
          unstyled
        />
      </label>
    </ValidationWrapper>
  )
}
