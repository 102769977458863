import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import router from 'next/router'
import { twMerge } from 'tailwind-merge'

import { trackNavigation } from 'utils/tracking'
import usePrevious from 'utils/usePrevious'
import BackButton from '../Buttons/BackButton'
import Icon from '../Icons/Icon'

interface NavigationBackButtonProps {
  className?: string
  title?: string
}

export default function NavigationBackButton({ className, title }: NavigationBackButtonProps) {
  const pathName = usePathname()
  const oldPathname = usePrevious(pathName)

  useEffect(() => {
    if (oldPathname && oldPathname !== pathName) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [oldPathname, pathName])

  return (
    <BackButton
      className={twMerge('focus-visible:!z-40', className)}
      onClick={() => {
        router.back()
        trackNavigation({
          location: 'header',
          path: 'breadcrumb - back',
        })
      }}
      aria-label={title ? `Back to ${title}` : 'Back'}
      autoFocus
    >
      <Icon name="arrow-west" type="stroke" width={20} height={20} className="shrink-0" />
      <span className="z-[1] ml-2 overflow-hidden text-ellipsis whitespace-nowrap transition duration-300">Back</span>
    </BackButton>
  )
}
