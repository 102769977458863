import Icon from '../Icons/Icon'

type FAQItemProps = {
  question?: string
  answer?: string
  onClick?: () => void
  isSelected?: boolean
}

const FAQItem = ({ isSelected, onClick, question }: FAQItemProps): JSX.Element => {
  const icon = <Icon name="arrow-east" type="stroke" className="!text-adf-primary" />

  return (
    <li
      className="flex w-full flex-col
border-b border-t border-solid border-grey-light [.dark_&]:border-grey-medium"
    >
      <button
        className={`nav-item sbody relative w-full px-6 py-3 text-left hover:font-bold [.dark_&]:text-white ${
          isSelected ? 'font-bold' : ''
        }`}
        onClick={onClick}
      >
        <span className="icon absolute right-[18px] top-1/2 z-[1] -translate-y-1/2">{icon}</span>
        {question}
      </button>
    </li>
  )
}

export default FAQItem
