import React, { useEffect, useRef, useState } from 'react'
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Placeholder,
  PlaceholdersData,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'

import usePageContext from 'components/front-end/_layoutContext'
import useScrollLock from 'utils/scrollLock'
import slugify from 'utils/slugifyStrings'
import { useGreaterThan, useLessThan } from 'utils/useBreakpoints'
import MainNavBack from './front-end/Navigation/MainNavBack'
import Tab from './front-end/Tabs/Tab'

export type TabType = {
  title: {
    field: Field<string>
  }
  name: string
  tag: {
    field: Field<string>
  }
}

export interface SectionTabFields {
  data: {
    datasource: {
      children: {
        results: TabType[]
      }
      field?: {
        type: Field<string>
      }
      name: string
      __typename: string
    }
  }
  [index: string]: unknown
}

interface TabListProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields?: SectionTabFields
  placeholders?: PlaceholdersData
}

export const MAX_SECTION_TABS = 9

const ADFSectionTab = (props: TabListProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const { lockScroll, unlockScroll } = useScrollLock()

  const [mobOpen, setMobOpen] = useState<boolean>(false)

  const isEdit = sitecoreContext.pageState === 'edit'

  const type = props?.fields?.data?.datasource?.field?.type?.value

  const containerStyles = `section-tab ${props.params?.GridParameters ?? ''} ${props.params?.styles ?? ''}`
  const gridcols = Number(props.params?.gridcols ?? '12')

  const pageState = usePageContext().pageContext
  const tabName = slugify(props.fields?.data?.datasource.name || 'section-tab')
  // title.field.value is only used for roles
  const tabList =
    props?.fields?.data?.datasource?.children?.results.map((x: TabType) => slugify(x?.name ?? x?.title.field.value)) ||
    []
  const deepLinkTab = pageState.deepLink.tabSet[tabName]
    ? tabList?.indexOf(pageState.deepLink.tabSet[tabName])
    : undefined
  const tab = deepLinkTab ?? 0
  const isMobile = useLessThan('xm')

  // Handles tab 'actually' being set (only cares for mobile)
  const previousTab = useRef<number | undefined>(undefined)

  useEffect(() => {
    if (!isEdit && isMobile && previousTab.current !== deepLinkTab) {
      setTimeout(() => {
        const shouldMobOpen = typeof deepLinkTab !== 'undefined'
        setMobOpen(shouldMobOpen)
        if (shouldMobOpen) {
          lockScroll()
        } else {
          unlockScroll()
        }
      }, 0)
      previousTab.current = deepLinkTab
    }
  }, [isEdit, isMobile, lockScroll, mobOpen, previousTab, deepLinkTab, tab, unlockScroll, tabName])

  return (
    <>
      <div className={`relative ${containerStyles}`}>
        <div className="py-12">
          <section className="tab-list section-tab-bar border-grey-light xm:border-y [.dark_&]:border-grey-medium">
            <div data-cols={gridcols}>
              <nav className="tab-nav flex flex-wrap xm:flex-nowrap" role="tablist">
                {props?.fields?.data?.datasource?.children?.results?.map((x, i) => (
                  <Tab
                    key={i}
                    title={x?.title?.field}
                    tag={type == 'extended' ? x?.tag?.field : undefined}
                    tracking={{
                      component: props.rendering.dataSource?.split('/').pop()?.replace(' ', '-') || 'ADFSectionTab',
                      name: `${x?.title?.field?.value}`,
                      guid: props.rendering.uid || '',
                    }}
                    onClick={() => {
                      pageState.deepLink.setTabSet({ type: 'SET', payload: { [tabName]: tabList[i] } })
                    }}
                    isSelected={tab === i}
                    isExperience={isEdit}
                  />
                ))}
              </nav>
            </div>
          </section>
        </div>

        {isEdit && (
          <div className="tab-content relative" data-cols={gridcols}>
            {[...Array(MAX_SECTION_TABS)].map((_item, i) => {
              return (
                <div
                  key={i}
                  className={`${tab !== i ? 'hidden' : '[&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden'}`}
                  role="tabpanel"
                  id={`tabpanel-${i}`}
                  aria-labelledby={`tab-${i}`}
                >
                  <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
                  {isEdit && (
                    <div id="grid">
                      {[...Array(gridcols)].map((_x, i) => (
                        <div key={`grid_${i}`}>{i + 1}</div>
                      ))}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}

        {useGreaterThan('xm') && !isEdit && (
          <div className="tab-content relative" data-cols={gridcols} role="tabpanel" id={slugify(`${tabName}=${tab}`)}>
            {[...Array(MAX_SECTION_TABS)].map((_item, i) => {
              return (
                <div key={i} className={`${tab !== i ? 'hidden' : ''}`}>
                  <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
                </div>
              )
            })}
          </div>
        )}
      </div>

      {useLessThan('xm') && !isEdit && mobOpen && (
        <div className="tab-content-takeover fixed top-0 z-[50] h-full w-full bg-white">
          <div className="tab-content-back mb-5 border-b border-grey-light">
            <div data-cols={gridcols} className="relative flex h-[64px] items-center">
              <div className="flex-1">
                <MainNavBack
                  title="Back"
                  onClick={() => {
                    pageState.deepLink.setTabSet({ type: 'SET', payload: { [tabName]: '' } })
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`h-[calc(100%_-_85px)] overflow-auto`}>
            <div data-colsx={gridcols}>
              {[...Array(MAX_SECTION_TABS)].map((_item, i) => (
                <div key={i} className={`${tab !== i ? 'hidden' : ''}`}>
                  <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ADFSectionTab
