import { MouseEvent, useEffect, useRef, useState } from 'react'
import { twJoin } from 'tailwind-merge'

import { JobComparisonFields } from 'components/ADFJobComparison'
import Button from '../Buttons/Button'
import Icon from '../Icons/Icon'
import { useShortlist } from '../Shortlist/ShortlistProvider'
import { formatPrintData } from './helpers'
import { useCareerOptions } from './useCareerOptions'

type JobComparisonActionsProps = {
  fields: JobComparisonFields
  queryKey: string
  printArea: React.RefObject<HTMLDivElement>
}

function JobComparisonActions({ fields, queryKey = 'c', printArea }: JobComparisonActionsProps) {
  const { jobs, config } = useShortlist()
  const [_, selectedOptions] = useCareerOptions() ?? [[], []]
  const [linkCopied, setLinkCopied] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const handleCopyLink = () => {
    const url = new URL(window.location.href)
    url.pathname = config.CompareJobsSharePage

    selectedOptions?.forEach((entryMethodId) => {
      const jobId = jobs?.find(({ entryMethods }) => entryMethods.some(({ id }) => id === entryMethodId))?.id as string
      url.searchParams.append(`${queryKey}[]`, `${jobId}--${entryMethodId}`)
    })

    navigator.clipboard.writeText(decodeURIComponent(url.href))

    setLinkCopied(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setLinkCopied(false)
    }, 1500)
  }

  const handlePrintComparison = (e: MouseEvent<HTMLButtonElement>) => {
    if (!printArea.current || !selectedOptions?.length) {
      return
    }

    const rows = Array.from(printArea.current.querySelectorAll<HTMLDivElement>('[data-comparison-table]>div'))

    if (e.metaKey || e.ctrlKey) {
      formatPrintData(rows, selectedOptions, fields.FooterNotes.value).then((doc) =>
        window.open(doc.output('bloburl'), '_blank')
      )
    } else {
      formatPrintData(rows, selectedOptions, fields.FooterNotes.value).then((doc) =>
        doc.save('Compare ADF Careers.pdf')
      )
    }
  }

  const disableActions = !selectedOptions?.length
  const disabledClasses =
    'transition-opacity duration-300 aria-disabled:pointer-events-none aria-disabled:cursor-default aria-disabled:opacity-50'

  return (
    <div className="flex items-start gap-x-4 md:order-1">
      <span className="relative">
        <Button
          data-trackingid="copy-comparison-link"
          button
          isSmall
          type={fields.CopyLinkButtonTheme.value}
          icon={fields.CopyLinkButtonIcon.value}
          link={{ value: { text: fields.CopyLinkButtonText.value } }}
          onClick={handleCopyLink}
          aria-disabled={disableActions}
          className={disabledClasses}
        />
        <span
          className={twJoin(
            'pointer-events-none absolute inset-px z-10 flex select-none items-center justify-center bg-tri-service transition-opacity',
            linkCopied ? 'opacity-100 duration-200' : 'pointer-events-none opacity-0 duration-[1.5s]'
          )}
        >
          <span className="button flex-1 whitespace-nowrap px-4">Copied</span>
          <Icon type="stroke" name="check" className="aspect-square w-12 shrink-0 px-3" width={20} height={20} />
        </span>
      </span>
      {/* DJTSU-180 Hide Download button
      <Button
        data-trackingid="print-comparison-page"
        button
        isSmall
        type={fields.PrintButtonTheme.value}
        icon={fields.PrintButtonIcon.value}
        link={{ value: { text: fields.PrintButtonText.value } }}
        onClick={handlePrintComparison}
        aria-disabled={disableActions}
        className={disabledClasses}
      />
      */}
    </div>
  )
}

export default JobComparisonActions
