import React, { useEffect, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'
import Link from 'next/link'
import { LocationData } from 'src/pages/api/v2/search/ClosestLocationLookup'
import { getBrowserLocation } from 'src/utils/BrowserLocation'
import { track, trackScrollBar } from 'src/utils/tracking'
import { useScrollProgress } from 'src/utils/useScrollProgress'

import Button from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'
import GoogleMap from 'components/front-end/Map/GoogleMap'
import MapDrawer from 'components/front-end/Map/MapJobDrawer'
import MapSearchField from 'components/front-end/Map/MapSearchField'
import { SearchResult } from './ADFSearchResults'

interface LocationItem {
  locality: string
  state: string
  postcode: string
  latitude: number
  longitude: number
}

interface LocationResponse {
  items?: LocationItem[]
}

export interface ReserveUnit {
  id: string
  name: string
  distance: number
  suburb: string
  state: string
  postcode: string
  latitude: number
  longitude: number
  jobs: SearchResult[]
  isActive?: boolean
}

interface ReserveResponse {
  units?: ReserveUnit[]
}

const ADFReserveUnitLocator = (props: ComponentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const [query, setQuery] = useState<string>('')
  const [location, setLocation] = useState<LocationResponse | null>(null)
  const [locationItem, setLocationItem] = useState<LocationItem | null>(null)
  const [reserveUnits, setReserveUnits] = useState<ReserveResponse | null>(null)
  const [autocomplete, setAutocomplete] = useState<string[] | undefined>(undefined)
  const [unitsController, setUnitsController] = useState(new AbortController())
  const [isLoading, setIsLoading] = useState(false)
  const [isMapDrawer, setIsMapDrawer] = useState(false)
  const [focusElement, setFocusElement] = useState<string | null>(null)

  const [focusMarker, setFocusMarker] = useState<ReserveUnit | undefined>(undefined)
  const [jobsUnit, setJobsUnit] = useState<ReserveUnit | null>(null)

  const [showOverlay, setShowOverlay] = useState(true)

  const scrollContainerRef = useScrollProgress(
    ({ milestone }) => {
      trackScrollBar('Reserve Unit Locator', milestone, 'vertical')
    },
    { axis: 'vertical', milestones: [0, 25, 50, 75, 100] }
  )

  useEffect(() => {
    if (!scrollContainerRef.current) return

    const el = scrollContainerRef.current

    const handleScroll = () => {
      const progress = Math.round((el.scrollTop / (el.scrollHeight - el.clientHeight)) * 100)
      if (progress == 100) {
        setShowOverlay(false)
      } else {
        setShowOverlay(true)
      }
    }

    el.addEventListener('scroll', handleScroll)
    return () => {
      el.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  // load postcodes
  useEffect(() => {
    if (locationItem && query === `${locationItem.locality} ${locationItem.state} ${locationItem.postcode}`) {
      return
    }

    const payload = {
      query: query?.toUpperCase(),
    }
    if (!query || sitecoreContext?.pageEditing) return

    const ctrl = {
      isAborted: false,
    }

    //setIsLoading(true)
    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/LocationLookup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((r) => {
        if (!ctrl.isAborted) {
          setLocation(r)
        } else {
          console.log('LocationLookup was aborted')
        }
      })
    //.finally(() => /*setIsLoading(false))

    return () => {
      ctrl.isAborted = true
    }
  }, [query])

  // load units
  useEffect(() => {
    try {
      unitsController.abort()
    } catch (_ex) {}
    const newUnitsController = new AbortController()
    setUnitsController(newUnitsController)

    setIsLoading(true)

    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/ReserveUnitLocations`, {
      signal: newUnitsController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        latitude: locationItem?.latitude,
        longitude: locationItem?.longitude,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        setReserveUnits(r)
      })
      .finally(() => setIsLoading(false))
  }, [locationItem])

  useEffect(() => {
    setAutocomplete(location?.items?.map((x) => `${x.locality} ${x.state} ${x.postcode}`))
  }, [location])

  useEffect(() => {
    if (focusElement) {
      const field = document?.querySelector(`.${focusElement}`) as HTMLInputElement
      field?.focus()
    }
  }, [focusElement])

  const countClass = `
    xsbody text-grey-medium
    mt-6
    pl-8 md:pl-12 xm:pl-16 xl:pl-18 xxl:pl-[120px] [.page-tab_&]:pl-0
  `
  const mapListParent = `map-list-parent
    relative
    adf-scroll-bar
    overflow-y-scroll
    xm:mr-4
  `

  const mapListContainer = `flex flex-col
  `

  const mapListItem = `pt-4 pb-4 xm:pt-8 xm:pb-8
    bg-white border-b border-grey-light
    pl-8 md:pl-12 xm:pl-16 xl:pl-18 xxl:pl-[120px] [.page-tab_&]:pl-0
    pr-6
  `

  //negative margin calculation
  //!pr-0 !mr-[-calc(var(--outermargin)_-_var(--gutter))] !max-w-[calc(100vw_-_var(--outermargin)_+_var(--gutter))]

  const mapSection = `SECTION-MAP relative flex max-h-[calc(100vh_-_280px)]
    border-y border-grey-light
  `

  //span-12 xm-span-6 lg-span-5 xl-span-4
  const mapList = `map-list
    flex flex-col
    relative w-full xm:w-6/12 xm:w-5/12 xl:w-4/12
    transition duration-500
  `

  const googleMap = `
    hidden xm:block xm:w-6/12 xm:w-7/12 xm:w-8/12
    transition duration-500
  `

  const gradient = `
    z-30 absolute bottom-0 left-0 right-5 h-22
    bg-gradient-to-b from-gradient to-white pointer-events-none
  `

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {lg: 1280}
  // {xl: 1440}
  // {xxl: 1920}

  // component top and bottom paddings
  // pt-10 pb-10 md:pt-12 xm:pt-22 xm:pb-22 xl:pt-18 xl:pb-18 xxl:pt-22 xxl:pb-22
  return (
    <div className={`content-inner`}>
      {/* <section className={`SECTION-HEAD pb-8 md:pb-12 xm:pt-16 xl:pb-18 xxl:pb-22`} data-cols="12">
        <div className="row">
          <div className="span-12 pull-quote">The places you could go</div>
        </div>
      </section> */}
      <section className={`${mapSection} ${isLoading ? 'h-[200px] bg-white' : ''}`}>
        <div className={`${mapList} adf-scroll-bar-container ${isLoading ? 'opacity-0' : ''}`}>
          <div className={`row`}>
            <MapSearchField
              searchTitle={'Find a reserve unit near you'}
              placeholderText={'Enter postcode or suburb'}
              routeQuery={locationItem ? `${locationItem.locality} ${locationItem.state} ${locationItem.postcode}` : ''}
              inPage={true}
              location={'My location'}
              isJobSearch
              updateResults={(query) => {
                setQuery(query)
              }}
              queryList={{
                TotalCount: autocomplete?.length ?? 0,
                Results: autocomplete,
                Message: null,
              }}
              onLocationClick={() => {
                setIsLoading(true)
                getBrowserLocation(
                  (location: LocationData) => {
                    setIsLoading(false)
                    setLocationItem(location)
                  },
                  (msg) => {
                    setIsLoading(false)
                    console.error(msg)
                  }
                )
              }}
              onQueryClick={(x) => {
                const newLocationItem = location?.items?.filter((y) => {
                  return `${y.locality} ${y.state} ${y.postcode}` == x
                })?.[0]
                setLocation(null)
                setLocationItem(newLocationItem ?? null)
              }}
              onResetClick={() => {
                setAutocomplete(undefined)
                setLocationItem(null)
              }}
            />
          </div>
          <div className={`${countClass}`}>{`Showing: ${reserveUnits?.units?.length ?? 0} locations`}</div>
          <div className={`${mapListParent}`} ref={scrollContainerRef}>
            <div className={`${mapListContainer}`}>
              {reserveUnits?.units
                ?.filter((unit) => !!unit && 'jobs' in unit)
                .map((unit, i) => {
                  const jobs = unit.jobs?.length ?? 0
                  const jobsButtonLabel = `${jobs} ${jobs === 1 ? 'role' : 'roles'} available`
                  return (
                    <div
                      key={`${unit.id}-${i}`}
                      className={`${unit.isActive ? 'active bg-white-off' : ''} ${mapListItem}`}
                    >
                      <button
                        onClick={(event) => {
                          setFocusMarker(unit)
                          track({
                            event: 'map',
                            map_click: `${unit.name} - ${event.currentTarget.textContent}`,
                          })
                        }}
                        className="mbody-bold mb-1.5 text-left hover:text-black hover:underline"
                      >
                        {unit.name}
                      </button>
                      <div className="mb-[20.5px] flex flex-row">
                        <Icon name="location" width={12} height={12} className="mr-1 mt-0.5" />
                        <button
                          className={`xsbody hidden text-left underline hover:text-black xm:block ${unit.suburb}${unit.state}${unit.postcode}`}
                          onClick={(event) => {
                            setFocusMarker(unit)
                            track({
                              event: 'map',
                              map_click: `${unit.name} - ${event.currentTarget.textContent}`,
                            })
                          }}
                        >
                          <span>{`${unit.suburb} ${unit.state} ${unit.postcode}`}</span>
                        </button>
                        <span className="xsbody ml-1 hidden xm:inline">
                          {' • '}
                          {unit.distance !== 0 && `${unit.distance}km away  • `}
                        </span>
                        <Link
                          className="xsbody ml-1 shrink-0 underline hover:text-black"
                          href={`https://www.google.com/maps?q=${`${unit.latitude},${unit.longitude}`}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            track({
                              event: 'map',
                              map_click: `${unit.name} - Get Directions`,
                            })
                          }}
                        >
                          <span>Get Directions</span>
                        </Link>
                      </div>

                      {jobs === 0 ? (
                        <>
                          <div className="button">{jobsButtonLabel}</div>
                          <div className="xsbody mt-2 text-grey-medium">
                            Call 13 19 01 to find a flexible part time role near you.
                          </div>
                        </>
                      ) : (
                        <Button
                          data-trackingid={props.rendering.uid as string}
                          onClick={() => {
                            setJobsUnit(unit)
                            setIsMapDrawer(true)
                            track({
                              event: 'map',
                              map_click: `${unit.name} - ${jobsButtonLabel}`,
                            })
                          }}
                          link={{
                            value: {
                              text: jobsButtonLabel,
                            },
                          }}
                          button
                          icon="arrow-east"
                          type="tertiary"
                        />
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
          {showOverlay && <div className={`${gradient}`}></div>}
        </div>
        <div className={`${googleMap} ${isLoading ? 'opacity-0' : ''}`}>
          <GoogleMap
            mapType={'army-reserve'}
            userMarker={
              locationItem
                ? {
                    latitude: locationItem?.latitude,
                    longitude: locationItem?.longitude,
                    id: 'centreMarker',
                    name: `${locationItem?.locality} ${locationItem?.state} ${locationItem?.postcode}`,
                  }
                : undefined
            }
            focusMarker={focusMarker}
            markerItems={reserveUnits?.units}
            // TODO: Fix all the types
            onMarkerClicked={(x: any) => {
              reserveUnits?.units?.forEach((y) => {
                y.isActive = false
              })
              x.isActive = true
              setReserveUnits({
                units: reserveUnits?.units,
              })
              setFocusElement(`${x.suburb}${x.state}${x.postcode}`)
              setFocusMarker(x)
            }}
          ></GoogleMap>
        </div>
        {isLoading && (
          <span className="absolute bottom-0 top-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        )}
      </section>
      <MapDrawer
        props={jobsUnit}
        id={props.rendering.uid as string}
        isOpen={isMapDrawer}
        onClose={() => setIsMapDrawer(false)}
      />
    </div>
  )
}

export default ADFReserveUnitLocator
