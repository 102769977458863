import Divider from '../Drawers/drawer-components/Divider'
import Icon from '../Icons/Icon'

type ListProps = {
  text?: string
  //icon: string;
  size?: 'large' | 'standard' | 'small'
  titleText?: string
  hasTopDivider?: boolean
}

const List = ({ text, size = 'large', titleText = '', hasTopDivider }: ListProps): JSX.Element => {
  if (titleText) {
    size = 'standard'
  }
  //set default as large values
  let paddingLeftAmt = 'pl-4'
  let textStyle = 'lbody text-grey-medium'
  let iconSize = 24
  let iconClasses = ''

  if (size === 'standard') {
    paddingLeftAmt = 'pl-3'
    textStyle = 'mbody text-grey-medium dark:text-white'
    iconSize = 18
    iconClasses = 'text-grey-medium dark:text-white shrink-0'
  } else if (size === 'small') {
    paddingLeftAmt = 'pl-[8px]'
    textStyle = 'sbody text-grey-medium dark:text-white '
    iconSize = 16
    iconClasses = 'mb-[3px] text-grey-medium dark:text-white shrink-0'
  }

  if (titleText) {
    textStyle = 'sbody'
    //add the icon size and paddingLeftAmt to get this
    const textPadding = 'pl-[30px]'

    return (
      <div className="flex flex-col py-5">
        <div className={`${size} flex items-center`}>
          <Icon name="check-circle" type="stroke" width={iconSize} height={iconSize} />
          <div className={`${paddingLeftAmt} mbody-bold`}>{titleText}</div>
        </div>
        <div className={`${textStyle} ${textPadding} pt-1`}>{text}</div>
      </div>
    )
  } else {
    return (
      <>
        {hasTopDivider && <Divider></Divider>}
        <div className={`${size} adf-list flex items-center py-5`}>
          <Icon name="check-circle" type="stroke" width={iconSize} height={iconSize} className={iconClasses} />

          <div className={`${textStyle} ${paddingLeftAmt}`}>{text}</div>
        </div>
        <Divider></Divider>
      </>
    )
  }
}

export default List
