import React, { useEffect, useRef, useState } from 'react'
import { ComponentParams, Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import Icon from 'components/front-end/Icons/Icon'
import GoogleMap, { MapType } from 'components/front-end/Map/GoogleMap'
import { ComponentProps } from 'lib/component-props'
import { getBrowserLocation } from 'src/utils/BrowserLocation'
import { track, trackScrollBar } from 'src/utils/tracking'
import { useScrollProgress } from 'src/utils/useScrollProgress'
import Button from './front-end/Buttons/Button'
import ImageLoader from './front-end/Images/ImageLoader'
import MapSearchField from './front-end/Map/MapSearchField'

interface LocationItem {
  locality: string
  state: string
  postcode: string
  latitude: number
  longitude: number
}

interface LocationResponse {
  items?: LocationItem[]
}

export interface MapUnit {
  // type	"d0a6fbfee5f0441782860a2fdacb95cb"
  // typeName	"Recruiting Centre"
  // service	null
  // facilities	null
  // sportingTeams	null
  // familyQuarters	null
  // isReserve	null
  // postalAddress	"Level 4, 191 Pulteney Street "
  // postalState	"SA"
  // postalSuburb	"Adelaide"
  // postalPostcode	"5000"
  // directionDocument	""

  id: string
  name: string
  nameExtra: string
  description: string
  serviceName: string
  typeName: string
  address: string
  suburb: string
  state: string
  postcode: string
  phoneNumber: string
  openingHours: string
  latitude: number
  longitude: number
  image: string
  postalAddress?: string
  postalState?: string
  postalSuburb?: string
  postalPostcode?: string

  distance: number
  isActive?: boolean
}

interface MapUnitResponse {
  units?: MapUnit[]
}

interface Fields {
  data: {
    datasource: {
      preset: {
        targetItem: {
          id: string
        }
      }
      showSearch: Field<boolean>
      showDirections: Field<boolean>
      showImages: Field<boolean>
      mapType: Field<MapType>
    }
  }
}

export type MapUnitLocatorProps = ComponentProps & {
  fields: Fields
}

const ADFMapUnitLocator = ({ fields, rendering }: MapUnitLocatorProps): JSX.Element => {
  const showSearch = fields?.data?.datasource?.showSearch?.value ?? false
  const showDirections = fields?.data?.datasource?.showDirections?.value ?? false
  const showImages = fields?.data?.datasource?.showImages?.value ?? false
  const mapType = fields?.data?.datasource?.mapType?.value

  const { sitecoreContext } = useSitecoreContext()

  const [query, setQuery] = useState<string>('')
  const [location, setLocation] = useState<LocationResponse | null>(null)
  const [locationItem, setLocationItem] = useState<LocationItem | null | undefined>(undefined)
  const [mapUnits, setMapUnits] = useState<MapUnitResponse | null>(null)
  const [autocomplete, setAutocomplete] = useState<string[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [focusElement, setFocusElement] = useState<string | null>(null)
  const [focusMarker, setFocusMarker] = useState<MapUnit | undefined>(undefined)

  const [showOverlay, setShowOverlay] = useState(true)

  const previousLocationItem = useRef({ locationItem }).current

  const scrollContainerRef = useScrollProgress(
    ({ milestone }) => {
      trackScrollBar('Unit Locator', milestone, 'vertical')
    },
    { axis: 'vertical', milestones: [0, 25, 50, 75, 100] }
  )

  useEffect(() => {
    if (!scrollContainerRef.current) return

    const el = scrollContainerRef.current

    const handleScroll = () => {
      const progress = Math.round((el.scrollTop / (el.scrollHeight - el.clientHeight)) * 100)
      if (progress == 100) {
        setShowOverlay(false)
      } else {
        setShowOverlay(true)
      }
    }

    el.addEventListener('scroll', handleScroll)
    return () => {
      el.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  // load postcodes
  useEffect(() => {
    if (locationItem && query == `${locationItem.locality} ${locationItem.state} ${locationItem.postcode}`) {
      return
    }

    const payload = {
      query: query?.toUpperCase(),
    }
    if (!query || sitecoreContext?.pageEditing == true) return

    const ctrl = {
      isAborted: false,
    }

    //setIsLoading(true)
    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/LocationLookup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((r) => {
        if (!ctrl.isAborted) {
          setLocation(r)
        } else {
          console.log('LocationLookup was aborted')
        }
      })
    //.finally(() => /*setIsLoading(false))

    return () => {
      ctrl.isAborted = true
    }
  }, [query])

  // load units
  useEffect(() => {
    if (!locationItem && previousLocationItem.locationItem === null) {
      console.log('prevent nextjs debug double rendering: ', locationItem, previousLocationItem.locationItem)
      return
    }

    setIsLoading(true)

    const ctrl = {
      locationItem: locationItem,
      isAborted: false,
    }

    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/LocationSearch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filterValues: [],
        searchId: fields?.data?.datasource?.preset?.targetItem?.id ?? 'e6e349b3-c84e-4027-98df-65294c964d0b',
        page: 1,
        perPage: locationItem?.latitude ? 5 : 500,
        latitude: locationItem?.latitude,
        longitude: locationItem?.longitude,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        // do not abort if location item is empty as nextjs debug double rendering is prevented
        if (!ctrl.locationItem || !ctrl.isAborted) {
          setMapUnits({
            units: r.Results?.filter((x: MapUnit) => x.latitude && x.longitude).map((x: any) => {
              return {
                ...x,
                latitude: Number(x.latitude),
                longitude: Number(x.longitude),
              }
            }),
          })
        } else {
          console.log('LocationSearch was aborted')
        }
      })
      .finally(() => setIsLoading(false))

    return () => {
      previousLocationItem.locationItem = locationItem ?? null
      ctrl.isAborted = true
    }
  }, [locationItem])

  useEffect(() => {
    setAutocomplete(location?.items?.map((x) => `${x.locality} ${x.state} ${x.postcode}`))
  }, [location])

  useEffect(() => {
    if (focusElement) {
      const field = document?.querySelector(`.${focusElement}`) as HTMLInputElement
      field?.focus()
    }
  }, [focusElement])

  const countClass = `
    xsbody text-grey-medium
    mt-6
    pl-8 md:pl-12 xm:pl-16 xl:pl-18 xxl:pl-[120px] [.page-tab_&]:pl-0
  `
  const mapListParent = `map-list-parent
    relative
    adf-scroll-bar
    overflow-y-scroll
    xm:mr-4
  `

  const mapListContainer = `flex flex-col
  `

  const mapListItem = `pt-4 pb-4 xm:pt-8 xm:pb-8
    pl-8 md:pl-12 xm:pl-16 xl:pl-18 xxl:pl-[120px] [.page-tab_&]:pl-0 pr-6
    bg-white border-b border-grey-light
    flex flex-row justify-between gap-7
  `

  //negative margin calculation
  //!pr-0 !mr-[-calc(var(--outermargin)_-_var(--gutter))] !max-w-[calc(100vw_-_var(--outermargin)_+_var(--gutter))]

  const mapSection = `SECTION-MAP relative flex h-screen max-h-[calc(100vh_-_280px)]
    border-y border-grey-light
  `

  //span-12 xm-span-6 lg-span-5 xl-span-4
  const mapList = `map-list
    flex flex-col
    relative w-full xm:w-6/12 xm:w-5/12 xl:w-4/12
    transition duration-500
  `

  const googleMap = `
    hidden xm:block xm:w-6/12 xm:w-7/12 xm:w-8/12
    transition duration-500
  `

  const gradient = `
    z-30 absolute bottom-0 left-0 right-5 h-22
    bg-gradient-to-b from-gradient to-white pointer-events-none
  `

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {lg: 1280}
  // {xl: 1440}
  // {xxl: 1920}

  return (
    <div className={`content-inner`}>
      <section className={`${mapSection} ${isLoading ? 'h-[200px] bg-white' : ''}`}>
        <div className={`${mapList} adf-scroll-bar-container ${isLoading ? 'opacity-0' : ''}`}>
          {showSearch && (
            <div className={`row`}>
              <MapSearchField
                searchTitle={'Find a centre near you'}
                placeholderText={'Enter postcode or suburb'}
                routeQuery={
                  locationItem ? `${locationItem.locality} ${locationItem.state} ${locationItem.postcode}` : ''
                }
                inPage={true}
                location={'My location'}
                isJobSearch
                updateResults={(query) => {
                  setQuery(query)
                }}
                queryList={{
                  TotalCount: autocomplete?.length ?? 0,
                  Results: autocomplete,
                  Message: null,
                }}
                onLocationClick={() => {
                  setIsLoading(true)
                  getBrowserLocation(
                    (location: any) => {
                      setIsLoading(false)
                      setLocationItem(location)
                    },
                    (msg) => {
                      setIsLoading(false)
                      console.error(msg)
                    }
                  )
                }}
                onQueryClick={(x) => {
                  const newLocationItem = location?.items?.filter((y) => {
                    // console.log(
                    //   `${y.locality} ${y.state} ${y.postcode}`,
                    //   x,
                    //   `${y.locality} ${y.state} ${y.postcode}` == x
                    // )
                    return `${y.locality} ${y.state} ${y.postcode}` == x
                  })?.[0]
                  setLocation(null)
                  setLocationItem(newLocationItem ?? null)
                }}
                onResetClick={() => {
                  setAutocomplete(undefined)
                  setLocationItem(null)
                }}
              />
            </div>
          )}
          <div className={`${countClass}`}>{`Showing: ${mapUnits?.units?.length ?? 0} locations`}</div>
          <div className={`${mapListParent}`} ref={scrollContainerRef}>
            <div className={`${mapListContainer}`}>
              {mapUnits?.units?.map((x, i) => (
                <div key={`${x.id}-${i}`} className={`${x.isActive ? 'active bg-white-off' : ''} ${mapListItem}`}>
                  <div>
                    <div className="mbody-bold mb-1.5">{x.name}</div>
                    <div className="mb-3 flex flex-row">
                      <div>
                        <div className="flex flex-nowrap">
                          <Icon name="location" width={12} height={12} className="mr-1 mt-0.5" />
                          <span className={`xsbody block text-grey-medium`}>
                            {`${x.address ? x.address + ' ' : ''}${x.suburb} ${x.state} ${x.postcode}`}
                          </span>
                        </div>
                      </div>
                    </div>

                    {x.postalAddress && x.postalState && x.postalSuburb && x.postalPostcode && (
                      <div className="-mt-2  flex flex-row">
                        <div>
                          <div className="flex flex-nowrap">
                            <Icon name="email" type="stroke" width={12} height={12} className="mr-1 mt-0.5" />
                            <span className={`xsbody block text-grey-medium`}>
                              {`${x.postalAddress ? x.postalAddress + ' ' : ''}${x.postalSuburb} ${x.postalState} ${
                                x.postalPostcode
                              }`}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {x.openingHours && (
                      <div className="mb-3 mt-1 flex flex-nowrap">
                        <Icon name="clock" width={12} height={12} className="mr-1 mt-0.5" type="stroke" />
                        <span className={`xsbody text-grey-medium`}>{`${x.openingHours}`}</span>
                      </div>
                    )}
                    {x.description && <div className="xsbody mb-4 hidden xm:block">{x.description}</div>}
                    <div className="flex flex-nowrap gap-3">
                      {showDirections && (
                        <div className="hidden xm:block">
                          <Button
                            data-trackingid={rendering.uid as string}
                            link={{
                              value: {
                                //href: `https://www.google.com/maps?q=${`${x.latitude},${x.longitude}`}`,
                                href: `https://www.google.com/maps?q=${`${x.address ? x.address + ' ' : ''}${
                                  x.suburb
                                } ${x.state} ${x.postcode}`}`,
                                target: '_blank',
                                text: 'Get Directions',
                              },
                            }}
                            icon="directions"
                            type="tertiary-action"
                            onClick={() => {
                              track({
                                event: 'map',
                                map_click: `${x.name} - Get Directions`,
                              })
                            }}
                          />
                        </div>
                      )}
                      <div className="hidden xm:block">
                        <Button
                          data-trackingid={rendering.uid as string}
                          className={`p${x.id.replaceAll('-', '')}`}
                          link={{
                            value: {
                              href: `https://www.google.com/maps?q=${`${x.latitude},${x.longitude}`}`,
                              target: '_blank',
                              text: 'View on map',
                            },
                          }}
                          onClick={(event) => {
                            event?.preventDefault()
                            // trigger refresh as the reference is different
                            setFocusMarker({
                              ...x,
                              isActive: x.isActive,
                            })
                            track({
                              event: 'map',
                              map_click: `${x.name} - View on map`,
                            })
                          }}
                          icon="location"
                          type="tertiary-action"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    {showImages && x.image && (
                      <div className="hidden min-h-[103px] min-w-[134px] xm:block">
                        <ImageLoader
                          width={134}
                          height={103}
                          src={`${process.env.HUB_API_HOST}${x.image}`}
                          alt={x.name}
                          lazy
                        />
                      </div>
                    )}
                    <Link
                      className="block xm:hidden"
                      href={`https://www.google.com/maps?q=${`${x.address ? x.address + ' ' : ''}${x.suburb} ${
                        x.state
                      } ${x.postcode}`}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() => {
                        track({
                          event: 'map',
                          map_click: `${x.name} - View on map`,
                        })
                      }}
                    >
                      <Icon name="arrow-east" type="stroke" width={20} height={20} />
                      <span className="sr-only">View on map</span>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {showOverlay && <div className={`${gradient}`}></div>}
        </div>
        <div className={`${googleMap} ${isLoading ? 'opacity-0' : ''}`}>
          <GoogleMap
            mapType={mapType}
            userMarker={
              locationItem
                ? {
                    latitude: locationItem?.latitude,
                    longitude: locationItem?.longitude,
                    id: 'centreMarker',
                    name: `${locationItem?.locality} ${locationItem?.state} ${locationItem?.postcode}`,
                    //focusToLocation: true
                  }
                : undefined
            }
            focusMarker={focusMarker}
            markerItems={mapUnits?.units}
            onMarkerClicked={(x: any) => {
              mapUnits?.units?.forEach((y) => {
                if (x.id == y.id) {
                  y.isActive = true
                  setMapUnits({
                    units: mapUnits?.units,
                  })
                  setFocusElement(`p${y.id.replaceAll('-', '')}`)
                  setFocusMarker(y)
                } else {
                  y.isActive = false
                }
              })
            }}
          ></GoogleMap>
        </div>
        {isLoading && (
          <span className="absolute bottom-0 top-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        )}
      </section>
    </div>
  )
}

export default ADFMapUnitLocator
