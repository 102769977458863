import CareerCardPanel from './CareerCardPanel'
import CopyPanel from './CopyPanel'
import ListPanel from './ListPanel'
import MediaImagePanel from './MediaImagePanel'
import MediaVideoPanel from './MediaVideoPanel'
import QuotePanel from './QuotePanel'
import StatementPanel from './StatementPanel'
import StatsPanel from './StatsPanel'
import { StoryCarouselItem } from './StoryLaunch'
import ThumbnailPanel from './ThumbnailPanel'
import TitlePanel from './TitlePanel'

const storyCarouselComponents = {
  TitlePanel,
  StatementPanel,
  QuotePanel,
  StatsPanel,
  ListPanel,
  CopyPanel,
  CareerCardPanel,
  ThumbnailPanel,
  MediaVideoPanel,
  MediaImagePanel,
} as const

type StoryCarouselComponents = keyof typeof storyCarouselComponents

export interface StorySlideProps {
  id: string
  name: StoryCarouselComponents
  service?: string
  content?: StoryCarouselItem
}

export const StorySlide = (props: StorySlideProps) => {
  const Component = storyCarouselComponents[props.name]

  if (!Component) {
    return null
  }

  return <Component {...props} />
}

export default StorySlide
