import React from 'react'
import { ComponentParams, ComponentRendering, Field, PlaceholdersData } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin } from 'tailwind-merge'

import SideNav from './front-end/Navigation/SideNav'

export type SubTabType = {
  title: {
    field: Field<string>
  }
  name: string
}

export type TabType = {
  title: {
    field: Field<string>
  }
  name: string
  children: {
    results: SubTabType[]
  }
}

export type SideTabData = {
  heading?: Field<string>
  headingStyle?: Field<string>
  children: {
    results: TabType[]
  }
  field: {
    type: Field<string>
  }
  name: string
  __typename: 'SideTabItem'
}

interface Fields {
  data: {
    datasource: SideTabData
  }
}

interface TabListProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
  placeholders?: PlaceholdersData
}

const ADFSideTab = ({ fields, params, rendering }: TabListProps): JSX.Element | null => {
  const datasource = fields.data?.datasource

  if (!datasource) {
    return null
  }
  const id = params.RenderingIdentifier ?? undefined
  const containerClass = twJoin(params?.GridParameters, params?.styles)

  const HeadingLevel = (datasource.headingStyle?.value?.toLowerCase() as keyof React.JSX.IntrinsicElements) || 'h4'

  const heading = datasource.heading?.value ? (
    <HeadingLevel className="mb-4">{datasource.heading.value}</HeadingLevel>
  ) : null

  const items = datasource.children?.results ?? []
  const name = datasource.name || 'title-tab'

  return (
    <SideNav id={id} name={name} className={containerClass} heading={heading} items={items} rendering={rendering} />
  )
}

export default ADFSideTab
