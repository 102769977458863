interface StatTextProps {
  title?: string
  stat: string
  text?: string
  className?: string
}

const StatText = ({ title, stat, text, className = '' }: StatTextProps) => {
  const statTextClasses = `
    stat-panel [.dark_&]:!text-white
  `
  return (
    <div className={`${statTextClasses} ${className}`}>
      {title && <p className="h3 uppercase mb-6 stat-title">{title}</p>}
      <p className="h3 uppercase mb-5">{stat}</p>
      {text && <p className={`mb-0 large`}>{text}</p>}
    </div>
  )
}

export default StatText
