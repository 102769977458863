import ListLink from 'components/front-end/Lists/ListLink'

import Divider from './Divider'

type ContentLinksProps = {
  title?: string
  linkItems: string[]
}

const ContentLinks = ({ title = '', linkItems }: ContentLinksProps): JSX.Element => {
  return (
    <div className="py-6">
      {title && (
        <>
          <div className="pb-6">
            <div className={'h6'}>{title}</div>
          </div>
          <Divider />
        </>
      )}
      {linkItems.map((li, i) => (
        <ListLink text={li} key={i} />
      ))}
    </div>
  )
}

export default ContentLinks
