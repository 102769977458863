import { LocationData, LocationSuccessResult } from '../pages/api/v2/search/ClosestLocationLookup'

export const getBrowserLocation = (onSuccess: (data: LocationData) => void, onError?: (msg: string) => void) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/ClosestLocationLookup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            latitude,
            longitude,
          }),
        })
          .then<LocationSuccessResult>((r) => r.json())
          .then((r) => {
            const location = r?.items?.[0]
            onSuccess(location)
          })
      },
      (error) => {
        onError?.(error.message)
      }
    )
  } else {
    onError?.('Geolocation is not supported by this browser. Please check your settings')
  }
}
