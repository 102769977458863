import StatementText from 'components/front-end/TextBlocks/StatementText'
import { StorySlideProps } from './StorySlide'

const StatementPanel = ({ service, content }: StorySlideProps) => {
  if (!content?.StatementText?.value) {
    return null
  }

  const storyPanelClasses = `
    story-panel bg-black-off dark
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[560px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[690px]
    xxl:py-[175px] xxl:px-[120px] max-w-[848px]
  `
  return (
    <div className={storyPanelClasses}>
      <StatementText
        text={content.StatementText.value}
        service={service}
        showServiceTag={content.StatementServiceTag?.value}
      />
    </div>
  )
}

export default StatementPanel
