import { EventSearchResult } from 'components/ADFEventSearchResults'
import EventSummaryCard from './EventSummaryCard'

type SimilarEventsProps = {
  events: EventSearchResult[]
  openEventDrawer: (eventData: EventSearchResult) => void
}

const SimilarEvents: React.FC<SimilarEventsProps> = ({ events, openEventDrawer }) => {
  return (
    <>
      <div className="mt-32" data-cols={12}>
        <h3 className="mb-12">Similar Events</h3>
      </div>
      <div>
        <div className="md-span-8 container-grid [&>div]:px-0">
          {events.map((result) => {
            return (
              <EventSummaryCard
                key={result.eventCode}
                data={result}
                onClickRegister={() => {
                  openEventDrawer(result)
                }}
                className="first:border-t"
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default SimilarEvents
