import JobCard from '../JobCard/JobCard'
import { StorySlideProps } from './StorySlide'

const CareerCardPanel = ({ content }: StorySlideProps) => {
  if (!content?.Jobs?.length) {
    return null
  }

  const careerCardPanelClasses = `
    title-panel bg-black-off dark
    flex flex-col justify-center
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[1100px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[1120px]
    xxl:py-[175px] xxl:px-[120px] max-w-[1170px]
    [&_.row]:flex
  `

  const [firstJob, secondJob] = content.Jobs

  return (
    <div className={careerCardPanelClasses}>
      <div className="border-t border-grey-light dark:border-grey-medium">
        <JobCard jobDetails={firstJob} />
      </div>

      {secondJob ? <JobCard jobDetails={secondJob} /> : null}
    </div>
  )
}

export default CareerCardPanel
