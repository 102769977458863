import FormField from "components/front-end/FormFields/FormField";

type OptInProps = {
    title: string;
    responses: string[];
}

const OptIn = ({ title, responses }: OptInProps): JSX.Element => {

    return (
        <div className="py-6">
            <div className="h6 pb-4">{title}</div>
            <div className="flex flex-row flex-wrap gap-5 shrink-0">
                {
                    responses.map((r, index) => <FormField type='radio' clicked={false} mainText={r} key={index}/>)
                }
            </div>
        </div>

    );
    };

export default OptIn;
