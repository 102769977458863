import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'
import { useService } from 'src/utils/useService'
import ImageLoader from '../Images/ImageLoader'

type ProfilePicturesProps = {
  service?: string
  profileID?: string
  profileName?: string
  profileImage?: string
  url?: string
  isActive: boolean
}

const ProfilePicture = ({ profileName, profileImage, url }: ProfilePicturesProps) => {
  const router = useRouter() // Access the router
  const [isHovering, setIsHovering] = useState(false)
  const isActive = router.asPath === url // Check if the current route matches the provided URL
  const service = useService(undefined, false)

  const colorBorder = {
    'tri-service': 'border-tri-service',
    army: 'border-army',
    'air-force': 'border-air-force',
    navy: 'border-navy',
  }

  const aTag = useRef<HTMLAnchorElement | null>(null)

  function focusInView() {
    window?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const aTagElement = aTag.current
    if (aTagElement) {
      aTagElement.addEventListener('focus', focusInView)
    }
    return () => aTagElement?.removeEventListener('focus', focusInView)
  }, [aTag])

  if (!profileImage) {
    return null
  }

  const mainDivClasses = `
        [.dark_&]:text-white
        ${isHovering ? 'cursor-pointer' : ''}
        ${useLessThan('md') ? 'flex justify-center flex-col items-center' : 'flex justify-center items-center flex-col'}
        ${useGreaterThan('md') ? 'py-4 px-6 w-full' : 'py-4 px-4'}
    `
  const imgDiv = `
        ${
          isHovering || isActive
            ? ' border-4 ' + (service ? colorBorder[service as keyof typeof colorBorder] : colorBorder['tri-service'])
            : ''
        }
        relative rounded-full
        ${useGreaterThan('md') ? 'size-[54px]' : 'size-[42px]'}
        overflow-hidden transition-border-width duration-200
        `

  const imgClass = `rounded-full !relative object-cover`
  const ptag = `xstag pt-3 text-white`
  const icon = 'chevron-up'

  return (
    <Link ref={aTag} href={url || '#'} className="flex w-full">
      <div
        className={`${mainDivClasses} icon-hover-${isHovering}`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className={imgDiv}>
          <ImageLoader
            className={imgClass}
            src={profileImage}
            width={54}
            height={54}
            fill
            sizes="(max-width: 768px) 42px, 54px"
            alt={icon}
          />
        </div>
        <p className={ptag}>{profileName}</p>
      </div>
    </Link>
  )
}

export default ProfilePicture
