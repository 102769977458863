import { useController } from 'react-hook-form'

import { InputProps } from './types'
import { ValidationWrapper } from './Validation'

export default function Input(props: InputProps | InputProps<'hidden'>) {
  const { field, fieldState } = useController(props)

  return props.type === 'hidden' ? (
    <input {...field} type="hidden" />
  ) : (
    <ValidationWrapper name={props.name}>
      <label className="relative block p-4">
        {'label' in props && (
          <span className="stag text-sm mb-2 block font-bold text-black">
            {props.label}
            {props.rules?.required && '*'}
          </span>
        )}
        <input
          {...field}
          placeholder={'placeholder' in props ? props.placeholder : ''}
          type={props.type || 'text'}
          className="block w-full ring-0"
          aria-required={!!props.rules?.required}
          aria-describedby={fieldState.invalid ? `${props.name}-error` : undefined}
        />
      </label>
    </ValidationWrapper>
  )
}
