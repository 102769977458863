import type jsPDF from 'jspdf'
import type { autoTable, CellDef } from 'jspdf-autotable'

import { capitaliseWords } from 'utils/capitaliseWords'
import { stripHtml } from 'utils/stripHTML'
import colours from '../../../../tailwind.colors'

declare module 'jspdf' {
  interface jsPDF {
    autoTable: autoTable
    lastAutoTable: {
      finalY: number
    }
  }
}

export async function formatPrintData(
  rows: HTMLElement[],
  selectedOptions: string[],
  disclaimer?: string
): Promise<jsPDF> {
  const { jsPDF } = await import('jspdf')
  const { applyPlugin } = await import('jspdf-autotable')
  const { default: html2canvas } = await import('html2canvas')
  applyPlugin(jsPDF)

  const pageOrientation = selectedOptions.length > 2 ? 'landscape' : 'portrait'
  const doc = new jsPDF(pageOrientation)
  const { width: pageWidth, height: pageHeight } = doc.internal.pageSize
  const margin = { x: 15, y: 8 }

  doc.setFontSize(20)
  doc.setFont('helvetica', 'bold')
  doc.text('Compare Careers', margin.x, margin.y + 20)
  doc.setFontSize(12)
  doc.setLineHeightFactor(1.25)

  const groups = rows.reduce((acc, row) => {
    if (row.hasAttribute('data-snap')) {
      return acc
    } else if (row.children.length === 1) {
      acc.push([row as HTMLElement])
    } else {
      const currentGroup = acc[acc.length - 1]
      currentGroup.push(row as HTMLElement)
    }
    return acc
  }, [] as HTMLElement[][])

  const tableWidth = pageWidth - margin.x * 2
  const rowHeadingWidth = groups.reduce((acc, group) => {
    group.forEach((row) => {
      if (row.children.length > 1) {
        const cell = row.children[0] as HTMLElement
        const lines = cell.innerText.split('\n')
        lines.forEach((line) => {
          acc = Math.max(acc, doc.getTextWidth(line))
        })
      }
    })
    return acc
  }, 0)
  const cellWidth = (tableWidth - rowHeadingWidth) / selectedOptions.length

  const getCellText = (cell: Element) =>
    cell.children.length > 0
      ? Array.from(cell.children)
          .map((c: HTMLElement) => c.innerText)
          .join(' ')
      : cell.textContent ?? ''

  const footnotes = groups.reduce((footnotes, group) => {
    const [head, ...body] = group.map((row) => {
      const rowData = Array.from(row.children).flatMap((cell, i, arr): CellDef => {
        const cellPadding = {
          top: 4,
          bottom: 3,
          horizontal: 4,
        }
        const valign = 'top'
        let content = getCellText(cell)

        if (arr.length === 1) {
          return {
            content: getCellText(cell),
            styles: { fontStyle: 'bold', fontSize: 13, cellPadding, valign },
            colSpan: selectedOptions.length + 1,
          }
        } else if (i === 0) {
          const footnote = cell.querySelector<HTMLButtonElement>('[data-info]')?.dataset.info

          if (footnote) {
            footnotes.push(footnote)
            const footnoteIndex = footnotes.length
            content = content.slice(0, content.lastIndexOf('\n'))
            content += `^${footnoteIndex}`
          }

          return {
            content,
            styles: {
              fontStyle: 'bold',
              cellPadding,
              valign: 'middle',
              overflow: 'hidden',
            },
          }
        } else {
          const link = cell.querySelector<HTMLAnchorElement>('a')?.href
          if (link) {
            content += `\n${link}`
          }

          return {
            content,
            styles: {
              cellPadding:
                i === arr.length - 1
                  ? {
                      ...cellPadding,
                      horizontal: undefined,
                      left: cellPadding.horizontal,
                      right: cellPadding.horizontal * 1.5,
                    }
                  : cellPadding,
              valign,
              cellWidth,
            },
          }
        }
      })

      return rowData
    })

    const startY = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 5 : 35
    doc.autoTable({
      tableId: head[0].content?.toString() ?? 'undefined',
      startY,
      margin: { top: 22 },
      rowPageBreak: 'avoid',
      pageBreak: 'auto',
      head: [head],
      headStyles: {
        fillColor: colours['tri-service'].tint,
        textColor: colours.black['off-secondary'],
      },
      bodyStyles: {
        fillColor: colours.white.off,
        textColor: colours.black['off-secondary'],
      },
      alternateRowStyles: {
        fillColor: colours.white.DEFAULT,
        textColor: colours.black['off-secondary'],
      },
      body,
      didParseCell: (data) => {
        if (data.cell.text.length > 1 && data.cell.text[1].match(/^http.*$/)) {
          const link = data.cell.text.slice(1).join('')
          if (link) {
            data.cell.text = ['']
          }
        }
      },
      willDrawCell: (data) => {
        if (data.section === 'head' && data.pageNumber > 1) {
          console.log(data.cell.text, data.cell.raw)
          data.cell.text[0] = `${data.cell.text[0]} (continued)`
        }

        const rawContent = (data.cell.raw as CellDef).content
        if (typeof rawContent === 'string' && rawContent.match(/^[A-Z- ]+$/)) {
          data.cell.text[0] = capitaliseWords(rawContent)
        } else if (typeof rawContent === 'string' && rawContent?.match(/\^\d+$/)) {
          data.cell.text[0] = rawContent.slice(0, rawContent.lastIndexOf('^'))
        }
      },
      didDrawCell: ({ cell }) => {
        const rawContent = (cell.raw as CellDef).content

        // Draw footnote index as superscript
        if (typeof rawContent === 'string' && rawContent?.match(/\^\d+$/)) {
          const footnoteIndex = rawContent.slice(rawContent.lastIndexOf('^'))
          doc.setFontSize(8)
          doc.text(
            footnoteIndex,
            cell.x + cell.contentWidth - cell.padding('left') - cell.padding('right') + 1,
            cell.y + cell.height / 2
          )
        }

        // Draw link with underline
        if (typeof rawContent === 'string' && rawContent?.match(/\nhttp/)) {
          const [text, url] = rawContent.split('\n')
          const { w, h } = doc.getTextDimensions(text)
          const x = cell.x + cell.padding('left')
          const y = cell.y + cell.padding('top') + h
          doc.setTextColor('#0000ff')
          doc.textWithLink(text, x, y - 1, { url })
          doc.setDrawColor('#0000ff')
          doc.line(x, y, x + w, y)
        }
      },
    })

    return footnotes
  }, [] as string[])

  doc.autoTable({
    theme: 'plain',
    startY: doc.lastAutoTable.finalY + 10,
    margin: { top: 20 },
    rowPageBreak: 'avoid',
    pageBreak: 'auto',
    head: [[{ content: 'Learn more', colSpan: 2 }]],
    body: footnotes.map((footnote, index) => [{ content: `*${index + 1}.`, styles: { cellWidth: 8 } }, footnote]),
  })

  if (disclaimer) {
    doc.setLineHeightFactor(1.5)
    doc.autoTable({
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 20,
      margin: { top: 24 },
      rowPageBreak: 'avoid',
      pageBreak: 'avoid',
      body: [[stripHtml(disclaimer)]],
      bodyStyles: { fontSize: 10, fontStyle: 'italic', textColor: '#595959' },
    })
    doc.setLineHeightFactor(1.25)
  }

  // Add footer text to every page
  const timestamp = getTimestamp()
  const logo = document.querySelector<HTMLImageElement>('.logo-wrapper img')

  for (let i = 1; i <= doc.getNumberOfPages(); i++) {
    if (logo) {
      const imgFile = await html2canvas(logo, { scale: 5 })
      doc.addImage(imgFile, 'SVG', margin.x, margin.y, 41.5, 10)
    }

    doc.setPage(i)
    doc.setFontSize(8)
    // Add timestamp and page number
    doc.text(timestamp, margin.x, pageHeight - margin.y, { align: 'left' })
    doc.text(`Page ${i} of ${doc.getNumberOfPages()}`, pageWidth - margin.x, pageHeight - margin.y, { align: 'right' })
  }

  return doc
}

function getTimestamp() {
  const date = new Date()
  const padLeft = (num: number, len = 2, chr = `0`) => `${num}`.padStart(len, chr)

  return `${padLeft(date.getDate())}/${padLeft(date.getMonth() + 1)}/${date.getFullYear()} ${padLeft(date.getHours())}:${padLeft(date.getMinutes())}`
}
