import React from 'react'
import { ComponentParams, ComponentRendering, Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs'

import { useGreaterThan } from 'src/utils/useBreakpoints'
import CategoryCard from './front-end/CategoryCard/CategoryCard'

interface Fields {
  ExploreButtonLabel: Field<string>
  ExploreButtonLink: Field<{ text: string; href: string; target: string }>
  JobsLabel: Field<string>
  Title: Field<string>
  CardImage: ImageField
  CareersButtonLabel: Field<string>
  CareersButtonLink: Field<{ text: string; href: string; target: string }>
  Category: {
    id: string
    url: string
    name: string
    displayName: string
    fields: {
      JobsCategoryTitle: Field<string>
    }
  }
  FullTimeJobs: string
  PartTimeJobs: string
}

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams }
  fields: Fields
}
interface StyleType {
  gridColumn: string
}
const ADFJobsCategoryCard = (props: ComponentProps): JSX.Element => {
  const { ExploreButtonLink, JobsLabel, Title, CardImage, CareersButtonLink, FullTimeJobs, PartTimeJobs } = props.fields

  const gridOption = useGreaterThan('xm') ? `col-span-${props.rendering.params.ColumnOption}` : 'grid grid-cols-1'

  const gridStyle: StyleType = {
    gridColumn: gridOption === 'col-span-2' ? 'span 2 / span 2' : 'span 1 / span 1',
  }
  return (
    <>
      <div style={gridStyle}>
        <CategoryCard
          id={props.rendering.uid as string}
          image={CardImage}
          title={Title.value}
          jobsCategoryTitle={JobsLabel.value}
          fullTimeJobs={FullTimeJobs}
          partTimeJobs={PartTimeJobs}
          exploreButtonLabel={ExploreButtonLink.value.text}
          exploreButtonLink={ExploreButtonLink.value.href}
          exploreButtonTarget={ExploreButtonLink.value.target}
          careersButtonLabel={CareersButtonLink.value.text}
          careersButtonLink={CareersButtonLink.value.href}
          careersButtonTarget={CareersButtonLink.value.target}
        />
      </div>
    </>
  )
}

export default ADFJobsCategoryCard
