import { FAQLookupData } from './FAQHelpers'
import { FAQ } from './FAQTypes'

export type FAQActions =
  | { type: 'UPDATE_QUERY'; payload: string }
  | { type: 'CLEAR_QUERY' }
  | { type: 'SAVE_SEARCH' }
  | { type: 'CLEAR_SEARCH' }
  | { type: 'SELECT_FAQ'; payload: string }
  | { type: 'DESELECT_FAQ' }
  | { type: 'SELECT_CATEGORY'; payload: string }
  | { type: 'DESELECT_CATEGORY' }
  | { type: 'SELECT_SUBCATEGORY'; payload: string }
  | { type: 'DESELECT_SUBCATEGORY' }
  | { type: 'SELECT_HEADING'; payload: string }
  | { type: 'DESELECT_HEADING' }

export type FAQState = {
  query: string
  results: FAQ[]
  selectedFaqId: string
  selectedCategoryId: string
  selectedSubcategoryId: string
  selectedHeading: string
  previousSearch: { query: string; results: FAQ[] }
}

export const faqReducer =
  (lookup: FAQLookupData) =>
  (state: FAQState, action: FAQActions): FAQState => {
    switch (action.type) {
      case 'UPDATE_QUERY':
        const query = action.payload.toLowerCase()

        if (query.length < 3) {
          return { ...state, query: action.payload, results: [] }
        }

        const results = lookup.faqsMatchingQuery(query)

        return { ...state, query: action.payload, results }

      case 'CLEAR_QUERY':
        return { ...state, query: '', results: [] }

      case 'SAVE_SEARCH':
        return { ...state, previousSearch: { query: state.query, results: state.results } }

      case 'CLEAR_SEARCH':
        return { ...state, previousSearch: { query: '', results: [] } }

      case 'SELECT_FAQ':
        return { ...state, selectedFaqId: action.payload }

      case 'DESELECT_FAQ':
        return { ...state, selectedFaqId: '' }

      case 'SELECT_CATEGORY':
        if (state.selectedCategoryId === action.payload) {
          return state
        }

        const selectedCategory = lookup.categories[action.payload]
        if (!selectedCategory) {
          console.error(`Category with id ${action.payload} not found`)
        }

        return {
          ...state,
          selectedCategoryId: selectedCategory?.id,
          selectedSubcategoryId: '',
          query: '',
          results: [],
        }

      case 'DESELECT_CATEGORY':
        return {
          ...state,
          selectedCategoryId: '',
          selectedSubcategoryId: '',
        }

      case 'SELECT_SUBCATEGORY':
        return {
          ...state,
          selectedSubcategoryId: action.payload,
          selectedHeading: '',
        }

      case 'DESELECT_SUBCATEGORY':
        return {
          ...state,
          selectedSubcategoryId: '',
          selectedHeading: '',
        }

      case 'SELECT_HEADING':
        return {
          ...state,
          selectedHeading: action.payload,
        }

      case 'DESELECT_HEADING':
        return {
          ...state,
          selectedHeading: '',
        }

      default:
        return state
    }
  }
