import { useMemo } from 'react'

import { trackNavigation } from 'utils/tracking'
import { usePathSegments } from 'utils/usePathSegments'
import { useLessThan } from 'utils/useScreen'
import Icon from '../Icons/Icon'
import QuickLink from '../QuickLinks/QuickLink'
import { navElementId, NavigationItemWithChildren } from './navigationHelpers'
import NavigationItem from './NavigationItem'
import { NavigationLevel, navigationLevels, useNavigation, navigationTrapStack } from './NavigationProvider'

interface NavigationMenuItemsProps {
  level: NavigationLevel
  items: NavigationItemWithChildren[]
  className?: string
  onClick: (item: NavigationItemWithChildren, evt: React.MouseEvent) => void
  onInteraction: (item: NavigationItemWithChildren) => void
  onFocusTrapDeactivate: () => void
  indicateChildren?: boolean
}

export function NavigationItemList({
  level,
  items,
  className,
  onInteraction,
  onFocusTrapDeactivate,
  onClick,
  indicateChildren,
}: NavigationMenuItemsProps) {
  const pathSegments = usePathSegments()
  const { activePath, isTrapLocked } = useNavigation()
  const mobileMode = useLessThan('lg')

  const levelIndex = navigationLevels.indexOf(level)
  const nextNavLevel = navigationLevels[levelIndex + 1]

  const handleHover = (item: NavigationItemWithChildren) => {
    if (!mobileMode) {
      onInteraction(item)
    }
  }

  const [navItems, quicklinkItems] = useMemo(() => {
    return items.reduce(
      (acc, item) => {
        if (!item.hideInNavigation) {
          acc[0].push(item)
        }
        if (item.showAsQuickLink) {
          acc[1].push(item)
        }
        return acc
      },
      [[], []] as [NavigationItemWithChildren[], NavigationItemWithChildren[]]
    )
  }, [items])

  const decorationClassName: Record<typeof level, string> = {
    primary: '-bottom-0.5 h-1 lg:bottom-0',
    secondary: 'h-0.75 lg:-bottom-2 lg:h-1',
    tertiary: 'h-0.75 lg:-bottom-1',
  }

  return (
    <>
      {navItems.map((item, i) => {
        const hasChildren = item.children.filter(({ hideInNavigation }) => !hideInNavigation).length > 0
        const levelIndex = navigationLevels.indexOf(level)
        const subnavExpanded = activePath[levelIndex]?.url === item.url
        const inActivePath = pathSegments.includes(item.url)
        const isActivePage = inActivePath && levelIndex === pathSegments.length - 1
        return (
          <NavigationItem
            key={item.url}
            aria-expanded={level !== 'primary' ? subnavExpanded : undefined}
            aria-controls={nextNavLevel ? `${nextNavLevel}-navigation` : undefined}
            aria-haspopup={hasChildren}
            aria-current={isActivePage ? 'page' : inActivePath}
            data={item}
            id={navElementId(item, level, 'link')}
            className={className}
            decorationClassName={decorationClassName[level]}
            onInteraction={handleHover}
            onClick={onClick}
            icon={
              indicateChildren &&
              hasChildren && (
                <button
                  className=""
                  onClick={(e) => {
                    e.preventDefault()
                    onInteraction(item)
                  }}
                >
                  <Icon
                    name="chevron-right"
                    type="stroke"
                    width={44}
                    height={44}
                    className="size-10 text-  inherit lg:size-11"
                  />
                </button>
              )
            }
            onFocus={() => {
              if (!isTrapLocked) {
                handleHover(item)
              }
            }}
            isFirst={i == 0}
            isLast={i == navItems.length - 1}
            onTrapDeactivate={() => onFocusTrapDeactivate?.()}
          />
        )
      })}

      {quicklinkItems.length > 0 ? (
        <li className="flex flex-1 flex-col justify-end">
          {quicklinkItems.map((item) => (
            <QuickLink
              key={item.url}
              className="!mb-0"
              link={{
                value: {
                  href: item.url,
                  target: '_self',
                  title: item.pageTitle,
                  text: item?.ctaDescription,
                },
              }}
              isServiceTag={false}
              onClick={() =>
                trackNavigation({
                  location: 'header',
                  path: `quicklink - ${item.pageTitle || ''}`,
                })
              }
            />
          ))}
        </li>
      ) : null}
    </>
  )
}
