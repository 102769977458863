import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin } from 'tailwind-merge'

import { useService } from 'utils/useService'
import Icon from './front-end/Icons/Icon'
import { SalaryInformation, Stage } from './front-end/JobDetails/_JobInterface'
import QuickLink from './front-end/QuickLinks/QuickLink'
import HeaderText from './front-end/TextBlocks/HeaderText'

export interface JobSalaryDetailsProps {
  rendering: {
    componentName: string
    fields: {
      Salary: Field<SalaryInformation>
    }
    service?: string
    isReserve?: boolean
  }
}

const toDollar = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
})

const JobDetailsSalary = ({ rendering }: JobSalaryDetailsProps): JSX.Element | null => {
  const salaryInformation = rendering.fields.Salary.value
  const service = useService(rendering.service)

  if (!salaryInformation) {
    return null
  }

  const icons = ['flag', 'qualification'] as const
  const stages = salaryInformation.salary?.stages ?? []

  const undergraduatePhases = [
    'firstPostGradYear',
    'finalYear',
    'twoYearToGraduation',
    'threeYearToGraduation',
    'fourYearToGraduation',
    'fiveYearToGraduation',
    'sixYearToGraduation',
  ] as const

  const getUndergraduateScale = (stage: Stage) =>
    undergraduatePhases.reduce(
      (acc, phase) => {
        if (!stage.isUndergraduate) {
          return []
        }
        const text = stage[`${phase}Text`]
        const salary = stage[`${phase}Salary`] ?? 0
        const formattedSalary = salary ? toDollar.format(parseInt(salary, 10)) : ''

        if (text && formattedSalary) {
          acc.push([text, formattedSalary])
        }

        return acc
      },
      [] as [string, string][]
    )

  return (
    <>
      <section className="mt-20">
        <div className="relative" data-cols="12">
          <div className="row">
            <div className="span-12 md-span-4 lg-span-3 md:mr-[calc(var(--colwidth))]">
              <div className="flex flex-col gap-y-4">
                {salaryInformation.salaryHeader ? (
                  <HeaderText
                    title={salaryInformation.salaryHeader}
                    headingLevel="h4"
                    showServiceTag={false}
                    className="mb-0"
                  />
                ) : null}
                <div className="[&_a]:link-underline">
                  <RichText className="text-lbody" field={{ value: salaryInformation.salaryDescription }} />
                  <RichText
                    className={twJoin(
                      "text-mbody [&_li:before]:absolute [&_li:before]:left-0 [&_li:before]:content-['✓'] [&_li]:relative [&_li]:pl-5 [&_p:not(:last-child)]:mb-[1lh] [&_ul]:my-[1lh]",
                      salaryInformation.salary?.trainingInfo && "[&_li:first-child]:after:content-['*']"
                    )}
                    field={{
                      value: salaryInformation.salaryBenefits,
                    }}
                  />
                  <RichText
                    className="text-sbody before:content-['*']"
                    field={{ value: salaryInformation.salary?.trainingInfo }}
                  />
                </div>
              </div>
            </div>
            <div className="span-12 md-span-67 lg-span-8 xxl-span-7 flex flex-col gap-y-9">
              <div className="salary-box mb-12 flex max-w-5xl flex-col gap-x-2 gap-y-4 md:flex-row xm:mb-0">
                {stages
                  .filter(stage => stage.isUndergraduate || (stage.amount && parseInt(stage.amount) != 0))
                  .map((stage, i: number) => 
                    <div key={i} className="flex w-full flex-col border border-grey p-6 md:w-1/2 ">
                      <div className="mb-5">
                        <Icon name={icons[i]} type="content" service={service} />
                      </div>
                      <div className="flex flex-1 flex-col gap-y-2">
                        <p className="lbody-bold leading-snug text-black">{stage.title}</p>
                        {rendering.isReserve && stage.package ? (
                          <p className="lbody-bold mt-auto">{stage.package}</p>
                        ) : null}
                        {stage.isUndergraduate ? (
                          <ol className="text-sbody">
                            {getUndergraduateScale(stage).map(([text, salary], i) => (
                              <li key={i}>
                                {text}&nbsp;<strong className="text-nowrap">{salary} p.a.</strong>
                              </li>
                            ))}
                          </ol>
                        ) : (
                          <div className="flex flex-1 flex-col justify-end gap-y-4 pt-3 text-black">
                            <span className="mtag font-bold uppercase">from</span>
                            <p className="flex items-baseline">
                              <span
                                className={twJoin(
                                  'h3 text-grey-medium',
                                  stage.name === 'Trained' ? 'mr-3' : 'mr-2 font-normal'
                                )}
                              >
                                {toDollar.format(parseInt(stage.amount, 10))}
                              </span>
                              <span className="h6 text-grey-medium">{stage.isDaily ? 'per day' : 'p.a.'}</span>
                            </p>
                          </div>
                        )}
                        {stage.super ? <p className="sbody-bold pt-1.5">✓ {stage.super}</p> : null}
                      </div>
                    </div>
                  )
                }
              </div>

              {salaryInformation.allowance ? (
                <QuickLink
                  className="!my-0 max-w-5xl"
                  service={service}
                  isServiceTag={false}
                  link={{
                    value: {
                      href: salaryInformation.allowance.link,
                      title: salaryInformation.allowance.header,
                      text: salaryInformation.allowance.allowances,
                      target: '_blank',
                    },
                  }}
                />
              ) : null}

              {salaryInformation.salaryFootnote ? (
                <RichText
                  className="[&_a]:link-underline ml-2 text-sbody leading-normal [&_ul]:list-disc [&_ul]:px-[calc(var(--gutter)/2)]"
                  field={{ value: salaryInformation.salaryFootnote }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobDetailsSalary
