import { FC, Fragment, useCallback, useState } from 'react'
import { type Swiper as TSwiper } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { twMerge } from 'tailwind-merge'

import { useBreadcrumbs } from 'utils/useBreadcrumbs'
import { usePathSegments } from 'utils/usePathSegments'
import NavigationBackButton from './NavigationBackButton'
import { NavigationItemWithChildren } from './navigationHelpers'
import NavigationItem from './NavigationItem'

interface NavigationBreadcrumbsProps {
  className?: string
}

const BreadcrumbLink: FC<{ data: NavigationItemWithChildren; className?: string; current: boolean }> = ({
  data,
  className,
  current,
}) => (
  <NavigationItem
    tag="div"
    aria-current={current}
    data={data}
    transformTitle={(data) => data.pageTitle}
    className={twMerge(
      'flex h-full items-center [.dark_&]:text-white [.grey_&]:text-white [.light_&]:text-grey-medium [.light_&]:hover:text-black',
      className
    )}
    decorationClassName="h-px"
    linkClassName="normal-case text-snav font-medium"
    isFirst={true}
    isLast={true}
  />
)

export default function NavigationBreadcrumbs({ className }: NavigationBreadcrumbsProps) {
  const { breadcrumbNavItems, showBreadcrumbBar, backLinkEnabled } = useBreadcrumbs()
  const [[showStartGradient, showEndGradient], setOverflow] = useState<readonly [boolean, boolean]>([false, true])
  const pathSegments = usePathSegments()

  const updateOverflow = useCallback(({ isBeginning, isEnd }: TSwiper) => {
    setOverflow([!isBeginning, !isEnd])
  }, [])

  const isCurrentPage = (item: NavigationItemWithChildren) => {
    const segmentIndex = pathSegments.indexOf(item.url)
    const inActivePath = segmentIndex >= 0
    const isActivePage = inActivePath && segmentIndex === pathSegments.length - 1
    return isActivePage ? 'page' : false
  }

  if (!showBreadcrumbBar) {
    return null
  }

  return showBreadcrumbBar ? (
    <>
      <nav
        aria-label="breadcrumb"
        className={twMerge(
          'navigation-grid relative inset-x-0 flex w-full items-center justify-start py-0',
          !backLinkEnabled &&
            'before:pointer-events-none before:absolute before:inset-y-0 before:left-0 before:z-10 before:w-14 before:bg-gradient-to-l before:from-transparent before:to-white before:transition-opacity before:duration-300 [.dark_&]:before:to-black',
          !backLinkEnabled &&
            'after:pointer-events-none after:absolute after:inset-y-0 after:right-0 after:z-10 after:w-14 after:bg-gradient-to-r after:from-transparent after:to-white after:transition-opacity after:duration-300 [.dark_&]:after:to-black',
          showStartGradient ? 'before:opacity-100' : 'before:opacity-0',
          showEndGradient ? 'after:opacity-100' : 'after:opacity-0',
          className
        )}
      >
        {backLinkEnabled ? (
          <NavigationBackButton className="z-50 justify-self-start pl-6 xm:pl-0 [.dark_&]:text-white [.grey_&]:text-white" />
        ) : breadcrumbNavItems.length ? (
          <Swiper
            className="relative w-full"
            slidesPerView="auto"
            wrapperTag="ol"
            onSwiper={updateOverflow}
            onSlideChange={updateOverflow}
          >
            {breadcrumbNavItems.filter(Boolean).map((item, index, array) => {
              const isLast = index === array.length - 1
              const hasChildren = item.children.filter(({ hideInNavigation }) => !hideInNavigation).length > 0

              return isLast ? (
                <Fragment key={`${item.url}-${index}`}>
                  {hasChildren ? (
                    <SwiperSlide tag="li" className="max-w-fit first:pl-6 xm:first:pl-0">
                      <BreadcrumbLink
                        data={item}
                        className="after:mx-6 after:h-4 after:w-px after:bg-current after:content-[''] xm:px-0"
                        current={isCurrentPage(item) === 'page'}
                      />
                    </SwiperSlide>
                  ) : null}

                  {((hasChildren ? item : array[index - 1])?.children ?? [])
                    .filter((item) => !item.hideInNavigation && !item.hideInBreadcrumb)
                    .map((childItem, subindex) => (
                      <SwiperSlide
                        key={`${childItem.url}-${index}-${subindex}`}
                        tag="li"
                        className="max-w-fit pr-8 first:pl-6 xm:first:pl-0"
                      >
                        <BreadcrumbLink data={childItem} current={isCurrentPage(childItem) === 'page'} />
                      </SwiperSlide>
                    ))}
                </Fragment>
              ) : (
                <SwiperSlide key={`${item.url}-${index}`} tag="li" className="max-w-fit first:pl-6 xm:first:pl-0">
                  <BreadcrumbLink
                    data={item}
                    className="after:mx-6 after:h-4 after:w-px after:bg-current after:content-['']"
                    current={isCurrentPage(item) === 'page'}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        ) : null}
      </nav>
      <style jsx global>{`
        :root[data-sticky-nav='true'] {
          --breadcrumb-height: 64px;
        }
        :root {
          --breadcrumb-height: 64px;
        }
        @media (min-width: 1024px) {
          :root {
            --breadcrumb-height: 70px;
          }
        }
        @media (min-width: 1280px) {
          :root {
            --breadcrumb-height: 108px;
          }
        }
      `}</style>
    </>
  ) : (
    <style jsx global>{`
      :root,
      :root[data-sticky-nav='true'] {
        --breadcrumb-height: 0;
      }
    `}</style>
  )
}
