import Notification from 'components/front-end/Notifications/Notification'
import type { JobData } from './_JobInterface'

interface JobNotificationProps {
  message: JobData['notificationMessage']
  icon: JobData['notificationIcon']
  service: JobData['service']
  className?: string
}

export default function JobNotification({
  message,
  icon = 'info-circle',
  service = 'tri-service',
  className,
}: JobNotificationProps) {
  if (!message) {
    return null
  }

  return (
    <section className={className}>
      <div className="relative" data-cols="12">
        <div className="row">
          <div className="span-12">
            <Notification serviceName={service} icon={{ name: icon, type: 'stroke' }} text={message} />
          </div>
        </div>
      </div>
    </section>
  )
}
