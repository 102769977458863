import TitleText from '../TextBlocks/TitleText'
import { StorySlideProps } from './StorySlide'

const TitlePanel = ({ id, service, content }: StorySlideProps) => {
  if (!content?.Heading?.value) {
    return null
  }

  const titlePanelClasses = `
    title-panel bg-black-off
    py-10 px-8 w-screen
    xm:py-14 xm:px-12
    lg:py-[92px] lg:px-16 lg:max-w-[560px]
    xl:py-[108px] xl:px-18 xl:max-w-[690px]
    xxl:py-[175px] xxl:px-[120px] max-w-[848px]
    h-full
  `
  return (
    <div className={titlePanelClasses}>
      <TitleText
        id={id}
        title={content?.Heading?.value}
        text={content?.TitleDescription?.value}
        CTAButton1={{
          value: {
            href: content?.TitleButtonLink?.value?.href,
            target: content?.TitleButtonLink?.value?.target,
            text: content?.TitleButtonLink?.value?.text,
          },
        }}
        CTAButton1Theme="primary"
        service={service}
        HeadingStyle="h3"
      />
    </div>
  )
}

export default TitlePanel
