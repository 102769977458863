import Button, { ButtonProps, ButtonTypes } from 'components/front-end/Buttons/Button'

type ButtonsProps = {
  buttons: drawerButton[]
}

export type drawerButton = {
  'data-trackingid': string
  type: ButtonTypes
  icon?: ButtonProps['icon']
  link: string
  target?: string
  text: string
}

const Buttons = ({ buttons }: ButtonsProps): JSX.Element => {
  return (
    <div className="flex flex-wrap gap-3">
      {buttons.map((button, i) => {
        return (
          <Button
            data-trackingid={button['data-trackingid'] as string}
            key={`${button.text}-${i}`}
            icon={button.icon ?? 'chevron-right-double'}
            isSmall={true}
            type={button.type}
            link={{
              value: {
                href: button.link,
                target: button.target ?? '_self',
                text: button.text,
              },
            }}
            service="tri-service"
          />
        )
      })}
    </div>
  )
}

export default Buttons
