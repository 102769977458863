import { assign, createMachine, sendParent } from 'xstate'

type ScrollDirection = 'up' | 'down' | 'notScrolled'

interface ScrollContext {
  prevScrollY: number // The previously measured scrollY value
  lastScrollChangePosition: number // The position of the last scroll direction change
  threshold: number
}

export type ScrollDirectionChangeEvent = {
  type: 'SCROLL_DIRECTION_CHANGED'
  direction: 'up' | 'down'
}

type ScrollEvent = { type: 'SCROLL'; scrollY: number } | ScrollDirectionChangeEvent

const createScrollDirectionMachine = (threshold = 0) =>
  createMachine(
    {
      //** @xstate-layout N4IgpgJg5mDOIC5SwMYCcD2AbLARAlmmCgC74YB2AdBRiQMrrZaQDE9AwgEoDyAMnwDaABgC6iUAAcMsfGUoSQAD0QBGAKwAOKgHYAzOoBsB9QCYdhzcPUAaEAE9EAWj3bhATgAsh4cJ3rhUyN9AF8Qu1RMHAIiUnJqWgYmHDZOXgFBVXEkEGlZeQpFFQRVPU8qU1NfVWtzS2s9O0cEMsMK93dVHU11UsNPVVMwiOS8QmICqkjmSABVSSoUDGx8CihWJVgSAEMSMCptgDM9tAAKDh5+XB4AdQA5AH0ABQBRLgBJHlwASlZp6PGcUoU1GcwWSxWaxE2SkMjk8SKiB0Xiomk0Ax0wjRwk8nU0TWcpR0VE8Oh0Az0QUCwXUwxA-zGsUmDLBVCw+C2YAoq3WaX4QjEijy8IUOWKhkM7io7k0ei8uNMlkpBJK3ioWM0pgGwj6Ay1dIZMQm8RBURYEHmbI5e25a3Y3H5mRhuThBURCE07mJfnU6jJpRxnXcKpcbi8WlcAU1niMBtGRqB1BZEFwGAA7tQIey7ZsdnsDscwGcLldbo9Xh8vr9DYDmaCU+nM8ts1BoULXQixYg9JiqIYozKyl7ySr9OV9FUuj1POiPHGzQm62bIKmM1bObbeQ6MoKcsK3V2Sg0+65rMJDOSdOZbA5EL7hOq9CYDLrBvPmIuTcnV9R2Ruefa6RCFk7b5J2oDFFexLuOe6heP0pgaNYKoSg+qhdO4pgGHKWE6mE4QgLQEBwIoNZMuBLpgaKEHOP0JLuHBWKqD07jYY0t4IE4M7lMOnhYf2rhYe474AuRwKJIwy4QKBIqFIeTiqG0uKMZozFwWxN7NAp6hUHo9SIQYWJakYhgiYyxrAsm8wyQeNEIL6UqqLiyJaFYuLMSGpgdCSZKeFililJ6TlmZ+ln1paWY8jZFHin4VBOV6DHYu5+IcQprjSr5pRUkEF60gRZEWUm4ULH+NpRXuHbUcoiDGKoPl+U5xiBopIYaLp+mlFGxnGCFtZfvWP7RdVxSUqYfYDrKzkjhxM5tKpAbqAYuF+n1YnFVJP6LM2FWwlRcl2TOUoXopEqaPoZjuIYKGkrp6F6cZlJ+qoa1FaaMwNmuZVcrtlGye6M46ZUxjqJ4PbLVdKpWPVrE9VUT06C9+FAA */

      preserveActionOrder: true,
      context: {
        prevScrollY: 0,
        lastScrollChangePosition: 0,
        threshold,
      },

      schema: {
        context: {} as ScrollContext,
        events: {} as ScrollEvent,
      },

      invoke: {
        id: 'scrollListener',
        src: 'scrollListenerService',
      },
      id: 'scrollDirection',
      initial: 'notScrolled',

      states: {
        notScrolled: {
          on: {
            SCROLL: [
              {
                target: 'scrolledUp',
                cond: 'isScrollingUp',
                actions: 'updatePrevScrollY',
              },
              {
                target: 'scrolledDown',
                cond: 'isScrollingDown',
                actions: 'updatePrevScrollY',
              },
            ],
          },
        },
        scrolledUp: {
          entry: ['notifyScrollUp', 'updateLastScrollChangePosition'],

          states: {
            cooling: {
              after: {
                COOLDOWN_PERIOD: 'listening',
              },
            },

            listening: {
              on: {
                SCROLL: [
                  {
                    actions: 'updatePrevScrollY',
                    cond: 'isScrollingDown',
                    target: '#scrollDirection.scrolledDown',
                  },
                  {
                    target: 'listening',
                    actions: 'updatePrevScrollY',
                    internal: true,
                  },
                ],
              },
            },
          },

          initial: 'cooling',
        },
        scrolledDown: {
          entry: ['notifyScrollDown', 'updateLastScrollChangePosition'],

          states: {
            cooling: {
              after: {
                COOLDOWN_PERIOD: 'listening',
              },
            },

            listening: {
              on: {
                SCROLL: [
                  {
                    actions: 'updatePrevScrollY',
                    target: '#scrollDirection.scrolledUp',
                    cond: 'isScrollingUp',
                  },
                  {
                    target: 'listening',
                    actions: 'updatePrevScrollY',
                    internal: true,
                  },
                ],
              },
            },
          },

          initial: 'cooling',
        },
      },

      tsTypes: {} as import('./scrollDirection.machine.typegen').Typegen0,
      description: `This state machine only updates state when the window's scroll direction changes.`,
    },
    {
      actions: {
        updatePrevScrollY: assign({
          prevScrollY: (_, { scrollY }) => scrollY,
        }),
        updateLastScrollChangePosition: assign({
          lastScrollChangePosition: (_, { scrollY }) => scrollY,
        }),
        notifyScrollUp: sendParent({ type: 'SCROLL_DIRECTION_CHANGED', direction: 'up' }),
        notifyScrollDown: sendParent({ type: 'SCROLL_DIRECTION_CHANGED', direction: 'down' }),
      },
      guards: {
        isScrollingUp: ({ prevScrollY, threshold, lastScrollChangePosition }, { scrollY }) =>
          scrollY < prevScrollY && Math.abs(scrollY - lastScrollChangePosition) >= threshold,
        isScrollingDown: ({ prevScrollY, threshold, lastScrollChangePosition }, { scrollY }) =>
          scrollY > prevScrollY && Math.abs(scrollY - lastScrollChangePosition) >= threshold,
      },
      services: {
        scrollListenerService: () => (send) => {
          const handleScroll = () => {
            send({ type: 'SCROLL', scrollY: window.scrollY })
          }

          window.addEventListener('scroll', handleScroll, { passive: true })

          return () => {
            window.removeEventListener('scroll', handleScroll)
          }
        },
      },
      delays: {
        COOLDOWN_PERIOD: 300,
      },
    }
  )

const scrollDirectionMachine = createScrollDirectionMachine()

export { createScrollDirectionMachine, scrollDirectionMachine }

export type { ScrollDirection, ScrollContext, ScrollEvent }
