import Link from 'next/link'

import { StrokeIcons } from 'components/front-end/Icons/icon-data'
import Icon from '../Icons/Icon'

type ListItemProps = {
  text?: string
  titleText?: string
  icon?: StrokeIcons
  url?: string
  size?: 'large' | 'standard' | 'small'
}

const ListItem: React.FC<ListItemProps> = ({ text, icon, url, size }) => {
  //set default as standard values
  let textStyle = 'mbody'
  let iconSize = 18
  let gap = 'gap-3'

  if (size === 'large') {
    textStyle = 'lbody'
    iconSize = 24
    gap = 'gap-4'
  } else if (size === 'small') {
    textStyle = 'sbody'
    iconSize = 16
    gap = 'gap-2'
  }

  const ItemContent = (): JSX.Element => {
    return (
      <div className={`flex items-center ${gap}`}>
        {icon && <Icon name={icon} type="stroke" width={iconSize} height={iconSize} />}
        <span className={textStyle}>{text}</span>
      </div>
    )
  }
  return (
    <li className={`flex py-5 ${gap} relative border-b border-grey-light`}>
      {url ? (
        <Link href={url} className="hover:underline">
          <ItemContent />
        </Link>
      ) : (
        <ItemContent />
      )}
    </li>
  )
}

export default ListItem
