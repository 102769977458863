import { useRef } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import { trackButton } from 'src/utils/tracking'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import Tab from '../Tabs/Tab'
import { useFAQContext } from './FAQContext'
import FAQList from './FAQList'
import FAQMobileBrowser from './FAQMobileBrowser'
import FAQSubcategoryItem from './FAQSubcategoryItem'
import FAQSubcategoryList from './FAQSubcategoryList'

const FAQBrowser = () => {
  const desktopMode = useGreaterThan('xm')
  const faqListRef = useRef<HTMLDivElement>(null)
  const {
    state: { selectedCategoryId, selectedSubcategoryId, selectedHeading },
    send,
    lookup,
  } = useFAQContext()

  const { sitecoreContext } = useSitecoreContext()
  const isEdit = sitecoreContext.pageState === 'edit'

  const activeCategoryId = selectedCategoryId || (desktopMode ? Object.keys(lookup.categories)[0] : selectedCategoryId)
  const activeSubcategoryId =
    selectedSubcategoryId ||
    (desktopMode ? lookup.categories[activeCategoryId]?.subcategories[0] : selectedSubcategoryId)

  const faqsInCategory = lookup.faqsInCategory(activeCategoryId)
  const faqsInSubcategory = lookup.faqsInSubcategory(activeSubcategoryId, faqsInCategory)

  const handleSelectCategory = (categoryId: string): void => {
    if (activeCategoryId === categoryId) {
      send({ type: 'DESELECT_CATEGORY' })
    } else {
      send({ type: 'SELECT_CATEGORY', payload: categoryId })
    }
  }

  const handleSelectHeading = (heading: string) => {
    if (typeof heading === 'string') {
      trackButton({ title: heading, guid: `${activeCategoryId} - ListView`, location: 'FAQ' })
      send({ type: 'SELECT_HEADING', payload: heading })
    }
  }

  return (
    <>
      <section className="tab-list flex flex-col justify-start">
        <div className="border-grey-light xm:border-y [.dark_&]:border-grey-medium">
          <nav data-cols={12} className="tab-nav flex flex-wrap xm:flex-nowrap" role="tablist">
            {Object.values(lookup.categories).map((category) => (
              <Tab
                key={category.id}
                title={{ value: category.faqCategoryTitle }}
                tag={{ value: category.faqCategorySubtitle }}
                onClick={() => {
                  handleSelectCategory(category.id)
                }}
                isSelected={
                  activeCategoryId === category.id ||
                  (desktopMode && !activeCategoryId && Object.keys(lookup.categories)[0] === category.id)
                }
                isExperience={isEdit}
                tracking={{
                  component: 'FAQBrowser',
                  name: category.faqCategoryTitle,
                  guid: category.id,
                }}
              />
            ))}
          </nav>
        </div>

        <div data-cols={12} className="tab-content relative hidden grid-cols-12 xm:mt-22 xm:grid">
          <div className="col-span-12 flex flex-col gap-y-8 xm:col-span-4">
            <h6 aria-level={3}>
              {activeCategoryId ? lookup.categories[activeCategoryId].faqCategorySubtitle : 'Categories'}
            </h6>
            <FAQSubcategoryList
              faqs={faqsInCategory}
              activeCategoryId={activeCategoryId}
              activeSubcategoryId={activeSubcategoryId}
            />
          </div>
          <div ref={faqListRef} className="col-span-12 flex scroll-m-6 flex-col xm:col-start-6">
            <FAQList faqs={faqsInSubcategory.map((id) => lookup.faqs[id])} groupByHeading />
          </div>
        </div>
      </section>

      <FAQMobileBrowser open={!!activeCategoryId}>
        {selectedHeading ? (
          <FAQList faqs={lookup.faqsWithHeading(selectedHeading).map((id) => lookup.faqs[id])} groupByHeading />
        ) : activeCategoryId ? (
          <>
            <h6 aria-level={3}>{lookup.categories[activeCategoryId].faqCategorySubtitle}</h6>
            <ul>
              {lookup.headingsForFAQs(faqsInCategory).map((heading) => (
                <FAQSubcategoryItem
                  key={heading}
                  id={heading}
                  title={`${heading} (${lookup.faqsWithHeading(heading, faqsInCategory).length})`}
                  onClick={() => handleSelectHeading(heading)}
                  isSelected={heading === selectedHeading}
                />
              ))}
            </ul>
          </>
        ) : null}
      </FAQMobileBrowser>
    </>
  )
}

export default FAQBrowser
