import React, { ReactNode, useState } from 'react'
import { ComponentParams, ComponentRendering, Field, RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs'
import axios from 'axios' // Import Axios for making API requests
import { DefaultValues, SubmitHandler } from 'react-hook-form'
import { FeedbackFormResponse } from 'src/pages/api/contactus/submitFeedbackForm'
import { track } from 'src/utils/tracking'

import { StrokeIcons } from 'components/front-end/Icons/icon-data'
import Header from './front-end/Drawers/drawer-components/Header'
import {
  Captcha,
  Checkbox,
  ErrorMessage,
  Fieldset,
  Form,
  Input,
  parseOptionFields,
  Select,
  Submit,
  SuccessMessage,
  TextArea,
} from './front-end/Forms'
import { emailRegex } from './front-end/Forms/constants'
import { KeyValueDefinition } from './front-end/Forms/types'
import type { InputOption } from './front-end/Forms/types'

interface Fields {
  ErrorMsgAgreePrivacyStatement: Field<string>
  ErrorMsgCaptcha: Field<string>
  ErrorMsgEmail: Field<string>
  ErrorMsgFeedback: Field<string>
  ErrorMsgFirstname: Field<string>
  ErrorMsgForm: RichTextField
  ErrorMsgSurname: Field<string>
  ErrorMsgTypeOfFeedback: Field<string>
  FormHeading: Field<string>
  Icon: Field<StrokeIcons>
  LblAgreePrivacyStatement: RichTextField
  LblCaptcha: Field<string>
  LblEmail: Field<string>
  LblFeedback: Field<string>
  LblFirstname: Field<string>
  LblLikeToBeCopied: Field<string>
  LblSubmitButton: Field<string>
  LblSurname: Field<string>
  LblTypeOfFeedback: Field<string>
  OptionsTypeOfFeedback: Array<KeyValueDefinition>
  PhTextEmail: Field<string>
  PhTextFeedback: Field<string>
  PhTextFirstname: Field<string>
  PhTextSurname: Field<string>
  PhTextTypeOfFeedback: Field<string>
  SuccessHeading: Field<string>
  SuccessMsg: RichTextField
}

export type FormValues = {
  agreeToTerms: boolean
  captcha: string
  email: string
  feedback: string
  feedbackLabel: 'Complaint' | 'Technical' | 'Other'
  feedbackType: InputOption<'complaint' | 'technical' | 'other'>
  feedbackUrl: string
  firstName: string
  lastName: string
  pageReferrerUrl: string
  sCode: ''
  sendUserCopy: boolean
}

export type FeedbackFormProps = {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
}

export const FeedbackForm = (props: FeedbackFormProps): JSX.Element => {
  const formId = props.rendering.uid as string
  const defaultValues: DefaultValues<FormValues> = {
    agreeToTerms: false,
    captcha: '',
    email: '',
    feedback: '',
    feedbackLabel: undefined,
    feedbackType: undefined,
    feedbackUrl: '',
    firstName: '',
    lastName: '',
    pageReferrerUrl: '',
    sCode: '',
    sendUserCopy: false,
  }
  const [error, setError] = useState<{ reason: string; details: ReactNode } | undefined>()

  const handleSubmit: SubmitHandler<FormValues> = async ({ feedback, feedbackType, ...data }: FormValues) => {
    const formData = {
      ...data,
      // The feedback field is base64 encoded twice to prevent the form from being submitted by bots
      feedback: Buffer.from(Buffer.from(feedback).toString('base64')).toString('base64'),
      feedbackType: feedbackType.value,
      feedbackLabel: feedbackType.value,
    }

    setError(undefined)

    const api = `${process.env.DIRECT_APP_API_HOST}/api/contactus/submitFeedbackForm`

    try {
      const { data } = await axios.post<FeedbackFormResponse>(api, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!data.success) {
        throw new Error(data.error)
      }

      track({
        event: 'form_submission',
        form_name: 'feedback',
        feedback_type: feedbackType.value,
      })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError({
          reason: err.message || 'Unknown error',
          details: props.fields.ErrorMsgForm.value || err.code || '',
        })
      }
      console.error(err)
    }
  }

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      successMessage={
        !error ? (
          <SuccessMessage
            id={formId}
            icon={props.fields.Icon.value}
            heading={props.fields.SuccessHeading.value}
            message={props.fields.SuccessMsg}
            rendering={props.rendering}
          />
        ) : null
      }
    >
      <Header text={props.fields.FormHeading.value} />

      <Fieldset legend={props.fields.FormHeading.value}>
        <Input
          label={props.fields.LblFirstname.value}
          name="firstName"
          placeholder={props.fields.PhTextFirstname.value}
          rules={{ required: true }}
        />
        <Input
          label={props.fields.LblSurname.value}
          name="lastName"
          placeholder={props.fields.PhTextSurname.value}
          rules={{ required: true }}
        />
        <Input
          label={props.fields.LblEmail.value}
          name="email"
          placeholder={props.fields.PhTextEmail.value}
          rules={{ required: true, pattern: { value: emailRegex, message: props.fields.ErrorMsgEmail.value } }}
        />
        <Select
          label={props.fields.LblTypeOfFeedback.value}
          name="feedbackType"
          options={parseOptionFields(props.fields.OptionsTypeOfFeedback)}
          rules={{ required: props.fields.ErrorMsgTypeOfFeedback.value }}
        />
        <Input type="hidden" name="feedbackUrl" />
        <Input type="hidden" name="pageReferrerUrl" />
        <TextArea
          label={props.fields.LblFeedback.value}
          name="feedback"
          placeholder={props.fields.PhTextFeedback.value}
          rules={{ required: props.fields.ErrorMsgFeedback.value }}
        />
      </Fieldset>

      <div className="flex flex-col gap-y-6 [&_a]:underline">
        <Checkbox label={props.fields.LblLikeToBeCopied.value} name="sendUserCopy" />
        {props.fields.LblAgreePrivacyStatement.value ? (
          <Checkbox
            label={<RichText tag="span" field={props.fields.LblAgreePrivacyStatement} />}
            name="agreeToTerms"
            rules={{ required: props.fields.ErrorMsgAgreePrivacyStatement.value }}
          />
        ) : null}
      </div>

      <Captcha name="captcha" rules={{ required: props.fields.ErrorMsgCaptcha.value }} />

      {error ? <ErrorMessage heading={error.reason}>{error.details}</ErrorMessage> : null}

      <Submit id={formId} label={props.fields.LblSubmitButton.value} />
    </Form>
  )
}

export default FeedbackForm
