import React, { useState } from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs'
import { twMerge } from 'tailwind-merge'

import Drawer from 'components/front-end/Drawers/Drawer'
import Feedback from 'components/front-end/Drawers/drawer-components/Feedback'
import Header from 'components/front-end/Drawers/drawer-components/Header'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import JobDetailsTypes, { PriorityLink, Stage } from 'components/front-end/JobDetails/_JobInterface'
import JobCardStats from 'components/front-end/JobDetails/JobCardStats'
import ListIcon from 'components/front-end/Lists/ListIcon'
import QuickLink from 'components/front-end/QuickLinks/QuickLink'
import StoryBanner from 'components/front-end/StoryPanel/StoryBanner'
import { useLessThan } from 'utils/useBreakpoints'
import JobTabPanel from './JobTabPanel'

const toDollar = (value: number) =>
  new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
  }).format(Number(value) || 0)

const getSalary = (trainingStage: Stage | undefined) => {
  if (!trainingStage?.amount) {
    return
  }

  const paymentFrequency = trainingStage.isDaily ? ' per day' : ' per annum'
  const amount = Number(trainingStage.amount)
  const salary = toDollar(amount)
  const formattedSalary = salary ? `${salary} ${paymentFrequency}` : 'TBC'
  return formattedSalary
}

const PriorityQuickLink = ({ link }: { link: PriorityLink | undefined }) => {
  return link?.URL.value.href ? (
    <QuickLink
      link={{
        value: {
          href: link.URL.value.href,
          title: link.Header.value,
          text: link.Description.value,
          target: '',
        },
      }}
      isServiceTag={false}
      service={link.Service.value}
      className="!my-0"
    />
  ) : null
}

const JobRole = ({
  responseData,
  animateClasses: [animateRightClasses, animateDownClasses] = [],
  id,
}: JobDetailsTypes) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const lessThanMd = useLessThan('md')

  if (!responseData) {
    return null
  }

  const priorityToFill = responseData.priorityToFill
  const entryMethods = responseData.entryMethods ?? []
  const firstEntryMethod = entryMethods[0]
  const finalStage = firstEntryMethod?.salaryInformation?.salary?.stages?.slice(-1)[0]
  const salary = getSalary(finalStage)
  const pathway = responseData.pathway.title && responseData.pathway.description ? responseData.pathway : undefined

  return (
    <JobTabPanel id={id}>
      <section className="JOB">
        <div className="relative" data-cols="12">
          <div className="row">
            <div className="span-12 lg-span-7">
              <div className={twMerge('flex w-full flex-col gap-y-20', animateRightClasses)}>
                <RichText className="xlarge" field={{ value: responseData.shortDescription }} />
                <div className="HERO-IMAGE lg:mx-autopr-[5px] mx-[calc(var(--outermargin)*-1-var(--gutter)/2)]">
                  <ImageLoader
                    src={responseData.desktopImage || ''}
                    alt={(responseData.desktopImageAlt as string) || ''}
                    priority
                    width={100}
                    height={75}
                    sizes="(max-width: 1280px) 100vw, 66vw"
                  />
                </div>
              </div>
            </div>
            <div className="span-12 lg-span-4 lg-offset-1">
              <div className={animateDownClasses}>
                <div className={`JOB-STATS relative z-20 md:-mt-20 lg:mt-0`}>
                  <JobCardStats
                    id={responseData.id}
                    title={responseData.title}
                    service={responseData.service}
                    isFulltime={responseData.isFulltime}
                    isReserve={responseData.isReserve}
                    salaryInfo={responseData.salaryInfo}
                    salaryFootnote={responseData.salaryFootnote}
                    entryMethods={entryMethods}
                    salaryDescription={firstEntryMethod?.salaryInformation?.salary?.sidebarDescription}
                    salary={salary}
                    isPriority={responseData.isPriority}
                    isPriorityText={responseData.isPriorityText}
                    isClosed={responseData.isClosed}
                    isClosedText={responseData.isClosedText ? responseData.isClosedText : ''}
                    applyUrl={responseData.applyUrl}
                    priorityToFill={responseData.priorityToFill}
                    salarySuper={responseData.salarySuper}
                    setOpenDrawer={setOpenDrawer}
                    oppositeUrl={responseData.oppositeUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {responseData.storyBannerHeader && responseData.storyBanner?.length ? (
        <section className="CAROUSEL">
          <div className="relative" data-cols="12">
            <div className="row">
              <div className="span-12">
                <h2 className="pull-quote-light mb-10 font-normal normal-case">{responseData.storyBannerHeader}</h2>
                {responseData.storyBanner
                  ?.slice(0, 1)
                  .map((storyBanner) => (
                    <StoryBanner key={storyBanner.id} id={storyBanner.id as string} fields={storyBanner.fields} />
                  ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {responseData.benefitsHeader1?.length && responseData.benefits?.length ? (
        <section className="BENEFITS">
          <div className="relative" data-cols="12">
            <div className="row">
              <div className="span-12 lg-span-4">
                <h2 className="pull-quote-light mb-10 font-normal normal-case">{responseData.benefitsHeader1}</h2>
              </div>
            </div>
            <div className="row">
              <div className="span-12 lg-span-4">
                <h3 className="h5 mb-10 normal-case">{responseData.benefitsHeader2}</h3>
              </div>
              <div className="span-12 lg-span-7 lg-offset-1">
                <div className="ICON-POINTS">
                  <div className="flex w-full flex-wrap">
                    {responseData.benefits.map((benefit, i: number) => {
                      return benefit.icon ? (
                        <div className="mb-5 w-full md:mb-10 md:w-1/2 lg:pr-20" key={i}>
                          <ListIcon
                            iconName={benefit.icon}
                            iconType="content"
                            text=""
                            titleText={benefit.descrition}
                            isHorizontal={lessThanMd}
                            service={responseData.service}
                          />
                        </div>
                      ) : null
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {pathway ? (
        <section className="PATHWAY">
          <div className="relative" data-cols="12">
            <div className="row">
              <div className="span-12 lg-span-4">
                <h3 className="pull-quote-light mb-10 font-normal normal-case">{pathway.title}</h3>
              </div>
              <div className="span-12 lg-span-7 lg-offset-1">
                <div className="ICON-POINTS">
                  <div className="flex w-full flex-wrap">
                    <RichText className="text-lbody [&_*+p]:mt-[1lh]" field={{ value: pathway.description }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {priorityToFill ? (
        <Drawer title="Priority to Fill Role" show={openDrawer} hideFooter closeDrawer={() => setOpenDrawer(false)}>
          <div className="flex flex-col gap-4 text-grey-medium">
            <Header text={priorityToFill.header} />
            <p>
              <strong className="text-black">{priorityToFill.subHeader}</strong>
            </p>
            <div className="mb-2" dangerouslySetInnerHTML={{ __html: priorityToFill.description }} />

            <PriorityQuickLink link={priorityToFill.navyLink} />
            <PriorityQuickLink link={priorityToFill.armyLink} />
            <PriorityQuickLink link={priorityToFill.airForceLink} />
            <div className="my-2" dangerouslySetInnerHTML={{ __html: priorityToFill.footer }} />
            <PriorityQuickLink link={priorityToFill.assistanceLink} />

            <Feedback
              id="priority-jobs-drawer"
              title="Was this helpful?"
              buttonNegativeText="Could be better"
              buttonPositiveText="Yes, very"
              service={responseData.service}
            />
          </div>
        </Drawer>
      ) : null}
    </JobTabPanel>
  )
}

export default JobRole
