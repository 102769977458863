type Item = string | number | Date | boolean | Record<string, unknown>

export function compareArrays<T extends Item>(
  array1: T[],
  array2: T[],
  config: {
    keys?: T extends Record<string, unknown> ? (keyof T)[] : never
    ignoreOrder?: boolean
  } = {}
): boolean {
  if (array1.length !== array2.length) {
    return false
  }

  try {
    const arr1 = array1.slice()
    const arr2 = array2.slice()
    if (config.ignoreOrder) {
      sortArrays(arr1, arr2, config.keys)
    }

    if (typeof arr1[0] === 'object' && typeof arr2[0] === 'object') {
      return compareObjectArrays(arr1, arr2, config.keys)
    } else {
      for (let i = 0; i < arr1.length; i++) {
        const aVal = arr1[i]
        const bVal = arr2[i]
        if (aVal instanceof Date && bVal instanceof Date) {
          if (aVal.getTime() !== bVal.getTime()) {
            return false
          }
        }
        if (aVal !== bVal) {
          return false
        }
      }
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    return false
  }

  return true
}

function compareObjectArrays<T extends Item>(array1: T[], array2: T[], keys?: (keyof T)[]) {
  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i]
    const obj2 = array2[i]

    if (typeof obj1 === 'object' && typeof obj2 === 'object') {
      if (obj1 instanceof Date && obj2 instanceof Date) {
        if (obj1.getTime() !== obj2.getTime()) {
          return false
        }
      } else if (obj1 !== null && obj2 !== null) {
        const objKeys = (keys as (keyof T)[]) || Object.keys(obj1)
        for (const key of objKeys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
      }
    } else {
      if (obj1 !== obj2) {
        return false
      }
    }
  }
  return true
}

export function sortArrays<T extends Item>(
  array1: T[],
  array2: T[],
  keys?: T extends Record<string, unknown> ? (keyof T)[] : never
): [T[], T[]] {
  const compareRecords =
    typeof array1[0] === 'object' && typeof array2[0] === 'object'
      ? (a: T, b: T): number => {
          const k = (keys as (keyof T)[]) || Object.keys(array1[0])
          for (const key of k) {
            const aVal = a[key]
            const bVal = b[key]

            if (typeof aVal === 'string' && typeof bVal === 'string') {
              const result = aVal.localeCompare(bVal)
              if (result !== 0) {
                return result
              }
            } else if (typeof aVal === 'number' && typeof bVal === 'number') {
              const result = aVal - bVal
              if (result !== 0) {
                return result
              }
            } else if (aVal instanceof Date && bVal instanceof Date) {
              const result = aVal.getTime() - bVal.getTime()
              if (result !== 0) {
                return result
              }
            } else if (typeof aVal === 'boolean' && typeof bVal === 'boolean') {
              if (aVal === bVal) {
                continue
              } else if (aVal) {
                return 1
              } else {
                return -1
              }
            } else {
              return 0
            }
          }
          return 0
        }
      : undefined

  array1.sort(compareRecords)
  array2.sort(compareRecords)

  return [array1, array2]
}
