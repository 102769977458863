import { useAudioPlayerProgress } from './useAudioPlayerProgress'

type AudioPlaytimeProps = {
  player: HTMLAudioElement | null
}

export const formatTime = (time: number) =>
  [
    Math.floor(time / 60).toString(),
    Math.floor(time % 60)
      .toString()
      .padStart(2, '0'),
  ].join(':')

function AudioPlaytime({ player }: AudioPlaytimeProps) {
  const [progress] = useAudioPlayerProgress(player)

  return <>{formatTime(progress)}</>
}

export default AudioPlaytime
