import { useEffect, useRef, useState } from 'react'
import YouTube, { YouTubePlayer, YouTubeProps } from 'react-youtube'

import Button from 'components/front-end/Buttons/Button'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import VideoModal, { CustomModalRef } from 'components/front-end/Video/VideoModal'
import { useIntersect } from 'src/utils/intersection'
import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'
import { useService } from 'src/utils/useService'

type VideoProps = {
  image?: string
  imageAlt?: string
  title?: string
  service?: string
  black?: boolean
  compact?: boolean
  background?: boolean
  type?: string
  overlay?: boolean
  id: string
}

const VideoBanner = ({
  image,
  imageAlt,
  title,
  service,
  black = false,
  compact = false,
  background = false,
  type = 'page',
  overlay = true,
  id,
}: VideoProps): JSX.Element => {
  service = useService(service)

  const [playerElement, setPlayerElement] = useState<YouTubePlayer | null>(null)
  const modalRef = useRef<CustomModalRef>()

  const onBackgroundReady: YouTubeProps['onReady'] = (event) => {
    // Access to player in all event handlers via event.target
    setPlayerElement(null)
    setTimeout(() => setPlayerElement(event.target), 200)
  }

  useEffect(
    () => () => {
      if (typeof playerElement?.destroy() === 'function') playerElement.destroy()
      setPlayerElement(null)
    },
    []
  )

  const onPlaybackEnd = () => {
    playerElement?.seekTo(0)
    playerElement?.playVideo()
  }
  const inViewPlayer = useRef<HTMLDivElement | null>(null)
  const entryIntersect = useIntersect(inViewPlayer, '-10px -10px')

  useEffect(() => {
    // Play / Pause the video when in or out of view
    const entry = entryIntersect?.intersectionRatio ? entryIntersect?.intersectionRatio * 100 > 10 : false
    if (playerElement && entry) {
      if (typeof playerElement.playVideo === 'function' && playerElement.getPlayerState() !== 1) {
        setTimeout(() => playerElement?.playVideo(), 500)
      }
    } else if (playerElement !== null && playerElement.getPlayerState() === 1) {
      playerElement?.pauseVideo()
    }
  }, [entryIntersect])

  const callback = () => {
    playerElement?.playVideo()
  }

  const bgOpts: YouTubeProps['opts'] = {
    height: '1280',
    width: '720',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 1,
      modestbranding: 1,
      loop: 1,
      playsinline: 1,
      mute: 1,
      controls: 0,
      // origin: window.location.origin, // Doesn't seem to be working POST MESSAGE error
    },
    playlist: id,
  }

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {xm: 1280}
  // {xl: 1440}
  // {xxl: 1920}

  const videoClasses = `
      video-placeholder relative
    `
  const pageClasses = `
      w-full h-auto
    `
  const videoOverlayClasses = `
    absolute bottom-0 left-0 h-full flex justify-center items-center w-full
    px-12
    md:px-14
    xm:px-12
    xl:px-16
    ${!compact ? 'xm:pb-12 xl:pb-16 xm:justify-between xm:bottom-0 xm:h-auto xm:bg-black/60' : ''}
    [&_.button-adf]:shrink-0
    ${
      useLessThan('xm') || compact
        ? '[&_.primary-icon_svg]:size-6 [&_.primary-icon]:size-14 [&_.primary-icon]:md:size-18 [&_.primary-icon]:outline-white [&_.primary-icon]:outline [&_.primary-icon]:outline-1'
        : ''
    }
  `
  const videoGradientClasses = `
      video-gradient
      ${!compact ? 'absolute left-0 -top-1/2 xm:-top-3/4 h-3/4 w-full bg-gradient-to-t from-black/60' : ''}
      pointer-events-none
    `
  const backgroundClasses = `
      absolute top-0 left-0 right-0 bottom-0 pointer-events-none
      [&_iframe]:absolute [&_iframe]:top-0 [&_iframe]:left-0 [&_iframe]:w-full [&_iframe]:h-full
    `
  const overlayCopyClasses = `
      dark [&_h3]:text-white
      w-full
      xm:w-auto
      [&_.service-tag-container]:mb-2
    `

  const overlayElement = (adClasses = '') => {
    return (
      <div className={`${overlayCopyClasses} ${adClasses}`}>
        <ServiceTag service={service} />
        <h3>{title}</h3>
      </div>
    )
  }
  // console.log('title', title)

  return (
    <>
      <div className={`${videoClasses} ${pageClasses}`} ref={inViewPlayer}>
        <div className="div">
          {useLessThan('xm') && !compact && !background && overlay && overlayElement('px-6 pb-6')}
          <div className="relative">
            <div className="video-container">
              <ImageLoader
                src={image || ''}
                alt={imageAlt || ''}
                height={56.25}
                width={100}
                sizes="(max-width: 1023px) 100vw, 60vw"
              />
              {type === 'story' && (
                <YouTube
                  className={backgroundClasses}
                  videoId={id}
                  opts={bgOpts}
                  onReady={onBackgroundReady}
                  onEnd={onPlaybackEnd}
                />
              )}
            </div>
            <div className={videoOverlayClasses}>
              {!background && <div className={videoGradientClasses}></div>}
              {useGreaterThan('xm') && !compact && !background && overlay && overlayElement()}

              {!background && (
                <Button
                  data-trackingid={id}
                  link={{ value: { text: '' } }}
                  icon="play"
                  type="primary-icon"
                  solid
                  button
                  onClick={() => {
                    if (modalRef.current) {
                      modalRef.current.openModal()
                      playerElement?.current?.pauseVideo()
                    }
                  }}
                  service={black ? 'black' : service}
                  aria-label="Play video"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <VideoModal id={id} ref={modalRef} videoId={id} callback={callback} service={service} />
    </>
  )
}

export default VideoBanner
