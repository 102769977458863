import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface JobTabPanelProps {
  className?: string
  children: ReactNode
  id: string
}

function JobTabPanel({ id, className, children }: JobTabPanelProps) {
  return (
    <div
      id={id}
      role="tabpanel"
      aria-labelledby={id.replace('tabpanel', 'tab')}
      className={twMerge('mb-20 flex flex-col gap-y-20', className)}
    >
      {children}
    </div>
  )
}

export default JobTabPanel
