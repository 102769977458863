import React from 'react'
import { Field } from '@sitecore-jss/sitecore-jss-nextjs'

import { DetailProps } from 'components/ADFEventDetails'
import Button from 'components/front-end/Buttons/Button'
import FAQProvider from 'components/front-end/FAQ/FAQContext'
import FAQTopicList from 'components/front-end/FAQ/FAQTopicList'
import { FAQData } from 'components/front-end/FAQ/FAQTypes'
import Icon from 'components/front-end/Icons/Icon'
import { isMultiDayEvent, timezoneLongName } from 'components/front-end/Shared/Utils'
import TitleText from 'components/front-end/TextBlocks/TitleText'
import { Service } from 'src/utils/useService'
import ClosedNotification from './ClosedNotification'
import DeliveryAllocated from './DeliveryAllocated'
import { attendanceMethodCopy } from './EventDetailConst'

type DetailTabProps = DetailProps & {
  faqs: {
    EventFaqTitle: Field<string>
    EventFaqText: Field<string>
    FaqTopicListTitle: Field<string>
    items: FAQData
  }
}

const DetailsTab: React.FC<DetailTabProps> = ({ event, faqs, openEventDrawer, deliveryAllocated }) => {
  const eventDates = [event.EventStartDateFormattedText]
  if (isMultiDayEvent(event.EventStartDate, event.EventEndDate)) {
    eventDates.push(event.EventEndDateFormattedText)
  }
  return (
    <div className="container-grid ">
      <div className="row ">
        <div className="span-12 md-span-4 component rich-text">
          <h2 className="h4 mb-6 normal-case">Event details</h2>
          <div className="flex flex-col gap-6">
            <div dangerouslySetInnerHTML={{ __html: event.EventContent }} />
            {event.EventCanRegister ? (
              event.EventEnableExternalRegistration && event.EventExternalRegistrationUrl ? (
                <>
                  <span className="mt-4">
                    <Button
                      data-trackingid={event.Id}
                      type="primary"
                      service={event.EventSiteDetailDisplayName}
                      link={{ value: { href: event.EventExternalRegistrationUrl, text: 'Register Now' } }}
                      icon="chevron-right-double"
                      className="shrink-1"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </span>
                  {deliveryAllocated && (
                    <DeliveryAllocated allocated={deliveryAllocated} service={event.EventSiteDetailDisplayName} />
                  )}
                </>
              ) : (
                <>
                  <span className="mt-4">
                    <Button
                      data-trackingid={event.Id}
                      type="primary"
                      service={event.EventSiteDetailDisplayName}
                      link={{ value: { href: '#', text: 'Register Now' } }}
                      icon="chevron-right-double"
                      button={true}
                      className="shrink-1"
                      onClick={() => openEventDrawer()}
                    />
                  </span>
                  {deliveryAllocated && (
                    <DeliveryAllocated allocated={deliveryAllocated} service={event.EventSiteDetailDisplayName} />
                  )}
                </>
              )
            ) : (
              <ClosedNotification
                className="mt-10"
                isClosed={event.EventStatus === 'Closed'}
                service={event.EventSiteDetailDisplayName}
              />
            )}
          </div>
        </div>
        <div className="sm-span-12 md-span-7 md-offset-1">
          <h3 className="h6 my-6 normal-case">Important information</h3>
          <ul className="mr-auto text-black">
            <li className="flex border-y border-grey-light py-4">
              <Icon name="calendar" type="stroke" width={18} height={18} className="mt-0.5 shrink-0 text-black" />
              <div className="ml-2 flex flex-col">
                <strong>{eventDates.join(' - ')}</strong>
                {event && (
                  <a
                    href={`${process.env.HUB_API_HOST}/api/v1/eventfinder/events/calendar/${event.Id}`}
                    className="link-underline mr-auto text-left font-semibold text-grey-medium"
                    onClick={(e) => {
                      e.preventDefault
                    }}
                  >
                    Add this Event to your calendar
                  </a>
                )}
              </div>
            </li>

            {event.EventVirtualTimes && Object.values(event.EventVirtualTimes).length > 1 ? (
              <li className="flex border-b border-grey-light py-4">
                <Icon name="clock" type="stroke" width={18} height={18} className="mt-0.5 shrink-0 text-black" />
                <div className="ml-2 flex flex-col">
                  {event.EventVirtualTimes.map((time: string, i: number) => (
                    <strong key={i}>{time}</strong>
                  ))}
                </div>
              </li>
            ) : (
              <li className="flex border-b border-grey-light py-4">
                <Icon name="clock" type="stroke" width={18} height={18} className="mt-0.5 shrink-0 text-black" />
                <div className="ml-2 flex flex-col">
                  <strong>
                    {event.EventStartTime} - {event.EventEndTime}
                  </strong>
                  <span className="text-grey-medium">({timezoneLongName(event.EventTimeZoneName)})</span>
                </div>
              </li>
            )}

            {(event?.EventLocationType === 'Hybrid' || event?.EventLocationType === 'Virtual') && (
              <li className="flex border-b border-grey-light py-4">
                <Icon name="computer" type="stroke" width={18} height={18} className="mt-0.5 shrink-0 text-black" />
                <div className="ml-2 flex flex-col">
                  <strong>
                    {attendanceMethodCopy[event.EventLocationType as keyof typeof attendanceMethodCopy].short} event
                  </strong>
                  <p className="text-grey-medium">
                    {attendanceMethodCopy[event.EventLocationType as keyof typeof attendanceMethodCopy].long}
                  </p>
                </div>
              </li>
            )}
            {event.EventLocationType !== 'Virtual' && (
              <li className="flex border-b border-grey-light py-4 text-black">
                <Icon name="location" type="stroke" width={18} height={18} className="mt-0.5 shrink-0" />
                <div className="ml-2 flex flex-col">
                  {event.EventLocation?.Name ? (
                    <>
                      <strong className="block">{event.EventLocation?.Name}</strong>
                      <p className=" text-grey-medium">
                        {event.EventLocation.Line1}, {event.EventLocation.Line2 ? event.EventLocation.Line2 + ', ' : ''}{' '}
                        {event.EventLocation.Line3 ? event.EventLocation.Line3 + ', ' : ''} {event.EventLocation.City},{' '}
                        {event.EventLocation.State} {event.EventLocation.PostCode}
                      </p>
                    </>
                  ) : (
                    <span>{event.EventVenue}</span>
                  )}
                  <a
                    className="link-underline mr-auto inline-flex items-center gap-2 font-semibold text-grey-medium"
                    href={`https://www.google.com/maps?q=${event.EventLocation.Line1}, ${event.EventLocation.Line2 ? event.EventLocation.Line2 + ', ' : ''} ${event.EventLocation.Line3 ? event.EventLocation.Line3 + ', ' : ''} ${event.EventLocation.City}, ${event.EventLocation.State} ${event.EventLocation.PostCode}`}
                    target={'_blank'}
                  >
                    <span>Get Directions</span>{' '}
                    <Icon name="open-new" type="stroke" width={16} height={16} className="shrink-0" />
                  </a>
                </div>
              </li>
            )}
          </ul>
          {(event.EventContactName || event.EventContactPhone || event.EventContactEmail) && (
            <>
              <h3 className="h6 mb-6 mt-10 normal-case">Contact information</h3>
              <ul className="text-black">
                {event.EventContactName && (
                  <li className="flex content-center items-center border-y border-grey-light py-4 ">
                    <Icon name="author" type="stroke" width={18} height={18} className="shrink-0 " />
                    <div className="ml-2">
                      <strong>{event.EventContactName}</strong>
                    </div>
                  </li>
                )}
                {event.EventContactPhone && (
                  <li className="flex content-center items-center border-b border-grey-light py-4 ">
                    <Icon name="phone" type="stroke" width={18} height={18} className="shrink-0 " />
                    <div className="ml-2">
                      <strong>{event.EventContactPhone}</strong>
                    </div>
                  </li>
                )}
                {event.EventContactEmail && (
                  <li className="flex content-center items-center border-b border-grey-light py-4 ">
                    <Icon name="email" type="stroke" width={18} height={18} className="shrink-0" />
                    <div className="ml-2">
                      <a className="link-underline font-semibold" href={`mailto:${event.EventContactEmail}`}>
                        Contact {event.EventContactName} via Email
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
      </div>

      {faqs && faqs.items && (
        <div className="row my-32">
          <div className="span-12 md-span-4 mb-6 md:mb-0">
            <TitleText
              id={event.Id}
              HeadingStyle="h4"
              title={faqs.EventFaqTitle.value || 'Questions'}
              text={faqs.EventFaqText.value || 'See answers to the most commonly asked questions'}
            />
          </div>
          <div className="span-12 md-offset-1 md-span-7">
            <FAQProvider data={faqs.items} service={event.EventSiteDetailSystemName as Service}>
              <FAQTopicList title={faqs.FaqTopicListTitle?.value || 'Ëvent FAQs'} />
            </FAQProvider>
          </div>
        </div>
      )}
    </div>
  )
}

export default DetailsTab
