import React from 'react'
import { Image, ImageFieldValue, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import ImageLoader from '../Images/ImageLoader'

type HeaderImageProps = {
  image?: ImageFieldValue
  className?: string
  width?: number
  height?: number
}

export const HeaderImage = ({ image, className, width, height }: HeaderImageProps) => {
  const { sitecoreContext } = useSitecoreContext()

  if (!image?.src) {
    return null
  }

  const imageProps = {
    width: width ?? image.width,
    height: height ?? image.height,
    alt: image.alt || '',
  } as { width: number; height: number; alt: string }

  return (
    <div className={className}>
      {sitecoreContext.pageEditing ? (
        <Image field={image} {...imageProps} />
      ) : (
        <ImageLoader src={image.src} {...imageProps} priority sizes="100vw" />
      )}
    </div>
  )
}
