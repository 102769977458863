'use client'

import { useEffect, useMemo, useRef, useState } from 'react'
import { Image as JssImage } from '@sitecore-jss/sitecore-jss-nextjs'
import { createPortal } from 'react-dom'
import { twJoin } from 'tailwind-merge'

import usePageContext from 'components/front-end/_layoutContext'
import AudioTranscript from 'components/front-end/Audio/AudioTranscript'
import Icon from 'components/front-end/Icons/Icon'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import { track } from 'src/utils/tracking'
import { useLessThan } from 'src/utils/useBreakpoints'
import { AudioGroupProps, AudioProps } from './_AudioInterface'
import AudioPlayerBar from './AudioPlayerBar'
import AudioPlaytime from './AudioPlaytime'
import { useAudioPlayerProgress } from './useAudioPlayerProgress'

const AudioPlayer = ({
  url,
  name,
  role,
  image,
  alt = '',
  group = false,
  id,
  story = false,
  audioTranscript,
  service,
  playerRef,
}: AudioProps) => {
  const { audio, isEdit } = usePageContext().pageContext

  const ref = useRef<HTMLAudioElement>(null)
  const audioRef = playerRef || ref

  const firstLoadRef = useRef(true)

  const [isBarReady, setIsBarReady] = useState(false)

  const [scriptSoloReady, setScriptSoloReady] = useState(false)
  const [scriptSoloOpen, setScriptSoloOpen] = useState(false)

  const [, playerState] = useAudioPlayerProgress(audioRef.current, 10)
  const isPlaying = playerState === 'playing'

  const indexInPlaylist = useMemo(
    () => audio.audioPlayList.findIndex((item: AudioGroupProps) => item.id === id),
    [id, audio.audioPlayList]
  )

  const playlistItem = useMemo(
    () =>
      group
        ? {
            audioRef,
            startPlayer: () => undefined,
            stopPlayer: () => undefined,
            id,
            name,
            role,
            image,
            audioTranscript,
          }
        : undefined,
    [group, audioRef, id, name, role, image, audioTranscript]
  )

  const startStopAudio = () => {
    const player = audioRef.current

    if (!player) return

    if (player.paused) {
      if (group) {
        setScriptSoloOpen(true)
        setTimeout(() => audio.setAudioPlayActive(indexInPlaylist), 10)
      }
      player.play()
    } else {
      player.pause()
    }
  }

  const openTranscript = () => {
    if (group) {
      audio.setAudioPlayActive(indexInPlaylist)
      setTimeout(() => audio.setIsScriptOpen(!audio.isScriptOpen), 10)
    } else {
      setScriptSoloOpen(!scriptSoloOpen)
    }
  }

  useEffect(() => {
    if (firstLoadRef.current && playlistItem) {
      firstLoadRef.current = false

      audio.setAudioPlayList((playlist) => {
        const isIdFound = playlist.find((item: AudioGroupProps) => item.id === id)
        return isIdFound ? playlist : [...playlist, playlistItem]
      })

      setIsBarReady(true)
    } else {
      setScriptSoloReady(true)
    }
  }, [id, audio, playlistItem])

  useEffect(() => {
    const audioElement = audioRef.current

    if (!audioElement) {
      return
    }

    const handlePlayerEnd = (): void => {
      if (audioRef.current) audioRef.current.currentTime = 0
    }

    audioElement.addEventListener('ended', handlePlayerEnd)

    return () => {
      audioElement.removeEventListener('ended', handlePlayerEnd)
    }
  }, [audioRef])

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play()
    } else {
      audioRef.current?.pause()
    }
  }, [audioRef, isPlaying])

  return (
    <div
      className={twJoin(
        'audio-player light z-[49] flex w-[235px] min-w-[235px] border border-grey-light bg-white lg:h-[104px]',
        !story && 'w-full md:w-[235px]'
      )}
    >
      {isEdit ? (
        <JssImage field={{ src: image }} />
      ) : (
        <div className="inner-left relative min-h-[83px] min-w-[67px] shrink-0 p-[2px] lg:h-full lg:w-[104px]">
          <div className="relative size-full">
            <ImageLoader
              src={image}
              wrapperClassName="aspect-[--aspect-ratio]"
              className="img"
              alt={alt}
              width={useLessThan('lg') ? 67 : 100}
              height={useLessThan('lg') ? 83 : 98}
              sizes="(max-width: 1024px) 100px, 67px"
              fixed
            />
            <div
              className={twJoin(
                'absolute top-0 flex h-full w-full items-end justify-center bg-gradient-to-t from-black/60 from-10% via-black/0 to-black/0 pb-2 text-white transition duration-300',
                isPlaying && 'bg-black/30'
              )}
            >
              <div
                className={twJoin(
                  'headphones-icon absolute transition-all duration-300',
                  !isPlaying ? 'delay-300' : '-translate-y-3 opacity-0  delay-100'
                )}
              >
                <Icon name="headphones" type="stroke" className="size-8" />
              </div>
              <div
                className={twJoin(
                  'sound-icon absolute transition-all duration-300',
                  isPlaying ? 'loop delay-300' : 'translate-y-3 opacity-0'
                )}
              >
                <Icon name="sound" type="stroke" hover className="size-10" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="inner-right flex w-full flex-col justify-center overflow-hidden px-3 lg:px-4">
        <p className="name mb-1 text-sbody !text-black lg:text-mbody">{name}</p>
        <p className="role mtag mb-[5px] line-clamp-2 overflow-hidden text-ellipsis !text-black lg:mb-[13px]">{role}</p>
        <div className="controls flex justify-between">
          <button
            className="flex !text-black"
            onClick={() => {
              startStopAudio()
              track({
                event: 'audio_interaction',
                ga4name: `${name} - ${isPlaying ? 'pause' : 'play'}`,
              })
            }}
          >
            <Icon name={isPlaying ? 'pause' : 'play'} className="-ml-1.5 mr-2 size-4 lg:ml-[-5px]" />
            <div className="mtag !text-black">
              <AudioPlaytime player={audioRef.current} />
            </div>
          </button>

          <button
            className="mtag ml-auto flex size-4 items-center justify-center border-2 border-black"
            onClick={() => {
              openTranscript()
            }}
            aria-label="Open transcript"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path
                d="M1.69954 6.32342C1.69954 6.77957 1.95205 7.03614 2.3308 7.03614C2.70956 7.03614 2.96206 6.77957 2.96206 6.32342V5.89579H4.61305V6.2569C4.61305 7.62533 3.62246 8.5091 2.31138 8.5091C0.971168 8.5091 0 7.66334 0 6.29492L0 3.91919C0 2.55076 1.01002 1.66699 2.35023 1.66699C3.66131 1.66699 4.61305 2.51275 4.61305 3.88117V4.18527H2.96206V3.85266C2.96206 3.39652 2.70956 3.13995 2.3308 3.13995C1.95205 3.13995 1.69954 3.39652 1.69954 3.85266V6.32342Z"
                fill="black"
              />
              <path
                d="M7.08649 6.32342C7.08649 6.77957 7.339 7.03614 7.71775 7.03614C8.09651 7.03614 8.34901 6.77957 8.34901 6.32342V5.89579H10V6.2569C10 7.62533 9.00941 8.5091 7.69833 8.5091C6.35812 8.5091 5.38695 7.66334 5.38695 6.29492V3.91919C5.38695 2.55076 6.39697 1.66699 7.73718 1.66699C9.04825 1.66699 10 2.51275 10 3.88117V4.18527H8.34901V3.85266C8.34901 3.39652 8.09651 3.13995 7.71775 3.13995C7.339 3.13995 7.08649 3.39652 7.08649 3.85266V6.32342Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>
      <audio ref={audioRef}>
        <source src={url} type="audio/mpeg" />
      </audio>
      {group &&
        isBarReady &&
        audio.audioPlayList[0]?.id === id &&
        createPortal(<AudioPlayerBar id={id} />, document.body)}
      {!group &&
        scriptSoloReady &&
        createPortal(
          <AudioTranscript
            id={id}
            group={false}
            transcript={audioTranscript}
            open={scriptSoloOpen}
            onClick={() => setScriptSoloOpen(false)}
            story={story}
            service={service}
          />,
          document.body
        )}
    </div>
  )
}

export default AudioPlayer
