import { useState } from 'react'

type ToggleButtonProps = {
  enabled: boolean
  onToggle?: () => void
}

const ToggleButton = ({ enabled, onToggle }: ToggleButtonProps): JSX.Element => {
  const [isEnabled, setIsEnabled] = useState(enabled)
  const innerEllipsisClasses = 'inner-ellipsis bg-white w-5 h-5 ml-[2px]'

  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault()
        setIsEnabled(!isEnabled)
        onToggle?.()
      }}
      className={'adf-button-toggle flex' + (isEnabled ? ' enabled' : '')}
    >
      <div className="outer-ellipsis flex h-6 w-10 items-center bg-grey">
        <div className={innerEllipsisClasses}></div>
      </div>
    </a>
  )
}

export default ToggleButton
