import React, { useEffect, useState } from 'react'
import { SwiperClass } from 'swiper/react'

import CarouselSwiperControls from './front-end/Carousel/CarouselSwiperControls'

import 'swiper/css'
import 'swiper/css/controller'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-fade'

import { Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import { useService } from 'src/utils/useService'

import QuoteAudioCarousel from './front-end/QuoteCarousel/QuoteAudioCarousel'
import QuoteImageCarousel from './front-end/QuoteCarousel/QuoteImageCarousel'
import QuoteSliderAudio from './front-end/QuoteCarousel/QuoteSlideAudio'
import QuoteSlideImage from './front-end/QuoteCarousel/QuoteSlideImage'
import QuoteSlideText from './front-end/QuoteCarousel/QuoteSlideText'
import QuoteTextCarousel from './front-end/QuoteCarousel/QuoteTextCarousel'

export type QuoteCarouselItem = {
  id: string
  url: string
  name: string
  displayName: string
  fields: {
    Image: ImageField
    Text: Field<string>
    TextStyle: Field<'Pull Quote' | 'Statement' | 'Statement CTA'> // Pull Quote, Statement, Statement CTA
    QuoteSource: Field<string>
    YoutubeId: Field<string>
    File: LinkField
    AudioImage: ImageField
    Name: Field<string>
    JobTitle: Field<string>
    AudioTranscript: Field<string>
    Title: Field<string>
    CTAButton: LinkField
  }
}

type QuoteCarouselRendering = {
  componentName?: 'ADFQuoteCarousel'
  fields: {
    items: QuoteCarouselItem[]
  }
}

export type QuoteCarouselProps = {
  rendering: QuoteCarouselRendering
  service?: string
}

const ADFQuoteCarousel = ({ rendering: { fields }, service: servicePriority }: QuoteCarouselProps) => {
  const service = useService(servicePriority)
  const [textCarousel, setTextCarousel] = useState<SwiperClass | null>(null)
  const [audioCarousel, setAudioCarousel] = useState<SwiperClass | null>(null)
  const controlledCarousels = [textCarousel, audioCarousel].filter(Boolean) as SwiperClass[]
  const [item, setItem] = useState<QuoteCarouselItem | null>(null)
  const [items, setItems] = useState<QuoteCarouselItem[]>([])

  useEffect(() => {
    setItem(fields?.items?.length ? fields.items[0] : null)
    setItems(fields.items)
  }, [fields])

  if (!item) {
    return null
  }

  const enableCarousel = items.length > 1
  return (
    <div className="[[data-cols='12']_&]:relative [[data-cols='12']_&]:-mx-[--outermargin] [[data-cols='12']_&]:w-auto [[data-cols='12']_&]:max-w-fit">
      <div
        className={`relative grid w-full max-w-full grid-cols-1 gap-y-4 overflow-hidden md:gap-y-6 xm:w-auto xm:grid-cols-12 xm:items-stretch ${
          enableCarousel && 'xm:pb-24'
        }`}
      >
        <div className="xm:relative xm:col-span-7 xm:flex xm:h-full xm:items-end">
          <div className="flex w-full items-stretch justify-end xm:justify-stretch">
            {enableCarousel ? (
              <QuoteImageCarousel
                items={items}
                controlledCarousels={controlledCarousels}
                id={item.id}
                service={service}
              />
            ) : (
              <QuoteSlideImage image={item.fields.Image} videoId={item.fields.YoutubeId.value} service={service} />
            )}
          </div>
        </div>

        <div className="mr-[--outermargin] flex flex-1 flex-col gap-y-10 xm:col-span-5 xm:mx-0 xm:flex-col-reverse xm:justify-between xm:gap-y-0">
          <div className="flex flex-row-reverse justify-between">
            {enableCarousel ? (
              <div className="min-w-fit shrink-0 xm:absolute xm:bottom-0 xm:right-0 xm:mr-[--outermargin] [&>div]:flex-col [&>div]:-space-y-px [&>div]:space-x-0 [&>div]:md:flex-row [&>div]:md:-space-x-px [&>div]:md:space-y-0 ">
                <CarouselSwiperControls id={item.id} service={service} />
              </div>
            ) : null}
            <div
              className={`relative flex ${
                enableCarousel ? 'w-10/12' : 'w-full'
              } items-start xm:w-5/12 xm:flex-1 xm:flex-col xm:items-end xm:justify-end xm:gap-x-0`}
            >
              {enableCarousel ? (
                <QuoteTextCarousel items={items} onSwiper={setTextCarousel} id={item.id} service={service} />
              ) : (
                <div className=" ml-[--outermargin] xm:ml-0">
                  <QuoteSlideText id={item.id} fields={item.fields} service={service} />
                </div>
              )}
            </div>
          </div>
          <div className="relative flex w-full items-start xm:flex-1 xm:flex-col xm:items-start">
            {enableCarousel ? (
              <QuoteAudioCarousel items={items} onSwiper={setAudioCarousel} id={item.id} />
            ) : (
              <div className=" ml-[--outermargin] xm:ml-0">
                <QuoteSliderAudio id={item.id} fields={item.fields} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ADFQuoteCarousel
