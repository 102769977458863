import Image from 'next/image'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

import { trackNavigation } from 'utils/tracking'
import logoDark from '../../../../public/logos/logo-adfa-careers-dark.svg'
import logoLight from '../../../../public/logos/logo-adfa-careers-light.svg'

interface SiteLogoProps {
  className?: string
}

export default function SiteLogo({ className }: SiteLogoProps) {
  return (
    <Link
      className={twMerge('flex shrink-0 items-center', className)}
      href="/"
      // className={`${navActive ? 'opacity-0' : 'opacity-100'} xm:opacity-100`}
      title="ADF Careers"
      onClick={() =>
        trackNavigation({
          location: 'header',
          path: 'logo',
        })
      }
    >
      <Image
        className="block w-[133px] max-w-full md:w-min [.dark_&]:hidden [.grey_&]:hidden [.light_&]:block"
        src={logoLight}
        alt="ADF Careers homepage"
      />
      <Image
        className="hidden w-[133px] max-w-full md:w-min [.dark_&]:block [.grey_&]:block [.light_&]:hidden"
        src={logoDark}
        alt="ADF Careers homepage"
      />
    </Link>
  )
}
