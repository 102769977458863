import { FillIcons } from 'components/front-end/Icons/icon-data'
import Icon from '../Icons/Icon'

type LabelIconProps = {
  text: string
  theme: 'light' | 'dark'
  icon: FillIcons
  isSmall?: boolean
}

const LabelIcon = ({ text, theme, icon, isSmall = false }: LabelIconProps): JSX.Element => {
  const textColor = theme === 'light' ? 'text-black' : 'text-white label-icon-fill-white'
  const textClass = isSmall ? 'xsbody' : 'sbody'
  const size = isSmall ? 11 : 14
  const paddingLeftAmt = isSmall ? 'pl-1' : 'pl-[5px]'
  return (
    <div className="label-icon flex items-center">
      <Icon name={icon} type="fill" width={size} height={size} className={`${textColor}`} />
      <div className={`${textClass} ${textColor} ${paddingLeftAmt}`}>{text}</div>
    </div>
  )
}

export default LabelIcon
