import { Field } from '@sitecore-jss/sitecore-jss-nextjs'

import Button from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'
import { StorySlideProps } from './StorySlide'

const ListsPanel = ({ id, service, content }: StorySlideProps) => {
  if (!content) {
    return null
  }

  const listPanelClasses = `
    list-panel bg-black-off dark
    flex flex-col justify-center
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[1080px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[1380px]
    xxl:py-[175px] xxl:px-[120px] xxl:max-w-[1456px]
    [&_svg]:self-start [&_svg]:mt-[3px]
  `

  const listItems = Array.from({ length: 10 }, (_, i) => {
    const itemNumber = i + 1
    const listItemValue = (content[`ListItem${itemNumber}` as keyof typeof content] as Field<string>).value

    if (!listItemValue) {
      return null
    }

    return (
      <li
        key={itemNumber}
        data-pos={itemNumber}
        className="flex w-full gap-6 border-b border-grey-light py-5 dark:border-grey-medium"
      >
        <span>
          <Icon name="check-circle" type="stroke" width={24} height={24} />
        </span>
        <p>{listItemValue as string}</p>
      </li>
    )
  }).filter(Boolean) as React.JSX.Element[]

  return (
    <div className={listPanelClasses}>
      <h3 className="h6 mb-8 mt-auto capitalize text-white">{content?.Heading.value as string}</h3>
      <ol className="mbody text-grey-medium dark:text-white lg:columns-2 lg:grid-cols-2 [&_svg]:shrink-0">
        {listItems}
      </ol>
      {content.ListButtonLink.value?.href && (
        <div className="button-row pt-12">
          <Button
            data-trackingid={id}
            icon="arrow-north-east"
            link={{
              value: content.ListButtonLink.value,
            }}
            type="primary"
            service={service}
          />
        </div>
      )}
    </div>
  )
}

export default ListsPanel
