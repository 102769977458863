import { KeyboardEvent, MouseEvent, useRef } from 'react'
import { useRouter } from 'next/router'

import { trackFAQ, trackSearch } from 'src/utils/tracking'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import Icon from '../Icons/Icon'
import SearchInput from '../Search/SearchInput'
import SearchInputResults from '../Search/SearchInputResults'
import { useFAQContext } from './FAQContext'
import FAQList, { FAQListGroup } from './FAQList'
import FAQMobileBrowser from './FAQMobileBrowser'
import FAQSubcategoryItem from './FAQSubcategoryItem'
import FAQSubcategoryList, { DefaultSubcategory } from './FAQSubcategoryList'
import { FAQ } from './FAQTypes'

type FAQSearchProps = {
  label: string
  placeholder: string
  subcategoriesTitle: string
  noResults?: React.ReactNode
  minQueryLength?: number
}

export const isDefaultSubcategory = (id: string) => id === 'default'

const FAQSearch = ({ subcategoriesTitle, noResults, minQueryLength = 3, ...inputProps }: FAQSearchProps) => {
  const desktopMode = useGreaterThan('xm')
  const faqTopicsRef = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const {
    state: {
      query,
      results,
      previousSearch,
      selectedFaqId,
      selectedCategoryId,
      selectedSubcategoryId,
      selectedHeading,
    },
    send,
    lookup,
  } = useFAQContext()

  const defaultSubcategory: DefaultSubcategory = {
    title: `Results for “${previousSearch.query}”`,
    count: previousSearch.results.length,
    id: 'search-results',
    isSelected:
      selectedSubcategoryId === 'search-results' ||
      (!!previousSearch.results.length && desktopMode && !selectedSubcategoryId && !selectedHeading),
  }

  const activeSubcategoryId = selectedSubcategoryId || (desktopMode ? defaultSubcategory.id : selectedSubcategoryId)

  const previousSearchFAQIds = previousSearch.results.map((faq) => faq.id)

  const selectedCategory =
    lookup.categories[selectedCategoryId] ?? desktopMode ? Object.values(lookup.categories)[0] : undefined

  const faqIds = selectedCategory?.id ? lookup.faqsInCategory(selectedCategory?.id) : []
  const faqs = faqIds.map((id) => lookup.faqs[id]).filter((faq) => !selectedHeading || faq.heading === selectedHeading)
  const headings = lookup.headingsForFAQs(faqIds)

  const handleChangeQuery = (query: string): void => {
    send({ type: 'UPDATE_QUERY', payload: query })

    if (selectedFaqId) {
      send({ type: 'DESELECT_FAQ' })
    }
  }

  const handleReset = () => {
    send({ type: 'CLEAR_QUERY' })
    send({ type: 'CLEAR_SEARCH' })
  }

  const handleClickFAQ = ({ id, ...faq }: FAQ): void => {
    trackFAQ({ id: `${id}-search`, ...faq })
    send({ type: 'SELECT_FAQ', payload: id })
  }

  const handleSelectHeading = (heading: string) => {
    if (typeof heading === 'string') {
      send({ type: 'SELECT_HEADING', payload: heading })
    }
  }

  const handleSubmitSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (results.length && (e.key === 'Enter' || e.key === 'Return')) {
      trackSearch(query, 'In-page', router.asPath)
      send({ type: 'SAVE_SEARCH' })
    }
  }

  const handleClickShowAll = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    send({ type: 'SAVE_SEARCH' })
    send({ type: 'SELECT_SUBCATEGORY', payload: defaultSubcategory.id })
  }

  return (
    <div className="mb-16 flex w-full flex-col gap-y-24">
      <div className="relative [&_.fixed]:sticky [&_.fixed]:mt-4 [&_.fixed]:w-full [&_.fixed]:px-6 md:[&_.fixed]:ml-0 md:[&_.fixed]:w-full xm:[&_.fixed]:px-4 [&_.search-list]:-mx-[--outermargin] [&_.search-list]:w-screen [&_.search-list]:translate-x-0 [&_.search-list]:px-0 [&_.search-list]:md:mx-0 [&_.search-list]:md:w-full xm:[&_.search-list]:px-6 [&_.search-match]:mx-0 [&_.search-match]:h-fit [&_.search-match]:max-h-none [&_.search-match]:overflow-y-auto [&_.search-match]:md:max-h-[473px]">
        <SearchInput
          value={query}
          onChange={handleChangeQuery}
          autoFocus
          onKeyUp={handleSubmitSearch}
          searchPos={false}
          inPage={false}
          location={false}
          filter={undefined}
          resetClicked={handleReset}
          {...inputProps}
        />
        {query.length >= minQueryLength && query !== previousSearch.query ? (
          <SearchInputResults
            query={query}
            results={{
              TotalCount: results.length,
              Results: results.slice(0, 5),
            }}
            display={(result) => result.question}
            showTotal={results.length > 5}
            onClickTotal={handleClickShowAll}
            noResults={noResults}
          >
            {({ richText, item }) => (
              <div className="flex w-full">
                <button
                  className="search-item flex flex-1 cursor-pointer items-center gap-x-6 py-3 pl-[--outermargin] pr-[calc(var(--outermargin)+16px)] text-left text-black transition duration-200 hover:bg-white-off focus-visible:bg-white-off focus-visible:outline-none md:px-6 xm:px-4 [&_path]:stroke-2"
                  onClick={() => handleClickFAQ(item)}
                >
                  <div className="flex flex-1 flex-col items-stretch justify-center gap-y-0.5">
                    <span className="xstag text-grey-dark">FAQS</span>
                    <span dangerouslySetInnerHTML={{ __html: richText }} />
                  </div>
                  <Icon name="arrow-east" type="stroke" width={16} height={16} strokeWidth={4} />
                </button>
              </div>
            )}
          </SearchInputResults>
        ) : null}
      </div>

      {previousSearch.results.length ? (
        <section className="grid-cols-12 xm:grid">
          <div ref={faqTopicsRef} className="col-span-4 flex scroll-m-6 flex-col gap-y-8">
            <h6>{subcategoriesTitle}</h6>
            <FAQSubcategoryList
              defaultSubcategory={previousSearch.results.length ? defaultSubcategory : undefined}
              faqs={previousSearchFAQIds}
              activeSubcategoryId={activeSubcategoryId}
            />
          </div>
          <div className="col-span-7 col-start-6 hidden flex-col xm:flex">
            {defaultSubcategory.isSelected ? (
              <FAQListGroup
                heading={defaultSubcategory.title}
                faqs={previousSearch.results.map(({ subcategories, ...result }) => ({
                  ...result,
                  subcategories: [...subcategories, 'search-results'],
                }))}
              />
            ) : (
              <FAQList faqs={previousSearch.results} groupByHeading />
            )}
          </div>
        </section>
      ) : null}

      <FAQMobileBrowser open={Boolean(previousSearch.results.length && selectedSubcategoryId)}>
        {selectedSubcategoryId ? (
          selectedSubcategoryId === 'search-results' ? (
            <FAQListGroup heading={defaultSubcategory.title} faqs={previousSearch.results} />
          ) : (
            <FAQListGroup
              heading={lookup.subcategories[selectedSubcategoryId].faqSubcategoryTitle}
              faqs={lookup.faqsInSubcategory(selectedSubcategoryId).map((id) => lookup.faqs[id])}
            />
          )
        ) : selectedHeading ? (
          <FAQList faqs={faqs} groupByHeading />
        ) : selectedCategory ? (
          <>
            <h6>{selectedCategory.faqCategorySubtitle}</h6>
            <ul>
              {headings.map((heading) => (
                <FAQSubcategoryItem
                  key={heading}
                  id={heading}
                  title={heading}
                  count={lookup.faqsWithHeading(heading).length}
                  onClick={() => handleSelectHeading(heading)}
                  isSelected={heading === selectedHeading}
                />
              ))}
            </ul>
          </>
        ) : null}
      </FAQMobileBrowser>
    </div>
  )
}

export default FAQSearch
