import React, { MouseEvent, useRef } from 'react'
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'
import { useService } from 'src/utils/useService'

import { useSharedContext } from './front-end/_sharedContext'
import Button, { ButtonProps, ButtonTypes } from './front-end/Buttons/Button'
import VideoModal, { CustomModalRef } from './front-end/Video/VideoModal'

interface ButtonFields {
  link?: LinkField
  service?: Field<string>
  type?: Field<ButtonTypes>
  icon?: Field<ButtonProps['icon']>
  PanelName?: Field<string>
  VideoYouTubeId?: Field<string>
  VideoButtonLabel?: Field<string>
}

type ADFButtonProps = ComponentProps & {
  fields: ButtonFields
}

const ADFButton = ({ params = {}, fields = {}, rendering }: ADFButtonProps): JSX.Element => {
  const service = useService(fields.service?.value)
  const { drawers, updateDrawers } = useSharedContext()
  const modalRef = useRef<CustomModalRef>(null)

  const id = rendering.uid as string
  const styles = `${params.GridParameters} ${params.styles ?? ''}`.trim()
  const type = fields.type?.value
  const icon = fields.icon?.value
  const link = fields.link ?? { value: { href: '', text: '' } }
  const panelName = fields.PanelName?.value
  const videoId = fields.VideoYouTubeId?.value

  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (videoId && modalRef.current?.openModal) {
      modalRef.current.openModal()
    }
    if (panelName && drawers.has(panelName)) {
      drawers.set(panelName, true)
      updateDrawers()
    }
  }

  let buttonProps: ButtonProps = {
    'data-trackingid': id,
    type,
    link,
    icon,
    service,
  }

  if (videoId) {
    buttonProps.link.value.text = fields.VideoButtonLabel?.value || 'Watch Video'
  }

  if (videoId || panelName) {
    buttonProps = { ...buttonProps, button: true, onClick: onButtonClick } as ButtonProps
  }

  return (
    <div className={styles}>
      <Button {...buttonProps} />
      {videoId ? <VideoModal id={id} ref={modalRef} videoId={videoId} service={service} /> : null}
    </div>
  )
}

export default ADFButton
