export const kebabToTitleCase = (str: string): string => {
  const words = str.split('-')
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  return capitalizedWords.join(' ')
}

export const dateStringToTime = (date: string, timeZone = 'UTC') => {
  const d = new Date(date)
  return d
    .toLocaleTimeString('en-AU', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone,
    })
    .replace(' ', '')
}

export const dateStringFormatTime = (date: string) => {
  const d = new Date(date)
  return d
    .toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .replace(' ', '')
}

export const dateStringToDate = (date: string, long?: boolean, tz = 'AEST') => {
  const timeZone = timezoneIANAName(tz)
  const d = new Date(date)

  return d
    .toLocaleDateString('en-AU', {
      year: long ? 'numeric' : '2-digit',
      month: long ? 'short' : '2-digit',
      day: '2-digit',
      weekday: 'short',
      timeZone,
    })
    .replace(',', '')
    .replace(/June|July|Sept/g, (match) => (match === 'June' ? 'Jun' : match === 'July' ? 'Jul' : 'Sep'))
}

/**
 *
 * @param startDate as string
 * @param endDate as string
 * @returns true if the start date and end date are not the same
 */
export const isMultiDayEvent = (startDate: string, endDate: string) => {
  return startDate.split('T')[0] !== endDate.split('T')[0]
}

export const dateRange = (startDate: string, endDate: string, tz = 'AEST', format: 'short' | 'long' = 'short') => {
  startDate = fixTimezoneOffset(startDate, tz)
  endDate = fixTimezoneOffset(endDate, tz)

  const eventDates = [dateStringToDate(startDate, format === 'long', tz)]
  if (isMultiDayEvent(startDate, endDate)) {
    eventDates.push(dateStringToDate(endDate, format === 'long', tz))
  }
  return eventDates.join(' - ')
}

function fixTimezoneOffset(originalTimestamp: string, timezone: string): string {
  const offsetString = getTimezoneOffset(timezone)
  if (offsetString === 'Invalid timezone') {
    throw new Error('Invalid timezone code provided')
  }

  // Check if the original timestamp ends with 'Z'
  if (originalTimestamp.endsWith('Z')) {
    // Replace the 'Z' with the correct timezone offset
    return originalTimestamp.replace('Z', offsetString)
  } else {
    // Append the correct timezone offset to the timestamp
    return `${originalTimestamp}${offsetString}`
  }
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/)
  if (phoneNumberString.startsWith('131')) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/)
  }
  if (match) {
    return match[1] + ' ' + match[2] + ' ' + match[3]
  }
  return null
}

export const timezoneLongName = (timezone: string) => {
  const timezoneMap: Record<string, string> = {
    AEST: 'Australian Eastern Standard Time',
    AEDT: 'Australian Eastern Daylight Time',
    ACST: 'Australian Central Standard Time',
    ACDT: 'Australian Central Daylight Time',
    AWST: 'Australian Western Standard Time',
    AWDT: 'Australian Western Daylight Time',
    ACWST: 'Australian Central Western Standard Time',
    ACWDT: 'Australian Central Western Daylight Time',
  }
  return timezoneMap[timezone] || timezone
}

export const timezoneIANAName = (timezone: string) => {
  const timezoneMap: Record<string, string> = {
    AEST: 'Australia/Sydney',
    AEDT: 'Australia/Sydney',
    ACST: 'Australia/Adelaide',
    ACDT: 'Australia/Adelaide',
    AWST: 'Australia/Perth',
    AWDT: 'Australia/Perth',
    ACWST: 'Australia/Eucla',
    ACWDT: 'Australia/Eucla',
  }
  return timezoneMap[timezone] || timezone || 'Australia/Sydney'
}

function getTimezoneOffset(timezone: string): string {
  const timezoneOffsets: Record<string, string> = {
    AEST: '+10:00', // Australian Eastern Standard Time
    AEDT: '+11:00', // Australian Eastern Daylight Time (Daylight Saving)
    ACST: '+09:30', // Australian Central Standard Time
    ACDT: '+10:30', // Australian Central Daylight Time (Daylight Saving)
    AWST: '+08:00', // Australian Western Standard Time
    AWDT: '+09:00', // Australian Western Daylight Time (Daylight Saving, rarely used)
  }

  return timezoneOffsets[timezone] || 'Invalid timezone'
}
