import { Field, RichText as JssRichText, LinkField, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import parse from 'html-react-parser'

import Button, { ButtonTypes } from 'components/front-end/Buttons/Button'
import { cleanHeadingStyle, HeadingElementStyle } from 'src/utils/cleanHeadingStyle'
import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'

interface LedeProps {
  id: string
  title: string
  text?: string
  service?: string
  HeadingStyle?: HeadingElementStyle
  LedeCopySize?: string
  CTAButton1?: LinkField
  CTAButton1Theme?: string
  CTAButton2?: LinkField
  CTAButton2Theme?: string
  TitleField?: Field<string>
  TextField?: Field<string>
}

const LedeText = ({
  id,
  title,
  text,
  service,
  HeadingStyle = 'h2',
  LedeCopySize,
  CTAButton1,
  CTAButton1Theme,
  CTAButton2,
  CTAButton2Theme,
  TitleField,
  TextField,
}: LedeProps) => {
  const HeadingLevel = cleanHeadingStyle(HeadingStyle) ?? 'h2'
  const headingStyles = HeadingStyle === 'Sub heading' ? 'pull-quote' : undefined
  const copyStyles = LedeCopySize === 'Large' ? 'lbody' : 'mbody'
  const { sitecoreContext } = useSitecoreContext()
  return (
    <div className="row items-end">
      <div className="lg-span-7 lg-gutter">
        {sitecoreContext.pageEditing && TitleField && TextField ? (
          <>
            <Text tag={HeadingLevel} className="contentTitle" field={TitleField} />
            {useLessThan('lg') && <JssRichText className={`lg-span-5 mt-8 ${copyStyles}`} field={TextField} />}
          </>
        ) : (
          <>
            <HeadingLevel className={headingStyles}>{parse(title)}</HeadingLevel>
            {text && useLessThan('lg') && <div className={`lg-span-5 mt-8 ${copyStyles}`}>{parse(text)}</div>}
          </>
        )}
        {(CTAButton1?.value?.href || CTAButton2?.value?.href) && (
          <div className="mt-6 flex flex-wrap gap-6">
            {CTAButton1?.value?.href && (
              <Button
                data-trackingid={id}
                icon="chevron-right-double"
                link={CTAButton1}
                type={(CTAButton1Theme as ButtonTypes) || 'tertiary'}
                service={service || ''}
              />
            )}
            {CTAButton2?.value?.href && (
              <Button
                data-trackingid={id}
                icon="chevron-right-double"
                link={CTAButton2}
                type={(CTAButton2Theme as ButtonTypes) || 'tertiary'}
                service={service || ''}
              />
            )}
          </div>
        )}
      </div>
      {sitecoreContext.pageEditing && TextField ? (
        <>{useGreaterThan('lg') && <JssRichText className={`lg-span-5 lg-gutter ${copyStyles}`} field={TextField} />}</>
      ) : (
        <>{text && useGreaterThan('lg') && <div className={`lg-span-5 lg-gutter ${copyStyles}`}>{parse(text)}</div>}</>
      )}
    </div>
  )
}

export default LedeText
