import { useRef, useState } from 'react'

import slugify from 'utils/slugifyStrings'
import { track } from 'utils/tracking'

type JobTabProps = {
  title: string
  onClick?: () => void
  isSelected?: boolean
  tracking_component?: string
  tracking_uuid?: string
}

const JobTabButton = ({ title, onClick, isSelected, tracking_component, tracking_uuid }: JobTabProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
  })

  const tabElement = useRef<HTMLButtonElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const offsetLeft = tabElement?.current?.getBoundingClientRect().left || 0
    const finalX = ev.pageX - offsetLeft

    setMousePosition({ left: finalX })
  }

  const buttonClasses = `
        job-tab
        group z-[0] relative
        flex flex-col items-start shrink-0
        ml-2 mr-2 first:ml-0 last:mr-0 md:ml-5 md:mr-5
        pb-4 md:pb-5
        text-left transition duration-400
        focus-visible:ring-0 focus-visible:ring-offset-0
        before:block before:absolute before:inset-y-0 before:-inset-x-1.5 before:transition-[outline] before:outline before:outline-0 before:outline-[--focus-color]
        focus-visible:before:outline-3
        ${isSelected ? 'is-active cursor-default' : ''}
    `
  const titleClasses = `
        sbody md:mbody
        z-[1] relative block
        text-grey-medium group-[.is-active]:text-black [.dark_&]:text-white [.dark_&]:group-[.is-active]:text-white
        overflow-hidden text-ellipsis whitespace-nowrap max-w-[250px]
    `
  const fillClasses = `
        tab-fill z-[0] absolute bottom-0 left-0 h-[2px] w-full
        bg-black-off [.dark_&]:bg-white
        scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
        transition duration-400 ease-linear
    `

  return (
    <button
      tabIndex={0}
      ref={tabElement}
      onMouseMove={(ev) => handleMouseMove(ev)}
      onClick={() => {
        if (isSelected) {
          return
        }
        onClick?.()
        track({
          event: 'tab_button',
          ga4category: 'tab_button',
          tab_component: tracking_component || 'Undefined Job Tab',
          ga4name: `${title as string} - ${tracking_uuid || 'Undefined UUID'}`,
        })
      }}
      className={buttonClasses}
      role="tab"
      id={`tab-${slugify(title)}`}
      aria-controls={`tabpanel-${slugify(title)}`}
      aria-selected={isSelected}
    >
      <span className={titleClasses}>{title as string}</span>
      <div className={fillClasses} style={{ transformOrigin: mousePosition.left }}></div>
    </button>
  )
}

export default JobTabButton
