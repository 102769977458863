import { CSSProperties, useEffect, useRef, useState } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-nextjs'
import { isMobile } from 'react-device-detect'

import { useGreaterThan, useLessThan } from '../../../utils/useBreakpoints'
import { TabType } from 'components/ADFSideTab'
import Icon from 'components/front-end/Icons/Icon'
import { trackDropdown } from 'src/utils/tracking'

export type SideNavTabProps = {
  id: string
  list?: TabType
  onClick?: (index?: number) => void
  isSelected?: boolean
  isExperience?: boolean
  selectedChild?: number
}

const SideNavTab = (props: SideNavTabProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  })
  // isMobile returns true for tablets, so we need to exclude them
  const isLessThanXm = useLessThan('xm')
  const isPhone = !useGreaterThan('xm') && isMobile

  const results = props.list?.children?.results ?? []

  const initiallyOpen = Boolean(results.length > 1 && props.isSelected && !isPhone)

  const [isOpen, setIsOpen] = useState<boolean>(initiallyOpen)
  const tabElement = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    setIsOpen(!!props.isSelected)
  }, [props.isSelected])

  function handleMouseMove(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const offsetLeft = tabElement?.current?.getBoundingClientRect().left || 0
    const width = tabElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < 80) finalX = 0
    if (finalX > width - 80) finalX = width

    setMousePosition({ left: finalX, top: ev.pageY })
  }

  const displayCollapseClass = (results.length ?? 0) > 0 ? '' : 'hidden '

  const navClasses = `
        group z-0 relative flex flex-col items-start w-full
        border-solid last:border-b border-t border-grey-light [.dark_&]:border-grey-medium
        transition duration-400
        ${isOpen ? 'svg-clicked clicked-active is-active' : ''}
    `
  const titleClasses = `
        nav-title mbody relative py-4 px-3.5 w-full
        text-left text-black [.dark_&]:text-white group-hover:text-white [.dark_&]:group-hover:text-black group-[.is-active]:text-white [.dark_&]:group-[.is-active]:text-black
        transition duration-500
    `
  const titleSpanClasses = `
        relative z-[1] pointer-events-none duration-0
        overflow-hidden text-ellipsis whitespace-nowrap
        block max-w-[calc(100%_-_40px)]
    `
  const itemClasses = `
        nav-item sbody relative py-3 px-2 mx-4 hover:font-bold [.dark_&]:text-white
        text-left
        duration-0 group-[.is-active]:duration-500
        -translate-y-4 opacity-0 group-[.is-active]:translate-y-0 group-[.is-active]:opacity-100
        transition-[transform,_opacity] group-[.is-active]:delay-[--in-delay] delay-500
    `
  const fillClasses = `
        title-fill z-0 absolute top-0 left-0 h-full w-full
        bg-black-off [.dark_&]:bg-white
        scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
        transition duration-500
    `
  const iconClasses = `
        icon z-[1] absolute right-[18px] top-1/2 -translate-y-1/2 ${displayCollapseClass}
    `

  const collapseClasses = `
        nav-collapse h-auto max-h-0 overflow-hidden [.is-active_&]:max-h-[500px] transition-[max-height] duration-500 [.is-active_&]:delay-[212.5ms] ease-linear w-full
    `

  const handleTabClick = () => {
    if (results && results.length > 1) {
      setIsOpen(!isOpen)

      const name = props.list?.title?.field?.value
      if (name && !isOpen && !isPhone) {
        trackDropdown(name)
      }
    }
    props.onClick?.(results.length > 1 ? 0 : undefined)
    if (isPhone) {
      setTimeout(() => setIsOpen(false), 200)
    }
  }

  return (
    <div className={navClasses}>
      <button
        data-side-tab
        className={titleClasses}
        ref={tabElement}
        onMouseMove={(ev) => handleMouseMove(ev)}
        onClick={() => handleTabClick()}
        onFocus={
          props.isExperience
            ? () => {
                console.log('focus on tab')
                handleTabClick()
              }
            : undefined
        }
      >
        {!props.isExperience && <span className={titleSpanClasses}>{props.list?.title?.field?.value as string}</span>}
        {isLessThanXm && (
          <span className={iconClasses} onClick={() => props.onClick?.()}>
            <Icon name={'arrow-east'} type="stroke" clicked={props.isExperience} />
          </span>
        )}
        {results && results.length > 1 && !isLessThanXm && (
          <span className={iconClasses} onClick={() => props.onClick?.()}>
            <Icon name={'chevron-down'} type="stroke" clicked={props.isExperience} />
          </span>
        )}
        {props.isExperience && <Text className="text relative z-[1]" field={props.list?.title?.field} />}
        <span className={fillClasses} style={{ transformOrigin: mousePosition.left }}></span>
      </button>

      {useGreaterThan('xm') && (
        <ul className={collapseClasses}>
          {results.length > 1 &&
            results.map((result, i) => {
              return (
                <li key={i} className="first:mt-3 last:mb-3">
                  <button
                    data-side-subtab
                    className={`${itemClasses} ${
                      props.selectedChild === i ? 'font-bold text-black [.dark_&]:text-white' : ''
                    }`}
                    onClick={() => {
                      props.onClick?.(i)
                    }}
                    onFocus={
                      props.isExperience
                        ? () => {
                            console.log('focus on subtab')
                            props.onClick?.(i)
                          }
                        : undefined
                    }
                    style={{ '--in-delay': 300 + 100 * i + 'ms' } as CSSProperties}
                    tabIndex={isOpen ? 0 : -1}
                    role="tab"
                    id={`tab-${props.id}-${i}`}
                    aria-controls={`tabpanel-${props.id}-${i}`}
                    aria-selected={props.selectedChild === i}
                  >
                    <Text field={result.title?.field} />
                  </button>
                </li>
              )
            })}
        </ul>
      )}
    </div>
  )
}

export default SideNavTab
