import ToggleButton from "components/front-end/Buttons/ToggleButton";
import FormField from "components/front-end/FormFields/FormField";
import Divider from "./Divider";

type ToggleProps = {
    title: string;
    mainText: string;
    secondaryText?: string;
};

const Toggle = ({ title, secondaryText = '', mainText}: ToggleProps): JSX.Element => {

    return <div className="py-6">
        <div className="h6 pb-6">{title}</div>
        <Divider></Divider>
        <div className="flex flex-col">
        <FormField
            type='toggle'
            enable = {true}
            mainText={mainText}
            secondaryText={secondaryText}
        />
        </div>
    </div>;
    };
    
export default Toggle;