'use client'

import { useEffect, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { twJoin } from 'tailwind-merge'

import usePageContext from 'components/front-end/_layoutContext'
import AudioTranscript from 'components/front-end/Audio/AudioTranscript'
import Button from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import useScrollLock from 'src/utils/scrollLock'
import { track } from 'src/utils/tracking'
import { useService } from 'src/utils/useService'
import AudioBarButtons from './AudioPlayerBarButtons'
import AudioPlayerProgressBar from './AudioPlayerProgressBar'
import AudioPlaytime, { formatTime } from './AudioPlaytime'
import { useAudioPlayerProgress } from './useAudioPlayerProgress'

const AudioPlayerBar = ({ id }: { id: string }) => {
  const { audio } = usePageContext().pageContext
  const service = useService()

  const { lockScroll, unlockScroll } = useScrollLock()
  const [isBarOpen, setIsBarOpen] = useState(false)

  const currentPlayerIndex = audio.audioPlayActive
  const audioElm = audio.audioPlayList[currentPlayerIndex]?.audioRef.current
  const multiplePlayers = audio.audioPlayList.length > 1
  const [, playerState] = useAudioPlayerProgress(audioElm)
  const isPlaying = playerState === 'playing'

  useEffect(() => {
    if (audio.isScriptOpen) {
      lockScroll()
      setIsBarOpen(true)
    } else unlockScroll()
  }, [lockScroll, audio.isScriptOpen, unlockScroll])

  useEffect(() => {
    if (isPlaying) setIsBarOpen((open) => (open ? open : true))
  }, [isPlaying])

  function playCurrentAudio() {
    setTimeout(() => (isPlaying ? audioElm?.pause() : audioElm?.play()), 50)
  }

  function prevNext(dir: number) {
    const newPlayerIndex = currentPlayerIndex + dir
    if (newPlayerIndex >= 0 && newPlayerIndex < audio.audioPlayList.length) {
      setTimeout(() => audioElm?.pause(), 10)
      setTimeout(() => audio.setAudioPlayActive(currentPlayerIndex + dir), 50)
    }
  }

  const handleClickClose = () => {
    setTimeout(() => audio.setIsScriptOpen(false), 10)
    setTimeout(() => setIsBarOpen(false), 20)
  }

  const handleClickCaptions = () => {
    setTimeout(() => audio.setIsScriptOpen(!audio.isScriptOpen), 10)
  }

  return (
    <FocusTrap active={audio.isScriptOpen && isBarOpen}>
      <div role="application" inert={isBarOpen ? undefined : ''}>
        <AudioTranscript id={id} group />
        <div
          className={twJoin(
            'duration-400 fixed bottom-[-115px] left-0 z-[49] flex h-[115px] w-screen items-center text-black transition-all ease-in xm:-bottom-24 xm:h-24 [.dark_&]:!bg-black-off [.dark_&]:!text-white [.grey_&]:!bg-black-off [.light_&]:bg-white-off [.light_&]:text-black',
            isBarOpen ? '-translate-y-full' : 'translate-y-0'
          )}
        >
          <div className="container" data-cols={12}>
            <div className="row">
              <div className="col span-8 xm-span-4 flex items-center">
                <div className="inner-left p-1/2 mr-4 size-[41] shrink-0 xm:h-16 xm:w-16">
                  <div className="relative size-full">
                    <ImageLoader
                      src={audio.audioPlayList[currentPlayerIndex].image || ''}
                      className="img"
                      alt=""
                      width={640}
                      height={640}
                    />
                    <div
                      className={twJoin(
                        'absolute top-0 flex h-full w-full items-end justify-center bg-gradient-to-t from-black/60 from-10% via-black/0 to-black/0 pb-2 text-white transition duration-300',
                        isPlaying && 'bg-black/30'
                      )}
                    >
                      <div
                        className={twJoin(
                          'headphones-icon absolute transition-all duration-300',
                          !isPlaying ? 'delay-300' : '-translate-y-3 opacity-0 delay-100'
                        )}
                      >
                        <Icon name="headphones" type="stroke" className="size-8" />
                      </div>
                      <div
                        className={twJoin(
                          'sound-icon absolute transition-all duration-300',
                          isPlaying ? 'loop delay-300' : 'translate-y-3 opacity-0'
                        )}
                      >
                        <Icon name="sound" type="stroke" hover className="h-10 w-10" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex h-16 flex-col justify-center">
                  <p className="name mb-1 font-inter text-xsbody xm:text-mbody">
                    {audio.audioPlayList[currentPlayerIndex].name}
                  </p>
                  <p className="role overflow-hidden text-ellipsis whitespace-nowrap font-uniform-condensed text-xstag font-semibold uppercase xm:text-mtag">
                    {audio.audioPlayList[currentPlayerIndex].role}
                  </p>
                </div>
              </div>

              <div className="col span-4 xm-span-4 flex items-center justify-end xm:justify-center">
                <div className="controls flex items-center">
                  {multiplePlayers && (
                    <button className="prev mr-4 flex items-center" onClick={() => prevNext(-1)}>
                      <span className="block h-[18px] w-px border-l border-[currentColor]"></span>
                      <Icon name="play" type="stroke" className="size-5 rotate-180" />
                    </button>
                  )}

                  <Button
                    data-trackingid={id}
                    link={{
                      value: {
                        text: isPlaying ? 'pause' : 'play',
                      },
                    }}
                    button
                    service={service}
                    icon={isPlaying ? 'pause' : 'play'}
                    solid
                    type="primary-icon"
                    onClick={() => {
                      playCurrentAudio()
                      track({
                        event: 'audio_interaction',
                        ga4name: `AudioPlayerBar - ${isPlaying ? 'pause' : 'play'}`,
                      })
                    }}
                    className={twJoin('!size-8 [&_svg]:size-5', isPlaying ? 'pause' : 'play')}
                  />

                  {multiplePlayers && (
                    <button className="next ml-4 flex items-center" onClick={() => prevNext(1)}>
                      <Icon name="play" type="stroke" className="size-5" />
                      <span className="block h-[18px] w-px border-r border-current"></span>
                    </button>
                  )}
                </div>
              </div>
              <div className="col span-12 h-px bg-grey-medium xm:hidden xm:w-0"></div>
              <div className="col span-12 xm-span-4 hidden items-center xm:flex">
                <AudioBarButtons
                  id={id}
                  service={service}
                  onClickClose={handleClickClose}
                  onClickCaptions={handleClickCaptions}
                />
              </div>
            </div>
            <div className="row mb-5 mt-2.5 text-[11px] xm:mb-0 xm:mt-[-15px]">
              <div className="col span-8 xm-span-12 flex items-center justify-center">
                <div className="current mr-2" aria-hidden>
                  <AudioPlaytime player={audioElm} />
                </div>
                <AudioPlayerProgressBar player={audioElm} />
                <div className="duration ml-2" aria-hidden>
                  {formatTime(audioElm?.duration || 0)}
                </div>
              </div>
              <div className="col span-4 flex items-center justify-center xm:hidden">
                <AudioBarButtons
                  id={id}
                  service={service}
                  onClickClose={handleClickClose}
                  onClickCaptions={handleClickCaptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}

export default AudioPlayerBar
