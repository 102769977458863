import React, { useState } from 'react'
import { ComponentParams, ComponentRendering, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import CarouselSwiper from 'components/front-end/Carousel/CarouselSwiper'
import EventDrawer from 'components/front-end/Events/EventDrawer'
import InfoPanel from 'components/front-end/SitecoreHelpers/InfoPanel'
import { EventProps, EventRegoField } from './ADFEventHome'
import { ButtonProps } from './front-end/Buttons/Button'
import EventCard from './front-end/Events/EventCard'

export interface EventRegoForm {
  id: string
  url: string
  name: string
  displayName: string
  fields: EventRegoField
}

interface EventCarouselField {
  EventsList: EventProps[]
  EventRegistrationForm: EventRegoForm
}

interface EventCarouselProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: EventCarouselField
}

export const ADFEventCarousel = (props: EventCarouselProps): JSX.Element => {
  const [activeEvent, setActiveEvent] = useState<EventProps>()
  const [openEventDrawer, setEventOpenDrawer] = useState(false)
  const isNotEmptyEventList = props?.fields?.EventsList?.length > 0 || false
  const registerButtonAction = (event: EventProps) => {
    setActiveEvent(event)
    setEventOpenDrawer(true)
  }

  const { sitecoreContext } = useSitecoreContext()
  const isEdit = sitecoreContext.pageState === 'edit'

  const eventButtons = (event: EventProps): ButtonProps[] => {
    const infoButton: ButtonProps = {
      'data-trackingid': event.id,
      link: { value: { href: event.url ?? '#', text: 'Info' } },
      icon: 'chevron-right-double',
      type: 'small-secondary',
    }

    return event.EventCanRegister
      ? [
          event.eventCapacityIsFull || event.isClosed
            ? {
                'data-trackingid': event.id,
                link: {
                  value: {
                    text: 'Event Full',
                  },
                },
                icon: 'error',
                type: 'small-action',
                button: true,
                disabled: true,
              }
            : event.EventEnableExternalForm && event.eventFormExternalUrl
              ? {
                  'data-trackingid': event.id,
                  link: { value: { text: 'Reserve a seat', href: event.eventFormExternalUrl } },
                  icon: 'edit',
                  type: 'small-action',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }
              : {
                  'data-trackingid': event.id,
                  link: { value: { text: 'Reserve a seat' } },
                  icon: 'edit',
                  type: 'small-action',
                  button: true,
                  onClick: () => registerButtonAction(event),
                },
          infoButton,
        ]
      : [infoButton]
  }

  return isEdit && !isNotEmptyEventList ? (
    <InfoPanel
      heading="Sitecore | Event Carousel"
      message="Please edit the datasource and select Event category to display"
    />
  ) : (
    <>
      {props?.fields?.EventsList?.length && (
        <div className="container-break-right">
          <CarouselSwiper id={props.rendering.uid as string} alignControls="right">
            {props?.fields?.EventsList.map((event) => (
              <EventCard key={event.id} event={event} buttons={eventButtons(event)} />
            ))}
          </CarouselSwiper>
        </div>
      )}
      <EventDrawer
        activeEvent={activeEvent}
        eventFormFields={props.fields.EventRegistrationForm?.fields}
        isOpen={openEventDrawer}
        closeDrawer={() => setEventOpenDrawer(false)}
      />
    </>
  )
}

export default ADFEventCarousel
