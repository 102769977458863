import { RichText } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin } from 'tailwind-merge'

import Icon from 'components/front-end/Icons/Icon'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import { cleanHeadingStyle, HeadingElementStyle } from 'utils/cleanHeadingStyle'
import { useService } from 'utils/useService'

interface QuoteProps {
  text: string
  service?: string
  author?: string
  showServiceTag?: boolean
  HeadingStyle?: HeadingElementStyle
}

const QuoteText = ({ text, service: defaultService, author, showServiceTag = true, HeadingStyle }: QuoteProps) => {
  const service = useService(defaultService)
  const textColor = {
    'tri-service': 'text-tri-service',
    army: 'text-army',
    'air-force': 'text-air-force',
    navy: 'text-navy',
  }[service]

  const HeadingLevel = cleanHeadingStyle(HeadingStyle)
  return (
    <blockquote className="quote-text text-black-off [.dark_&]:!text-white [.light_&]:text-black-off">
      {showServiceTag && (
        <div className="mb-18">
          <ServiceTag service={service} />
        </div>
      )}
      <Icon name="quote" className={`mb-6 size-12 ${textColor}`} />
      <RichText
        className={twJoin('mb-12', HeadingLevel ? 'font-normal normal-case leading-none' : 'pull-quote')}
        tag={HeadingLevel}
        field={{ value: text }}
      />
      {author && (
        <footer className="flex gap-x-2">
          <Icon
            name="user"
            type="stroke"
            className="inline h-[1lh] w-5 shrink-0 [.dark_&]:!text-white [.light_&]:text-black-off"
          />
          <cite className="mbody not-italic">{author}</cite>
        </footer>
      )}
    </blockquote>
  )
}

export default QuoteText
