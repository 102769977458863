// Regular expression validations for form fields

/**
 * @param email
 * @returns {boolean}
 * @description Checks if the email is valid
 */
export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/
  return email.length < 1 || emailRegex.test(email)
}
/**
 * @param name
 * @returns {boolean}
 * @description Checks if the name is valid
 */
export const isValidName = (name: string) => {
  const validPattern = /^[A-Za-z\s'’\-0-9+_%.,—]+$/
  return name.length < 1 || validPattern.test(name)
}
/**
 * @param mobile
 * @returns {boolean}
 * @description Checks if the mobile is valid
 */
export const isValidMobile = (mobile: string) => {
  const validPattern = /^[0-9\s+]+$/
  const maxLength = 15
  return mobile.length < 1 || (validPattern.test(mobile) && mobile.length <= maxLength)
}

/**
 * @param number
 * @param length
 * @returns {boolean}
 * @description Checks if the number is valid and has the specified length
 * @example isValidNumber('123', 3) // true
 */
export const isValidNumber = (number: string, length?: number) => {
  const validPattern = new RegExp(`^\\d{${length}}$`)
  return number.length < 1 || validPattern.test(number)
}
