import React from 'react'
import { ImageField, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs'
import { isMobile } from 'react-device-detect'
import { useService } from 'src/utils/useService'

import usePageContext from 'components/front-end/_layoutContext'
import Button, { ButtonProps, ButtonTypes } from 'components/front-end/Buttons/Button'

interface CTATileProps {
  id: string
  ImageDesktop: ImageField
  ImageMobile?: ImageField
  Headline1?: string
  SubHeadline1?: string
  CTAButton1?: LinkField
  CTAButton1Theme?: ButtonTypes
  CTAButton1Icon?: ButtonProps['icon']
  Headline2?: string
  SubHeadline2?: string
  CTAButton2?: LinkField
  CTAButton2Theme?: ButtonTypes
  CTAButton2Icon?: ButtonProps['icon']
  Headline3?: string
  SubHeadline3?: string
  CTAButton3?: LinkField
  CTAButton3Theme?: ButtonTypes
  CTAButton3Icon?: ButtonProps['icon']
  service?: string
}

const CTATile = (props: CTATileProps): JSX.Element => {
  const state = usePageContext()
  const serviceColor = useService(props.service || state?.pageContext?.globalService)

  const backgroundImageUrl = isMobile
    ? props?.ImageMobile?.value?.src ?? props?.ImageDesktop?.value?.src
    : props?.ImageDesktop?.value?.src ?? props?.ImageMobile?.value?.src
  const backgroundStyle = {
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url('${backgroundImageUrl}') `,
    backgroundPosition: isMobile ? 'center top' : 'center center',
  }

  const ctaTileType =
    props.Headline2 || props.SubHeadline2 ? 'Layout1' : props.Headline3 || props.SubHeadline3 ? 'Layout2' : 'Layout3'

  return (
    <div
      className="dark flex h-full max-h-[900px] w-full flex-col bg-black/60 bg-cover bg-no-repeat text-white sm:flex-col md:flex-col xm:flex-row lg:flex-row xl:flex-row xxl:flex-row [@media(max-width:640px)]:max-h-[1200px]"
      style={backgroundStyle}
    >
      <div
        className={`mx-[--outermargin] py-5 pt-10 xm:mr-0 xm:w-7/12 xm:pb-16 xm:pr-[120px] xm:pt-[342px] lg:w-3/4 lg:pt-[336px] xl:w-3/4 xl:pb-18 xl:pr-[120px] xl:pt-[330px] xxl:w-3/4 xxl:pb-22 xxl:pr-[120px] xxl:pt-[322px] ${
          ctaTileType !== 'Layout3'
            ? 'border-b border-t border-grey-medium xs:border-b md:border-b xm:border-r lg:border-r xl:border-r xxl:border-r'
            : ''
        }`}
      >
        <RichText tag="h2" field={{ value: props.Headline1 }} />
        <div className="medium pr-8 font-uniform-condensed leading-snug sm:pt-3.5 md:pt-3.5 xm:pt-6 lg:pt-8">
          {props.SubHeadline1}
        </div>
        <div className="pt-8">
          {props.CTAButton1?.value.href ? (
            <Button
              data-trackingid={props.id}
              type={props.CTAButton1Theme || 'primary'}
              link={props.CTAButton1}
              icon={props.CTAButton1Icon || 'chevron-right-double'}
              service={serviceColor}
            />
          ) : null}
        </div>
      </div>
      <div
        className={`flex flex-col border-t border-grey-medium xs:flex-col md:flex-row xm:w-5/12 xm:flex-col lg:w-1/3 lg:flex-col xl:w-1/3 xxl:w-1/4 xxl:flex-col [@media(max-width:640px)]:flex-col ${
          ctaTileType !== 'Layout3' ? 'border-b border-grey-medium' : 'hidden'
        }`}
      >
        <div
          className={
            ctaTileType === 'Layout1'
              ? 'flex h-1/2 w-full flex-col justify-end border-grey-medium pb-5 pl-[--outermargin] pr-5 pt-10 xs:border-b md:h-full md:min-h-80 md:border-b-0 md:border-r xm:border-b xm:pb-10 xm:pl-10 xm:pt-2.5'
              : 'flex h-1/2 flex-col justify-end p-0'
          }
        >
          <h5 className={`text-8 w-5/6 pt-5 leading-10 ${ctaTileType === 'Layout1' ? '' : 'hidden'}`} aria-level={3}>
            {/* {field.Headline2} */}
            <RichText tag="span" field={{ value: props.Headline2 }} />
          </h5>
          <div
            className={`w-5/6 pt-5 font-inter text-[22px] leading-9 [@media(max-width:640px)]:pt-0 ${
              ctaTileType === 'Layout1' ? '' : 'hidden'
            }`}
          >
            {props.SubHeadline2}
          </div>
          <div className={`pt-8 ${ctaTileType === 'Layout1' ? '' : 'hidden'}`}>
            {props.CTAButton2?.value.href ? (
              <Button
                data-trackingid={props.id}
                type={props.CTAButton2Theme || 'primary'}
                link={props.CTAButton2}
                icon={props.CTAButton2Icon || 'chevron-right-double'}
                service={serviceColor}
              />
            ) : null}
          </div>
        </div>
        <div className="flex h-1/2 w-full flex-col justify-end pb-5 pl-[--outermargin] pr-5 pt-10 md:h-full md:min-h-80 xm:py-10 xm:pl-10">
          <h5 className={`text-8 w-5/6 pt-5 leading-10 ${ctaTileType !== 'Layout3' ? '' : 'hidden'}`} aria-level={3}>
            <RichText tag="span" field={{ value: props.Headline3 }} />
          </h5>
          <div
            className={`w-5/6 pt-5 font-inter text-[22px] leading-9 [@media(max-width:640px)]:pt-0 ${
              ctaTileType !== 'Layout3' ? '' : 'hidden'
            }`}
          >
            {props.SubHeadline3}
          </div>
          <div className={`pt-8 ${ctaTileType !== 'Layout3' ? '' : 'hidden'}`}>
            {props.CTAButton3?.value.href ? (
              <Button
                data-trackingid={props.id}
                type={props.CTAButton3Theme || 'primary'}
                link={props.CTAButton3}
                icon={props.CTAButton3Icon || 'chevron-right-double'}
                service={serviceColor}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTATile
