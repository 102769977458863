import { Field, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { twJoin, twMerge } from 'tailwind-merge'

import { Url } from 'next/dist/shared/lib/router/router'
import { useHoverOrigin } from 'utils/useHoverOrigin'
import Icon from '../Icons/Icon'
import { StrokeIcons } from '../Icons/icon-data'
import { AnimatedUnderline } from './NavigationItem'

export interface NavigationActionProps extends React.HTMLAttributes<HTMLElement> {
  field: Field<string> | LinkField
  count?: number

  icon?: StrokeIcons
  iconClicked?: boolean
  iconOnly?: boolean
  button?: boolean
}

function NavigationAction({
  field: { value },
  count,
  className,
  onClick,
  icon,
  iconOnly,
  button,
  'aria-expanded': ariaExpanded,
}: NavigationActionProps) {
  const setHoverOriginRef = useHoverOrigin()
  const actionClassName = twJoin(
    'text-mnav w-full font-uniform-condensed font-medium z-10 flex h-full items-center gap-x-5 justify-center whitespace-nowrap text-black-off transition-colors duration-200 group-hover:text-white group-aria-expanded:text-white [.dark_&]:text-white [.light_&]:text-black-off [.dark_&]:group-hover:text-black-off [.light_&]:group-hover:text-white',
    typeof count !== 'undefined' ? 'pl-4 pr-12 xlg:pl-6 xlg:pr-14' : 'px-4 xlg:px-6',
    icon ? (iconOnly ? 'w-16 xlg:w-22' : 'text-mbody') : 'uppercase'
  )

  const isLink = !button && typeof value === 'object' && typeof value.href === 'string'
  const Action = (props: { children: React.ReactNode }) =>
    isLink ? (
      <Link tabIndex={0} href={value.href as Url} aria-current="false" onClick={onClick} className={actionClassName}>
        {props.children}
      </Link>
    ) : (
      <button tabIndex={0} onClick={onClick} className={actionClassName}>
        {props.children}
      </button>
    )

  return (
    <div
      className={twMerge(
        'group relative flex shrink-0 cursor-pointer items-stretch justify-center',
        icon && iconOnly && 'w-16 xlg:w-22',
        className
      )}
      ref={setHoverOriginRef}
      aria-expanded={ariaExpanded}
    >
      <Action>
        {icon && iconOnly ? (
          <Icon name={icon} type="stroke" clicked />
        ) : (
          <>
            {icon ? <Icon name={icon} type="stroke" clicked className="size-8" /> : null}
            <span className="relative flex items-center">
              <Text field={{ value: typeof value === 'string' ? value : value.text }} />
              {typeof count !== 'undefined' ? (
                <span className="pointer-events-none absolute -right-2 flex items-center">
                  <span className="text-sm absolute z-10 flex size-6 items-center justify-center rounded-full
                    bg-tri-service text-black text-center font-uniform-condensed font-medium transition-colors
                    [.light_&]:group-hover:bg-white [.light_&]:group-hover:text-black
                    [.dark_&]:group-hover:bg-black [.dark_&]:group-hover:text-white"
                  >
                    {count}
                  </span>
                </span>
              ) : null}
            </span>
          </>
        )}
      </Action>
      <AnimatedUnderline className="bottom-0 flex h-full group-hover:scale-x-100 group-aria-expanded:flex group-aria-expanded:scale-x-100" />
    </div>
  )
}

export default NavigationAction
