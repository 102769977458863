import { StoryBannerFields } from 'components/front-end/StoryPanel/StoryLaunch'
import { ComponentProps } from 'lib/component-props'
import StoryBanner from './front-end/StoryPanel/StoryBanner'

type ADFStoryBannerProps = ComponentProps & {
  fields: StoryBannerFields
}

const ADFStoryBanner = ({ fields, rendering }: ADFStoryBannerProps) => (
  <StoryBanner id={rendering.uid as string} fields={fields} />
)

export default ADFStoryBanner
