import { useService } from 'src/utils/useService'

import Button from '../Buttons/Button'

export type CarouselSwiperControlsProps = {
  alignControls?: 'left' | 'right' | undefined
  id: string
  service?: string
}

const CarouselSwiperControls = (props: CarouselSwiperControlsProps) => {
  const service = useService(props.service)
  return (
    <div
      className={`carousel-controls -space-px flex -space-x-px md:text-right [&>.button-adf]:box-content [&>.button-adf]:h-14 [&>.button-adf]:w-14 [&_svg]:w-6 ${
        props.alignControls === 'right' ? 'justify-end' : ''
      }`}
    >
      <Button
        data-trackingid={props.id}
        button
        link={{ value: { text: 'Previous Slide' } }}
        icon="chevron-left"
        type="secondary-icon"
        service={service}
        onClick={() => null}
        className={`swiper-prev-${props.id} z-10 aria-disabled:pointer-events-none aria-disabled:z-0 aria-disabled:opacity-50 [.light_&_svg]:aria-disabled:text-grey-medium`}
      />
      <Button
        data-trackingid={props.id}
        button
        link={{ value: { text: 'Next Slide' } }}
        icon="chevron-right"
        type="secondary-icon"
        service={service}
        onClick={() => null}
        className={`swiper-next-${props.id} z-10 aria-disabled:pointer-events-none aria-disabled:z-0 aria-disabled:opacity-50 [.light_&_svg]:aria-disabled:text-grey-medium`}
      />
    </div>
  )
}

export default CarouselSwiperControls
