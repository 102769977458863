import Button from 'components/front-end/Buttons/Button'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import ShareButtons from 'components/front-end/ShareButtons/ShareButtons'
import { dateRange } from 'components/front-end/Shared/Utils'
import HeaderText from 'components/front-end/TextBlocks/HeaderText'
import { DetailProps } from 'src/components/ADFEventDetails'
import { useLessThan } from 'src/utils/useBreakpoints'
import ClosedNotification from './ClosedNotification'
import { attendanceMethodCopy } from './EventDetailConst'

const AboutSection: React.FC<DetailProps> = ({ event, openEventDrawer }) => {
  const containerStyles = `${useLessThan('xm') ? '' : 'container-grid'}`
  const rowStyles = `${useLessThan('xm') ? '' : 'row'}`
  const colLeftStyles = `${useLessThan('xm') ? '[&_.statement-panel]:px-10' : 'span-12 xm-span-8 xm-gutter '}`
  const colRightStyles = `${useLessThan('xm') ? '' : 'span-12 xm-span-4 xm-gutter'}`

  return (
    <>
      <div className={`${containerStyles} relative`}>
        <div className={rowStyles}>
          <div className={colLeftStyles}>
            <HeaderText
              headingStyle="h3"
              headingLevel="h1"
              title={event.EventName}
              service={event.EventSiteDetailDisplayName}
              showServiceTag={true}
              text={event.EventSubHeading || undefined}
            />
            {event?.EventBannerImage?.ImagePath && (
              <div className="mt-16">
                <ImageLoader
                  src={event.EventBannerImage?.ImagePath as string}
                  alt={event.EventName}
                  width={280}
                  height={200}
                  sizes="(max-width: 1023px) 100vw, 66vw"
                />
              </div>
            )}
          </div>

          <div className={colRightStyles}>
            <div className="bg-white-off p-10 xm:p-6 lg:ml-10 lg:p-10">
              <dl className="font-uniform-condensed">
                <dt className="xsbody mb-1 pt-2 font-bold uppercase text-grey-dark">date</dt>
                <dd className="border-b border-grey pb-4 text-meta font-medium text-black">
                  {dateRange(event.EventStartDate, event.EventEndDate, event.EventTimeZoneName, 'long')}
                </dd>
                <dt className="xsbody mb-1 pt-6 font-bold uppercase text-grey-dark">time</dt>
                {event.EventVirtualTimes && Object.values(event.EventVirtualTimes).length > 1 ? (
                  <>
                    {event.EventVirtualTimes.map((time: string, i: number) => (
                      <dd className="mb-3 text-meta font-medium uppercase text-black" key={i}>
                        {time}
                      </dd>
                    ))}
                    <div className="border-b border-grey pt-1" />
                  </>
                ) : (
                  <dd className="border-b border-grey pb-4 text-meta font-medium uppercase text-black">
                    {event.EventStartTime} - {event.EventEndTime} ({event.EventTimeZoneName})
                  </dd>
                )}
                <dt className="xsbody mb-1 pt-6 font-bold uppercase text-grey-dark">Attendance Method</dt>
                {event.EventLocationType && (
                  <dd className=" border-b border-grey pb-4 text-meta font-medium text-black">
                    {attendanceMethodCopy[event.EventLocationType as keyof typeof attendanceMethodCopy].short}
                  </dd>
                )}
                {!event.EventCanRegister && (
                  <div className="border-b border-grey py-4">
                    <ClosedNotification
                      className="lg:p-4"
                      isClosed={event.EventStatus === 'Closed'}
                      service={event.EventSiteDetailDisplayName}
                    />
                  </div>
                )}

                {event.EventCanRegister && (
                  <>
                    <dt className="xsbody sr-only font-bold uppercase text-grey-dark">Register</dt>
                    <dd className="border-b border-grey pb-6 pt-4 text-meta text-black">
                      {event.EventEnableExternalRegistration && event.EventExternalRegistrationUrl ? (
                        <Button
                          data-trackingid={event.Id}
                          type="primary"
                          service={event.EventSiteDetailDisplayName}
                          link={{ value: { href: event.EventExternalRegistrationUrl, text: 'Register Now' } }}
                          icon="chevron-right-double"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ) : (
                        <Button
                          data-trackingid={event.Id}
                          type="primary"
                          service={event.EventSiteDetailDisplayName}
                          link={{ value: { href: '#', text: 'Register Now' } }}
                          icon="chevron-right-double"
                          button={true}
                          onClick={() => openEventDrawer()}
                        />
                      )}
                    </dd>
                  </>
                )}
                <dt className="xsbody mb-1.5 pt-4 font-bold uppercase text-grey-dark">Share this event</dt>
                <dd className="text-meta text-black">
                  <ShareButtons
                    title={event.EventName}
                    description={event.EventSubHeading}
                    url={`${process.env.PUBLIC_URL}/events/events-detail/${event?.EventCode}`}
                  />
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutSection
