import { Field, ImageField, Image as JssImage, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { useRouter } from 'next/router'

import Button, { ButtonProps } from 'components/front-end/Buttons/Button'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import StandardTagList from 'components/front-end/Tags/StandardTagList'

interface ImageProps {
  field: ImageField
  fixed?: boolean
  lazy?: boolean
  button?: Pick<ButtonProps, 'link' | 'icon' | 'type' | 'data-trackingid'>
}

interface ImageCardProps extends ImageProps {
  title?: Field<string>
  image?: ImageProps
  className?: string
  service?: string
  url?: string
  tags?: string[]
  cardType?: string
  description?: Field<string>
  children?: JSX.Element[] | JSX.Element | undefined
}

const ImageCard = (props: ImageCardProps) => {
  const { sitecoreContext } = useSitecoreContext()
  const router = useRouter()
  const onCardClick = () => {
    if (props.url) {
      if (sitecoreContext.pageEditing) return
      return router.push(props.url as string)
    } else {
      return
    }
  }
  return (
    <div className="relative overflow-hidden">
      <div onClick={() => onCardClick()} className="field-link mb-4 cursor-pointer">
        <div className="opacity-overlay dark absolute left-0 top-0  flex w-full items-center px-6 pt-3 text-white shadow-[0_0_20px_20px_rgba(0,0,0,.6)]">
          {props.service && <ServiceTag service={props.service as string} isCard={true} />}
          {props.tags && <StandardTagList className="ml-auto dark:text-white" tags={props.tags} />}
        </div>
        <JssImage field={props.field} className="w-full" srcSet={[{ mw: 640 }, { mh: 360 }]} />
      </div>
      {props.cardType !== 'service' && props.title && (
        <h5 className="h6 mb-4">
          <Text field={props.title} />
        </h5>
      )}
      {props.description && (
        <p className="sbody">
          <Text field={props.description} />
        </p>
      )}
      {props.button?.link && (
        <div className="mt-10 flex flex-wrap gap-4">
          <Button
            data-trackingid={props.button['data-trackingid']}
            link={props.button.link}
            service={props.service}
            icon={props.button.icon || 'chevron-right-double'}
            type={props.button.type || 'small-secondary'}
          />
        </div>
      )}
    </div>
  )
}

export default ImageCard
