import { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs'

import { Stage } from './_JobInterface'

export type TitleContent = ReturnType<typeof buildTitle>
export const buildTitle = (title: string) => {
  return {
    title: { field: { value: title } },
  }
}

type BuildContentArgs = {
  copy?: string
  title?: string
}

const buildContent = ({ copy = '', title = '' }: BuildContentArgs): ComponentRendering | undefined => {
  return copy
    ? {
        componentName: 'ADFContentBlock',
        fields: {
          HeadingStyle: { value: 'h6' },
          LedeCopySize: { value: '16px' },
          Description: { value: copy },
          Title: { value: title },
          Type: { value: 'Text' },
        },
      }
    : undefined
}
type BuildSalaryContentArgs = {
  data: Stage[]
  service?: string
  isReserve?: boolean
}

const buildSalaryContent = ({
  data = [],
  service = '',
  isReserve = false,
}: BuildSalaryContentArgs):
  | (ComponentRendering & Pick<BuildSalaryContentArgs, 'service' | 'isReserve'>)
  | undefined => {
  return data
    ? {
        componentName: 'ADFJobSalaryDetails',
        fields: {
          Salary: { value: data },
        } as unknown as ComponentRendering['fields'],
        service,
        isReserve,
      }
    : undefined
}
type BuildButtonArgs = {
  link?: string
  service?: string
}

const buildButton = ({ link, service = '' }: BuildButtonArgs): ComponentRendering | undefined => {
  return link
    ? {
        componentName: 'ADFButton',
        params: { GridParameters: 'col-12', DynamicPlaceholderId: '3' },
        fields: {
          type: { value: 'primary' },
          link: {
            value: {
              text: 'Download',
              linktype: 'file',
              target: '_blank',
              href: link,
            },
          },
          icon: { value: 'download' },
          service: { value: service },
        },
      }
    : undefined
}

type BuildNotificationArgs = {
  service?: string
  message?: string
}

const buildNotification = ({ service = '', message = '' }: BuildNotificationArgs): ComponentRendering | undefined => {
  return message
    ? {
        componentName: 'ADFNotification',
        params: { GridParameters: 'col-12', DynamicPlaceholderId: '4' },
        fields: {
          Type: { value: 'primary' },
          Message: { value: message },
          Icon: { value: 'error' },
          Status: { value: 'Warning' },
          Service: { value: service },
        },
      }
    : undefined
}

class ContentBuilder {
  private content: ComponentRendering[] = []

  addContent(args: BuildContentArgs) {
    const content = buildContent(args)
    if (content) {
      this.content.push(content)
    }
    return this
  }

  addSalaryContent(args: BuildSalaryContentArgs) {
    const salaryContent = buildSalaryContent(args)
    if (salaryContent) {
      this.content.push(salaryContent)
    }
    return this
  }

  addButton(args: BuildButtonArgs) {
    const button = buildButton(args)
    if (button) {
      this.content.push(button)
    }
    return this
  }

  addNotification(args: BuildNotificationArgs) {
    const notification = buildNotification(args)
    if (notification) {
      this.content.push(notification)
    }
    return this
  }

  get() {
    return this.content
  }
}

export const create = () => {
  return new ContentBuilder()
}
