import React, { ReactElement } from 'react'
import { RichText as JssRichText } from '@sitecore-jss/sitecore-jss-nextjs'
import { twMerge } from 'tailwind-merge'

import ServiceTag from 'components/front-end/Tags/ServiceTag'

type StatementProps = {
  title: string
  text?: string
  service?: string
  showServiceTag: boolean
  headingLevel?: HeadingLevel
  headingStyle?: HeadingLevel
  variant?: HeadingVariants
  className?: string
}

export type HeadingLevel = (typeof headingLevels)[number]
export type HeadingVariants = 'normal' | 'pull-quote' | 'pull-quote-light'

const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const
const defaultHeadingLevel = 'h3' as const

const HeaderText = ({
  title,
  text,
  service,
  showServiceTag,
  headingLevel,
  headingStyle,
  variant = 'normal',
  className,
}: StatementProps): ReactElement<HTMLHeadingElement> => {
  const HeadingElement = headingLevel ?? defaultHeadingLevel
  const headingClasses = [headingStyle ?? headingLevel ?? defaultHeadingLevel, variant]
    .filter((style) => style && style !== 'normal' && style !== headingLevel)
    .join(' ')

  return (
    <div className="statement-panel [.dark_&]:!text-white">
      {service && showServiceTag && (
        <div className="mb-2 inline-flex align-top">
          <ServiceTag service={service || ''} />
        </div>
      )}
      <HeadingElement
        className={twMerge(headingClasses, variant === 'pull-quote-light' && 'font-normal', 'mb-12', className)}
      >
        <JssRichText tag="span" field={{ value: title }} />
      </HeadingElement>
      {text && <div className="xlarge">{text}</div>}
    </div>
  )
}

export default HeaderText
