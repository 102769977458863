export const TwIndicator = () => {
  if (!process.env.NEXT_PUBLIC_TWINDICATOR) {
    return null
  }

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {lg: 1280}
  // {xl: 1440}
  // {xxl: 1920}

  return (
    <div className="text-xs pointer-events-none fixed bottom-1 left-1 z-[99999] flex h-6 w-6 items-center justify-center rounded-full bg-white p-3 text-xstag font-semibold text-black">
      <div className="block sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block xm:hidden">md</div>
      <div className="hidden xm:block lg:hidden">xm</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="hidden xl:block xxl:hidden">xl</div>
      <div className="hidden xxl:block">xxl</div>
    </div>
  )
}
