import { useCallback, useEffect, useRef } from 'react'

const DEFAULT_DELAY = 100

export function debounce<T extends (...args: unknown[]) => void>(
  func: T,
  waitMilliseconds = DEFAULT_DELAY
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return function (this: unknown, ...args: Parameters<T>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this

    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      func.apply(context, args)
      timeoutId = null
    }, waitMilliseconds)
  }
}

export function useDebounce<T extends (...args: unknown[]) => void>(callback: T, delay = DEFAULT_DELAY): T {
  const callbackRef = useRef<T>(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((...args: Parameters<T>) => {
      callbackRef.current(...args)
    }, delay),
    [delay]
  ) as T
}
