import React from 'react'
import { ComponentParams, ComponentRendering, Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import { useGreaterThan } from 'src/utils/useBreakpoints'
import InfoPanel from './front-end/SitecoreHelpers/InfoPanel'

interface ContainerProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
}

export const Default = (props: ContainerProps): JSX.Element => {
  const containerStyles = props.params?.styles ?? ''
  const styles = `${props.params.GridParameters} ${containerStyles}`.trimEnd()
  const gridcols = props.params.gridcols as string
  const maxRowValue = props.params.MaxCardsPerRow as string
  const gridSize = useGreaterThan('xm') ? `grid-cols-${maxRowValue ? maxRowValue : '3'}` : 'grid grid-cols-1'

  const phKey = `container-${props.params.DynamicPlaceholderId}`
  const id = props.params.RenderingIdentifier
  const { sitecoreContext } = useSitecoreContext()

  return (
    <>
      {sitecoreContext.pageEditing && (
        <InfoPanel heading="Sitecore | Cartegory Card Wrapper" message="Please add Category Cards below" />
      )}

      <div className={`relative ${styles} grid w-full gap-6 ${gridSize}`} id={id ? id : undefined} data-cols={gridcols}>
        <Placeholder name={phKey} rendering={props.rendering} />
      </div>
    </>
  )
}
