import Icon from "components/front-end/Icons/Icon";
import parse from 'html-react-parser';

type ContentProps = {
    title?: string;
    bodyContent: string;
  };
  
  const Content = ({ title = '', bodyContent }: ContentProps): JSX.Element => {

    return (
        <div className="py-6">
            { title && (
                    <div className="pb-4">
                        <div className={'h6'}>{title}</div>
                    </div>
                ) 
            }
            <div className="mbody text-grey-medium">{parse(bodyContent)}</div>
        
        </div>
    );
  };
  
  export default Content;
  