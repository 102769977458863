import React, { useState } from 'react'
import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'

import Drawer from 'components/front-end/Drawers/Drawer'
import { ComponentProps } from 'lib/component-props'
import SimpleCard from './front-end/Cards/SimpleCard'
import CarouselSwiper from './front-end/Carousel/CarouselSwiper'
import FAQContent from './front-end/FAQ/FAQContent'
import { buildLookupData } from './front-end/FAQ/FAQHelpers'
import { FAQData } from './front-end/FAQ/FAQTypes'

type FaqSearchProps = ComponentProps & {
  fields: {
    SearchInputFieldLabel: Field<string>
    SearchInputFieldPlaceholder: Field<string>
    SearchResultsSubcategoryTitle: Field<string>
    FAQSearchURL: Field<string>
    FAQSearchResultsPage: LinkField
    NoSearchResultsTitle: Field<string>
    NoSearchResultsCopy: Field<string>
    items: FAQData
  }
}
export const ADFFaqCarousel = (props: FaqSearchProps): JSX.Element | null => {
  const { fields } = props
  const lookup = buildLookupData(fields.items)
  const id = props.params.RenderingIdentifier ?? props.params.DynamicPlaceholderId
  const hideFilters = props.params.HideFaqFilters ?? false // 1 = true, 0 = false
  const [filters] = useState(fields.items.subcategories.map((item) => item))
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [drawerItem, setDrawerItem] = useState<number>(0)

  const [activeFilters, setActiveFilters] = useState<string[]>([])

  const setActiveFilter = (filter: string) => {
    if (activeFilters?.includes(filter)) {
      setActiveFilters(activeFilters.filter((f) => f !== filter))
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  const filteredFaqs = activeFilters.length
    ? lookup.faqsInSubcategories(activeFilters).map((faqId) => lookup.faqs[faqId])
    : Object.values(lookup.faqs)

  if (!filteredFaqs.length) {
    return null
  }

  const selectedFAQ = filteredFaqs[drawerItem]

  const filterButtonClasses =
    'py-3 px-4 bg-grey-pale text-black xm:hover:bg-grey-dark xm:hover:text-white dark:bg-grey-medium text-nowrap'
  const filterButtonActiveClasses = '!bg-grey-dark text-white dark:!bg-white dark:!text-black'
  return (
    <>
      {!hideFilters && filters.length > 1 && (
        <div data-cols="12" className="adf-scroll-bar-container">
          <div className="adf-scroll-bar my-6 flex gap-3 overflow-x-auto pb-2">
            {filters.map((filter, i) => (
              <button
                key={i}
                className={`${filterButtonClasses} ${
                  activeFilters?.includes(filter.id) ? filterButtonActiveClasses : ''
                }`}
                onClick={() => {
                  setActiveFilter(filter.id)
                }}
              >
                {filters[i].faqSubcategoryTitle}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="container-break-right mb-20">
        <CarouselSwiper
          columns={filteredFaqs.length > 3 ? 4 : undefined}
          alignControls="right"
          id={id ?? (props.rendering.uid as string)}
        >
          {filteredFaqs.map((item, i: number) => {
            return (
              <SimpleCard
                key={i}
                className="h-full border border-grey-light"
                title={item.question}
                headingLevel="h6"
                icon={item.icon || 'goals'}
                buttons={[
                  {
                    'data-trackingid': item.id,
                    'data-trackinglabel': item.question,
                    'data-location': 'FAQ',
                    link: { value: { href: '#', text: 'View Answer' } },
                    icon: 'chevron-right-double',
                    type: 'small-secondary',
                    button: true,
                    onClick: () => {
                      setDrawerIsOpen(true)
                      setDrawerItem(i)
                    },
                  },
                ]}
                rightButtonText={null}
                unsavedButtonText={null}
                savedButtonText={null}
                tags={[item.heading]}
              />
            )
          })}
        </CarouselSwiper>
      </div>
      {selectedFAQ ? (
        <Drawer title={selectedFAQ.heading} show={drawerIsOpen} closeDrawer={() => setDrawerIsOpen(false)} hideFooter>
          <FAQContent {...selectedFAQ} />
        </Drawer>
      ) : null}
    </>
  )
}

export default withDatasourceCheck()<FaqSearchProps>(ADFFaqCarousel)
