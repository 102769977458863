import React from 'react'
import router from 'next/router'

import usePageContext from 'components/front-end/_layoutContext'
import { ProfileTabPageProps } from 'components/front-end/ProfileTab/_ProfileTabInterface'
import ProfilePicture from 'components/front-end/ProfileTab/ProfilePicture'
import ProfileTab from 'components/front-end/ProfileTab/ProfileTab'

type ProfileTabWrapperProps = ProfileTabPageProps & {
  hidden: boolean
}

const ProfileTabWrapper = ({ fields, hidden }: ProfileTabWrapperProps): JSX.Element => {
  const pageState = usePageContext().pageContext
  const results = fields?.data?.datasource?.children?.results || []

  return (
    <div
      className={`relative w-full transition-opacity duration-500 ${
        pageState.footer.isVisibleFooter || hidden ? 'pointer-events-none opacity-0' : 'pointer-events-auto opacity-100'
      }`}
    >
      <ProfileTab>
        {results.map((result, index) => (
          <ProfilePicture
            key={index}
            profileID={result.profileID}
            profileName={result.profileTitle.value}
            profileImage={result?.profileImage?.jsonValue?.value?.src || ''}
            url={result.url.value}
            isActive={router.asPath === result.url.value} // Determine isActive conditionally
          />
        ))}
      </ProfileTab>
    </div>
  )
}

export default ProfileTabWrapper
