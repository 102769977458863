import { MouseEventHandler } from 'react'

import { EventSearchResult } from 'components/ADFEventSearchResults'
import { ButtonProps } from 'components/front-end/Buttons/Button'
import Card from 'components/front-end/Cards/Card'
import { EventCardDetails, eventLocationTag } from '../EventCard'

type EventSummaryCardProps = {
  data: EventSearchResult
  onClickRegister: MouseEventHandler<HTMLButtonElement>
  className?: string
}

const EventSummaryCard: React.FC<EventSummaryCardProps> = ({ data, onClickRegister, className }) => {
  const buttonList = (result: EventSearchResult) => {
    const eventOpen = !result.eventCapacityIsFull && !result.isClosed

    const b: ButtonProps[] = [
      {
        'data-trackingid': data.id,
        link: {
          value: {
            href: result.url || '#',
            text: 'Explore Event',
          },
        },
        icon: 'chevron-right-double',
        type: 'small-secondary',
      },
    ]
    if (result.eventStatus === 'Published') {
      if (eventOpen) {
        if (result.EventEnableExternalForm && result.eventFormExternalUrl) {
          b.push({
            'data-trackingid': data.id,
            link: { value: { href: result.eventFormExternalUrl, text: 'Register Now' } },
            icon: 'edit',
            type: 'small-action',
            target: '_blank',
            rel: 'noopener noreferrer',
          })
        } else {
          b.push({
            'data-trackingid': data.id,
            link: { value: { href: '#', text: 'Register Now' } },
            icon: 'edit',
            type: 'small-action',
            button: true,
            onClick: onClickRegister,
          })
        }
      } else {
        b.push({
          'data-trackingid': data.id,
          link: {
            value: {
              href: '#',
              text: 'Event Full',
            },
          },
          icon: 'error',
          type: 'small-action',
          button: true,
          disabled: true,
        })
      }
    }
    return b
  }

  return (
    <Card
      className={className}
      service={data.service}
      title={data.title}
      subtitle={data.eventType}
      id={data.id}
      image={[data.image]}
      imageHeight={230}
      imageWidth={386}
      tags={data.eventStatus === 'Closed' ? ['Closed'] : eventLocationTag(data.EventLocationType)}
      dateTag={{ dateString: data.eventStartDate, service: data.service }}
      rightButtonText="" // forced in type
      unsavedButtonText="" // forced in type
      savedButtonText="" // forced in type
      headingAriaLevel={2}
      buttons={buttonList(data) as ButtonProps[]}
    >
      <EventCardDetails event={data} className="sbody-bold flex-col gap-x-5 text-grey-medium xm:mt-3" />
    </Card>
  )
}

export default EventSummaryCard
