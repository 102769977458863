import React, { useState } from 'react'
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Placeholder,
  PlaceholdersData,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs'

import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'
import MainNavBack from './front-end/Navigation/MainNavBack'

export type TabType = {
  title: {
    field: Field<string>
  }
  tag: {
    field: Field<string>
  }
}

interface Fields {
  data: {
    datasource: {
      children: {
        results: TabType[]
      }
      field: {
        type: Field<string>
      }
    }
  }
}

interface TabListProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
  placeholders?: PlaceholdersData
}

const Default = (props: TabListProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const [tab] = useState(0)
  const [mobOpen, setMobOpen] = useState<boolean>(false)

  // const id = props.params.RenderingIdentifier ?? undefined
  const isEdit = sitecoreContext.pageState === 'edit'

  // const themes = ['light', 'dark']
  // const theme = typeof window !== 'undefined' ? (document.body.classList.contains(themes[1]) ? themes[1] : themes[0]) : themes[0]
  // const type = props?.fields?.data?.datasource?.field?.type?.value

  const containerStyles = `${props.params?.GridParameters ?? ''} ${props.params?.styles ?? ''}`
  // const styles = `${theme === themes[0]? 'bg-[#f8f8f8]': 'bg-[#2c2c2c]'} ${theme}`
  const gridcols = Number(props.params?.gridcols ?? '12')

  return (
    <>
      <div className={`relative ${containerStyles}`}>
        <div className={`pb-12 pt-12`}>
          <section className="tab-list border-grey-light xm:border-y [.dark_&]:border-grey-medium">
            <div data-cols={gridcols}>
              <nav className="tab-nav flex flex-wrap xm:flex-nowrap">
                {props?.fields?.data?.datasource?.children?.results?.map((x, i) => <div key={i}>ADF Story Panel</div>)}
              </nav>
            </div>
          </section>
        </div>

        {isEdit && (
          <div className="tab-content relative" data-cols={gridcols}>
            {[...Array(Number(9))].map((_item, i) => {
              return (
                <div
                  key={i}
                  className={`${tab !== i ? 'hidden' : '[&>.sc-jss-empty-placeholder>*:nth-child(n+4)]:hidden'}`}
                >
                  <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
                  {isEdit && (
                    <div id="grid">
                      {[...Array(gridcols)].map((_x, i) => (
                        <div key={`grid_${i}`}>{i + 1}</div>
                      ))}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}

        {useGreaterThan('xm') && !isEdit && (
          <div className="tab-content relative" data-cols={gridcols}>
            {[...Array(Number(9))].map((_item, i) => {
              return (
                <div key={i} className={`${tab !== i ? 'hidden' : ''}`}>
                  <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
                </div>
              )
            })}
          </div>
        )}
      </div>

      {useLessThan('xm') && !isEdit && mobOpen && (
        <div className="tab-content-takeover fixed top-0 z-50 h-full w-full bg-white">
          <div className="tab-content-back mb-5 border-b border-grey-light">
            <div data-cols={gridcols} className="flex h-16 items-center">
              <div className="flex-1">
                <MainNavBack title="Back" onClick={() => setMobOpen(false)} />
              </div>
            </div>
          </div>
          <div data-cols={gridcols}>
            {[...Array(Number(9))].map((_item, i) => (
              <div key={i} className={`${tab !== i ? 'hidden' : ''}`}>
                <Placeholder name={`placeholder0-${i}`} rendering={props.rendering} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Default
