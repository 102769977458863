import React from 'react'
import {
  Field,
  RichText as JssRichText,
  ComponentRendering,
  ComponentParams,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs'

interface Fields {
  Content: Field<string>
}

export type ContentHTMLProps = {
  rendering: ComponentRendering & { params: ComponentParams }
  params: { [key: string]: string }
  fields: Fields
}

export const ContentHtml = (props: ContentHTMLProps): JSX.Element => {
  const id = props.params.RenderingIdentifier

  return (
    <div className={`component rich-text ${props.params}`} id={id ? id : undefined}>
      <div className="component-content [&_img_]:max-w-full">
        <JssRichText field={props.fields?.Content} />
      </div>
    </div>
  )
}

export default withDatasourceCheck()<ContentHTMLProps>(ContentHtml)
