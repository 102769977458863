import FocusTrap from 'focus-trap-react'
import { twJoin, twMerge } from 'tailwind-merge'

import { navElementId, NavigationItemWithChildren } from './navigationHelpers'
import { NavigationItemList } from './NavigationItemList'
import {
  NavigationContext,
  NavigationLevel,
  navigationLevels,
  navigationTrapStack,
  useNavigation,
} from './NavigationProvider'

interface NavigationMenuProps extends React.HTMLAttributes<HTMLElement> {
  level: NavigationLevel
  className?: string
  listClassName?: string
  itemClassName?: string
  activeItemClassName?: string
  mobileMode?: boolean
}

export default function NavigationMenu({
  level,
  mobileMode,
  className,
  listClassName,
  itemClassName,
  ...props
}: NavigationMenuProps) {
  const [, send] = NavigationContext.useActor()
  const {
    activePath,
    navIsActivated,
    secondaryNavIsActivated,
    tertiaryNavIsActivated,
    visibleNavItems,
    renderedNavItems,
  } = useNavigation()

  const levelIndex = navigationLevels.indexOf(level)

  const handleClick = () => {
    send({ type: 'DEACTIVATE_NAVIGATION' })
  }

  const handleIntertaction = (item: NavigationItemWithChildren) => {
    if (activePath[activePath.length - 1]?.url !== item.url) {
      const type = `INTERACT_${level.toUpperCase() as Uppercase<NavigationLevel>}_NAV` as const
      send({ type, item })
    }
  }

  const groupClassName = ['group/primary', 'group/secondary', 'group/tertiary'][levelIndex]
  const menuHidden = level !== 'primary' ? visibleNavItems[levelIndex] === undefined : undefined
  const currentNavIsActivated =
    (level === 'primary' && navIsActivated) ||
    (level === 'secondary' && secondaryNavIsActivated) ||
    (level === 'tertiary' && tertiaryNavIsActivated)

  const source = mobileMode ? [visibleNavItems[activePath.length]] : renderedNavItems[levelIndex]

  return (
    <nav
      role="navigation"
      aria-label={`${level} navigation`}
      data-level={level}
      data-active={!menuHidden}
      id={`${level}-navigation`}
      className={twJoin(groupClassName, className)}
      {...props}
    >
      {source.map((items, index) => {
        const listParent = items[0]?.parent

        const listHidden = mobileMode
          ? !navIsActivated
          : level !== 'primary'
            ? items !== visibleNavItems[levelIndex]
            : !navIsActivated

        return items.length ? (
          <FocusTrap
            key={`${listParent?.url}-${index}`}
            active={!mobileMode && currentNavIsActivated && items === visibleNavItems[levelIndex]}
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              returnFocusOnDeactivate: true,
              trapStack: navigationTrapStack,
              preventScroll: true,
              initialFocus: () => document.querySelector(`#${level}-navigation > ul[aria-hidden="false"]`),
              onActivate: () => {
                const l = navigationTrapStack?.length ?? 0
                if (l > 0) {
                  send({type: 'LOCK_TRAP' })
                }
              },
              onPostDeactivate: () => {
                const l = navigationTrapStack?.length ?? 0
                if (l > 0) {
                  send({type: 'UNLOCK_TRAP' })
                }
              },
            }}
          >
            <ul
              role="menu"
              aria-hidden={listHidden}
              aria-label={listParent ? `${listParent.navigationTitle} subcategories` : undefined}
              aria-labelledby={navElementId(listParent, level, 'link')}
              data-menu={navElementId(listParent, level, 'menu')}
              className={twMerge(
                'focus-visible:ring-0',
                listClassName,
                level !== 'primary' &&
                  'pointer-events-auto pb-12 opacity-100 delay-150 duration-300 ease-in-out aria-hidden:pointer-events-none aria-hidden:max-h-full aria-hidden:overflow-hidden aria-hidden:opacity-0 aria-hidden:duration-200 aria-hidden:ease-in-out'
              )}
            >
              <NavigationItemList
                level={level}
                items={items}
                className={twJoin('group/link', itemClassName)}
                onClick={handleClick}
                onInteraction={handleIntertaction}
                onFocusTrapDeactivate={() => {
                  const l = navigationTrapStack?.length ?? 0
                  if (l > 0) {
                    const last = navigationTrapStack![l - 1]
                    last.deactivate()
                  }
                }}
                indicateChildren={mobileMode || level !== 'primary'}
              />
            </ul>
          </FocusTrap>
        ) : null
      })}
    </nav>
  )
}
