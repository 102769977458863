import FocusTrap from 'focus-trap-react'
import { twJoin } from 'tailwind-merge'

import usePageContext from 'components/front-end/_layoutContext'
import Button from 'components/front-end/Buttons/Button'
import { TranscriptProps } from './_AudioInterface'

const AudioTranscript = ({
  id,
  group,
  transcript,
  open,
  onClick,
  story = false,
  service = 'white',
}: TranscriptProps) => {
  const { audio } = usePageContext().pageContext

  const currentPlayerIndex = audio.audioPlayActive

  const isOpen = Boolean(open || (group && audio.isScriptOpen))
  const trapFocus = isOpen && !group

  return (
    <FocusTrap active={trapFocus} focusTrapOptions={{ clickOutsideDeactivates: true }}>
      <div
        className={`transcription${story ? ' dark' : ''}`}
        role="dialog"
        aria-modal="true"
        aria-label="Audio transcript"
      >
        <div
          className={twJoin(
            'transition-height fixed inset-x-0 bottom-0 pb-[120px] pt-[150px] font-uniform-condensed text-lg antialiased duration-500 md:text-xl xm:pt-[300px] [.dark_&]:!bg-black [.dark_&]:!text-white [.grey_&]:!bg-black [.grey_&]:text-white [.light_&]:bg-white [.light_&]:text-black',
            group ? 'z-[49]' : 'z-[99]',
            isOpen ? 'top-0 overflow-auto' : 'top-full overflow-hidden'
          )}
        >
          <div>
            {!group ? (
              <div
                className="absolute right-10 top-10 z-40 transition duration-300"
                aria-describedby="audio-transcript"
              >
                {open && (
                  <div>
                    <input className="hidden" />
                    <Button
                      data-trackingid={id}
                      link={{ value: { text: 'close' } }}
                      icon="close"
                      type="action"
                      button
                      onClick={() => {
                        onClick?.()
                      }}
                      isSmall
                      service={service}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div
                className="absolute right-10 top-10 z-40 transition duration-300"
                aria-describedby="audio-transcript"
              >
                {audio.isScriptOpen && (
                  <div className="mt-[100px] bg-black xm:mt-[140px]">
                    <input className="hidden" />
                    <Button
                      data-trackingid={id}
                      link={{ value: { text: 'close' } }}
                      icon="close"
                      type="action"
                      button
                      onClick={() => {
                        audio.setIsScriptOpen(false)
                      }}
                      isSmall
                      service={service}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="mx-auto max-w-[1112px] px-4 py-8" id="audio-transcript">
              {group ? audio.audioPlayList[currentPlayerIndex].audioTranscript : transcript}
            </div>
            <div
              className={twJoin(
                'fixed bottom-0 left-0 h-[200px] w-full bg-gradient-to-t transition duration-500 [.dark_&]:!from-black/100 [.dark_&]:!from-50% [.dark_&]:!to-black/0 [.light_&]:from-white/100 [.light_&]:from-50% [.light_&]:to-white/0',
                isOpen ? 'translate-y-0' : 'translate-y-full'
              )}
            />
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}

export default AudioTranscript
