import React from 'react'
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'

import { PageHeaderComponent, PageHeaderProps } from './front-end/PageHeader/PageHeader'

const CategoryHeaderComponent = (props: PageHeaderProps<'Header'>) => {
  return <PageHeaderComponent {...props} contentComponentType="Header" />
}

export default withDatasourceCheck()<PageHeaderProps<'Header'>>(CategoryHeaderComponent)
