import ImageLoader from 'components/front-end/Images/ImageLoader'
import ImageSwiper from 'components/front-end/Images/ImageSwiper'

const CardImage = ({ image }: { image: string[] }) => {
  return (
    <>
      {image?.length === 1 ? (
        <ImageLoader full height={298} width={386} src={image[0]} alt={image[0]} />
      ) : (
        <ImageSwiper full>
          {image?.map((x) => <ImageLoader full key={x} height={298} width={386} src={x} alt={x} />)}
        </ImageSwiper>
      )}
    </>
  )
}

export default CardImage
