import { useState } from 'react'
import { Controller, EffectFade } from 'swiper'
import { Swiper, SwiperSlide, type SwiperClass } from 'swiper/react'

import type { QuoteCarouselItem } from 'components/ADFQuoteCarousel'
import QuoteSlideText from './QuoteSlideText'

type QuoteTextCarouselProps = {
  items: QuoteCarouselItem[]
  onSwiper: (swiper: SwiperClass) => void
  id: string
  service?: string
}

const QuoteTextCarousel = ({ items, onSwiper, id, service = '' }: QuoteTextCarouselProps) => {
  const [isSwiperTouch, setIsSwiperTouch] = useState(false)
  return (
    <Swiper
      modules={[Controller, EffectFade]}
      onSwiper={onSwiper}
      effect="fade"
      fadeEffect={{ crossFade: false }}
      loop={false}
      allowTouchMove={false}
      initialSlide={items.length - 1}
      className="h-full xm:w-[calc(100dvw*5/12-6px)] [&.swiper]:mx-0"
      navigation={{
        nextEl: `.swiper-next-${id}`,
        prevEl: `.swiper-prev-${id}`,
      }}
      autoHeight
      observeParents
      observer
      onTouchMove={() => {
        !isSwiperTouch && setIsSwiperTouch(true)
      }}
      onTouchEnd={() => {
        setTimeout(() => {
          setIsSwiperTouch(false)
        }, 300)
      }}
    >
      {items.map(({ fields, id }) => (
        <SwiperSlide key={`text-${id}`}>
          <div
            className={`
              relative mx-[--outermargin] h-full
              -translate-x-1/4 scale-95 opacity-0 transition duration-300 xm:mx-0
              [.swiper-slide-active+.swiper-slide_&]:translate-x-1/4
              [.swiper-slide-active+.swiper-slide_&]:scale-95
              [.swiper-slide-active_&]:translate-x-0
              [.swiper-slide-active_&]:scale-100
              [.swiper-slide-active_&]:opacity-100
              [.swiper-slide-active_&]:delay-75
            `}
          >
            <QuoteSlideText id={id} fields={fields} service={service} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default QuoteTextCarousel
