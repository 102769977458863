import React, { useRef, useState } from 'react'
import Link from 'next/link'

import Icon from 'components/front-end/Icons/Icon'
import JobDetailsTypes from 'components/front-end/JobDetails/_JobInterface'
import JobTabHeader from 'components/front-end/JobDetails/JobTabHeader'
import QuickLink from 'components/front-end/QuickLinks/QuickLink'
import { Service } from 'utils/useService'
import JobTabPanel from './JobTabPanel'

const JobNextSteps = ({
  responseData,
  animateClasses: [animateRightClasses, animateDownClasses] = [],
  id,
}: JobDetailsTypes) => {
  const [mousePosition, setMousePosition] = useState({ left: 0 })
  const tabElement = useRef<HTMLAnchorElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const offsetLeft = tabElement?.current?.getBoundingClientRect().left || 0
    const width = tabElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < 80) finalX = 0
    if (finalX > width - 80) finalX = width

    setMousePosition({ left: finalX })
  }

  const [isHovering, setIsHovering] = useState(-1)

  if (!responseData) {
    return null
  }

  const ellipsisStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical' as const,
  }

  const buttonClasses = `
    svg-hover
    group z-0 relative flex flex-col items-start
    min-w-full
    p-[18px] xm:px-8 xm:py-6
    text-left overflow-hidden transition duration-400
    pr-12
  `
  const listClasses = `
    first:mt-3 last:mb-3
    border-t last:border-b
    border-solid border-grey-light [.dark_&]:border-grey-medium
  `
  const titleClasses = `
    z-[1] relative block
    text-gray-medium [.dark_&]:text-white
    group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black
    [.dark_&]:group-[.is-active]:text-black
  `
  const iconClasses = `
    z-[1] absolute right-[18px] top-1/2
    group-hover:text-white group-[.is-active]:text-white [.dark_&]:group-hover:text-black
    [.dark_&]:text-white
    -translate-y-1/2
`
  const fillClasses = `
    tab-fill z-0 absolute top-0 left-0 h-full w-full
    bg-black-off [.dark_&]:bg-white
    scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
    transition duration-400 ease-linear
`

  return (
    <JobTabPanel id={id}>
      <JobTabHeader
        className={animateRightClasses}
        title={responseData?.nextStepHeader1 ?? ''}
        apply={responseData?.applyUrl}
        jobId={responseData?.id}
        service={responseData?.service}
        closed={Boolean(responseData?.isClosed)}
        isPriority={Boolean(responseData?.isPriority)}
      />

      <section>
        <div className="relative" data-cols="12">
          <div className="row">
            <div className="span-12 md-span-4 lg-span-3 md:mr-[calc(var(--colwidth))]">
              <div className={animateRightClasses}>
                <h3 className="h4 mb-4 font-normal normal-case lg:mb-5">{responseData.linksHeader}</h3>
                <p className="mb-15 mb-4">{responseData.linksSubHeader}</p>
              </div>
            </div>
            <div className="span-12 md-span-7 lg-span-8 xxl-span-7">
              <div className={animateDownClasses}>
                <ul>
                  {responseData.links &&
                    responseData.links.map((link, i) => (
                      <li key={i} className={listClasses}>
                        <Link
                          key={i}
                          ref={tabElement}
                          className={`${buttonClasses} icon-hover-${isHovering === i}`}
                          tabIndex={0}
                          onMouseMove={(ev) => handleMouseMove(ev)}
                          onMouseEnter={() => setIsHovering(i)}
                          onMouseLeave={() => setIsHovering(-1)}
                          href={link.url || '#'}
                          target={link.target}
                        >
                          <span className={titleClasses} style={ellipsisStyles}>
                            {link.title}
                          </span>
                          <Icon name="chevron-right-double" type="stroke" hover className={iconClasses} />
                          <div className={fillClasses} style={{ transformOrigin: mousePosition.left }}></div>
                        </Link>
                      </li>
                    ))}
                </ul>
                {responseData.quickLinks && (
                  <div className="mb-5">
                    {responseData.quickLinks.map(({ url, header, description, target }, i) => (
                      <QuickLink
                        key={i}
                        link={{
                          value: {
                            href: url,
                            title: header,
                            text: description,
                            target: target,
                          },
                        }}
                        service={responseData.service as Service}
                        className="quicklink !mb-3"
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </JobTabPanel>
  )
}

export default JobNextSteps
