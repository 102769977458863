import React, { useEffect, useRef, useState } from 'react'
import { usePathname } from 'next/navigation'
import { A11y, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { track } from 'src/utils/tracking'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import CarouselSwiperControls, { CarouselSwiperControlsProps } from './CarouselSwiperControls'

import 'swiper/css'
import 'swiper/css/navigation'
import { Element } from 'html-react-parser'
import { useScreen } from 'utils/useScreen'

interface CarouselSwiperProps extends CarouselSwiperControlsProps {
  children: JSX.Element[] | JSX.Element | undefined
  className?: string
  columns?: number
}

const CarouselSwiper = (props: CarouselSwiperProps) => {
  const length = (props.children as unknown as Element[]).length ?? 1
  const cols = props.columns || (length <= 3 ? length : 3)
  const scrollbarWidthRef = useRef<boolean>(true)
  const dataTrackingComponent = useRef<string | null>(null)
  const [showControls, setShowControls] = useState<boolean>(false)
  const [isSwiperTouch, setIsSwiperTouch] = useState(false)
  const path = usePathname()
  const screen = useScreen()

  const calculateScrollbarWidth = () => {
    if (scrollbarWidthRef.current) {
      const root = document.documentElement
      const scrollbarWidth = window.innerWidth - root.clientWidth
      root.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`)
    }
  }

  useEffect(() => {
    if (cols) {
      const root = document.documentElement
      root.style.setProperty('--slide-cols', `${screen == "xs" || screen == "sm" ? 1 : cols}`)
    }
  }, [screen])

  useEffect(() => {
    const parentTrackingComponent = document.querySelector('[data-tracking-component]') as HTMLDivElement
    dataTrackingComponent.current = parentTrackingComponent?.dataset.trackingComponent || 'CarouselSwiper'

    calculateScrollbarWidth()
    scrollbarWidthRef.current = true
    const resizeObserver = new ResizeObserver(calculateScrollbarWidth)
    resizeObserver.observe(document.documentElement)
    return () => {
      resizeObserver.disconnect()
      scrollbarWidthRef.current = false
    }
  }, [])

  return (
    <Swiper
      className={`carousel-swiper ${props.className || ''} ${cols === 4 ? 'four-up' : ''}`}
      modules={[Navigation, A11y]}
      watchOverflow={true}
      navigation={{
        nextEl: `.swiper-next-${props.id}`,
        prevEl: `.swiper-prev-${props.id}`,
      }}
      spaceBetween={useGreaterThan('xl') ? 24 : 16}
      slidesPerView={useGreaterThan('sm') ? cols : 1}
      onAfterInit={(swiper) => {
        setTimeout(() => {
          swiper?.navigation && swiper.navigation.update()
          setShowControls(true)
        }, 1)
      }}
      onTouchMove={() => {
        !isSwiperTouch && setIsSwiperTouch(true)
      }}
      onTouchEnd={() => {
        setTimeout(() => {
          setIsSwiperTouch(false)
        }, 300)
      }}
      onSlideChange={(swiper) => {
        isSwiperTouch
          ? track({
              event: 'carousel_swipe',
              ga4category: 'Carousel',
              ga4action: `Swipe - ${swiper.activeIndex < swiper.previousIndex ? 'Backward' : 'Forward'}`,
              ga4name: `${dataTrackingComponent.current} - ${path}`,
            })
          : track({
              event: 'carousel_click',
              ga4category: 'Carousel',
              ga4action: `Click - ${swiper.activeIndex < swiper.previousIndex ? 'Previous' : 'Next'}`,
              ga4name: `${dataTrackingComponent.current} - ${path}`,
            })
      }}
    >
      {React.Children.map(props.children, (child, i) => (
        <SwiperSlide className="!h-auto" key={i}>
          {child}
        </SwiperSlide>
      ))}
      <div className={`mr-[--outermargin] mt-8 transition ${showControls ? 'opacity-100' : 'opacity-0'}`}>
        <CarouselSwiperControls id={props.id as string} alignControls={props.alignControls} />
      </div>
    </Swiper>
  )
}

export default CarouselSwiper
