import React, { ReactNode } from 'react'

import Button from 'components/front-end/Buttons/Button'
import SummaryInfo from 'components/front-end/SummaryInfo/SummaryInfo'

type KeyInfoPanelProps = {
  children: JSX.Element[] | undefined
}

const KeyInfoPanelPageComponent = ({ children }: KeyInfoPanelProps): JSX.Element => {
  const summaryInfoComponents = children?.filter((child) => child.type === SummaryInfo) //this will filter the children for all the summary info components and store it
  const buttonComponents = children?.filter((child) => child.type === Button) // this will filter the children for all the button components and store it
  const firstDiv = 'sm:w-full md:w-full lg:w-[424px] xl:w-[483px] xxl:w-[483px] bg-[#f8f8f8]'
  const componentMainDiv =
    'md:pt-4 xs:pt-2 lg:pt-4 md:pr-4 xs:pr-2 lg:pr-4 md:pl-4 xs:pl-2 lg:pl-4 md:pb-10 xs:pb-8 lg:pb-8'
  const listGrid = 'md:grid md:grid-cols-2 lg:grid lg:grid-cols-1 mx-0 px-6 pt-2'
  const individualGrid = 'border-b border-grey-light'
  const buttonGrid = 'pt-8 mx-0 px-6 flex'
  const individualButton = 'pr-3'

  return (
    <div className={firstDiv}>
      <div className="grid grid-cols-1">
        <div className={componentMainDiv}>
          {children?.filter((child) => child.type !== SummaryInfo && child.type !== Button)}

          {summaryInfoComponents && ( // loop for summary info component
            <div className={listGrid}>
              {summaryInfoComponents.map((child, index) => (
                <div className={individualGrid} key={index}>
                  {child}
                </div>
              ))}
            </div>
          )}

          {buttonComponents && ( //loop for button component
            <div className={buttonGrid}>
              {buttonComponents.map((child, index) => (
                <div className={individualButton} key={index}>
                  {child}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default KeyInfoPanelPageComponent
