import { FocusEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Field } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Icon from '../Icons/Icon'
import SearchInput from '../Search/SearchInput'
import SearchInputResults from '../Search/SearchInputResults'

export type FacetType = {
  id: string
  displayName: Field<string>
  name: Field<string>
  groupingName: {
    targetItem: {
      name: string
    } | null
  }
}

type CustomSelectProps = {
  degreeResults: FacetType[]
  degreeSearchTitle: string
  degreeSearchPlaceholder: string
  routeQuery: string
  showDegrees?: boolean
  updateResults: (query: string, selectedDegree: string) => void
  removeDegreeSearchResults?: () => void
}

const CustomSelect = ({
  degreeResults,
  degreeSearchTitle,
  degreeSearchPlaceholder,
  routeQuery,
  showDegrees,
  updateResults,
  removeDegreeSearchResults,
}: CustomSelectProps) => {
  const route = useRouter()
  const path = route?.asPath?.split('?')?.[0] ?? ''
  const [degreeList, setDegreeList] = useState<FacetType[]>([])
  const [selectedDegree, setSelectedDegree] = useState<string>('')
  const currentQueryParams = useRef(route.query)
  const divRef = useRef<HTMLDivElement>(null)

  const showHideOptions = (e: MouseEvent | FocusEvent<HTMLInputElement>) => {
    e.stopPropagation?.()
    if (degreeList.length > 0) {
      setDegreeList([])
    } else {
      setDegreeList(degreeResults)
    }
  }

  const handleRouteChange = useCallback(() => {
    const { query: queryParam } = route
    if (currentQueryParams.current.degree !== queryParam.degree) {
      currentQueryParams.current = queryParam
      const currentQuery = queryParam.degree as string
      const item = degreeResults.find((item) => {
        return item.name.value === currentQuery.split(' ').join('-')
      })
      const displayName = item?.displayName?.value as string
      setSelectedDegree(displayName)
      updateResults('', displayName)
      setDegreeList([])
    }
  }, [route, degreeResults, updateResults])

  const iconEl = selectedDegree ? (
    <Icon name="close" type="stroke" height={34} width={34} className="[&_path]:!stroke-2" />
  ) : (
    <Icon name="chevron-down" type="stroke" height={34} width={34} className="[&_path]:!stroke-2" />
  )

  const handleIconClick = (e: MouseEvent) => {
    if (selectedDegree) {
      const { query } = route
      const currentPath = query.path
      const path = currentPath ? (Array.isArray(currentPath) ? currentPath.join('/') : currentPath) : '/'
      setSelectedDegree('')
      setDegreeList([])
      removeDegreeSearchResults?.()
      currentQueryParams.current = {}
      route.push(
        {
          pathname: path,
          query: null,
        },
        undefined,
        { shallow: true, scroll: false }
      )
    } else {
      showHideOptions(e)
    }
  }

  // Load job results when degree is selected and route changes
  useEffect(() => {
    route.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      route.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [handleRouteChange, route.events, route.isReady])

  // Adding Event to the body to hide the options when clicked outside
  useEffect(() => {
    const hideOptions: EventListener = (e) => {
      const currentTarget = e.target
      if (!divRef.current?.contains(currentTarget as Node)) {
        setDegreeList([])
      }
    }
    document.body.addEventListener('click', hideOptions)
    return () => document.body.removeEventListener('click', hideOptions)
  }, [])

  // Load data on page refresh if degree is selected
  useEffect(() => {
    const { query } = route
    if (query.degree) {
      handleRouteChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.isReady])

  return (
    <div className="relative" ref={divRef}>
      <SearchInput
        showDegrees={showDegrees}
        label={degreeSearchTitle}
        placeholder={degreeSearchPlaceholder}
        onChange={() => undefined}
        onFocus={() => setDegreeList(degreeResults)}
        value={selectedDegree}
        isExpanded={degreeList.length > 0}
        actions={[
          {
            name: 'select',
            icon: iconEl,
            onClick: handleIconClick,
          },
        ]}
        readOnly
      />
      <SearchInputResults
        minQueryLength={0}
        query={routeQuery}
        results={{
          TotalCount: degreeList?.length ?? 0,
          Results: degreeList,
        }}
        display={({ displayName }) => displayName.value ?? ''}
      >
        {({ text, item }) => {
          const params = new URLSearchParams()
          params.set('query', routeQuery)
          params.set('degree', item.name.value.split('-').join(' '))
          params.set('page', '1')

          return (
            <Link
              href={`${path}?${params.toString()}`}
              className="search-item block w-full py-3 pl-6 transition duration-200 hover:bg-white-off md:pl-4 md:pr-10"
              key={item.id}
              shallow
              scroll={false}
            >
              {text}
            </Link>
          )
        }}
      </SearchInputResults>
    </div>
  )
}

export default CustomSelect
