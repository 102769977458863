import { useCallback, useEffect } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin } from 'tailwind-merge'

import slugify from 'utils/slugifyStrings'
import { useStickyNav } from 'utils/useStickyNav'
import usePageContext from '../_layoutContext'
import ServiceTag from '../Tags/ServiceTag'
import StandardTag from '../Tags/StandardTag'
import { JobData } from './_JobInterface'
import JobTabButton from './JobTabButton'

interface JobPageHeaderProps {
  jobData: JobData
  onChange: (tab: string) => void
  labels: string[]
  defaultLabel?: string
}

function EmploymentTag({ isReserve, className }: { isReserve?: boolean; className?: string }) {
  return (
    <StandardTag
      text={isReserve ? 'Reserve' : 'Full Time'}
      className={twJoin('mtag shrink-0 whitespace-nowrap [&_.xstag]:font-semibold', className)}
    />
  )
}

export default function JobPageHeader({ jobData, onChange, labels, defaultLabel }: JobPageHeaderProps) {
  const { sitecoreContext } = useSitecoreContext()
  const { deepLink } = usePageContext().pageContext
  const stickyHeader = useStickyNav()
  const pageState = usePageContext().pageContext

  const tabGroup = 'tab'
  const tabKey = deepLink.tabSet[tabGroup]
  const jobPageTabKeys = labels.map(slugify)

  const handleChangeTabs = useCallback(
    (newTabKey: string) => {
      if (tabKey !== newTabKey) {
        deepLink.setTabSet({ type: 'SET', payload: { [tabGroup]: newTabKey } })
        window.scrollTo({ top: 44, behavior: 'smooth' })
      }
    },
    [deepLink, tabKey]
  )

  useEffect(() => {
    if (tabKey) {
      onChange(tabKey)
    }
  }, [onChange, tabKey])

  return (
    <section
      className={twJoin(
        'sticky z-30 overflow-hidden bg-white pb-px pt-10 transition-all duration-200 ease-out',
        //stickyHeader ? 'translate-y-[calc(var(--sticky-top)-1.5rem)]' : 'translate-y-0',
        pageState.pageScroll?.scrollDirection ? 'top-16 xlg:top-20' : 'top-0'
      )}
    >
      <div className="relative">
        <div data-cols="12">
          <div className="row">
            <div className="span-12">
              <div
                className={twJoin(
                  'HEADER-INNER grid transition-all duration-500 ease-out',
                  stickyHeader ? 'grid-rows-[0rem,1fr,3.75rem]' : 'grid-rows-[2.5rem,1fr,5.25rem]'
                )}
              >
                <div
                  className={twJoin(
                    'flex items-center gap-x-4 self-start overflow-hidden transition-opacity duration-500',
                    stickyHeader ? 'opacity-0 ease-out' : 'opacity-100 ease-in'
                  )}
                >
                  <ServiceTag service={jobData.service} className="before:size-3" />
                  <EmploymentTag
                    isReserve={jobData?.isReserve}
                    className={twJoin('transition-all duration-500 ease-out')}
                  />
                </div>
                <div
                  className={twJoin(
                    'flex items-center',
                    stickyHeader ?
                      'grid-cols-[auto,auto,auto] max-w-[calc(100vw_-_var(--outermargin)_*_2_+_var(--gutter))]' :
                      'grid-cols-[0fr,auto,0fr]'
                  )}
                >
                  <div
                    className={twJoin(
                      'grid place-content-end transition-[grid-template-columns] duration-500 ease-out',
                      stickyHeader ? 'grid-cols-[1fr]' : 'grid-cols-[0fr]',
                      stickyHeader && jobData.service == 'air-force' ? 'min-w-[96px]' : '',
                      stickyHeader && jobData.service != 'air-force' ? 'min-w-[60px]' : ''
                    )}
                  >
                    <div
                      className={twJoin(
                        'flex items-center overflow-hidden transition-opacity duration-500',
                        stickyHeader ? 'opacity-100 delay-150 ease-out' : 'opacity-0 ease-in'
                      )}
                    >
                      <ServiceTag service={jobData.service} className="overflow-hidden text-mtag before:size-3" />
                      <span
                        className={twJoin(
                          'font-bold transition',
                          stickyHeader
                            ? 'scale-100 opacity-100 delay-300 duration-300 ease-in'
                            : 'scale-0 opacity-50 delay-0 duration-150 ease-out'
                        )}
                      >
                        /
                      </span>
                    </div>
                  </div>
                  <h1
                    className={twJoin(
                      'mx-2 block transition-all duration-500 ease-out',
                      stickyHeader ? 'truncate text-mtag' : 'h3 -translate-x-2'
                    )}
                  >
                    {jobData.title}
                  </h1>
                  <div
                    className={twJoin(
                      'grid place-content-start transition-[grid-template-columns,opacity] duration-500',
                      stickyHeader ? 'min-w-[68px] grid-cols-[1fr] opacity-100 ease-out' : 'grid-cols-[0fr] opacity-0 ease-in'
                    )}
                  >
                    <EmploymentTag isReserve={jobData?.isReserve} className="overflow-hidden" />
                  </div>
                </div>

                <nav
                  className={twJoin(
                    'job-tab-header-labels',
                    'relative inline-flex w-fit self-end',
                    'after:absolute after:bottom-0 after:h-px after:w-full after:translate-y-full after:transition-all after:duration-300',
                    stickyHeader
                      ? 'after:-left-[--outermargin] after:w-screen after:bg-grey-light'
                      : 'after:left-0 after:bg-grey'
                  )}
                  role="tablist"
                >
                  {labels.map((tab) => {
                    return (
                      <JobTabButton
                        key={tab}
                        title={tab}
                        isSelected={
                          (!jobPageTabKeys.includes(tabKey) && tab === (defaultLabel ?? labels[0])) ||
                          tabKey === slugify(tab)
                        }
                        onClick={() => handleChangeTabs(slugify(tab))}
                        tracking_component={jobData.title}
                        tracking_uuid={sitecoreContext?.route?.layoutId || ''}
                      />
                    )
                  })}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
