import { memo, MouseEventHandler, useCallback, useMemo } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'

import { useShortlist } from '../Shortlist/ShortlistProvider'
import NavigationAction, { NavigationActionProps } from './NavigationAction'
import { ActionFields, parseActionFields } from './navigationHelpers'
import { NavigationContext, useSearchTakeover } from './NavigationProvider'

type ActionType = 'shortList' | 'apply' | 'call' | 'account' | 'search' | 'menu'

interface HeaderActionsProps {
  className?: string
  actionFields?: ActionFields
  action: ActionType
  icon?: boolean
  iconOnly?: boolean
}

export function HeaderAction({ actionFields = {}, action, className, icon, iconOnly }: HeaderActionsProps) {
  const actionItems = useMemo(() => parseActionFields(actionFields), [actionFields])
  const [searchActive, , toggleSearchActive] = useSearchTakeover()
  const shortlist = useShortlist()
  const navIsActivated = NavigationContext.useSelector((state) => state.matches('nav.activated'))
  const sendToNavigation = NavigationContext.useActorRef().send

  const handleToggleShortlist: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      sendToNavigation({ type: 'DEACTIVATE_NAVIGATION' })
      shortlist.setShowShortlist(true)
    },
    [sendToNavigation, shortlist]
  )

  const handleToggleMobileNavigation = useCallback(() => {
    sendToNavigation({ type: 'TOGGLE_NAVIGATION' })
  }, [sendToNavigation])

  const shortList: NavigationActionProps = useMemo(
    () => ({
      field: actionItems.shortList,
      count: shortlist.items?.length ?? 0,
      onClick: handleToggleShortlist,
      'aria-expanded': shortlist.showShortlist,
      button: true,
    }),
    [actionItems.shortList, handleToggleShortlist, shortlist.items?.length, shortlist.showShortlist]
  )
  const apply: NavigationActionProps = useMemo(
    () => ({
      field: actionItems.apply,
      'aria-expanded': false,
    }),
    [actionItems.apply]
  )
  const call: NavigationActionProps = useMemo(
    () => ({
      field: actionItems.call,
      icon: icon ? 'phone' : undefined,
      iconOnly: !!icon && iconOnly,
      'aria-expanded': false,
    }),
    [actionItems.call, icon, iconOnly]
  )
  const account: NavigationActionProps = useMemo(
    () => ({
      field: actionItems.account,
      icon: icon ? 'user' : undefined,
      iconOnly: !!icon && iconOnly,
      'aria-expanded': false,
    }),
    [actionItems.account, icon, iconOnly]
  )
  const search: NavigationActionProps = useMemo(
    () => ({
      field: { value: searchActive ? 'Close' : 'Search' },
      onClick: toggleSearchActive,
      icon: icon ? (searchActive ? 'close' : 'search') : undefined,
      iconOnly: !!icon && iconOnly,
      'aria-expanded': searchActive,
    }),
    [icon, iconOnly, searchActive, toggleSearchActive]
  )
  const menu: NavigationActionProps = useMemo(
    () => ({
      field: { value: 'Slide out menu' },
      className: twJoin(navIsActivated && 'svg-clicked clicked-active'),
      icon: 'menu',
      iconOnly: true,
      onClick: handleToggleMobileNavigation,
      'aria-expanded': navIsActivated,
    }),
    [handleToggleMobileNavigation, navIsActivated]
  )

  const actionProps: NavigationActionProps = useMemo(
    () =>
      ({
        shortList,
        apply,
        call,
        account,
        search,
        menu,
      })[action],
    [account, action, apply, call, menu, search, shortList]
  )

  return actionProps ? (
    <NavigationAction {...actionProps} className={twMerge(actionProps.className, className)} />
  ) : null
}

function HeaderActions({ actionFields }: Pick<HeaderActionsProps, 'actionFields'>) {
  return (
    <>
      <HeaderAction action="shortList" actionFields={actionFields} className="hidden md:flex" />
      <HeaderAction action="apply" actionFields={actionFields} className="hidden xm:flex" />
      <div className="z-10 hidden md:flex">
        <HeaderAction action="call" actionFields={actionFields} className="flex md:hidden xlg:flex" />
        <HeaderAction action="call" icon iconOnly actionFields={actionFields} className="hidden md:flex xlg:hidden" />
      </div>
      <HeaderAction action="account" actionFields={actionFields} className="hidden xlg:flex" />
      <HeaderAction action="account" icon iconOnly actionFields={actionFields} className="flex xlg:hidden" />
      <HeaderAction
        action="search"
        icon
        iconOnly
        actionFields={actionFields}
        className="flex lg:!border-r-0 xl:!border-r"
      />
      <HeaderAction
        action="menu"
        icon
        iconOnly
        actionFields={actionFields}
        className="!border-r-0 xm:!border-r lg:hidden"
      />
    </>
  )
}

const HeaderActionsMemo = memo(HeaderActions, (prev, next) => prev.actionFields === next.actionFields)

export default HeaderActionsMemo
