import React from 'react'
import parse from 'html-react-parser'
import { cleanHeadingStyle } from 'src/utils/cleanHeadingStyle'
import { useService } from 'src/utils/useService'

import { SearchResult } from 'components/ADFSearchResults'
import Button, { ButtonProps } from 'components/front-end/Buttons/Button'
import { ContentIcons } from 'components/front-end/Icons/icon-data'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import StandardTagList from 'components/front-end/Tags/StandardTagList'
import Icon from '../Icons/Icon'

interface CardProps extends SearchResult {
  children?: React.ReactNode
  className?: string
  subtitle?: string
  headingLevel?: keyof React.JSX.IntrinsicElements
  tags?: string[] | undefined
  buttons?: ButtonProps[]
  icon?: ContentIcons
}

const SimpleCard = ({
  service: overridingService,
  title,
  headingLevel = 'h6',
  copy,
  children,
  className,
  icon,
  tags,
  buttons,
}: CardProps): JSX.Element => {
  const HeadingLevel = cleanHeadingStyle(headingLevel)

  const service = useService(overridingService)

  const themeClasses = `
    bg-white [.light_&]:bg-white [.dark_&]:!bg-black-off
    text-black [.light_&]:text-black [.dark_&]:!text-white
    ${className || ''}
  `

  return (
    <div className={`relative flex flex-col p-10 ${themeClasses} `}>
      <div className={`mb-6 flex justify-between `}>
        {service && <ServiceTag isCard service={service} />}
        {tags && <StandardTagList className="ml-auto" tags={tags} />}
      </div>
      <div className={`mt-auto ${icon ? 'pt-12' : ''}`}>
        {icon && <Icon name={icon} type="content" service={service} />}
        <HeadingLevel className="mb-2 max-w-[36rem] overflow-hidden xm:max-w-[26rem]">{title}</HeadingLevel>

        <div className={`mbody`}>{parse(copy || '')}</div>

        {children}

        <div className={`mt-6 flex flex-wrap xm:flex-nowrap`}>
          <div className={`flex flex-wrap gap-4`}>
            {buttons?.map((b, i) => <Button key={i} {...b} service={service} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleCard
