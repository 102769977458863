import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'

import { TextField } from '@sitecore-jss/sitecore-jss-nextjs'

import { SubTabType } from '../../ADFSideTab'
import PageTab from './PageTab'

type TabSwiperProps = {
  tabList: SubTabType[]
  onClick?: (idx: number) => void
  isSelected: number
  setSwipeMove: (move: boolean) => void
}

const TabSwiper = ({ tabList, onClick, isSelected, setSwipeMove }: TabSwiperProps) => {
  return (
    <Swiper
      className="transparent-right-40 w-full"
      slidesPerView={'auto'}
      onTouchMove={() => setSwipeMove(true)}
      onTransitionEnd={() => setSwipeMove(false)}
    >
      {tabList &&
        tabList?.map((x: SubTabType, i) => (
          <SwiperSlide key={i} className="!w-fit pl-6">
            <PageTab title={x?.title?.field as TextField} onClick={() => onClick?.(i)} isSelected={isSelected === i} />
          </SwiperSlide>
        ))}
      <SwiperSlide className={`!w-[200px] pl-6`}></SwiperSlide>
    </Swiper>
  )
}

export default TabSwiper
