import { useAudioPlayerProgress } from './useAudioPlayerProgress'

type AudioPlayerProgressBarProps = {
  player: HTMLAudioElement | null
}

export const formatTime = (time: number) =>
  [
    Math.floor(time / 60).toString(),
    Math.floor(time % 60)
      .toString()
      .padStart(2, '0'),
  ].join(':')

function AudioPlayerProgressBar({ player }: AudioPlayerProgressBarProps) {
  const [currentTime, playerState] = useAudioPlayerProgress(player, 20)

  // Doesn't work in Firefox
  const inputSliderClasses =
    'w-full h-1 xm:w-[410px] xm:max-w-[30%] bg-black/20 dark:bg-white/20 rounded-lg appearance-none cursor-pointer range-sm [.light_&]:text-black [.dark_&]:!text-white [.grey_&]:!text-white [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-1 [&::-webkit-slider-thumb]:w-5 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white [.light_&]:[&::-webkit-slider-thumb]:bg-black-off'

  return (
    <input
      type="range"
      value={playerState === 'ended' ? player?.duration : currentTime}
      max={player?.duration || 100}
      className={inputSliderClasses}
      onChange={() => undefined}
      aria-label={`${(player && Math.floor(player.duration - currentTime) * 100) || 0} seconds remaining`}
    />
  )
}

export default AudioPlayerProgressBar
