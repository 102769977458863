import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import { useLocalNavContext } from 'components/front-end/_LocalNavContext'

export function useLocalBackNavigation(): boolean {
  const { sitecoreContext } = useSitecoreContext()

  const hasPreviousLocalRoute = useLocalNavContext()

  if (!sitecoreContext) {
    return false
  }

  const hideTheBackLinkField = sitecoreContext?.route?.fields?.HideTheBackLink ?? {}
  const hideBackLink = 'value' in hideTheBackLinkField ? !!hideTheBackLinkField.value : false

  return hasPreviousLocalRoute && !hideBackLink
}
