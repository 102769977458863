import { JobSearchResultsContext, JobSearchResultsEvents } from ".."

const autocompleteSensitivity = 3

export const getAutocomplete = async ({}, evt: JobSearchResultsEvents): Promise<string[]> => {
  if (evt.type != "UPDATE_QUERY")
    throw `Invalid event type ${evt.type}`

  if (evt.query.length < autocompleteSensitivity) {
    return [];
  }

  const payload = {
    filterValues: [],
    page: 1,
    perPage: 6,
    query: evt.query,
    //searchId: '9e79bd2a-629f-462e-a236-afc87b575171',
    searchId: 'eb06ffcc-1ba6-4240-947b-a99e3befb57e'
  }

  const res = await fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/KeywordSearch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })

  if (!res.ok) {
    return []
  }

  const data = await res.json()

  return data.Results
}
