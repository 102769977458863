import { MouseEvent, useEffect, useState } from 'react'
import { twJoin } from 'tailwind-merge'

import { JobComparisonFields } from 'components/ADFJobComparison'
import { useLessThan } from 'src/utils/useBreakpoints'
import Button from '../Buttons/Button'
import Drawer from '../Drawers/Drawer'
import Icon from '../Icons/Icon'
import { useShortlist } from '../Shortlist/ShortlistProvider'
import HeaderText from '../TextBlocks/HeaderText'
import CareerSelectorOptions from './CareerSelectorOptions'
import { useCareerOptions } from './useCareerOptions'

type CareerSelectorProps = {
  trackingId: string
  maxSelected: number
  fields: JobComparisonFields
}

export default function CareerSelector({ fields, maxSelected, trackingId }: CareerSelectorProps) {
  const { jobs, setShowShortlist } = useShortlist()
  const [careerOptions, selectedOptions] = useCareerOptions() ?? [[], []]
  const [optionsExpanded, setOptionsExpanded] = useState(!!selectedOptions?.length)

  const lessSm = useLessThan('md')
  const iconSize = lessSm ? 16 : 28

  const entryMethods = jobs?.flatMap((job) => job.entryMethods) ?? []

  useEffect(() => {
    if (typeof selectedOptions === 'undefined' && !!jobs?.length && !!entryMethods.length) {
      setOptionsExpanded(true)
    }
  }, [entryMethods.length, jobs?.length, selectedOptions])

  const handleToggleOptions = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setOptionsExpanded(!optionsExpanded)
  }

  const selectionCount = entryMethods.length
    ? `${selectedOptions?.length ?? 0} / ${Math.min(maxSelected, entryMethods.length)} selected`
    : 'You have shortlisted 0 roles'

  const handleShowComparison = () => {
    if (optionsExpanded) {
      document.querySelector<HTMLDivElement>('[data-comparison-table]')?.scrollTo(0, 0)
    }
    setOptionsExpanded(false)
  }

  return (
    <>
      <div className="career-selector relative z-10 flex w-full flex-col items-stretch gap-x-6 gap-y-4 bg-white pb-4 md:flex-row md:divide-x-0.5px md:divide-grey md:border-b-0.5px md:border-grey">
        <button
          className="flex flex-1 items-center justify-between border-b-0.5px border-grey pb-2 md:border-b-0 md:pb-0 overflow-hidden"
          onClick={handleToggleOptions}
        >
          <HeaderText
            title={fields.SelectTitle.value}
            headingLevel="h2"
            headingStyle="h4"
            service="tri-service"
            showServiceTag={false}
            className="m-0 normal-case text-left"
          />
          <div className={twJoin('-rotate-90', optionsExpanded ? 'md:rotate-180' : 'md:rotate-0')}>
            <Icon type="stroke" name="chevron-down" width={34} height={34} />
          </div>
        </button>

        <div className="flex items-stretch gap-y-6 md:pl-6">
          <Button
            data-trackingid={trackingId}
            button
            type="tertiary-action"
            icon={fields.ShortlistButtonIcon.value}
            iconHeight={iconSize}
            iconWidth={iconSize}
            link={{ value: { text: fields.ShortlistButtonText.value } }}
            className="[&_path]:stroke-2 [&_span]:font-inter [&_span]:text-sbody [&_span]:font-normal [&_span]:capitalize md:[&_span]:text-lbody"
            onClick={(e) => {
              e.preventDefault()
              setShowShortlist(true)
            }}
          />
        </div>
      </div>

      {entryMethods ? (
        <div
          className={twJoin(
            'relative -translate-y-0.5 border-0.5px border-grey bg-white',
            optionsExpanded && !lessSm
              ? 'h-auto opacity-100 transition duration-200 ease-out'
              : 'pointer-events-none h-px overflow-hidden opacity-100 delay-300'
          )}
          inert={optionsExpanded ? undefined : ''}
        >
          <div className="divide-y-0.5px divide-grey px-10">
            <div
              className={twJoin(
                'py-8 tabular-nums transition ease-out',
                optionsExpanded ? 'duration-500 ease-out' : '-translate-y-1 duration-300'
              )}
            >
              {selectionCount}
            </div>
            {entryMethods.length ? (
              <CareerSelectorOptions
                className={twJoin(
                  'py-12 md:grid-cols-2 xm:grid-cols-3 lg:grid-cols-4',
                  !optionsExpanded && '-translate-y-2 opacity-0'
                )}
                options={careerOptions}
                selectedOptions={selectedOptions ?? []}
                maxSelected={maxSelected}
              />
            ) : null}
          </div>
        </div>
      ) : null}

      <Drawer
        title={jobs ? selectionCount : ''}
        show={Boolean(jobs && optionsExpanded && lessSm)}
        closeDrawer={() => setOptionsExpanded(false)}
        footer={
          <div className="flex w-full">
            <button
              className="icon-hover relative flex h-[60px] w-full select-none items-center justify-center gap-x-5 bg-tri-service aria-disabled:pointer-events-none aria-disabled:bg-grey-light aria-disabled:text-grey-medium"
              onClick={handleShowComparison}
              aria-disabled={!selectedOptions?.length}
            >
              <span className="button">Show comparison</span>
              <span className="svg-hover">
                <Icon
                  name="chevron-right-double"
                  type="stroke"
                  className="before:absolute before:inset-0 before:bg-error"
                  width={15}
                  height={15}
                />
              </span>
            </button>
          </div>
        }
      >
        <p className="mb-6 mt-10 text-mbody">Select the Entry Method of the careers you'd like to compare.</p>
        {entryMethods.length ? (
          <CareerSelectorOptions
            maxSelected={maxSelected}
            options={careerOptions}
            selectedOptions={selectedOptions ?? []}
          />
        ) : null}
        <div className="flex flex-col gap-4 text-grey-medium"></div>
      </Drawer>
    </>
  )
}
