import { useState } from 'react'
import { FeedbackAction, track } from 'src/utils/tracking'
import { useService } from 'src/utils/useService'

import Button from 'components/front-end/Buttons/Button'

type FAQFeedbackStore = Record<string, string>

type FeedbackProps = {
  id: string
  title: string
  buttonPositiveText: string
  buttonNegativeText: string
  service?: string
}

type FeedbackOption = {
  icon: 'satisfied' | 'unsatisfied'
  label: string
}

const Feedback = ({ id, title, buttonPositiveText, buttonNegativeText, service }: FeedbackProps): JSX.Element => {
  service = useService(service) as string
  const [feedback, setFeedback] = useState<string>()

  const options: FeedbackOption[] = [
    { icon: 'satisfied', label: buttonPositiveText },
    { icon: 'unsatisfied', label: buttonNegativeText },
  ]

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const { textContent } = e.currentTarget

    if (textContent) {
      const feedbackAction: FeedbackAction = textContent !== feedback ? `select` : 'deselect'
      setFeedback(feedbackAction === 'select' ? textContent : undefined)
      track({
        event: 'feedback',
        feedback_name: `${feedbackAction} - ${textContent}`,
        feedback_component: title,
      })
      storeFeedback(id, textContent)
    }
  }

  return (
    <div className="py-6">
      <div className="h6 pb-6">{title}</div>
      <div className="flex-start flex flex-wrap gap-4">
        {options.map(({ icon, label: text }) => (
          <Button
            data-trackingid={id}
            key={`${id}-${text}`}
            button
            link={{ value: { text } }}
            icon={icon}
            type="action"
            isSmall
            service={service}
            onClick={handleClick}
            className={feedback === text ? '[&_.button-fill]:scale-x-100' : undefined}
          />
        ))}
      </div>
    </div>
  )
}

export default Feedback

// Append the feedback id and feedback text to the `feedback` array in localStorage
export function storeFeedback(id: string, feedback: string) {
  const feedbackStore: FAQFeedbackStore = JSON.parse(localStorage.getItem('feedback') || '{}')

  if (feedbackStore[id] === feedback) {
    delete feedbackStore[id]
  } else {
    feedbackStore[id] = feedback
  }
  localStorage.setItem('feedback', JSON.stringify(feedbackStore))
}

// Retrieve existing feedback for id from the `feedback` array in localStorage
export function retrieveFeedback(id: string) {
  const feedbackStore: FAQFeedbackStore = JSON.parse(localStorage.getItem('feedback') || '{}')
  return feedbackStore[id]
}
