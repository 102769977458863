import React, { CSSProperties } from 'react'
import { ComponentRendering, RichText } from '@sitecore-jss/sitecore-jss-nextjs'
import { twJoin, twMerge } from 'tailwind-merge'

import ADFSideTab from 'components/ADFSideTab'
import JobDetailsTypes, { SalaryInformation } from 'components/front-end/JobDetails/_JobInterface'
import JobTabHeader from 'components/front-end/JobDetails/JobTabHeader'
import ListIcon from 'components/front-end/Lists/ListIcon'
import { useService } from 'utils/useService'
import { create } from './contentBuilder'
import JobTabPanel from './JobTabPanel'
import { createSideTabContent, createSideTabRenderingData } from './JobWaysToJoin'

const JobDetails = ({
  responseData,
  animateClasses: [animateRightClasses, animateDownClasses] = [],
  id,
}: JobDetailsTypes) => {
  const service = useService(responseData?.service)
  if (!responseData) {
    return null
  }

  const richTextAreaClass = twJoin(
    'rich-text component',
    '[&_.title-panel_h1]:mb-5 [&_.title-panel_h2]:mb-5 [&_.title-panel_h3]:mb-5',
    '[&_.title-panel_p:not(:last-child)]:mb-5',
    '[&_.title-panel_a]:underline',
    '[&_.title-panel_ul:not(:last-child)]:mb-9 [&_.title-panel_ul]:pl-3 [&_.title-panel_ul]:list-disc',
    '[&_.title-panel_li_p]:mb-0 [&_.title-panel_li]:mb-3',
    '[&_.content-component]:p-0 [&_.content-component]:mt-0',
    '[&.rich-text.component_ul]:pl-0 [&.rich-text.component_ul>li]:text-unset [&.rich-text.component_ul>li]:pl-10'
  )

  return (
    <JobTabPanel id={id}>
      <JobTabHeader
        className={animateRightClasses}
        title={responseData?.globalHeader ?? ''}
        apply={responseData?.applyUrl}
        jobId={responseData?.id}
        service={service}
        closed={Boolean(responseData?.isClosed)}
        isPriority={Boolean(responseData?.isPriority)}
      />

      <section className="ABOUT-JOB">
        <div className="relative" data-cols="12">
          <div className="row">
            <div className="span-12 md-span-4 lg-span-3 md:mr-[calc(var(--colwidth))]">
              <div className={animateRightClasses}>
                <h3 className="h4 mb-12 text-pretty font-normal normal-case">{responseData?.globalSubHeader}</h3>
              </div>
            </div>
            <div className="span-12 md-span-7 lg-span-8 xxl-span-7">
              <div className={`${animateDownClasses} ${richTextAreaClass}`}>
                <RichText className="title-panel lbody" field={{ value: responseData?.longDescription }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ABOUT-JOB">
        <div className="relative" data-cols="12">
          <div className="row">
            <div className="span-12 md-span-4 lg-span-3 md:mr-[calc(var(--colwidth))]">
              <div className={animateRightClasses}>
                <h3 className="h4 mb-12 text-pretty font-normal normal-case">{responseData?.roleDescriptionHeader}</h3>
              </div>
            </div>
            <div className="span-12 md-span-7 lg-span-8 xxl-span-7">
              <div className={`${animateDownClasses} ${richTextAreaClass}`}>
                <RichText className="title-panel lbody" field={{ value: responseData?.roleDescription }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <KeyInformationSideNav data={responseData.keyInformation} className={richTextAreaClass} />

      {responseData.eligibilityChecklist?.fields ? (
        <section className="ELIGIBILITY-LIST">
          <div className="relative" data-cols="12">
            <div className="row">
              <div className="span-12 md-span-4 lg-span-3 md:mr-[calc(var(--colwidth))]">
                <div className={animateRightClasses}>
                  <h3 className="h4 mb-12 text-pretty font-normal normal-case">
                    {responseData.eligiblityChecklistHeader}
                  </h3>
                  <div className={twMerge(animateDownClasses, richTextAreaClass)}>
                    <RichText
                      className="title-panel lbody"
                      field={{ value: responseData.eligiblityChecklistContent }}
                    />
                  </div>
                </div>
              </div>
              <div className="span-12 md-span-7 lg-span-8 xxl-span-7">
                <div className="flex flex-col">
                  <div
                    className="grid gap-10 md:grid-cols-[--gridCols]"
                    style={
                      {
                        '--gridCols': responseData.eligibilityChecklist.fields.Layout?.value === '1 Column' ? '1' : '2',
                      } as CSSProperties
                    }
                  >
                    {responseData.eligibilityChecklist.fields.items.map((item, index) => (
                      <ListIcon
                        key={index}
                        iconName={item.fields.Icon?.value}
                        iconType="content"
                        text={item.fields.Description?.value}
                        titleText={item.fields.Heading?.value}
                        isHorizontal={responseData.eligibilityChecklist?.fields?.Layout.value === '1 Column'}
                        service={service}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </JobTabPanel>
  )
}

export default JobDetails

function KeyInformationSideNav({ data, className }: { data?: SalaryInformation; className?: string }) {
  if (!data) {
    return null
  }

  const [tabContent, placeholders] = createSideTabContent(defineSideTabs(data))
  const { fields, ...rendering } = createSideTabRenderingData({
    heading: data.name,
    tabContent,
    placeholders: {
      'sidetab-narrow': [],
      ...placeholders,
    },
  })

  return (
    <section className={twMerge('KEY-INFORMATION', className)}>
      <ADFSideTab
        fields={fields}
        rendering={rendering}
        params={{
          GridParameters: 'col-12',
          gridcols: '12',
          DynamicPlaceholderId: '2',
        }}
      />
    </section>
  )
}

function defineSideTabs(keyInformation: SalaryInformation) {
  const sideTabData: Record<string, Record<string, ComponentRendering[]>> = keyInformation.fields?.items.reduce(
    (acc, { fields }) => {
      acc[fields.Title.value] = {
        [fields.Title.value]: create()
          .addContent({
            copy: fields.Content.value,
            title: fields.Title.value,
          })
          .get(),
      }
      return acc
    },
    {} as Record<string, Record<string, ComponentRendering[]>>
  )
  return sideTabData
}
