import { useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import { ParsedQuestionnaireResults, WeightedQuestionnaireFormState } from './weightedQuestionnaireHelpers'
import WeightedQuestionnaireResult from './WeightedQuestionnaireResult'

type WeightedQuestionnaireResultsCarouselProps = {
  results: ParsedQuestionnaireResults
  scores: WeightedQuestionnaireFormState['scores']
}

const WeightedQuestionnaireResultsCarousel = ({ results, scores }: WeightedQuestionnaireResultsCarouselProps) => {
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [activeIndex, setActiveIndex] = useState(0)
  const topScore = Math.max(...Object.values(scores ?? {}))

  return (
    <Swiper
      className="mx-[--outermargin]"
      watchOverflow
      spaceBetween={12}
      slidesPerView={1.1}
      centeredSlides
      onSwiper={setSwiper}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
    >
      <p
        slot="container-start"
        className="stag mx-[--outermargin] mb-4 flex justify-between uppercase text-black dark:text-white"
      >
        <span>Your Results</span>
        {swiper?.slides && (
          <span>
            {activeIndex + 1} / {swiper.slides.length}
          </span>
        )}
      </p>
      {results.map((result) => {
        const score = scores?.[result.name] ?? 0
        return (
          <SwiperSlide key={result.id} style={{ blockSize: 'auto' }}>
            <WeightedQuestionnaireResult bestMatch={score === topScore} score={score} {...result} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default WeightedQuestionnaireResultsCarousel
