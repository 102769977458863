import { useLessThan } from 'src/utils/useBreakpoints'
import { Service } from 'src/utils/useService'
import Drawer from '../Drawers/Drawer'
import FAQContent from './FAQContent'
import { useFAQContext } from './FAQContext'

type FAQDrawerProps = {
  service?: Service
}

const FAQDrawer = ({ service }: FAQDrawerProps) => {
  const {
    state: { selectedFaqId },
    send,
    lookup,
  } = useFAQContext()
  const faq = lookup.faqs[selectedFaqId]

  const handleCloseDrawer = () => {
    send({ type: 'DESELECT_FAQ' })
  }

  const heading = useLessThan('xm') ? '' : faq?.heading
  return (
    <Drawer show={!!faq} title={heading} closeDrawer={handleCloseDrawer} hideFooter returnFocusOnClose>
      <FAQContent {...faq} service={service} />
    </Drawer>
  )
}

export default FAQDrawer
