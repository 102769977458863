import { CSSProperties, MouseEventHandler } from 'react'
import { RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs'

import Button, { ButtonProps } from '../Buttons/Button'

export type WeightedQuestionnaireResultProps = {
  id: string
  title: string
  description: string
  weightedScoreKeyPoints: RichTextField
  score: number
  bestMatch: boolean
  cta: Pick<ButtonProps, 'link' | 'icon' | 'type' | 'data-trackingid'>
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

const WeightedQuestionnaireResult = ({
  title,
  description,
  weightedScoreKeyPoints,
  score = 0,
  bestMatch,
  cta,
  onClick,
}: WeightedQuestionnaireResultProps) => {
  const match = `${Math.round(score)}%`

  return (
    <div className="grid h-full grid-cols-1 grid-rows-[auto_1fr] border-[0.5px] border-grey px-6 py-8 md:grid-cols-2 md:grid-rows-1 md:gap-x-12">
      <div className="flex flex-col gap-y-2">
        <h6 className="mb-2 text-black dark:text-white">{title}</h6>
        <div className="stag flex items-center gap-x-2 uppercase">
          <div
            className={`relative h-2 w-2/3 flex-1 overflow-hidden rounded-full ${
              bestMatch ? 'bg-success-tint' : 'bg-grey-light'
            }`}
          >
            <div
              className={`absolute inset-y-0 right-full w-full translate-x-[--progress] rounded-full ${
                bestMatch ? 'bg-success' : 'bg-black'
              }`}
              style={{ '--progress': match } as CSSProperties}
            />
          </div>
          <div className="w-1/3 text-right text-black dark:text-white md:w-fit xm:w-1/3 xm:text-left">
            {match} match
          </div>
        </div>
        <p className="py-3 text-sbody leading-normal xm:pt-6">{description}</p>
      </div>
      <div className="flex flex-1 flex-col items-start justify-between gap-y-8 text-black dark:text-white md:justify-start">
        <div className="w-full [&_li]:flex [&_li]:gap-x-2 [&_li]:border-b-[0.5px] [&_li]:border-b-grey [&_li]:py-3 [&_li]:before:mr-1.5 [&_li]:before:inline-block [&_li]:before:h-4 [&_li]:before:w-4 [&_li]:before:scale-75 [&_li]:before:content-[url('/icons/svgs/check-circle-stroke.svg')]">
          <RichText field={weightedScoreKeyPoints} />
        </div>
        <Button {...cta} isSmall onClick={onClick} />
      </div>
    </div>
  )
}

export default WeightedQuestionnaireResult
