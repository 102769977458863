import { useCallback, useEffect } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'

import { useIntersectionObserver } from 'utils/useIntersectionObserver'
import { usePathSegments } from 'utils/usePathSegments'
import { useLessThan } from 'utils/useScreen'
import useScrollLock from 'utils/useScrollLock'
import BackButton from '../Buttons/BackButton'
import SearchTakeover from '../Search/SearchTakeover'
import HeaderActions, { HeaderAction } from './HeaderActions'
import NavigationBreadcrumbs from './NavigationBreadcrumbs'
import { ActionFields } from './navigationHelpers'
import NavigationMenu from './NavigationMenu'
import { NavigationContext, navigationLevels, useNavigation, useSearchTakeover } from './NavigationProvider'
import SiteLogo from './SiteLogo'

interface HeaderBarProps {
  actionFields?: ActionFields
  children?: React.ReactNode
}

export default function SiteNavigation({ actionFields, children }: HeaderBarProps) {
  const sendToNavigation = NavigationContext.useActorRef().send
  const { activePath, navIsActivated, visibleNavItems, secondaryNavIsActivated } = useNavigation()
  const navIsHidden = NavigationContext.useSelector((state) => state.matches('nav.idle.navigationBarHidden'))
  const [searchActive, setSearchActive] = useSearchTakeover()

  const { isIntersecting, ref } = useIntersectionObserver({ threshold: [0, 1] })

  const [, setLock] = useScrollLock()
  const pathSegments = usePathSegments()
  const showBreadcrumbBar = !!pathSegments.length
  const inferredLevel = navigationLevels[activePath.length] ?? 'primary'
  const mobileMode = useLessThan('lg')

  const enableStickyNav = !isIntersecting

  const [_, secondaryNavItems, tertiaryNavItems] = visibleNavItems
    .map((items) => items.filter(({ hideInNavigation }) => !hideInNavigation))
    .filter((items) => items.length > 0)

  useEffect(() => {
    document.documentElement.setAttribute('data-sticky-nav', enableStickyNav ? 'true' : 'false')

    return () => {
      document.documentElement.setAttribute('data-sticky-nav', 'false')
    }
  }, [enableStickyNav])

  useEffect(() => {
    setLock(navIsActivated)
  }, [navIsActivated, setLock])

  const handleLeaveNavigation = useCallback(() => {
    if (navIsActivated && !mobileMode) {
      sendToNavigation({ type: 'LEAVE_NAVIGATION' })
    }
  }, [mobileMode, navIsActivated, sendToNavigation])

  const handleEnterNavigation = useCallback(() => {
    if (navIsActivated) {
      sendToNavigation({ type: 'REENTER_NAVIGATION' })
    }
  }, [navIsActivated, sendToNavigation])

  return (
    <div
      className={twJoin(
        'transitiuon-[padding-top] pointer-events-none relative top-0 duration-200 ease-out',

        showBreadcrumbBar ? 'pt-[--header-height]' : 'pt-[--navbar-height]',
        (navIsActivated || searchActive) && 'light'
      )}
    >
      <div className="pointer-events-none absolute inset-x-0 top-0 -z-10 h-[--navbar-height]" ref={ref} />
      <div
        className={twJoin(
          'adf-scroll-bar-container inset-x-0 bottom-0 z-20 flex flex-col items-start',
          enableStickyNav ? 'fixed -top-[--navbar-height]' : 'absolute top-0'
        )}
      >
        {/* NAVIGATION BAR */}
        <div
          className={twMerge(
            'adf-scroll-bar pointer-events-auto relative z-40 w-full max-w-full divide-y divide-grey-light border-grey-light bg-white transition-transform before:pointer-events-none [.dark_&]:divide-grey-medium [.dark_&]:border-grey-medium [.dark_&]:bg-black-off [.grey_&]:bg-black/60 [.light_&]:divide-grey-light [.light_&]:border-grey-light [.light_&]:bg-white [.light_&]:text-black-off',
            navIsActivated || searchActive
              ? 'before:fixed before:box-content before:h-[calc(var(--navbar-height)+1px)] before:w-screen before:border-b before:border-grey-light before:bg-white'
              : 'before:absolute before:inset-x-0 before:top-full before:h-26 before:translate-y-px before:bg-gradient-to-b before:from-black/0 [.grey_&]:before:from-black/60 [.grey_&]:before:to-black/0',
            enableStickyNav ? 'duration-200' : 'duration-75 [.grey_&]:before:translate-y-0',
            enableStickyNav && (navIsHidden ? 'border-b' : 'translate-y-[--navbar-height] border-b')
          )}
        >
          <div
            role="menubar"
            className="nav-container navigation-grid flex w-screen max-w-full items-stretch divide-x divide-x-reverse divide-grey-light overflow-x-hidden border-grey-light xm:!pr-[--scrollbar-compensation] [.grey_&]:divide-grey-medium [.light_&]:divide-grey-light [.light_&]:border-grey-light"
          >
            <div className="relative z-10 ml-6 flex shrink-0 items-center xm:ml-0 lg:mr-2.5 lg:border-r lg:border-grey-light lg:pr-[22px] xlg:mr-3 xlg:pr-8 [.grey_&]:lg:border-grey-medium [.light_&]:border-grey-light">
              <BackButton
                className={twJoin(
                  'pointer-events-none absolute -translate-x-4 opacity-0 transition duration-300 lg:pointer-events-none lg:-translate-x-4 lg:opacity-0',
                  secondaryNavIsActivated &&
                    'pointer-events-auto translate-x-0 opacity-100 md:pointer-events-none md:-translate-x-4 md:opacity-0'
                )}
                onClick={() => {
                  sendToNavigation({ type: 'POP_NAVIGATION' })
                }}
              />
              <SiteLogo
                className={twJoin(
                  'transition duration-300',
                  secondaryNavIsActivated &&
                    'pointer-events-none translate-x-2 opacity-0 md:pointer-events-auto md:translate-x-0 md:opacity-100'
                )}
              />
            </div>
            <NavigationMenu
              level="primary"
              className="flex h-[--navbar-height] flex-1 items-stretch"
              listClassName="hidden items-stretch lg:flex"
              itemClassName="mnav flex whitespace-nowrap flex-1 items-stretch text-black-off lg:px-2.5 xl:px-3 xlg:px-[17px] [.dark_&]:text-white [.grey_&]:text-white [.light_&]:text-black-off"
              onMouseEnter={handleEnterNavigation}
              onMouseLeave={handleLeaveNavigation}
              onFocus={handleEnterNavigation}
            />

            <HeaderActions actionFields={actionFields} />
          </div>
          <div className={twJoin('adf-breadcrumb', navIsActivated && 'max-h-0 overflow-hidden')}>
            <NavigationBreadcrumbs className="pointer-events-auto flex h-[--breadcrumb-height] transition-all duration-200" />
          </div>
          {children}
        </div>

        {/* BACKDROP */}
        <div
          aria-hidden={!navIsActivated}
          className="pointer-events-auto fixed inset-0 z-10 flex w-full flex-1 bg-black-off/50 opacity-100 transition duration-300 ease-out aria-hidden:pointer-events-none aria-hidden:opacity-0 aria-hidden:ease-in"
          onClick={() => sendToNavigation({ type: 'DEACTIVATE_NAVIGATION' })}
        />

        {/* DEEP NAVIGATION */}
        <div
          aria-hidden={mobileMode ? !navIsActivated : !secondaryNavItems}
          className="adf-scroll-bar-container pointer-events-auto fixed bottom-0 left-0 top-[--navbar-height] z-40 w-screen translate-x-0 translate-y-px overflow-y-auto overflow-x-hidden border-t border-t-grey-light bg-white px-9 opacity-100 transition duration-200 ease-out aria-hidden:pointer-events-none aria-hidden:-translate-x-full aria-hidden:opacity-0 aria-hidden:ease-in md:left-1/2 md:aria-hidden:translate-x-full lg:left-0 lg:flex lg:w-auto lg:aria-hidden:-translate-x-full"
          onMouseEnter={handleEnterNavigation}
          onMouseLeave={handleLeaveNavigation}
        >
          {/* MOBILE NAV */}
          {mobileMode ? (
            <div
              className={twJoin(
                'adf-scroll-bar light fixed inset-0 flex w-full flex-col overflow-y-auto bg-white transition duration-200 ease-out md:w-1/2 lg:hidden lg:opacity-0 lg:duration-0',
                !navIsActivated && 'pointer-events-none translate-x-full opacity-0'
              )}
            >
              <div className="flex justify-start bg-white-off px-2 md:hidden">
                <HeaderAction
                  action="call"
                  icon
                  actionFields={actionFields}
                  className={twJoin(
                    'flex h-16 w-full [&>a]:justify-start [&>a]:text-mbody [&_a]:!text-black-off',
                    inferredLevel === 'primary' ? 'flex' : 'hidden'
                  )}
                />
              </div>
              <BackButton
                className={twJoin(
                  'pointer-events-none mx-6 h-10 max-h-0 translate-x-4 overflow-hidden opacity-0 transition duration-200 xm:mx-16',
                  inferredLevel !== 'primary' &&
                    'md:pointer-events-auto md:mt-4.5 md:flex md:max-h-none md:translate-x-0 md:opacity-100'
                )}
                onClick={() => {
                  sendToNavigation({ type: 'POP_NAVIGATION' })
                }}
              />
              <div className="grid flex-1 overflow-x-hidden">
                {navigationLevels.map((level) => (
                  <NavigationMenu
                    key={level}
                    level={level}
                    mobileMode
                    aria-hidden={level !== inferredLevel}
                    className={twJoin(
                      'col-start-1 row-start-1 flex w-full max-w-full flex-1 translate-x-0 items-start transition delay-100 duration-300 ease-out aria-hidden:pointer-events-none aria-hidden:translate-x-2 aria-hidden:opacity-0 aria-hidden:delay-0 aria-hidden:duration-0 lg:hidden',
                      level === inferredLevel ? 'z-10' : 'z-0'
                    )}
                    listClassName="flex w-full flex-1 flex-col items-stretch justify-start pl-6 pr-4 pt-7 xm:px-16"
                    itemClassName={twJoin(
                      'font-uniform-condensed flex lg:whitespace-nowrap flex-1 justify-between items-stretch text-grey [.dark_&]:text-grey [.grey_&]:text-white [.light_&]:text-grey',
                      level === 'primary' ? 'text-h3 uppercase py-4.5' : 'text-lg leading-normal py-3 text-error'
                    )}
                  />
                ))}
              </div>
              <div className="sticky bottom-0 z-10 grid h-16 shrink-0 grid-cols-2 justify-stretch divide-x divide-grey-light border-t border-t-grey-light bg-white md:hidden">
                <HeaderAction action="apply" actionFields={actionFields} />
                <HeaderAction action="shortList" actionFields={actionFields} />
              </div>
            </div>
          ) : (
            <>
              {/* SECONDARY NAV */}
              <NavigationMenu
                level="secondary"
                className="mb-12 hidden w-[40rem] grid-cols-1 pt-24 lg:grid"
                listClassName="relative col-start-1 row-start-1 flex flex-col transition-[opacity,transform] ease-out aria-hidden:-translate-x-2"
                itemClassName="h3 relative flex justify-between text-balance py-4.5 pr-16 text-grey-medium transition ease-out duration-300 hover:text-black-off [.dark_&]:text-grey-medium [.dark_&]:hover:text-black-off [.light_&]:text-grey-medium [.light_&]:hover:text-black-off"
              />

              {/* TERTIARY NAV */}
              <NavigationMenu
                level="tertiary"
                aria-hidden={!tertiaryNavItems}
                className="sticky top-0 hidden w-[30rem] grid-cols-1 py-24 opacity-100 transition-all duration-200 ease-out aria-hidden:w-0 aria-hidden:opacity-0 aria-hidden:delay-300 aria-hidden:ease-in lg:grid [.dark_&]:text-grey-medium [.dark_&]:hover:text-black-off [.light_&]:text-grey-medium [.light_&]:hover:text-black-off"
                listClassName="relative col-start-1 row-start-1 flex flex-col pl-14 transition-[opacity,transform] ease-out"
                itemClassName="flex py-3 font-uniform-condensed text-[26px] leading-[36px]"
              />
            </>
          )}
        </div>
      </div>
      <SearchTakeover
        searchActive={searchActive}
        onClose={() => {
          setSearchActive(false)
        }}
      />
      <style jsx global>{`
        :root {
          --navbar-offset: ${enableStickyNav && navIsHidden ? '0px' : 'var(--navbar-height)'};
        }
      `}</style>{' '}
      <style jsx global>{`
        :root {
          --sticky-top: calc(var(--navbar-offset, 0px) + var(--breadcrumb-height, 0px));
          --header-height: calc(var(--navbar-height) + var(--breadcrumb-height, 0px));
        }
      `}</style>
    </div>
  )
}
