import { useWatch } from 'react-hook-form'

import type { FormFieldOptions, FormValues, MethodOfEntryOptionKey } from 'components/ADFContactusForm'
import { Input, Radio, Select } from '../Forms'
import Fieldset from '../Forms/Fieldset'
import { InputProps } from '../Forms/types'

export type ContactUsPreferencesProps = {
  heading: string
  workType: InputProps<'radio'>
  postcode: InputProps<'text'>
  service: InputProps<'select'>
  methodOfEntry: Omit<InputProps<'select'>, 'options'> & {
    options: Record<MethodOfEntryOptionKey, FormFieldOptions['options']>
  }
}

export default function ContactUsPreferences(props: ContactUsPreferencesProps) {
  const [workType, serviceName] = useWatch<FormValues>({ name: ['workType', 'serviceName'] }) as [
    FormValues['workType'],
    FormValues['serviceName'],
  ]
  const key = [workType, workType === 'reserve' ? serviceName?.value : undefined]
    .filter(Boolean)
    .join('.') as MethodOfEntryOptionKey
  const { options, ...methodOfEntryProps } = props.methodOfEntry

  return (
    <Fieldset legend={props.heading}>
      <Radio {...props.workType} />
      <Input type={workType === 'reserve' ? 'text' : 'hidden'} {...props.postcode} />
      <Select {...props.service} />
      <Select {...methodOfEntryProps} options={options[key]} />
    </Fieldset>
  )
}
