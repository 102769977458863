import React, { useRef, useState } from 'react'
import { Field, LinkField, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import config from 'temp/config'

import QuickLinks from 'components/front-end/QuickLinks/QuickLink'
import SearchField from 'components/front-end/Search/SearchField'
import Icon from './front-end/Icons/Icon'

interface FAQResponse {
  Result: Result
  Id: number
  Exception: null
  Status: number
  IsCanceled: boolean
  IsCompleted: boolean
  CreationOptions: number
  AsyncState: null
  IsFaulted: boolean
}

interface Result {
  faqs: FAQ[]
  categories: Category[]
  subcategories: Subcategory[]
  tags: Tag[]
  faqCount: number
  query: string
}

interface FAQ {
  id: string
  categories: string[]
  subcategories: string[]
  heading: string
  answer: string
  question: string
  tags: string[]
}

interface Category {
  id: string
  faqCategoryTitle: string
  faqCategorySubtitle: string
}

interface Subcategory {
  id: string
  faqSubcategoryTitle: string
  faqSubcategoryDescription: string
  faqCount: number
}

interface Tag {
  id: string
  tag: string
}

type FaqSearchProps = ComponentProps & {
  fields: {
    SearchInputFieldLabel: Field<string>
    SearchInputFieldPlaceholder: Field<string>
    SearchResultsSubcategoryTitle: Field<string>
    FAQSearchURL: Field<string>
    FAQSearchResultsPage: LinkField
    NoSearchResultsTitle: Field<string>
    NoSearchResultsCopy: Field<string>
  }
}

const sectionClasses = `
relative mb-8
`

const columnLGLessGutters = `
lg:!pr-[calc(var(--colwidth)_-_var(--gutter)_/_2)] lg:!pl-[calc(var(--colwidth)_-_var(--gutter)_/_2)]
xxl:!pr-[calc(var(--colwidth)_*_2_-_var(--gutter)_/_2)] xxl:!pl-[calc(var(--colwidth)_*_2_-_var(--gutter)_/_2)]
`
const transitionClasses = `
transition-all duration-[500ms] ease-out
`
const breadcrumbFollow = `
breadcrumbFollow ${'translate-y-0'}
transition-all duration-[500ms] ease-out
delay-[73ms] ease-linear
`
const itemClasses = `
nav-item sbody relative py-3 px-6 hover:font-bold [.dark_&]:text-white w-full
text-left
`
const iconClasses = `
icon z-[1] absolute right-[18px] top-1/2 -translate-y-1/2
`

const extractValue = (query: string, key: string): string | undefined => {
  const regexp = `${key}=([^&]*)`
  const matches = query?.match(regexp)
  const res = matches?.[1]
  return res && res != '' ? decodeURIComponent(res.replace(/\+/g, ' ')) : undefined
}

const SearchComponent = (props: FaqSearchProps): JSX.Element => {
  const [query, setQuery] = useState('')
  const searchElement = useRef<HTMLDivElement | null>(null)
  const searchField = useRef<HTMLDivElement | null>(null)
  const searchResults = useRef<HTMLDivElement | null>(null)

  const fetchURL = `${config.sitecoreApiHost}/${props.fields.FAQSearchURL.value}?Query=${query}&DatasourceId=${props.rendering.dataSource}`

  const fetcher = (url: string) => fetch(url).then((res) => res.json())
  const { data, error, isValidating } = useSWR<FAQResponse>(query ? fetchURL : null, fetcher)

  if (error) return <div>An error has occurred: {error.message}</div>

  // render data
  return (
    <div className="content-inner pb-22 pt-14 lg:pt-22 xl:pt-22 xxl:pt-[120px]">
      <div className="intersection-observer absolute left-0 top-[-5px] h-[100px] w-4" />

      <section className={`SEARCH-HEAD relative z-[49] h-[100px] bg-white xm:mb-8 ${''}`} ref={searchElement}>
        <div className={`w-full bg-white ${transitionClasses} adjust`} ref={searchField}>
          <div data-cols="12" className={`SEARCH-STICKY adjust relative bg-white ${breadcrumbFollow}`}>
            <div className={` ${transitionClasses} adjust`}>
              <div className={`span-12 ${columnLGLessGutters} ${transitionClasses} adjust`}>
                <SearchField
                  routeQuery={''}
                  focusField={true}
                  inPage={true}
                  location={false}
                  isJobSearch={false}
                  searchTitle={props.fields.SearchInputFieldLabel.value}
                  placeholderText={props.fields.SearchInputFieldPlaceholder.value}
                  updateResults={(query) => {
                    // don't reload for special sequence
                    if (query == '-----') return

                    if (query.length > 2) {
                      setQuery(query)
                    } else {
                      return
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {!data && (
        <section className={`SEARCH-BANNER ${sectionClasses}`}>
          <div data-cols="12" className="relative">
            <div className="row">
              <div className={`span-12 ${columnLGLessGutters}`}>
                <QuickLinks
                  link={{
                    value: {
                      href: 'https://www.google.com/',
                      target: '_blank',
                      title: props.fields?.NoSearchResultsTitle?.value || '',
                      text: props.fields?.NoSearchResultsCopy?.value || '',
                    },
                  }}
                  isServiceTag={false}
                  className="bg-white-off" // Make sure this uses theme version / light / white / 95%
                />
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className={`SEARCH-RESULTS ${sectionClasses} ${
          isValidating ? 'h-[200px] bg-white' : 'bg-white'
        } ${transitionClasses}`}
        ref={searchResults}
      ></section>
      {isValidating ? (
        <div>Loading...</div>
      ) : data && data.Result.faqs.length > 0 ? (
        <ul>
          {data.Result.faqs.map((faq) => (
            <li key={faq.id}>
              <p
                dangerouslySetInnerHTML={{
                  __html: faq.question.replace(
                    new RegExp(`(${query})`, 'gi'),
                    '<span style="background-color: yellow;">$1</span>'
                  ),
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <div>No results found</div>
      )}
    </div>
  )
}

type SubcategoryListItemProps = {
  key: string
  title?: string
  count?: number
  onClick?: () => void
  isSelected?: boolean
}

const SubcategoryListItem = (props: SubcategoryListItemProps): JSX.Element => {
  return (
    <li
      className="flex w-full flex-col
                      border-b border-t border-solid border-grey-light [.dark_&]:border-grey-medium"
    >
      <button
        key={props.key}
        className={`${itemClasses} ${props.isSelected ? 'font-bold' : ''}`}
        onClick={() => props.onClick?.()}
      >
        {props.title} ({props.count})
      </button>
    </li>
  )
}

type FaqListItemProps = {
  key: string
  question?: string
  answer?: string
  onClick?: () => void
  isSelected?: boolean
}

const FaqListItem = (props: FaqListItemProps): JSX.Element => {
  const icon = <Icon name="arrow-east" type="stroke" className="!text-adf-primary" />

  return (
    <li
      className="flex w-full flex-col
border-b border-t border-solid border-grey-light [.dark_&]:border-grey-medium"
    >
      <button
        key={props.key}
        className={`${itemClasses} ${props.isSelected ? 'font-bold' : ''}`}
        onClick={() => props.onClick?.()}
      >
        <span className={iconClasses}>{icon}</span>
        {props.question}
      </button>
    </li>
  )
}

export const FAQSearchResults = (props: FaqSearchProps): JSX.Element => {
  const router = useRouter()
  const [query, setQuery] = useState('')
  const searchElement = useRef<HTMLDivElement | null>(null)
  const searchField = useRef<HTMLDivElement | null>(null)
  const searchResults = useRef<HTMLDivElement | null>(null)
  const [routeQuery] = useState<string>(extractValue(router.asPath, 'query') ?? '')
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<string | null>(null)
  const [selectedFaqId, setSelectedFaqId] = useState<string | null>(null)

  const handleSubcategoryClick = (id: string) => {
    setSelectedSubcategoryId(id)
  }

  const handleFaqClick = (id: string) => {
    setSelectedFaqId(id)
  }

  const fetchURL = `${config.sitecoreApiHost}/${props.fields.FAQSearchURL.value}?Query=${query}&DatasourceId=${props.rendering.dataSource}`

  const fetcher = (url: string) => fetch(url).then((res) => res.json())
  const { data, error, isValidating } = useSWR<FAQResponse>(query ? fetchURL : null, fetcher)

  if (error) return <div>An error has occurred: {error.message}</div>

  const selectedFaqs = selectedSubcategoryId
    ? data?.Result.faqs?.filter((faq) => faq.subcategories.some((sub) => sub === selectedSubcategoryId))
    : []

  const faqsGroupedByHeading = selectedFaqs?.reduce((groups: Record<string, typeof selectedFaqs>, faq) => {
    const key = faq.heading
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(faq)
    return groups
  }, {})

  // render data
  return (
    <div className="content-inner pb-22 pt-14 lg:pt-22 xl:pt-22 xxl:pt-[120px]">
      <div className="intersection-observer absolute left-0 top-[-5px] h-[100px] w-4" />

      <section className={`SEARCH-HEAD relative z-[49] h-[100px] bg-white xm:mb-8 ${''}`} ref={searchElement}>
        <div className={`w-full bg-white ${transitionClasses} adjust`} ref={searchField}>
          <div data-cols="12" className={`SEARCH-STICKY adjust relative bg-white ${breadcrumbFollow}`}>
            <div className={` ${transitionClasses} adjust`}>
              <div className={`span-12 ${columnLGLessGutters} ${transitionClasses} adjust`}>
                <SearchField
                  routeQuery={routeQuery}
                  focusField={true}
                  inPage={true}
                  location={false}
                  isJobSearch={false}
                  searchTitle={props.fields.SearchInputFieldLabel.value}
                  placeholderText={props.fields.SearchInputFieldPlaceholder.value}
                  updateResults={(query) => {
                    // don't reload for special sequence
                    if (query == '-----') return

                    if (query.length > 2) {
                      setQuery(query)
                      router.push(
                        {
                          query: {
                            ...router.query,
                            query: query,
                            page: 1,
                          },
                        },
                        undefined,
                        {
                          shallow: true,
                        }
                      )
                    } else {
                      return
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {!data && (
        <section className={`SEARCH-BANNER ${sectionClasses}`}>
          <div data-cols="12" className="relative">
            <div className="row">
              <div className={`span-12 ${columnLGLessGutters}`}>
                <QuickLinks
                  link={{
                    value: {
                      href: 'https://www.google.com/',
                      target: '_blank',
                      title: 'Sorry no results were found',
                      text: 'Please refine your search, explore the FAQs below, or Contact Us  ',
                    },
                  }}
                  isServiceTag={false}
                  className="bg-white-off" // Make sure this uses theme version / light / white / 95%
                />
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className={`SEARCH-RESULTS ${sectionClasses} bg-white ${isValidating ? 'h-[200px]' : ''} ${transitionClasses}`}
        ref={searchResults}
      >
        <div data-cols="12" className="relative pb-12 pt-12">
          <div className="row">
            <div className="col span-12 xm-span-4 mb-10">
              <Text tag="h6" className="mb-5" field={props.fields.SearchResultsSubcategoryTitle} />
              {isValidating ? (
                <div>Loading...</div>
              ) : data && data.Result.faqs.length > 0 ? (
                <ul>
                  {data.Result.subcategories.map((subcategory) => {
                    const faqsWithSubcategory = data.Result.faqs.filter((faq) =>
                      faq.subcategories.some((sub) => sub === subcategory.id)
                    )
                    return (
                      <SubcategoryListItem
                        key={subcategory.id}
                        title={subcategory.faqSubcategoryTitle}
                        count={faqsWithSubcategory.length}
                        onClick={() => handleSubcategoryClick(subcategory.id)}
                        isSelected={selectedSubcategoryId === subcategory.id}
                      />
                    )
                  })}
                </ul>
              ) : (
                <div>No results found</div>
              )}
            </div>
            <div className="span-12 xm-offset-2 xm-span-6">
              {faqsGroupedByHeading &&
                Object.keys(faqsGroupedByHeading).map((heading) => (
                  <div className="mb-10" key={heading}>
                    <h6 className="mb-5">{heading}</h6>
                    <ul>
                      {faqsGroupedByHeading &&
                        faqsGroupedByHeading[heading].map((faq) => {
                          return (
                            <FaqListItem
                              key={faq.id}
                              question={faq.question}
                              answer={faq.answer}
                              onClick={() => handleFaqClick(faq.id)}
                              isSelected={selectedFaqId === faq.id}
                            />
                          )
                        })}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withDatasourceCheck()<FaqSearchProps>(SearchComponent)
