import Icon from '../Icons/Icon'

type SummaryInfoProps = {
  title: string
  text?: string
  service: string
  className?: string
}

const SummaryInfo = ({ className, title, text, service }: SummaryInfoProps): JSX.Element => {
  return (
    <div
      className={`${className} flex w-full flex-row items-center py-[18px] xs:items-start xs:pb-4 xs:pt-6 sm:flex-col`}
    >
      <div className="xstag w-[92px] pb-3 text-grey-medium sm:whitespace-nowrap">{service}</div>
      <div className="flex flex-col pl-4 pt-px xs:pl-0 xs:pt-0">
        <div className="meta">{title}</div>
        <div className="sbody pb-0 pt-1 text-grey-medium xs:pb-1">{text}</div>
      </div>
    </div>
  )
}

export default SummaryInfo
