import { twMerge } from 'tailwind-merge'

type FeaturedEventWrapperProps = {
  children: React.ReactNode
  id: string
  featured?: boolean
  doubleFeatured?: boolean
}

function FeaturedEventWrapper({ id, featured, doubleFeatured, children }: FeaturedEventWrapperProps) {
  return (
    <div
      key={id}
      id={id}
      className={twMerge(
        'xm:col-span-4',
        featured && 'xm:col-span-12 lg:col-span-8',
        doubleFeatured && 'xm:col-span-6'
      )}
    >
      {children}
    </div>
  )
}

export default FeaturedEventWrapper
