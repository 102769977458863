import { useEffect, useState } from 'react'

import { screens } from 'utils/tailwindConfig'

// Create a type for valid screen keys
export type ScreenKey = keyof typeof screens

// Function to create a media query string from a breakpoint
const createMediaQuery = (breakpoint: string, isGreaterThan: boolean): string => {
  return isGreaterThan ? `(min-width: ${breakpoint})` : `(max-width: ${breakpoint})`
}

// Shared logic for comparing window width to breakpoint using matchMedia
function useMediaQueryMatch(breakpoint: ScreenKey, isGreaterThan: boolean): boolean {
  const [matches, setMatches] = useState<boolean>(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia(createMediaQuery(screens[breakpoint], isGreaterThan))

    const updateMatches = (e: MediaQueryListEvent) => {
      setMatches(e.matches)
    }

    // Set initial value
    setMatches(mediaQuery.matches)

    // Add event listener
    mediaQuery.addEventListener('change', updateMatches)

    // Cleanup
    return () => mediaQuery.removeEventListener('change', updateMatches)
  }, [breakpoint, isGreaterThan])

  return matches
}

export function useGreaterThan(breakpoint: ScreenKey): boolean {
  return useMediaQueryMatch(breakpoint, true)
}

export function useLessThan(breakpoint: ScreenKey): boolean {
  return useMediaQueryMatch(breakpoint, false)
}

export function useScreen(): ScreenKey {
  const [currentScreen, setCurrentScreen] = useState<ScreenKey>('sm')

  useEffect(() => {
    const breakpoints: ScreenKey[] = Object.keys(screens) as ScreenKey[]

    const updateScreen = () => {
      const newScreen = breakpoints.reduce((acc, breakpoint) => {
        if (window.matchMedia(`(min-width: ${screens[breakpoint]})`).matches) {
          return breakpoint
        }
        return acc
      }, 'sm' as ScreenKey)

      setCurrentScreen(newScreen)
    }

    // Initial check
    updateScreen()

    // Add event listener
    window.addEventListener('resize', updateScreen)

    // Cleanup
    return () => window.removeEventListener('resize', updateScreen)
  }, [])

  return currentScreen
}
