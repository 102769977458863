import { Text } from '@sitecore-jss/sitecore-jss-nextjs'

import { QuoteCarouselItem } from 'components/ADFQuoteCarousel'
import Button from '../Buttons/Button'
import Icon from '../Icons/Icon'

const serviceColour = {
  'tri-service': 'text-tri-service',
  army: 'text-army',
  'air-force': 'text-air-force',
  navy: 'text-navy',
} as const

const getTextColor = (value: string) =>
  serviceColour[value.toLowerCase().replaceAll(' ', '-') as keyof typeof serviceColour]

type QuoteSlideTextProps = {
  id: string
  service?: string
  fields: Pick<QuoteCarouselItem['fields'], 'TextStyle' | 'Text' | 'QuoteSource' | 'JobTitle' | 'Title' | 'CTAButton'>
}

const QuoteSlideText = ({ id, service = '', fields }: QuoteSlideTextProps) => {
  const isPullQuote = fields.TextStyle.value === 'Pull Quote'
  const isStatement = fields.TextStyle.value === 'Statement CTA' || fields.TextStyle.value === 'Statement'

  return (
    <div className="flex h-full xm:items-end xm:pl-12 xm:pr-[--outermargin]">
      <blockquote className="flex flex-col gap-y-5 text-black-off xm:gap-y-8 lg:gap-y-12 [.dark_&]:!text-white [.light_&]:text-black-off">
        <div className="large">
          {isPullQuote && (
            <Icon
              name="quote"
              className={`mb-3 size-6 lg:mb-6 lg:h-12 lg:w-12 ${service ? getTextColor(service) : 'text-tri-service'}`}
            />
          )}
          {!isPullQuote && fields?.Title.value && <span className="ltag mb-6 block">{fields?.Title.value}</span>}
          <Text field={fields.Text} />
          {isStatement && fields?.CTAButton.value.href && (
            <div className="mt-10">
              <Button
                data-trackingid={id}
                link={{
                  value: {
                    href: fields?.CTAButton.value.href,
                    text: fields?.CTAButton.value.text,
                    target: fields?.CTAButton.value.target,
                  },
                }}
                service={service}
                icon="chevron-right-double"
                type="primary"
              />
            </div>
          )}
        </div>
        {isPullQuote && fields.QuoteSource.value && (
          <footer className="flex gap-x-2">
            <Icon
              name="user"
              type="stroke"
              className="inline h-[1lh] w-5 shrink-0 [.dark_&]:!text-white [.light_&]:text-black-off"
            />
            <cite className="mbody not-italic">{fields.QuoteSource.value}</cite>
          </footer>
        )}
      </blockquote>
    </div>
  )
}

export default QuoteSlideText
