import React from 'react'
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs'

interface Fields {
  Image: ImageField
}

type ImageProps = {
  params: { [key: string]: string }
  fields: Fields
}

const ADFBannerImage = (props: ImageProps): JSX.Element => {
  const containerStyles = `
    ${props.params?.GridParameters ?? ''}
    ${props.params?.styles ?? ''}
  `

  const containerImage = `
      bg-cover bg-no-repeat bg-center
      xxl:h-[875px] xl:h-[681px] lg:h-[650px] lg:h-[650px] xm:h-[476px] md:h-[372px] sm:h-[236px] h-[236px]
    `
  return (
    <div
      className={`relative ${containerStyles} ${containerImage}`}
      style={{ backgroundImage: `url(${props?.fields?.Image?.value?.src})` }}
    />
  )
}
export default ADFBannerImage
