import ReCAPTCHA from 'react-google-recaptcha'
import { useRef, useState } from 'react'

interface ReCaptchaProps {
  onCaptchaToken: (token: string | null) => void
}

const ReCaptcha = ({ onCaptchaToken }: ReCaptchaProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  async function handleCaptchaSubmission(token: string | null) {
    // Server function to verify captcha
    console.log(token)
    // Call the callback function with the token
    onCaptchaToken(token)
  }

  return (
    <>
      <ReCAPTCHA
        sitekey="6LcY_roUAAAAAGGPSYdqVht5zsBUqOX4_57i4NZ3"
        ref={recaptchaRef}
        onChange={handleCaptchaSubmission}
      />
    </>
  )
}
export default ReCaptcha
