import React, { useState } from 'react'
import { Link, LinkField, Text, TextField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import Icon from './front-end/Icons/Icon'

interface Fields {
  PageTitle: TextField
  SitemapItems: SitemapItem[]
}

interface SitemapItem {
  Id: string
  DisplayName: string
  PageTitle: TextField
  NavigationTitle: TextField
  Href: string
  Querystring: string
  Children: Array<SitemapItem>
  Styles: string[]
}

type NavigationProps = {
  params?: { [key: string]: string }
  fields: Fields
}

type SitemapItemProps = {
  params?: { [key: string]: string }
  fields: SitemapItem
  handleClick: (event?: React.MouseEvent<HTMLElement>) => void
  relativeLevel: number
}

const getNavigationText = function (props: SitemapItemProps): JSX.Element | string {
  let text

  if (props.fields.NavigationTitle) {
    text = <Text field={props.fields.NavigationTitle} />
  } else if (props.fields.PageTitle) {
    text = <Text field={props.fields.PageTitle} />
  } else {
    text = props.fields.DisplayName
  }

  return text
}

const getLinkField = (props: SitemapItemProps): LinkField => ({
  value: {
    href: props.fields.Href,
    title: getLinkTitle(props),
    querystring: props.fields.Querystring,
  },
})

export const Default = (props: NavigationProps): JSX.Element => {
  const [isOpenMenu, openMenu] = useState(false)
  const { sitecoreContext } = useSitecoreContext()
  const styles =
    props.params != null ? `${props.params.GridParameters ?? ''} ${props.params.Styles ?? ''}`.trimEnd() : ''
  const id = props.params != null ? props.params.RenderingIdentifier : null

  if (!Object.values(props.fields).length) {
    return (
      <div className={`component sitemap-navigation ${styles}`} id={id ? id : undefined}>
        <div className="component-content">[Navigation]</div>
      </div>
    )
  }

  const handleToggleMenu = (event?: React.MouseEvent<HTMLElement>, flag?: boolean): void => {
    if (event && sitecoreContext?.pageEditing) {
      event.preventDefault()
    }

    if (flag !== undefined) {
      return openMenu(flag)
    }

    openMenu(!isOpenMenu)
  }

  const list = Object.values(props.fields.SitemapItems)
    .filter((element) => element)
    .map((element: SitemapItem, key: number) => (
      <NavigationList
        key={`${key}${element.Id}`}
        fields={element}
        handleClick={(event: React.MouseEvent<HTMLElement>) => handleToggleMenu(event, false)}
        relativeLevel={1}
      />
    ))

  return (
    <div className={`component sitemap-navigation ${styles}`} id={id ? id : undefined}>
      <div className="component-content">
        <Text className="mb-10" field={props.fields.PageTitle} tag="h1" />
        <nav>
          <ul className="clearfix w-auto md:w-2/4">{list}</ul>
        </nav>
      </div>
    </div>
  )
}

const NavigationList = (props: SitemapItemProps) => {
  const { sitecoreContext } = useSitecoreContext()

  let children: JSX.Element[] = []
  if (props.fields.Children && props.fields.Children.length) {
    children = props.fields.Children.map((element: SitemapItem, index: number) => (
      <NavigationList
        key={`${index}${element.Id}`}
        fields={element}
        handleClick={props.handleClick}
        relativeLevel={props.relativeLevel + 1}
      />
    ))
  }

  return (
    <li
      className={props.fields.Styles?.concat('rel-level' + props.relativeLevel).join(' ')}
      key={props.fields.Id}
    >
      <div className="pt-6 pb-6 border-b border-solid border-grey-light">
        <Link tabIndex={0} className="flex flex-row justify-between w-full hover:underline cursor-pointer" field={getLinkField(props)} editable={sitecoreContext.pageEditing} onClick={props.handleClick}>
          {getNavigationText(props)}
          <Icon name="chevron-right-double" type="stroke" width={18} height={18} />
        </Link>
      </div>
      {children.length > 0 ? <ul className="clearfix">{children}</ul> : null}
    </li>
  )
}

const getLinkTitle = (props: SitemapItemProps): string | undefined => {
  let title
  if (props.fields.NavigationTitle?.value) {
    title = props.fields.NavigationTitle.value.toString()
  } else if (props.fields.PageTitle?.value) {
    title = props.fields.PageTitle.value.toString()
  } else {
    title = props.fields.DisplayName
  }

  return title
}
