import { CSSProperties } from 'react'
import { ImageField, NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import ImageLoader from '../Images/ImageLoader'
import VideoBanner from '../Video/VideoBanner'

type QuoteSlideImageProps = {
  image: ImageField
  videoId?: string
  service?: string
} & JSX.IntrinsicElements['div']

const carouselAspectRatio = 3 / 2

const QuoteSlideImage = ({ image, videoId, service }: QuoteSlideImageProps) => {
  const { sitecoreContext } = useSitecoreContext()
  const imageSrc = image.value?.src as string
  const imageAlt: string = (image.value?.alt as string) ?? ''

  return (
    <div
      className="relative ml-[--outermargin] w-full select-none border-r-0 border-r-white dark:border-r-black-off xm:border-r-4 [&_.image-loader]:aspect-[--aspectRatio] [&_.image-loader]:h-auto [&_.image-loader]:!pb-0 [.swiper-slide_&]:ml-0"
      style={{ '--aspectRatio': `${carouselAspectRatio}/1` } as CSSProperties}
    >
      {videoId ? (
        <VideoBanner id={videoId} image={image.value?.src} imageAlt={imageAlt} compact service={service} black />
      ) : sitecoreContext.pageEditing ? (
        <NextImage field={image} className="block aspect-[--aspectRatio] w-full max-w-full object-cover" />
      ) : imageSrc ? (
        <ImageLoader
          {...image.value}
          width={913}
          height={609}
          src={imageSrc}
          alt={imageAlt}
          className="block aspect-[--aspectRatio] w-full max-w-full object-cover"
          sizes="(max-width: 1024px) 66vw,100vw"
        />
      ) : null}
    </div>
  )
}

export default QuoteSlideImage
