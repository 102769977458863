import React from 'react'
import { ComponentParams, ComponentRendering, Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { Service } from 'src/utils/useService'

import { StrokeIcons } from 'components/front-end/Icons/icon-data'
import Notification from './front-end/Notifications/Notification'

interface Fields {
  Type: Field<string>
  Message: Field<string>
  Icon: Field<StrokeIcons>
  Status: Field<string>
  Service?: Field<Service>
}

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: Fields
}

export const ADFNotification = (props: ComponentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  // const type = 'Embedded' //props?.fields?.Type?.value ?? props.params?.Type
  const icon = props?.fields?.Icon?.value ?? props.params?.Icon
  const message = props?.fields?.Message?.value ?? props.params?.Message
  const status = undefined //props?.fields?.Status?.value ?? props.params?.Status
  const isPageEditing = sitecoreContext.pageEditing
  const id = props.params.RenderingIdentifier ?? props.params.DynamicPlaceholderId
  const isToast = false //type === toastValue

  return (
    <div id={id ? id : undefined} className={isToast && !isPageEditing ? 'adf-notification-container' : undefined}>
      <Notification
        id={id}
        isToast={isToast}
        text={message}
        icon={{ name: icon, type: 'stroke' }}
        toastIconType={status}
        serviceName={props.fields.Service?.value}
      />
    </div>
  )
}

export default ADFNotification
