import React from 'react'
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'

import { PageHeaderComponent, PageHeaderProps } from './front-end/PageHeader/PageHeader'

const RealStoriesHeaderComponent = (props: PageHeaderProps<'Quote'>): JSX.Element => {
  return <PageHeaderComponent {...props} contentComponentType="Quote" showAudioPlayer />
}

export default withDatasourceCheck()<PageHeaderProps<'Quote'>>(RealStoriesHeaderComponent)
