import { useBreakPoint } from 'src/utils/useBreakpoints'

// {xs: 375}
// {sm: 428}
// {md: 768}
// {xm: 1024}
// {lg: 1280}
// {xl: 1440}
// {xxl: 1920}

const GridTest = () => {
  return (
    <>
      <div id="grid">
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
        <div>10</div>
        <div>11</div>
        <div>12</div>
      </div>
      <div className="screen-size fixed right-0 top-0 bg-army/20 p-3">Screen {useBreakPoint()}</div>
    </>
  )
}

export default GridTest
