import React from 'react'
import {
  Field,
  ImageField,
  Image as JssImage,
  LinkField,
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs'

import { ComponentProps } from 'lib/component-props'
import { cleanHeadingStyle } from 'src/utils/cleanHeadingStyle'
import ImageLoader from './front-end/Images/ImageLoader'
import TitleText from './front-end/TextBlocks/TitleText'

interface Fields {
  Layout: Field<string> // Feature Right, Feature Left
  FeatureImageType: Field<string> // Portrait, Landscape
  FeatureImage: ImageField
  Heading: Field<string>
  HeadingStyle: Field<string>
  Description: Field<string>
  LedeCopySize: Field<string>
  CTAButton1: LinkField
  CTAButton1Theme: Field<string>
}

export type TextImageProps = ComponentProps & {
  fields: Fields
}

export const ImageText = (props: TextImageProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const id = props.params?.RenderingIdentifier ?? props.params?.DynamicPlaceholderId
  const isFeatureRight = props.fields.Layout.value === 'Feature Right'
  const isLandscape = props.fields.FeatureImageType.value === 'Landscape'
  const textSizes = { Large: 'lbody', Regular: 'mbody', Small: 'sbody' }
  const textSize = textSizes[(props.fields.LedeCopySize.value as keyof typeof textSizes) || 'Regular']
  const imageSpanClass = `w-flow
    ${!isLandscape ? 'col-span-2' : 'col-span-1 xm:col-span-3'}
    ${isFeatureRight ? 'md:order-last' : 'order-none  flow-left'}`
  const ledeTextSpanClass = !isLandscape ? 'flex flex-col xm:col-span-3' : 'flex flex-col xm:col-span-2'

  return (
    <>
      <div
        id={id ? id : undefined}
        data-cols="12"
        className="mb-10 grid items-center gap-x-20 gap-y-10 md:grid-cols-2 xm:grid-cols-5 lg:gap-x-32"
      >
        <div className={imageSpanClass}>
          {sitecoreContext.pageEditing ? (
            <JssImage field={props.fields.FeatureImage} className="w-full" />
          ) : props?.fields?.FeatureImage?.value?.src ? (
            <ImageLoader
              src={props.fields.FeatureImage?.value?.src as string}
              alt={props.fields.FeatureImage?.value?.alt as string}
              width={isLandscape ? 960 : 600}
              height={isLandscape ? 600 : 700}
              sizes="(max-width: 768px) 100vw, 50vw"
            />
          ) : null}
        </div>
        <div className={ledeTextSpanClass}>
          <TitleText
            id={props.rendering.uid as string}
            title={props.fields.Heading.value}
            text={props.fields.Description.value}
            LedeCopySize={textSize}
            HeadingStyle={cleanHeadingStyle(props.fields.HeadingStyle.value)}
            CTAButton1={props.fields.CTAButton1}
            CTAButton1Theme={
              props.fields?.CTAButton1Theme?.value?.toLowerCase() as 'primary' | 'secondary' | 'tertiary'
            }
          />
          <div className="mt-4">
            <Placeholder name={`btn-placeholder`} rendering={props.rendering} />
          </div>
        </div>
      </div>
    </>
  )
}

export default withDatasourceCheck()<TextImageProps>(ImageText)
