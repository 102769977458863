import React from 'react'
import { Field, ImageField, Image as JssImage, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import Image from 'next/image'
import { twJoin, twMerge } from 'tailwind-merge'

import Button, { ButtonProps } from 'components/front-end/Buttons/Button'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import StandardTagList from 'components/front-end/Tags/StandardTagList'
import { useService } from 'src/utils/useService'
import DateTag, { DateTagProps } from '../Tags/DateTag'

interface ImageProps {
  fixed?: boolean
  lazy?: boolean
  field: ImageField
  width?: number
  height?: number
  sizes?: string
}

interface ImageOverlayCardProps extends ImageProps {
  title?: Field<string>
  subtitle?: Field<string>
  image?: ImageProps
  className?: string
  service?: string
  isReserve?: boolean
  url?: string
  tags?: string[]
  dateTag?: DateTagProps
  cardType?: string
  description?: Field<string>
  cardLink?: ButtonProps
  buttons?: ButtonProps[]
  children?: React.ReactNode
}

const ImageOverlayCard = (props: ImageOverlayCardProps) => {
  const { sitecoreContext } = useSitecoreContext()
  const service = useService(props.service)
  const serviceLogos: Record<string, string> = {
    'air-force': '/logos/logo-airforce.avif',
    army: '/logos/logo-army.avif',
    navy: '/logos/logo-navy.avif',
    'tri-service': '/logos/logo-adfa-careers-dark.svg',
  }
  const serviceLogo = serviceLogos[`${props.service || 'tri-service'}`]

  return (
    <div className={twMerge('image-overlay-card dark overflow-hidden', props.className)}>
      {sitecoreContext.pageEditing ? (
        <div className="overlay-image aspect-[2/3] h-full max-h-[800px] min-h-[33vw] w-full [&>.image-loader]:!p-0 md:[.xm\:col-span-4_&]:aspect-[4/3]">
          <JssImage field={props.field} />
        </div>
      ) : (
        <div className="[>_div_&]:overlay-image aspect-[2/3] h-full max-h-[800px] min-h-[33vw] w-full [&>.image-loader]:!p-0 md:[.xm\:col-span-4_&]:aspect-[4/3]">
          <ImageLoader
            src={props?.field?.value?.src as string}
            alt={props?.field?.value?.alt as string}
            width={props.width || (props?.field?.value?.width as number)}
            height={props.height || (props?.field?.value?.height as number)}
            full
            lazy={props.lazy !== false ? true : undefined}
            sizes={props.sizes || '(max-width: 1279px) 100vw, 66vw'}
          />
        </div>
      )}
      <div className="absolute inset-0 z-10 flex h-full w-full flex-col gap-4 ">
        {(props.service || props.tags) && (
          <div
            className={twJoin(
              'opacity-overlay absolute left-0 top-0 flex h-12 w-full items-center pt-10 shadow-[0_0_60px_60px_rgba(0,0,0,.6)]',
              props.dateTag ? 'pl-10 xm:pl-0 xm:pr-10' : 'px-10'
            )}
          >
            {props.service && (
              <ServiceTag service={props.service as string} isCard={true} isReserve={props.isReserve} />
            )}
            {props.tags && <StandardTagList className="ml-auto" tags={props.tags} />}
            {props.dateTag && <DateTag {...props.dateTag} className="ml-6 xm:order-first xm:ml-0 xm:mr-6" />}
          </div>
        )}
        <div className="opacity-overlay mt-auto p-10 shadow-[0_0_60px_60px_rgba(0,0,0,.6)]">
          {props.cardType === 'service' && (
            <div>
              <Image
                className="mb-3 mr-auto h-12 w-auto"
                src={serviceLogo}
                alt={`${props.service} logo`}
                width={192}
                height={48}
                unoptimized
              />
            </div>
          )}
          {props.cardType !== 'service' && props.subtitle && (
            <p className="sbody mb-3 font-uniform-condensed uppercase">
              <Text field={props.subtitle} />
            </p>
          )}
          {props.cardType !== 'service' && props.title && (
            <h5 className="h6 mb-4" aria-level={3}>
              <Text field={props.title} />
            </h5>
          )}
          {props.description && (
            <p className="sbody">
              <Text field={props.description} />
            </p>
          )}
          {props.children}
          {(props.cardLink || props.buttons) && (
            <div className="mt-10 flex flex-wrap gap-4">
              {props.cardLink && (
                <Button
                  data-trackingid={props.cardLink['data-trackingid']}
                  className={twJoin('before:absolute before:inset-0 before:z-0', props.cardLink && 'static')}
                  link={props.cardLink.link}
                  service={service}
                  icon={props.cardLink.icon || 'chevron-right-double'}
                  type={props.cardLink.type || 'small-secondary'}
                />
              )}
              {props.buttons?.map(({ className, ...buttonProps }, i) => (
                <Button
                  key={i}
                  className={twMerge(className, props.cardLink && 'relative z-10')}
                  {...buttonProps}
                  service={props.service}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageOverlayCard
