'use client'

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { createPortal } from 'react-dom'
import YouTube, { YouTubeProps } from 'react-youtube'

import Button from 'components/front-end/Buttons/Button'

export type CustomModalRef = {
  current: HTMLDivElement | null
  openModal: () => void
}

export type VideoProps = {
  id: string
  videoId: string
  service?: string
  ref: CustomModalRef
  callback?: () => void
}

const Video = forwardRef(({ id, videoId, service, callback }: VideoProps, ref) => {
  const videoWrapper = useRef<HTMLDivElement>(null)
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
    setModalActive(true)
  }
  const opts: YouTubeProps['opts'] = {
    height: '1280',
    width: '720',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 1,
      modestbranding: 1,
      playsinline: 1,
    },
  }

  const [showModal, setShowModal] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [animateModal, setAnimateModal] = useState(false)

  useImperativeHandle(ref, () => ({
    openModal() {
      openModal()
    },
  }))

  function openModal() {
    setShowModal(true)
    setTimeout(() => setAnimateModal(true))
  }

  function closeModal() {
    setAnimateModal(false)
    setModalActive(false)
    setTimeout(() => setShowModal(false), 300)
  }

  const videoModalClasses = `
        video-modal fixed top-0 left-0 w-full h-full flex justify-center items-center z-50
        `
  const bgModalClasses = `
        absolute top-0 left-0 w-full h-full bg-black-off
        transition duration-300
        ${animateModal ? 'opacity-1' : 'opacity-0'}
    `
  const youTubeWrapperClasses = `
        relative w-[85%]
        transition duration-300
        ${animateModal ? 'translate-y-0 opacity-1' : 'translate-y-20 opacity-0'}
    `
  const youTubeClasses = `
        z-20 relative w-full h-0 pb-[56.25%] bg-black
        [&_iframe]:absolute [&_iframe]:top-0 [&_iframe]:left-0 [&_iframe]:w-full [&_iframe]:h-full
    `
  const fadeInClasses = `
        absolute top-0 w-full h-full bg-black z-30 pointer-events-none
        transition duration-300 delay-300
        ${animateModal ? 'opacity-0' : 'opacity-1'}
    `
  const closeClasses = `
        absolute top-10 right-10 z-40 dark
        transition duration-300
        ${animateModal ? 'opacity-1' : 'opacity-0'}
    `

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal()
      }
    }
    if (modalActive) {
      const iframe = videoWrapper.current?.querySelector('iframe')
      document.addEventListener('keydown', handleKeyDown)
      if (iframe) {
        iframe.focus()
      }
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [modalActive])

  return (
    <>
      {showModal &&
        createPortal(
          <FocusTrap>
            <div className={videoModalClasses} ref={videoWrapper} role="region">
              <div className={bgModalClasses} onClick={() => closeModal()}></div>
              <div className={youTubeWrapperClasses}>
                <span tabIndex={0} className="sr-only">
                  Video Player
                </span>
                <YouTube className={youTubeClasses} videoId={videoId} opts={opts} onReady={onPlayerReady} />
                <div className={fadeInClasses}></div>
              </div>
              <div className={closeClasses}>
                <Button
                  data-trackingid={id}
                  link={{ value: { text: 'close' } }}
                  icon="close"
                  type="small-action"
                  button
                  service={service}
                  onClick={() => {
                    if (callback) callback()
                    closeModal()
                  }}
                />
              </div>
            </div>
          </FocusTrap>,
          document.body
        )}
    </>
  )
})

Video.displayName = 'VideoModal'

export default Video
