import { ComponentParams, Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'

export type NavigationItem = {
  url: {
    path: string
  }
  hideInNavigation: {
    value: Field<boolean>
  }
  hideInBreadcrumb: {
    value: Field<boolean>
  }
  hideBackLink: {
    value: Field<boolean>
  }
  showAsQuickLink: {
    value: Field<boolean>
  }
  ctaDescription: Field<string>
  navigationTitle: Field<string>
  pageTitle: Field<string>
  children: {
    results: NavigationItem[]
  }
}

export interface NavigationFields {
  data: {
    datasource: Record<string, { field: LinkField }>
    // account
    // field
    // value
    // "Account"
    item: {
      children: {
        results: NavigationItem[]
      }
    }
  }
}

export type NavigationProps = {
  params?: ComponentParams
  fields: NavigationFields
}

export type NavigationAction = {
  value: string
  url?: {
    path?: string
  }
}
