import { useState } from 'react'
import { ImageField, NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import { useService } from 'src/utils/useService'
import Button from '../Buttons/Button'
import ImageLoader from '../Images/ImageLoader'

type CategoryCardProps = {
  id: string
  image?: ImageField
  title?: string
  jobsCategoryTitle?: string
  fullTimeJobs?: string
  partTimeJobs?: string
  exploreButtonLabel?: string
  exploreButtonLink?: string
  exploreButtonTarget?: string
  careersButtonLabel?: string
  careersButtonLink?: string
  careersButtonTarget?: string
}

const CategoryCard = ({
  id,
  image,
  title,
  jobsCategoryTitle,
  fullTimeJobs,
  partTimeJobs,
  exploreButtonLabel,
  exploreButtonLink,
  exploreButtonTarget,
  careersButtonLabel,
  careersButtonLink,
  careersButtonTarget,
}: CategoryCardProps): JSX.Element => {
  const service = useService()
  const [isHovering, setIsHovering] = useState(false)
  const containerClass = `icon-hover-${isHovering} w-full overflow-hidden`
  const imageDivClasses = `
    opacity-overlay
    w-full
    shadow-[0_0_60px_60px_rgba(0,0,0,.6)]
    absolute
    bottom-0 left-0
    pr-2.5 pb-8 pl-8 lg:pl-10 lg:pb-10
    text-white dark inline-block
  `
  const paraClass = 'xstag pb-8 lg:pb-10'
  const buttonDivClass = 'inline-block pr-4 pb-2.5 lg:inline-block lg:pr-4'
  const mainDivClass = 'relative w-full from-slate-950 lg:h-[768px] h-[550px]'

  const { sitecoreContext } = useSitecoreContext()

  return (
    <div className={containerClass} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <div className={mainDivClass}>
        <div className="absolute inset-0 z-10 h-1/3 bg-gradient-to-b from-black/60 to-transparent" />
        {sitecoreContext.pageEditing ? (
          <NextImage
            field={image}
            width={600}
            height={400}
            className="absolute inset-0 size-full object-cover transition duration-200"
          />
        ) : (
          <ImageLoader
            src={image?.value?.src || ''}
            width={100}
            height={100}
            alt={(image?.value?.alt as string) || ''}
            lazy
            full
            sizes="(max-width: 1023px) 100vw, 66vw"
          />
        )}
        <div>
          <div className={imageDivClasses}>
            <h5 className="pb-4">{title}</h5>
            <p className={paraClass}>
              <span className="pr-3">{jobsCategoryTitle}</span>
              <span className="border-l pl-3">{fullTimeJobs} full time</span>
              <span className="pl-3">{partTimeJobs} part time</span>
            </p>
            <div className={buttonDivClass}>
              <Button
                data-trackingid={id}
                icon="chevron-right-double"
                isSmall={true}
                link={{
                  value: {
                    href: exploreButtonLink || '',
                    target: exploreButtonTarget ? exploreButtonTarget : '_self',
                    text: exploreButtonLabel || '',
                  },
                }}
                type="secondary"
                service={service}
              />
            </div>
            <div className="inline-block">
              <Button
                data-trackingid={id}
                icon="chevron-right-double"
                isSmall={true}
                link={{
                  value: {
                    href: careersButtonLink || '',
                    target: careersButtonTarget ? careersButtonTarget : '_self',
                    text: careersButtonLabel || '',
                  },
                }}
                type="secondary"
                service={service}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryCard
