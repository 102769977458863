import { useCallback, useEffect, useState } from 'react'
import {
  Field,
  ImageField,
  LinkField,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'

import Button from './front-end/Buttons/Button'
import ImageOverlayCard from './front-end/Cards/ImageOverlayCard'
import InfoPanel from './front-end/SitecoreHelpers/InfoPanel'

interface CardItemFields {
  CtaTitle: Field<string>
  CtaDescription?: Field<string>
  CtaImage: ImageField
  CtaButtonText: Field<string>
  Service: Field<string>
}

interface CardRowLayoutItem {
  fields: {
    CardRowLayoutType: Field<string>
  }
}

type RealStoriesCardItem = {
  id: string
  url?: string
  fields: CardItemFields
  items: CardRowLayoutItem[]
}

export type RealStoriesCardGridProps = ComponentProps & {
  fields: {
    NumberOfRowsDisplayed: Field<number>
    DisplayFilterTags: Field<boolean>
    LoadMoreText: Field<string>
    RealStoriesProfiles: RealStoriesCardItem[]
    items: CardRowLayoutItem[]
  }
}

const CarouselCardsDefaultComponent = (): JSX.Element => (
  <div>
    <span className="is-empty-hint">Real Stories Profiles</span>
  </div>
)

const RealStoriesCardGrid = ({ fields, rendering }: RealStoriesCardGridProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const [filters, setFilters] = useState<string[]>([])
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [showAll, setShowAll] = useState<boolean>(false)
  const [rowLayout] = useState<string[]>([])
  let removedCards = 0 as number

  const serviceDisplayName = {
    army: 'Army',
    navy: 'Navy',
    'air-force': 'Air Force',
    'tri-service': 'All Services',
  }
  const calcDisplayTotal = useCallback(() => {
    if (!showAll && fields.NumberOfRowsDisplayed.value) {
      const rmCount = rowLayout
        .slice(0, fields.NumberOfRowsDisplayed.value)
        .filter((row) => ['featuredLeft', 'featuredRight', 'twoUp'].includes(row)).length
      return fields.NumberOfRowsDisplayed.value * 3 - rmCount
    } else {
      return fields.RealStoriesProfiles.length
    }
  }, [fields.NumberOfRowsDisplayed.value, fields.RealStoriesProfiles.length, rowLayout, showAll])
  const [displayTotal, setDisplayTotal] = useState<number | void>(() => calcDisplayTotal())

  useEffect(() => {
    setDisplayTotal(calcDisplayTotal())
  }, [calcDisplayTotal, showAll])

  useEffect(() => {
    const createFilters = fields.RealStoriesProfiles.reduce<string[]>((acc, curr) => {
      const serviceValue = curr.fields.Service.value as string
      if (serviceValue && !acc.includes(serviceValue)) {
        acc.push(serviceValue)
      }
      return acc
    }, [])
    setFilters(createFilters)
  }, [fields])

  const setActiveFilter = (filter: string[]) => {
    if (activeFilters?.includes(filter[0])) {
      setActiveFilters(activeFilters.filter((f) => f !== filter[0]))
    } else {
      setActiveFilters([...activeFilters, filter[0]])
    }
  }

  fields.items?.forEach((row) => {
    const layout = row.fields.CardRowLayoutType.value
    if (layout === '2 Cards Feature Left') {
      rowLayout.push('featuredLeft')
      removedCards = removedCards + 1
    } else if (layout === '2 Cards Feature Right') {
      rowLayout.push('featuredRight')
      removedCards = removedCards + 1
    } else if (layout === '2 Cards') {
      rowLayout.push('twoUp')
      removedCards = removedCards + 1
    } else {
      rowLayout.push('threeUp')
    }
  })

  const gridClasses = `grid md:grid-cols-2 gap-6 mb-10 ${fields.RealStoriesProfiles.length > 2 && 'xm:grid-cols-6'}`
  const gridItemClasses = (key: number, featured: number[], twoUp: number[]) => {
    if (featured.includes(key)) {
      return `xm:col-span-4`
    } else if (twoUp.includes(key)) {
      return `xm:col-span-3`
    } else {
      return `xm:col-span-2`
    }
  }

  const filterButtonClasses = `py-3 px-4 bg-grey-light hover:bg-grey-dark hover:text-white dark:bg-grey-medium`
  const filterButtonActiveClasses = `!bg-grey-dark text-white dark:!bg-white dark:!text-black`

  if (Object.values(fields).length) {
    const filteredProfiles = fields.RealStoriesProfiles.filter((story) =>
      activeFilters.length ? activeFilters.includes(story.fields.Service.value as string) : true
    ).slice(0, displayTotal as number)

    const list = filteredProfiles.map((element: RealStoriesCardItem, key: number) => {
      let rowStart = 0
      const featuredCards: number[] = []
      const twoUpCards: number[] = []
      rowLayout.forEach((element) => {
        if (element === 'featuredLeft' || element === 'featuredRight') {
          const cardIndex = element === 'featuredLeft' ? rowStart : rowStart + 1
          featuredCards.push(cardIndex)
          rowStart = rowStart + 2
        } else if (element === 'twoUp') {
          twoUpCards.push(rowStart, rowStart + 1)
          rowStart = rowStart + 2
        } else {
          rowStart = rowStart + 3
        }
      })

      const cardLink: LinkField = {
        value: {
          href: element.url ?? '',
          text: element.fields.CtaButtonText.value ?? '',
          target: '_self',
        },
      }

      return (
        <div key={key} className={gridItemClasses(key, featuredCards, twoUpCards)}>
          <ImageOverlayCard
            cardLink={{
              'data-trackingid': element.id,
              link: cardLink,
              type: 'small-secondary',
            }}
            title={element.fields.CtaTitle}
            lazy={true}
            description={element.fields.CtaDescription}
            field={element.fields.CtaImage}
            service={element.fields.Service.value}
          />
        </div>
      )
    })

    return (
      <div className="mb-20">
        {fields.DisplayFilterTags.value && (
          <div data-cols="12">
            <div className="my-6 flex gap-3">
              {filters.map((filter, i) => (
                <button
                  key={i}
                  className={`${filterButtonClasses} ${
                    activeFilters?.includes(filter) ? filterButtonActiveClasses : ''
                  }`}
                  onClick={() => {
                    setActiveFilter([filter])
                  }}
                >
                  {serviceDisplayName[filter as keyof typeof serviceDisplayName]}
                </button>
              ))}
            </div>
          </div>
        )}
        {sitecoreContext.pageEditing && list.length === 0 && (
          <InfoPanel
            heading="Sitecore | Real Stories Card Grid"
            message="Please edit the datasource and select Real Stories to display"
          />
        )}
        <div data-cols="12" className={gridClasses}>
          {list}
        </div>
        {(displayTotal as number) < fields.RealStoriesProfiles.length && (
          <div className="" data-cols="12">
            <Button
              data-trackingid={rendering.uid as string}
              data-testid="load-more-button"
              button
              link={{ value: { text: fields.LoadMoreText.value } }}
              type="action"
              icon="refresh"
              onClick={() => {
                setShowAll(!showAll)
              }}
            />
          </div>
        )}
      </div>
    )
  }

  return <CarouselCardsDefaultComponent />
}

export default withDatasourceCheck()<RealStoriesCardGridProps>(RealStoriesCardGrid)
