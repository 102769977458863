import ContentSummary from "./ContentSummary";

export type Step = {
    title: string;
    bodyContent: string;
    subHeading: string;
    listItems?: string[];
    isActive: boolean;
}

type ContentSummaryListProps = {
    steps?: Step[];
};


const ContentSummaryList = ({ steps = [] }: ContentSummaryListProps): JSX.Element => {

    return (<>
      { steps.map((s,i) => <ContentSummary key={i} title={s.title} bodyContent={s.bodyContent} subHeading={s.subHeading} listItems={s.listItems} step={i + 1} isActive={s.isActive} />) }
    </>)
       };

export default ContentSummaryList;
