import { useMemo } from 'react'

import { NavigationItemWithChildren } from 'components/front-end/Header/navigationHelpers'
import { NavigationContext } from 'components/front-end/Header/NavigationProvider'
import { useLocalBackNavigation } from './useLocalBackNavigation'
import { usePathSegments } from './usePathSegments'

export function useBreadcrumbs() {
  const navItems = NavigationContext.useSelector(({ context }) => context.navItems)
  const pathSegments = usePathSegments()
  const enableBackArrow = useLocalBackNavigation()
  const breadcrumbNavItems = useMemo(
    () =>
      pathSegments.reduce((acc, segment, index) => {

        const source = index === 0 ? navItems : acc[index - 1]?.children

        if (!source) {
          return acc
        }

        const item = source.find((item) => !item.hideInNavigation && !item.hideInBreadcrumb && item.url === segment)

        if (item) {
          acc[index] = item
        }
        else {
          acc = []
        }

        return acc
      }, [] as NavigationItemWithChildren[]),
    [navItems, pathSegments]
  )

  const backLinkEnabled = enableBackArrow && breadcrumbNavItems.length === 0
  const showBreadcrumbBar = breadcrumbNavItems.length > 0 || (backLinkEnabled && pathSegments.length)

  return { breadcrumbNavItems, showBreadcrumbBar, backLinkEnabled } as const
}
