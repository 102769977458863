type LabelProps = {
  text: string;
  theme: 'light' | 'dark';
};

const Label = ({ text, theme }: LabelProps): JSX.Element => {
  const textColor = theme === 'light' ? 'text-grey-dark' : 'text-white';
  return <div className={'stag ' + textColor}>{text}</div>;
};

export default Label;
