import { useEffect, useState } from 'react'

export function useStickyNav() {
  const [stickyNavEnabled, setStickyNavEnabled] = useState(false)

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-sticky-nav') {
          setStickyNavEnabled(document.documentElement.getAttribute('data-sticky-nav') === 'true')
        }
      })
    })

    observer.observe(document.documentElement, {
      attributes: true,
    })

    // Initial check
    setStickyNavEnabled(document.documentElement.getAttribute('data-sticky-nav') === 'true')

    return () => {
      observer.disconnect()
    }
  }, [])

  return stickyNavEnabled
}
