import { ButtonTypes } from 'components/front-end/Buttons/Button'
import CTATile from 'components/front-end/CTATile/CTATile'
import { JobData } from './_JobInterface'

type JobCTATilesProps = Pick<JobData, 'service' | 'cta' | 'id'> & { className?: string }

export default function JobCTATiles({ service, cta, id, className }: JobCTATilesProps) {
  if (!cta) {
    return null
  }
  return (
    <section className={className}>
      <CTATile
        id={id as string}
        service={service}
        ImageDesktop={{ value: { src: cta.imageDesktop } }}
        ImageMobile={{ value: { src: cta.imageMobile } }}
        Headline1={cta.leftHeadline1}
        SubHeadline1={cta.leftSubHeadline1}
        CTAButton1={{
          value: {
            type: cta.leftCTAButton1URL,
            href: cta.leftCTAButton1URL,
            text: cta.leftCTAButton1Title,
          },
        }}
        CTAButton1Theme={cta.leftCTAButton1Theme as ButtonTypes}
        CTAButton1Icon={cta.leftCTAButton1Icon}
        Headline2={cta.rightHeadline2}
        SubHeadline2={cta.rightSubHeadline2}
        CTAButton2={{
          value: {
            type: cta.rightCTAButton2URL,
            href: cta.rightCTAButton2URL,
            text: cta.rightCTAButton2Title,
          },
        }}
        CTAButton2Theme={cta.rightCTAButton2Theme as ButtonTypes}
        CTAButton2Icon={cta.rightCTAButton2Icon}
        Headline3={cta.bottomHeadline3}
        SubHeadline3={cta.bottomSubHeadline3}
        CTAButton3={{
          value: {
            type: cta.bottomCTAButton3URL,
            href: cta.bottomCTAButton3URL,
            text: cta.bottomCTAButton3Title,
          },
        }}
        CTAButton3Theme={cta.bottomCTAButton3Theme as ButtonTypes}
        CTAButton3Icon={cta.bottomCTAButton3Icon}
      />
    </section>
  )
}
