import React, { useEffect, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

import { KeywordSuggestionsRequest, KeywordSuggestionsResponse } from 'components/ADFEventSearchResults'
import { SearchResult } from 'components/ADFSearchResults'
import Button from 'components/front-end/Buttons/Button'
import Pagination from 'components/front-end/Buttons/Pagination'
import Drawer from 'components/front-end/Drawers/Drawer'
import FAQContent, { FAQContentProps } from 'components/front-end/FAQ/FAQContent'
import Icon from 'components/front-end/Icons/Icon'
import JobCard from 'components/front-end/JobCard/JobCard'
import ListLink from 'components/front-end/Lists/ListLink'
import QuickLink from 'components/front-end/QuickLinks/QuickLink'
import SearchField from 'components/front-end/Search/SearchField'
import SearchNoResults from 'components/front-end/Search/SearchNoResults'
import { ComponentProps } from 'lib/component-props'
import { track, trackFAQ } from 'utils/tracking'
import { Service } from 'utils/useService'

export interface SearchResultsResponse {
  TotalCount: number
  Results: SearchResult[]
  Message: string | null
}

export interface SearchResultsRequest {
  filterValues: {
    FacetId: string
    FacetValues: string[]
  }[]
  searchId: string
  page: number
  perPage: number
  query?: string
}

interface RequestControl {
  isAborted: boolean
}

type FAQDataResponse = {
  message: 'OK'
  data: FAQContentProps
}

const ALL_RESULTS = 0
const JOBS_RESULTS = 1
const PAGES_RESULTS = 2
const FAQS_RESULTS = 3
const FILES_RESULTS = 4

const getSuggestions = (
  query: string,
  searchResult: SearchResultsResponse[] | undefined,
  ctrl: RequestControl,
  setNoResults: React.Dispatch<React.SetStateAction<KeywordSuggestionsResponse | undefined>>
) => {
  if ((searchResult?.[ALL_RESULTS]?.TotalCount ?? 0) > 0) {
    setNoResults(undefined)
    return Promise.resolve(undefined)
  }
  const keywordRequest: KeywordSuggestionsRequest = {
    index: '3E5609FF-1517-4EBE-9037-3EC539AF3D24',
    query,
  }
  return fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/keywordSuggestion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(keywordRequest),
  })
    .then((suggestionsResult) => suggestionsResult.json())
    .then((suggestionsResult) => {
      if (!ctrl.isAborted) {
        setNoResults(suggestionsResult)
      }
    })
}

const GlobalSearchResults = (props: ComponentProps) => {
  const resultsPerPage = 10
  const autocompleteSensitivity = 3

  const { sitecoreContext } = useSitecoreContext()

  const router = useRouter()
  const searchParams = useSearchParams()
  const globalSearchQuery = searchParams.get('query') ?? ''

  const setGlobalSearchQuery = (word: string | undefined) => {
    router.push(
      {
        query: {
          ...router.query,
          query: word,
        },
      },
      undefined,
      { shallow: true }
    )
  }

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [tab, setTab] = useState(0)
  const [query, setQuery] = useState(globalSearchQuery)
  const [autocomplete, setAutocomplete] = useState<string[] | undefined>(undefined)

  const [showFAQ, setShowFAQ] = useState(false)
  const [faqId, setFaqId] = useState<string | undefined>()
  const [faqIsLoading, setFaqIsLoading] = useState(false)
  const [faq, setFaq] = useState<FAQContentProps | undefined>()

  const [keywords, setKeywords] = useState<KeywordSuggestionsResponse | undefined>(undefined)
  const [results, setResults] = useState<SearchResultsResponse[] | undefined>(undefined)

  const tabTitle = (heading: string, resultKey: number) => {
    const totalCount = results?.[resultKey]?.TotalCount
    const title = typeof totalCount === 'undefined' ? heading : `${heading} (${totalCount})`
    return { title, heading }
  }
  const pageTabs = [
    tabTitle('All', ALL_RESULTS),
    tabTitle('Careers', JOBS_RESULTS),
    tabTitle('Pages', PAGES_RESULTS),
    tabTitle('FAQS', FAQS_RESULTS),
    tabTitle('Resources', FILES_RESULTS),
  ]

  useEffect(() => {
    if (sitecoreContext?.pageEditing === true) return

    setQuery(globalSearchQuery)

    const payload = {
      filterValues: [
        {
          FacetId: 'ac13942d-8d75-4454-ac9c-80ef64130ab6',
          FacetValues: [
            'a67ecd37-1c1d-4ef4-9d1b-77a0fb773f50', // jobs
            'c8a6acc0-e772-449e-ab05-504e7da87fca', // pages
            '25feeb5e-a531-4ce4-9d58-a6d76710b7a1', // FAQ
            'fb96522a-4500-45b0-8c95-999515426e0b', // files
          ],
        },
      ],
      searchId: '17dfd14f-ebf5-4056-bbf2-e47b2626663c',
      page: currentPage,
      perPage: resultsPerPage,
      query: globalSearchQuery,
    }

    const ctrl = {
      isAborted: false,
    }

    setIsLoading(true)
    setKeywords(undefined)

    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/AllSearch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(async (r) => {
        const json = r.status == 200 ? await r.json() : []
        if (!ctrl.isAborted) {
          setResults(json)
          getSuggestions(globalSearchQuery, json, ctrl, setKeywords).finally(() => setIsLoading(false))
        }
      })
      .catch(() => setIsLoading(false))

    return () => {
      ctrl.isAborted = true
    }
  }, [globalSearchQuery, currentPage, sitecoreContext?.pageEditing])

  // load results
  useEffect(() => {
    if (query === '' || query === globalSearchQuery || query.length < autocompleteSensitivity) {
      setAutocomplete(undefined)
      return
    }

    const payload = {
      filterValues: [],
      page: 1,
      perPage: 6,
      query,
      searchId: '9e79bd2a-629f-462e-a236-afc87b575171',
    }
    if (!query || sitecoreContext?.pageEditing === true) return

    const ctrl = {
      isAborted: false,
    }

    //setIsLoading(true)
    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/KeywordSearch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((r) => {
        if (!ctrl.isAborted) {
          setAutocomplete(r?.Results)
          getSuggestions(query, r, ctrl, setKeywords)
        } else {
          // console.log('KeywordSearch was aborted')
        }
      })

    return () => {
      ctrl.isAborted = true
    }
  }, [globalSearchQuery, query, sitecoreContext?.pageEditing])

  // load results
  useEffect(() => {
    if (!faqId || sitecoreContext?.pageEditing === true) {
      return
    }

    setShowFAQ(false)

    const ctrl = {
      isAborted: false,
    }

    setFaqIsLoading(true)

    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/faq/getFaq`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: faqId,
      }),
    })
      .then((r) => r.json())
      .then((r: FAQDataResponse) => {
        if (!ctrl.isAborted && r?.data) {
          setFaqIsLoading(false)
          setFaq(r.data)
          setShowFAQ(true)
          trackFAQ(r.data)
        } else {
          console.log('FAQ was aborted')
        }
      })

    return () => {
      ctrl.isAborted = true
    }
  }, [faqId, sitecoreContext?.pageEditing])

  useEffect(() => {
    if (tab) {
      const el = document.querySelector<HTMLElement>(`.banner-tab-${tab}`)
      el?.focus()
    }
  }, [tab])

  const sectionClasses = `
      relative mb-8
  `

  const columnLGLessGutters = `
    lg:!pr-[calc(var(--colwidth)-var(--gutter)/2)] lg:!pl-[calc(var(--colwidth)-var(--gutter)/2)]
    xxl:!pr-[calc(var(--colwidth)*2-var(--gutter)/2)] xxl:!pl-[calc(var(--colwidth)*2-var(--gutter)/2)]
  `

  const transitionClasses = `
    transition-all duration-[500ms] ease-out
  `

  const breadcrumbFollow = `
    transition-all duration-[500ms] ease-out
    delay-[73ms] ease-linear
  `

  const bannerTab = `
    py-3 px-4 bg-grey-light text-black mr-2 cursor-pointer
  `
  const bannerTabActive = `
    banner-tab-active !bg-black text-white
  `

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {lg: 1280}
  // {xl: 1440}
  // {xxl: 1920}

  const renderJobs = (response: SearchResultsResponse, tab: number, max: number) => (
    <>
      <div className="border-b border-grey-light pb-6">
        <h2 className="h5 mt-10 pb-3 normal-case">{pageTabs[tab].heading}</h2>
        <span className="sbody mr-2">
          Showing: {(currentPage - 1) * resultsPerPage + 1}-{Math.min(currentPage * max, response.TotalCount)} of{' '}
          {response.TotalCount} results
        </span>
      </div>
      <div className="results">
        {response.Results.slice(0, max).map((job) => (
          <JobCard key={job.id} jobDetails={job} displayPriorityTag={true} />
        ))}
      </div>
    </>
  )

  const renderFiles = (
    response: SearchResultsResponse,
    tab: number,
    max: number,
    isShortList: boolean,
    groupName: string
  ) => {
    return (
      <div className="flex flex-col xm:flex-row">
        <div className="w-full pb-6 xm:w-2/4">
          <h2 className="h5 pb-3 normal-case">{pageTabs[tab].heading}</h2>
          <span className="sbody mr-2">
            Showing: {(currentPage - 1) * resultsPerPage + 1}-{Math.min(currentPage * max, response.TotalCount)} of{' '}
            {response.TotalCount} results
          </span>
        </div>
        <div className="results w-full xm:w-2/4">
          {response.Results.map((result) => (
            <QuickLink
              className="!my-6"
              key={`f-${result.id}`}
              isServiceTag={false}
              link={{
                value: {
                  href: result.url || '',
                  target: '_blank',
                  title: result.title || '',
                },
              }}
              service={result.cssClass as Service}
            />
          )).slice(0, max)}
          {isShortList && response.Results.length > max && (
            <div key="btn" className="mt-8 text-right">
              <Button
                data-trackingid={[groupName, props.rendering.uid].join(' - ')}
                link={{ value: { href: '', text: 'See all results' } }}
                icon="chevron-right-double"
                type="small-secondary"
                button
                onClick={() => {
                  setTab(tab)
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderPages = (
    response: SearchResultsResponse,
    tab: number,
    max: number,
    isShortList: boolean,
    groupName: string
  ) => (
    <div className="flex flex-col xm:flex-row">
      <div className="w-full pb-6 xm:w-2/4">
        <h2 className="h5 pb-3 normal-case">{pageTabs[tab].heading}</h2>
        <span className="sbody mr-2">
          Showing: {(currentPage - 1) * resultsPerPage + 1}-{Math.min(currentPage * max, response.TotalCount)} of{' '}
          {response.TotalCount} results
        </span>
      </div>
      <div className="results w-full xm:w-2/4">
        {response.Results.map((result, i) => (
          <div key={i}>
            {tab === PAGES_RESULTS ? (
              <Link key={`p-${result.id}`} className="block" href={result.url || ''}>
                <ListLink key={i} text={result.title || ''} />
              </Link>
            ) : (
              <button
                className="w-full text-left"
                key={`p-${result.id}`}
                onClick={() => {
                  setFaqId(result.id || '')
                }}
              >
                <ListLink key={i} text={result.title || ''} />
              </button>
            )}
          </div>
        )).slice(0, max)}
        {isShortList && response.Results.length > max && (
          <div key="btn" className="mt-8 text-right">
            <Button
              data-trackingid={[groupName, props.rendering.uid].join(' - ')}
              link={{ value: { href: '', text: 'See all results' } }}
              icon="chevron-right-double"
              type="small-secondary"
              button
              onClick={() => {
                setTab(tab)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div className={`content-inner pb-20 pt-14 lg:pt-22 xl:pt-20 xxl:pt-[120px]`}>
      <div className="intersection-observer absolute left-0 top-[-5px] h-[100px] w-4" />

      <section className={`SEARCH-HEAD relative z-[49] h-[100px] bg-white xm:mb-10`}>
        <div className={`w-full bg-white ${transitionClasses} adjust`}>
          <div data-cols="12" className={`SEARCH-STICKY adjust relative bg-white ${breadcrumbFollow}`}>
            <div className={`${transitionClasses} adjust`}>
              <div className={`span-12 ${columnLGLessGutters} ${transitionClasses} adjust`}>
                <SearchField
                  routeQuery={router.asPath}
                  focusField={true}
                  inPage={false}
                  placeholderText={'Start typing'}
                  searchTitle={'Website Search'}
                  updateResults={(q) => {
                    setQuery(q)
                  }}
                  searchClicked={(q) => {
                    setAutocomplete(undefined)
                    setCurrentPage(1)
                    setGlobalSearchQuery(q)
                    track({
                      event: 'search_completed',
                      ga4category: `In-page - Search`,
                      searchinitiated_page: router.asPath,
                      ga4name: q,
                    })
                  }}
                  onClickTotal={(q) => {
                    setAutocomplete(undefined)
                    setGlobalSearchQuery(q)
                  }}
                  queryList={{
                    TotalCount: autocomplete?.length ?? 0,
                    Results: autocomplete,
                    Message: null,
                  }}
                  showTotal={true}
                  onQueryClick={(q) => {
                    router.replace(
                      {
                        pathname: 'search',
                        query: {
                          query: q,
                          page: 1,
                        },
                      },
                      undefined,
                      { shallow: true }
                    )
                    setGlobalSearchQuery(q)
                    setAutocomplete([])
                    track({
                      event: 'search_completed',
                      ga4category: `In-page - Search`,
                      searchinitiated_page: router.asPath,
                      ga4name: q,
                    })
                  }}
                  onResetClick={() => {
                    router.replace(
                      {
                        pathname: 'search',
                        query: {
                          query: '',
                          page: 1,
                        },
                      },
                      undefined,
                      { shallow: true }
                    )
                    setAutocomplete([])
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {!keywords && (
        <section className={`SEARCH-TAGS search-tags ${sectionClasses} ${transitionClasses}`}>
          <div data-cols="12" className="relative">
            <div className="row overflow-x-auto">
              <div className={`span-12 ${columnLGLessGutters}`}>
                {globalSearchQuery && (
                  <div className="pb-8">
                    <span className="sbody">Showing results for "{globalSearchQuery}"</span>
                  </div>
                )}
                <div className="flex flex-col justify-between gap-3 md:flex-row md:items-start">
                  <div className="filter-tags flex gap-x-2 gap-y-3 pb-2">
                    {pageTabs?.map(({ title }, i) => (
                      <button
                        key={i}
                        onClick={() => {
                          setCurrentPage(1)
                          setTab(i)
                        }}
                        className={`${bannerTab} ${`banner-tab-${i}`} ${tab === i ? bannerTabActive : ''}`}
                      >
                        {title}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className={`SEARCH-RESULTS ${sectionClasses} bg-white ${isLoading ? 'h-[200px]' : ''} ${transitionClasses}`}
      >
        {isLoading ? (
          <span className="absolute inset-y-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        ) : (
          <div data-cols="12" className={`relative`}>
            <div className="row">
              {Array.isArray(results) && results.length >= 5
                ? results?.map((result, i) => (
                    <div key={i} className={`span-12 ${columnLGLessGutters}`}>
                      {tab === i && result && result.TotalCount > 0 && (
                        <>
                          {tab === ALL_RESULTS && results[JOBS_RESULTS] && results[JOBS_RESULTS].TotalCount > 0 && (
                            <>
                              <div className="mb-10 last:[&_.results>div]:!border-none">
                                {renderJobs(results[JOBS_RESULTS], JOBS_RESULTS, 5)}
                              </div>
                              <div className="mb-10 border-b border-grey-light pb-10 text-right">
                                {result.Results.length > 5 && (
                                  <Button
                                    data-trackingid={['careers', props.rendering.uid].join(' - ')}
                                    link={{ value: { href: '', text: 'See all results' } }}
                                    icon="chevron-right-double"
                                    type="small-secondary"
                                    button
                                    onClick={() => {
                                      setTab(JOBS_RESULTS)
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )}
                          {tab === ALL_RESULTS && results[PAGES_RESULTS] && results[PAGES_RESULTS].TotalCount > 0 && (
                            <div className="mb-36 mt-10">
                              {renderPages(results[PAGES_RESULTS], PAGES_RESULTS, 5, true, 'pages')}
                            </div>
                          )}
                          {tab === ALL_RESULTS && results[FAQS_RESULTS] && results[FAQS_RESULTS].TotalCount > 0 && (
                            <div className="mb-36 mt-10">
                              {renderPages(results[FAQS_RESULTS], FAQS_RESULTS, 5, true, 'faqs')}
                            </div>
                          )}
                          {tab === ALL_RESULTS && results[FILES_RESULTS] && results[FILES_RESULTS].TotalCount > 0 && (
                            <div className="mb-36 mt-10">
                              {renderFiles(results[FILES_RESULTS], FILES_RESULTS, 5, true, 'resources')}
                            </div>
                          )}
                          {tab === JOBS_RESULTS && renderJobs(result, tab, resultsPerPage)}
                          {tab === PAGES_RESULTS && renderPages(result, tab, resultsPerPage, false, 'pages')}
                          {tab === FAQS_RESULTS && renderPages(result, tab, resultsPerPage, false, 'faqs')}
                          {tab === FILES_RESULTS && renderFiles(result, tab, resultsPerPage, false, 'resources')}
                        </>
                      )}
                    </div>
                  ))
                : // : typeof results === 'object' && 'Message' in results
                  //   ? (results.Message as string)
                  null}
              <SearchNoResults
                keywords={keywords}
                type="global"
                query={globalSearchQuery}
                showTips
                onClick={(word) => {
                  router.push(
                    {
                      query: {
                        ...router.query,
                        query: word,
                      },
                    },
                    undefined,
                    { shallow: true }
                  )
                }}
              />
            </div>
            {tab != ALL_RESULTS && (
              <div className="row mt-6">
                <div className={`span-12 ${columnLGLessGutters}`}>
                  {results
                    ?.filter((result, i) => i === tab && result && result.TotalCount > 0)
                    .map((result, i) => (
                      <Pagination
                        key={i}
                        itemCount={result.TotalCount}
                        currentPage={currentPage}
                        setCurrentPage={(page) => {
                          setCurrentPage(page)
                          setTimeout(() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                          }, 1000)
                        }}
                        resultsPerPage={resultsPerPage}
                        scrollOffset={0}
                        hideShowing={true}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        )}
      </section>
      <Drawer
        show={showFAQ}
        title="ADF"
        closeDrawer={() => {
          setFaqId(undefined)
          setShowFAQ(false)
        }}
        hideFooter
        returnFocusOnClose
      >
        {faqIsLoading || !faq ? (
          <span className="absolute inset-y-0 flex w-full items-center justify-center">
            <div className="svg-loading">
              <Icon name="chevron-right-double" type="stroke" width={120} height={120} className="text-grey-light" />
            </div>
          </span>
        ) : (
          <FAQContent {...faq} />
        )}
      </Drawer>
    </div>
  )
}

//question, answer, heading, quickLink, id }: FAQ
export default GlobalSearchResults
