import React from 'react'
import { ComponentParams, ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs'

interface ContainerProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
}

export const Default = (props: ContainerProps): JSX.Element => {
  const containerStyles = props.params?.styles ?? ''
  const styles = `${props.params.GridParameters} ${containerStyles}`.trimEnd()
  const gridcols = props.params.gridcols as string

  const phKey = `container-${props.params.DynamicPlaceholderId}`
  const id = props.params.RenderingIdentifier

  return (
    <div className={`relative ${styles}`} id={id ? id : undefined} data-cols={gridcols}>
      <Placeholder name={phKey} rendering={props.rendering} />
    </div>
  )
}
