import React from 'react'
import { ComponentParams, ComponentRendering, Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import { HeadingElementStyle } from 'src/utils/cleanHeadingStyle'

import ContentComponent from 'components/front-end/ContentBlock/ContentComponent'

interface ContentBlockField {
  Type: Field<string>
  Title: Field<string>
  Description: Field<string>
  ShowServiceTag: Field<boolean>
  HeadingStyle?: Field<HeadingElementStyle>
  LedeCopySize?: Field<string>
  CTAButton1?: LinkField
  CTAButton1Theme?: Field<string>
  CTAButton2?: LinkField
  CTAButton2Theme?: Field<string>
}

interface ContentBlockPageProps {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: ContentBlockField
}

export const ADFContentBlock = (props: ContentBlockPageProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier ?? props.params?.DynamicPlaceholderId
  const containerStyles = props.params?.styles ?? ''
  const styles = `${props.params?.GridParameters ?? ''} ${containerStyles}`
  const gridcols = Number(props.params?.gridcols ?? '12')

  return (
    <div className={`content-component relative my-6 ${styles}`} id={id ? id : undefined} data-cols={gridcols}>
      <ContentComponent
        id={id ?? (props.rendering.uid as string)}
        Type={props.fields?.Type?.value}
        Title={props.fields?.Title?.value}
        Description={props.fields?.Description?.value}
        CTAButton1={props.fields?.CTAButton1}
        CTAButton1Theme={props.fields?.CTAButton1Theme?.value}
        CTAButton2={props.fields?.CTAButton2}
        CTAButton2Theme={props.fields?.CTAButton2Theme?.value}
        ShowServiceTag={props.fields?.ShowServiceTag?.value}
        HeadingStyle={props.fields?.HeadingStyle?.value}
        LedeCopySize={props.fields?.LedeCopySize?.value}
      />
    </div>
  )
}

export default ADFContentBlock
