import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react'
import { twJoin } from 'tailwind-merge'

import useHasScroll from 'src/utils/useHasScroll'
import Icon from '../Icons/Icon'

export type ComparedItems = [ReactNode, ReactNode[][]][]
export type ComparisonTableProps = {
  data: ComparedItems
}

const Cell = ({ children, row, column }: { children: ReactNode; row: number; column: number }) => (
  <div
    data-row={row}
    data-column={column}
    className={twJoin(
      'flex items-start justify-start border-y-0.5px border-l-0.5px border-grey-light py-4 pl-4 pr-6 text-left text-sbody data-[column="1"]:col-start-1 data-[column="1"]:pl-6 sm:data-[column="1"]:pl-8 md:border-t-0 md:px-8 md:py-5 md:data-[column="1"]:col-start-auto md:data-[row="0"]:border-t-0.5px',
      'first:sticky first:left-0 first:z-10 first:border-y-0 first:border-l-0 first:bg-gradient-to-r first:from-white/90 first:from-80% first:to-transparent first:to-95% first:pl-6 first:pr-8 first:text-sbody first:font-bold first:text-black last:border-b-0.5px sm:first:pl-8 md:first:border-b-0.5px md:first:pl-0',
      children === null ? 'border-dashed bg-white-off' : ''
    )}
  >
    {children}
  </div>
)

const Attribute = ({ children, row }: { children: ReactNode[]; row: number }) => {
  const columns = React.Children.count(children)
  const padding = Math.max(3 - columns, 0)
  return (
    <div data-comparison-table-row={row} className="col-span-full grid grid-cols-subgrid">
      {children.flat().map((cell, columnIndex) => (
        <Cell key={`${row}-${columnIndex}`} row={row} column={columnIndex}>
          {cell}
        </Cell>
      ))}
      {Array.from({ length: padding }, (_, columnIndex) => (
        <Cell key={`${row}-${columnIndex + columns}`} row={row} column={columnIndex + columns}>
          {null}
        </Cell>
      ))}
    </div>
  )
}

const AttributeGroup = ({ name, children }: { name: ReactNode; children: ReactNode[] }) => (
  <React.Fragment>
    <div className="sticky left-0 col-span-2 whitespace-nowrap pb-4 pl-6 pt-8 sm:pl-8 md:pb-6 md:pl-0 md:pt-10">
      {name}
    </div>
    {children}
  </React.Fragment>
)

const ComparisonTable = React.memo(function ComparisonTable({ data = [] }: ComparisonTableProps) {
  const columns = Math.max(2, (data[0]?.[1]?.[0]?.length ?? 1) - 1)
  const ref = useRef<HTMLDivElement>(null)
  const [hasScroll, scrollPosition] = useHasScroll(ref, 'horizontal')

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const addedNode = mutation.addedNodes[0] as HTMLElement
          addedNode.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
      }
    })

    const target = ref.current?.querySelector('[data-comparison-table-row="0"]')
    if (target) {
      observer.observe(target, { childList: true })
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const showOverflow = { left: scrollPosition > 0, right: hasScroll && scrollPosition !== 1 }

  const handleScrollToNextColumn = (direction: 'left' | 'right') => {
    if (!hasScroll || (direction === 'left' && !showOverflow.left) || (direction === 'right' && !showOverflow.right)) {
      return
    }

    const snapElements = Array.from(ref.current?.querySelectorAll<HTMLDivElement>('[data-snap]') ?? [])
    const viewportWidth = ref.current?.getBoundingClientRect().width ?? 0
    const elementsOutOfBounds = snapElements.filter((snapElement) => {
      const { left, right } = snapElement.getBoundingClientRect()
      return direction === 'left' ? left < -1 : right > viewportWidth
    })

    if (elementsOutOfBounds.length >= 1) {
      const { offsetLeft, offsetWidth } =
        elementsOutOfBounds[direction === 'right' ? 0 : elementsOutOfBounds.length - 1]
      ref.current?.scrollTo({
        left: direction === 'left' ? offsetLeft : offsetLeft - viewportWidth + offsetWidth,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className="relative">
      <div
        className={twJoin(
          'relative',
          'before:pointer-events-none before:absolute before:inset-y-0 before:left-0 before:z-10 before:w-12 before:shrink-0 before:bg-gradient-to-r before:from-white before:from-10% before:to-transparent before:to-95% before:transition-opacity before:duration-300 md:before:opacity-0',
          'after:pointer-events-none after:absolute after:inset-y-0 after:right-0 after:z-10 after:w-12 after:shrink-0 after:bg-gradient-to-l after:from-white after:from-10% after:to-transparent after:to-95% after:transition-opacity after:duration-300 md:after:w-16 xm:after:w-24',
          showOverflow.left ? 'before:opacity-100' : 'before:opacity-0',
          showOverflow.right ? 'after:opacity-100' : 'after:opacity-0'
        )}
      >
        <div
          data-comparison-table
          className="grid snap-x snap-mandatory grid-cols-[repeat(var(--columns),50%)] overflow-x-auto md:grid-cols-[max-content,repeat(var(--columns),minmax(280px,1fr))]"
          style={{ '--columns': columns } as CSSProperties}
          ref={ref}
        >
          {[...Array(columns)].map((_, i) => (
            <div key={i} className="h-0 snap-start md:snap-end md:first:col-start-2" aria-hidden data-snap={i} />
          ))}

          {data.map(([groupTitle, group], groupIndex) => (
            <AttributeGroup key={groupIndex} name={groupTitle}>
              {group.map((attribute, rowIndex) => (
                <Attribute key={`${groupIndex}${rowIndex}`} row={rowIndex}>
                  {attribute}
                </Attribute>
              ))}
            </AttributeGroup>
          ))}
        </div>
      </div>
      {hasScroll ? (
        <div className="pointer-events-none absolute inset-0 z-20">
          <div className="sticky inset-y-1/2 mt-36 flex h-12 justify-between overflow-hidden">
            <button
              className={twJoin(
                'flex size-8 shrink-0 items-center justify-start overflow-hidden transition duration-300 md:pointer-events-none md:-translate-x-full md:opacity-0',
                showOverflow.left ? 'pointer-events-auto opacity-100 delay-300' : '-translate-x-full opacity-0 '
              )}
              onClick={() => handleScrollToNextColumn('left')}
            >
              <Icon className="drop-shadow" type="stroke" name="chevron-left" width={32} height={32} />
            </button>
            <button
              className={twJoin(
                'flex size-8 shrink-0 items-center justify-end overflow-hidden transition duration-300 md:pointer-events-none md:translate-x-full md:opacity-0',
                showOverflow.right ? 'pointer-events-auto opacity-100 delay-300' : 'translate-x-full opacity-0 '
              )}
              onClick={() => handleScrollToNextColumn('right')}
            >
              <Icon className="drop-shadow" type="stroke" name="chevron-right" width={32} height={32} />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
})

export default ComparisonTable
