import List from "components/front-end/Lists/List";
import parse from 'html-react-parser';

type ContentSummaryProps = {
    title: string;
    bodyContent: string;
    subHeading: string;
    listItems?: string[];
    isActive?: boolean;
    step: number;
  };
  
  const ContentSummary = ({ step, title, bodyContent, subHeading, listItems = [], isActive = true }: ContentSummaryProps): JSX.Element => {

     const notActiveColor = isActive ? '' : 'text-grey';

    return (
        <div className="py-6">
            <div className={"xstag " + notActiveColor}>STEP {step}/3</div>
            <div className="pb-4">
                <div className={`h6 ${notActiveColor}`}>{title}</div>
            </div>
            {
                isActive &&
                (
                    <>
                        <div className="mbody pb-4">{parse(bodyContent)}</div>
                        { subHeading && <div className="py-2 mbody-bold">{subHeading}</div> }
                        {
                            listItems &&
                            (
                                listItems.map((li, i) => <List text={li} key={i} />)
                            )
                        }
                    </>
                )
                
            }
            
        </div>
    );
  };
  
  export default ContentSummary;
  