import { useState } from 'react'
import { Controller, EffectFade } from 'swiper'
import { Swiper, SwiperSlide, type SwiperClass } from 'swiper/react'

import type { QuoteCarouselItem } from 'components/ADFQuoteCarousel'
import QuoteSliderAudio from './QuoteSlideAudio'

type QuoteAudioCarouselProps = {
  items: QuoteCarouselItem[]
  onSwiper: (swiper: SwiperClass) => void
  id?: string
}

const QuoteAudioCarousel = ({ items, onSwiper, id }: QuoteAudioCarouselProps) => {
  const [isSwiperTouch, setIsSwiperTouch] = useState(false)
  const [activeSlideId, setActiveSlideId] = useState<string | undefined>(items[0]?.id)

  return (
    <Swiper
      modules={[Controller, EffectFade]}
      onSwiper={onSwiper}
      effect="fade"
      fadeEffect={{ crossFade: false }}
      allowTouchMove={false}
      loop={false}
      initialSlide={0}
      className="xm:w-[calc(100dvw*5/12-6px)] xm:!overflow-visible [&.swiper]:mx-0"
      navigation={{
        nextEl: `.swiper-next-${id}`,
        prevEl: `.swiper-prev-${id}`,
      }}
      autoHeight
      onTouchMove={() => {
        !isSwiperTouch && setIsSwiperTouch(true)
      }}
      onTouchEnd={() => {
        setTimeout(() => {
          setIsSwiperTouch(false)
        }, 300)
      }}
      onSlideChange={({ activeIndex }) => {
        setActiveSlideId(items[activeIndex]?.id)
      }}
    >
      {items.map(({ fields, id }) => {
        return fields?.File?.value?.href ? (
          <SwiperSlide key={`audio-${id}`} className="select-none pl-[--outermargin] xm:pl-0">
            <div
              className={`
              -translate-x-1/4 opacity-0 transition duration-300 xm:duration-500
              [.swiper-slide-active+.swiper-slide_&]:translate-x-1/4
              [.swiper-slide-active_&]:translate-x-0
              [.swiper-slide-active_&]:opacity-100
              [.swiper-slide-active_&]:xm:delay-75
              `}
            >
              <QuoteSliderAudio id={id} fields={fields} active={id === activeSlideId} />
            </div>
          </SwiperSlide>
        ) : (
          <SwiperSlide key={`audio-${id}`} className="select-none pl-[--outermargin] xm:pl-0"></SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default QuoteAudioCarousel
