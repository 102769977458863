import QuoteText from 'components/front-end/TextBlocks/QuoteText'
import type { StorySlideProps } from './StorySlide'

const QuotePanel = ({ service, content }: StorySlideProps) => {
  if (!content?.QuoteText?.value) {
    return null
  }

  const quotePanelClasses = `
    story-panel bg-black-off
    py-10 px-8 w-screen h-auto
    lg:py-[92px] lg:px-16 lg:max-w-[560px] lg:h-screen
    xl:py-[108px] xl:px-18 xl:max-w-[690px]
    xxl:py-[175px] xxl:px-[120px] max-w-[848px]
  `
  return (
    <div className={quotePanelClasses}>
      <QuoteText
        text={content.QuoteText?.value}
        showServiceTag={content.ServiceTag?.value}
        service={service}
        author={content.Author?.value}
      />
    </div>
  )
}

export default QuotePanel
