import { useCallback, useRef, useState } from 'react'

import useIsomorphicLayoutEffect from 'utils/useIsomorphicLayoutEffect'

function getScrollbarWidth(): number {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  document.body.appendChild(outer)
  const inner = document.createElement('div')
  outer.appendChild(inner)
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
  outer.parentNode?.removeChild(outer)
  return scrollbarWidth
}

function setScrollbarWidthProperty(width: number) {
  document.documentElement.style.setProperty('--scrollbar-compensation', `${width}px`)
}

function isScrollLocked(): boolean {
  return typeof window !== 'undefined' ? document.body.hasAttribute('data-scroll-lock') : false
}

type ScrollLockReturn = [isLocked: boolean, setLock: (lock: boolean) => void, toggleLock: () => void]
type LegacyScrollLockReturn = {
  lockScroll: () => void
  unlockScroll: () => void
  lockedState: { current: boolean }
}

type UseBodyScrollLockReturn = ScrollLockReturn & LegacyScrollLockReturn

export default function useBodyScrollLock(): UseBodyScrollLockReturn {
  const [isLocked, setIsLocked] = useState(isScrollLocked)
  const internalChangeRef = useRef(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  // Sets the internal state of the hook and the data attribute on the body
  const setLocked = useCallback(
    (lock: boolean) => {
      if (lock !== isLocked) {
        internalChangeRef.current = true
        if (lock) {
          const scrollbarWidth = getScrollbarWidth()
          setScrollbarWidthProperty(scrollbarWidth)
          document.body.setAttribute('data-scroll-lock', '')
        } else {
          document.body.removeAttribute('data-scroll-lock')
        }
        setIsLocked(lock)

        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }

        // Set a timeout to reset the internalChangeRef
        timeoutRef.current = setTimeout(() => {
          internalChangeRef.current = false
        }, 10)
      }
    },
    [isLocked]
  )

  const lockScroll = useCallback(() => setLocked(true), [setLocked])
  const unlockScroll = useCallback(() => setLocked(false), [setLocked])
  const toggleLock = useCallback(() => {
    setLocked(!isLocked)
  }, [isLocked, setLocked])

  const lockedState = { current: isLocked }

  useIsomorphicLayoutEffect(() => {
    setIsLocked(isScrollLocked())
    const scrollbarWidth = getScrollbarWidth()
    setScrollbarWidthProperty(scrollbarWidth)

    const observer = new MutationObserver(() => {
      if (!internalChangeRef.current) {
        const currentLockState = isScrollLocked()
        if (currentLockState !== isLocked) {
          setIsLocked(currentLockState)
        }
      }
    })

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['data-scroll-lock'],
    })

    return () => {
      observer.disconnect()
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [isLocked])

  const returnValue = [isLocked, setLocked, toggleLock] as const
  Object.assign(returnValue, {
    lockScroll,
    unlockScroll,
    lockedState,
  })

  return returnValue as UseBodyScrollLockReturn
}
