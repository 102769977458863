import parse from 'html-react-parser'

interface TextProps {
  text: string
}

const StandardPanel = ({ text }: TextProps) => {
  const standardPanelClasses = `
    standard-panel [.dark_&]:!text-white
  `
  return (
    <div className={standardPanelClasses}>
      <div className={`medium mb-12`}>{parse(text)}</div>
    </div>
  )
}

export default StandardPanel
