import React from 'react'
import { ComponentParams, ComponentRendering, Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'

import FAQProvider from './front-end/FAQ/FAQContext'
import FAQTopicList from './front-end/FAQ/FAQTopicList'
import { FAQData } from './front-end/FAQ/FAQTypes'

export type ADFFAQTopicsProps = ComponentProps & {
  rendering: ComponentRendering & { params: ComponentParams }
  params: ComponentParams
  fields: {
    FaqTopicListTitle: Field<string>
    items: FAQData
  }
}

export const ADFFaqTopics = ({ fields }: ADFFAQTopicsProps) => {
  return (
    <FAQProvider data={fields.items}>
      <FAQTopicList title={fields.FaqTopicListTitle.value} />
    </FAQProvider>
  )
}

export default withDatasourceCheck()<ADFFAQTopicsProps>(ADFFaqTopics)
