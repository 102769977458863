import { ReactNode, useState } from 'react'
import { twJoin } from 'tailwind-merge'

import slugify from 'utils/slugifyStrings'
import { trackButton } from 'utils/tracking'
import MainNavBack from '../Navigation/MainNavBack'
import TabSwiper from '../Tabs/TabSwiper'
import { useFAQContext } from './FAQContext'

type FAQMobileBrowserProps = {
  children: ReactNode
  open?: boolean
}

const FAQMobileBrowser = ({ children, open }: FAQMobileBrowserProps) => {
  const {
    state: { selectedCategoryId, selectedSubcategoryId, selectedHeading, selectedFaqId },
    send,
    lookup,
  } = useFAQContext()

  const [swipeMove, setSwipeMove] = useState(false)

  if (!open) {
    return null
  }

  const faqIds = lookup.faqsInCategory(selectedCategoryId) ?? []
  const headings = lookup.headingsForFAQs(faqIds)

  const mobileListTitles = selectedHeading
    ? headings.map((heading) => ({ title: { field: { value: heading } }, name: slugify(heading) }))
    : []
  const selectedFAQ = selectedFaqId ? lookup.faqs[selectedFaqId] : undefined

  const handleClickBack = () => {
    if (selectedFAQ) {
      send({ type: 'DESELECT_FAQ' })
    } else if (selectedHeading) {
      send({ type: 'DESELECT_HEADING' })
    } else if (selectedSubcategoryId) {
      send({ type: 'DESELECT_SUBCATEGORY' })
    } else if (selectedCategoryId) {
      send({ type: 'DESELECT_CATEGORY' })
    }
  }

  const handleSelectHeading = (heading: string) => {
    trackButton({ title: heading, guid: `${selectedCategoryId} - TabSwiper`, location: 'FAQ' })
    if (typeof heading === 'string') {
      send({ type: 'SELECT_HEADING', payload: heading })
    }
  }

  return (
    <div className="tab-content-takeover fixed left-0 top-0 z-50 flex h-dvh w-full flex-col bg-white xm:hidden">
      <div className="tab-content-back mb-5 border-b border-grey-light">
        <div className="flex h-16 w-full !max-w-[initial] items-center pl-6 xs:pl-10">
          <div className={twJoin('shrink-0', mobileListTitles?.length && 'border-r border-black pr-6')}>
            <MainNavBack title="Back" onClick={handleClickBack} />
          </div>

          <div className="relative overflow-hidden">
            <TabSwiper
              tabList={mobileListTitles}
              onClick={(tabIndex: number) => handleSelectHeading(headings[tabIndex])}
              isSelected={Math.max(
                0,
                headings.findIndex((heading) => heading === selectedHeading)
              )}
              setSwipeMove={setSwipeMove}
            />
            <div
              className={twJoin(
                'absolute right-0 top-0 z-30 h-[65px] w-12 bg-gradient-to-r from-gradient to-white transition duration-200',
                swipeMove && 'opacity-0'
              )}
            />
          </div>
        </div>
      </div>
      <div
        className="flex flex-1 flex-col gap-y-6 overflow-y-auto px-6 py-10 xs:px-10"
        role="tabpanel"
        id={`tabpanel-${slugify(selectedHeading)}`}
        aria-labelledby={`tab-${slugify(selectedHeading)}`}
      >
        {children}
      </div>
    </div>
  )
}

export default FAQMobileBrowser
