import { forwardRef, MouseEvent } from 'react'

import Button from '../Buttons/Button'
import {
  ParsedQuestions,
  WeightedQuestionnaireButtonLabels,
  WeightedQuestionnaireFormState,
} from './weightedQuestionnaireHelpers'
import WeightedQuestionnaireQuestion from './WeightedQuestionnaireQuestion'

type WeightedQuestionnaireProps = {
  id: string
  questions: ParsedQuestions
  formState: WeightedQuestionnaireFormState
  labels: WeightedQuestionnaireButtonLabels
  onNext: () => void
  onPrevious: () => void
  onReset: () => void
  onAnswer: (payload: { id: string; value: string }) => void
}

const WeightedQuestionnaire = forwardRef<HTMLFormElement, WeightedQuestionnaireProps>(
  ({ id, questions, formState, labels, onNext, onPrevious, onReset, onAnswer }, ref) => {
    const handleNext = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      onNext?.()
    }

    const handlePrevious = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      onPrevious?.()
    }

    const handleReset = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      onReset?.()
    }

    return (
      <form ref={ref} className="text-black dark:text-white lg:pt-2 [&>div]:mb-6 xm:[&>div]:mb-10">
        <p className="stag mb-2 uppercase xm:mb-4">
          Question {formState.index + 1}/{questions.length}
        </p>

        {formState.answers.map(({ id, value }, index) => (
          <div
            className={`${
              index === formState.index ? 'block' : 'hidden'
            } [&_fieldset]:space-y-6 xm:[&_fieldset]:space-y-8 [&_label>svg]:shrink-0 [&_label]:flex [&_label]:gap-x-3 [&_label]:border-[0.5px] [&_label]:border-grey [&_label]:p-4 [&_label]:text-mbody [&_legend+div]:flex [&_legend+div]:flex-col [&_legend+div]:gap-y-4 [&_legend]:mb-2 [&_legend]:text-lg [&_legend]:font-normal`}
            key={id}
          >
            <WeightedQuestionnaireQuestion
              question={questions[index]}
              value={value}
              onChange={onAnswer}
              error={formState.error}
            />
          </div>
        ))}

        {formState.answers.some(({ value }) => !!value) ? (
          <div className="flex w-full flex-row-reverse justify-end gap-x-3 [&_.disabled]:opacity-50">
            <Button
              data-trackingid={id}
              type="action"
              isSmall
              button
              link={{ value: { text: labels.nextButtonText?.value || 'Next' } }}
              onClick={handleNext}
              disabled={formState.answers[formState.index].value === ''}
            />
            <Button
              data-trackingid={id}
              type="action"
              isSmall
              button
              link={{ value: { text: labels.previousButtonText?.value || 'Previous' } }}
              onClick={handlePrevious}
              disabled={formState.index === 0}
            />
            <Button
              data-trackingid={id}
              type="action"
              isSmall
              button
              link={{ value: { text: labels.resetButtonText?.value || 'Restart' } }}
              onClick={handleReset}
              icon="refresh"
              disabled={formState.answers.every(({ value }) => value === '')}
            />
          </div>
        ) : null}
      </form>
    )
  }
)

WeightedQuestionnaire.displayName = 'WeightedQuestionnaire'

export default WeightedQuestionnaire
