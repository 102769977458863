import React, { useEffect, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import { useBreakPoint, useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'
import Icon from '../Icons/Icon'
import SearchMatch from './MapSearchMatch'

interface SearchMatchDataProps {
  TotalCount: number
  Results?: string[]
  Message: null | string
}
interface SearchFieldProps {
  routeQuery: string
  focusField?: boolean
  inPage?: boolean
  filter?: number
  location?: string
  searchPos?: boolean
  onLocationClick?: () => void
  updateResults?: (query: string) => void
  isJobSearch?: boolean
  isEventSearch?: boolean
  searchTitle?: string
  placeholderText?: string
  queryList?: SearchMatchDataProps | null
  onQueryClick?: (result: string) => void
  onResetClick?: () => void
}

const SearchField = ({
  routeQuery,
  focusField,
  inPage = false,
  location,
  searchPos = false,
  isJobSearch = false,
  isEventSearch = false,
  updateResults,
  searchTitle = 'Search',
  placeholderText = 'Start typing',
  onLocationClick,
  queryList,
  onQueryClick,
  onResetClick,
}: SearchFieldProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  useEffect(() => {
    const field = document?.querySelector('.search-input') as HTMLInputElement
    setTimeout(() => field.focus(), 100)
  }, [focusField])

  const [inputQuery, setInputQuery] = useState<string>(routeQuery)

  const route = isJobSearch ? 'jobs' : isEventSearch ? 'events' : 'keywords'

  useEffect(() => {
    setInputQuery(routeQuery)
  }, [routeQuery])

  useEffect(() => {
    if (sitecoreContext?.pageEditing == true) return

    updateResults?.(inputQuery)
  }, [inputQuery])

  const searchWrapperClasses = `
        search-field search-wrapper relative w-full
        border-b border-grey-light
        py-5
        pl-8 md:pl-12 xm:pl-16 xl:pl-18 xxl:pl-[120px]
        pr-8 md:pr-12 xl:-[54px]
    `
  const searchTitleClasses = `
        search-title xstag-bold mb-1 transition
    `
  const searchInputClasses = `
        search-input h4 w-full h-6  mbody
        text-black placeholder-grey-medium
        !outline-none
    `
  const iconSearechClasses = `
        icon-search transition-[size]
    `
  const labelFilterLocationClasses = `hidden lg:flex mbody min-w-[94px]
        overflow-hidden transition-size-spacing-opacity duration-200
        ${searchPos ? 'w-0 opacity-0 m-0 xm:w-auto xm:opacity-100 xm:ml-1.5' : 'ml-1.5'}
    `
  const barFilterLocationClasses = `
        bar w-px h-5 border-r-[0.5px] border-grey
        mx-4
    `

  // font: font-uniform-condensed font-[500] text-sm
  const counterClasses = `
    rounded-full text-black mtag h-[21px] w-[21px] items-center justify-center flex no-underline group-hover:bg-black group-hover:text-white transition duration-500
    [.tri-service_&]:bg-tri-service [.army_&]:bg-army [.air-force_&]:bg-air-force [.navy_&]:bg-navy
    ml-[3px] mr-px
    `

  const subLocationMobile = `
    sub-filter flex py-4 pl-8 md:pl-12 xm:pl-16 xl:pl-18 xxl:pl-[120px] border-b border-grey-light transition-all
    `

  function updateSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const element = e.target as HTMLInputElement
    setInputQuery(element.value)
  }

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {lg: 1280}
  // {xl: 1440}
  // {xxl: 1920}

  return (
    <>
      <div className={searchWrapperClasses}>
        <div className={searchTitleClasses}>{searchTitle}</div>
        <div className="search-inner relative transition-all duration-[300ms] ease-out">
          <div className="flex items-center">
            <input
              autoFocus={focusField}
              tabIndex={0}
              className={searchInputClasses}
              type="text"
              onChange={(e) => updateSearch(e)}
              onKeyDown={(e) => {
                if (
                  (e.code === 'Enter' || e.code === 'NumpadEnter') &&
                  queryList?.Results &&
                  queryList?.Results.length > 0
                ) {
                  onQueryClick?.(queryList?.Results[0])
                }
              }}
              placeholder={placeholderText}
              value={inputQuery}
            />

            <button
              className={`${iconSearechClasses} svg-clicked flex items-center justify-center bg-white ${
                inputQuery.length > 0 ? 'clicked-active' : ''
              }`}
              onClick={() => {
                setInputQuery('')
                onResetClick?.()
              }}
              aria-label="search reserve units"
            >
              <Icon name="search" type="stroke" height={20} width={20} className="[&_path]:!stroke-[2px] " />
            </button>

            {inPage && (useGreaterThan('xm') || searchPos) && (
              <>
                {location && (
                  <>
                    <div className={barFilterLocationClasses}></div>
                    <button
                      className="svg-hover flex items-center justify-center bg-white"
                      onClick={() => {
                        onLocationClick?.()
                      }}
                    >
                      <Icon name="location" type="stroke" width={20} height={20} />
                      <span className={labelFilterLocationClasses}>{location}</span>
                    </button>
                  </>
                )}
              </>
            )}
          </div>

          {(queryList?.Results?.length ?? 0) > 0 && (
            <SearchMatch
              query={inputQuery}
              results={queryList}
              onClick={(x) => {
                onQueryClick?.(x)
              }}
            />
          )}
        </div>
      </div>

      {inPage && useLessThan('xm') && (
        <div className={`${subLocationMobile}`}>
          {location && (
            <>
              <button
                className="svg-hover ml-[-3px] flex items-center justify-center bg-white"
                onClick={() => {
                  onLocationClick?.()
                }}
              >
                <Icon name="location" type="stroke" className="h-[18px] w-[18px]" />
                <span className="mbody ml-1.5 font-normal">{location}</span>
              </button>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default SearchField
